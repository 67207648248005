import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { addUserdata } from '../../store/slices/userDataSlice';
import axios from 'axios';

export const handleInterestsSubmit = async (
    user_data,
    ChoosedInterest,
    setIsLoading,
    SetEnableToastMsg,
    dispatch,
    setNav,
    isForEditProfile,
    handleBack
) => {
    setIsLoading(true);

    const data = JSON.stringify({
        interests: ChoosedInterest
    });

    const config = {
        method: 'post',
        url: `${process.env.REACT_APP_ONBOARDING_SERVER2}update/profile?step=11`,
        headers: {
            authToken: user_data?.authToken ?? '',
            instanceId: user_data?.instanceId ?? '',
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        // console.log('Updated interests: ', response.data);

        if (response.data.statusCode === 200) {
            // console.log('updated ');
            setIsLoading(false);
            SetEnableToastMsg(true);
            GetUserProfile(
                async (data) => {
                    dispatch(addUserdata({ ...data?.data }));
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );

            if (!isForEditProfile) setNav('ProfileSummary');
            else {
                handleBack();
            }
        }
    } catch (error) {
        setIsLoading(false);
        console.error('Error updating interests: ', error);
    }
    setIsLoading(false);
};
