function FacebookShareIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
            {...props}
        >
            <path
                fill="#395185"
                d="M26 0C11.64 0 0 11.64 0 26s11.64 26 26 26 26-11.64 26-26S40.36 0 26 0zm6.159 17.967H28.25c-.463 0-.978.61-.978 1.42v2.821h4.888l-.739 4.025h-4.15v12.082h-4.611V26.233h-4.185v-4.025h4.185v-2.367c0-3.396 2.356-6.156 5.59-6.156h3.908v4.282z"
            ></path>
        </svg>
    );
}

export default FacebookShareIcon;
