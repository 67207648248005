import axios from 'axios';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
// import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
// import i18n from '../../i18n/config';
const SelectedLanguage = Geti18nLang();

export const ShortTermPlans = (
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any },
    user_data?: any
) => {
    const API: any = process.env.REACT_APP_LIVEEVENTS_SERVER + 'plans';
    axios
        .get(API, {
            headers: {
                lang: SelectedLanguage || 'en',
                instanceId: user_data?.instanceId?.toString() || '',
                authToken: user_data?.authToken || ''
            },
            params: {}
        })
        .then((res) => {
            onSuccess(res.data.data);
        })
        .catch((e) => {
            onFailure(e);
        });
};

export default ShortTermPlans;
