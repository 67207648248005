import { useState } from 'react';
import { BsGenderAmbiguous, BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { TopBabynames } from '../../api/baby-name';
import { t } from 'i18next';
import Gutter from '../../components/Gutter/Gutter';
import { useNavigate } from 'react-router-dom';
// import i18n from '../../i18n/config';
import { getNameFromUrl } from '../../utils/utils';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import TopTenIcon from '../../assets/icons/TopTenIcon';
import UniqueNameIcon from '../../assets/icons/UniqueNameIcon';
const arrowfront = 'https://img1.parentune.com/web/assests_v2/arrowfront.webp';
const SelectedLanguage = Geti18nLang();

const BabyNameBottom2 = () => {
    const [nametype, setnametype] = useState('');
    let data: any;
    data = TopBabynames(nametype);
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);

    const navigate = useNavigate();

    const handleNameClick = (e: any) => {
        const nameDetail = Object.assign({}, e.currentTarget.dataset);
        let name = getNameFromUrl(nameDetail?.name);
        const url =
            SelectedLanguage === 'en'
                ? `/baby-names/detail/${name}/${nameDetail.id}`
                : `/${SelectedLanguage}/baby-names/detail/${name}/${nameDetail.id}`;
        navigate({
            pathname: url
        });
    };

    return (
        <div className="mt-5">
            <div className="border box-1">
                <div className="p-3">
                    <div
                        onClick={() => {
                            setshow(!show);
                            setshow1(false);
                            setshow2(false);
                            setnametype('');
                        }}
                        className="flex justify-between mb-1 cursor-pointer items-center pt-track-click"
                        data-ptname="baby-names-top-10-names"
                    >
                        <div className="flex items-center space-x-2 ">
                            {/* <TopTenIcon /> */}
                            {/* <img className="h-6 w-6 mr-1" src={top}></img> */}
                            <p className="para-1">{t('Top 10 names')}</p>
                        </div>
                        <img className="h-3 w-1.5" src={arrowfront} alt="arrow"></img>
                    </div>
                    {show && (
                        <div className=" grid grid-cols-2 sm:grid sm:grid-cols-4 mb-4 ">
                            {data.data &&
                                data.data.baby_names.map((c: any) => {
                                    return (
                                        <>
                                            <div
                                                className="flex pb-1 items-center cursor-pointer"
                                                onClick={handleNameClick}
                                                data-name={c.name}
                                                data-desc={c.description}
                                                data-gender={c.gender}
                                                data-religion={c.origin}
                                                data-id={c.id}
                                                key={`babyNames1${c.id}`}
                                            >
                                                {c.gender == 'boy' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full ">
                                                        <BsGenderMale />
                                                    </div>
                                                )}
                                                {c.gender == 'girl' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full  ">
                                                        <BsGenderFemale />
                                                    </div>
                                                )}
                                                {c.gender == 'other' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full ">
                                                        <BsGenderAmbiguous />
                                                    </div>
                                                )}

                                                <p className="ml-3">{c.name}</p>
                                            </div>
                                        </>
                                    );
                                })}
                        </div>
                    )}
                </div>
                <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />
                <div className="p-3">
                    <div
                        onClick={() => {
                            setshow(false);
                            setshow2(!show2);
                            setshow1(false);
                            setnametype('uniqueName');
                        }}
                        className="flex justify-between mt-1 mb-1 items-center cursor-pointer pt-track-click"
                        data-ptname="baby-names-top-unique-names"
                    >
                        <div className="flex items-center space-x-2">
                            {/* <UniqueNameIcon /> */}
                            {/* <img className="h-6 w-6 mr-1" src={star}></img> */}
                            <p className="para-1">{t('Unique names')}</p>
                        </div>
                        <img className="h-3 w-1.5" src={arrowfront} alt="arrow"></img>
                    </div>
                    {show2 && (
                        <div className=" grid grid-cols-2 sm:grid sm:grid-cols-4 mb-4 ">
                            {data.data &&
                                data.data.baby_names.map((c: any) => {
                                    return (
                                        <>
                                            <div
                                                className="flex pb-1 items-center cursor-pointer"
                                                onClick={handleNameClick}
                                                data-name={c.name}
                                                data-desc={c.description}
                                                data-gender={c.gender}
                                                data-religion={c.origin}
                                                data-id={c.id}
                                                key={`babyNames2${c.id}`}
                                            >
                                                {c.gender == 'boy' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full ">
                                                        <BsGenderMale />
                                                    </div>
                                                )}
                                                {c.gender == 'girl' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full  ">
                                                        <BsGenderFemale />
                                                    </div>
                                                )}
                                                {c.gender == 'other' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full ">
                                                        <BsGenderAmbiguous />
                                                    </div>
                                                )}

                                                <p className="ml-3">{c.name}</p>
                                            </div>
                                        </>
                                    );
                                })}
                        </div>
                    )}
                </div>
                <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />
                <div className="p-3">
                    <div
                        onClick={() => {
                            setshow(false);
                            setshow1(!show1);
                            setshow2(false);
                            setnametype('');
                        }}
                        className=" mt-1 flex justify-between items-center cursor-pointer pt-track-click"
                        data-ptname="baby-names-top-trending-names"
                    >
                        <div className="flex items-center">
                            {/* <img className="h-5 w-5 mr-1" src={trending}></img> */}
                            <p className="para-1">{t('Trending names')}</p>
                        </div>
                        <img className="h-3 w-1.5" src={arrowfront} alt="arrow"></img>
                    </div>
                    {show1 && (
                        <div className=" grid grid-cols-2 sm:grid sm:grid-cols-4 mb-4 ">
                            {data.data &&
                                data.data.baby_names.map((c: any) => {
                                    return (
                                        <>
                                            <div
                                                className="flex pb-1 items-center cursor-pointer"
                                                onClick={handleNameClick}
                                                data-name={c.name}
                                                data-desc={c.description}
                                                data-gender={c.gender}
                                                data-religion={c.origin}
                                                data-id={c.id}
                                                key={`babyNames3${c.id}`}
                                            >
                                                {c.gender == 'boy' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full ">
                                                        <BsGenderMale />
                                                    </div>
                                                )}
                                                {c.gender == 'girl' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full  ">
                                                        <BsGenderFemale />
                                                    </div>
                                                )}
                                                {c.gender == 'other' && (
                                                    <div className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full ">
                                                        <BsGenderAmbiguous />
                                                    </div>
                                                )}

                                                <p className="ml-3">{c.name}</p>
                                            </div>
                                        </>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BabyNameBottom2;
