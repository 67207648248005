import React, { useEffect, useRef, useState } from 'react';
import CrossIcon from '../../../assets/icons/CrossIconVIdeo.svg';
import ParentuneLogo from '../../../assets/icons/Parentune-Logo-round.png';
import BlackPlay from '../../../assets/icons/BlackPlay.svg';
import BlackPause from '../../../assets/icons/BlackPause.svg';
import './HowToUseParentune.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/store';
import { addUserdata } from '../../../store/slices/userDataSlice';
import { useUserData } from '../../../utils/hooks/useUserData';
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi';

const HowToUseParentune = (props: any) => {
    const vidRef = useRef<any>(null);
    const [isPaused, setIsPaused] = useState(false);
    const navigate = useNavigate();
    const { userData: user_data } = useUserData();
    const [mute, setMute] = useState(true);
    const progressBarRef = useRef<any>(null);
    const [time, setTime] = useState<number>(0);
    const [videoTime, setVideoTime] = useState<number>(0);
    const location = useLocation();
    const [isUserFromSideBar, setisUserFromSideBar] = useState(false);

    const handlePause = () => {
        setIsPaused(true);
    };

    const handlePlay = () => {
        setIsPaused(false);
    };

    const updateProgressBar = () => {
        const video: any = vidRef.current;
        if (video) {
            // setTime(vidRef.current.time);
            const progressBar: any = progressBarRef.current;
            // console.log('line 32');
            const progress = (video.currentTime / video.duration) * 100;
            // console.log('progress', progress);
            progressBar.style.width = `${progress}%`;
        }
    };

    const handleTogglePlayPause = () => {
        const video: any = vidRef.current;
        if (video) {
            if (video.paused) {
                video.play(); // Play the video if it's paused
            } else {
                video.pause(); // Pause the video if it's playing
            }
            setIsPaused(!video.paused); // Update the paused state
        }
    };

    useEffect(() => {
        if (!vidRef?.current) return;
        setVideoTime(vidRef.current.duration);
        vidRef.current.addEventListener('timeupdate', updateProgressBar); // Listen for time updates
    }, [vidRef?.current]);

    useEffect(() => {
        if (window.location.pathname == '/how-to-use-parentune') setisUserFromSideBar(true);
        else {
            setisUserFromSideBar(false);
        }
        const toggleMute = () => {
            // Check if the video element exists
            if (vidRef.current) {
                // Toggle the muted property
                vidRef.current.muted = !vidRef.current.muted;
            }
        };
        toggleMute();
    }, [mute]);

    useEffect(() => {
        if (!vidRef?.current) return;
        console.log('vidRef.current.muted', vidRef.current.muted);
        setMute(vidRef.current.muted);
    }, []);

    window.setInterval(function () {
        if (!vidRef.current) {
            return;
        }
        setTime(vidRef.current?.currentTime);
    }, 1000);

    const dispatch = useAppDispatch();

    return (
        <main className="relative max-w-4xl mx-auto flex flex-col !h-[100svh] !max-h-[100vh]   bg-[#f6d751]  ">
            <section className="w-full relative">
                <div className="absolute top-0 left-0 z-50">
                    <button
                        onClick={() => {
                            if (isUserFromSideBar) {
                                return navigate(-1);
                            }
                            vidRef?.current?.pause();
                            dispatch(addUserdata({ ...user_data, isNewUser: false }));

                            // @ts-ignore
                            const { from } = location.state || { from: { pathname: '/' } };
                            navigate(from);
                        }}
                        className=" p-6 "
                    >
                        <img src={CrossIcon} />
                    </button>
                </div>

                {/* video */}
                {/* <section className="video-placeholder bb">
                    <main className="w-full bg-gray-400 animate-pulse"></main>
                </section> */}
                <div className=" w-full z-[49]">
                    {mute ? (
                        <BiVolumeFull
                            onClick={() => setMute((prev) => !prev)}
                            className="absolute cursor-pointer h-7 w-7 z-30 bg-white rounded-full p-0.5 right-2 top-[80%]"
                        />
                    ) : (
                        <BiVolumeMute
                            onClick={() => setMute((prev) => !prev)}
                            className="absolute cursor-pointer h-7 w-7 z-30 bg-white rounded-full p-0.5 right-2 top-[80%]"
                        />
                    )}
                    <video
                        id="HowToPlayVideo"
                        ref={vidRef}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        className="m-video max-h-[100vh]"
                        autoPlay
                        loop
                        muted
                        onLoad={() => {
                            console.log('vid loaded');
                        }}
                    >
                        <source
                            src="https://img1.parentune.com/images/videos/parentuen_vimeo_videos_cdn/onbarding_video_-_1920x1080.mp4%20%281080p%29.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>

                {isPaused && (
                    <div className="absolute bottom-10 md:bottom-40 left-1/2 transform -translate-x-1/2">
                        <button
                            className="bg-[#367E8A] rounded-3xl w-fit h-35 flex justify-center items-center mt-16 self-center text-white px-5 py-2"
                            onClick={() => {
                                vidRef?.current?.pause();
                                dispatch(addUserdata({ ...user_data, isNewUser: false }));

                                // @ts-ignore
                                const { from } = location.state || { from: { pathname: '/' } };
                                navigate(from);
                            }}
                        >
                            <div>NEXT</div>
                        </button>
                    </div>
                )}
            </section>

            <footer className="absolute bottom-0 w-full">
                {/* progress bar */}
                <div className="progress-bar">
                    <div ref={progressBarRef} className="progress  bg-black h-1" />
                </div>
                <section className="flex flex-row items-center space-x-4 w-full min-h-[100px]  px-4 bg-[linear-gradient(103.83deg,_#FFE0E3_39.52%,_#FFEED9_147.52%)] ">
                    <div className=" ">
                        <img src={ParentuneLogo} className=" w-12" />
                    </div>

                    <div className=" w-auto max-w-[70%] font-[450] font-roboto text-base ">
                        <p>
                            Learn how to use parentune. You can rely on parentune 24x7 to make
                            informed decison
                        </p>
                    </div>

                    <button onClick={handleTogglePlayPause}>
                        <div className="">
                            {isPaused ? (
                                <img src={BlackPlay} className="h-5 w-5" />
                            ) : (
                                <img src={BlackPause} className="h-5 w-5" />
                            )}
                        </div>
                    </button>
                </section>
            </footer>
        </main>
    );
};

export default HowToUseParentune;
