export const countriesTOShowBabyNames = ['africa', 'south-africa', 'nigeria', 'kenya', 'ghana'];

export const countriesTOShowBabyNamesInDropDown = [
    { name: 'South Africa', value: 'south-africa' },
    { name: 'Nigeria', value: 'nigeria' },
    { name: 'Kenya', value: 'kenya' },
    { name: 'Ghana', value: 'ghana' }
];

export const countryMapped = {
    'south-africa': 'South Africa',
    nigeria: 'Nigeria',
    kenya: 'Kenya',
    ghana: 'Ghana',
    africa: 'Africa'
};

export const countryMapped2 = {
    'south-africa': ' a South African',
    nigeria: 'a Nigerian',
    kenya: 'a Kenyan',
    ghana: 'a Ghanaian ',
    africa: 'an African'
};

export const countryMapped3 = {
    'south-africa': 'South African',
    nigeria: 'Nigerian',
    kenya: 'Kenyan',
    ghana: 'Ghanaian ',
    africa: 'African'
};

export const IntroGenForBabyNames = (country: string) => {
    return `Looking for a special name for your baby? Selecting a name for your baby is a significant decision that requires thought and creativity. But worry not! We've got you covered. With over 15000+ number unique baby names for both boys and girls, along with their meanings, you're sure to find the perfect fit for your little one. Our baby name finder tool makes it easy to browse through our extensive collection of names. Whether you prefer traditional names or modern names, we have options tailored just for you. Explore our alphabetical listing of names and their meanings to find inspiration for your baby's name. We have the widest variety of baby boy names and girl names according to religion and gender (Muslim Boy Names, Muslim Girl Names, Christian Boy Names, Christian Girl Names, Traditional ${country} Boy Names, Traditional ${country} Girl Names). You can even choose a name based on astrology or numerology if you wish. Our alphabetical listing of names along with their meanings makes your baby name search even easier. We also have a bookmark feature that allows you to save your favorite names and share them with family and friends for feedback. Welcome to our world of ${country} baby names, where every name tells a story and carries a special meaning.`;
};
