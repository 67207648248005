import axios from 'axios';

export const fetchAvatars = async (setcustomAvatars) => {
    const res = await axios.get(`https://api9.parentune.com/user/v1/avatars`);
    if (res?.data?.statusCode !== 200) return;
    setcustomAvatars(res?.data?.data);
};

export const AvatarsArray = [
    {
        name: 'pt_avatar_1.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_1.jpg'
    },
    {
        name: 'pt_avatar_2.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_2.jpg'
    },
    {
        name: 'pt_avatar_3.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_3.jpg'
    },
    {
        name: 'pt_avatar_4.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_4.jpg'
    },
    {
        name: 'pt_avatar_5.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_5.jpg'
    },
    {
        name: 'pt_avatar_6.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_6.jpg'
    },
    {
        name: 'pt_avatar_7.jpg',
        url: 'https://img1.parentune.com/images/avatar/pt_avatar_7.jpg'
    }
];
