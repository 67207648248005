import { t } from 'i18next';
import { Constants } from '../components/Constants/Constants';
import { v4 as uuid } from 'uuid';
import ReactGA from 'react-ga4';
import { User } from '../types/user';

export function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export const getHost = () => {
    let url = window?.location;
    return `${url?.protocol}//${url.host}/`;
};

export const capitalize = (name: string) => {
    if (!name) {
        return null;
    }
    return name
        .split(' ')
        .map((item) => item?.[0]?.toUpperCase() + item.slice(1).toLowerCase())
        .join(' ');
};

export const getNameFromUrl = (url: string) => {
    if (url.includes('(')) {
        let result: any = url.match(/\(.+\)/);
        let name = result?.[0]?.slice(1, -1);
        return name;
    } else {
        return url;
    }
};

export const toCapitalize = (name: any) => {
    if (name) {
        return name[0]?.toUpperCase() + name.slice(1);
    }
};
// ============================================================================================
export const countDown = (eventEndsIn: string) => {
    const endTime: number = new Date(eventEndsIn).getTime();
    const nowTime: number = new Date().getTime();
    const timeLeft: number = endTime - nowTime;
    // console.log(endTime, nowTime, timeLeft);
    const sec = 1000;
    const min: number = sec * 60;
    const hour: number = min * 60;
    const day: number = hour * 24;

    // calculate the shit!!!
    const textDay: string = Math.floor(timeLeft / day).toString();

    const textHour: string =
        Math.floor((timeLeft % day) / hour) <= 9
            ? '0' + Math.floor((timeLeft % day) / hour).toString()
            : Math.floor((timeLeft % day) / hour).toString();

    const textMinute: string =
        Math.floor((timeLeft % hour) / min) <= 9
            ? '0' + Math.floor((timeLeft % hour) / min).toString()
            : Math.floor((timeLeft % hour) / min).toString();

    const textSecond: string =
        Math.floor((timeLeft % min) / sec) <= 9
            ? '0' + Math.floor((timeLeft % min) / sec).toString()
            : Math.floor((timeLeft % min) / sec).toString();

    return `${textHour}:${textMinute}:${textSecond}`;

    // return {textDay, textHour, textMinute, textSecond}
};

// ============================================================================================

export const timeSince = (date: number) => {
    const p = Date.now() - date;
    const seconds = Math.floor(p / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        if (interval >= 2) return Math.floor(interval) + ' years ago';
        else return '1 year ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        if (interval >= 2) return Math.floor(interval) + ' months ago';
        else return '1 month ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        if (interval >= 2) return Math.floor(interval) + ' days ago';
        else return '1 day ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        if (interval >= 2) return Math.floor(interval) + ' hours ago';
        else return '1 hour ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
        if (interval >= 2) return Math.floor(interval) + ' min ago';
        else return '1 min ago';
    }

    if (seconds > 1) return '';
    else return '';
};

export const getAddView = (
    adId: any,
    adText: any,
    redirectionUrl: any,
    imageUrl: any,
    videoUrl: any,
    conditionalText: any,
    className?: any,
    ageGroup?: any,
    card_size?: any,
    adDescription?: any,
    pageInfo?: any,
    includesCity?: any
) => {
    //  console.log(adId);
    let textView = '';
    redirectionUrl ? (textView = `<div class=native-ds><a href=${redirectionUrl}>`) : null;
    if (!conditionalText) {
        conditionalText = '';
    }

    if (!ageGroup) {
        ageGroup = t('all_age_groups');
    }

    let textAdView = `<div class=${className}>
            <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
                <div class="nativeAdFooterContainer">
                    <div class="left-side">
                        <p class="nativeAdFooterHeading">${adText}</p>
                    </div>
                    <div class="right-side">
                        <a href=${redirectionUrl} class="nativeAdCTA pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" onclick="">
                            ${conditionalText}
                        </a>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>`;

    let PreschooltextAdView = `<div class=${className}>
            <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
                <div class="nativeAdImageContainer">
                        <img src=${imageUrl} width="100%" loading="lazy" />
                </div>
            </div>
        </div>`;

    let imageAdView = `<div class=${className}>
            <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
                <div class="nativeAdImageContainer">
                    <a class="pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" href=${redirectionUrl} onclick="">
                        <img src=${imageUrl} width="100%" loading="lazy" />
                    </a>
                </div>
                <div class="nativeAdFooterContainer">
                    <div class="right-side">
                        <a href=${redirectionUrl} class="nativeAdCTA pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" onclick="">
                            ${conditionalText}
                        </a>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>`;

    let imageAdView2 = `<div class=${className}>
        <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
            <div class="nativeAdImageContainer">
                <a class="pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" href=${redirectionUrl} onclick="">
                    <img src=${imageUrl} width="100%"  loading="lazy" />
                </a>
            </div>
        </div>
    </div>`;

    let textImageAddView;
    if (card_size == 'lrg') {
        textImageAddView = `<div class=${className}>
                    <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
                        <div class="nativeAdImageContainer">
                            <a class="pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" href=${redirectionUrl} onclick="" />
                                <img src=${imageUrl} width="100%" loading="lazy" />
                            </a>
                        </div>
                        <div class="nativeAdFooterContainer">
                            <div class="left-side">
                                <p class="nativeAdFooterHeading" style="display:flex;">
                                   <span style="display:flex; justify-content:center; align-items:center; margin-right:5px;  ">
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7778 7.2999C11.5779 6.80669 11.5779 6.26015 11.7778 5.78027L11.8444 5.62031C12.2709 4.60723 11.7778 3.43418 10.7648 3.00762L10.6182 2.94097C10.1251 2.74102 9.73856 2.35445 9.53864 1.86123L9.48533 1.72793C9.0455 0.714848 7.88594 0.234966 6.85967 0.648198L6.72639 0.701518C6.23324 0.901469 5.68679 0.901469 5.19364 0.701518L5.07369 0.648198C4.07407 0.234966 2.90119 0.728179 2.47469 1.74126L2.42138 1.8479C2.22145 2.34112 1.83494 2.72769 1.34179 2.92764L1.22184 2.98096C0.222222 3.40752 -0.270922 4.58057 0.155581 5.59365L0.208894 5.71362C0.408817 6.20683 0.408817 6.75337 0.208894 7.23325L0.155581 7.37988C-0.270922 8.39296 0.208894 9.56601 1.23517 9.97924L1.36845 10.0326C1.86159 10.2325 2.24811 10.6191 2.44803 11.1123L2.51467 11.2589C2.92785 12.2853 4.10073 12.7652 5.11367 12.352L5.26028 12.2853C5.75343 12.0854 6.29988 12.0854 6.79303 12.2853L6.91298 12.3387C7.92593 12.7652 9.09881 12.272 9.52531 11.2589L9.57862 11.1523C9.77855 10.6591 10.1651 10.2725 10.6582 10.0726L10.7648 10.0326C11.7911 9.606 12.2709 8.44629 11.8444 7.41987L11.7778 7.2999ZM5.43355 9.09946L2.83455 6.91333L3.63424 5.96689L5.28694 7.36655L8.20582 3.90073L9.15212 4.70054L5.43355 9.09946Z" fill="black"/>
                                    </svg>
                                   </span> 
                                   <span>${adText}</span>
                                </p>
                                <p class="nativeAdFooterDescription">
                                   ${adDescription}
                                </p>
                            </div>
                            <div class="right-side">
                                    <a href=${redirectionUrl} class="nativeAdCTA pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" onclick="">
                                        ${conditionalText}
                                    </a>
                                </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>`;
    } else if (card_size == 'sml') {
        textImageAddView = `
        <div class=${className}>
        <div class="nativeAdContainer smallNativeAdCon pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >    
        <div style="background-image: url(${imageUrl}); background-repeat: no-repeat" class="nativeAdSmallContainer">
                <div class="nativeAdSmallText">
                    <h2 class="nativeAdSmallHeading">${adText}</h2>
                    <a class="nativeAdSmallCta" href=${redirectionUrl}>${conditionalText}</a>
                </div>
            </div>
        </div>
        </div>
            `;
    } else {
        textImageAddView = `<div class=${className}>
                    <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
                        <div class="nativeAdImageContainer">
                            <a class="pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" href=${redirectionUrl} onclick="" />
                                <img src=${imageUrl} width="100%" loading="lazy"/>
                            </a>
                        </div>
                        <div class="nativeAdFooterContainer">
                            <div class="left-side">
                                <p class="nativeAdFooterHeading">
                                 ${adText}
                                </p>
                               
                            </div>
                            <div class="right-side">
                                    <a href=${redirectionUrl} class="nativeAdCTA pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" onclick="">
                                        ${conditionalText}
                                    </a>
                                </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>`;
    }

    let textVideoView = `<div class=${className}>
        <div class="nativeAdContainer pt-track-impression" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" >
            <div class="nativeAdImageContainer">
                <video className="h-fit w-fit"
                                                src=${videoUrl}
                                                title="live-details-video"
                                                controls
                                                autoPlay
                                                muted
                                                controlsList="nodownload"
                                                style="width: 100%; height:250px"
                                            ></video>
           
            </div>
            <div class="nativeAdFooterContainer">
                <div class="left-side">
                    <p class="nativeAdFooterHeading">
                        ${adText}
                    </p>
                </div>
                <div class="right-side">
                        <a href=${redirectionUrl} class="nativeAdCTA pt-track-click" data-ptname="ptAd_${adId}" data-pttype="nativead" data-ptposition="content-mid" onclick="">
                            ${conditionalText}
                        </a>
                    </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>`;
    if (includesCity) {
        textView = PreschooltextAdView;
    } else if (adText && imageUrl && videoUrl) {
        textView = textVideoView;
    } else if (adText && imageUrl && !videoUrl) {
        textView = textImageAddView;
    } else if (adText && videoUrl && !imageUrl) {
        textView = textAdView;
    } else if (
        (imageUrl && !videoUrl && !adText && window.location.pathname.includes('school')) ||
        window.location.pathname.includes('prechool')
    ) {
        textView = imageAdView2;
    } else if (imageUrl && videoUrl && !adText) {
        textView = imageAdView;
    } else if (imageUrl && !videoUrl && !adText) {
        textView = imageAdView;
    } else if (adText && !videoUrl && !imageUrl) {
        textView = textAdView;
    }
    // else if(videoUrl && !imageUrl && !adText ){
    //     textView = wTextAdView

    // }
    // else if(!videoUrl && !imageUrl && !adText ){
    //     textView = wTextAdView
    // }
    return textView;
};

export const createArray = (start: number, end: number) => {
    let a = [];
    for (let i = start; i < end; i++) {
        a.push(i);
    }
    return a;
};

export const getClientDevice = () => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width < Constants.BREAK_POINTS.MD) {
        return Constants.DEVICE.MOBILE;
    } else return Constants.DEVICE.DESKTOP;
};

export const getClientWidthHeight = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

export const getUniqueId = () => {
    const unique_id = uuid();
    return unique_id.slice(0, 8);
};

export class compareDate {
    date1: any;
    m1: any;
    y1: any;
    date2: any;
    m2: any;
    y2: any;
    constructor(d1: any, d2: any) {
        this.date1 = d1.getDate();
        this.m1 = d1.getMonth();
        this.y1 = d1.getYear();

        this.date2 = d2.getDate();
        this.m2 = d2.getMonth();
        this.y2 = d2.getYear();
    }

    isPrev = () => {
        if (this.y1 < this.y2) return true;
        if (this.m1 < this.m2 && this.y1 <= this.y2) return true;
        if (this.date1 < this.date2 && this.m1 <= this.m2 && this.y1 <= this.y2) return true;
        else return false;
    };

    isEqual = () => {
        if (this.y1 === this.y2 && this.m1 === this.m2 && this.date1 === this.date2) return true;
        else return false;
    };
}

export const getAllStorageKey = () => {
    return Object.keys(localStorage);
};

export const isOneDayLeft = (futreDate: string) => {
    const then = new Date(futreDate + '+0530');
    const now = new Date();
    const msBetweenDate = then.getTime() - now.getTime();
    const hour = 60 * 60 * 1000;
    const leftHours = msBetweenDate / hour;
    if (msBetweenDate < 0) {
        return false;
    } else if (leftHours < 24) {
        return true;
    } else {
        return false;
    }
};
export const Dateformatter = (dateValue: string) => {
    const date = new Date(dateValue);
    const options: any = { month: 'short', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
};

export const formatTIme = (timeInput: string) => {
    const time = timeInput;
    const date = new Date('1970-01-01T' + time);
    const options: any = { hour: 'numeric', minute: 'numeric' };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime;
};

export const CityStateMapping = {
    'Port Blair': 'Andaman and Nicobar Islands',
    Faridabad: 'Haryana',
    Gurgaon: 'Haryana',
    Hisar: 'Haryana',
    Rohtak: 'Haryana',
    Panipat: 'Haryana',
    Karnal: 'Haryana',
    Sonipat: 'Haryana',
    Yamunanagar: 'Haryana',
    Panchkula: 'Haryana',
    Bhiwani: 'Haryana',
    Bahadurgarh: 'Haryana',
    Jind: 'Haryana',
    Sirsa: 'Haryana',
    Thanesar: 'Haryana',
    Kaithal: 'Haryana',
    Palwal: 'Haryana',
    Rewari: 'Haryana',
    Hansi: 'Haryana',
    Narnaul: 'Haryana',
    Fatehabad: 'Haryana',
    Gohana: 'Haryana',
    Tohana: 'Haryana',
    Narwana: 'Haryana',
    'Mandi Dabwali': 'Haryana',
    'Charkhi Dadri': 'Haryana',
    Shahbad: 'Haryana',
    Pehowa: 'Haryana',
    Samalkha: 'Haryana',
    Pinjore: 'Haryana',
    Ladwa: 'Haryana',
    Sohna: 'Haryana',
    Safidon: 'Haryana',
    Taraori: 'Haryana',
    Mahendragarh: 'Haryana',
    Ratia: 'Haryana',
    Rania: 'Haryana',
    Sarsod: 'Haryana',
    Chennai: 'Tamil Nadu',
    Coimbatore: 'Tamil Nadu',
    Madurai: 'Tamil Nadu',
    Tiruchirappalli: 'Tamil Nadu',
    Salem: 'Tamil Nadu',
    Tirunelveli: 'Tamil Nadu',
    Tiruppur: 'Tamil Nadu',
    Ranipet: 'Tamil Nadu',
    Nagercoil: 'Tamil Nadu',
    Thanjavur: 'Tamil Nadu',
    Vellore: 'Tamil Nadu',
    Kancheepuram: 'Tamil Nadu',
    Erode: 'Tamil Nadu',
    Tiruvannamalai: 'Tamil Nadu',
    Pollachi: 'Tamil Nadu',
    Rajapalayam: 'Tamil Nadu',
    Sivakasi: 'Tamil Nadu',
    Pudukkottai: 'Tamil Nadu',
    'Neyveli (TS)': 'Tamil Nadu',
    Nagapattinam: 'Tamil Nadu',
    Viluppuram: 'Tamil Nadu',
    Tiruchengode: 'Tamil Nadu',
    Vaniyambadi: 'Tamil Nadu',
    'Theni Allinagaram': 'Tamil Nadu',
    Udhagamandalam: 'Tamil Nadu',
    Aruppukkottai: 'Tamil Nadu',
    Paramakudi: 'Tamil Nadu',
    Arakkonam: 'Tamil Nadu',
    Virudhachalam: 'Tamil Nadu',
    Srivilliputhur: 'Tamil Nadu',
    Tindivanam: 'Tamil Nadu',
    Virudhunagar: 'Tamil Nadu',
    Karur: 'Tamil Nadu',
    Valparai: 'Tamil Nadu',
    Sankarankovil: 'Tamil Nadu',
    Tenkasi: 'Tamil Nadu',
    Palani: 'Tamil Nadu',
    Pattukkottai: 'Tamil Nadu',
    Tirupathur: 'Tamil Nadu',
    Ramanathapuram: 'Tamil Nadu',
    Udumalaipettai: 'Tamil Nadu',
    Gobichettipalayam: 'Tamil Nadu',
    Thiruvarur: 'Tamil Nadu',
    Thiruvallur: 'Tamil Nadu',
    Panruti: 'Tamil Nadu',
    Namakkal: 'Tamil Nadu',
    Thirumangalam: 'Tamil Nadu',
    Vikramasingapuram: 'Tamil Nadu',
    Nellikuppam: 'Tamil Nadu',
    Rasipuram: 'Tamil Nadu',
    Tiruttani: 'Tamil Nadu',
    'Nandivaram-Guduvancheri': 'Tamil Nadu',
    Periyakulam: 'Tamil Nadu',
    Pernampattu: 'Tamil Nadu',
    Vellakoil: 'Tamil Nadu',
    Sivaganga: 'Tamil Nadu',
    Vadalur: 'Tamil Nadu',
    Rameshwaram: 'Tamil Nadu',
    Tiruvethipuram: 'Tamil Nadu',
    Perambalur: 'Tamil Nadu',
    Usilampatti: 'Tamil Nadu',
    Vedaranyam: 'Tamil Nadu',
    Sathyamangalam: 'Tamil Nadu',
    Puliyankudi: 'Tamil Nadu',
    Nanjikottai: 'Tamil Nadu',
    Thuraiyur: 'Tamil Nadu',
    Sirkali: 'Tamil Nadu',
    Tiruchendur: 'Tamil Nadu',
    Periyasemur: 'Tamil Nadu',
    Sattur: 'Tamil Nadu',
    Vandavasi: 'Tamil Nadu',
    Tharamangalam: 'Tamil Nadu',
    Tirukkoyilur: 'Tamil Nadu',
    Oddanchatram: 'Tamil Nadu',
    Palladam: 'Tamil Nadu',
    Vadakkuvalliyur: 'Tamil Nadu',
    Tirukalukundram: 'Tamil Nadu',
    Uthamapalayam: 'Tamil Nadu',
    Surandai: 'Tamil Nadu',
    Sankari: 'Tamil Nadu',
    Shenkottai: 'Tamil Nadu',
    Vadipatti: 'Tamil Nadu',
    Sholingur: 'Tamil Nadu',
    Manachanallur: 'Tamil Nadu',
    Viswanatham: 'Tamil Nadu',
    Polur: 'Tamil Nadu',
    Panagudi: 'Tamil Nadu',
    Uthiramerur: 'Tamil Nadu',
    Thiruthuraipoondi: 'Tamil Nadu',
    Pallapatti: 'Tamil Nadu',
    Ponneri: 'Tamil Nadu',
    Lalgudi: 'Tamil Nadu',
    Natham: 'Tamil Nadu',
    Unnamalaikadai: 'Tamil Nadu',
    'P.N.Patti': 'Tamil Nadu',
    Tharangambadi: 'Tamil Nadu',
    Tittakudi: 'Tamil Nadu',
    Pacode: 'Tamil Nadu',
    "O' Valley": 'Tamil Nadu',
    Suriyampalayam: 'Tamil Nadu',
    Sholavandan: 'Tamil Nadu',
    Thammampatti: 'Tamil Nadu',
    Namagiripettai: 'Tamil Nadu',
    Peravurani: 'Tamil Nadu',
    Parangipettai: 'Tamil Nadu',
    Pudupattinam: 'Tamil Nadu',
    Pallikonda: 'Tamil Nadu',
    Sivagiri: 'Tamil Nadu',
    Punjaipugalur: 'Tamil Nadu',
    Padmanabhapuram: 'Tamil Nadu',
    Thirupuvanam: 'Tamil Nadu',
    Indore: 'Madhya Pradesh',
    Bhopal: 'Madhya Pradesh',
    Jabalpur: 'Madhya Pradesh',
    Gwalior: 'Madhya Pradesh',
    Ujjain: 'Madhya Pradesh',
    Sagar: 'Madhya Pradesh',
    Ratlam: 'Madhya Pradesh',
    Satna: 'Madhya Pradesh',
    'Murwara (Katni)': 'Madhya Pradesh',
    Morena: 'Madhya Pradesh',
    Singrauli: 'Madhya Pradesh',
    Rewa: 'Madhya Pradesh',
    Vidisha: 'Madhya Pradesh',
    Ganjbasoda: 'Madhya Pradesh',
    Shivpuri: 'Madhya Pradesh',
    Mandsaur: 'Madhya Pradesh',
    Neemuch: 'Madhya Pradesh',
    Nagda: 'Madhya Pradesh',
    Itarsi: 'Madhya Pradesh',
    Sarni: 'Madhya Pradesh',
    Sehore: 'Madhya Pradesh',
    'Mhow Cantonment': 'Madhya Pradesh',
    Seoni: 'Madhya Pradesh',
    Balaghat: 'Madhya Pradesh',
    'Ashok Nagar': 'Madhya Pradesh',
    Tikamgarh: 'Madhya Pradesh',
    Shahdol: 'Madhya Pradesh',
    Pithampur: 'Madhya Pradesh',
    Alirajpur: 'Madhya Pradesh',
    Mandla: 'Madhya Pradesh',
    Sheopur: 'Madhya Pradesh',
    Shajapur: 'Madhya Pradesh',
    Panna: 'Madhya Pradesh',
    'Raghogarh-Vijaypur': 'Madhya Pradesh',
    Sendhwa: 'Madhya Pradesh',
    Sidhi: 'Madhya Pradesh',
    Pipariya: 'Madhya Pradesh',
    Shujalpur: 'Madhya Pradesh',
    Sironj: 'Madhya Pradesh',
    Pandhurna: 'Madhya Pradesh',
    Nowgong: 'Madhya Pradesh',
    Mandideep: 'Madhya Pradesh',
    Sihora: 'Madhya Pradesh',
    Raisen: 'Madhya Pradesh',
    Lahar: 'Madhya Pradesh',
    Maihar: 'Madhya Pradesh',
    Sanawad: 'Madhya Pradesh',
    Sabalgarh: 'Madhya Pradesh',
    Umaria: 'Madhya Pradesh',
    Porsa: 'Madhya Pradesh',
    Narsinghgarh: 'Madhya Pradesh',
    'Malaj Khand': 'Madhya Pradesh',
    Sarangpur: 'Madhya Pradesh',
    Mundi: 'Madhya Pradesh',
    Nepanagar: 'Madhya Pradesh',
    Pasan: 'Madhya Pradesh',
    Mahidpur: 'Madhya Pradesh',
    'Seoni-Malwa': 'Madhya Pradesh',
    Rehli: 'Madhya Pradesh',
    Manawar: 'Madhya Pradesh',
    Rahatgarh: 'Madhya Pradesh',
    Panagar: 'Madhya Pradesh',
    'Wara Seoni': 'Madhya Pradesh',
    Tarana: 'Madhya Pradesh',
    Sausar: 'Madhya Pradesh',
    Rajgarh: 'Madhya Pradesh',
    Niwari: 'Madhya Pradesh',
    Mauganj: 'Madhya Pradesh',
    Manasa: 'Madhya Pradesh',
    Nainpur: 'Madhya Pradesh',
    Prithvipur: 'Madhya Pradesh',
    Sohagpur: 'Madhya Pradesh',
    'Nowrozabad (Khodargama)': 'Madhya Pradesh',
    Shamgarh: 'Madhya Pradesh',
    Maharajpur: 'Madhya Pradesh',
    Multai: 'Madhya Pradesh',
    Pali: 'Rajasthan',
    Pachore: 'Madhya Pradesh',
    Rau: 'Madhya Pradesh',
    Mhowgaon: 'Madhya Pradesh',
    Vijaypur: 'Madhya Pradesh',
    Dhanbad: 'Jharkhand',
    Ranchi: 'Jharkhand',
    Jamshedpur: 'Jharkhand',
    'Bokaro Steel City': 'Jharkhand',
    Deoghar: 'Jharkhand',
    Phusro: 'Jharkhand',
    Adityapur: 'Jharkhand',
    Hazaribag: 'Jharkhand',
    Giridih: 'Jharkhand',
    Ramgarh: 'Jharkhand',
    'Jhumri Tilaiya': 'Jharkhand',
    Saunda: 'Jharkhand',
    Sahibganj: 'Jharkhand',
    'Medininagar (Daltonganj)': 'Jharkhand',
    Chaibasa: 'Jharkhand',
    Chatra: 'Jharkhand',
    Gumia: 'Jharkhand',
    Dumka: 'Jharkhand',
    Madhupur: 'Jharkhand',
    Chirkunda: 'Jharkhand',
    Pakaur: 'Jharkhand',
    Simdega: 'Jharkhand',
    Musabani: 'Jharkhand',
    Mihijam: 'Jharkhand',
    Patratu: 'Jharkhand',
    Lohardaga: 'Jharkhand',
    'Tenu dam-cum-Kathhara': 'Jharkhand',
    Aizawl: 'Mizoram',
    Lunglei: 'Mizoram',
    Saiha: 'Mizoram',
    Dimapur: 'Nagaland',
    Kohima: 'Nagaland',
    Zunheboto: 'Nagaland',
    Tuensang: 'Nagaland',
    Wokha: 'Nagaland',
    Mokokchung: 'Nagaland',
    Shimla: 'Himachal Pradesh',
    Mandi: 'Himachal Pradesh',
    Solan: 'Himachal Pradesh',
    Nahan: 'Himachal Pradesh',
    Sundarnagar: 'Himachal Pradesh',
    Palampur: 'Himachal Pradesh',
    Kullu: 'Himachal Pradesh',
    Agartala: 'Tripura',
    Udaipur: 'Rajasthan',
    Dharmanagar: 'Tripura',
    Pratapgarh: 'Rajasthan',
    Kailasahar: 'Tripura',
    Belonia: 'Tripura',
    Khowai: 'Tripura',
    Visakhapatnam: 'Andhra Pradesh',
    Vijayawada: 'Andhra Pradesh',
    Guntur: 'Andhra Pradesh',
    Nellore: 'Andhra Pradesh',
    Kurnool: 'Andhra Pradesh',
    Rajahmundry: 'Andhra Pradesh',
    Kakinada: 'Andhra Pradesh',
    Tirupati: 'Andhra Pradesh',
    Anantapur: 'Andhra Pradesh',
    Kadapa: 'Andhra Pradesh',
    Vizianagaram: 'Andhra Pradesh',
    Eluru: 'Andhra Pradesh',
    Ongole: 'Andhra Pradesh',
    Nandyal: 'Andhra Pradesh',
    Machilipatnam: 'Andhra Pradesh',
    Adoni: 'Andhra Pradesh',
    Tenali: 'Andhra Pradesh',
    Chittoor: 'Andhra Pradesh',
    Hindupur: 'Andhra Pradesh',
    Proddatur: 'Andhra Pradesh',
    Bhimavaram: 'Andhra Pradesh',
    Madanapalle: 'Andhra Pradesh',
    Guntakal: 'Andhra Pradesh',
    Dharmavaram: 'Andhra Pradesh',
    Gudivada: 'Andhra Pradesh',
    Srikakulam: 'Andhra Pradesh',
    Narasaraopet: 'Andhra Pradesh',
    Rajampet: 'Andhra Pradesh',
    Tadpatri: 'Andhra Pradesh',
    Tadepalligudem: 'Andhra Pradesh',
    Chilakaluripet: 'Andhra Pradesh',
    Yemmiganur: 'Andhra Pradesh',
    Kadiri: 'Andhra Pradesh',
    Chirala: 'Andhra Pradesh',
    Anakapalle: 'Andhra Pradesh',
    Kavali: 'Andhra Pradesh',
    Palacole: 'Andhra Pradesh',
    Sullurpeta: 'Andhra Pradesh',
    Tanuku: 'Andhra Pradesh',
    Rayachoti: 'Andhra Pradesh',
    Srikalahasti: 'Andhra Pradesh',
    Bapatla: 'Andhra Pradesh',
    Naidupet: 'Andhra Pradesh',
    Nagari: 'Andhra Pradesh',
    Gudur: 'Andhra Pradesh',
    Vinukonda: 'Andhra Pradesh',
    Narasapuram: 'Andhra Pradesh',
    Nuzvid: 'Andhra Pradesh',
    Markapur: 'Andhra Pradesh',
    Ponnur: 'Andhra Pradesh',
    Kandukur: 'Andhra Pradesh',
    Bobbili: 'Andhra Pradesh',
    Rayadurg: 'Andhra Pradesh',
    Samalkot: 'Andhra Pradesh',
    Jaggaiahpet: 'Andhra Pradesh',
    Tuni: 'Andhra Pradesh',
    Amalapuram: 'Andhra Pradesh',
    Bheemunipatnam: 'Andhra Pradesh',
    Venkatagiri: 'Andhra Pradesh',
    Sattenapalle: 'Andhra Pradesh',
    Pithapuram: 'Andhra Pradesh',
    'Palasa Kasibugga': 'Andhra Pradesh',
    Parvathipuram: 'Andhra Pradesh',
    Macherla: 'Andhra Pradesh',
    Gooty: 'Andhra Pradesh',
    Salur: 'Andhra Pradesh',
    Mandapeta: 'Andhra Pradesh',
    Jammalamadugu: 'Andhra Pradesh',
    Peddapuram: 'Andhra Pradesh',
    Punganur: 'Andhra Pradesh',
    Nidadavole: 'Andhra Pradesh',
    Repalle: 'Andhra Pradesh',
    Ramachandrapuram: 'Andhra Pradesh',
    Kovvur: 'Andhra Pradesh',
    Tiruvuru: 'Andhra Pradesh',
    Uravakonda: 'Andhra Pradesh',
    Narsipatnam: 'Andhra Pradesh',
    Yerraguntla: 'Andhra Pradesh',
    Pedana: 'Andhra Pradesh',
    Puttur: 'Karnataka',
    Renigunta: 'Andhra Pradesh',
    Rajam: 'Andhra Pradesh',
    'Srisailam Project (Right Flank Colony) Township': 'Andhra Pradesh',
    Ludhiana: 'Punjab',
    Patiala: 'Punjab',
    Amritsar: 'Punjab',
    Jalandhar: 'Punjab',
    Bathinda: 'Punjab',
    Pathankot: 'Punjab',
    Hoshiarpur: 'Punjab',
    Batala: 'Punjab',
    Moga: 'Punjab',
    Malerkotla: 'Punjab',
    Khanna: 'Punjab',
    Mohali: 'Punjab',
    Barnala: 'Punjab',
    Firozpur: 'Punjab',
    Phagwara: 'Punjab',
    Kapurthala: 'Punjab',
    Zirakpur: 'Punjab',
    'Kot Kapura': 'Punjab',
    Faridkot: 'Punjab',
    Muktsar: 'Punjab',
    Rajpura: 'Punjab',
    Sangrur: 'Punjab',
    Fazilka: 'Punjab',
    Gurdaspur: 'Punjab',
    Kharar: 'Punjab',
    Gobindgarh: 'Punjab',
    Mansa: 'Gujarat',
    Malout: 'Punjab',
    Nabha: 'Punjab',
    'Tarn Taran': 'Punjab',
    Jagraon: 'Punjab',
    Sunam: 'Punjab',
    Dhuri: 'Punjab',
    'Firozpur Cantt.': 'Punjab',
    'Sirhind Fatehgarh Sahib': 'Punjab',
    Rupnagar: 'Punjab',
    'Jalandhar Cantt.': 'Punjab',
    Samana: 'Punjab',
    Nawanshahr: 'Punjab',
    'Rampura Phul': 'Punjab',
    Nangal: 'Punjab',
    Nakodar: 'Punjab',
    Zira: 'Punjab',
    Patti: 'Punjab',
    Raikot: 'Punjab',
    Longowal: 'Punjab',
    'Urmar Tanda': 'Punjab',
    'Morinda, India': 'Punjab',
    Phillaur: 'Punjab',
    Pattran: 'Punjab',
    Qadian: 'Punjab',
    Sujanpur: 'Punjab',
    Mukerian: 'Punjab',
    Talwara: 'Punjab',
    Chandigarh: 'Chandigarh',
    Jaipur: 'Rajasthan',
    Jodhpur: 'Rajasthan',
    Bikaner: 'Rajasthan',
    Ajmer: 'Rajasthan',
    Bhilwara: 'Rajasthan',
    Alwar: 'Rajasthan',
    Bharatpur: 'Rajasthan',
    Barmer: 'Rajasthan',
    Sikar: 'Rajasthan',
    Tonk: 'Rajasthan',
    Sadulpur: 'Rajasthan',
    'Sawai Madhopur': 'Rajasthan',
    Nagaur: 'Rajasthan',
    Makrana: 'Rajasthan',
    Sujangarh: 'Rajasthan',
    Sardarshahar: 'Rajasthan',
    Ladnu: 'Rajasthan',
    Ratangarh: 'Rajasthan',
    Nokha: 'Bihar',
    Nimbahera: 'Rajasthan',
    Suratgarh: 'Rajasthan',
    Rajsamand: 'Rajasthan',
    Lachhmangarh: 'Rajasthan',
    'Rajgarh (Churu)': 'Rajasthan',
    Nasirabad: 'Rajasthan',
    Nohar: 'Rajasthan',
    Phalodi: 'Rajasthan',
    Nathdwara: 'Rajasthan',
    Pilani: 'Rajasthan',
    'Merta City': 'Rajasthan',
    Sojat: 'Rajasthan',
    'Neem-Ka-Thana': 'Rajasthan',
    Sirohi: 'Rajasthan',
    Rawatbhata: 'Rajasthan',
    Sangaria: 'Rajasthan',
    Lalsot: 'Rajasthan',
    Pilibanga: 'Rajasthan',
    'Pipar City': 'Rajasthan',
    Taranagar: 'Rajasthan',
    'Vijainagar, Ajmer': 'Rajasthan',
    Sumerpur: 'Uttar Pradesh',
    Sagwara: 'Rajasthan',
    'Ramganj Mandi': 'Rajasthan',
    Lakheri: 'Rajasthan',
    Udaipurwati: 'Rajasthan',
    Losal: 'Rajasthan',
    'Sri Madhopur': 'Rajasthan',
    Ramngarh: 'Rajasthan',
    Rawatsar: 'Rajasthan',
    Rajakhera: 'Rajasthan',
    Shahpura: 'Rajasthan',
    Raisinghnagar: 'Rajasthan',
    Malpura: 'Rajasthan',
    Nadbai: 'Rajasthan',
    Sanchore: 'Rajasthan',
    Nagar: 'Rajasthan',
    'Rajgarh (Alwar)': 'Rajasthan',
    Sheoganj: 'Rajasthan',
    Sadri: 'Rajasthan',
    Todaraisingh: 'Rajasthan',
    Todabhim: 'Rajasthan',
    Reengus: 'Rajasthan',
    Rajaldesar: 'Rajasthan',
    Sadulshahar: 'Rajasthan',
    Sambhar: 'Rajasthan',
    Prantij: 'Rajasthan',
    'Mount Abu': 'Rajasthan',
    Mangrol: 'Gujarat',
    Phulera: 'Rajasthan',
    Mandawa: 'Rajasthan',
    Pindwara: 'Rajasthan',
    Mandalgarh: 'Rajasthan',
    Takhatgarh: 'Rajasthan',
    Guwahati: 'Assam',
    Silchar: 'Assam',
    Dibrugarh: 'Assam',
    Nagaon: 'Assam',
    Tinsukia: 'Assam',
    Jorhat: 'Assam',
    'Bongaigaon City': 'Assam',
    Dhubri: 'Assam',
    Diphu: 'Assam',
    'North Lakhimpur': 'Assam',
    Tezpur: 'Assam',
    Karimganj: 'Assam',
    Sibsagar: 'Assam',
    Goalpara: 'Assam',
    Barpeta: 'Assam',
    Lanka: 'Assam',
    Lumding: 'Assam',
    Mankachar: 'Assam',
    Nalbari: 'Assam',
    Rangia: 'Assam',
    Margherita: 'Assam',
    Mangaldoi: 'Assam',
    Silapathar: 'Assam',
    Mariani: 'Assam',
    Marigaon: 'Assam',
    Bhubaneswar: 'Odisha',
    Cuttack: 'Odisha',
    Raurkela: 'Odisha',
    Brahmapur: 'Odisha',
    Sambalpur: 'Odisha',
    Puri: 'Odisha',
    'Baleshwar Town': 'Odisha',
    'Baripada Town': 'Odisha',
    Bhadrak: 'Odisha',
    Balangir: 'Odisha',
    Jharsuguda: 'Odisha',
    Bargarh: 'Odisha',
    Paradip: 'Odisha',
    Bhawanipatna: 'Odisha',
    Dhenkanal: 'Odisha',
    Barbil: 'Odisha',
    Kendujhar: 'Odisha',
    Sunabeda: 'Odisha',
    Rayagada: 'Odisha',
    Jatani: 'Odisha',
    Byasanagar: 'Odisha',
    Kendrapara: 'Odisha',
    Rajagangapur: 'Odisha',
    Parlakhemundi: 'Odisha',
    Talcher: 'Odisha',
    Sundargarh: 'Odisha',
    Phulabani: 'Odisha',
    Pattamundai: 'Odisha',
    Titlagarh: 'Odisha',
    Nabarangapur: 'Odisha',
    Soro: 'Odisha',
    Malkangiri: 'Odisha',
    Rairangpur: 'Odisha',
    Tarbha: 'Odisha',
    Raipur: 'Chhattisgarh',
    'Bhilai Nagar': 'Chhattisgarh',
    Korba: 'Chhattisgarh',
    Bilaspur: 'Chhattisgarh',
    Durg: 'Chhattisgarh',
    Rajnandgaon: 'Chhattisgarh',
    Jagdalpur: 'Chhattisgarh',
    Raigarh: 'Chhattisgarh',
    Ambikapur: 'Chhattisgarh',
    Mahasamund: 'Chhattisgarh',
    Dhamtari: 'Chhattisgarh',
    Chirmiri: 'Chhattisgarh',
    Bhatapara: 'Chhattisgarh',
    'Dalli-Rajhara': 'Chhattisgarh',
    'Naila Janjgir': 'Chhattisgarh',
    'Tilda Newra': 'Chhattisgarh',
    Mungeli: 'Chhattisgarh',
    Manendragarh: 'Chhattisgarh',
    Sakti: 'Chhattisgarh',
    Srinagar: 'Uttarakhand',
    Jammu: 'Jammu and Kashmir',
    Baramula: 'Jammu and Kashmir',
    Anantnag: 'Jammu and Kashmir',
    Sopore: 'Jammu and Kashmir',
    'KathUrban Agglomeration': 'Jammu and Kashmir',
    Rajauri: 'Jammu and Kashmir',
    Punch: 'Jammu and Kashmir',
    Udhampur: 'Jammu and Kashmir',
    Bengaluru: 'Karnataka',
    'Hubli-Dharwad': 'Karnataka',
    Belagavi: 'Karnataka',
    Mangaluru: 'Karnataka',
    Davanagere: 'Karnataka',
    Ballari: 'Karnataka',
    Mysore: 'Karnataka',
    Tumkur: 'Karnataka',
    Shivamogga: 'Karnataka',
    Raayachuru: 'Karnataka',
    'Robertson Pet': 'Karnataka',
    Kolar: 'Karnataka',
    Mandya: 'Karnataka',
    Udupi: 'Karnataka',
    Chikkamagaluru: 'Karnataka',
    Karwar: 'Karnataka',
    Ranebennuru: 'Karnataka',
    Ranibennur: 'Karnataka',
    Ramanagaram: 'Karnataka',
    Gokak: 'Karnataka',
    Yadgir: 'Karnataka',
    'Rabkavi Banhatti': 'Karnataka',
    Shahabad: 'Karnataka',
    Sirsi: 'Uttar Pradesh',
    Sindhnur: 'Karnataka',
    Tiptur: 'Karnataka',
    Arsikere: 'Karnataka',
    Nanjangud: 'Karnataka',
    Sagara: 'Karnataka',
    Sira: 'Karnataka',
    Athni: 'Karnataka',
    Mulbagal: 'Karnataka',
    Surapura: 'Karnataka',
    Siruguppa: 'Karnataka',
    Mudhol: 'Karnataka',
    Sidlaghatta: 'Karnataka',
    Shahpur: 'Karnataka',
    'Saundatti-Yellamma': 'Karnataka',
    Wadi: 'Karnataka',
    Manvi: 'Karnataka',
    Nelamangala: 'Karnataka',
    Lakshmeshwar: 'Karnataka',
    Ramdurg: 'Karnataka',
    Nargund: 'Karnataka',
    Tarikere: 'Karnataka',
    Malavalli: 'Karnataka',
    Savanur: 'Karnataka',
    Lingsugur: 'Karnataka',
    Vijayapura: 'Karnataka',
    Sankeshwara: 'Karnataka',
    Madikeri: 'Karnataka',
    Talikota: 'Karnataka',
    Sedam: 'Karnataka',
    Shikaripur: 'Karnataka',
    Mahalingapura: 'Karnataka',
    Mudalagi: 'Karnataka',
    Muddebihal: 'Karnataka',
    Pavagada: 'Karnataka',
    Malur: 'Karnataka',
    Sindhagi: 'Karnataka',
    Sanduru: 'Karnataka',
    Afzalpur: 'Karnataka',
    Maddur: 'Karnataka',
    Madhugiri: 'Karnataka',
    Tekkalakote: 'Karnataka',
    Terdal: 'Karnataka',
    Mudabidri: 'Karnataka',
    Magadi: 'Karnataka',
    Navalgund: 'Karnataka',
    Shiggaon: 'Karnataka',
    Shrirangapattana: 'Karnataka',
    Sindagi: 'Karnataka',
    Sakaleshapura: 'Karnataka',
    Srinivaspur: 'Karnataka',
    Ron: 'Karnataka',
    Mundargi: 'Karnataka',
    Sadalagi: 'Karnataka',
    Piriyapatna: 'Karnataka',
    Adyar: 'Karnataka',
    Imphal: 'Manipur',
    Thoubal: 'Manipur',
    Lilong: 'Manipur',
    'Mayang Imphal': 'Manipur',
    Thiruvananthapuram: 'Kerala',
    Kochi: 'Kerala',
    Kozhikode: 'Kerala',
    Kollam: 'Kerala',
    Thrissur: 'Kerala',
    Palakkad: 'Kerala',
    Alappuzha: 'Kerala',
    Malappuram: 'Kerala',
    Ponnani: 'Kerala',
    Vatakara: 'Kerala',
    Kanhangad: 'Kerala',
    Taliparamba: 'Kerala',
    Koyilandy: 'Kerala',
    Neyyattinkara: 'Kerala',
    Kayamkulam: 'Kerala',
    Nedumangad: 'Kerala',
    Kannur: 'Kerala',
    Tirur: 'Kerala',
    Kottayam: 'Kerala',
    Kasaragod: 'Kerala',
    Kunnamkulam: 'Kerala',
    Ottappalam: 'Kerala',
    Thiruvalla: 'Kerala',
    Thodupuzha: 'Kerala',
    Chalakudy: 'Kerala',
    Changanassery: 'Kerala',
    Punalur: 'Kerala',
    Nilambur: 'Kerala',
    Cherthala: 'Kerala',
    Perinthalmanna: 'Kerala',
    Mattannur: 'Kerala',
    Shoranur: 'Kerala',
    Varkala: 'Kerala',
    Paravoor: 'Kerala',
    Pathanamthitta: 'Kerala',
    Peringathur: 'Kerala',
    Attingal: 'Kerala',
    Kodungallur: 'Kerala',
    Pappinisseri: 'Kerala',
    'Chittur-Thathamangalam': 'Kerala',
    Muvattupuzha: 'Kerala',
    Adoor: 'Kerala',
    Mavelikkara: 'Kerala',
    Mavoor: 'Kerala',
    Perumbavoor: 'Kerala',
    Vaikom: 'Kerala',
    Palai: 'Kerala',
    Panniyannur: 'Kerala',
    Guruvayoor: 'Kerala',
    Puthuppally: 'Kerala',
    Panamattom: 'Kerala',
    Delhi: 'Delhi',
    'New Delhi': 'Delhi',
    Silvassa: 'Dadra and Nagar Haveli',
    Pondicherry: 'Puducherry',
    Karaikal: 'Puducherry',
    Yanam: 'Puducherry',
    Mahe: 'Puducherry',
    Dehradun: 'Uttarakhand',
    Hardwar: 'Uttarakhand',
    'Haldwani-cum-Kathgodam': 'Uttarakhand',
    Kashipur: 'Uttarakhand',
    Roorkee: 'Uttarakhand',
    Rudrapur: 'Uttar Pradesh',
    Rishikesh: 'Uttarakhand',
    Ramnagar: 'Bihar',
    Pithoragarh: 'Uttarakhand',
    Manglaur: 'Uttarakhand',
    Nainital: 'Uttarakhand',
    Mussoorie: 'Uttarakhand',
    Tehri: 'Uttarakhand',
    Pauri: 'Uttarakhand',
    Nagla: 'Uttarakhand',
    Sitarganj: 'Uttarakhand',
    Bageshwar: 'Uttarakhand',
    Lucknow: 'Uttar Pradesh',
    Kanpur: 'Uttar Pradesh',
    Firozabad: 'Uttar Pradesh',
    Agra: 'Uttar Pradesh',
    Meerut: 'Uttar Pradesh',
    Varanasi: 'Uttar Pradesh',
    Allahabad: 'Uttar Pradesh',
    Amroha: 'Uttar Pradesh',
    Moradabad: 'Uttar Pradesh',
    Aligarh: 'Uttar Pradesh',
    Saharanpur: 'Uttar Pradesh',
    Noida: 'Uttar Pradesh',
    Loni: 'Uttar Pradesh',
    Jhansi: 'Uttar Pradesh',
    Shahjahanpur: 'Uttar Pradesh',
    Rampur: 'Uttar Pradesh',
    Modinagar: 'Uttar Pradesh',
    Hapur: 'Uttar Pradesh',
    Etawah: 'Uttar Pradesh',
    Sambhal: 'Uttar Pradesh',
    Orai: 'Uttar Pradesh',
    Bahraich: 'Uttar Pradesh',
    Unnao: 'Uttar Pradesh',
    'Rae Bareli': 'Uttar Pradesh',
    Lakhimpur: 'Uttar Pradesh',
    Sitapur: 'Uttar Pradesh',
    Lalitpur: 'Uttar Pradesh',
    Pilibhit: 'Uttar Pradesh',
    Chandausi: 'Uttar Pradesh',
    'Hardoi ': 'Uttar Pradesh',
    Azamgarh: 'Uttar Pradesh',
    Khair: 'Uttar Pradesh',
    Sultanpur: 'Uttar Pradesh',
    Tanda: 'Uttar Pradesh',
    Nagina: 'Uttar Pradesh',
    Shamli: 'Uttar Pradesh',
    Najibabad: 'Uttar Pradesh',
    Shikohabad: 'Uttar Pradesh',
    Sikandrabad: 'Uttar Pradesh',
    'Shahabad, Hardoi': 'Uttar Pradesh',
    Pilkhuwa: 'Uttar Pradesh',
    Renukoot: 'Uttar Pradesh',
    Vrindavan: 'Uttar Pradesh',
    Ujhani: 'Uttar Pradesh',
    Laharpur: 'Uttar Pradesh',
    Tilhar: 'Uttar Pradesh',
    Sahaswan: 'Uttar Pradesh',
    Rath: 'Uttar Pradesh',
    Sherkot: 'Uttar Pradesh',
    Kalpi: 'Uttar Pradesh',
    Tundla: 'Uttar Pradesh',
    Sandila: 'Uttar Pradesh',
    Nanpara: 'Uttar Pradesh',
    Sardhana: 'Uttar Pradesh',
    Nehtaur: 'Uttar Pradesh',
    Seohara: 'Uttar Pradesh',
    Padrauna: 'Uttar Pradesh',
    Mathura: 'Uttar Pradesh',
    Thakurdwara: 'Uttar Pradesh',
    Nawabganj: 'Uttar Pradesh',
    Siana: 'Uttar Pradesh',
    Noorpur: 'Uttar Pradesh',
    'Sikandra Rao': 'Uttar Pradesh',
    Puranpur: 'Uttar Pradesh',
    Rudauli: 'Uttar Pradesh',
    'Thana Bhawan': 'Uttar Pradesh',
    'Palia Kalan': 'Uttar Pradesh',
    Zaidpur: 'Uttar Pradesh',
    Nautanwa: 'Uttar Pradesh',
    Zamania: 'Uttar Pradesh',
    'Shikarpur, Bulandshahr': 'Uttar Pradesh',
    'Naugawan Sadat': 'Uttar Pradesh',
    'Fatehpur Sikri': 'Uttar Pradesh',
    'Shahabad, Rampur': 'Uttar Pradesh',
    Robertsganj: 'Uttar Pradesh',
    Utraula: 'Uttar Pradesh',
    Sadabad: 'Uttar Pradesh',
    Rasra: 'Uttar Pradesh',
    Lar: 'Uttar Pradesh',
    'Lal Gopalganj Nindaura': 'Uttar Pradesh',
    Sirsaganj: 'Uttar Pradesh',
    Pihani: 'Uttar Pradesh',
    'Shamsabad, Agra': 'Uttar Pradesh',
    Soron: 'Uttar Pradesh',
    'SUrban Agglomerationr': 'Uttar Pradesh',
    Samdhan: 'Uttar Pradesh',
    Sahjanwa: 'Uttar Pradesh',
    'Rampur Maniharan': 'Uttar Pradesh',
    Shahganj: 'Uttar Pradesh',
    Tulsipur: 'Uttar Pradesh',
    Tirwaganj: 'Uttar Pradesh',
    'PurqUrban Agglomerationzi': 'Uttar Pradesh',
    'Shamsabad, Farrukhabad': 'Uttar Pradesh',
    Warhapur: 'Uttar Pradesh',
    Powayan: 'Uttar Pradesh',
    Sandi: 'Uttar Pradesh',
    Achhnera: 'Uttar Pradesh',
    Naraura: 'Uttar Pradesh',
    Nakur: 'Uttar Pradesh',
    Sahaspur: 'Uttar Pradesh',
    Safipur: 'Uttar Pradesh',
    Reoti: 'Uttar Pradesh',
    Sikanderpur: 'Uttar Pradesh',
    Saidpur: 'Uttar Pradesh',
    Purwa: 'Uttar Pradesh',
    Parasi: 'Uttar Pradesh',
    Lalganj: 'Bihar',
    Phulpur: 'Uttar Pradesh',
    Shishgarh: 'Uttar Pradesh',
    Sahawar: 'Uttar Pradesh',
    Samthar: 'Uttar Pradesh',
    Pukhrayan: 'Uttar Pradesh',
    Obra: 'Uttar Pradesh',
    Niwai: 'Uttar Pradesh',
    Mirzapur: 'Uttar Pradesh',
    Patna: 'Bihar',
    Gaya: 'Bihar',
    Bhagalpur: 'Bihar',
    Muzaffarpur: 'Bihar',
    Darbhanga: 'Bihar',
    Arrah: 'Bihar',
    Begusarai: 'Bihar',
    Chhapra: 'Gujarat',
    Katihar: 'Bihar',
    Munger: 'Bihar',
    Purnia: 'Bihar',
    Saharsa: 'Bihar',
    Sasaram: 'Bihar',
    Hajipur: 'Bihar',
    'Dehri-on-Sone': 'Bihar',
    Bettiah: 'Bihar',
    Motihari: 'Bihar',
    Bagaha: 'Bihar',
    Siwan: 'Bihar',
    Kishanganj: 'Bihar',
    Jamalpur: 'Bihar',
    Buxar: 'Bihar',
    Jehanabad: 'Bihar',
    Aurangabad: 'Maharashtra',
    Lakhisarai: 'Bihar',
    Nawada: 'Bihar',
    Jamui: 'Bihar',
    Sitamarhi: 'Bihar',
    Araria: 'Bihar',
    Gopalganj: 'Bihar',
    Madhubani: 'Bihar',
    Masaurhi: 'Bihar',
    Samastipur: 'Bihar',
    Mokameh: 'Bihar',
    Supaul: 'Bihar',
    Dumraon: 'Bihar',
    Arwal: 'Bihar',
    Forbesganj: 'Bihar',
    'BhabUrban Agglomeration': 'Bihar',
    Narkatiaganj: 'Bihar',
    Naugachhia: 'Bihar',
    Madhepura: 'Bihar',
    Sheikhpura: 'Bihar',
    Sultanganj: 'Bihar',
    'Raxaul Bazar': 'Bihar',
    'Mahnar Bazar': 'Bihar',
    Warisaliganj: 'Bihar',
    Revelganj: 'Bihar',
    Rajgir: 'Bihar',
    Sonepur: 'Bihar',
    Sherghati: 'Bihar',
    Sugauli: 'Bihar',
    Makhdumpur: 'Bihar',
    Maner: 'Bihar',
    Rosera: 'Bihar',
    Piro: 'Bihar',
    Rafiganj: 'Bihar',
    Marhaura: 'Bihar',
    Mirganj: 'Bihar',
    Murliganj: 'Bihar',
    Motipur: 'Bihar',
    Manihari: 'Bihar',
    Sheohar: 'Bihar',
    Maharajganj: 'Bihar',
    Silao: 'Bihar',
    Barh: 'Bihar',
    Asarganj: 'Bihar',
    Ahmedabad: 'Gujarat',
    Surat: 'Gujarat',
    Vadodara: 'Gujarat',
    Rajkot: 'Gujarat',
    Bhavnagar: 'Gujarat',
    Jamnagar: 'Gujarat',
    Nadiad: 'Gujarat',
    Porbandar: 'Gujarat',
    Anand: 'Gujarat',
    Morvi: 'Gujarat',
    Mahesana: 'Gujarat',
    Bharuch: 'Gujarat',
    Vapi: 'Gujarat',
    Navsari: 'Gujarat',
    Veraval: 'Gujarat',
    Bhuj: 'Gujarat',
    Godhra: 'Gujarat',
    Palanpur: 'Gujarat',
    Valsad: 'Gujarat',
    Patan: 'Gujarat',
    Deesa: 'Gujarat',
    Amreli: 'Gujarat',
    Anjar: 'Gujarat',
    Dhoraji: 'Gujarat',
    Khambhat: 'Gujarat',
    Mahuva: 'Gujarat',
    Keshod: 'Gujarat',
    Wadhwan: 'Gujarat',
    Ankleshwar: 'Gujarat',
    Savarkundla: 'Gujarat',
    Kadi: 'Gujarat',
    Visnagar: 'Gujarat',
    Upleta: 'Gujarat',
    Una: 'Gujarat',
    Sidhpur: 'Gujarat',
    Unjha: 'Gujarat',
    Viramgam: 'Gujarat',
    Modasa: 'Gujarat',
    Palitana: 'Gujarat',
    Petlad: 'Gujarat',
    Kapadvanj: 'Gujarat',
    Sihor: 'Gujarat',
    Wankaner: 'Gujarat',
    Limbdi: 'Gujarat',
    Mandvi: 'Gujarat',
    Thangadh: 'Gujarat',
    Vyara: 'Gujarat',
    Padra: 'Gujarat',
    Lunawada: 'Gujarat',
    Rajpipla: 'Gujarat',
    Umreth: 'Gujarat',
    Sanand: 'Gujarat',
    Rajula: 'Gujarat',
    Radhanpur: 'Gujarat',
    Mahemdabad: 'Gujarat',
    Ranavav: 'Gujarat',
    Tharad: 'Gujarat',
    Umbergaon: 'Gujarat',
    Talaja: 'Gujarat',
    Vadnagar: 'Gujarat',
    Manavadar: 'Gujarat',
    Salaya: 'Gujarat',
    Vijapur: 'Gujarat',
    Pardi: 'Gujarat',
    Rapar: 'Gujarat',
    Songadh: 'Gujarat',
    Lathi: 'Gujarat',
    Adalaj: 'Gujarat',
    Gandhinagar: 'Gujarat',
    Hyderabad: 'Telangana',
    Warangal: 'Telangana',
    Nizamabad: 'Telangana',
    Karimnagar: 'Telangana',
    Ramagundam: 'Telangana',
    Khammam: 'Telangana',
    Mahbubnagar: 'Telangana',
    Mancherial: 'Telangana',
    Adilabad: 'Telangana',
    Suryapet: 'Telangana',
    Jagtial: 'Telangana',
    Miryalaguda: 'Telangana',
    Nirmal: 'Telangana',
    Kamareddy: 'Telangana',
    Kothagudem: 'Telangana',
    Bodhan: 'Telangana',
    Palwancha: 'Telangana',
    Mandamarri: 'Telangana',
    Koratla: 'Telangana',
    Sircilla: 'Telangana',
    Tandur: 'Telangana',
    Siddipet: 'Telangana',
    Wanaparthy: 'Telangana',
    Kagaznagar: 'Telangana',
    Gadwal: 'Telangana',
    Sangareddy: 'Telangana',
    Bellampalle: 'Telangana',
    Bhongir: 'Telangana',
    Vikarabad: 'Telangana',
    Jangaon: 'Telangana',
    Bhadrachalam: 'Telangana',
    Bhainsa: 'Telangana',
    Farooqnagar: 'Telangana',
    Medak: 'Telangana',
    Narayanpet: 'Telangana',
    Sadasivpet: 'Telangana',
    Yellandu: 'Telangana',
    Manuguru: 'Telangana',
    Kyathampalle: 'Telangana',
    Nagarkurnool: 'Telangana',
    Shillong: 'Meghalaya',
    Tura: 'Meghalaya',
    Nongstoin: 'Meghalaya',
    Manali: 'Himachal Praddesh',
    Naharlagun: 'Arunachal Pradesh',
    Pasighat: 'Arunachal Pradesh',
    Mumbai: 'Maharashtra',
    Pune: 'Maharashtra',
    Nagpur: 'Maharashtra',
    Thane: 'Maharashtra',
    Nashik: 'Maharashtra',
    'Kalyan-Dombivali': 'Maharashtra',
    'Vasai-Virar': 'Maharashtra',
    Solapur: 'Maharashtra',
    'Mira-Bhayandar': 'Maharashtra',
    Bhiwandi: 'Maharashtra',
    Amravati: 'Maharashtra',
    'Nanded-Waghala': 'Maharashtra',
    Sangli: 'Maharashtra',
    Malegaon: 'Maharashtra',
    Akola: 'Maharashtra',
    Latur: 'Maharashtra',
    Dhule: 'Maharashtra',
    Ahmednagar: 'Maharashtra',
    Ichalkaranji: 'Maharashtra',
    Parbhani: 'Maharashtra',
    Panvel: 'Maharashtra',
    Yavatmal: 'Maharashtra',
    Achalpur: 'Maharashtra',
    Osmanabad: 'Maharashtra',
    Nandurbar: 'Maharashtra',
    Satara: 'Maharashtra',
    Wardha: 'Maharashtra',
    Udgir: 'Maharashtra',
    Amalner: 'Maharashtra',
    Akot: 'Maharashtra',
    Pandharpur: 'Maharashtra',
    Shrirampur: 'Maharashtra',
    Parli: 'Maharashtra',
    Washim: 'Maharashtra',
    Ambejogai: 'Maharashtra',
    Manmad: 'Maharashtra',
    Ratnagiri: 'Maharashtra',
    'Uran Islampur': 'Maharashtra',
    Pusad: 'Maharashtra',
    Sangamner: 'Maharashtra',
    'Shirpur-Warwade': 'Maharashtra',
    Malkapur: 'Maharashtra',
    Wani: 'Maharashtra',
    Lonavla: 'Maharashtra',
    'Talegaon Dabhade': 'Maharashtra',
    Anjangaon: 'Maharashtra',
    Umred: 'Maharashtra',
    Palghar: 'Maharashtra',
    Shegaon: 'Maharashtra',
    Ozar: 'Maharashtra',
    Phaltan: 'Maharashtra',
    Yevla: 'Maharashtra',
    Shahade: 'Maharashtra',
    Vita: 'Maharashtra',
    Umarkhed: 'Maharashtra',
    Warora: 'Maharashtra',
    Pachora: 'Maharashtra',
    Tumsar: 'Maharashtra',
    Manjlegaon: 'Maharashtra',
    Sillod: 'Maharashtra',
    Arvi: 'Maharashtra',
    Nandura: 'Maharashtra',
    Vaijapur: 'Maharashtra',
    'Wadgaon Road': 'Maharashtra',
    Sailu: 'Maharashtra',
    Murtijapur: 'Maharashtra',
    Tasgaon: 'Maharashtra',
    Mehkar: 'Maharashtra',
    Yawal: 'Maharashtra',
    Pulgaon: 'Maharashtra',
    Nilanga: 'Maharashtra',
    Wai: 'Maharashtra',
    Umarga: 'Maharashtra',
    Paithan: 'Maharashtra',
    Rahuri: 'Maharashtra',
    Nawapur: 'Maharashtra',
    Tuljapur: 'Maharashtra',
    Morshi: 'Maharashtra',
    Purna: 'Maharashtra',
    Satana: 'Maharashtra',
    Pathri: 'Maharashtra',
    Sinnar: 'Maharashtra',
    Uchgaon: 'Maharashtra',
    Uran: 'Maharashtra',
    Pen: 'Maharashtra',
    Karjat: 'Maharashtra',
    Manwath: 'Maharashtra',
    Partur: 'Maharashtra',
    Sangole: 'Maharashtra',
    Mangrulpir: 'Maharashtra',
    Risod: 'Maharashtra',
    Shirur: 'Maharashtra',
    Savner: 'Maharashtra',
    Sasvad: 'Maharashtra',
    Pandharkaoda: 'Maharashtra',
    Talode: 'Maharashtra',
    Shrigonda: 'Maharashtra',
    Shirdi: 'Maharashtra',
    Raver: 'Maharashtra',
    Mukhed: 'Maharashtra',
    Rajura: 'Maharashtra',
    'Vadgaon Kasba': 'Maharashtra',
    Tirora: 'Maharashtra',
    Mahad: 'Maharashtra',
    Lonar: 'Maharashtra',
    Sawantwadi: 'Maharashtra',
    Pathardi: 'Maharashtra',
    Pauni: 'Maharashtra',
    Ramtek: 'Maharashtra',
    Mul: 'Maharashtra',
    Soyagaon: 'Maharashtra',
    Mangalvedhe: 'Maharashtra',
    Narkhed: 'Maharashtra',
    Shendurjana: 'Maharashtra',
    Patur: 'Maharashtra',
    Mhaswad: 'Maharashtra',
    Loha: 'Maharashtra',
    Nandgaon: 'Maharashtra',
    Warud: 'Maharashtra',
    Marmagao: 'Goa',
    Panaji: 'Goa',
    Margao: 'Goa',
    Mapusa: 'Goa',
    Kolkata: 'West Bengal',
    Siliguri: 'West Bengal',
    Asansol: 'West Bengal',
    Raghunathganj: 'West Bengal',
    Kharagpur: 'West Bengal',
    Naihati: 'West Bengal',
    'English Bazar': 'West Bengal',
    Baharampur: 'West Bengal',
    'Hugli-Chinsurah': 'West Bengal',
    Raiganj: 'West Bengal',
    Jalpaiguri: 'West Bengal',
    Santipur: 'West Bengal',
    Balurghat: 'West Bengal',
    Medinipur: 'West Bengal',
    Habra: 'West Bengal',
    Ranaghat: 'West Bengal',
    Bankura: 'West Bengal',
    Nabadwip: 'West Bengal',
    Darjiling: 'West Bengal',
    Purulia: 'West Bengal',
    Arambagh: 'West Bengal',
    Tamluk: 'West Bengal',
    'AlipurdUrban Agglomerationr': 'West Bengal',
    Suri: 'West Bengal',
    Jhargram: 'West Bengal',
    Gangarampur: 'West Bengal',
    Rampurhat: 'West Bengal',
    Kalimpong: 'West Bengal',
    Sainthia: 'West Bengal',
    Taki: 'West Bengal',
    Murshidabad: 'West Bengal',
    Memari: 'West Bengal',
    'Paschim Punropara': 'West Bengal',
    Tarakeswar: 'West Bengal',
    Sonamukhi: 'West Bengal',
    'PandUrban Agglomeration': 'West Bengal',
    Mainaguri: 'West Bengal',
    Malda: 'West Bengal',
    Panchla: 'West Bengal',
    Raghunathpur: 'West Bengal',
    Mathabhanga: 'West Bengal',
    Monoharpur: 'West Bengal',
    Srirampore: 'West Bengal',
    Adra: 'West Bengal'
};

export const VibrationActive = (val: number[]) => {
    if (!navigator.vibrate) return false;
    navigator.vibrate(val);
};

export const SendEvents = (user_data: any, category: string, action: string, label?: string) => {
    if (window.location.hostname.toLowerCase() === 'www.parentune.com') {
        return ReactGA.event({
            category, //impression
            action, // i am mom
            label, //gender pop up visible
            value: Number(user_data ?? 0) // user_data
        });
    }
};

export const TrackGoogleAnalyticsEvent = (
    category: any,
    event_name: any,
    label: any,
    data: any
) => {
    console.log('GA event:', category, ':', event_name, ':', label);

    let event_params = {
        category,
        label,
        ...data
    };
    // Send GA4 Event
    // @ts-ignore
    if (window.location.host.toLowerCase() === 'www.parentune.com') {
        ReactGA.event(event_name, event_params);
    }
};

export function isSocialMediaBrowser() {
    const ua = navigator.userAgent || navigator.vendor;
    return (
        ua.indexOf('FBAN') > -1 ||
        ua.indexOf('FBAV') > -1 ||
        ua.indexOf('Instagram') > -1 ||
        ua.indexOf('LinkedIn') > -1 ||
        ua.indexOf('Firefox') > -1
    );
}

export function isUserSubscribedPlus(user_data: User) {
    // console.log(user_data);
    if (user_data?.isSubscribed === true && typeof user_data?.subscriptionInfo === 'object') {
        return true;
    } else if (
        user_data?.isSubscribed === true &&
        typeof user_data?.subscriptionInfo === 'string' &&
        user_data?.subscriptionInfo === 'plus'
    ) {
        return true;
    } else {
        return false;
    }
}

export function isUserSubscribedPro(user_data: User) {
    if (typeof user_data?.subscriptionInfo === 'string' && user_data?.subscriptionInfo === 'pro') {
        return true;
    } else {
        return false;
    }
}

// export const Tracking_Id = '2390634130';
export const Tracking_Id = 'G-KF05MGQPKP';

// for dev
// trackingId: '6435734847'

export const DefaultUser = 0;

export const HideHeaderInTheseRoutes = [
    'register',
    'how-to-use-parentune',
    'gift/'
    // '/services/short-term-plan',
    // '/workshop/short-term-plan'
];

export const handlePurposeCards = () => {
    const PurposeCardsExistsInLocalStorage = sessionStorage.getItem('PurposeCardsData');

    const PurposeCardsData = {
        'parent-talk': 1,
        'baby-names/all': 1,
        'expert-corner': 1
    };

    if (!PurposeCardsExistsInLocalStorage)
        sessionStorage.setItem('PurposeCardsData', JSON.stringify(PurposeCardsData));
};

export const languages = ['hi', 'ta', 'te', 'mr'];

export const showB2CCardsafterParagraphs = 4;

export function cookieExists(cookieName: any) {
    // Split the cookies string into individual cookies
    const cookies = document.cookie.split(';');

    // Loop through the cookies to find the one with the specified name
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the specified name
        if (cookie.startsWith(cookieName + '=')) {
            return true; // Cookie found
        }
    }

    return false; // Cookie not found
}

export const country_codes_africa = [
    'DZ',
    'AO',
    'BJ',
    'BW',
    'BF',
    'BI',
    'CV',
    'CM',
    'CF',
    'TD',
    'KM',
    'CD',
    'CG',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'SZ',
    'ET',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'CI',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RW',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SS',
    'SD',
    'TZ',
    'TG',
    'TN',
    'UG',
    'ZM',
    'ZW'
];

export const country_codes_africaWithCountry = {
    DZ: 'Algeria',
    AO: 'Angola',
    BJ: 'Benin',
    BW: 'Botswana',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cape Verde',
    CM: 'Cameroon',
    CF: 'Central African Republic',
    TD: 'Chad',
    KM: 'Comoros',
    CD: 'Democratic Republic of the Congo',
    CG: 'Republic of the Congo',
    DJ: 'Djibouti',
    EG: 'Egypt',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    SZ: 'Eswatini (formerly Swaziland)',
    ET: 'Ethiopia',
    GA: 'Gabon',
    GM: 'gambia',
    GH: 'ghana',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    CI: "Ivory Coast (Côte d',Ivoire)",
    KE: 'kenya',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    MG: 'Madagascar',
    MW: 'Malawi',
    ML: 'Mali',
    MR: 'Mauritania',
    MU: 'Mauritius',
    MA: 'Morocco',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NE: 'Niger',
    NG: 'nigeria',
    RW: 'Rwanda',
    ST: 'Sao Tomé and Príncipe',
    SN: 'Senegal',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SO: 'Somalia',
    ZA: 'SouthAfrica',
    SS: 'South Sudan',
    SD: 'Sudan',
    TZ: 'Tanzania',
    TG: 'Togo',
    TN: 'Tunisia',
    UG: 'uganda',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    IN: 'India'
};
export const country_codes_w_phone: { [key: string]: string } = {
    GH: '+233',
    NG: '+234',
    KE: '+254',
    TZ: '+255',
    UG: '+256',
    ZA: '+27',
    IN: '+91',
    US: '+1',
    GB: '+44',
    QA: '+974',
    SG: '+65',
    LK: '+94',
    AU: '+61',
    AE: '+971',
    CA: '+1'
};
export const phoneNumberLengths: { [key: string]: number } = {
    GH: 8, // Ghana
    NG: 10, // Nigeria
    KE: 9, // Kenya
    TZ: 9, // Tanzania
    UG: 9, // Uganda
    ZA: 9, // South Africa
    IN: 10, // India
    US: 10, // United States
    GB: 10, // United Kingdom
    QA: 8, // Qatar
    SG: 8, // Singapore
    LK: 9, // Sri Lanka
    AU: 9, // Australia (for mobile numbers starting with 4)
    AE: 9, // United Arab Emirates
    CA: 10 // Canada
};

export const phoneRegexByCountryList: { [key: string]: RegExp } = {
    GH: /^\+233[2-9][0-9]{7}$/, // Ghana: +233 followed by 8 digits, starting with 2-9
    NG: /^\+234[1-9][0-9]{9}$/, // Nigeria: +234 followed by 10 digits, first digit not zero
    KE: /^\+254[1-9][0-9]{8}$/, // Kenya: +254 followed by 9 digits, first digit not zero
    TZ: /^\+255[1-9][0-9]{8}$/, // Tanzania: +255 followed by 9 digits, first digit not zero
    UG: /^\+256[1-9][0-9]{8}$/, // Uganda: +256 followed by 9 digits, first digit not zero
    ZA: /^\+27[1-9][0-9]{8}$/, // South Africa: +27 followed by 9 digits, first digit not zero
    IN: /^\+91[1-9][0-9]{9}$/, // India: +91 followed by 10 digits, first digit not zero
    US: /^\+1[2-9][0-9]{2}[0-9]{7}$/, // USA: +1 followed by 10 digits, area code not starting with 0 or 1
    GB: /^\+44[1-9][0-9]{9}$/, // UK: +44 followed by 10 digits, first digit not zero
    QA: /^\+974[3-9][0-9]{7}$/, // Qatar: +974 followed by 8 digits, first digit 3-9
    SG: /^\+65[689][0-9]{7}$/, // Singapore: +65 followed by 8 digits, starting with 6, 8, or 9
    LK: /^\+94[1-9][0-9]{8}$/, // Sri Lanka: +94 followed by 9 digits, first digit not zero
    AU: /^\+61[4][0-9]{8}$/, // Australia: +61 followed by 9 digits, mobile numbers start with 4
    AE: /^\+971[1-9][0-9]{8}$/, // UAE: +971 followed by 9 digits, first digit not zero
    CA: /^\+1[2-9][0-9]{2}[0-9]{7}$/ // Canada: +1 followed by 10 digits, area code not starting with 0 or 1
};

export function isLessThanThreeYears(dobStr: string): boolean {
    // Parse the given DOB string to a Date object
    const dob = new Date(dobStr);

    // Get the current date
    const currentDate = new Date();

    // Calculate the age in milliseconds
    const ageInMilliseconds = currentDate.getTime() - dob.getTime();

    // Convert the age to years
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    // Check if the age is less than 3 years
    return ageInYears < 3;
}

export const eventDateConverter = (startDate: any, endDate: any) => {
    if (startDate.endsWith('GMT')) {
        startDate = startDate.replace('GMT', '');
    }
    if (endDate.endsWith('GMT')) {
        endDate = endDate.replace('GMT', '');
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const day = start.toLocaleDateString('en-us', { day: 'numeric' });
    const month = start.toLocaleDateString('en-us', { month: 'long' });

    const time =
        `${day} ${month}` +
        ' | ' +
        hours12(start) +
        ' to ' +
        hours12(end) +
        ' ' +
        formatAMPM(end).toString();
    return time;
};

const hours12 = (date: any) => {
    const hours = (date.getHours() + 24) % 12 || 12;
    const minutes = date.getMinutes();
    if (minutes !== 0) {
        return hours.toString() + ' : ' + minutes.toString();
    }
    return hours.toString();
};

const formatAMPM = (date: any) => {
    const hours = date.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    return ampm;
};

export const enableTheseCountry = ['ZA', 'IN'];

const NEXTJSLOCALROUTE = {
    petnames: 'http://localhost:3000/pet-names',
    ovulation: 'http://localhost:3000/ovulation-calculator'
};

const NEXTJSPRODROUTE = {
    petnames: 'https://www.parentune.com/pet-names',
    ovulation: 'https://www.parentune.com/ovulation-calculator'
};

export const NEXTJSROUTE = {
    petnames:
        process.env.NODE_ENV === 'development'
            ? NEXTJSLOCALROUTE['petnames']
            : NEXTJSPRODROUTE['petnames'],
    ovulation:
        process.env.NODE_ENV === 'development'
            ? NEXTJSLOCALROUTE['ovulation']
            : NEXTJSPRODROUTE['ovulation']
};
