import React, { useState } from 'react';

const InterestTagsList = (props: any) => {
    const { keyx: key } = props;
    const [showAll, setShowAll] = useState(false);

    if (!props.interests || !Array.isArray(props.interests[key])) {
        return null;
    }

    const interestArray = props.interests[key] as any[];

    return (
        <div>
            <div className=" flex flex-col justify-start w-full">
                <div className="mb-[20px] font-proxima font-bold text-xl">{key}</div>
                <section>
                    <div className="flex flex-wrap">
                        {interestArray
                            .slice(0, showAll ? interestArray?.length : 5)
                            .map((item: any, index: number) => {
                                if (
                                    props.interestFlag &&
                                    item.isUserInterest &&
                                    !props.ChoosedInterest?.includes(item.interest_id)
                                ) {
                                    props.setChoosedInterest((prevState: any) => [
                                        ...prevState,
                                        item.interest_id
                                    ]);
                                }

                                return (
                                    <button
                                        onClick={() => {
                                            props.handleInterestSelection(
                                                item.interest_id,
                                                item.interest_name,
                                                item.isUserInterest
                                            );
                                        }}
                                        key={index}
                                        className={`whitespace-nowrap h-9 text-sm transition-colors cursor-pointer px-3 py-1.5 rounded-full mb-2 ml-[10px] ${
                                            props.ChoosedInterest?.includes(item.interest_id)
                                                ? 'bg-[#FB7882] text-white'
                                                : 'hover:bg-gray-200 border-2 border-slate-300 text-black/80'
                                        }`}
                                    >
                                        {item.interest_name}
                                    </button>
                                );
                            })}
                        {interestArray?.length > 5 && !showAll && (
                            <button
                                onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
                                className={`whitespace-nowrap h-9 text-sm transition-colors cursor-pointer px-3 py-1.5 rounded-full mb-2 hover:bg-gray-200 border-2 border-slate-300 text-black ml-[10px]`}
                            >
                                <div>Show More +</div>
                            </button>
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default React.memo(InterestTagsList);
