import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
// import i18n from '../../i18n/config';
const SelectedLanguage = Geti18nLang();

const AskedQuestionFunction = (
    formData: any,
    user_data: any,
    onSuccess: (arg0: any) => void,
    onFailure: (arg0: any) => any,
    isForTalk?: boolean
) => {
    console.log('ask AskedQuestionFunction caalled');

    const URLforTalk = process.env.REACT_APP_PARENT_TALK + 'talk/v1/posttalk';
    const END_POINT = isForTalk ? URLforTalk : process.env.REACT_APP_ASKEXPERTS_SERVER + 'question';

    axios
        .post(END_POINT, formData, {
            headers: {
                userId: user_data ? user_data.userId : '',
                authToken: user_data ? user_data.authToken : '',
                instanceId: user_data?.instanceId?.toString() || '',
                lang: SelectedLanguage
            }
        })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
};

export default AskedQuestionFunction;
