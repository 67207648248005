export const TIMER_LENGTH = 20; // timer length is in second and use timer length<=59
export const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
import { Buffer } from 'buffer';
export const handle_fblogin = (response) => {
    // setIsLoading(true);
    // if (response.accessToken) {
    //     setLogin(true);
    //     setIsLoading(true);
    //     axios
    //         .post(REACT_APP_ONBOARDING_SERVER + 'auth/verify-login', {
    //             mode: 'social_login',
    //             email: {
    //                 user_id: response.id,
    //                 accesstoken: response.accessToken
    //             }
    //         })
    //         .then(({ data }) => {
    //             console.log(data);
    //             dispatch(setUserData(data));
    //         })
    //         .catch((error) => console.log(error))
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // } else {
    //     setLogin(false);
    // }
};

import axios from 'axios';
import { country_codes_africaWithCountry } from '../../utils/utils';

// Usage:
// handlePhoneNumber('your_phone_number', setcurrSelectedType, setLogin_nav, setResend, setcountDown, setIncorrectOTP, setShowToaster, pushEventToGtm);

export const storePpmgi = (userId) => {
    console.log('storing cookie');
    const ppmgi = '1fj44' + userId + '55pdjks';
    const ppmgiEncode = Buffer.from(ppmgi).toString('base64');
    const expires = new Date(Date.now() + 3600 * 24 * 365).toUTCString();
    document.cookie = '_ppmgi' + '=' + ppmgiEncode + '; expires=' + expires + '; path=/';
};

export const storeCookieForBasicVsPlus = () => {
    const expires = new Date(Date.now() + 3600 * 24 * 365).toUTCString();
    document.cookie = 'new_user' + '=' + 1 + '; expires=' + expires + '; path=/';
    document.cookie = 'user_profile_created' + '=' + 1 + '; expires=' + expires + '; path=/';
};

// handle func

export const _handlePhoneNumber = async (
    number,
    setcurrSelectedType,
    setLogin_nav,
    setResend,
    setcountDown,
    setIncorrectOTP,
    setShowToaster,
    TIMER_LENGTH
) => {
    setcurrSelectedType('mobile');
    localStorage.setItem('login_data', number);
    setLogin_nav('otp');

    setResend(false);
    setcountDown(TIMER_LENGTH);
    setIncorrectOTP(false);
    setShowToaster(true);

    setTimeout(() => {
        setResend(true);
    }, TIMER_LENGTH * 1000);

    const countryCode = sessionStorage.getItem('currentCountryCode')
        ? sessionStorage.getItem('currentCountryCode')?.replace('+', '')
        : '91';

    let country = localStorage.getItem('UserLocation');

    country = JSON.parse(country);

    const country_ = await country_codes_africaWithCountry[country?.country];

    console.log('country_country_country_', country, country_);

    const headers = {
        'Content-Type': 'application/json',
        country: country_ || 'INDIA'
    };

    const nonIndianCountry = countryCode.includes('91') ? false : true;

    console.log('nonIndianCountry', nonIndianCountry);
    axios
        .post(
            process.env.REACT_APP_ONBOARDING_SERVER2 + 'auth/send-otp',
            {
                mode: 'mobile',
                countryCode: countryCode, // You can replace '91' with the desired country code
                mobile: number
            },
            { headers }
        )
        .then(({ data }) => {
            console.log(data);
            // pushEventToGtm('send-otp', { pt_countryCode: '91' });
        })
        .catch((error) => {
            console.error(error);
        });
};
