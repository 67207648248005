import { hydrate, render } from 'react-dom';
// import './index.css';
import './build.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';
if (
    process.env.NODE_ENV === 'production' &&
    window.location.origin == 'https://www.parentune.com'
) {
    // @ts-ignore
    console.log = () => null;
    // @ts-ignore
    console.error = () => null;
    // @ts-ignore
    console.debug = () => null;
    // @ts-ignore
    console.table = () => null;
}

// if (process.env.NODE_ENV === 'development') {
//     // @ts-ignore
//     console.log = () => null;
//     // @ts-ignore
//     console.error = () => null;
//     // @ts-ignore
//     console.debug = () => null;
// }

import '../src/i18n/config';
import { Tracking_Id } from './utils/utils';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

ReactGA.initialize([
    {
        trackingId: Tracking_Id
        // gaOptions: {...}, // optional
        // gtagOptions: {...}, // optional
    }
]);

// Send pageview with a custom path
ReactGA.send({
    hitType: 'pageview',
    page: `${window.location.pathname}+${window.location.hash}`,
    title: document?.title
});

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
    hydrate(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App />
            </Provider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>,
        document.getElementById('root')
    );
} else {
    render(
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App />
            </Provider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>,
        document.getElementById('root')
    );
}

// } else {
//     render(
//         <QueryClientProvider client={queryClient}>
//             <Provider store={store}>
//                 <App />
//             </Provider>
//             {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//         </QueryClientProvider>,
//         rootElement
//     );
// }

// ReactDOM.render(
//     <QueryClientProvider client={queryClient}>
//         <Provider store={store}>
//             <App />
//         </Provider>
//         {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//     </QueryClientProvider>,
//     document.getElementById('root')
// );

//reportWebVitals(reportHandler);
