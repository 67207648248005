import { useUserData } from '../../utils/hooks/useUserData';
import './ZohoScript.js';
import ZohoDeskScript from './ZohoScript.js';
const ZohoChat = () => {
    const { userData: user_data, isLoggedIn } = useUserData();
    // hide bubble after certain time
    // window.onload = function () {
    //     setTimeout(function () {
    //         document.getElementById('zohohc-asap-web-launcherbox').style.visibility = 'hidden';
    //     }, 2000);
    // };
    return (
        <>
            <ZohoDeskScript user_data={user_data ?? null} />
        </>
    );
};
export default ZohoChat;
