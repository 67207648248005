import { doc } from 'prettier';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import i18n from '../../i18n/config';
import { ImagesArrayType } from '../AskExpertModule/ExpertQandA/ExpertQandA';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';

type propsType = {
    imagesArray: ImagesArrayType[];
    redirectToNone?: boolean;
    isForHIndiUsers?: boolean;
    redirectToMuslimListing?: boolean;
};

type propType2 = {
    image: string;
    index: number;
    setVisibleIndex: (arg: any) => void;
};

const SelectedLanguage = Geti18nLang();

const ImageItem = (props: propType2) => {
    const [element, setElement] = useState<any>(null);

    useEffect(() => {
        const currentElement = element;
        const currentObserver = observer.current;
        if (currentElement) {
            currentObserver.observe(currentElement);
        }
    }, [element]);

    const doThisWhenInView = () => {
        props.setVisibleIndex(props.index);
    };

    const observer = useRef(new IntersectionObserver(doThisWhenInView, { threshold: 1.0 }));

    return (
        <img
            ref={setElement}
            id=""
            className="snap-start relative rounded-[5px]  w: 200rem  h-full object-cover last_child_right_margin"
            src={props.image}
            alt=""
            loading="lazy"
        ></img>
    );
};

const ImageCarouselForBabyNames = (props: propsType) => {
    const [visibleIndex, setVisibleIndex] = useState();

    const isValidImageArray = (images: any[]) => {
        images.map((image, index) => {
            if (
                !image.url.endsWith('.jpg') &&
                !image.url.endsWith('.png') &&
                !image.url.endsWith('.jpeg')
            ) {
                return false;
            }
        });
        return true;
    };
    const hindiArr = [
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_09.jpg',
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_10.jpg',
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_11.jpg',
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_12.jpg'
    ];
    const { imagesArray } = props;
    const { t, i18n } = useTranslation();
    const validImages = isValidImageArray(imagesArray);
    const hindiImages: any[] = [
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_09.jpg',
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_10.jpg',
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_11.jpg',
        'https://img1.parentune.com/temp/baby-name-ads-v1/baby_names_hindi_carousel_12.jpg'
    ];
    return validImages ? (
        <div>
            <a
                className="pt-track-click"
                data-ptname="baby-name-consulation-top-banner-v2"
                data-pttype="nativead"
                data-ptposition="content-mid"
                href={
                    props.redirectToMuslimListing
                        ? '/services/astrologer/muslim'
                        : props.redirectToNone
                        ? '#'
                        : '/services/astrologer'
                }
            >
                <div
                    id="carousalContainer"
                    className="w-full h-auto flex overflow-scroll gap-[24px] scrollbar-hide snap-x pt-track-impression"
                    data-pttype="nativead"
                    data-ptposition="content-mid"
                    data-ptname="baby-name-consulation-top-banner-v2"
                >
                    {SelectedLanguage == 'en' ||
                    SelectedLanguage == 'ta' ||
                    SelectedLanguage == 'te'
                        ? imagesArray.map((value, index) => {
                              return (
                                  <ImageItem
                                      key={index}
                                      setVisibleIndex={setVisibleIndex}
                                      index={index}
                                      image={value.url}
                                  />
                              );
                          })
                        : hindiArr.map((value, index) => {
                              return (
                                  <ImageItem
                                      key={index}
                                      setVisibleIndex={setVisibleIndex}
                                      index={index}
                                      image={value}
                                  />
                              );
                          })}
                </div>
                {/* <div className="mt-[18px] w-full mb-[10px] mx-auto flex flex-row items-center justify-center gap-[10px]">
                {imagesArray.map((value, indexIndicator) => (
                    <div
                        key={value.url}
                        className={`h-[11px] w-[11px] rounded-full ${
                            visibleIndex == indexIndicator ? 'bg-[#666666]' : 'bg-[#c8c8c8]'
                        }`}
                    />
                ))}
            </div> */}
            </a>
        </div>
    ) : (
        <></>
    );
};

export default ImageCarouselForBabyNames;
