import axios from 'axios';

export function UpdateProfile(
    onSuccess: (response: any) => void,
    onFailure: (response: any) => void,
    user_data: any,
    step: number,
    data: any
) {
    console.log('>>>data', data);
    const config = {
        method: 'post',
        url: process.env.REACT_APP_ONBOARDING_SERVER2 + `update/profile?step=${step}`,
        headers: {
            authToken: user_data?.authToken ?? '',
            instanceId: user_data?.instanceId ?? '',
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
    };
    axios
        // @ts-ignore
        .request(config)
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            console.log(err);
            onFailure(err);
        });
}
