import React, { useState, useEffect } from 'react';
import FormProgress from '../../FormProgress/FormProgress';
import { useAppDispatch } from '../../../store/store';
import { useUserData } from '../../../utils/hooks/useUserData';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import AddChildComponent from '../../AddChildComponent/AddChildComponent';
import DOBInput from '../../../pages/ParentTypeInputPage/DOBInput';
import { checkIfChildDataisNull } from './utils';
import { useNavigate } from 'react-router-dom';
import pushEventToGtm from '../../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';

const HaveChild = (props: any) => {
    const { nav, setNav, selectedData, setSelectedData, isLoading } = props;

    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const dispatch = useAppDispatch();
    const { userData: user_data } = useUserData();
    const [dueDate, setDueDate] = useState<string>('');
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const handleDueDateUpdate = () => {
        setNav('IdentifyYourSelf');
        localStorage.setItem('onboarding_data', JSON.stringify(data));
        pushEventToGtm('have-child-user-onboard', {
            'have-child': 'have-child-next',
            url: window.location.pathname,
            newGuestUid
        });
    };
    useEffect(() => {
        // navigate(window.location.pathname);
        window.history.pushState(null, '', '/register');

        // Listen for popstate event to prevent back navigation
        const handlePopState = () => {
            //   navigate(window.location.pathname);
            window.history.pushState(null, '', '/register');
            setNav('SetupProfile'); // Keep the user on the same page
        };
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    return (
        <div>
            <div className="h-[90vh] animate-fade-in-left max-w-4xl mx-auto pt-3 flex flex-col px-4">
                <FormProgress onBack={() => setNav('SetupProfile')} dots={4} progress={2} />
                <h1 className="font-proxima font-bold text-xl text-[#242F35] ">
                    Hello, {user_data.name}
                </h1>
                {/* {JSON.stringify(dueDate)} */}
                <section className="my-2 flex flex-col gap-2">
                    {/* Add another Child */}
                    <AddChildComponent
                        data={user_data?.kidsList}
                        setData={setData}
                        atLeastOneChild={true}
                    />
                </section>

                {/* btn on bottom */}

                <div className="flex-1 flex flex-col justify-end pb-3">
                    <ButtonPrimary
                        size="large"
                        isLoading={isLoading}
                        text="Next"
                        onClick={() => handleDueDateUpdate()}
                        disabled={checkIfChildDataisNull(data)}
                    />
                </div>
            </div>
        </div>
    );
};

export default HaveChild;
