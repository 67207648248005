const FellowParents = () => {
    return (
        <div className="mx-5 mt-[30px] flex justify-between items-center">
            <p>Disha +2 friends are attending</p>
            <img
                src="https://parntune-assets.b-cdn.net/images/about_us/badges.jpg"
                alt="badges-icon"
            />
        </div>
    );
};

export default FellowParents;
