import './GiftPage.css';
import { useEffect, useState } from 'react';
import { getPlans } from '../../api/GiftPage/GiftPage';
import TopHeader from './TopHeader';
import HeroImg from './HeroImg';
import PlusBenefit from './PlusBenefit';
import ReceiverDetail from './ReceiverDetail';
import SenderDetail from './SenderDetail';
import { useNavigate } from 'react-router-dom';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';

function GiftPage() {
    const [pluscontent, setPlusContent] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getPlans(onSuccess, onFailure);
    }, []);

    const onSuccess = (response: any) => {
        console.log(response);
        setPlusContent(response.data.plans);
    };

    const onFailure = (error: any) => {
        console.log('error', error);
    };

    const onBackHandler = () => {
        navigate(-1);
    };

    return (
        <div className="flex justify-center">
            <div className="max-w-4xl w-full flex flex-col">
                <TopHeader handleBack={onBackHandler} />
                {pluscontent && pluscontent.length > 0 && (
                    <>
                        <HeroImg image={pluscontent[0].gift_description.image} />
                        <PlusBenefit />
                        <SenderDetail />
                        <ReceiverDetail
                            plusAnnual={{
                                price: pluscontent[0].price,
                                perday: pluscontent[0].cost_per_day
                            }}
                            plusMonth={{
                                price: pluscontent[1].price,
                                perday: pluscontent[1].cost_per_day
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default GiftPage;
