import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CalendarInvite from '../../../api/workshop/CalendarInvite';

import setReminder from '../../../api/workshop/setReminder';
import DialogModel from '../../../components/DialogModel/DialogModel';
import Calendar from '../../../components/Icons/Calendar';
import ClockIcon from '../../../components/Icons/ClockIcon';
import Overlay from '../../../components/Overlay/Overlay';
import Toggle from '../../../components/V2Components/Toggle';
import { useUserData } from '../../../utils/hooks/useUserData';

export default function Reminder(props: any) {
    const [enabled, setEnabled] = useState(false);
    const { userData: user_data, guestUid, isLoggedIn } = useUserData();
    const [reminderStored, setReminderStored] = useState(props?.eventDetail?.isReminderSet);
    const [calendarInvite, setCalendarInvite] = useState<boolean>(false);
    const navigate = useNavigate();

    const [isValid, setIsValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState<any>((isLoggedIn && user_data?.mobile) || '');
    const [email, setEmail] = useState<any>((isLoggedIn && user_data?.email) || '');
    const [isVisible, setIsVisible] = useState(false);
    const [closeModel, setCloseModel] = useState(false);
    const [reminderSetDone, setReminderSetDone] = useState(
        localStorage.getItem(`workshop${props.eventDetail?.id}`) ? true : false
    );

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    const eventDetail = props.eventDetail;

    // Create a new Date object for the specified date and time
    const workshopdate = new Date(eventDetail.start_date);

    // Extract the components
    const day = workshopdate.getUTCDate();
    const month = workshopdate.getUTCMonth() + 1; // getUTCMonth() returns 0 for January, so we add 1
    const year = workshopdate.getUTCFullYear();
    const hours = workshopdate.getUTCHours();
    const minutes = workshopdate.getUTCMinutes();
    const seconds = workshopdate.getUTCSeconds();

    // Pad single digits with leading zeros
    const DD = day.toString().padStart(2, '0');
    const MM = month.toString().padStart(2, '0');
    const YY = year.toString().padStart(2, '0');
    const HH = hours.toString().padStart(2, '0');
    const MI = minutes.toString().padStart(2, '0');
    const SS = seconds.toString().padStart(2, '0');

    const date = [
        {
            id: 1,
            placeholder: 'DD',
            value: `${DD ?? ''}`
        },
        {
            id: 2,
            placeholder: 'MM',
            value: `${MM ?? ''}`
        },
        {
            id: 3,
            placeholder: 'YYYY',
            value: `${YY ?? ''}`
        }
    ];

    const time = [
        {
            id: 1,
            placeholder: 'HH',
            value: `${HH ?? ''}`
        },
        {
            id: 2,
            placeholder: 'MM',
            value: `${MI ?? ''}`
        },
        {
            id: 3,
            placeholder: 'SS',
            value: `${SS ?? ''}`
        }
    ];

    const NumberformHandler = (e: any) => {
        e.preventDefault();
        const reminderEndTime: any = `${eventDetail.end_date.split(' ')[3]}-${(
            monthNames.indexOf(eventDetail.end_date.split(' ')[2]) + 1
        ).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}-${eventDetail.end_date.split(' ')[1]}T${eventDetail.end_date.split(' ')[4]}`;

        const reminderStartTime: any = `${eventDetail.start_date.split(' ')[3]}-${(
            monthNames.indexOf(eventDetail.start_date.split(' ')[2]) + 1
        ).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}-${eventDetail.start_date.split(' ')[1]}T${eventDetail.start_date.split(' ')[4]}`;

        console.log('start-date', reminderEndTime, reminderStartTime);

        const reminderPayload = {
            startTime: reminderStartTime,
            endTime: reminderEndTime,
            title: eventDetail?.title,
            description: eventDetail.share_details.facebook,
            userEmail: email,
            user_data: user_data,
            guestUid: guestUid
        };
        if (enabled && email != '') {
            CalendarInvite(
                reminderPayload,
                () => {
                    console.log('invite sent successfully');
                },
                (data: any) => {
                    console.log(data);
                }
            );
        }

        const data = {
            phoneNumber,
            email,
            calendarInvite
        };
        const reminderData = {
            id: eventDetail?.id,
            title: eventDetail?.title,
            email: email,
            mobile: phoneNumber,
            user_data: user_data,
            guestUid: guestUid,
            calendarInvite: calendarInvite
        };

        setReminder(
            reminderData,
            () => {
                // alert('Reminder Set');
                console.log('set reminder!');
                setEnabled(false);
                setReminderSetDone(true);
                localStorage.setItem(`workshop${eventDetail?.id}`, eventDetail?.id);
                props.setShowAttendEvent(true);
                props.setReminderPop(false);
                setReminderStored(true);
            },
            () => {
                console.log('something went wrong!');
                // alert('Something went wrong');
            }
        );
    };
    const handleInputChange = (e: any) => {
        const value = e.target.value;

        // Validate input using regex
        const regex = /^[0-9]{0,10}$/;
        if (regex.test(value)) {
            setPhoneNumber(value);
        }
    };
    const handleEmailChange = (e: any) => {
        const value = e.target.value;
        setEmail(value);

        // Validate email using regex
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setIsValid(regex.test(value));
    };
    return (
        <>
            <div className="flex justify-between items-center mx-2 m-5">
                <div className="flex items-center gap-2">
                    <ClockIcon />
                    <div className="font-roboto text-[16px] text-black ml-2 ">
                        {t('Set Reminder')}
                    </div>
                </div>

                {reminderSetDone ? (
                    <Toggle
                        enabled={true}
                        setEnabled={() => {
                            props.setIsThankYou(true);
                        }}
                    ></Toggle>
                ) : (
                    <div className="flex-initial ">
                        {enabled || reminderStored ? (
                            <div style={{ pointerEvents: 'none', touchAction: 'none' }}>
                                <Toggle
                                    enabled={enabled || reminderStored}
                                    setEnabled={() => {
                                        if (!user_data) return navigate('/register');
                                        setEnabled(!enabled);
                                    }}
                                ></Toggle>
                            </div>
                        ) : (
                            <Toggle
                                enabled={enabled || reminderStored}
                                setEnabled={() => {
                                    setEnabled(!enabled);
                                }}
                            ></Toggle>
                        )}
                    </div>
                )}
            </div>
            <>
                {(enabled || props.reminderPop) && (
                    <DialogModel
                        closeModel={() => {
                            () => setCloseModel(false);
                        }}
                    >
                        <div>
                            <div className="bg-white text-black h-fit w-[320px] flex m-auto rounded-[5px]">
                                <div className="m-3">
                                    <form onSubmit={NumberformHandler} className="m-1 p-1">
                                        <div className="text-left">
                                            <div className="text-lg font-semibold mb-2">
                                                The reminder will be sent on this number
                                            </div>
                                            {/* <label>Number</label> */}
                                            <br />
                                            <input
                                                id="PhoneNumber"
                                                placeholder="Enter mobile number"
                                                className={`rounded-[5px] border h-[40px] w-full text-[16px] leading-[26px] pl-[20px] mt-[8px] mb-[24px]`}
                                                type="text"
                                                value={phoneNumber}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <br />
                                            <label
                                                htmlFor="email"
                                                className="font-roboto text-[16px] leading-[22px]"
                                            >
                                                Add email ID
                                                {/* {t('Email_ID')} */}
                                            </label>
                                            <br />
                                            <input
                                                type="email"
                                                id="email"
                                                placeholder="Enter your email ID"
                                                value={email}
                                                onChange={handleEmailChange}
                                                required
                                                className="rounded-[5px] border w-full h-[40px] text-[16px] leading-[26px] pl-[20px] mt-[8px]  "
                                            />
                                            {!isValid && (
                                                <p className="text-red-500">
                                                    Please enter a valid email address.
                                                </p>
                                            )}
                                            <div className="font-roboto mt-6 text-[14px] text-[#787878]">
                                                A reminder will be sent to this email ID{' '}
                                            </div>
                                            <div className="flex my-5">
                                                <Calendar />
                                                <div className="font-roboto text-[14px] text-black ml-2 p-1">
                                                    Add to Calendar
                                                </div>
                                                <div className="flex-initial ml-10">
                                                    <Toggle
                                                        enabled={calendarInvite}
                                                        setEnabled={() => {
                                                            setCalendarInvite(!calendarInvite);
                                                            setIsVisible(!isVisible);
                                                        }}
                                                    ></Toggle>
                                                </div>
                                            </div>
                                        </div>

                                        {isVisible && (
                                            <div>
                                                <div className="mt-[29px] flex flex-col gap-[18px]">
                                                    <h3 className="flex">Set Date</h3>
                                                    <div className="flex items-center gap-[28px]">
                                                        {date.map((item: any) => (
                                                            <input
                                                                type="text"
                                                                key={item.id}
                                                                value={item.value}
                                                                placeholder={item.placeholder}
                                                                className={`${
                                                                    item.placeholder === 'YYYY'
                                                                        ? 'w-[71px]'
                                                                        : 'w-[54px]'
                                                                } h-[54px] border border-[#C4C4C4] rounded-md p-2 text-center`}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="mt-[20px]  flex flex-col gap-[18px]">
                                                    <h3 className="flex">Set Time</h3>
                                                    <div className="flex items-center gap-[28px]">
                                                        {time.map((item) => (
                                                            <input
                                                                type="text"
                                                                key={item.id}
                                                                placeholder={item.placeholder}
                                                                value={item.value}
                                                                className="w-[54px] h-[54px] border border-[#C4C4C4] rounded-md p-2 text-center"
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* <br /> */}
                                        <div className="mt-[34px] font-roboto text-[16px] font-[500] leading-[22px] text-right">
                                            <button
                                                className={`w-[88px] h-[30px] rounded-[50px] border-2 font-roboto mt-[56px]}`}
                                                onClick={() => {
                                                    setEnabled(false);
                                                    props.setReminderPop(false);
                                                }}
                                            >
                                                {t('Skip')}
                                            </button>
                                            <button
                                                disabled={!isValid}
                                                className={`${
                                                    !isValid
                                                        ? 'bg-gray-500 text-white font-normal'
                                                        : 'bg-[#367E8A] text-white'
                                                } w-[88px] h-[30px] rounded-[50px]   font-roboto mt-[56px]} ml-[20px] }`}
                                                onSubmit={NumberformHandler}
                                            >
                                                {t('Submit')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </DialogModel>
                )}
            </>
        </>
    );
}
