import pregnent from '../../assets/dailyFocusGuest/pragnent-icon.svg';
import pragnentlight from '../../assets/dailyFocusGuest/pragnent-light-icon.svg';
import parent from '../../assets/dailyFocusGuest/parent-icon.svg';
import parentlight from '../../assets/dailyFocusGuest/parent-light-icon.svg';
import Pregnancy from './Pregnancy';
import ParentDetails from './ParentDetail';
import TopHeader from './TopHeader';
import { useState } from 'react';
import { Constants } from '../Constants/Constants';
import { getClientDevice } from '../../utils/utils';
import { useUserData } from '../../utils/hooks/useUserData';

function DailyFocusGuestWidget() {
    const device = getClientDevice();
    const { userData } = useUserData();

    if (device === Constants.DEVICE.DESKTOP) {
        return null;
    }

    if (userData) {
        return null;
    }

    return (
        <div className="flex  justify-center">
            <div className="w-[360px] bg-white border border-[#C4C4C4] rounded-[8px] mt-[20px] mx-[12px]">
                <TopHeader />
                <TabHeader />
            </div>
        </div>
    );
}

export default DailyFocusGuestWidget;

const TabHeader = () => {
    const [state, setState] = useState(false);

    return (
        <>
            <div className="mt-[16px] flex items-center justify-between border-b border-[#151E261A]">
                <div
                    className={`px-[16px] flex  gap-[10px] items-center ${
                        state === false ? 'border-b-[3px] border-[#242F35]' : ''
                    }`}
                    onClick={() => setState(false)}
                >
                    <img loading="lazy" src={state === false ? parent : parentlight} alt="parent" />
                    <h2
                        className={
                            state === false
                                ? 'text-[18px] font-medium text-[#242F35]'
                                : 'text-[18px] font-medium text-[#787878]'
                        }
                    >
                        I have a child
                    </h2>
                </div>
                <div
                    className={`px-[16px] flex gap-[10px] items-center ${
                        state === true ? 'border-b-[3px] border-[#242F35]' : ''
                    }`}
                    onClick={() => setState(true)}
                >
                    <img src={state === true ? pregnent : pragnentlight} alt="pragnent" />
                    <h2
                        className={
                            state === true
                                ? 'text-[18px] font-medium text-[#242F35]'
                                : 'text-[18px] font-medium text-[#787878]'
                        }
                    >
                        I am pregnant
                    </h2>
                </div>
            </div>
            {!state && <ParentDetails />}
            {state && <Pregnancy />}
        </>
    );
};
