import backbutton from '../../assets/giftpage/backarrow.svg';

const TopHeader = (props: any) => {
    const { handleBack } = props;
    return (
        <div
            className="mx-5  mt-[16px] mb-[12px] flex items-center gap-[20.25px]"
            onClick={handleBack}
        >
            <img src={backbutton} alt="back-icon" />
            <h2 className="text-[#242F35] text-[21px] font-bold">PLUS Gift</h2>
        </div>
    );
};

export default TopHeader;
