import { useState, memo, useEffect } from 'react';
import './EditProfileParentSelect.css';
import SelectionFormOption from '../../SelectionFormOption/SelectionFormOption';

import { Link } from 'react-router-dom';
import { StageData } from './utils';
const FatherIcon = 'https://img1.parentune.com/web/assests_v2/FatherIcon.svg';
const MotherIcon = 'https://img1.parentune.com/web/assests_v2/Mother.svg';
const arrowback = 'https://img1.parentune.com/web/assests_v2/arrowback.webp';
const MarriedIcon = 'https://img1.parentune.com/web/assests_v2/MarriedIcon.svg';

function EditProfileParentSelect(props: any) {
    const { setSelectedData, nav } = props;
    const isForHaveaChild = props.isForHaveaChild ?? false;
    const isForKeenToAdopt = props?.isForKeenToAdopt ?? false;
    // const SignupStage = 1;

    const [selectedOption, setSelectedOption] = useState(null);

    const [data, setdata] = useState<any>(null);

    useEffect(() => {
        //
        // console.log('nav>>>', nav, 'isForKeenToAdopt', isForKeenToAdopt);
        if (nav === 'SetupProfile') setdata(StageData.SetupProfileData);
        else if (nav === 'NewlyMarried') setdata(StageData.NewlyMarriedData);
        else if (nav === 'PlanningForPregnancy') setdata(StageData.PlanningForPregnancyData);
        else if (nav === 'IdentifyYourSelf') setdata(StageData.IamWeArePregnantData);
        // console.log('line 29', isForKeenToAdopt);
        if (isForHaveaChild) setdata(StageData.HaveaChildData);
        if (isForKeenToAdopt) setdata(StageData.ForKeenToAdoptData);
    }, [nav, isForKeenToAdopt]);

    useEffect(() => {
        const selected =
            data && data?.length > 0 && data.find((option: any) => option.id === selectedOption);
        setSelectedData(selected);
        // console.log('data', selected);
    }, [selectedOption]);

    const [currentIndex, setCurrentIndex] = useState(0);

    // Use this effect to increment the currentIndex after a 1-second delay
    useEffect(() => {
        if (!data || data?.length === 0) return;
        if (currentIndex < data?.length) {
            const timeoutId = setTimeout(() => {
                setCurrentIndex(currentIndex + 1);
            }, 100); // 1 second delay

            // Clear the timeout if the component unmounts or currentIndex changes
            return () => clearTimeout(timeoutId);
        }
    }, [currentIndex, data]);

    return (
        <div className="ParentSelect">
            {/* {JSON.stringify(isForKeenToAdopt)} isForKeenToAdopt */}
            {nav === 1 && (
                <p className="!mb-4">{nav === 1 ? 'Choose one of these options' : null}</p>
            )}
            {data &&
                data?.length > 0 &&
                data.slice(0, currentIndex).map((d: any, index: number) => {
                    return (
                        <div key={index}>
                            <SelectionFormOption
                                icon={d.icon}
                                option={d.name}
                                response={d.response}
                                id={d.id}
                                setSelectedOption={setSelectedOption}
                                isSelected={selectedOption === d.id}
                            />
                        </div>
                    );
                })}
        </div>
    );
}

export default memo(EditProfileParentSelect);
