import { useUserData } from '../../utils/hooks/useUserData';
import { useState, useEffect, useContext } from 'react';
import { RenewalPageApi } from '../../api/RenewalPage';
import { UNSAFE_NavigationContext, useLocation, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { DefaultUser, getClientDevice, SendEvents } from '../../utils/utils';
import FetchMyOrders from '../../api/MyOrders/MyOrders';
import Header from '../../components/Header/Header';
import RenewalCarousel from '../../components/RenewalPage/RenewalCarousel';
import ButtonPrimary from '../../components/V2Components/ButtonPrimary';
import RenewalFaq from '../../components/PlusComponents/RenewalFaq';
import EventDetailsQuestions from '../../components/EventDeatailsQuestions/EventDetailsQuestions';
import { t } from 'i18next';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { addUserdata } from '../../store/slices/userDataSlice';
import { useDispatch } from 'react-redux';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
import axios from 'axios';
import { Constants } from '../../components/Constants/Constants';

const Myprofile = () => {
    const { userData: user_data, isLoggedIn } = useUserData();
    const location = useLocation();
    const { state } = location || {};

    // @ts-ignore
    const isForChooseAPlan = state?.planType == 'choose-a-plan' ?? false;
    const [loading, setLoading] = useState(false);
    const [faq, setFaq] = useState([]);
    const [renewalData, setRenewalData] = useState<any>([]);
    const [plusRenewalBenefits, setPlusRenewalBenefits] = useState<any>([]);
    const [proRenewalBenefits, setProRenewalBenefits] = useState<any>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [currency, setCurrency] = useState('₹');

    const [chipsArray, setchipsArray] = useState<any>([
        {
            name: 'Ask Doctor',
            arrowText: 'Ask Now',
            url: '/expert-corner',
            img: 'https://img1.parentune.com/home_assets/footer/askexperticon.svg'
        },
        {
            name: 'Learn',
            arrowText: 'Watch Now',
            url: '/livechats',
            img: 'https://img1.parentune.com/home_assets/workshop%201.svg'
        },
        {
            name: 'Expert Blogs',
            arrowText: 'View Now',
            url: '/parent-blog',
            img: 'https://img1.parentune.com/images/icons/Group%202344.svg'
        }
    ]);
    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
        useFetchUserLocation().then((res: any) => {
            setCurrency(res.currency_symbol);
        });

        // @ts-ignore
        let unlisten = navigation.listen((locationListener) => {
            if (locationListener.action === 'POP') {
                navigate('/');
            }
        });
        return () => {
            unlisten();
        };
    }, []);

    function changeBodyColorForSubscriptions() {
        // todo remove !
        if (user_data?.subscriptionInfo == 'plus') {
            document.body.style.background = 'black';
            document.body.style.color = 'white';
        } else {
            document.body.style.background = 'white';
            document.body.style.color = 'black';
        }
    }
    changeBodyColorForSubscriptions();
    function changeBodyColorAfterRedirections() {
        document.body.style.background = 'white';
        document.body.style.color = 'black';
    }
    function addGa4Event(eventName: string) {
        SendEvents(user_data?.id ?? DefaultUser, 'btn-click', eventName);
    }

    const onSuccessApi = (res: any) => {
        setIsLoading(false);
        setFaq(res.data.faq.question);
        setRenewalData(res.data);
        setPlusRenewalBenefits(res.data.plusRenewBenefitsText);
        setProRenewalBenefits(res.data.proRenewBenefitsText);
    };
    const onFailureAPi = (res: any) => {
        console.log(res);
    };

    //api call
    useEffect(() => {
        GetUserProfile(
            async (data: any) => {
                dispatch(addUserdata({ ...data?.data }));
            },
            (err) => {
                console.error('Error: ', err);
            },
            user_data
        );

        if (localStorage.getItem('gift-user') === '1') {
            axios({
                method: 'post',
                url: process.env.REACT_APP_SUBSCRIPTION_SERVER + 'choosePlan/log',
                headers: {
                    lang: 'en',
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || ''
                }
            });
        }

        FetchMyOrders(
            user_data,
            (s: any) => console.log('sucess', s),
            (f: any) => console.log('failure', f)
        );

        // return;
        setLoading(true);
        if (user_data) {
            RenewalPageApi(user_data, onSuccessApi, onFailureAPi);
        } else {
            // window.location.href = '/';
        }

        return () => {
            changeBodyColorAfterRedirections();
        };
    }, []);

    useEffect(() => {
        user_data?.subscriptionInfo == 'plus' ? null : setchipsArray(chipsDataForPro);
    }, [user_data?.subscriptionInfo]);

    const chipsDataForPro = [
        {
            name: 'Expert Blogs',
            arrowText: 'View Now',
            url: '/parent-blog',
            img: 'https://img1.parentune.com/images/icons/Group%202344.svg'
        },
        {
            name: 'Expert Videos',
            arrowText: 'Watch Now',
            url: '/parent-blog',
            img: 'https://img1.parentune.com/home_assets/workshop%201.svg'
        },
        {
            name: 'Parent Talk',
            arrowText: 'Start a Talk',
            url: '/parent-talk',
            img: 'https://img1.parentune.com/home_assets/footer/talksicon.svg'
        }
    ];

    const handleCTAClick = () => {
        const currentblogurl = sessionStorage.getItem('current-blog-url');
        console.log('current-blog:', currentblogurl);

        const device = getClientDevice();
        const url = sessionStorage.getItem('urlFromOnboarding');

        if (url) {
            navigate(JSON.parse(url), {
                replace: true
            });
            sessionStorage.removeItem('urlFromOnboarding');
            return;
        }

        let url2 = user_data?.isEligibleForDailyFocus ? '/daily-focus' : '/parent-blog';

        if (device === Constants.DEVICE.DESKTOP) {
            if (url2.includes('/daily-focus')) {
                url2 = '/parent-blog';
            }
        }

        navigate(url2);
    };

    return (
        // border-0 black ${styles.mainCon}
        <div className="site-spacing min-h-screen">
            <div className=""></div>
            {/* img and icon */}
            <div className=" flex items-center justify-center mt-3">
                <div className="">
                    <div
                        className={`relative ${user_data && user_data.isSubscribed ? 'pb-3' : ''} `}
                    >
                        <img
                            className="h-[94px] w-[94px] rounded-full border-2 object-cover "
                            src={user_data?.avatar}
                            alt="User Profile pic"
                        />
                        {user_data &&
                            (user_data?.isSubscribed ||
                                (user_data?.subscriptionInfo !== '' &&
                                    user_data?.subscriptionInfo != 'expired')) && (
                                <button
                                    className="text-white px-1 pb-[1px] text-[18px] h-[23px]  w-[64px] leading-3  rounded right-0 left-[15px] absolute bottom-0"
                                    style={{
                                        backgroundColor:
                                            user_data?.subscriptionInfo == 'plus'
                                                ? 'rgba(54, 126, 138, 1)'
                                                : 'rgba(243, 134, 48, 1)'
                                    }}
                                >
                                    {user_data?.subscriptionInfo.toUpperCase()}
                                </button>
                            )}
                    </div>
                </div>
            </div>
            <div className="mt-4 h-auto items-center text-center">
                {/* saving texts */}
                <div className=" font-normal font-roboto">
                    Member till {user_data?.subscriptionEndDate}
                </div>

                <div className="w-full px-16">
                    {renewalData ? (
                        <div
                            className="mt-[30px] text-xs break-words font-normal font-roboto leading-4 text-center mx-10"
                            dangerouslySetInnerHTML={{ __html: renewalData?.savingsText }}
                        ></div>
                    ) : (
                        <div className="mt-[30px] h-5 bg-gray-800 animate-pulse"></div>
                    )}
                </div>

                <section className="flex flex-col w-full px-5 mt-14">
                    {chipsArray.map((item: any, index: number) => (
                        <div
                            key={index}
                            className="flex items-center justify-between mt-[10px] h-[49px] w-full font-roboto"
                            onClick={() => {
                                navigate(item?.url);
                            }}
                            style={{
                                backgroundColor:
                                    user_data?.subscriptionInfo === 'plus'
                                        ? 'rgba(41, 41, 41, 1)'
                                        : 'rgba(243, 243, 243, 1)',
                                alignItems: 'center',
                                borderRadius: '10px'
                            }}
                        >
                            {/* img */}
                            <section className="w-[40px]">
                                <div
                                    className="!w-[32px] !h-[32px] ml-[10px] border border-white"
                                    style={{ borderRadius: '27px', backgroundColor: 'white' }}
                                >
                                    <img
                                        src={item?.img}
                                        alt=""
                                        className="h-[19px] w-[12px] relative top-[6px] left-[10px]"
                                    />
                                </div>
                            </section>

                            <section className=" w-full h-[49px] flex justify-between items-center">
                                <section className="w-fit ml-[15px]">
                                    <div className="">
                                        <div className="text-base text-left font-normal">
                                            {item?.name}
                                        </div>
                                        {item?.name == 'Expert Blogs' &&
                                            renewalData?.countBlogsRead > 0 && (
                                                <div className="text-[10px] text-left">
                                                    Viewed {renewalData?.countBlogsRead}
                                                </div>
                                            )}
                                    </div>
                                </section>
                                <section
                                    className={`${
                                        user_data?.subscriptionInfo == 'pro'
                                            ? 'text-black'
                                            : 'text-white'
                                    }`}
                                >
                                    {renewalData?.blogSavings && item?.name == 'Expert Blogs' ? (
                                        <div className="text-[16px] pr-4 ">
                                            {currency} {renewalData?.blogSavings}
                                        </div>
                                    ) : (
                                        <div
                                            className="text-end flex ml-[40px]  pr-4"
                                            onClick={() => {
                                                addGa4Event('renew_read_now_click');
                                                navigate('/parent-blog');
                                            }}
                                        >
                                            <div className="w-[92%]">{item?.arrowText}</div>
                                            <div
                                                className="m-auto w-[8%] relative"
                                                style={{ left: '-5px' }}
                                            >
                                                <AiOutlineArrowRight
                                                    className={` ml-2   text-[12px] cursor-pointer`}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </section>
                            </section>
                        </div>
                    ))}
                </section>

                <div className="mt-4 h-[49px] items-center text-center mb-44">
                    {renewalData?.carousel && renewalData?.carousel.length > 0 ? (
                        <section className="mt-11">
                            <RenewalCarousel
                                data={renewalData?.carousel}
                                // isPlus={false}
                                // todo
                                isPlus={user_data?.subscriptionInfo == 'plus'}
                            />
                        </section>
                    ) : (
                        <section className="flex items-center justify-between mt-4 space-x-5 ml-5">
                            <div className="h-[160px] w-[95%] bg-gray-300 card-shine-effect rounded-md"></div>
                            <div className="h-[160px] w-[5%] bg-gray-300 card-shine-effect  rounded-md"></div>
                        </section>
                    )}
                </div>
                <div className="m-auto leading-[35px] font-normal">
                    <section className="mt-5 w-full px-5 mb-5 leading-5">
                        <p className="font-medium text-[16px] w-full text-left x">Benefits</p>
                        <ol className="list-decimal mt-3 px-5 break-words ">
                            {renewalData?.planType == 'PLUS'
                                ? plusRenewalBenefits?.map((benefit: string, index: number) => {
                                      return (
                                          <li
                                              key={index}
                                              className="font-normal font-roboto text-left  text-sm w-full mb-5 leading-5"
                                          >
                                              {benefit}
                                          </li>
                                      );
                                  })
                                : proRenewalBenefits?.map((benefit: string, index: number) => {
                                      return (
                                          <li
                                              key={index}
                                              className="font-normal font-roboto text-left  text-sm w-full mb-5 leading-5"
                                          >
                                              {benefit}
                                          </li>
                                      );
                                  })}
                        </ol>
                    </section>
                </div>
                {/* faq sections */}
                {faq && (
                    <div
                        className={`${
                            user_data?.subscriptionInfo == 'plus' ? 'bg-black' : 'bg-white'
                        } text-left  `}
                    >
                        <div
                            className={`w-[90%]  ${
                                user_data?.subscriptionInfo == 'plus' ? 'text-white' : 'text-black'
                            } font-roboto font-medium text-base px-5 pt-5`}
                        >
                            {t('Frequently Asked Questions (FAQS)')}
                        </div>

                        <EventDetailsQuestions
                            quickLinks={faq}
                            darkMode={user_data?.subscriptionInfo == 'plus'}
                        />
                    </div>
                )}
            </div>
            <div className=" pt-5  sticky bottom-2 w-full md:w-[400px] h-auto z-50 mx-auto  ">
                <div className="bg-transparent p-5 ">
                    <ButtonPrimary
                        size="large"
                        text={'Continue'}
                        onClick={handleCTAClick}
                        // disabled={name.length < 3 || !selectedData}
                    />
                </div>
            </div>
        </div>
    );
};

export default Myprofile;
