function CopyLinkIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
            {...props}
        >
            <circle cx="26" cy="26" r="26" fill="#242F35"></circle>
            <path
                fill="#FBFCFD"
                fillRule="evenodd"
                d="M21.41 16.339a2.945 2.945 0 012.062-.839h4.555c.762 0 1.494.293 2.039.816l3.223 3.095a2.82 2.82 0 01.878 2.048v8.36c0 .759-.308 1.487-.855 2.024a2.945 2.945 0 01-2.062.839h-7.778a2.945 2.945 0 01-2.062-.839 2.838 2.838 0 01-.855-2.025V18.364c0-.76.308-1.488.855-2.025zm2.062 1.07c-.258 0-.505.1-.687.28a.946.946 0 00-.285.675v11.454c0 .253.102.496.285.675.182.18.43.28.687.28h7.778c.258 0 .505-.1.688-.28a.946.946 0 00.284-.675v-8.36a.939.939 0 00-.292-.682l-3.224-3.095a.982.982 0 00-.679-.272h-4.555z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FBFCFD"
                fillRule="evenodd"
                d="M19.583 22.182c-.257 0-.505.1-.687.28a.946.946 0 00-.285.674v10.5c0 .253.102.496.285.675.182.18.43.28.687.28h7.778c.258 0 .505-.1.688-.28a.946.946 0 00.284-.675v-1.909c0-.527.436-.954.973-.954.537 0 .972.427.972.954v1.91c0 .759-.307 1.487-.854 2.024a2.945 2.945 0 01-2.063.839h-7.778a2.945 2.945 0 01-2.062-.839 2.838 2.838 0 01-.854-2.025v-10.5c0-.76.307-1.488.854-2.024a2.944 2.944 0 012.062-.84h1.945c.537 0 .972.428.972.955a.963.963 0 01-.972.955h-1.945z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CopyLinkIcon;
