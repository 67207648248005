import { emailRegex } from '../OTPPage/utils';

export const validUserCrendential = (userDetails: any) => {
    let isValid = true;

    const errors = {
        name: '',
        email: '',
        number: ''
    };

    if (!userDetails.name) {
        errors.name = 'This field is required';
        isValid = false;
    }

    if (userDetails.name && userDetails.name.length < 3) {
        errors.name = 'Name must be at least 3 characters long';
        isValid = false;
    }

    if (!userDetails.email && !userDetails.number) {
        errors.email = 'This field is required';
        errors.number = 'This field is required';
        isValid = false;
    }

    if (userDetails.email || userDetails.number) {
        if (userDetails.email) {
            if (userDetails.email && !userDetails.email.match(emailRegex)) {
                errors.email = 'This email is invalid';
                isValid = false;
            }
        }

        if (userDetails.number) {
            if (userDetails.number && userDetails.number.length < 10) {
                errors.number = 'This Phone number must be 10 digit long';
                isValid = false;
            }
        }
    }

    return { isValid, errors };
};
