import woman from '../../assets/landing/woman.svg';
import star from '../../assets/landing/star.svg';
import downarrow from '../../assets/landing/doc-arrow-down.svg';
import rightarrow from '../../assets/landing/doc-arrow.svg';

import { useEffect, useState } from 'react';

interface DoctorHeaderType {
    specialization: string;
    name: string;
    avatar: string;
}

const DoctorHeader = (props: any) => {
    const { expert_details, rating_count } = props;

    const ratingcount = Math.floor(rating_count);
    const decimal_rating_count = rating_count - ratingcount;

    const expertInfo = expert_details;
    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            {expertInfo && expertInfo.length > 0 && (
                <div
                    className="mx-5 mt-[17.5px] flex gap-[13px] items-center justify-between"
                    onClick={() => setIsVisible(!isVisible)}
                >
                    <div className="flex gap-[12px] items-center">
                        <div>
                            <img
                                src={expertInfo[0].avatar}
                                alt="woman"
                                width={48}
                                className="rounded-full object-cover aspect-square"
                            />
                        </div>
                        <div className="flex flex-col gap-[0.5px] justify-center items-center">
                            <h2 className="text-[#262626] text-[16px] font-bold">
                                {expertInfo[0].name}
                                <p className="flex text-[#262626] text-[16px] font-normal gap-2 items-start">
                                    <span className="">
                                        {expertInfo[0].specialization.length > 30
                                            ? `${expertInfo[0].specialization.substring(0, 30)}...`
                                            : expertInfo[0].specialization}
                                    </span>
                                    <div className="min-w-[60px] flex gap-1 items-center">
                                        {rating_count > 1 && (
                                            <>
                                                <span className="flex  items-center">
                                                    {`(`}{' '}
                                                    <img src={star} alt="star_icon" width={16} />
                                                </span>
                                                <span>{`${(
                                                    ratingcount + decimal_rating_count
                                                ).toFixed(1)})`}</span>
                                            </>
                                        )}
                                    </div>
                                </p>
                            </h2>
                        </div>
                    </div>
                    <img
                        className="w-[24px] h-[24px]"
                        src={!isVisible ? rightarrow : downarrow}
                        alt="arrow-icon"
                    />
                </div>
            )}

            {isVisible && (
                <DoctorDetails
                    isVisible={isVisible}
                    expertInfo={expertInfo}
                    rating_count={rating_count}
                />
            )}
        </>
    );
};

export default DoctorHeader;

const DoctorDetails = ({
    isVisible,
    rating_count,
    expertInfo
}: {
    isVisible: boolean;
    rating_count: number;
    expertInfo: any;
}) => {
    const ratingcount = Math.floor(rating_count);
    const decimal_rating_count = rating_count - ratingcount;

    return (
        <>
            {/* <div
                className={`ml-[26px] mt-[17.5px] flex gap-[18px] items-center transition-all duration-300  ${
                    isVisible ? 'opacity-100' : 'opacity-0'
                }`}
            >
                <div>
                    <img
                        src={expertInfo[0].avatar}
                        alt="woman"
                        className="w-[75px] h-[75px] rounded-full object-cover"
                    />
                </div>
                <div className="flex flex-col gap-[3px] justify-center">
                    <h2 className="text-[#262626] text-[24px] font-bold">
                        {expertInfo[0].name}
                        <p className="flex text-[#262626] text-[18px] font-normal">
                            {`${expertInfo[0].specialization}`}
                            <img src={star} alt="star" />
                            {`(${ratingcount + decimal_rating_count})`}
                        </p>
                    </h2>
                </div>
            </div> */}
            <DoctorInfo expertInfo={expertInfo} />
        </>
    );
};

const DoctorInfo = (props: any) => {
    const { expertInfo } = props;

    const getHtml = (html: any) => {
        const originalHtmlContent = html;
        const newHtmlContent = originalHtmlContent.replace('<p>', '<p><b>About: </b>');
        return newHtmlContent;
    };

    return (
        <div className="mx-5">
            <p className="mt-[30px]">
                {expertInfo[0].details && (
                    <>
                        <div
                            className="flex"
                            dangerouslySetInnerHTML={{ __html: getHtml(expertInfo[0].details) }}
                        ></div>
                    </>
                )}
            </p>

            {expertInfo[0].education && (
                <p>
                    <span className="font-bold">Education:</span> {expertInfo[0].education}
                </p>
            )}
            {expertInfo[0].expertise && (
                <p>
                    <span className="font-bold">Expertise:</span> {expertInfo[0].expertise}
                </p>
            )}

            {/* <p className="mt-[30px]">
                <span className="text-[#242F35] text-[16px] font-bold">Education:</span> enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut
            </p> */}

            {/* <p className="mt-[30px]">
                <span className="text-[#242F35] text-[16px] font-bold">Expertise:</span> labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat Lorem ipsum dolor sit.
            </p> */}
        </div>
    );
};
