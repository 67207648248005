function GenderMale(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill={props.color}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.28 17.232A6.256 6.256 0 0011.224 7.13l4.455-4.455v2.808a.784.784 0 001.566 0v-4.7A.784.784 0 0016.462 0h-4.7a.784.784 0 000 1.566h2.808l-4.455 4.455A6.266 6.266 0 106.28 17.232zm0-10.966a4.7 4.7 0 110 9.4 4.7 4.7 0 010-9.4z"
                fill={props.color}
            />
        </svg>
    );
}

export default GenderMale;
