const FatherIcon = 'https://img1.parentune.com/web/assests_v2/FatherIcon.svg';
const MotherIcon = 'https://img1.parentune.com/web/assests_v2/Mother.svg';
const MarriedIcon = 'https://img1.parentune.com/web/assests_v2/MarriedIcon.svg';

export const StageData = {
    SetupProfileData: [
        {
            name: 'I am/We are Pregnant',
            id: 1,
            response: {
                parentType: 'Expect-Father',
                parentStage: 'Expecting'
            },
            icon: MotherIcon
        },
        {
            name: 'I have a child/have Children',
            id: 2,
            response: {
                parentType: 'Father',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        },

        {
            name: 'I am planning for Pregnancy',
            id: 3,
            response: {
                parentType: 'Planning-Father',
                parentStage: 'Planning'
            },
            icon: MotherIcon
        },
        {
            name: 'I am keen to Adopt',
            id: 4,
            response: {
                parentType: 'Planning-Father',
                parentStage: 'Planning',
                planning_stage: 'adopt',
                maxAdoptionYear: '2',
                minAdoptionYear: '1'
            },
            icon: FatherIcon
        },
        {
            name: "I am Newly Married/Unmarried (Don't Have Children/Not planning pregnancy)",
            id: 5,
            response: {
                parentType: 'Man',
                parentStage: 'Planning'
            },
            icon: MarriedIcon
        }
    ],

    PlanningForPregnancyData: [
        {
            name: 'To be mother',
            id: 1,
            response: {
                parentType: 'Planning-Mother',
                parentStage: 'Planning',
                planning_stage: 'adopt'
            },
            icon: MotherIcon
        },
        {
            name: 'To be father',
            id: 2,
            response: {
                parentType: 'Planning-Father',
                parentStage: 'Planning',
                planning_stage: 'adopt'
            },
            icon: FatherIcon
        },

        {
            name: 'To be guardian',
            id: 3,
            response: {
                parentType: 'Guardian',
                parentStage: 'Planning',
                planning_stage: 'adopt'
            },
            icon: FatherIcon
        }
    ],

    NewlyMarriedData: [
        {
            name: 'He/Him',
            id: 1,
            response: {
                parentType: 'Man',
                parentStage: 'Exploring'
            },
            icon: FatherIcon
        },
        {
            name: 'She/Her',
            id: 2,
            response: {
                parentType: 'Woman',
                parentStage: 'Exploring'
            },
            icon: MotherIcon
        }
    ],

    IamWeArePregnantData: [
        {
            name: 'Mother to a child/children',
            id: 1,
            response: {
                parentType: 'Expect-Mother',
                parentStage: 'Expecting'
            },
            icon: MotherIcon
        },
        {
            name: 'Father to a child/children',
            id: 2,
            response: {
                parentType: 'Expect-Father',
                parentStage: 'Expecting'
            },
            icon: FatherIcon
        },
        {
            name: 'Caregiver',
            id: 3,
            response: {
                parentType: 'Caregiver',
                parentStage: 'Expecting'
            },
            icon: FatherIcon
        },
        {
            name: 'Guardian',
            id: 4,
            response: {
                parentType: 'Guardian',
                parentStage: 'Expecting'
            },
            icon: FatherIcon
        },
        {
            name: 'Doctor/Child Specialist',
            id: 5,
            response: {
                parentType: 'Doctor',
                parentStage: 'Expecting'
            },
            icon: FatherIcon
        },
        {
            name: 'Teacher/ Educator',
            id: 6,
            response: {
                parentType: 'Teacher',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        }
    ],

    HaveaChildData: [
        {
            name: 'Mother to a child/children',
            id: 1,
            response: {
                parentType: 'Mother',
                parentStage: 'Parent'
            },
            icon: MotherIcon
        },
        {
            name: 'Father to a child/children',
            id: 2,
            response: {
                parentType: 'Father',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        },
        {
            name: 'Caregiver',
            id: 3,
            response: {
                parentType: 'Caregiver',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        },
        {
            name: 'Guardian',
            id: 4,
            response: {
                parentType: 'Guardian',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        },
        {
            name: 'Doctor/Child Specialist',
            id: 5,
            response: {
                parentType: 'Doctor',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        },
        {
            name: 'Teacher/ Educator',
            id: 6,
            response: {
                parentType: 'Teacher',
                parentStage: 'Parent'
            },
            icon: FatherIcon
        }
    ],

    ForKeenToAdoptData: [
        {
            name: 'Mother to a child/children',
            id: 1,
            response: {
                parentType: 'Planning-Mother',
                parentStage: 'Planning',
                planning_stage: 'adopt'
            },
            icon: MotherIcon
        },
        {
            name: 'Father to a child/children',
            id: 2,
            response: {
                parentType: 'Planning-Father',
                parentStage: 'Planning',
                planning_stage: 'adopt'
            },
            icon: FatherIcon
        },
        {
            name: 'Guardian',
            id: 3,
            response: {
                parentType: 'Guardian',
                parentStage: 'Planning',
                planning_stage: 'adopt'
            },
            icon: FatherIcon
        }
    ]
};
