function CourseRating(props: any) {
    const { rating_count } = props;

    const ratingcount = Math.floor(rating_count);
    const decimal_rating_count = rating_count - ratingcount;
    const fullRating = ratingcount + decimal_rating_count;

    if (rating_count < 1) {
        return null;
    }

    const getRating = (num: number) => {
        const ratinglist: JSX.Element[] = [];

        for (let i = 1; i <= 5; i++) {
            if (i <= num) {
                ratinglist.push(filledStar); // Full star
            } else if (i === Math.ceil(num)) {
                ratinglist.push(halfStar); // Half star
            } else {
                ratinglist.push(unfilledStar); // Empty star
            }
        }

        return ratinglist;
    };

    return (
        <div className="mt-[24px] mb-[28px] mx-5 flex gap-4 items-center">
            <div className="text-[#242F35] text-[18px] font-normal">
                <span className="font-medium">Course Rating </span> {fullRating.toFixed(1)}
            </div>
            <div className="flex gap-2">
                {getRating(fullRating).map((item: JSX.Element) => item)}
            </div>
        </div>
    );
}

export default CourseRating;

const filledStar = (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.04894 1.25713C9.3483 0.335819 10.6517 0.335818 10.9511 1.25713L12.4697 5.93089C12.6035 6.34291 12.9875 6.62187 13.4207 6.62187H18.335C19.3037 6.62187 19.7065 7.86149 18.9228 8.43089L14.947 11.3194C14.5966 11.5741 14.4499 12.0254 14.5838 12.4375L16.1024 17.1112C16.4017 18.0325 15.3472 18.7987 14.5635 18.2293L10.5878 15.3407C10.2373 15.0861 9.7627 15.0861 9.41221 15.3407L5.43648 18.2293C4.65276 18.7987 3.59828 18.0325 3.89763 17.1112L5.41623 12.4375C5.55011 12.0254 5.40345 11.5741 5.05296 11.3194L1.07722 8.43089C0.293507 7.86149 0.696283 6.62187 1.66501 6.62187H6.57929C7.01252 6.62187 7.39647 6.34291 7.53035 5.93089L9.04894 1.25713Z"
            fill="#FE992F"
        />
    </svg>
);

const unfilledStar = (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.52447 1.41164C9.67415 0.950982 10.3259 0.950984 10.4755 1.41164L11.9941 6.0854C12.1949 6.70343 12.7709 7.12187 13.4207 7.12187H18.335C18.8194 7.12187 19.0207 7.74168 18.6289 8.02638L14.6531 10.9149C14.1274 11.2969 13.9074 11.9739 14.1082 12.592L15.6268 17.2657C15.7765 17.7264 15.2493 18.1095 14.8574 17.8248L10.8817 14.9362C10.3559 14.5542 9.64405 14.5542 9.11832 14.9362L5.14258 17.8248C4.75073 18.1095 4.22349 17.7264 4.37316 17.2657L5.89176 12.592C6.09257 11.9739 5.87258 11.2969 5.34685 10.9149L1.37111 8.02638C0.979257 7.74168 1.18064 7.12187 1.66501 7.12187H6.57929C7.22913 7.12187 7.80506 6.70343 8.00587 6.0854L9.52447 1.41164Z"
            stroke="#787878"
        />
    </svg>
);

const halfStar = (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.52447 1.41164C9.67415 0.950982 10.3259 0.950984 10.4755 1.41164L11.9941 6.0854C12.1949 6.70343 12.7709 7.12187 13.4207 7.12187H18.335C18.8194 7.12187 19.0207 7.74168 18.6289 8.02638L14.6531 10.9149C14.1274 11.2969 13.9074 11.9739 14.1082 12.592L15.6268 17.2657C15.7765 17.7264 15.2493 18.1095 14.8574 17.8248L10.8817 14.9362C10.3559 14.5542 9.64405 14.5542 9.11832 14.9362L5.14258 17.8248C4.75073 18.1095 4.22349 17.7264 4.37316 17.2657L5.89176 12.592C6.09257 11.9739 5.87258 11.2969 5.34685 10.9149L1.37111 8.02638C0.979257 7.74168 1.18064 7.12187 1.66501 7.12187H6.57929C7.22913 7.12187 7.80506 6.70343 8.00587 6.0854L9.52447 1.41164Z"
            stroke="#787878"
        />
        <path
            d="M9.6162 0.709533C9.87855 0.664417 10.0005 0.976914 10.0005 1.24312L10.0006 10.3273V11.7436V12.982V14.4013C10.0006 14.7214 9.84732 15.0222 9.58835 15.2103L5.43704 18.2264C4.65333 18.7958 3.59885 18.0297 3.8982 17.1084L5.4168 12.4346C5.55067 12.0226 5.40402 11.5712 5.05353 11.3166L1.07779 8.42805C0.294076 7.85865 0.69685 6.61903 1.66557 6.61903H6.54651C6.99562 6.61903 7.38937 6.32098 7.51316 5.88926C7.87736 4.61907 8.62974 2.06663 9.00049 1.32724C9.21979 0.889882 9.43909 0.73999 9.6162 0.709533Z"
            fill="#FE992F"
        />
    </svg>
);
