function UserIcon(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 19.143A9.143 9.143 0 1010 .857a9.143 9.143 0 000 18.286z"
                stroke="#73787D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.857 15.714C16.1 13.115 13.2 12.257 10 12.257c-3.117 0-6.023.993-6.857 3.457"
                stroke="#73787D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M10 3.143a3.428 3.428 0 013.428 3.428v2.286a3.428 3.428 0 11-6.857 0V6.57A3.429 3.429 0 0110 3.143v0z"
                stroke="#73787D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default UserIcon;
