function WhatsappShareIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
            {...props}
        >
            <circle cx="26" cy="26" r="26" fill="#4ECB5C"></circle>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M34.721 17.27a12.207 12.207 0 00-8.67-3.577c-6.754 0-12.253 5.471-12.255 12.197a12.122 12.122 0 001.636 6.1l-1.74 6.318 6.498-1.696a12.287 12.287 0 005.857 1.483h.005c6.754 0 12.252-5.472 12.255-12.197a12.097 12.097 0 00-3.586-8.628zm-8.67 18.766h-.004c-1.823 0-3.613-.487-5.184-1.413l-.372-.22-3.855 1.007 1.029-3.741-.243-.385a10.079 10.079 0 01-1.557-5.394c0-5.589 4.572-10.137 10.19-10.137a10.165 10.165 0 0110.183 10.145c-.002 5.59-4.572 10.138-10.186 10.138zm5.588-7.592c-.306-.153-1.813-.89-2.092-.992-.28-.102-.485-.153-.69.153-.203.305-.79.989-.969 1.195-.179.206-.357.228-.663.075-.306-.152-1.294-.474-2.463-1.512-.91-.808-1.525-1.806-1.704-2.111-.178-.305-.019-.47.134-.622.138-.137.307-.356.46-.534.153-.178.204-.305.306-.509.102-.203.051-.381-.025-.533-.077-.152-.69-1.653-.944-2.263-.25-.595-.501-.514-.69-.524a12.424 12.424 0 00-.587-.01 1.128 1.128 0 00-.816.38c-.28.306-1.072 1.045-1.072 2.544 0 1.498 1.099 2.949 1.25 3.152.152.204 2.16 3.282 5.232 4.602.57.245 1.153.459 1.746.642.733.232 1.4.2 1.928.121.589-.087 1.813-.737 2.068-1.45.254-.712.255-1.321.178-1.448-.076-.127-.28-.204-.587-.356z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default WhatsappShareIcon;
