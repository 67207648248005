// @ts-nocheck
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, memo } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Mousewheel, Pagination, Navigation, Controller } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import './ChoosePlanCard2.css';
import { useUtilsData } from '../../utils/hooks/useUtislData';

function ChoosePlanCard2(props: any) {
    const { data, currency_symbol, setcurrentCardData, setcurrentCardIndex } = props;
    const navigate = useNavigate();

    const {
        location: { country }
    } = useUtilsData();

    const [controlledSwiper1, setControlledSwiper1] = useState(null);
    const [controlledSwiper2, setControlledSwiper2] = useState(null);

    // const [currentSwiperSlideIndex, setcurrentSwiperSlideIndex] = useState(0);
    // const [isBeginning, setIsBeginning] = useState(true);

    const SvgCard = ({ id }) => {
        if (id == 1)
            return (
                <svg
                    className="w-full "
                    // width={311}
                    // width={window.innerWidth - 120}
                    height={window.innerWidth > 700 ? 90 : 'auto'}
                    viewBox="0 0 311 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x={311}
                        y="82.9609"
                        width="310.999"
                        height="82.9609"
                        rx={10}
                        transform="rotate(-180 311 82.9609)"
                        // fill="#3C8A97"
                        fill={'#3C8A97'}
                    />
                    <path
                        opacity="0.8"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M232.137 27.1201C229.954 27.9259 227.736 28.7447 225.483 29.5746C207.216 36.3043 194.61 42.9901 183.886 48.6781C162.452 60.047 148.532 67.4296 111.943 63.2086C72.1238 58.615 56.0427 50.66 45.484 45.4367C37.488 41.4812 32.6589 39.0923 23.0859 40.9164C18.8078 41.7315 11.279 47.2834 3.37097 54.0924C1.21033 55.9528 0.000978677 58.6776 0.000978429 61.5287L0.000977433 72.9614C0.000976952 78.4842 4.47812 82.9614 10.001 82.9614L301 82.9614C306.523 82.9614 311 78.4843 311 72.9614L311 14.2873C311 9.87753 308.064 5.92939 303.655 5.99004C288.827 6.19403 264.305 15.2458 232.137 27.1201Z"
                        // fill="#217786"
                        // fill="#FD9095"
                        fill={'#217786'}
                    />
                    <g style={{ mixBlendMode: 'soft-light' }}>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M34.8357 69.2784C44.3241 64.949 56.0571 59.5955 70.9988 53.8961C96.031 44.3478 135.733 51.888 173.556 59.0715C217.598 67.4361 259.094 75.3171 271.917 55.171C272.442 54.3465 273.035 53.5347 273.733 52.8504C294.249 32.7298 299.832 30.3953 304.838 28.3016C305.328 28.0971 305.811 27.8948 306.303 27.6785C309.367 26.3301 311 23.1012 311 19.7537L311 5.06384C311 2.26717 308.733 1.23766e-05 305.936 1.22626e-05L5.06434 0C2.26766 -1.13984e-07 0.000506511 2.26715 0.000506417 5.06382L0.000503176 71.5642C0.000502965 75.8906 2.86466 79.9113 7.16877 79.473C14.0229 78.775 22.5468 74.8857 34.8357 69.2784Z"
                            // fill="#095B69"
                            // fill="#D8606D"
                            fill={'#095B69'}
                        />
                    </g>
                </svg>
            );
        else if (id == 3) {
            return (
                <svg
                    className="w-full"
                    // width={311}
                    // width={window.innerWidth - 120}
                    // height={83}
                    height={window.innerWidth > 700 ? 90 : 'auto'}
                    viewBox="0 0 311 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x={311}
                        y="82.9609"
                        width="310.999"
                        height="82.9609"
                        rx={10}
                        transform="rotate(-180 311 82.9609)"
                        // fill="#3C8A97"
                        fill={'#FA7681'}
                    />
                    <path
                        opacity="0.8"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M232.137 27.1201C229.954 27.9259 227.736 28.7447 225.483 29.5746C207.216 36.3043 194.61 42.9901 183.886 48.6781C162.452 60.047 148.532 67.4296 111.943 63.2086C72.1238 58.615 56.0427 50.66 45.484 45.4367C37.488 41.4812 32.6589 39.0923 23.0859 40.9164C18.8078 41.7315 11.279 47.2834 3.37097 54.0924C1.21033 55.9528 0.000978677 58.6776 0.000978429 61.5287L0.000977433 72.9614C0.000976952 78.4842 4.47812 82.9614 10.001 82.9614L301 82.9614C306.523 82.9614 311 78.4843 311 72.9614L311 14.2873C311 9.87753 308.064 5.92939 303.655 5.99004C288.827 6.19403 264.305 15.2458 232.137 27.1201Z"
                        // fill="#217786"
                        // fill="#FD9095"
                        fill={'#FD9095'}
                    />
                    <g style={{ mixBlendMode: 'soft-light' }}>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M34.8357 69.2784C44.3241 64.949 56.0571 59.5955 70.9988 53.8961C96.031 44.3478 135.733 51.888 173.556 59.0715C217.598 67.4361 259.094 75.3171 271.917 55.171C272.442 54.3465 273.035 53.5347 273.733 52.8504C294.249 32.7298 299.832 30.3953 304.838 28.3016C305.328 28.0971 305.811 27.8948 306.303 27.6785C309.367 26.3301 311 23.1012 311 19.7537L311 5.06384C311 2.26717 308.733 1.23766e-05 305.936 1.22626e-05L5.06434 0C2.26766 -1.13984e-07 0.000506511 2.26715 0.000506417 5.06382L0.000503176 71.5642C0.000502965 75.8906 2.86466 79.9113 7.16877 79.473C14.0229 78.775 22.5468 74.8857 34.8357 69.2784Z"
                            // fill="#095B69"
                            // fill="#D8606D"
                            fill={'#D8606D'}
                        />
                    </g>
                </svg>
            );
        } else
            return (
                <svg
                    // width={310}
                    className="w-full"
                    // width={window.innerWidth - 120}
                    // height={83}
                    height={window.innerWidth > 700 ? 90 : 'auto'}
                    viewBox="0 0 310 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x={310}
                        y={83}
                        width={310}
                        height={83}
                        rx="5.06383"
                        transform="rotate(-180 310 83)"
                        fill="url(#paint0_linear_12_473)"
                    />
                    <path
                        opacity="0.8"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M231.391 27.1324C229.215 27.9386 227.004 28.7578 224.758 29.5881C206.55 36.3209 193.985 43.0099 183.295 48.7006C161.93 60.0748 148.055 67.461 111.583 63.238C71.8922 58.6422 55.8628 50.6834 45.338 45.4577C37.3677 41.5003 32.5541 39.1103 23.0118 40.9352C18.1444 41.8661 9.05549 48.9712 0.00128551 57.0734L0.0012837 77.9362C0.00128346 80.7329 2.26846 83 5.06513 83L304.937 83.0001C307.734 83.0001 310.001 80.7329 310.001 77.9362L310.001 6.86308C296.852 2.87922 269.507 13.0107 231.391 27.1324Z"
                        fill={'#FE992F'}
                    />
                    <g style={{ mixBlendMode: 'soft-light' }}>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M34.7234 69.3113C44.1813 64.9799 55.8765 59.6238 70.7703 53.9217C95.722 44.3689 135.296 51.9127 172.998 59.0996C217.799 67.6398 259.956 75.676 271.793 53.9217C293.113 32.8253 298.783 30.4456 303.858 28.3152C305.733 27.5282 307.527 26.7752 310 25.125L310 5.06396C310 2.26728 307.732 0.000129607 304.936 0.000129493L5.0639 0.000117225C2.26723 0.000117111 7.32254e-05 2.26727 7.31313e-05 5.06395L7.06484e-05 78.8548C8.09152 81.508 17.8448 77.0413 34.7234 69.3113Z"
                            fill={'#FE992F'}
                        />
                    </g>
                    <defs>
                        <linearGradient
                            id="paint0_linear_12_473"
                            x1="273.188"
                            y1={83}
                            x2="445.038"
                            y2="302.63"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor={'#FCC978'} />
                            <stop offset={1} stopColor={'#F68B45'} />
                        </linearGradient>
                    </defs>
                </svg>
            );
    };

    return (
        <div className=" w-full pt-3">
            <section
                className={`h-auto w-full relative ${
                    window.innerWidth > 700 ? 'flex justify-center items-center' : ''
                }`}
            >
                <Swiper
                    // @ts-ignore
                    onSwiper={setControlledSwiper1}
                    // @ts-ignore
                    controller={{ control: controlledSwiper2 }}
                    id="small-carousel"
                    style={{
                        width: `${window.innerWidth > 700 ? 375 : window.innerWidth}px`
                    }}
                    onSlideChange={(e) => {
                        // handlePlayPause();
                        // console.log('slide change', e);
                        // setIsBeginning(e.isBeginning);
                        // setcurrentSwiperSlideIndex(e.activeIndex);
                        setcurrentCardIndex(e.activeIndex);
                        setcurrentCardData(data[e.activeIndex]);
                    }}
                    // pagination={true}
                    pagination={{
                        clickable: true
                    }}
                    // spaceBetween={14}
                    spaceBetween={window.innerWidth / 30.4286}
                    // spaceBetween={-28}
                    effect="creative"
                    // slidesPerView={2}
                    // loopFillGroupWithBlank={true}
                    // breakpoints={{
                    //     slidesPerView: 2,
                    //     spaceBetween: 20
                    // }}
                    mousewheel={{ forceToAxis: true }}
                    modules={[Pagination, Navigation, Mousewheel, Controller]}
                    className="flex justify-center  items-center choose-plan-card carousel-small "
                    slidesPerView={1.1}
                    centeredSlides={true}
                >
                    {data &&
                        data.length > 0 &&
                        data.map((smallCarditem: any, index: number) => {
                            const cost =
                                country !== 'IN'
                                    ? smallCarditem?.cost_per_day_new
                                    : smallCarditem?.cost_per_day;
                            return (
                                <SwiperSlide
                                    // onClick={() => {
                                    //     if (currentCardIndex == 2) return navigate('/');
                                    //     navigate(`/choosePlan/${currentCardIndex}`);
                                    // }}
                                    className="mb-12 "
                                    key={index}
                                >
                                    <div className="w-full relative ">
                                        <SvgCard id={index + 1} />
                                        <main className="absolute top-0 left-0 w-full h-full  text-white flex justify-center items-center">
                                            <section className="">
                                                {' '}
                                                <div className="font-roboto font-semibold text-[28px]">
                                                    {smallCarditem?.plan_name}
                                                </div>
                                            </section>
                                        </main>
                                        <div className="absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-roboto text-[10px] font-normal">
                                            {/* smallCarditem?.cost_per_day_new */}
                                            {cost == 0 ? (
                                                <p>PAY AS YOU USE</p>
                                            ) : (
                                                <div>
                                                    {currency_symbol} {cost}/ DAY
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={`absolute top-2 right-[10%] text-white font-roboto text-[10px] font-normal`}
                                        >
                                            {index == 0
                                                ? 'RECOMMENDED'
                                                : index == 1
                                                ? 'POPULAR'
                                                : null}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
                <Swiper
                    id="big-carousel"
                    // @ts-ignore
                    onSwiper={setControlledSwiper2}
                    // @ts-ignore
                    controller={{ control: controlledSwiper1 }}
                    onSlideChange={(e) => {
                        // handlePlayPause();
                        // console.log('slide change', e);
                        // setIsBeginning(e.isBeginning);
                        // setcurrentSwiperSlideIndex(e.activeIndex);
                        // setcurrentCardIndex(e.activeIndex);
                        // setcurrentCardData(data[e.activeIndex]);
                    }}
                    // spaceBetween={14}
                    spaceBetween={window.innerWidth / 27.4286}
                    // spaceBetween={-28}
                    effect="creative"
                    // slidesPerView={2}
                    // loopFillGroupWithBlank={true}
                    // breakpoints={{
                    //     slidesPerView: 2,
                    //     spaceBetween: 20
                    // }}
                    mousewheel={{ forceToAxis: true }}
                    modules={[Navigation, Mousewheel, Controller]}
                    className="flex justify-center  items-center choose-plan-card carousel-big"
                    slidesPerView={1}
                    centeredSlides={true}
                >
                    {data &&
                        data.length > 0 &&
                        data.map((cardItem: any, index: number) => (
                            <SwiperSlide
                                // onClick={() => {
                                //     if (currentCardIndex == 2) return navigate('/');
                                //     navigate(`/choosePlan/${currentCardIndex}`);
                                // }}
                                className="mb-0 "
                                key={index}
                            >
                                <div className="w-full">
                                    {/* <div className="h-[148px] w-full bg-indigo-300"></div> */}
                                    <img
                                        src={
                                            cardItem?.banner ??
                                            'https://parntune-assets.b-cdn.net/images/plus_plan_banner.png'
                                        }
                                        alt=""
                                        // md:max-h-fit
                                        className="w-full mx-0 overflow-x-hidden  !h-[148px]   object-contain"
                                    />
                                    <div className="h-44 w-full bg-transparent"></div>
                                    {cardItem?.claims && (
                                        <h1 className="font-bold font-roboto text-xl mt-7 mx-6">
                                            {cardItem?.claims}
                                        </h1>
                                    )}
                                    {cardItem?.description && (
                                        <ul className="space-y-2 font-roboto text-sm  text-left w-full  px-8  mt-4 mb-5 ">
                                            {cardItem?.description?.data_annual?.map(
                                                (item: any, index: number) => (
                                                    <li
                                                        key={index}
                                                        className={`flex items-center justify-start w-full  ${
                                                            item.isLocked == 'True'
                                                                ? 'space-x-10'
                                                                : 'space-x-9'
                                                        }  space-y-2`}
                                                    >
                                                        {item?.isLocked !== 'True' ? (
                                                            <svg
                                                                width={16}
                                                                height={12}
                                                                viewBox="0 0 16 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M14.3333 1L5.16667 10.1667L1 6"
                                                                    stroke="#464646"
                                                                    strokeWidth={2}
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                width="11"
                                                                height="15"
                                                                viewBox="0 0 11 15"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.0833 5.625H9.16667V3.75C9.16667 1.67906 7.52492 0 5.5 0C3.47508 0 1.83333 1.67906 1.83333 3.75V5.625H0.916667C0.409979 5.625 0 6.0443 0 6.5625V14.0625C0 14.5807 0.409979 15 0.916667 15H10.0833C10.59 15 11 14.5807 11 14.0625V6.5625C11 6.0443 10.59 5.625 10.0833 5.625ZM5.5 11.25C4.99331 11.25 4.58333 10.8307 4.58333 10.3125C4.58333 9.7943 4.99331 9.375 5.5 9.375C6.00669 9.375 6.41667 9.7943 6.41667 10.3125C6.41667 10.8307 6.00669 11.25 5.5 11.25ZM7.33333 5.625H3.66667V3.75C3.66667 2.71453 4.48754 1.875 5.5 1.875C6.51246 1.875 7.33333 2.71453 7.33333 3.75V5.625Z"
                                                                    fill="#464646"
                                                                />
                                                            </svg>
                                                        )}
                                                        <p className={` text-left `}>
                                                            {item?.text}
                                                        </p>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </section>
        </div>
    );
}

export default ChoosePlanCard2;
