import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface ModelType {
    closeModel: any;
    children: ReactNode;
}

function DialogModel({ closeModel, children }: ModelType) {
    const DialogModel = (
        <div onClick={closeModel} className="modelContainer">
            <div className="model">{children}</div>
        </div>
    );

    return createPortal(DialogModel, document.body);
}

export default DialogModel;
