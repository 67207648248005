export const dummyData = {
    content: [
        {
            answer_text: 'Answer for testing purpose',
            content_id: 160246,
            content_order: 1,
            content_type: 'question',
            content_url:
                'https://pg.parentune.com/expert-corner/how-to-introduce-solid-foods-in-my-babies/160246',
            custom_title: '6days baby boy tests?',
            duration: '1',
            id: 160246,
            image: 'https://img1.parentune.com/images/dailyfocus/0-3-Adoption.png',
            lang: 'en',
            milestone_id: 1,
            question_text: 'How to introduce solid Foods in my babies',
            week: 0
        },
        {
            content_id: 7741,
            content_order: 1,
            content_type: 'blog',
            content_url:
                'http://www.parentune.com/parent-blog/1-week-key-milestones-in-your-baby/7741',
            custom_title: 'Retina begins to form',
            id: 11441,
            lang: 'en',
            milestone_id: 1,
            week: 0
        },
        {
            content_id: 499,
            content_order: 2,
            content_type: 'workshop',
            content_url:
                'https://pg.parentune.com/landing-page/essential-tests-during-pregnancy/499',
            custom_title: 'Choosing a 6 in 1 Vaccine for your newborn',
            duration: 1,
            id: 11440,
            itemId: 499,
            lang: 'en',
            milestone_id: 1,
            thumbImage:
                'https://img1.parentune.com/images/live-session/thumbnail_1636202109Preg.jpg',
            title: 'Essential Tests You Must Get During Pregnancy',
            week: 0
        },
        {
            content_id: 83381,
            content_order: 3,
            content_type: 'talk',
            content_url: 'https://www.parentune.com/parent-talk/baby-recognise/83381',
            custom_title: 'When will baby start recognising?',
            description:
                "When does a baby recognise her mother or is there any special emotions for her mom?  My baby is 7 month old she doesn't have any special emotions for me.... She doesn't keep quiet by me when she cry... She just show special emotions when she need milk...",
            duration: '1',
            id: 83381,
            image: 'https://img1.parentune.com/images/dailyfocus/Parents-talk.png',
            lang: 'en',
            milestone_id: 1,
            title: 'Baby recognise',
            week: 0
        }
    ],
    static: {
        awards: {
            images: [
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/1.png',
                    text: 'WSJ Global Showcase'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/2.png',
                    text: 'NASSCOM EMERGE 50'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/3.png',
                    text: 'SuperStartups'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/4.png',
                    text: 'BW Businessworld'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/5.png',
                    text: 'NASSCOM EMERGE 50'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/6.png',
                    text: 'NASSCOM EMERGE 50'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/7.png',
                    text: 'NASSCOM EMERGE 50'
                },
                {
                    image: 'https://img1.parentune.com/web-home-page/awards/8.png',
                    text: 'NASSCOM EMERGE 50'
                }
            ],
            sub_text: 'Awarded & Recognised Globally',
            title_text: 'Get 24x7 Support with Parentune PLUS'
        },
        prompt: {
            components: [
                {
                    description: 'Chat with Child Specialists, get verified & prompt answers. ',
                    icon: 'https://img1.parentune.com/images/homepage/dfprompt/Frame%203540.png',
                    image: 'https://img1.parentune.com/images/homepage/support/Blog-1.png',
                    sub_text: '96% queries solved in 59 min',
                    title_text: 'Ask Doctors Anytime- 24 x 7'
                },
                {
                    description: 'Unlimited access, comprehensive understanding',
                    icon: 'https://img1.parentune.com/images/homepage/dfprompt/Frame%203540(1).png',
                    image: 'https://img1.parentune.com/images/homepage/support/Talks.png',
                    sub_text: "5000+ hrs of videos I Concepts mapped to your child's age",
                    title_text: 'Learn Important Concepts '
                }
            ],
            title_text: 'The most prompt & verified support for parents '
        }
    },
    user: {
        avatar: 'https://img1.parentune.com/images/avatar/m_1375683032.jpg',
        name: 'Bhavna'
    }
};

export const dropDownData = [
    {
        ageGroup: 'pragnency',
        ageGroupId: '1',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Child Safety', id: '10' }
        ]
    },
    {
        ageGroup: 'for_0-1_years',
        ageGroupId: '2',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Learning & Education', id: '5' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Behaviour', id: '9' },
            { name: 'Child Safety', id: '10' }
        ]
    },
    {
        ageGroup: 'for_1-3_years',
        ageGroupId: '3',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Learning & Education', id: '5' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Behaviour', id: '9' },
            { name: 'Child Safety', id: '10' },
            { name: 'Hobbies & Sports', id: '11' },
            { name: 'Morals & Value System', id: '12' },
            { name: 'Gadgets & Screen time', id: '13' }
        ]
    },
    {
        ageGroup: 'for_3-7_years',
        ageGroupId: '4',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Learning & Education', id: '5' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Behaviour', id: '9' },
            { name: 'Child Safety', id: '10' },
            { name: 'Hobbies & Sports', id: '11' },
            { name: 'Morals & Value System', id: '12' },
            { name: 'Gadgets & Screen time', id: '13' }
        ]
    },
    {
        ageGroup: 'for_7-11_years',
        ageGroupId: '5',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Learning & Education', id: '5' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Behaviour', id: '9' },
            { name: 'Child Safety', id: '10' },
            { name: 'Hobbies & Sports', id: '11' },
            { name: 'Morals & Value System', id: '12' },
            { name: 'Gadgets & Screen time', id: '13' }
        ]
    },
    {
        ageGroup: 'for_11-16_years',
        ageGroupId: '6',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Learning & Education', id: '5' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Behaviour', id: '9' },
            { name: 'Child Safety', id: '10' },
            { name: 'Hobbies & Sports', id: '11' },
            { name: 'Morals & Value System', id: '12' },
            { name: 'Gadgets & Screen time', id: '13' }
        ]
    },
    {
        ageGroup: 'adoption',
        ageGroupId: '8',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Learning & Education', id: '5' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' },
            { name: 'Behaviour', id: '9' },
            { name: 'Child Safety', id: '10' }
        ]
    },
    {
        ageGroup: 'conceiving',
        ageGroupId: '9',
        interestCluster: [
            { name: 'Health', id: '1' },
            { name: 'Nutrition', id: '2' },
            { name: 'Babycare', id: '3' },
            { name: 'Mental Health & Wellness', id: '4' },
            { name: 'Special Needs', id: '6' },
            { name: 'Reviews', id: '7' },
            { name: 'Environment', id: '8' }
        ]
    }
];

export const liveChatData = {
    live_events: [
        {
            amount: null,
            description:
                '<p>Vaccination is important during pregnancy for both, the mom-to-be and the baby. Ask all about pregnancy vaccination in this Expert live session.</p>\r\n',
            expert_details: [
                {
                    avatar: 'https://max-website20-images.s3.ap-south-1.amazonaws.com/DSC_01204_38f2cb3802.JPG',
                    desc: 'Dr. Meeta Singh, Co Director, Consultant, Obstetric and Gynaecology,Tanvir Hospital',
                    details:
                        '<p>Dr. Meeta Singh, Co Director, Consultant, Obstetric and Gynaecology,Tanvir Hospital</p>\r\n',
                    name: 'Dr. Meeta Singh',
                    specialization:
                        'Dr. Meeta Singh, Co Director, Consultant, Obstetric and Gynaecology,Tanvir Hospital'
                },
                {
                    avatar: 'https://max-website20-images.s3.ap-south-1.amazonaws.com/DSC_01204_38f2cb3802.JPG',
                    desc: 'Dr. Meeta Singh, Co Director, Consultant, Obstetric and Gynaecology,Tanvir Hospital',
                    details:
                        '<p>Dr. Meeta Singh, Co Director, Consultant, Obstetric and Gynaecology,Tanvir Hospital</p>\r\n',
                    name: 'Dr. Meeta Singh',
                    specialization:
                        'Dr. Meeta Singh, Co Director, Consultant, Obstetric and Gynaecology,Tanvir Hospital'
                }
            ],
            id: 7,
            isBookmarked: false,
            name: 'Pregnancy',
            primary_age_group: 1,
            summary: '',
            thumb_image:
                'https://img1.parentune.com/images/live-session/thumbnail_1635345918Preg-Oct30th.jpg',
            title: '3-in-1 Protection during pregnancy',
            url: '3-in-1-protection-during-pregnancy-ask-doctor'
        },
        {
            amount: null,
            description:
                '<p>Unable to visit your Gynaecologist during the Lockdown? Join us to ask all your questions about pregnancy and gynaecological issues.</p>\r\n',
            expert_details: [
                {
                    avatar: 'https://max-website20-images.s3.ap-south-1.amazonaws.com/DSC_01204_38f2cb3802.JPG',
                    desc: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist',
                    details: '<p>Dr Lalita Mayadeo, Obstetriician and Gynaecologist</p>\r\n',
                    name: 'Dr Lalita Mayadeo',
                    specialization: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist'
                }
            ],
            id: 36,
            isBookmarked: true,
            name: 'Pregnancy',
            primary_age_group: 1,
            summary: 'Ask The Gynaecologist',
            thumb_image:
                'https://img1.parentune.com/images/live-session/thumbnail_163513407725th-Oct-Preg.jpg',
            title: 'Ask The Gynaecologist',
            url: 'ask-the-gynaecologist'
        },
        {
            amount: null,
            description:
                '<p>Unable to visit your Gynaecologist during the Lockdown? Join us to ask all your questions about pregnancy and gynaecological issues.</p>\r\n',
            expert_details: [
                {
                    avatar: 'https://max-website20-images.s3.ap-south-1.amazonaws.com/DSC_01204_38f2cb3802.JPG',
                    desc: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist',
                    details: '<p>Dr Lalita Mayadeo, Obstetriician and Gynaecologist</p>\r\n',
                    name: 'Dr Lalita Mayadeo',
                    specialization: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist'
                },
                {
                    avatar: 'https://max-website20-images.s3.ap-south-1.amazonaws.com/DSC_01204_38f2cb3802.JPG',
                    desc: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist',
                    details: '<p>Dr Lalita Mayadeo, Obstetriician and Gynaecologist</p>\r\n',
                    name: 'Dr Lalita Mayadeo',
                    specialization: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist'
                },
                {
                    avatar: 'https://max-website20-images.s3.ap-south-1.amazonaws.com/DSC_01204_38f2cb3802.JPG',
                    desc: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist',
                    details: '<p>Dr Lalita Mayadeo, Obstetriician and Gynaecologist</p>\r\n',
                    name: 'Dr Lalita Mayadeo',
                    specialization: 'Dr Lalita Mayadeo, Obstetriician and Gynaecologist'
                }
            ],
            id: 36,
            isBookmarked: true,
            name: 'Pregnancy',
            primary_age_group: 1,
            summary: 'Ask The Gynaecologist',
            thumb_image:
                'https://img1.parentune.com/images/live-session/thumbnail_163513407725th-Oct-Preg.jpg',
            title: 'Ask The Gynaecologist',
            url: 'ask-the-gynaecologist'
        }
    ],
    next_page: false
};
