import axios from 'axios';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';

const SelectedLanguage = Geti18nLang();
export const WorkShopLogCountHandler = async ({
    user_data,
    workshopId
}: {
    user_data: any;
    workshopId: string;
}) => {
    const API: any = process.env.REACT_APP_LIVEEVENTS_SERVER + 'logWatchCount';

    const res = await axios.post(API, null, {
        headers: {
            lang: SelectedLanguage || 'en',
            instanceId: user_data?.instanceId?.toString() || '',
            authToken: user_data?.authToken?.toString() || '',
            workshopId: workshopId
        }
    });
    return res.data;
};
