import { useState } from 'react';
import './AskQuestionExpert.css';
import { lazyWithRetry } from '../../../utils/LazyWithRetry';
import { GrClose } from 'react-icons/gr';
const Header = lazyWithRetry(() => import('../../Header/Header'));
const ButtonSecondry1 = lazyWithRetry(() => import('../../V2Components/ButtonSecondry/index1'));
const ButtonPrimary = lazyWithRetry(() => import('../../V2Components/ButtonPrimary'));
const BreadCrumbs = lazyWithRetry(() => import('../../V2Components/BreadCrumbs'));
const REACT_APP_ASKEXPERTS_SERVER = process.env.REACT_APP_ASKEXPERTS_SERVER;

import Overlay from '../../Overlay/Overlay';
import { t } from 'i18next';
import { SendEvents } from '../../../utils/utils';
import { useUserData } from '../../../utils/hooks/useUserData';

const img = 'https://img1.parentune.com/web/assets/images/Icon/AddPhoto.svg';
const Next = ({
    photo,
    content,
    disabled,
    col,
    col1,
    onclick,
    isLoading,
    show1,
    file,
    setImagePrivacy,
    setFile,
    isForTalk
}: any) => {
    const { userData: user_data } = useUserData();

    const _isForTalk = isForTalk ?? false;
    const handleupload = (e: any) => {
        console.log(e.target.files.length);
        if (e.target.files.length > 0) {
            setFile([...file, ...e.target.files]);
        }
        // setFile(e.target.files);
    };
    const [isChecked, setisChecked] = useState(false);

    const removeitem = (i: any) => {
        setFile([...file.filter((_: any, idx: any) => idx != i)]);
    };
    // console.log(isLoading);

    return (
        <div
            className=" justify-between p-4 pt-5"
            style={{
                maxWidth: '1400px',
                width: '100%',
                left: 0
            }}
        >
            <div className="2xl:ml-[150px] ml-[5px] mb-5 grid 2xl:grid-cols-8 lg:grid-cols-6 grid-cols-4 gap-4 ">
                {photo &&
                    file.length > 0 &&
                    file.map((val: any, index: any) => (
                        <div key={index}>
                            <div className="relative">
                                <div className="absolute top-0 right-2  ml-8 lg:ml-[86px] flex bg-white rounded-full w-6 h-6  justify-center items-center lg:h-8 lg:w-8">
                                    <GrClose
                                        className="cursor-pointer text-[13px]"
                                        onClick={() => removeitem(index)}
                                    />
                                </div>
                                <img
                                    src={URL.createObjectURL(val)}
                                    alt=""
                                    loading="lazy"
                                    className="2xl:w-32 2xl:h-32 md:h-24 md:w-24 lg:w-24 w-16 h-16 rounded-lg"
                                    style={{ objectFit: 'cover' }}
                                />
                            </div>
                        </div>
                    ))}
            </div>
            {!_isForTalk && (
                <div className="aqeas3 aqeas4">
                    {photo && file.length > 0 && (
                        <>
                            <input
                                checked={isChecked}
                                onChange={() =>
                                    setisChecked((s) => {
                                        setImagePrivacy(!s);
                                        return !s;
                                    })
                                }
                                type="checkbox"
                                name=""
                                id="imagePrivacy"
                            />
                            <label className="mt-2" htmlFor="anonymus">
                                {t('share_expert', 'Share image only with expert')}
                            </label>
                        </>
                    )}
                </div>
            )}

            <div className="flex justify-between items-center">
                <div className="flex cursor-pointer ">
                    {photo ? (
                        <div>
                            <label htmlFor="upload_photo">
                                <div
                                    onClick={() =>
                                        SendEvents(user_data?.userId, 'btn-click', 'btn_add_photo')
                                    }
                                    className=" cursor-pointer flex 2xl:ml-[150px] ml-[5px]"
                                >
                                    <img
                                        src={img}
                                        alt=""
                                        className=" object-contain pr-2"
                                        loading="lazy"
                                    />
                                    <div style={{ width: '100%' }} className="!text-[#787878]">
                                        {t('Add_a_Photo')}
                                    </div>
                                </div>
                            </label>
                            <input
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleupload}
                                type="file"
                                id="upload_photo"
                                className="hidden"
                                multiple
                            />
                        </div>
                    ) : null}
                </div>
                <ButtonPrimary
                    onClick={() => onclick()}
                    disabled={disabled}
                    text={content}
                    // isLoading={isLoading}
                    customStyle={{
                        color: `${col1}`,
                        margin: '5px',
                        cursor: 'pointer',
                        backgroundColor: `${col}`
                    }}
                    size="small"
                />
            </div>
            {isLoading && (
                <Overlay>
                    <div className="flex justify-center items-center fixed top-0 w-screen h-screen bg-white">
                        <div className="w-full flex-col">
                            <h1 className="mx-auto  px-24 font-semibold text-[#242F35]">
                                {t('Wait_Parentune_is_getting_an_expert_response_for_you')}
                            </h1>
                            <div className="flex justify-center items-center mt-5">
                                <div role="status">
                                    <svg
                                        aria-hidden="true"
                                        className="mr-2 w-[100px] h-[100px] text-gray-200 animate-spin "
                                        viewBox="0 0 100 101"
                                        fill="#ffe0e3ac "
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </Overlay>
            )}
        </div>
    );
};

export default Next;
