import axios from 'axios';
// import i18n from '../i18n/config';
import pushEventToGtm from '../utils/pushEventToGtm';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();

export function postSuppport(params: any) {
    const { user_data } = params;

    return axios
        .post(
            process.env.REACT_APP_CONTENT_SERVER + 'content/support',
            {
                itemId: params.itemId,
                itemType: params.itemType,
                parentItemType: params.parentItemType || '',
                parentItemId: params.parentItemId || ''
            },
            {
                headers: {
                    userId: user_data.userId?.toString() || '',
                    instanceId: user_data.instanceId?.toString() || '',
                    authToken: user_data.authToken?.toString() || '',
                    lang: SelectedLanguage || 'en'
                }
            }
        )
        .then((data) => {
            pushEventToGtm('share', {
                pt_itemType: params.itemType,
                pt_itemId: params?.itemId
            });
            return data.data.statusCode;
        })
        .catch((error) => {
            console.log(error);
        });
}
