import axios from 'axios';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
// import i18n from '../../i18n/config';
const SelectedLanguage = Geti18nLang();

function CalendarInvite(
    props: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any }
) {
    const { startTime, endTime, title, description, userEmail, user_data, guestUid } = props;
    return axios
        .post(
            'https://api9.parentune.com/queue/addToCalendar',
            {
                startTime,
                endTime,
                title,
                description,
                userEmail
            },

            {
                headers: {
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || '',
                    lang: SelectedLanguage || 'en',
                    guestUid: guestUid || ''
                }
            }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}
export default CalendarInvite;
