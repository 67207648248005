import { useEffect, useState } from 'react';
import FormProgress from '../../FormProgress/FormProgress';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import AddChildComponent from '../../AddChildComponent/AddChildComponent';
import KeenToAdoptAgeGroupDropdown from './KeenToAdoptAgeGroupDropdown';
import { checkIfChildDataisNull } from '../HaveChild/utils';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import pushEventToGtm from '../../../utils/pushEventToGtm';

const KeenToAdopt = (props: any) => {
    const { setNav, isLoading, setIsLoading } = props;
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const [selectedAgeGroup, setSelectedAgeGroup] = useState<string | null>(null);
    const [data, setData] = useState<any>(null);
    const [canGoNext, setcanGoNext] = useState(false);
    const navigate = useNavigate();

    const handleKeenToAdopt = async () => {
        setIsLoading(true);
        localStorage.setItem('onboarding_data', JSON.stringify({ selectedAgeGroup, data }));
        setNav('IdentifyYourSelf');
        setIsLoading(false);
        pushEventToGtm('keen-to-adopt-user-onboard', {
            'keen-to-adopt': 'keen-to-adopt-next',
            url: window.location.pathname,
            newGuestUid
        });
    };

    useEffect(() => {
        localStorage.removeItem('HaveChild_step');
        if (!selectedAgeGroup) return;

        const isChildDataNull = checkIfChildDataisNull(data);
        setcanGoNext(data && data.length > 0 ? !isChildDataNull : true);
    }, [selectedAgeGroup, data]);

    return (
        <main>
            <div className="h-[90vh] animate-fade-in-left max-w-4xl mx-auto pt-3 flex flex-col px-4">
                <FormProgress onBack={() => setNav('SetupProfile')} dots={4} progress={3} />
                <h1 className="font-proxima font-bold text-xl text-[#242F35] mb-8">
                    I would like to adopt and parent a heart child in the age group
                </h1>
                <p className="text[#242F35] text-sm font-normal font-roboto mb-5 mt-3">
                    Choose age group
                </p>

                <KeenToAdoptAgeGroupDropdown
                    selectedAgeGroup={selectedAgeGroup}
                    setSelectedAgeGroup={setSelectedAgeGroup}
                />

                <div className=" mt-3">
                    {selectedAgeGroup && <AddChildComponent setData={setData} />}
                </div>

                <div className="flex-1 flex flex-col justify-end pb-3">
                    <ButtonPrimary
                        size="large"
                        isLoading={isLoading}
                        text="Next"
                        onClick={() => handleKeenToAdopt()}
                        disabled={!canGoNext}
                    />
                </div>
            </div>
        </main>
    );
};

export default KeenToAdopt;
