import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { classNames } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

type page = {
    name: string;
    href: string;
    current?: boolean;
};
type links = {
    label: string;
    href: string;
};
interface IBreadcrumb {
    pages: page[];
}
export default function BreadCrumbs(props: IBreadcrumb) {
    const [hideHeaderForMobile, setHideHeaderForMobile] = useState(false);

    // hide header in mobile app
    useEffect(() => {
        if (window.location.href.slice(window.location.href.length - 8) === '?inapp=1') {
            setHideHeaderForMobile(true);
        } else if (sessionStorage.getItem('userOnMobile') == 'true') {
            setHideHeaderForMobile(true);
        }
    }, [window.location]);

    useEffect(() => {
        setWindowsize(screen.width);
    });
    window.onresize = function () {
        setWindowsize(window.innerWidth);
    };
    const { pages } = props;
    const [Windowsize, setWindowsize] = useState<number>(0);

    const dataView = () => {
        let data = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: listItemMetatData()
        };

        return JSON.stringify(data);
    };

    const host = 'https:www.parentune.com';

    const listItemMetatData = () => {
        let listItem = [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: host
            }
        ];
        pages?.map((page, index) => {
            listItem.push({
                '@type': 'ListItem',
                position: index + 1,
                name: page.name,
                item: host + page.href
            });
        });
        return listItem;
    };

    const updateLink = (page: page, index: number) => {
        if (pages?.length === index + 1) {
            return (
                <p>
                    <span
                        className={classNames(
                            ` text-light-black font-proxima text-sm   hover:text-slate-400 cursor:pointer ml-1 lg:text-base font-bold capitalize  `
                        )}
                        aria-current={page.current ? 'page' : undefined}
                    >
                        {/* {index != 0 && Windowsize < 400 ? page.name + '...' : page.name} */}
                        {page.name.length > 18
                            ? `${page.name.substring(0, 18)} ...`
                            : `${page.name}`}
                        {/* {page.name.substring(0, 25)} ... */}
                        {/* {page.name.length()>25} */}
                    </span>
                </p>
            );
        } else {
            return (
                <Link to={page.href}>
                    <span
                        className={classNames(
                            `text-light-black font-proxima  md:text-sm  hover:text-slate-400 cursor:pointer ml-1 lg:text-base font-bold capitalize `
                        )}
                        aria-current={page.current ? 'page' : undefined}
                    >
                        {/* {index != 0 && Windowsize < 400 ? page.name + '...' : page.name}*/}
                        {page.name}
                    </span>
                </Link>
            );
        }
    };

    const orderListView = () => {
        return (
            <ol
                role="list"
                className={classNames('flex items-center !pl-0 flex-wrap  gap-1 max-w-[1000px]')}
            >
                <li>
                    <div>
                        <a href="/">
                            <span className="text-darkblue-400 hover:text-darkblue-500 ">
                                <HomeIcon
                                    className="flex-shrink-0 h-6 w-6 lg:h-6 lg:w-6 fill-slate-400"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Home</span>
                            </span>
                        </a>
                    </div>
                </li>
                {pages?.map((page, index) => {
                    return (
                        <li className="" key={page.href}>
                            <div className="flex items-center justify-center ">
                                <ChevronRightIcon
                                    className="flex-shrink-0 h-5 w-5 text-Grey"
                                    aria-hidden="true"
                                />
                                {updateLink(page, index)}
                            </div>
                        </li>
                    );
                })}
            </ol>
        );
    };

    return (
        <nav
            className={`${
                hideHeaderForMobile && !window.location.pathname.includes('baby-names')
                    ? 'hidden'
                    : ''
            }  flex xl:px-0 lg:p-4 pt-4  justify-between max-w-[1000px] mx-auto"
            aria-label="Breadcrumb mx-5 ${
                window.location.pathname.includes('/livechats') && 'mb-2'
            }`}
        >
            <Helmet>
                <script type="application/ld+json">{dataView()}</script>
            </Helmet>
            {orderListView()}
        </nav>
    );
}
