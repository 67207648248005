import React from 'react';
import { BenefitArray } from '../../components/V2Components/LazyLoader/types';

interface BenefitItemType {
    item: BenefitArray;
    index: number;
}

function BenefitItem(props: BenefitItemType) {
    const { Image, Text } = props.item;

    return (
        <div className="flex flex-col justify-between items-center text-center w-[130px] h-[100px] mb-4">
            <img src={Image} alt="gift-image" loading="lazy" width={36} height={36} />
            <h2 className="text-[#000000] text-[14px] font-normal line-clamp-2">{Text}</h2>
        </div>
    );
}

export default BenefitItem;
