// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/user';
import { isLessThanThreeYears } from '../../utils/utils';
//import { RootState, store, useAppSelector } from '../store';

// Define a type for the slice state
interface UserState {
    isLoggedIn: boolean;
    userData: User | null | any;
    guestUid?: string | null;
    consent?: boolean;
    canWatchWorkshops?: boolean;
}

const userInfo = localStorage.getItem('user_data');
const localUser = userInfo ? (JSON.parse(userInfo) as User) : null;

const guestUidInfo = localStorage.getItem('guest_uid');
const local_guest_uid = guestUidInfo ? (guestUidInfo as string) : null;

// Define the initial state using that type
const initialState: UserState = localUser
    ? { isLoggedIn: true, userData: localUser, guestUid: '' }
    : { isLoggedIn: false, userData: null, guestUid: local_guest_uid };

export const UserSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<any>) => {
            state.isLoggedIn = true;
            state.userData = action.payload;
            state.guestUid = '';
            localStorage.setItem('user_data', JSON.stringify({ ...state.userData }));
            localStorage.setItem('guest_uid', state.guestUid + '');
        },
        updateUserData: (state, action: PayloadAction<any>) => {
            state.isLoggedIn = true;
            const _data = {
                ...action.payload.userData,
                authToken: action.payload.data.token,
                userId: parseInt(action.payload.data.id),
                instanceId: action.payload.data.instanceId,
                phoneNumber: action.payload.data.phoneNumber,
                parentName: action.payload.data.name,
                email: action.payload.data.email,
                avatar: action.payload.data.avatar,
                isSubscribed: action.payload.data.isSubscribed,
                isExpert: action.payload.data.isExpert ?? false
            };
            state.userData = _data;
            state.consent = action.payload.data.consentGiven;
            state.guestUid = '';
            localStorage.setItem('user_data', JSON.stringify({ ...state.userData }));
            localStorage.setItem('guest_uid', state.guestUid + '');
        },
        updateConsent: (state, action: PayloadAction<any>) => {
            const _data = {
                Consent: action.payload.data.consentGiven
            };
            localStorage.setItem('consent', JSON.stringify({ _data }));
        },

        setGuestUid: (state, action: PayloadAction<any>) => {
            state.isLoggedIn = false;
            state.guestUid = action.payload.guestUid;
            localStorage.setItem('guest_uid', state.guestUid + '');
        },

        clearGuestUid: (state, action: PayloadAction<any>) => {
            state.guestUid = '';
            localStorage.removeItem('ptgc');
        },

        addUserdata: (state, action: PayloadAction<any>) => {
            let isEligibleForDailyFocus = false;
            if (action?.payload?.kidsList && action?.payload?.kidsList.length > 0) {
                isEligibleForDailyFocus = isLessThanThreeYears(action?.payload?.kidsList[0]?.dob);
            }
            const _data = {
                ...state.userData,
                ...action.payload,
                isEligibleForDailyFocus: isEligibleForDailyFocus
            };
            state.isLoggedIn = true;
            state.userData = _data;
            state.guestUid = '';
            localStorage.setItem('user_data', JSON.stringify({ ...state.userData }));
            localStorage.setItem('guest_uid', state.guestUid + '');
        },
        clearUserData: (state) => {
            state.isLoggedIn = false;
            state.userData = null;
            state.guestUid = '';
            localStorage.removeItem('user_data');
            console.log('logged out');
        },
        canWatchEvent: (state, action: PayloadAction<any>) => {
            state.canWatchWorkshops = action.payload;
        }
    }
});

export const {
    setUserData,
    updateUserData,
    addUserdata,
    setGuestUid,
    canWatchEvent,
    clearUserData,
    clearGuestUid,
    updateConsent
} = UserSlice.actions;
export default UserSlice.reducer;
