import { useEffect, useState } from 'react';

interface PlusPriceType {
    price: number;
    perday: number;
}

const ProceedToPayment = ({
    handleCheckbox,
    checkbox,
    onPlan,
    submitButton,
    plusAnnual,
    plusMonth,
    handleSubmit
}: {
    checkbox?: string;
    handleCheckbox?: () => void;
    onPlan: (value: number) => void;
    submitButton: boolean;
    plusAnnual: PlusPriceType;
    plusMonth: PlusPriceType;
    handleSubmit: any;
}) => {
    const [state, setState] = useState<boolean>(false);

    const handlePlan = (value: number) => {
        onPlan(value);
        setState(!state);
    };

    useEffect(() => {
        onPlan(1499);
    }, []);

    return (
        <div className="mx-5">
            <h2 className="mt-[31px] text-[#242F35] text-[18px] font-medium text-center">
                Proceed to payment{' '}
            </h2>
            <div className="mt-[41px] flex gap-[20px] justify-between">
                <div
                    className={`relative flex-1 bg-[#FFFFFF] bg-shadow h-[115px] w-[150px] rounded-[10px] pt-[20px] pl-[20px] ${
                        state === false ? 'border-2 border-[#367E8A]' : ''
                    }`}
                    onClick={() => handlePlan(1499)}
                >
                    {!state && (
                        <span className="absolute bg-[#367E8A] text-white text-[14px] rounded-[5px] h-[26px] w-[105px] py-2 text-center top-[-16px] flex justify-center items-center left-1/2 -translate-x-1/2 right-0">
                            Selected
                        </span>
                    )}

                    <p className="text-[#141414] text-[12px] font-normal">YEARLY</p>
                    <h2 className="text-[#242F35] text-[21px] font-medium">₹ {plusAnnual.price}</h2>
                    <p className="text-[#373737] text-[12px] font-normal">
                        ₹{plusAnnual.perday}/DAY
                    </p>
                </div>
                <div
                    className={`relative flex-1 bg-[#FFFFFF] bg-shadow h-[115px] w-[150px] rounded-[10px] pt-[20px] pl-[20px] ${
                        state === true ? 'border-2 border-[#367E8A]' : ''
                    } `}
                    onClick={() => handlePlan(199)}
                >
                    {state && (
                        <span className="absolute bg-[#367E8A] text-white text-[14px] rounded-[5px] h-[26px] w-[105px] py-2 text-center top-[-16px] flex justify-center items-center  left-1/2 -translate-x-1/2 right-0">
                            Selected
                        </span>
                    )}
                    <p className="text-[#141414] text-[12px] font-normal">MONTHLY</p>
                    <h2 className="text-[#242F35] text-[21px] font-medium">₹ {plusMonth.price}</h2>
                    <p className="text-[#373737] text-[12px] font-normal">
                        ₹{plusMonth.perday}/DAY
                    </p>
                </div>
            </div>

            {/* custom-checkbox */}
            <div className="mt-[24px] flex items-center">
                <label
                    className="relative flex items-center p-3 rounded-full cursor-pointer"
                    htmlFor="check"
                >
                    <input
                        type="checkbox"
                        checked={checkbox === 'true' ? true : false}
                        onChange={handleCheckbox}
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:bg-[#367E8A] checked:before:bg-[#367E8A] hover:before:opacity-10"
                        id="check"
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth={1}
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </label>
                <label htmlFor="check" className="text-[#242F35] text-[14px]">
                    I agree to abide to{' '}
                    <span className="border-b border-[#AAAAAA]">Terms and Conditions.</span>
                </label>
            </div>
            {/* end */}

            <div className="mt-[47px] mb-[24px]">
                <button
                    onClick={handleSubmit}
                    className={`w-full h-[45px] rounded-full ${
                        submitButton ? 'bg-[#377E8B]' : 'bg-[#C4C4C4]'
                    } text-white`}
                    disabled={!submitButton}
                >
                    CONTINUE
                </button>
            </div>
        </div>
    );
};

export default ProceedToPayment;
