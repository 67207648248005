import { XIcon } from '@heroicons/react/solid';
import React, { useEffect, useRef, useState } from 'react';
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi';
import { SocialShare } from '../../pages/BabyNameBottom1/BabyNameBottom1';
import { getHost } from '../../utils/utils';
import ButtonPrimary from '../V2Components/ButtonPrimary';

type propTypes = {
    src: string;
    sharableLink?: string | undefined;
    title?: string | undefined;
    setClose: () => void;
};

const PlusVideoComponent = (props: propTypes) => {
    const { src, sharableLink, title, setClose } = props;
    const [play, setPlay] = useState(true);
    const [time, setTime] = useState<number>(0);
    const [videoTime, setVideoTime] = useState<number>(0);
    const [mute, setMute] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handlePlay = () => {
        if (!videoRef.current) {
            return;
        }
        if (play) {
            videoRef.current.pause();
            setVideoTime(videoRef.current.duration);
            setPlay(false);
        } else {
            setPlay(true);
            videoRef.current.play();
        }
    };

    window.setInterval(function () {
        if (!videoRef.current) {
            return;
        }
        setTime(videoRef.current?.currentTime);
    }, 1000);

    // useEffect(() => {
    //     if (videoRef.current?.nodeName === 'VIDEO') {
    //         videoRef.current.play();
    //         setVideoTime(videoRef.current.duration);
    //         setPlay(false);
    //      }
    // }, [videoRef.current]);

    const durationView = () => {
        return (
            <>
                <div className="flex flex-col">
                    <ButtonPrimary
                        className="mx-auto fixed bottom-[22%]  left-1/2 -translate-x-1/2"
                        size="small"
                        text={'PLAY'}
                        onClick={() => handlePlay()}
                    />
                    <div className="flex mb-1 text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] gap-1">
                        <time>
                            {Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)}
                        </time>
                        <span> / </span>
                        <time>
                            {Math.floor(videoTime / 60) +
                                ':' +
                                ('0' + Math.floor(videoTime % 60)).slice(-2)}
                        </time>
                    </div>
                    <div className="h-1 rounded-full bg-gray-400 relative w-full ">
                        <div
                            style={{ width: `${(time / videoTime) * 100}%` }}
                            className="bg-yellow-400 h-full"
                        ></div>
                        <div
                            style={{ left: `${(time / videoTime) * 100}%` }}
                            className="w-4 h-4 -top-1.5 rounded-full bg-white absolute"
                        ></div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="h-screen  fixed top-0 right-0 left-0 z-10 bg-black">
            <SocialShare
                shareUrl={sharableLink ? sharableLink : src}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={title ? title : src}
            />
            <video
                ref={videoRef}
                autoPlay={true}
                muted={mute}
                className="h-full max-w-4xl mx-auto w-full object-contain"
                src={src}
                controlsList="nodownload"
            />
            {!mute ? (
                <BiVolumeFull
                    onClick={() => setMute((prev) => !prev)}
                    className="absolute cursor-pointer h-7 w-7 z-30 bg-white rounded-full p-0.5 right-2 top-1/3"
                />
            ) : (
                <BiVolumeMute
                    onClick={() => setMute((prev) => !prev)}
                    className="absolute cursor-pointer h-7 w-7 z-30 bg-white rounded-full p-0.5 right-2 top-1/3"
                />
            )}

            <div
                onClick={() => setIsOpen(true)}
                className="bg-white cursor-pointer z-30 h-10 w-10 p-2 rounded-full absolute  right-2 top-2/3"
            >
                <img
                    src="https://img1.parentune.com/home_assets/home/share.svg"
                    className="h-full w-full"
                />
            </div>

            <div className="z-20 absolute h-5/6 left-0 top-0 right-0 bottom-0 px-3 py-4 flex flex-col justify-between">
                <div className="flex justify-end flex- items-center">
                    <XIcon onClick={setClose} className="h-8 w-8 cursor-pointer text-white" />
                </div>

                {!play ? (
                    durationView()
                ) : (
                    <ButtonPrimary
                        className="mx-auto fixed bottom-[22%] left-1/2 -translate-x-1/2"
                        size="small"
                        text={'PAUSE'}
                        onClick={() => handlePlay()}
                    />
                )}
            </div>
        </div>
    );
};

export default PlusVideoComponent;
