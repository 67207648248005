import { classNames } from '../../../utils/utils';
import * as React from 'react';

type ButtonSecondryType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyle?: React.CSSProperties;
};

const ButtonSecondry1 = (props: ButtonSecondryType) => {
    const { size, text, className, onClick, disabled = false, customStyle } = props;

    return (
        <button
            style={customStyle}
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                size === 'small'
                    ? `px-3.5 py-2 w-10 h-10 text-sm `
                    : size === 'medium'
                    ? 'px-5 py-2.5 text-sm w-min'
                    : `w-full px-5 py-3 text-sm`,
                disabled ? 'opacity-30' : '',
                'inline-flex  border-2 items-center uppercase  justify-center rounded-full    font-medium  text-grey-700  ',
                className
            )}
        >
            {text}
        </button>
    );
};
export default ButtonSecondry1;
