import axios from 'axios';

// import i18n from '../i18n/config';

function xhrSaveBookMark(
    params: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any }
) {
    const { user_data } = params;
    // console.log(params);
    return axios
        .post(
            process.env.REACT_APP_BABYNAME + 'bookmark',
            {
                nameId: params.id
            },
            {
                headers: {
                    userId: user_data ? user_data.userId?.toString() : '',
                    authToken: user_data ? user_data.authToken : '',
                    instanceId: user_data ? user_data.instanceId : ''
                }
            }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export { xhrSaveBookMark };

function xhrDeleteBookMark(
    params: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any }
) {
    const { user_data } = params;
    // console.log(params);
    return axios
        .post(
            process.env.REACT_APP_BABYNAME + 'delete-bookmark',
            {
                nameId: params.id
            },
            {
                headers: {
                    userId: user_data ? user_data.userId?.toString() : '',
                    authToken: user_data ? user_data.authToken : '',
                    instanceId: user_data ? user_data.instanceId : ''
                }
            }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export { xhrDeleteBookMark };
