import React, { useEffect, useState } from 'react';
import Header from '../../Header/Header';
import UserDetailsCard from '../../UserDetailsCard/UserDetailsCard';
import InterestTopic from '../../InterestTopic/InterestTopic';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import { useUserData } from '../../../utils/hooks/useUserData';
import { useAppDispatch } from '../../../store/store';
import { setUserData } from '../../../store/slices/userDataSlice';
import './ProfileSummary.css';
import { handleSteps } from '../../../store/slices/onboardingSlice';
import { useOnboardingData } from '../../../utils/hooks/useOnboardingData';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import pushEventToGtm from '../../../utils/pushEventToGtm';

interface Topic {
    name: string;
    id: number;
    // Add any other properties here if they exist in your actual data
}

const ProfileSummary = (props: any) => {
    const { onboardingSteps } = useOnboardingData();
    const { setNav, lastpath } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const { userData: user_data } = useUserData();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleProfileSummaryNext = () => {
        localStorage.removeItem('redirectNav');
        localStorage.setItem('welcomePage', 'false');
        setIsLoading(true);

        if (localStorage.getItem('gift-profile') === '1') {
            navigate('/my-profile', {
                replace: true,
                state: { from: lastpath }
            });
        }
        pushEventToGtm('profile-summary-user-onboard', {
            'profile-summary': 'profile-summary-next',
            url: window.location.pathname,
            newGuestUid
        });

        navigate('/choose-plan', {
            replace: true,
            state: { from: lastpath }
        });

        setIsLoading(false);
    };

    useEffect(() => {
        window.history.pushState(null, '', '/register');
        navigate(window.location.pathname, { replace: true });

        // Listen for popstate event to prevent back navigation
        const handlePopState = () => {
            navigate(window.location.pathname, { replace: true });
            window.history.pushState(null, '', '/register');

            setNav(onboardingSteps[onboardingSteps.length - 1]); // Keep the user on the same page
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);
    const handleBack = () => {
        dispatch(handleSteps());
        if (!onboardingSteps) return;
        setNav(onboardingSteps && onboardingSteps.at(-2));
    };

    return (
        <div>
            <div className="h-[90vh] animate-fade-in-left max-w-4xl mx-auto pt-3 flex flex-col px-4 ">
                <Header isForOnboarding onBack={handleBack} back headerText="Profile Summary" />
                Awesome! Your profile is set up. You are all set for a personalized experience now
                <UserDetailsCard />
                <div className="mx-auto text-center mb-5">
                    <h1 className="text-[#787878] font-roboto text-sm font-normal">
                        {user_data?.ParentDescription}
                    </h1>
                </div>
                <div className="flex-1"></div>
                <section>
                    <p className="text-[#242F35] font-roboto font-normal text-sm my-5 mx-3">
                        Your Interests
                    </p>
                    <div className="flex flex-wrap ">
                        {user_data?.interests.map((topic: Topic, i: number) => (
                            <p
                                className="whitespace-nowrap h-9 text-sm transition-colors cursor-pointer px-3 py-1.5 rounded-full mb-2 ml-[10px] bg-[#FB7882] text-white"
                                key={i}
                            >
                                {' '}
                                {topic?.name}
                            </p>
                        ))}
                    </div>
                </section>
                <div className="flex-1 flex flex-col justify-end pb-3">
                    <ButtonPrimary
                        size="large"
                        onClick={handleProfileSummaryNext}
                        text="Next"
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProfileSummary;
