import React, { useEffect, useState } from 'react';
import FormProgress from '../../FormProgress/FormProgress';
import { useUserData } from '../../../utils/hooks/useUserData';
import { useAppDispatch } from '../../../store/store';
import ProfilePic from '../../ProfilePic/ProfilePic';
import AvatarSlide from '../../AvatarSlide/AvatarSlide';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import { GetUserProfile } from '../../../api/OnBoarding/GetUserProfile';
import { addUserdata } from '../../../store/slices/userDataSlice';
import './ChooseAvatar.css';
import { AvatarsArray, fetchAvatars } from './utils';
import { handleSteps } from '../../../store/slices/onboardingSlice';
import { useOnboardingData } from '../../../utils/hooks/useOnboardingData';
import { useNavigate } from 'react-router-dom';
import pushEventToGtm from '../../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';

const ChooseAvatar = (props: any) => {
    const dispatch = useAppDispatch();
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const { setNav } = props;
    const { userData: user_data } = useUserData();
    const navigate = useNavigate();
    const [avatarFile, setAvatarFile] = useState<File>();
    const [avatar, setAvatar] = useState<
        | {
              name: string;
              url: string;
          }
        | undefined
    >({ name: 'abhi', url: user_data?.avatar });
    const [customAvatars, setcustomAvatars] = useState(AvatarsArray);
    const { onboardingSteps } = useOnboardingData();

    useEffect(() => {
        window.history.pushState(null, '', '/register');
        navigate(window.location.pathname, { replace: true });

        // Listen for popstate event to prevent back navigation
        const handlePopState = () => {
            navigate(window.location.pathname, { replace: true });
            window.history.pushState(null, '', '/register');

            setNav(onboardingSteps[onboardingSteps.length - 1]); // Keep the user on the same page
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    useEffect(() => {
        GetUserProfile(
            async (data: any) => {
                dispatch(addUserdata({ ...data?.data }));
                setAvatar({ name: data?.data?.name, url: data?.data?.avatar });
            },
            (err) => {
                console.error('Error: ', err);
            },
            user_data
        );
        fetchAvatars(setcustomAvatars);
    }, []);

    const handleBack = () => {
        dispatch(handleSteps());
        if (!onboardingSteps) return;
        console.log('nav>>>', onboardingSteps.at(-2));
        setNav(onboardingSteps && onboardingSteps.at(-2));
    };

    const handleAvatarSubmit = () => {
        setNav('ChooseInterests');
        pushEventToGtm('choose-avatar-user-onboard', {
            'choose-avatar': 'choose-avatar-next',
            url: window.location.pathname,
            newGuestUid
        });
    };

    return (
        <main>
            <div className="h-[90vh] animate-fade-in-left max-w-4xl mx-auto pt-3 flex flex-col px-4">
                <FormProgress onBack={handleBack} dots={4} progress={4} />
                <h2 className="text-xl font-semibold flex flex-col w-fit">
                    <p>Time to choose your recent pic </p>
                    <p>{user_data?.name}!</p>
                </h2>
                <ProfilePic setter={setAvatar} avatar={avatar} />
                <section className="flex items-center justify-center relative mb-4 w-full  h-5">
                    <div className="w-5/6 h-[0.38px] my-8 bg-gray-200 border-0 " />
                    <div className="w-fit absolute font-normal text-base text-[#787878] bg-white  mx-0">
                        &nbsp; Or choose a fun avatar &nbsp;
                    </div>
                </section>
                <AvatarSlide
                    setAvatarFile={setAvatarFile}
                    avatars={customAvatars}
                    setter={setAvatar}
                />
                <div className="flex flex-col flex-1 justify-end pb-3">
                    <ButtonPrimary size="large" text="DONE" onClick={handleAvatarSubmit} />
                </div>
            </div>
        </main>
    );
};

export default ChooseAvatar;
