import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { number, string } from 'yup';
import { emailRegex } from '../OTPPage/utils';
import Input from './Input';
import ProceedToPayment from './ProceedToPayment';
import { validReceiverDetail } from './validReceiverDetails';

interface PlusPriceType {
    price: number;
    perday: number;
}

interface ReceiverDetailType {
    name: string;
    email: string;
    mobile: string;
    message: string;
    plan: number;
    checkbox: string;
    error: {
        name: string;
        email: string;
        mobile: string;
        message: string;
    };
}

const ReceiverDetail = ({
    plusAnnual,
    plusMonth
}: {
    plusAnnual: PlusPriceType;
    plusMonth: PlusPriceType;
}) => {
    const [receiverDetail, setReceiverDetail] = useState<ReceiverDetailType>({
        name: '',
        email: '',
        mobile: '',
        message: '',
        plan: 0,
        checkbox: 'true',
        error: {
            name: '',
            email: '',
            mobile: '',
            message: ''
        }
    });

    const [submitButton, setSubmitButton] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name == 'mobile' && value.length > 10) return;

        setReceiverDetail((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckbox = () => {
        setReceiverDetail((prev) => ({
            ...prev,
            checkbox: prev.checkbox === 'true' ? 'false' : 'true'
        }));
    };

    const handlePlan = (value: number) => {
        setReceiverDetail((prev) => ({ ...prev, plan: value }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const { errors, isValid } = validReceiverDetail(receiverDetail);
        if (isValid) {
            handlePaymentPage();
        } else {
            setReceiverDetail((prev) => ({ ...prev, error: errors }));
        }
    };

    const handlePaymentPage = () => {
        if (receiverDetail.plan === 1499)
            return navigate(`/PlanDetailpage/1/subscription`, {
                state: { receiverDetail: JSON.stringify(receiverDetail) }
            });
        return navigate(`/PlanDetailpage/2/subscription`, {
            state: { receiverDetail: JSON.stringify(receiverDetail) }
        });
    };

    useEffect(() => {
        if (
            receiverDetail.name !== '' &&
            receiverDetail.checkbox !== 'false' &&
            receiverDetail.plan !== 0 &&
            receiverDetail.email !== '' &&
            receiverDetail.mobile !== ''
        ) {
            setSubmitButton(true);
        } else {
            setSubmitButton(false);
        }
    }, [
        receiverDetail.name,
        receiverDetail.email,
        receiverDetail.mobile,
        receiverDetail.checkbox,
        receiverDetail.plan
    ]);

    return (
        <>
            <div className="mx-[13px] p-[7px] mt-[41px] sender-shadow pt-[18px] pb-[38px]">
                <h2 className="text-[#242F35] text-[18px] font-medium">Receiver Detail </h2>
                <Input
                    key={1001}
                    id={1}
                    label={'Name'}
                    placeholder="Name"
                    name="name"
                    value={receiverDetail.name}
                    onChange={handleOnChange}
                    error={receiverDetail.error.name}
                />
                <Input
                    key={123e23}
                    id={1}
                    label="Email"
                    placeholder="Email"
                    name="email"
                    value={receiverDetail.email}
                    onChange={handleOnChange}
                    error={receiverDetail.error.email}
                />
                <Input
                    key={209348}
                    id={2}
                    label="+91"
                    placeholder=""
                    name="mobile"
                    value={receiverDetail.mobile}
                    onChange={handleOnChange}
                    error={receiverDetail.error.mobile}
                />
                <Input
                    key={29837}
                    id={3}
                    label="Your Message"
                    placeholder="Message"
                    name="message"
                    value={receiverDetail.message}
                    onChange={handleOnChange}
                    error={receiverDetail.error.message}
                />
            </div>
            <ProceedToPayment
                checkbox={receiverDetail.checkbox}
                handleCheckbox={handleCheckbox}
                onPlan={handlePlan}
                submitButton={submitButton}
                plusAnnual={plusAnnual}
                plusMonth={plusMonth}
                handleSubmit={handleSubmit}
            />
        </>
    );
};

export default ReceiverDetail;
