export const overlay: any = {
    height: '100%',
    width: '100%',
    position: 'fixed',
    StayInPlaceZIndex: '1',
    SitOnTopLeft: '0',
    top: '0',
    backgroundColor: 'rgb(0,0,0, .5)',
    BlackFallbackColorBackgroundColor: 'rgba(0,0,0, 0.9)',
    BlackWOpacityOverflowX: 'hidden',
    DisableHorizontalScrollTransition: '0.5s',
    zIndex: '55'
};

export const overlayContent: any = {
    position: 'relative',
    top: '20%',
    width: '100%',
    textAlign: 'center',
    marginTop: '30px',
    color: 'white'
};

export const overlayContent2: any = {
    position: 'relative',
    top: '20%',
    width: '100%',
    textAlign: 'center',
    marginTop: '30px',
    color: 'white'
};
