import './BlogPostCardDetails.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
type BlogPostCardDetailsType = {
    url: string;
    itemId: number;
    thumb: string;
    thumbType: string;
    title: string;
    bloggerAvatar: string;
    bloggerName: string;
    primaryAgeGroup: string;
    viewsCount: string;
    profileUrl?: any;
};
function BlogPostCardDetails(props: BlogPostCardDetailsType) {
    const { t } = useTranslation();
    const { profileUrl } = props;
    return (
        <div className="text-muted">
            <div className="flex">
                <div>
                    <img
                        className="blog-post-details-author-image rounded-full"
                        src={props.bloggerAvatar}
                        loading="lazy"
                        alt={props.bloggerName}
                    />
                </div>
                <a
                    href={profileUrl ? profileUrl : '#'}
                    className="font-normal text-base ml-2 blog-post-details-author-name  "
                >
                    {props.bloggerName}
                </a>
            </div>
            <div className="text-base mt-2 flex text-gray-500 ; ">
                <div className="blog-post-details-author-age-views">
                    {props.primaryAgeGroup ? props.primaryAgeGroup : null}
                </div>
                <div className="ml-4">|</div>
                <div className="ml-4 blog-post-details-author-age-views">
                    {props.viewsCount} {t('views')}
                </div>
            </div>
        </div>
    );
}

export default BlogPostCardDetails;
