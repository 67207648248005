import React, { useEffect, useState } from 'react';
import CrossIcon from '../../assets/icons/CrossIconDOB.svg';
import DOBInput from '../../pages/ParentTypeInputPage/DOBInput';
import { t } from 'i18next';

function NameInput({
    child,
    onChangeName,
    onChangeDob,
    atLeastOneChild,
    handleRemoveItem,
    index,
    id
}) {
    console.log(child);
    const [childName, setChildName] = useState(child.name);
    const [dob, setDob] = useState(child.dob);

    const handleNameChange = (e) => {
        let newName = e.target.value;
        if (/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\0-9]/g.test(newName)) return;
        newName = newName.trimStart();

        setChildName(newName);
        onChangeName(newName);
    };

    useEffect(() => {
        console.log(dob);
        onChangeDob(dob);
    }, [dob]);

    return (
        <div>
            <main className=" relative">
                {/* cross btn  */}
                {atLeastOneChild && index === 0 ? null : (
                    <button
                        onClick={() => handleRemoveItem(id)}
                        className=" absolute top-0 right-6  rounded-full"
                    >
                        <img
                            src={CrossIcon}
                            alt="remove child btn"
                            className=" border border-black rounded-full p-1 h-5 w-5"
                        />
                    </button>
                )}
                <br />
                <section className=" my-2 flex flex-col">
                    {t('Childs name')}
                    <input
                        maxLength={50}
                        value={childName}
                        onChange={handleNameChange}
                        placeholder="Child's name"
                        className=" text-input-form-form border-[0.5px] border-[#C4C4C4] rounded-md min-h-[53px] min-w-[53px] w-auto px-4 py-[14px]"
                        // pattern="[^-,]+"
                    />
                    <p className="mb-3 mt-1">Child&apos;s date of birth</p>
                    <DOBInput age={dob} setAge={setDob} isForChild={true} />
                </section>
            </main>
        </div>
    );
}

export default NameInput;
