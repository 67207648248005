import './AvatarSlide.css';

import { useEffect, useRef, useState } from 'react';
import { User } from '../../types/user';
import { UpdateProfile } from '../../api/OnBoarding/UpdateProfile';
import { useUserData } from '../../utils/hooks/useUserData';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { useAppDispatch } from '../../store/store';
import { addUserdata } from '../../store/slices/userDataSlice';
const arrowback = 'https://img1.parentune.com/web/assests_v2/arrowback.webp';
const arrowfront = 'https://img1.parentune.com/web/assests_v2/arrowfront.webp';
function AvatarSlide(props: {
    setAvatarFile: React.Dispatch<React.SetStateAction<File | undefined>>;
    avatars?: any;
    setter: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string;
                  url: string;
              }
            | undefined
        >
    >;
}) {
    const { userData: user_data } = useUserData();
    const dispatch = useAppDispatch();

    const SliderRef = useRef<HTMLDivElement>(null);
    const scrollFront = () => {
        if (SliderRef.current) SliderRef.current.scrollLeft += 100;
    };

    const scrollBack = () => {
        if (SliderRef.current) SliderRef.current.scrollLeft -= 100;
    };

    const UpdateAvatarSticker = (name: string) => {
        const UpdateAvatarStickerSucess = (d: any) => {
            console.log('sucess', d);
            if (d.statusCode !== 200) alert('Something went wrong please try again');

            GetUserProfile(
                async (data: any) => {
                    dispatch(addUserdata({ ...data?.data }));
                    // @ts-ignore
                    console.log('profile updated');
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );
        };
        const UpdateAvatarStickerFailure = (e: any) => {
            console.log('Failure', e);
        };
        UpdateProfile(UpdateAvatarStickerSucess, UpdateAvatarStickerFailure, user_data, 12, {
            stickerAvatar: name
        });
    };
    // {
    //     "stickerAvatar":"pt_avatar_2.jpg"
    //  }

    const displayAvatars = () =>
        props.avatars?.map((avatar: any, i: number) => (
            <img
                key={i}
                className=" avatar-slide-avatar"
                onClick={() => {
                    // console.log(props, avatar);
                    props.setter(avatar);
                    props.setAvatarFile(undefined);
                    // console.log('slide done', avatar.name);
                    UpdateAvatarSticker(avatar.name);
                }}
                loading="lazy"
                src={avatar.url}
                alt=""
            />
        ));

    return (
        <div className="avatar-slide-container ">
            <img
                className="avatar-slide-arrow"
                onClick={scrollBack}
                src={arrowback}
                alt=""
                loading="lazy"
            />
            <div
                id="avatar-slide-slider"
                className="flex justify-center items-center h-[100px] overflow-x-scroll scroll-smooth scrollbar-hide "
                ref={SliderRef}
            >
                {displayAvatars()}
            </div>
            <img
                className="avatar-slide-arrow"
                src={arrowfront}
                onClick={scrollFront}
                alt=""
                loading="lazy"
            />
        </div>
    );
}

export default AvatarSlide;
