import * as React from 'react';

function ClockIcon(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 18.333a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667z"
                stroke="#787878"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 5v5l3.333 1.667"
                stroke="#787878"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ClockIcon;
