import React, { useEffect, useRef, useState } from 'react';
// import './AddChildComponent.css';
import PlusIcon from '../../assets/PlusIcon.svg';
import TextInputForm from '../TextInputForm/TextInputForm';
import DOBInput from '../../pages/ParentTypeInputPage/DOBInput';
// import CrossIcon from '../../assets/icons/CrossIconDOB.svg';
import NameInput from './NameInput';

type ChildDataType = {
    name: string;
    dob: string;
    id: number;
    gender: string;
};

type AddChildComponentType = {
    onClick?: () => void;
    atLeastOneChild?: boolean;
    setDataForProfile?: any;
    data?: any;
    isForProfile?: any;
};

function AddChildComponent(props: AddChildComponentType) {
    const { setDataForProfile } = props;
    const [childData1, setChildData1] = useState<ChildDataType[]>([]);

    const xref = useRef(false);

    useEffect(() => {
        console.log('props?.data', props?.data);
        if (props?.data) {
            console.log('props?.data is true');
            setChildData1(props?.data);
            xref.current = true;
        }
    }, [props?.data]);

    // useEffect(() => {
    //     console.log('xref.current: ', xref.current);
    //     if (xref.current) {
    //         // setChildData1(props?.data);
    //         console.log('data set on line 45');
    //     }
    // }, [xref?.current]);

    useEffect(() => {
        if (!setDataForProfile) return;
        // if (props?.isForProfile) return;
        setDataForProfile(childData1);
    }, [childData1]);

    const handleRemoveItem = (id: number) => {
        console.log('handle remove id', id);
        setChildData1((oldValues: any) => {
            return oldValues.filter((child: any) => child.id !== id);
        });
    };

    const updateChildName = (newName: any, index: number) => {
        const updatedChildData = [...childData1];
        // @ts-ignore
        updatedChildData[index].name = newName;
        setChildData1(updatedChildData);
    };
    const updateChildDob = (dob: any, index: number) => {
        console.log('updateChildDob', dob);

        const updatedChildData = [...childData1];
        // @ts-ignore
        updatedChildData[index].dob = dob;
        setChildData1(updatedChildData);
    };

    return (
        <div>
            {/* add child box */}

            {childData1 && childData1.length > 0 && (
                <section>
                    {childData1.map((child: any, index) => (
                        <div key={index}>
                            <NameInput
                                child={child}
                                onChangeName={(newName: any) => updateChildName(newName, index)}
                                onChangeDob={(dob: any) => updateChildDob(dob, index)}
                                index={index}
                                atLeastOneChild={true}
                                handleRemoveItem={handleRemoveItem}
                                id={child.id}
                            />
                        </div>
                    ))}
                </section>
            )}

            {/* add child plus btn */}
            {childData1 && childData1.length < 3 && (
                <section
                    onClick={() => {
                        console.log('open add child');
                        setChildData1((prevChildData: any) => [
                            ...prevChildData,
                            {
                                id: childData1.length + 1,
                                name: '',
                                gender: 'Male',
                                dob: ''
                            }
                        ]);
                        sessionStorage.setItem('button-first-time', 'false');
                    }}
                    className="flex justify-start items-center space-x-2 my-6"
                >
                    <img src={PlusIcon} alt="Add Child Icon" />
                    <p className="text-[#242F35] font-roboto font-normal text-sm">
                        Add another child
                    </p>
                </section>
            )}
        </div>
    );
}

export default AddChildComponent;
