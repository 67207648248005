import { useEffect, useState } from 'react';
import axios from 'axios';

export function useLocation() {
    useEffect(() => {
        requestLocation();
    }, []);

    const requestLocation = () => {
        if ('geolocation' in navigator) {
            // Check if location permission is already granted
            navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
                if (permissionStatus.state === 'granted') {
                    // Permission already granted, get the location
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            localStorage.setItem(
                                'user_location',
                                JSON.stringify({ latitude, longitude })
                            );
                        },
                        (error) => {
                            console.error('Error getting location:', error);
                        }
                    );
                } else if (permissionStatus.state === 'prompt') {
                    // Permission not yet granted, prompt the user for permission
                    // Attach an event listener to a button or UI element that triggers permission
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            console.log('recived location from user');
                            const { latitude, longitude } = position.coords;
                            localStorage.setItem(
                                'user_location',
                                JSON.stringify({ latitude, longitude })
                            );
                        },
                        (error) => {
                            console.error('Error getting location:', error);
                            // if (error.code === 1)
                            //     alert(
                            //         "You have denied permisson location, either enable it and reload or you won't be able ask NearBy questions"
                            //     );
                        }
                    );
                } else if (permissionStatus.state === 'denied') {
                    // alert(
                    //     "You have denied permisson location, either enable it and reload or you won't be able ask NearBy questions"
                    // );
                }
            });
        } else {
            console.error('Geolocation is not available in your browser');
        }
    };

    return null;
}
