import React from 'react';

const LoadingLayout = ({
    isLoading,
    child,
    skelton,
    spinLoading = false
}: {
    isLoading: boolean;
    child: React.ReactNode;
    skelton?: React.ReactNode;
    spinLoading?: boolean;
}) => {
    if (spinLoading) {
        if (isLoading) {
            return (
                <div className="flex justify-center items-center w-scree h-screen p-4">
                    <div
                        style={{ borderTopColor: 'transparent' }}
                        className=" w-10 h-10 border-4 border-orange-500 border-solid rounded-full animate-spin"
                    ></div>
                </div>
            );
        }
        return <>{child}</>;
    }

    if (isLoading && skelton) {
        return <>{skelton}</>;
    }

    return <>{child}</>;
};

export default LoadingLayout;
