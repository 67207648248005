import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Card from '../../components/Icons/Card';
import ChoosePlanCard from '../../components/ChoosePlan/ChoosePlanCard';
import './ChoosePlan.css';
import ButtonPrimary from '../../components/V2Components/ButtonPrimary';
import axios from 'axios';
import CardWithVideoButton from '../PlusSubscription/CardWithVideoButton/CardWithVideoButton';
import AwardCarousel from '../../components/ChoosePlan/AwardCarousel';
import { t } from 'i18next';
import EventDetailsQuestions from '../../components/EventDeatailsQuestions/EventDetailsQuestions';
import EventDetailsQuestions2 from '../../components/EventDeatailsQuestions/EventDetailsQuestions2';
import Testimonal from '../../components/Conversion/Testimonal';
// @ts-ignore
import { SwiperSlide, Swiper } from 'swiper/react';
// @ts-ignore
import { Mousewheel, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
import { useUserData } from '../../utils/hooks/useUserData';
import { useUtilsData } from '../../utils/hooks/useUtislData';

const ChoosePlanPageDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('locationlocation', location);
    // @ts-ignore
    const { state } = location || {};
    // @ts-ignore
    const { planName } = state || {};
    const [currentCardData, setcurrentCardData] = useState<any>(null);
    const [currentCardIndex, setcurrentCardIndex] = useState<any>(0);
    const [plansData, setplansData] = useState<any>(null);
    const [PlansDetails, setPlansDetails] = useState<any>(null);
    const [scrollHight, setscrollHight] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currency_symbol, setCurrency_symbol] = useState('₹');
    const { userData: user_data } = useUserData();
    const params = useParams();
    const { id } = useParams();

    const {
        location: { country }
    } = useUtilsData();

    useEffect(() => {
        if (!planName) navigate('/choose-plan');
    }, []);

    useEffect(() => {
        const fetchReq = async () => {
            if (!id) return;

            const { country, city, currency_symbol } = await useFetchUserLocation();
            setCurrency_symbol(currency_symbol);

            try {
                // const res = await axios.get(process.env.REACT_APP_SUBSCRIPTION_SERVER + 'v2/plans');
                // const res2 = await axios.get(process.env.REACT_APP_SUBSCRIPTION_SERVER + 'blogs/plan');

                const planDetails = await axios.get(
                    process.env.REACT_APP_SUBSCRIPTION_SERVER +
                        `plans/details?planType=${id == '0' ? 'plus' : 'pro'}`,
                    {
                        headers: {
                            Country: country
                        }
                    }
                );

                // if (res.data.statusCode !== 200) return;

                // setcurrentCardData(res?.data?.data[id]);
                // setplansData(res2?.data?.data);
                setPlansDetails(planDetails?.data?.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchReq();
    }, [id]);

    useEffect(() => {
        if (!user_data || ['plus', 'pro'].includes(user_data?.subscriptionInfo))
            navigate('/register', {
                replace: true
            });

        const handleScroll = () => {
            if (window.scrollY > 330) return;
            // console.log('window.scrollY>>', window.scrollY);
            // if (window.scrollY <= 8) {
            setscrollHight(window.scrollY);
            // }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // useEffect(() => {
    //     console.log('currentCardIndex', currentCardIndex);
    //     console.log(data && data[currentCardIndex]?.claims);
    //     console.log(currentCardData?.claims);
    // }, [currentCardIndex]);

    const OptionBox = ({ id }: { id: number }) => {
        return (
            <button
                onClick={() => {
                    setcurrentCardIndex(id);
                    console.log('>>>>>', currentCardIndex);
                }}
                className={`flex flex-col items-start justify-center w-[150px] pl-3 h-[115px] bg-white  rounded-xl shadow-sm relative ${
                    id == currentCardIndex && 'border-2 border-cyan-700 border-solid'
                }`}
            >
                <div>
                    {/* {JSON.stringify(currentCardIndex, null, 4)} currentCardIndex {id}{' '} */}
                </div>
                {id == currentCardIndex && (
                    <div className=" justify-center px-2.5 py-px text-sm leading-5 text-white whitespace-nowrap bg-cyan-700 rounded-md absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2">
                        Selected
                    </div>
                )}
                <div className="mt-2 leading-6 uppercase text-zinc-800">
                    {PlansDetails?.plans[id]?.duration == 12 ? 'yearly' : 'Monthly'}
                </div>
                <div className="text-xl font-medium leading-6 text-gray-800">
                    {currency_symbol} {parseInt(PlansDetails && PlansDetails?.plans[id]?.price)}
                </div>
                {country == 'IN' && (
                    <div className="text-[#373737] text-xs ">{`${currency_symbol} ${PlansDetails?.plans[id]?.cost_per_day}/DAY`}</div>
                )}
                {country != 'IN' && (
                    <div className="text-[#373737] text-xs ">{`${currency_symbol} ${PlansDetails?.plans[id]?.cost_per_day_new}/DAY`}</div>
                )}
                {PlansDetails?.savings && PlansDetails?.plans[id]?.duration == 12 && (
                    <div className="font-roboto font-normal text-xs text-[#373737] mt-1">
                        YOU SAVE {currency_symbol}
                        {PlansDetails?.savingsNew < 1
                            ? PlansDetails?.savingsNew
                            : PlansDetails?.savings}
                    </div>
                )}
            </button>
        );
    };

    const buttonStyle = {
        marginLeft: `${scrollHight * 0.1}%`,
        justifyContent: 'flex-end',
        width: `${(window.innerWidth > 700 ? 100 : 90) - scrollHight * 0.1}%`
    };

    // console.log('currentCardDatacurrentCardData', currentCardData);

    // console.log('plansDataplansDataplansData', plansData);

    // console.log('PlansDetails', PlansDetails);
    return (
        <main className=" min-h-screen h-full w-full mx-auto max-w-4xl">
            {/* <div>{JSON.stringify(PlansDetails?.plans.length, null, 4)} PlansDetails </div> */}
            <Header
                back={true}
                textType={'string'}
                onBack={() => navigate(-1)}
                headerText={`Parentune ${planName || ''}`}
                textClass={undefined}
                isForOnboarding={false}
                isDarkMode={false}
                BottomShadow={true}
            />

            {/* <div>{JSON.stringify(currentCardData?.cta, null, 4)} currentCardData </div> */}

            {/* <div className="button bg-red-400 sticky top-20 w-full h-auto" style={buttonStyle}>
                <span>Your Button Text</span>
            </div> */}

            <main className="abc pb-10">
                {loading ? (
                    <section>
                        <div className="h-[148px] w-full bg-gray-300 card-shine-effect px-12"></div>

                        <div className="flex items-center justify-between mt-4 space-x-5 mx-5">
                            <div className="h-[90px] w-1/2 bg-gray-300 card-shine-effect rounded-md"></div>
                            <div className="h-[90px] w-1/2 bg-gray-300 card-shine-effect  rounded-md"></div>
                        </div>

                        <div className="flex flex-col items-start space-y-2 mx-5 mt-10">
                            <p className="h-6 w-full bg-gray-300 card-shine-effect"></p>
                            <p className="h-6 w-full bg-gray-300 card-shine-effect"></p>
                            <p className="h-6 w-[20%] bg-gray-300 card-shine-effect"></p>
                        </div>

                        <div className="flex flex-col space-y-2 mx-10 mt-10">
                            {[1, 2, 3, 4, 5, 6].map((item) => (
                                <p
                                    key={item}
                                    className="flex items-center space-x-9 space-y-2 justify-center"
                                >
                                    <p className="h-5 w-[5%] bg-gray-300 card-shine-effect"></p>
                                    <p className="h-5 w-[95%] bg-gray-300 card-shine-effect"></p>
                                </p>
                            ))}
                        </div>
                    </section>
                ) : (
                    <section className="mx-5  ">
                        <div className="flex justify-between items-center w-full">
                            <h1 className="w-1/2 font-medium font-roboto text-lg mt-10 mx-6 leading-6 ">
                                {PlansDetails?.claims}
                            </h1>

                            <img
                                src={PlansDetails?.detail_page_banner}
                                alt=""
                                className="w-1/2 min-h-[148px] object-contain scale-105 "
                            />
                        </div>

                        <p className="text-[#242F35] text-sm font-normal mx-5 my-5">
                            Select a plan
                        </p>

                        <div className="w-full h-auto flex flex-col justify-center items-center md:items-start  mt-3 ">
                            {PlansDetails?.plans && (
                                <section className="flex items-center justify-center space-x-5">
                                    <OptionBox id={0} />
                                    <OptionBox id={1} />
                                </section>
                            )}

                            {PlansDetails?.description && (
                                <ul className="space-y-2 font-roboto text-sm w-full text-left mx-6 px-5 mt-9">
                                    {(currentCardIndex === 1
                                        ? PlansDetails?.description?.data_monthly
                                        : PlansDetails?.description?.data_annual
                                    )?.map((item: any, index: number) => (
                                        <li
                                            key={index}
                                            className={`flex items-center justify-start w-full ${
                                                item.isLocked == 'True' ? 'space-x-10' : 'space-x-9'
                                            }  space-y-2`}
                                        >
                                            {item?.isLocked !== 'True' ? (
                                                <svg
                                                    width={16}
                                                    height={12}
                                                    viewBox="0 0 16 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.3333 1L5.16667 10.1667L1 6"
                                                        stroke="#464646"
                                                        strokeWidth={2}
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="11"
                                                    height="15"
                                                    viewBox="0 0 11 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.0833 5.625H9.16667V3.75C9.16667 1.67906 7.52492 0 5.5 0C3.47508 0 1.83333 1.67906 1.83333 3.75V5.625H0.916667C0.409979 5.625 0 6.0443 0 6.5625V14.0625C0 14.5807 0.409979 15 0.916667 15H10.0833C10.59 15 11 14.5807 11 14.0625V6.5625C11 6.0443 10.59 5.625 10.0833 5.625ZM5.5 11.25C4.99331 11.25 4.58333 10.8307 4.58333 10.3125C4.58333 9.7943 4.99331 9.375 5.5 9.375C6.00669 9.375 6.41667 9.7943 6.41667 10.3125C6.41667 10.8307 6.00669 11.25 5.5 11.25ZM7.33333 5.625H3.66667V3.75C3.66667 2.71453 4.48754 1.875 5.5 1.875C6.51246 1.875 7.33333 2.71453 7.33333 3.75V5.625Z"
                                                        fill="#464646"
                                                    />
                                                </svg>
                                            )}
                                            <p className="text-left">{item?.text}</p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </section>
                )}
            </main>
            {/* <div className=" sticky bottom-0 w-full h-auto z-50 ">
                <div className="p-5 bg-transparent">
                    <ButtonPrimary
                        size="large"
                        text={'Continue'}
                        // onClick={handleCTAClick}
                        // disabled={name.length < 3 || !selectedData}
                    />
                </div>
            </div> */}

            <section className="bg-white  border border-white">
                <h1 className="font-roboto text-lg font-medium text-center mt-10 ">
                    Parents share their PLUS experience{' '}
                </h1>

                {PlansDetails?.testimonials?.testimonial && (
                    <CardWithVideoButton
                        key={'1'}
                        data={PlansDetails?.testimonials?.testimonial}
                        pagination={false}
                    />
                )}
            </section>

            <section className="">
                <h2 className="font-medium font-roboto text-lg mx-6">
                    Parents&apos; {''} Reviews of PLUS
                </h2>
                <h2 className="font-normal font-roboto text-base mx-6">
                    What customers are saying about our new feature
                </h2>
                <Swiper
                    spaceBetween={30}
                    // slidesPerView={device !== Constants.DEVICE.DESKTOP ? 1.5 : 3}
                    mousewheel={{ forceToAxis: true }}
                    modules={[Pagination, Mousewheel]}
                    slidesPerView={window.innerWidth > 700 ? 3 : 1.5}
                    onSlideChange={() => console.log('slide change')}
                    className="mySwiper"
                >
                    <div className=" lg:flex ">
                        <div className="flex gap-x-2">
                            {PlansDetails?.testimonials?.testimonial.map(
                                (item: any, index: any) => (
                                    <SwiperSlide className={`${index == 0 && `ml-5`}`} key={index}>
                                        <Testimonal data={item} />
                                    </SwiperSlide>
                                )
                            )}
                        </div>
                    </div>
                </Swiper>
            </section>

            {PlansDetails?.faq?.question && (
                <div className="bg-white ">
                    <div
                        style={{
                            padding: '20px 0 0  20px',
                            fontSize: 'larger',
                            fontWeight: 'bold'
                        }}
                        className="w-[90%]"
                    >
                        {t('Frequently Asked Questions (FAQS)')}
                    </div>

                    <EventDetailsQuestions2 quickLinks={PlansDetails?.faq?.question} />
                </div>
            )}

            {/* <section className="bg-white pb-10 border border-white">
                <h1 className="font-roboto text-lg font-medium text-center mt-10 ">
                    Awarded & Recognised Globally
                </h1>

                {PlansDetails?.awards?.images && (
                    <AwardCarousel key={'1'} images={PlansDetails?.awards?.images} />
                )}
            </section> */}

            <div className=" sticky bottom-0 w-full h-[75px] z-50  ">
                {/* <div>{JSON.stringify(scrollHight, null, 4)} scrollHight </div> */}
                <div className="h-full w-full flex flex-col justify-center items-center bg-white">
                    <div className="py-1  flex  pl-7 h-fit w-full relative">
                        <div className={`absolute w-1/3 z-10 ml-[10px]`}>
                            <p className="uppercase font-normal text-xs font-roboto">
                                {currentCardIndex == 0 ? 'yearly' : 'Monthly'}{' '}
                            </p>
                            <p className="uppercase font-medium text-lg font-roboto">
                                {currency_symbol}
                                {PlansDetails &&
                                    country == 'IN' &&
                                    PlansDetails.plans[currentCardIndex].cost_per_day}
                                {PlansDetails &&
                                    country !== 'IN' &&
                                    PlansDetails.plans[currentCardIndex].cost_per_day_new}
                                / DAY
                            </p>
                        </div>
                        <button
                            onClick={() => {
                                let url: any;

                                if (planName == 'PLUS') {
                                    if (currentCardIndex == 0) {
                                        url = '/PlanDetailpage/1/subscription';
                                    } else url = '/PlanDetailpage/2/subscription';
                                } else if (planName == 'PRO') {
                                    if (currentCardIndex == 0) {
                                        url = '/PlanDetailpage/11/blogs?isNotPregnancy=1';
                                    } else url = '/PlanDetailpage/10/blogs?isNotPregnancy=1';
                                }

                                navigate(url, {
                                    state: {
                                        planType: 'Choose-a-plan'
                                    }
                                });
                            }}
                            style={buttonStyle}
                            className="primary-cta-lg  !py-4  z-20 "
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ChoosePlanPageDetail;
