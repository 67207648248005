import { useUserData } from '../../utils/hooks/useUserData';
import './UserDetailsCard.css';

function UserDetailsCard() {
    const { userData: user_data } = useUserData();

    return (
        <div className="user-details-card-container">
            <div>
                <img
                    src={user_data?.avatar}
                    className="object-cover h-28 w-28 rounded-full mx-auto my-10"
                />
                <div>
                    <div className="text-center">{user_data?.name}</div>
                    <div className="text-center text-muted"> {user_data?.userDetails} </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetailsCard;
