import React, { useEffect, useState } from 'react';
// @ts-ignore
import { AutoTabProvider } from 'react-auto-tab';
import './OTPInput.css';

// interface CredentialRequestOptions {
//     otp: { transport: string[] };
//     signal: AbortSignal;
// }

function OTPInput(props: {
    setter: (val: string) => void;
    incorrectOTP: boolean;
    setIncorrectOTP?: (val: boolean) => void;
    onNext?: () => void;
    otpReset?: string;
    setOtpReset?: () => void;
}) {
    const [OTP, setOTP] = useState<string[]>(['', '', '', '']);
    const [focused, setFocused] = useState<number>(0);

    // New useEffect to handle OTP reset
    useEffect(() => {
        if (props.otpReset === 'reset') {
            setOTP(['', '', '', '']);
            setFocused(0);
            // @ts-ignore
            if (props?.setOtpReset) props.setOtpReset();
        }
    }, [props.otpReset]);

    // useEffect(() => {
    //     if ('OTPCredential' in window) {
    //         const ac = new AbortController();
    //         navigator.credentials.get({
    //                 otp: { transport: ['sms'] },
    //                 // signal: ac.signal
    //             } as CredentialRequestOptions)
    //             .then((otp: any) => {
    //                 if (otp && otp.code) {
    //                     console.log('autofetchotp' + otp);
    //                     setval(otp.code);
    //                 }
    //                 else {
    //                     console.log('failded otp');
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error('Error retrieving OTP:', err);
    //             })
    //             // return () => ac.abort();
    //     }
    // }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 4) {
            let data = event.target.value;

            setOTP(
                data
                    .split('')
                    .slice(0, 4)
                    .map((item) => item)
            );
            props.setter(
                data
                    .split('')
                    .slice(0, 4)
                    .map((item) => item)
                    .join('')
            );
            return;
        }
        let OTPNew = [...OTP];
        const index = parseInt(event.target.id.substring(2));
        const eventValue = event.target.value;
        OTPNew[index] = eventValue.slice(-1);
        // OTPNew[index] = eventValue;
        setOTP(OTPNew);
        props.setter(OTPNew.join(''));
        // if (props.incorrectOTP && props.setIncorrectOTP) {
        //     props.setIncorrectOTP(false);
        // }
        // console.log('props', props);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
        !/[0-9]/.test(event.key) && event.preventDefault();

    const handleNext = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && OTP.join('').length > 3) {
            props.onNext && props.onNext();
        }
    };

    return (
        <>
            <AutoTabProvider className="otp-input-container">
                {new Array(4).fill(0).map((_, i) => (
                    <input
                        key={i}
                        // @ts-ignore
                        tabbable="true"
                        id={`id${i}`}
                        value={OTP[i]}
                        onChange={handleChange}
                        onPaste={(e) => {
                            const data = e.clipboardData.getData('text/plain');
                            if (Number.isInteger(parseInt(data)) === false) {
                                setOTP(['', '', '', '']);
                                setFocused(0);
                            } else {
                                setOTP(
                                    data
                                        .split('')
                                        .slice(0, 4)
                                        .map((item) => item)
                                );
                                props.setter(
                                    data
                                        .split('')
                                        .slice(0, 4)
                                        .map((item) => item)
                                        .join('')
                                );
                            }
                        }}
                        onKeyPress={handleKeyPress}
                        onKeyUp={handleNext}
                        className={
                            props.incorrectOTP ? 'otp-input-input incorrectOTP' : 'otp-input-input'
                        }
                        placeholder="_"
                        type="number"
                        maxLength={1}
                    />
                ))}
            </AutoTabProvider>
        </>
    );
}

export default OTPInput;
