import starfilled from '../../assets/landing/star-filled.svg';
import starround from '../../assets/landing/start-icon.svg';
import crossicon from '../../assets/booksummary/cross-icon.svg';
import eventbadge from '../../assets/landing/badges.webp';
import { useEffect, useState } from 'react';
import { useUserData } from '../../utils/hooks/useUserData';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

interface LocationState {
    title: string;
    itemid: number;
}

function Congrutulation() {
    const location = useLocation();
    const state = location.state as LocationState;

    if (!location.state) return <Navigate to="/livechats" />;

    return (
        <div className="flex justify-center w-full h-full">
            <div className="max-w-4xl w-full h-screen">
                <TopHeader />
                <div className="flex justify-center">
                    <img src={eventbadge} alt="badge" />
                </div>
                <EventDetails title={state.title} itemid={state.itemid} />
            </div>
        </div>
    );
}

export default Congrutulation;

const TopHeader = () => {
    const { userData: user_data } = useUserData();

    const navigate = useNavigate();

    return (
        <>
            {user_data && (
                <div className="mt-[24px] mx-5 flex justify-between pb-[24px]">
                    <div></div>
                    <div className="flex flex-col  justify-center items-center">
                        {user_data.avatar && (
                            <img
                                src={user_data.avatar}
                                alt="heroImg"
                                width={32}
                                className="aspect-square rounded-full"
                            />
                        )}
                        {!user_data.avatar && (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10 19.143A9.143 9.143 0 1010 .857a9.143 9.143 0 000 18.286z"
                                    stroke="#73787D"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M16.857 15.714C16.1 13.115 13.2 12.257 10 12.257c-3.117 0-6.023.993-6.857 3.457"
                                    stroke="#73787D"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    clipRule="evenodd"
                                    d="M10 3.143a3.428 3.428 0 013.428 3.428v2.286a3.428 3.428 0 11-6.857 0V6.57A3.429 3.429 0 0110 3.143v0z"
                                    stroke="#73787D"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        )}
                        <h2>{user_data.name}</h2>
                    </div>
                    <img
                        src={crossicon}
                        alt="cross-icon"
                        width={24}
                        onClick={() => navigate('/livechats')}
                    />
                </div>
            )}
            {!user_data && (
                <div className="flex justify-end  pt-4 pr-4">
                    <img src={crossicon} alt="cross-icon" onClick={() => navigate('/livechats')} />
                </div>
            )}
        </>
    );
};

interface FeedBAckType {
    totalstar: number;
    feeditem: string[];
    feedback: string;
}

const EventDetails = (props: any) => {
    const { title, itemid } = props;
    const navigate = useNavigate();
    const feedback = ['Loved It!', 'Helpful', 'OK', 'Informative', 'Other'];
    const [starlist, setStarList] = useState<number[]>([1, 2, 3, 4, 5]);
    const [visible, setIsVisible] = useState(false);
    const { userData: user_data } = useUserData();

    const [feedDetails, setFeedDetails] = useState<FeedBAckType>({
        totalstar: 0,
        feeditem: [],
        feedback: ''
    });

    const [inputValue, setValue] = useState('');

    const handleStarAction = (item: number) => {
        setFeedDetails((prevData: FeedBAckType) => ({
            ...prevData,
            totalstar: item
        }));
    };

    const handleOnClick = (it: number) => {
        if (it === 1) return navigate('/livechats');

        const jsonString = JSON.stringify(feedDetails.feeditem);

        // Remove the square brackets if they exist
        const resultString = jsonString.replace(/^\[|\]$/g, '').replace(/"/g, '');

        if (visible) {
            const data = {
                type: null,
                msg: feedDetails.feedback,
                itemId: itemid,
                itemType: 'live_event',
                rating: feedDetails.totalstar,
                feedback: resultString
            };

            if (user_data) {
                axios
                    .post(process.env.REACT_APP_CONTENT_SERVER + '/content/feedback/add', data, {
                        headers: {
                            lang: 'en',
                            platform: 'web',
                            userId: user_data ? user_data.userId?.toString() : '',
                            authToken: user_data ? user_data.authToken : '',
                            instanceId: user_data ? user_data.instanceId : '',
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((res) => {
                        if (res.status >= 200) {
                            navigate('/livechats');
                        }
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
            }
        }
    };

    const handleOnFeed = (item: string) => {
        let arr = feedDetails.feeditem;
        let isMatch = arr.includes(item);

        if (isMatch) {
            arr = arr.filter((listitem: string) => listitem !== item);
        } else {
            arr.push(item);
        }

        setFeedDetails((prevData: FeedBAckType) => ({
            ...prevData,
            feeditem: [...new Set(arr)]
        }));
    };

    const handleOnChange = (message: string) => {
        setValue(message);
        setFeedDetails((prevData: FeedBAckType) => ({
            ...prevData,
            feedback: message
        }));
    };

    useEffect(() => {
        if (feedDetails.totalstar !== 0 || feedDetails.feeditem.length !== 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }

        if (feedDetails.feedback !== '' && feedDetails.feedback.length <= 100) {
            setIsVisible(true);
        }

        if (feedDetails.feedback !== '' && feedDetails.feedback.length > 100) {
            setIsVisible(false);
        }
    }, [feedDetails.totalstar, feedDetails.feedback, feedDetails.feeditem.length]);

    return (
        <>
            <div className="mx-5 mb-[24px]">
                {title && (
                    <h2 className="text-center text-[#242F35] text-[16px] font-normal pt-[24px]">
                        {title}
                    </h2>
                )}
                <div className="mt-[44px]">
                    <div className="flex flex-col items-center justify-center gap-[24px]">
                        <h2 className="text-[#242F35] text-[18px] text-center font-medium pt-[32px]">
                            Rate this Course
                        </h2>
                        <div className="flex gap-4 justify-center">
                            {starlist.map((item: number, index: number) => {
                                if (index + 1 <= feedDetails.totalstar) {
                                    return (
                                        <img
                                            src={starfilled}
                                            alt="starfilled"
                                            onClick={() => handleStarAction(item)}
                                        />
                                    );
                                } else {
                                    return (
                                        <img
                                            src={starround}
                                            alt="starround"
                                            onClick={() => handleStarAction(item)}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="mt-[30px] flex  gap-[12px] justify-center flex-wrap">
                        {feedback.map((item, index) => (
                            <FeedbackItem key={index} item={item} handleOnFeed={handleOnFeed} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="relative">
                <textarea
                    rows={5}
                    placeholder="Add a feedback!"
                    className={`px-[20px] py-[26px] w-full h-[120px] outline-none shadow-md ${
                        inputValue.length > 100 ? 'text-red-600' : 'text-[#242F35]'
                    }`}
                    value={feedDetails.feedback}
                    onChange={(e: any) => handleOnChange(e.target.value)}
                ></textarea>
                <span
                    className={`absolute bottom-6 right-6 ${
                        inputValue.length > 100 ? 'text-red-600' : 'text-[#242F35]'
                    }`}
                >{`${inputValue.length}/100`}</span>
            </div>
            <div className="mt-[20px] mb-[40px] gap-[20px] flex justify-center items-center">
                <button
                    className="text-[#717171] text-[14px] border border-[#151E261A] rounded-full h-[30px] px-[18px] py-[7px] flex justify-center items-center"
                    onClick={() => handleOnClick(1)}
                >
                    CANCEL
                </button>
                <button
                    className={` text-white text-[14px]  rounded-full h-[30px] px-[18px] py-[7px] flex justify-center items-center ${
                        visible ? 'bg-[#367E8A]' : 'bg-[#CED2D9]'
                    }`}
                    onClick={() => handleOnClick(2)}
                >
                    SUBMIT
                </button>
            </div>
        </>
    );
};

const FeedbackItem = ({
    item,
    handleOnFeed
}: {
    item: string;
    handleOnFeed: (item: string) => void;
}) => {
    const [isFilled, setIsFilled] = useState(false);

    return (
        <span
            className={`border ${
                isFilled === false ? 'bg-[#151E260D]' : 'bg-[#FB7882] text-white'
            }  border-[#151E2626]  px-[16px] py-[10px] rounded-full h-[35px] flex justify-center items-center`}
            onClick={() => {
                setIsFilled(!isFilled), handleOnFeed(item);
            }}
        >
            {item}
        </span>
    );
};
