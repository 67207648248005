import React, { useEffect, useState } from 'react';
import FormProgress from '../../FormProgress/FormProgress';
import { useAppDispatch } from '../../../store/store';
import { useUserData } from '../../../utils/hooks/useUserData';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import AddChildComponent from '../../AddChildComponent/AddChildComponent';
import DOBInput from '../../../pages/ParentTypeInputPage/DOBInput';
import { UpdateProfile } from '../../../api/OnBoarding/UpdateProfile';
import { setLoading } from '../../../store/slices/homePageSlice';
import { checkIfChildDataisNull } from '../HaveChild/utils';
import Calendar from '../../Pt-calender/Ptcalendar';
import Overlay from '../../Overlay/Overlay';
import { handleSteps } from '../../../store/slices/onboardingSlice';
import { useOnboardingData } from '../../../utils/hooks/useOnboardingData';
import { useNavigate } from 'react-router-dom';
import pushEventToGtm from '../../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';
// import PtCalender from '../../Pt-calender/index';

const DueDatePage = (props: any) => {
    const { nav, setNav, selectedData, setSelectedData, isLoading } = props;

    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const dispatch = useAppDispatch();
    const { userData: user_data } = useUserData();
    const navigate = useNavigate();
    const [dueDate, setDueDate] = useState<string>(() => {
        if (user_data?.dueDate) return user_data.dueDate;
        const storedData = localStorage.getItem('onboarding_data');
        return storedData ? JSON.parse(storedData).dueDate || '' : '';
    });
    const [xdate, setxdate] = useState(null);
    const { onboardingSteps } = useOnboardingData();
    const [childDataNew, setChildDataNew] = useState<any>(null);

    // @ts-ignore
    // const onboardingDataLocal: any = JSON.parse(localStorage.getItem('onboarding_data'));
    const [data, setData] = useState(null);

    useEffect(() => {
        navigate(window.location.pathname, { replace: true });

        // Listen for popstate event to prevent back navigation
        const handlePopState = () => {
            navigate(window.location.pathname, { replace: true });
            window.history.pushState(null, '', '/register');
            setNav('SetupProfile'); // Keep the user on the same page
        };
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    useEffect(() => {
        if (user_data?.kidsList?.length <= 0) return;

        const kidsFromUserData = user_data?.kidsList.map((kid: any) => {
            return {
                name: kid?.name,
                id: kid?.id,
                gender: kid?.gender,
                dob: new Date(kid?.dob).toISOString().substr(0, 10)
            };
        });
        setChildDataNew([...kidsFromUserData]);
    }, [user_data]);

    // const [data, setdata] = useState(null);

    const handleDueDateUpdate = () => {
        localStorage.setItem('onboarding_data', JSON.stringify({ dueDate, data }));
        setNav('IdentifyYourSelf');
        pushEventToGtm('due-date-user-onboard', {
            'due-date': 'due-date-next',
            url: window.location.pathname,
            newGuestUid
        });

        // setLoading(true);
        // console.log('handleDueDateUpdate');
        // function onSucessUpdateProfile(d: any) {
        //     console.log('date', d);
        //     if (d.statusCode !== 200) return;
        //     console.log('done');
        //     setLoading(false);
        //     setNav('IdentifyYourSelf');
        // }
        // function onFailureUpdateProfile(e: any) {
        //     console.log('Error', e);
        //     setLoading(false);
        // }
        // UpdateProfile(onSucessUpdateProfile, onFailureUpdateProfile, user_data, 4, data);
    };
    return (
        <div>
            <div
                style={{ height: '90vh' }}
                className="animate-fade-in-left max-w-4xl mx-auto pt-3 flex flex-col px-4"
            >
                <FormProgress
                    onBack={() => {
                        dispatch(handleSteps());
                        setNav(onboardingSteps && onboardingSteps.at(-1));
                    }}
                    dots={4}
                    progress={2}
                />
                <h1 className="font-proxima font-bold text-xl text-[#242F35] mb-5">
                    Hello, {user_data.name}
                </h1>
                {/* {JSON.stringify(data)} */}
                <section className="my-2 flex flex-col gap-2">
                    <DOBInput age={dueDate} setAge={setDueDate} isForDueDate={true} />
                    {/* <PtCalender date={xdate} setDate={setxdate} /> */}
                    {/* <Overlay>
                        <Calendar />
                    </Overlay> */}

                    {/* Add another Child */}

                    <AddChildComponent
                        data={childDataNew}
                        setData={setData}
                        atLeastOneChild={childDataNew ?? false}
                    />
                </section>

                {/* {JSON.stringify(selectedData)} */}
                {/* {JSON.stringify(data, null, 4)} */}

                {/* btn on bottom */}

                <div className="flex-1 flex flex-col justify-end pb-3">
                    <ButtonPrimary
                        size="large"
                        isLoading={isLoading}
                        text="Next"
                        onClick={() => handleDueDateUpdate()}
                        disabled={!dueDate || checkIfChildDataisNull(data, true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default DueDatePage;
