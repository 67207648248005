import React from 'react';
import { Question } from '../Question/Question';
import './QuestionThread.css';
interface replyprops {
    key: number;
    r: {
        comment: string;
        date: string;
        isSupported: boolean;
        itemId: number;
        replyCount: number;
        supportCount: number;
        userAvatar: string;
        userDetail: string;
        userName: string;
    }[];
    parentItemId: number;
    parentItemType: string;
}
export const Reply = (props: replyprops) => {
    console.log(props);
    return (
        <div className="qtcti1">
            <div className="qtcti2"></div>
            <div className="qtcti21">
                <Question {...props} />
                {/* {props.component} */}
            </div>
        </div>
    );
};

// function QuestionThread(props:any) {
//     const displayReplies = () =>
//         props.replies.map(( index:any) => (
//             <Reply key={index} component={<Reply component={<Reply />} />} />
//         ));

//     return (
//         <div>
//             <Question {...props} />
//             {props.replies && displayReplies()}
//         </div>
//     );
// }

// export default QuestionThread;
