import './BlogPostCard.css';

import { Link, useNavigate } from 'react-router-dom';
import BlogPostCardDetails from '../BlogPostCardDetails/BlogPostCardDetails';
// import i18n from '../../i18n/config';
import { getHost } from '../../utils/utils';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import { t } from 'i18next';
import { useUserData } from '../../utils/hooks/useUserData';
import ContentProtectionCTA from '../ContentProtectionCTA/ContentProtectionCTA';
const LockIconSvg = 'https://img1.parentune.com/images/icons/content%20Lock%20icon.svg';
const SelectedLanguage = Geti18nLang();
import ReactGA from 'react-ga4';

type BlogPostCardType = {
    url: string;
    itemId: number;
    thumb: string;
    thumbType: string;
    title: string;
    bloggerAvatar: string;
    bloggerName: string;
    primaryAgeGroup: string;
    viewsCount: string;
    propsObj?: any;
    bloggerId?: any;
    isProBlog?: boolean;
    canViewBlog?: boolean;
    proText?: string;
};

function BlogPostCard(props: BlogPostCardType) {
    const navigate = useNavigate();
    const { bloggerId, isProBlog, canViewBlog, proText } = props;

    const { userData: user_data } = useUserData();

    // const handleBlogPostClick = () => {
    //     let url = '/parent-blog' + '/' + props.url + '/' + props.itemId;
    //     if (SelectedLanguage !== 'en') {
    //         url = `/${SelectedLanguage}${url}`;
    //     }
    //     navigate(url, { state: props.propsObj });
    // };

    let url = '/parent-blog' + '/' + props.url + '/' + props.itemId;
    if (SelectedLanguage !== 'en') {
        url = `/${SelectedLanguage}${url}`;
    }

    const profileUrl = bloggerId ? `${getHost()}user/profile/${bloggerId}` : '#';

    return (
        <div
            className="blog-post-card-container m-4 pt-track-impression "
            data-ptname={`blog_list_card_${props.itemId}`}
            onClick={() => {
                ReactGA.send({
                    hitType: 'btn-click',
                    type: 'blog post card',
                    title: props.title
                });
            }}
        >
            <a
                href={url}
                className="flex flex-col break-words bg-white border shadow-md rounded-[10px] h-full"
            >
                <div className="pt-track-click" data-ptname={`blog_list_card_${props.itemId}`}>
                    <img
                        className="blog-post-card-img rounded-t-[10px] hover:rounded-[10px] hover:scale-[1.05] duration-200 pt-track-click object-cover"
                        src={props.thumb}
                        alt={props.title}
                        data-ptname={`blog_list_card_${props.itemId}`}
                        loading="lazy"
                    />
                </div>
                {/* {props.thumbType === 'video' && (
                    <div className="blog-post-card-play-button">
                        <img className="blog-post-card-play-icon" src={playicon} alt="play-icon" />
                    </div>
                )} */}
                <div className="p-4 ">
                    <Link to={url} state={props.propsObj}>
                        <h3
                            className="font-medium text-lg hover:scale-[0.95] duration-150 font-roboto line-clamp pt-track-click md:h-[56px]"
                            data-ptname={`blog_list_card_${props.itemId}`}
                        >
                            {props.title}
                        </h3>
                    </Link>
                    <br />
                    <BlogPostCardDetails profileUrl={profileUrl} {...props} />

                    {isProBlog === true ? (
                        <ContentProtectionCTA
                            place="BlogListingPage"
                            canViewBlog={canViewBlog}
                            cta={proText}
                        />
                    ) : (
                        <div className="!h-[25px] w-2 " id="abcd"></div>
                    )}
                </div>
            </a>
        </div>
    );
}

export default BlogPostCard;
