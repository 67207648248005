import axios from 'axios';

export function AvatarUpload(
    onSuccess: (response: any) => void,
    onFailure: (response: any) => void,
    user_data: any,
    File: File
) {
    const data = new FormData();
    data.append('avatarImage', File);
    data.append('avatarCryptImage', File);

    const { instanceId, authToken } = user_data;
    const headers = {
        instanceId,
        authToken
    };

    axios({
        method: 'POST',
        url: process.env.REACT_APP_ONBOARDING_SERVER2 + `avatar-upload`,
        headers,
        data
    })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            console.log(err);
            onFailure(err);
        });
}
