import React from 'react';
// import NewlyMarried from '../NewlyMarried/NewlyMarried';
import IdentifyYourSelf from '../IdentifyYourSelf/IdentifyYourSelf';

const PlanningForPregnancy = (props: any) => {
    const { nav, setNav, selectedData, setSelectedData, isLoading, setIsLoading } = props;

    return (
        <IdentifyYourSelf
            nav={nav}
            setNav={setNav}
            isLoading={isLoading}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setIsLoading={setIsLoading}
            isPlanningForPregnancy={true}
        />
    );
};

export default PlanningForPregnancy;
