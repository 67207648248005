import React from 'react';
import { useUserData } from '../../utils/hooks/useUserData';
const LockIconSvg = 'https://img1.parentune.com/images/icons/content%20Lock%20icon.svg';
const UnlockIconSvg = 'https://img1.parentune.com/web/assets/icons/UnlockIcon.svg';

interface ContentProtectionCTATypes {
    place: string;
    cta?: string;
    canViewBlog?: boolean;
}

const ContentProtectionCTA = (props: ContentProtectionCTATypes) => {
    const { userData: user_data } = useUserData();
    const { place, cta, canViewBlog } = props;

    const UnlockIconWithCta = () => (
        <section id={place} className="flex justify-start items-center space-x-2 mt-1">
            <img src={UnlockIconSvg} />
            {cta && <p>{cta}</p>}
        </section>
    );
    const ForBlogView = () =>
        canViewBlog ? (
            <section className="flex justify-start items-center mt-1">
                <img src={UnlockIconSvg} />
            </section>
        ) : (
            <section id={place} className="flex justify-start items-center space-x-2 mt-1">
                <img src={LockIconSvg} />
                {cta && <p>{cta}</p>}
            </section>
        );

    const ForEventView = () => (
        // user_data?.isSubscribed ? (
        <section id={place} className="flex justify-start items-center space-x-2">
            <img src={LockIconSvg} />
            <p>{cta ?? 'Only For PRO'}</p>
        </section>
    );
    // ) : null;

    return place == 'UnlockWithCta' ? (
        <UnlockIconWithCta />
    ) : place.includes('Blog') ? (
        <ForBlogView />
    ) : (
        <ForEventView />
    );
};

export default ContentProtectionCTA;
