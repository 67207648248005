import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserBasicInfoType {
    name?: string | null;
    avatar?: string | null;
}

const initialState: UserBasicInfoType = {
    name: '',
    avatar: ''
};

export const userBasicInfoSlice = createSlice({
    name: 'userBasicInfo',
    initialState,
    reducers: {
        addName: (state, action: PayloadAction<UserBasicInfoType>) => {
            const { name, avatar } = action.payload;
            if (name) {
                state.name = name;
            }
            if (avatar) {
                state.avatar = avatar;
            }
        },

        clearName: (state, action: PayloadAction<UserBasicInfoType>) => {
            state.name = '';
            state.avatar = '';
        }
    }
});
export const { addName, clearName } = userBasicInfoSlice.actions;
export default userBasicInfoSlice.reducer;
