import { useRef, useState, useEffect } from 'react';
import './AskQuestion.css';
import { useUserData } from '../../utils/hooks/useUserData';
import LoginPopup from '../LoginPopup/LoginPopup';
import { Constants } from '../Constants/Constants';
import UserIcon from '../Icons/UserIcon';
import QuestionAskedPopUp from './QuestionAskedPopUp';
import Overlay from '../Overlay/Overlay';
import { DefaultUser, SendEvents } from '../../utils/utils';
const send = 'https://img1.parentune.com/web/assets/images/Icon/send-msg.svg';
type AskQuestionType = {
    sender: (msg: string) => void;
    content?: string;
    ageGroup?: any;
    specialization?: any;
    itemid?: any;
    itemType?: any;
    ageGroupId?: any;
    specializationId?: any;
    isAnonymous?: any;
    renderFor?: string;
};
function AskQuestion(props: AskQuestionType) {
    const { userData: user_data, isLoggedIn, guestUid } = useUserData();

    const [reply, setReply] = useState<string>('');
    const [color, setColor] = useState<boolean>(false);
    console.log(user_data);
    const [showModal, setShowModal] = useState(false);
    const [ShowPopUp, setShowPopUp] = useState(false);
    const questionContainerRef = useRef<unknown>(null);
    const inputRef = useRef<any>(null);
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };
    const handleSend = () => {
        if (props.renderFor === 'followup') {
            if (reply.length > 1) {
                setShowPopUp(true);
            }
            return;
        } else {
            if (user_data) {
                if (reply) {
                    try {
                        props.sender(reply);
                        setReply('');
                        let cmtbox = document.getElementById(
                            'commment-box'
                        ) as HTMLInputElement | null;
                    } catch (error) {
                        console.log(error);
                    }
                }
            } else {
                console.log('login required');
                setShowModal(true);
            }
        }
    };

    const handleReplyTextChange = (e: any) => {
        if (user_data) {
            setReply(e.target.value);
        } else {
            console.log('login required');
            setShowModal(true);
        }
    };
    useEffect(() => {
        if (reply != '') {
            setColor(true);
        } else {
            setColor(false);
        }
    });
    return (
        <div className="flex justify-between  items-center  w-full p-2 ">
            <div>
                {isLoggedIn && user_data?.avatar ? (
                    <div className=" mx-auto my-auto ">
                        <img
                            src={user_data?.avatar}
                            alt=""
                            className="h-[30px] w-[30px] rounded-full border-2"
                            loading="lazy"
                        />
                    </div>
                ) : (
                    <UserIcon />
                )}
            </div>
            <div className="flex ml-2 items-center h-8 justify-between w-full space-x-2">
                <div
                    className="h-full w-full"
                    onClick={() => {
                        SendEvents(user_data?.id ?? DefaultUser, 'btn-click', 'btn_typing_blog');
                    }}
                >
                    <input
                        className="pl-2 shadow-sm placeholder:text-sm   md:placeholder:text-base font-normal  outline-none w-full px-1 py-3 font-roboto rounded-md"
                        placeholder={props.content}
                        value={reply}
                        id="commment-box"
                        onChange={handleReplyTextChange}
                        type="text"
                        ref={inputRef}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    {color ? (
                        <div className="h-[27px] w-[27px] sm:h-[40px] sm:w-[27px] flex justify-center items-center">
                            <img
                                className="comment-allow cursor-pointer "
                                onClick={handleSend}
                                src={send}
                                alt=""
                                height="100%"
                                width="100%"
                            />
                        </div>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27"
                            height="27"
                            viewBox="0 0 30 27"
                            fill="none"
                        >
                            <path
                                d="M28.2134 10.4117L4.0086 0.226711C2.9889 -0.202411 1.83688 -0.0136375 1.00221 0.719114C0.167544 1.45198 -0.181557 2.58118 0.0912553 3.66599L2.24569 12.2337H12.7941C13.2795 12.2337 13.6731 12.6327 13.6731 13.125C13.6731 13.6173 13.2796 14.0164 12.7941 14.0164H2.24569L0.0912553 22.584C-0.181557 23.6689 0.167486 24.7981 1.00221 25.5309C1.83858 26.2651 2.99071 26.4516 4.00866 26.0233L28.2134 15.8384C29.3154 15.3747 30 14.335 30 13.125C30 11.9151 29.3154 10.8753 28.2134 10.4117Z"
                                fill=""
                            />
                        </svg>
                    )}
                </div>
            </div>
            {ShowPopUp && (
                <Overlay>
                    <QuestionAskedPopUp
                        age={props?.ageGroup}
                        specialization={props?.specialization}
                        itemid={props?.itemid}
                        followUp={reply}
                        specializationId={props?.specializationId}
                        ageGroupId={props?.ageGroupId}
                        isAnonymous={props?.isAnonymous}
                        setClose={(value: boolean) => {
                            setShowPopUp(value);
                        }}
                    />
                </Overlay>
            )}
            {showModal ? (
                <LoginPopup
                    showModal={showModal}
                    setShowModal={setShowModal}
                    questionContainerRef={questionContainerRef}
                    modalType={Constants.MODAL_TYPE.COMMENT}
                ></LoginPopup>
            ) : null}
        </div>
    );
}

export default AskQuestion;
