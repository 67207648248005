import styles from './styles.module.css';

export interface TextWrapperProps {
    children?: any;
    line?: number;
    maxWidth?: string;
}

export function TextWrapper(props: TextWrapperProps) {
    return (
        <div
            className={`font-medium text-lg hover:scale-[0.95] duration-150 font-roboto ${styles.line_clamp}`}
            style={{ WebkitLineClamp: props.line, maxWidth: props.maxWidth }}
        >
            {props.children}
        </div>
    );
}

export function TextWrapper2(props: TextWrapperProps) {
    return (
        <div className={` `} style={{ WebkitLineClamp: props.line, maxWidth: props.maxWidth }}>
            {props.children}
        </div>
    );
}
