export const EventSkeletel = () => (
    <main
        className="animate animate-pulse site-spacing
    "
    >
        <div
            className="h-60 sm:h-[400px]
         w-full px-5 bg-gray-300 mt-3"
        ></div>
        <section className="mx-5">
            <div className="h-5 w-[40%] px-5 bg-gray-300 mt-3"></div>
            <div className="h-8 w-[80%] px-5 bg-gray-300 mt-3"></div>
            <div className="h-8 w-[30%] px-5 bg-gray-300 mt-1"></div>
            <div className="flex justify-between items-center my-10">
                <div className="h-8 w-[30%] px-5 bg-gray-300 mt-1"></div>
                <div className="h-8 w-[50%] px-5 bg-gray-300 mt-1"></div>
            </div>
            <div className="h-5 w-[85%] px-5 bg-gray-300 mt-3"></div>
            <div className="h-5 w-[60%] px-5 bg-gray-300 mt-3"></div>

            <div className="h-56 sm:h-40 w-[100%] px-5 bg-gray-300 mt-3"></div>
        </section>
    </main>
    // <div className="grid md:grid-cols-2 gap-8 grid-cols-1 px-2 py-10">
    //     {[1].map((_, index) => (
    //         <div
    //             key={index}
    //             className="flex w-5/6 border rounded-md shadow-md gap-4 p-6 flex-col mx-auto animate-pulse">
    //             <div className="flex gap-4">
    //                 <div className="bg-gray-300 flex-shrink-0 rounded-md h-28 w-28"> </div>
    //                 <div className="flex flex-col w-full justify-between">
    //                     <div className="bg-gray-300 rounded-md h-10 w-full"></div>
    //                     <div className="bg-gray-300 rounded-md h-10 w-full"></div>
    //                 </div>
    //             </div>
    //             <div className="flex gap-4">
    //                 <div className="bg-gray-300 rounded-md h-10 w-full"></div>
    //             </div>
    //             <div className="animate-pulse w-2/4 ml-auto px-4 bg-gray-300 h-10 rounded-full border-gray-400" />
    //         </div>
    //     ))}
    // </div>
);
