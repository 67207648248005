import axios from 'axios';
import { country_codes_w_phone } from '../utils';

const useFetchUserLocation = async () => {
    const Country = localStorage.getItem('UserLocation');
    console.log('Page rerender...');
    if (Country) return JSON.parse(Country);

    try {
        // return {
        //     country: 'IN',
        //     city: 'Delhi',
        //     country_code: '+91',
        //     country_name: 'India',
        //     currency_symbol: '₹',
        //     currency_key: 'INR'
        // };
        const res = await axios.get('https://pro.ip-api.com/json/?key=8s3x1papPU1F8av');

        let data = null;

        if (res.status !== 200) {
            data = {
                country: 'IN',
                city: 'New Delhi'
            };
        }
        const currencyApi = process.env.REACT_APP_SUBSCRIPTION_SERVER + 'getCurrency';

        let country_code;
        // @ts-ignore
        country_code = country_codes_w_phone[res?.data?.countryCode?.toUpperCase() || 'IN'];
        console.log('country_code', country_code);
        if (res.data !== null || res.data !== undefined) {
            console.log('RES TEST', res.data.countryCode);

            const currency_details = await axios.get(currencyApi, {
                headers: {
                    country: res.data.countryCode
                }
            });
            console.log(currency_details);
            data = {
                country: res?.data?.countryCode?.toUpperCase() || 'IN',
                city: res?.data?.city || 'New Delhi',
                country_code: country_code,
                // @ts-ignore
                country_name: res?.data?.country || 'India',
                currency_symbol: currency_details?.data?.data[0] || '₹',
                currency_key: currency_details?.data?.data[1] || 'INR'
            };

            console.log('CURRENCYCYCYYCY ', currency_details);

            // temp fix
            // const data = {
            //     country: 'IN',
            //     city: 'New Delhi'
            // };

            console.log('DATAAA', data);

            localStorage.setItem('UserLocation', JSON.stringify(data));
            return data;
        }
    } catch (error) {
        return {
            country: 'IN',
            city: 'Delhi',
            country_code: '+91',
            country_name: 'India',
            currency_symbol: '₹',
            currency_key: 'INR'
        };
    }
};

export default useFetchUserLocation;
