import { useState, useEffect, useRef } from 'react';
import './Question.css';
import axios from 'axios';
import { Reply } from '../QuestionThread/QuestionThread';
import AskQuestion from '../AskQuestion/AskQuestion';
import { useUserData } from '../../utils/hooks/useUserData';
import pushEventToGtm from '../../utils/pushEventToGtm';

import { useLocation, useNavigate } from 'react-router-dom';
import Overlay from '../Overlay/Overlay';
import styles from './question.module.css';
import { Buffer } from 'buffer';
import { Constants } from '../Constants/Constants';
import SubmitButton from '../SubmitButton/SubmitButton';
import CloseIcon from '../Icons/CloseIcon';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
const ReplyIcon = 'https://img1.parentune.com/web/assets/images/Reply.svg';
const img1 = 'https://img1.parentune.com/web/assets/images/Icon/Support.svg';
const img4 = 'https://img1.parentune.com/web/assets/images/Icon/isSupported.svg';
const img5 = 'https://img1.parentune.com/web/assets/images/Hide.svg';
const REACT_APP_CONTENT_SERVER = process.env.REACT_APP_CONTENT_SERVER;
const SelectedLanguage = Geti18nLang();
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

interface questionprops {
    comment: string;
    date: string;
    isSupported: boolean;
    itemId: number;
    parentItemId: string;
    parentItemType: string;
    replyCount: number;
    supportCount: number;
    userAvatar: string;
    type: string;
    userDetail: string;
    userName: string;
    user_id: string;
}

export const Profile = (props: questionprops) => {
    const RedirectToProfile = (userId: string) => {
        window.location.href = 'https://www.parentune.com/user/profile/' + userId;
    };

    const navigate = useNavigate();
    const { userData: user_data } = useUserData();
    return (
        <div className="qcm gap-3 items-center px-2">
            {user_data && (
                <img
                    src={props.userAvatar}
                    alt=""
                    onClick={() => {
                        // navigate(`/user/profile/${props?.user_id}`);
                        RedirectToProfile(props?.user_id);
                    }}
                    style={{ cursor: 'pointer' }}
                />
            )}
            {!user_data && <img className="blur-sm" src={props.userAvatar} alt="" />}
            <div>
                {user_data && (
                    <div
                        className="qcmh1"
                        onClick={() => {
                            // navigate(`/user/profile/${props?.user_id}`);
                            RedirectToProfile(props?.user_id);
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        {props.userName}
                    </div>
                )}
                {user_data &&
                    location.pathname.includes('/livechats') &&
                    props.type &&
                    props.type === 'comment' && (
                        <p className="text-[#787878] text-[16px] font-normal">
                            {props?.userDetail}
                        </p>
                    )}

                {!user_data && <div className="qcmh1 blur-sm">{props.userName}</div>}
                {props.type && props.type !== 'comment' && (
                    <div className="qcmh2">{props.userDetail}</div>
                )}
            </div>
        </div>
    );
};

export const Question = (props: any) => {
    // console.log(props);

    const { userData: user_data } = useUserData();
    const { t } = useTranslation();
    const [more, setMore] = useState(false);

    const [replies, setReplies] = useState<any[]>([]);
    const [isSupport, setIsSupport] = useState(props.is_supported);
    const [isCommentSupport, setIsCommentSupport] = useState(false);
    const [supportCount, setSupportCount] = useState(props.supportCount);
    // const [showReply, setShowReply] = useState(false);
    const [replyCount, setReplyCount] = useState(props.replyCount);
    const [loading, setLoading] = useState<boolean>();
    const [showReplies, setShowReplies] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const questionContainerRef = useRef<any>(null);
    const [modalType, setModalType] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const redirectToLogin = () =>
        navigate('/register', {
            replace: true,
            state: { from: location }
        });

    const handleReply = () => {
        if (!user_data) {
            redirectToLogin();
        } else {
            setShowReplies((s) => !s);
            handleFetchReplies('3');
        }
    };

    const sender = (msg: any) => {
        axios
            .post(
                REACT_APP_CONTENT_SERVER + 'content/comment/add',
                {
                    itemType: 'comment',
                    comment: msg,
                    parentItemType: props.parentItemType,
                    parentItemId: props.parentItemId,
                    itemId: props.itemId
                },
                {
                    headers: {
                        // userId: user_data?.userId?.toString() || '',
                        instanceId: user_data?.instanceId?.toString() || '',
                        authToken: user_data?.authToken || ''
                        // lang: SelectedLanguage || 'en'
                    }
                }
            )
            .then(({ data }) => {
                data.statusCode === 200 && alert('Commented Successfully');
                if (props.parentItemType === 'comment') {
                    pushEventToGtm('reply', {
                        pt_itemType: props.parentItemType,
                        pt_itemId: props.parentItemId
                    });
                } else {
                    pushEventToGtm('comment', {
                        pt_itemType: props.parentItemType,
                        pt_itemId: props.parentItemId
                    });
                }
                setReplyCount((s: number) => s + 1);
                // setShowReply(false);
                handleFetchReplies('3');
            })
            .catch((error) => console.log(error));
    };

    const handleFetchReplies = (type: string) => {
        setLoading(true);
        axios
            .get(REACT_APP_CONTENT_SERVER + 'content/comment', {
                params: {
                    itemType: 'comment',
                    pageNumber: 1,
                    count: 'all',
                    itemId: props.itemId
                },
                headers: {
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || '',
                    lang: SelectedLanguage || 'en'
                }
            })
            .then(({ data }) => {
                setReplies((s: any) => [...data.comments]);
                setLoading(false);
            });
    };

    const handleSupport = () => {
        if (!user_data) {
            redirectToLogin();
        } else {
            if (isSupport) {
                setIsSupport(!isSupport);
                setSupportCount((s: number) => s - 1);
            } else {
                setIsSupport(!isSupport);
                setSupportCount((s: number) => s + 1);
            }

            axios
                .post(
                    REACT_APP_CONTENT_SERVER + 'content/support',
                    {
                        itemType: 'comment',
                        itemId: props.itemId,
                        parentItemType: props.parentItemType,
                        parentItemId: props.parentItemId
                    },
                    {
                        headers: {
                            userId: user_data?.userId?.toString() || '',
                            instanceId: user_data?.instanceId?.toString() || '',
                            authToken: user_data?.authToken || '',
                            lang: SelectedLanguage || 'en'
                        }
                    }
                )
                .then(({ data }) => {
                    console.log(data);
                })
                .catch((error) => console.log(error));
        }
    };

    useEffect(() => {
        setIsSupport(props.is_supported);
        setSupportCount(props.supportCount);
        setReplyCount(props?.replyCount);
    }, [props.is_supported, props.supportCount, props?.replyCount]);

    // useEffect(() => {
    //     console.log('');
    // }, [replies]);

    const handleModalClose = (e: any) => {
        if (questionContainerRef && questionContainerRef?.current.contains(e.target)) {
            let el = e.target.textContent;
            let el2 = e.target.getAttribute('class');
            if (el2 === 'svg-icon') {
                setShowModal(false);
            } else {
                if (el === 'Done' || el === 'Skip') {
                    setShowModal(false);
                } else return;
            }
        } else {
            setShowModal(false);
        }
    };

    const modalHeaderView = () => {
        return (
            <span
                className={styles.closeIcon}
                style={{ margin: '0 20px' }}
                onClick={handleModalClose}
            >
                <CloseIcon />
            </span>
        );
    };

    const modalContentView = () => {
        let view = <p></p>;
        if (modalType === Constants.MODAL_TYPE.SUPPORT) {
            view = <p>Please Login/Signup to support</p>;
        } else if (modalType === Constants.MODAL_TYPE.REPLY) {
            view = <p>Please login or register to continue</p>;
        } else if (modalType === Constants.MODAL_TYPE.BOOKMARK) {
            view = <p>Please login or register to continue</p>;
        }
        return view;
    };

    const modalActionView = () => {
        let view = <button></button>;
        const currentURL = window.location.href;
        const redirect = Buffer.from(currentURL).toString('base64');

        if (modalType === Constants.MODAL_TYPE.SUPPORT) {
            view = (
                <SubmitButton
                    transparent
                    handleSubmit={() => redirectToLogin()}
                    buttonText={'Continue'}
                    className={styles._abc}
                />
            );
        } else if (modalType === Constants.MODAL_TYPE.REPLY) {
            view = (
                <SubmitButton
                    transparent
                    handleSubmit={() => redirectToLogin()}
                    buttonText={'Continue'}
                    className={styles._abc}
                />
            );
        } else if (modalType === Constants.MODAL_TYPE.BOOKMARK) {
            view = (
                <SubmitButton
                    transparent
                    handleSubmit={() => redirectToLogin()}
                    buttonText={'Continue'}
                    className={styles._abc}
                />
            );
        }
        return view;
    };

    const toRegister = (url: string) => {
        open(url, '_self');
    };

    // const getReplyView = () => {
    //     let view = '';
    //     if (loading) {
    //         view = 'Loading...';
    //     } else if (replyCount === 1) {
    //         view = 'View 1 Reply';
    //     } else {
    //         view = 'View ' + replyCount + ' Replies';
    //     }
    //     return (
    //         <div className="qcm31" style={{ marginLeft: '10px', cursor: 'pointer' }}>
    //             <span style={{ color: '#C4C4C4' }}>|</span>
    //             <span style={{ cursor: 'pointer' }} onClick={handleFetchReplies}>
    //                 {view}
    //             </span>
    //         </div>
    //     );
    // };
    // console.log(replies);

    function DescriptionBox() {
        // Regular expression to find links (URLs) in the text

        const linkRegex = /<\s*a[^>]*>/;

        let comment = more ? props.comment : props.comment.slice(0, 200);

        // Split the text into parts: text segments and links
        const parts = comment?.split(linkRegex);

        return (
            <div className="">
                <p>
                    {parts.map((part: string, index: number) =>
                        linkRegex.test(part) ? (
                            <a className="text-[#377E8B] focus:opacity-70" href={part} key={index}>
                                {part}
                            </a>
                        ) : (
                            <span key={index}>{part}</span>
                        )
                    )}
                </p>
            </div>
        );
    }

    return (
        <div className="qcm1">
            <Profile {...props} />
            <div className="qcmq  ml-2 mt-4">
                {props.commentType && props.commentType === 'sticker' ? (
                    <img className="w-32 h-32" src={props.comment} />
                ) : (
                    props.comment && (
                        <div>
                            <div
                                className="htmlConmment"
                                dangerouslySetInnerHTML={{
                                    __html: `<p>${
                                        more ? props.comment : props.comment.slice(0, 200)
                                    }</p>`
                                }}
                            ></div>

                            {/* <DescriptionBox /> */}

                            {/* {more ? props.comment : props.comment.slice(0, 200)} */}
                            {props.comment.length > 200 && (
                                <span onClick={() => setMore((s) => !s)}>
                                    {more ? '...less' : '...more'}
                                </span>
                            )}
                        </div>
                    )
                )}
            </div>
            <div className="qcm3">
                {props?.type === 'reply' ? (
                    <></>
                ) : (
                    <>
                        <div
                            onClick={handleReply}
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                        >
                            <img src={ReplyIcon} style={{ width: '16px', height: '14px' }} alt="" />{' '}
                            <span className="md:text-sm text-[14px] font-normal qcm31">
                                {replyCount === 1
                                    ? `${replyCount} Reply`
                                    : replyCount > 1
                                    ? `${replyCount} Replies`
                                    : 'Reply'}
                            </span>
                        </div>
                        <span style={{ color: '#C4C4C4' }}>|</span>
                    </>
                )}

                {props?.type === 'reply' ? (
                    <></>
                ) : (
                    <div onClick={handleSupport} style={{ cursor: 'pointer' }}>
                        <img
                            style={{ width: '16px', height: '14px' }}
                            src={isSupport ? img4 : img1}
                            alt=""
                        />{' '}
                        <span className="md:text-sm text-[14px] font-normal qcm31">
                            {supportCount} {t('Support')}
                        </span>
                    </div>
                )}

                {/* {replyCount && !showReplies && props?.showReplies === true ? getReplyView() : null} */}
            </div>

            {props.parentItemType &&
            props.parentItemType == 'talk' &&
            props.replyCount > 0 &&
            showReplies ? (
                <div
                    style={{
                        margin: '20px 10px',
                        display: 'flex',
                        fontFamily: 'Roboto',
                        fontWeight: '500px',
                        fontSize: '14px',
                        lineHeight: '16px',
                        alignItems: 'center'
                    }}
                >
                    <div className="flex items-center">
                        <div className="showingReplies mr-[10px]">
                            {replyCount === 1
                                ? 'Showing 1 Reply'
                                : 'Showing ' + replyCount + ' Replies'}
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => setShowReplies(false)}>
                            <img src={img5} alt="close" />
                        </div>
                    </div>
                </div>
            ) : null}
            {replies.length && showReplies
                ? replies
                      .reverse()
                      .map((r, i) => (
                          <Reply
                              key={i}
                              {...r}
                              type="reply"
                              itemId={r.itemId}
                              itemType="comment"
                              parentItemId={props.itemId}
                              parentItemType="comment"
                          />
                      ))
                : null}
            {/* {replyCount > 3 && showReplies && replyCount != replies.length && replies.length > 0 && (
                <div>
                    <button
                        onClick={() => handleFetchReplies('All')}
                        className="bg-slate-200 px-2 rounded-md">
                        View All replies
                    </button>
                </div>
            )} */}
            {showReplies && (
                <div className="ml-5">
                    {' '}
                    <AskQuestion
                        content="Reply to this chat..."
                        sender={sender}
                        renderFor="comments"
                    />
                </div>
            )}

            {showModal ? (
                <Overlay handleModalClose={handleModalClose}>
                    <div className="instant-box">
                        <div className={styles.container} ref={questionContainerRef}>
                            <div className={styles.header}>{modalHeaderView()}</div>
                            <div className={styles.content}>{modalContentView()}</div>
                            <div className={styles.footer}>{modalActionView()}</div>
                        </div>
                    </div>
                </Overlay>
            ) : null}
        </div>
    );
};
