import './buttonWithIcon.css';

type ButtonWithIconType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyleMain?: React.CSSProperties;
    customStyleText?: React.CSSProperties;
    startIcon?: any;
};

const ButtonWithIcon = (props: ButtonWithIconType) => {
    const {
        size,
        text,
        className,
        onClick,
        disabled = false,
        customStyleMain,
        customStyleText,
        startIcon
    } = props;

    const buttonView = () => {
        return (
            <div className="chip-main cursor-pointer" style={customStyleMain} onClick={onClick}>
                <div className="chip-text-icon-wrapper">
                    {startIcon ? <span>{props.startIcon} </span> : null}

                    <div
                        // type="button"
                        // disabled={disabled}
                        className={`chip-text border-none pt-track-click `}
                        data-ptname={`btn_${text}`}
                        style={customStyleText}
                    >
                        {text}
                    </div>
                </div>
            </div>
        );
    };

    return buttonView();
};
export default ButtonWithIcon;
