import { emailRegex } from '../OTPPage/utils';

interface ReceiverDetailType {
    name: string;
    email: string;
    mobile: string;
    message: string;
    plan: number;
    checkbox: string;
    error: {
        name: string;
        email: string;
        mobile: string;
    };
}

export const validReceiverDetail = (userDetails: ReceiverDetailType) => {
    let isValid = true;

    const errors = {
        name: '',
        email: '',
        mobile: '',
        message: ''
    };

    if (userDetails.name && userDetails.name.length < 3) {
        errors.name = 'Name must be at least 3 characters long';
        isValid = false;
    }

    if (userDetails.email && !userDetails.email.match(emailRegex)) {
        errors.email = 'Please enter your valid email';
        isValid = false;
    }

    if (userDetails.mobile && userDetails.mobile.length < 10) {
        errors.mobile = 'Please enter your valid mobile number';
        isValid = false;
    }

    if (userDetails.message && userDetails.message.length > 200) {
        errors.message = 'Messsage length should be less than 200 characters';
        isValid = false;
    }

    return { isValid, errors };
};
