import React, { useState, useEffect } from 'react';

const EventCountdown = (props) => {
    const eventDate = new Date(props.date + '+5:30');
    const _isForLiveChatAd = props?.isForLiveChatAd ?? false;
    const calculateTimeLeft = () => {
        const currentTime = new Date();
        const timeDifference = eventDate - currentTime;
        if (timeDifference < 0) {
            if (_isForLiveChatAd) return null;
            // Event has already passed
            return {
                // days: 0,
                hours: '00',
                minutes: '00',
                seconds: '00'
            };
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);
        hours = days * 24 + hours;
        return {
            // days,
            hours,
            minutes,
            seconds
        };
    };

    const [remainingTime, setRemainingTime] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            let timeLeft = calculateTimeLeft();
            setRemainingTime(timeLeft);
            if (timeLeft && timeLeft.hours == 0 && timeLeft.minutes == 0 && timeLeft.seconds == 0) {
                window.location.reload(false);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    return (
        <div
            className={`${
                _isForLiveChatAd ? 'font-bold' : 'bg-[#D96572] text-white p-1 px-3 rounded-lg'
            } `}
        >
            {remainingTime && (
                <div>
                    {remainingTime.hours.toString().length > 1
                        ? remainingTime.hours.toString()
                        : '0' + remainingTime.hours.toString()}
                    :
                    {remainingTime.minutes.toString().length > 1
                        ? remainingTime.minutes.toString()
                        : '0' + remainingTime.minutes.toString()}
                    :
                    {remainingTime.seconds.toString().length > 1
                        ? remainingTime.seconds.toString()
                        : '0' + remainingTime.seconds.toString()}
                </div>
            )}
        </div>
    );
};

export default EventCountdown;
