function BoyIcon(props) {
    return (
        <svg
            width={props?.size ?? 15}
            height={props?.size ?? 15}
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.733 14.163a5.04 5.04 0 003.983-8.137l3.589-3.589v2.262a.631.631 0 001.261 0V.913a.632.632 0 00-.63-.63H10.15a.631.631 0 100 1.261h2.262L8.823 5.133a5.048 5.048 0 10-3.09 9.03zm0-8.833a3.786 3.786 0 110 7.572 3.786 3.786 0 010-7.572z"
                fill="#000"
            />
        </svg>
    );
}

export default BoyIcon;
