import * as React from 'react';

function LiveIcon(props) {
    return (
        <svg
            width="38"
            height="31"
            viewBox="0 0 38 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="38" height="31.0001" rx="8" fill="#D96572" />
            <path
                d="M16.3805 10.9099V11.4367H14.3984V8.00391H15.0285V10.9099H16.3805Z"
                fill="white"
            />
            <path d="M17.4625 11.4367H16.8306V8.00391H17.4625V11.4367Z" fill="white" />
            <path
                d="M21.1171 8.00391L19.7501 11.4367H19.1819L17.8149 8.00391H18.3212C18.3756 8.00391 18.4206 8.01722 18.4544 8.04384C18.4881 8.07047 18.5144 8.1047 18.5313 8.14844L19.3301 10.2423C19.3563 10.3108 19.3826 10.385 19.407 10.4668C19.4313 10.5485 19.4538 10.6341 19.4763 10.7216C19.4951 10.6322 19.5157 10.5485 19.5363 10.4668C19.5588 10.385 19.5832 10.3108 19.6095 10.2423L20.4027 8.14844C20.4158 8.11231 20.4402 8.07808 20.4758 8.04955C20.5133 8.01912 20.5564 8.00391 20.6108 8.00391H21.1171Z"
                fill="white"
            />
            <path
                d="M22.0997 8.51159V9.4625H23.2829V9.95316H22.0997V10.9231H23.6017V11.4328H21.4678V8H23.6017V8.50778H22.0997V8.51159Z"
                fill="white"
            />
            <path
                d="M24.0703 13.1768C26.643 15.8849 26.643 20.2914 24.0703 22.9996"
                stroke="white"
                strokeWidth="0.897139"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.811 15.6885C22.9793 17.0121 22.9793 19.165 21.811 20.4887"
                stroke="white"
                strokeWidth="0.897139"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.9295 23.0006C11.3568 20.2924 11.3568 15.8859 13.9295 13.1777"
                stroke="white"
                strokeWidth="0.897139"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.1892 20.4877C15.0209 19.164 15.0209 17.0112 16.1892 15.6875"
                stroke="white"
                strokeWidth="0.897139"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.9998 19.4258C19.7289 19.4258 20.3199 18.8264 20.3199 18.0869C20.3199 17.3475 19.7289 16.748 18.9998 16.748C18.2707 16.748 17.6797 17.3475 17.6797 18.0869C17.6797 18.8264 18.2707 19.4258 18.9998 19.4258Z"
                fill="white"
            />
        </svg>
    );
}

export default LiveIcon;
