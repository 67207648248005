import axios from 'axios';
// import i18n from '../../i18n/config';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();

function setReminder(
    props: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any }
) {
    const { id, email, mobile, user_data, guestUid, calendarInvite, title } = props;
    return axios
        .post(
            process.env.REACT_APP_LIVEEVENTS_SERVER + 'reminder',
            {
                eventId: id,
                title: title,
                email: email,
                mobile: mobile,
                calendarInvite: calendarInvite
            },

            {
                headers: {
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || '',
                    lang: SelectedLanguage || 'en',
                    guestUid: guestUid || ''
                }
            }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}
export default setReminder;
