import { Disclosure, Menu, Transition } from '@headlessui/react';
import { t } from 'i18next';
import { useRef } from 'react';
import { dropDownData } from '../../dummydata/dummyData';
import { addFilter } from '../../store/slices/filterSlice';
import { useAppDispatch } from '../../store/store';
import { useFilterData } from '../../utils/hooks/useFilterData';
import Gutter from '../Gutter/Gutter';
import ArrowupIcon from '../Icons/ArrowupIcon';
import styles from './styles.module.css';

export interface TopicList {
    ageGroup: string;
    ageGroupId: string;
    interestCluster: InterestCluster[];
}

export interface InterestCluster {
    name: string;
    id: string;
}

export interface propsType {
    handleApply: any;
}

export default function DisclosureNew(props: propsType) {
    const dispatch = useAppDispatch();
    const { topicId, ageGroup } = useFilterData();

    let topicList: InterestCluster[] = [];

    dropDownData.map((item) => {
        if (item.ageGroupId === String(ageGroup)) {
            topicList = item.interestCluster;
        }
    });

    const handleTopic = (e: any) => {
        let val = e.target.dataset.id ? parseInt(e.target.dataset.id) : e.target.dataset.id;
        dispatch(addFilter({ topicId: val }));
        props.handleApply(val);
    };

    const handleAgeGroup = (e: any) => {
        let val = e.target.dataset.id ? parseInt(e.target.dataset.id) : e.target.dataset.id;
        dispatch(addFilter({ ageGroup: val }));
    };

    const menuItemView = () => {
        let view: JSX.Element[] = [];
        topicList.forEach((item, index) => {
            view.push(
                <Menu.Item key={`${index}_${item}`}>
                    {({ active }) => (
                        <p
                            onClick={handleTopic}
                            className={`${
                                item.id === String(topicId)
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                            } flex justify-between w-full p-[10px] text-sm leading-5 text-left cursor-pointer`}
                            data-id={item.id}
                        >
                            {t(item.name)}
                        </p>
                    )}
                </Menu.Item>
            );
        });
        return view;
    };
    const ageGroupView = () => {
        let view: JSX.Element[] = [];
        const ageGroupList = dropDownData;
        ageGroupList.forEach((item, index) => {
            view.push(
                <Menu.Item key={`${index}_${item}`}>
                    {({ active }) => (
                        <p
                            onClick={handleAgeGroup}
                            className={`${
                                item.ageGroupId === String(ageGroup)
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700'
                            } flex justify-between w-full p-[10px] text-sm leading-5 text-left cursor-pointer`}
                            data-id={item.ageGroupId}
                        >
                            {t(item.ageGroup)}
                        </p>
                    )}
                </Menu.Item>
            );
        });
        return view;
    };

    return (
        <div className="w-full">
            <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
                <Disclosure as="div" className="max-h-64 overflow-auto" defaultOpen={true}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button
                                className={`flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 ${styles.topicW}`}
                            >
                                <p className={styles.topics}>{t('Age Group')}</p>
                                <ArrowupIcon
                                    className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-purple-500`}
                                    color="#C4C4C4"
                                />
                            </Disclosure.Button>
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel static className="text-sm text-gray-500">
                                    {({ open }) => <Menu>{ageGroupView()}</Menu>}
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>

                <Gutter
                    styles={{
                        border: '1px solid #EEEEEE',
                        flex: 'none',
                        order: 1,
                        alignSelf: 'stretch',
                        flexGrow: 0
                    }}
                />
                <Disclosure as="div" className="max-h-64 overflow-auto">
                    {({ open }) => (
                        <>
                            <Disclosure.Button
                                disabled={ageGroup === 'none' || !ageGroup ? true : false}
                                className={`flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 ${styles.topicW}`}
                            >
                                <p className={styles.topics}>{t('All Topics')}</p>
                                <ArrowupIcon
                                    className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-purple-500`}
                                    color="#C4C4C4"
                                />
                            </Disclosure.Button>

                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel className="text-sm text-gray-500 max-h-80 overflow-auto">
                                    {({ open }) => <Menu>{menuItemView()}</Menu>}
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}
