import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import { useUserData } from '../../../utils/hooks/useUserData';
import FormProgress from '../../FormProgress/FormProgress';
import TextInputForm from '../../TextInputForm/TextInputForm';
import EditProfileParentSelect from '../../Profile/EditProfileParentSelect/EditProfileParentSelect';
import { GetUserProfile } from '../../../api/OnBoarding/GetUserProfile';
import { addUserdata, clearUserData } from '../../../store/slices/userDataSlice';
import { UpdateProfile } from '../../../api/OnBoarding/UpdateProfile';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import { Navigate, useNavigate } from 'react-router-dom';
import Header from '../../Header/Header';
import SelectionFormOption from '../../SelectionFormOption/SelectionFormOption';

const ChooseLanguage = (props: any) => {
    const [selectedData, setSelectedData] = useState<any>();

    const data = [
        {
            name: 'English',
            id: 1,
            response: 'ENG'
        },
        {
            name: 'हिन्दी',
            id: 2,
            response: 'HINDI'
        },
        {
            name: 'मराठी',
            id: 3,
            response: 'MARATHI'
        },
        {
            name: 'தமிழ்',
            id: 4,
            response: 'TAMIL'
        },
        {
            name: 'తెలుగు',
            id: 5,
            response: 'TELGU'
        }
    ];

    return (
        <main>
            <div className=" h-[100vh] animate-fade-in-left max-w-4xl max-h-[100vh] mx-auto  flex flex-col px-4">
                <h1 className="font-proxima font-bold text-xl text-[#242F35] mx-auto text-center py-4">
                    Choose a langugage
                </h1>
                {/* {JSON.stringify(data[selectedData - 1])} */}

                <section className="my-2 flex flex-col gap-0">
                    {data &&
                        data?.length > 0 &&
                        data.map((d: any, index: number) => {
                            return (
                                <div key={index}>
                                    <SelectionFormOption
                                        icon={d.icon}
                                        option={d.name}
                                        response={d.response}
                                        id={d.id}
                                        setSelectedOption={setSelectedData}
                                        isSelected={selectedData?.id === d.id}
                                    />
                                </div>
                            );
                        })}
                </section>

                <div className="flex-1 flex flex-col justify-end pb-3 mt-12">
                    <ButtonPrimary
                        size="large"
                        text="Next"
                        onClick={() => {
                            //
                            localStorage.setItem('onboarding_nav', 'SetupPorfile');
                            localStorage.setItem('tes_nav', 'SetupPorfile');

                            window.location.assign('/hi/register');
                        }}
                    />
                </div>
            </div>
        </main>
    );
};

export default ChooseLanguage;
