import './SelectionFormOption.css';
import { Link } from 'react-router-dom';
import { useState, memo } from 'react';
import { VibrationActive } from '../../utils/utils';

interface FormProps {
    icon: string;
    option: any;
    response: {
        parentType: string;
        parentStage: string;
    };
    id: number;
    setSelectedOption: any;
    isSelected: any;
}

function SelectionFormOption(props: FormProps) {
    const { response, icon, id, setSelectedOption, option, isSelected } = props;

    const handleOptionSelect = () => {
        if (!isSelected) {
            setSelectedOption(id);
            VibrationActive([10]);
        }
    };

    return (
        <div
            onClick={handleOptionSelect}
            className={` animated-SelectionFormOption
                ${
                    isSelected
                        ? 'selection-form-option-selected '
                        : 'selection-form-option cursor-pointer '
                }
                    flex justify-start items-center hover:bb
            `}
        >
            {icon && <img src={icon} alt="" className="mr-5" />}
            {option}
        </div>
    );
}

export default memo(SelectionFormOption);
