import axios from 'axios';

function fetchToken(params: any, onSuccess: any, onFailure: any) {
    axios
        .post(process.env.REACT_APP_BABYNAME + 'fetch-token', {
            weqeqww: params.token,
            guestUid: params.guestUid
        })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export default fetchToken;
