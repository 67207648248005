import axios from 'axios';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';

export const parentType = ['Mother', 'Expect-mother', 'Planning-Mother', 'Woman'];
export async function GetUserProfile(
    onSuccess: (response: any) => void,
    onFailure: (response: any) => void,
    user_data: any
) {
    const { instanceId, authToken, userId } = user_data;

    const { country } = await useFetchUserLocation();
    const headers = {
        instanceId,
        authToken,
        platform: 'web',
        country: country || 'IN'
    };

    axios({
        method: 'GET',
        url: process.env.REACT_APP_ONBOARDING_SERVER2 + `profile?type=advanced&userId=${userId}`,
        headers
    })
        .then((res) => {
            if (
                res?.data?.statusCode === 404 ||
                res?.data?.message == 'USER_NOT_FOUND' ||
                res?.data?.statusCode === 401 ||
                res?.data?.errorCode === 401
            ) {
                window.location.assign('/force-logout/');
            }

            if (res?.data?.data?.can_show_gift_option == 1) {
                localStorage.setItem('gift-user', res?.data?.data?.can_show_gift_option);
            } else {
                localStorage.setItem('gift-user', '0');
            }

            // if (parentType.includes(res?.data?.data?.parentType)) {
            //     localStorage.setItem('UserGender', 'female');
            //     localStorage.setItem('UserGenderFemale', 'true');
            // }

            onSuccess(res.data);
        })
        .catch((err) => {
            console.log(err);
            console.log('err', err);
            onFailure(err);
        });
}
