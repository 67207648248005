import * as React from 'react';

function Calendar(props) {
    return (
        <svg
            width="23"
            height="25"
            viewBox="0 0 23 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.399639 2.1H3.19964V0.900007C3.19964 0.678804 3.37844 0.5 3.59965 0.5H6.39965C6.62085 0.5 6.79965 0.678804 6.79965 0.900007V2.1H15.5996V0.900007C15.5996 0.678804 15.7784 0.5 15.9996 0.5H18.7996C19.0208 0.5 19.1997 0.678804 19.1997 0.900007V2.1H21.9997C22.2209 2.1 22.3997 2.27881 22.3997 2.50001V24.1C22.3997 24.3212 22.2209 24.5 21.9997 24.5H0.39971C0.178507 24.5 -0.000297546 24.3212 -0.000297546 24.1V2.50001C-0.000297546 2.27881 0.178507 2.1 0.39971 2.1H0.399639ZM5.99962 1.29999H3.99963V3.69998H5.99962V1.29999ZM18.3996 1.29999H16.3996V3.69998H18.3996V1.29999ZM21.5996 2.89999H19.1996V4.09999C19.1996 4.32119 19.0208 4.5 18.7996 4.5H15.9996C15.7784 4.5 15.5996 4.32119 15.5996 4.09999V2.89999H6.79958V4.09999C6.79958 4.32119 6.62078 4.5 6.39958 4.5H3.59958C3.37837 4.5 3.19957 4.32119 3.19957 4.09999V2.89999H0.799576V6.49998H21.5995V2.89999H21.5996ZM21.5996 23.6999V7.3H0.799623V23.6999H21.5996Z"
                fill="#242F35"
            />
            <path
                d="M7.99971 9.69995H18.7997V21.2999H3.59973V9.69995H7.99971ZM10.7997 10.5H7.99969V13.3H10.7997V10.5ZM7.99971 16.8999H10.7997V14.0999H7.99971V16.8999ZM14.3997 14.1H11.5997V16.9H14.3997V14.1ZM14.3997 10.5H11.5997V13.3H14.3997V10.5ZM17.9997 10.5H15.1997V13.3H17.9997V10.5ZM17.9997 14.1H15.1997V16.9H17.9997V14.1ZM15.1997 20.4999H17.9997V17.6999H15.1997V20.4999ZM11.5997 20.4999H14.3997V17.6999H11.5997V20.4999ZM7.99971 20.4999H10.7997V17.6999H7.99971V20.4999ZM4.39972 20.4999H7.19972V17.6999H4.39972V20.4999ZM4.39972 16.8999H7.19972V14.0999H4.39972V16.8999ZM4.39972 10.5V13.3H7.19972V10.5H4.39972Z"
                fill="#242F35"
            />
        </svg>
    );
}

export default Calendar;
