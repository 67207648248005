import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

interface UserBasicInfoType {
    pathName?: string | null;
    onboardingSteps?: string[] | any;
    previousStep?: string | null;
    isAllowedToChange?: boolean;
    isForEditProfile?: boolean;
}
const initialState: UserBasicInfoType = {
    pathName: null,
    onboardingSteps: ['SetupProfile'],
    previousStep: null,
    isForEditProfile: false
};

export const onboardingSlice = createSlice({
    name: 'userBasicInfo',
    initialState,
    reducers: {
        updatePathName: (state, action: PayloadAction<string>) => {
            state.pathName = action.payload;
        },
        clearPathName: (state) => {
            state.pathName = null;
        },
        UpdateOnboardingSteps: (state, action) => {
            const previousSteps = [...current(state.onboardingSteps)];
            console.log('previoussteps: ', previousSteps);

            const currentStep = action.payload;
            if (!previousSteps) return;
            console.log('updaing/slicing steps');
            state.onboardingSteps = previousSteps?.includes(currentStep)
                ? [...previousSteps]
                : [...previousSteps, currentStep];
        },
        UpdatePreviousStep: (state, action) => {
            const prevStep = action.payload;
            state.previousStep = prevStep;
        },
        handleSteps: (state) => {
            const previousStep = state.previousStep;
            const steps = state.onboardingSteps;
            if (!steps) return;
            const x = steps[steps?.length - 1];

            if (x === previousStep) {
                const newSteps = steps.slice(0, -1);
                state.onboardingSteps = newSteps;
            }
        },
        defaultOnboardingSteps: (state) => {
            state.onboardingSteps = ['SetupProfile'];
            state.previousStep = null;
        },
        setForEditProfile: (state, action) => {
            state.isForEditProfile = action.payload;
        }
    }
});

export const {
    updatePathName,
    clearPathName,
    UpdateOnboardingSteps,
    UpdatePreviousStep,
    handleSteps,
    defaultOnboardingSteps,
    setForEditProfile
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
