import styles from './textView.module.css';

type TextViewType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyle?: React.CSSProperties;
    startIcon?: any;
    sliderRef?: any;
};

const TextView = (props: TextViewType) => {
    const {
        size,
        text,
        className,
        onClick,
        disabled = false,
        customStyle,
        startIcon,
        sliderRef
    } = props;

    const buttonView = () => {
        return (
            <div className={styles.chipMain} ref={sliderRef} onClick={onClick}>
                <div className={styles.chipTextIconWrapper}>
                    {startIcon ? <span>{props.startIcon} </span> : null}

                    <button
                        style={customStyle}
                        type="button"
                        disabled={disabled}
                        className={styles.chipText}
                    >
                        {text}
                    </button>
                </div>
            </div>
        );
    };

    return buttonView();
};
export default TextView;
