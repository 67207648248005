import React, { useEffect, useState } from 'react';
import { TIMER_LENGTH } from '../../pages/OTPPage/utils';
import axios from 'axios';
import { Transition } from '@headlessui/react';
import OTPInput from '../OTPInput/OTPInput';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import ToastMessage from '../Toast/ToastMessage';
import { useUtilsData } from '../../utils/hooks/useUtislData';

const MobileInputBox = (props: any) => {
    const [countDown, setcountDown] = useState(TIMER_LENGTH);
    const [resend, setResend] = useState(false);
    const [number, setNumber] = useState<any>(props?.preFilledNumber ?? '');
    const [showOtpBox, setShowOtpBox] = useState(false);
    const [, setIsLoading] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [otpVerfied, setotpVerfied] = useState(false);
    const [authData, setauthData] = useState<any>(null);
    const [showToaster, setShowToaster] = useState<boolean>(false);
    const [toasterMsg, settoasterMsg] = useState('');

    const {
        location: { country }
    } = useUtilsData();

    const [inputOTP, setInputOTP] = useState('');
    const [otpReset, setOtpReset] = useState<any>('');

    const { onOtpVerified } = props;

    useEffect(() => {
        if (otpVerfied && number) {
            console.log('otp verified', otpVerfied);
            settoasterMsg('OTP Verified Successfully');
            setShowToaster(true);

            onOtpVerified(number);
        }
    }, [otpVerfied]);

    useEffect(() => {
        console.log('preFilledNumber Mobile Input box', props?.preFilledNumber);
        if (props?.preFilledNumber) setNumber(props?.preFilledNumber);
    }, [props?.preFilledNumber]);

    useEffect(() => {
        console.log('number: ', number);
        setotpVerfied(false);
        setShowToaster(false);
        onOtpVerified(null);
        setShowOtpBox(false);
    }, [number]);

    const ScrollToBottom = () => {
        // if (!window.location.pathname.includes('/workshop/short-term-plan')) return;
        setTimeout(() => {
            window?.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 1000);
    };
    useEffect(() => {
        // setResend(false);
        setIncorrectOTP(false);
        setShowToaster(false);
        if (inputOTP.length !== 4) return;
        handleVerifyOTP();
    }, [inputOTP]);

    useEffect(() => {
        let timer: NodeJS.Timer;

        if (countDown > 0) {
            timer = setInterval(() => {
                setcountDown(countDown - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [countDown]);

    const handlePhoneNumber = () => {
        const headers = {
            'Content-Type': 'application/json',
            country: country || 'INDIA'
        };
        setcountDown(TIMER_LENGTH);
        setResend(false);
        setOtpReset('reset');
        setIncorrectOTP(false);

        setTimeout(() => {
            setResend(true);
        }, TIMER_LENGTH * 1000);

        const countryCode = sessionStorage.getItem('currentCountryCode')
            ? sessionStorage.getItem('currentCountryCode')?.replace('+', '')
            : '91';

        console.log('countryCode', countryCode);
        axios
            .post(
                process.env.REACT_APP_ONBOARDING_SERVER2 + 'auth/send-otp',
                {
                    mode: 'mobile',
                    countryCode: countryCode,
                    mobile: number
                },
                { headers }
            )
            .then(({ data }) => {
                if (data?.statusCode === 200) {
                    setShowOtpBox(true);
                    settoasterMsg('OTP Sent Successfully');
                    setShowToaster(true);
                    ScrollToBottom();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const handleVerifyOTP = () => {
        const countryCode = sessionStorage.getItem('currentCountryCode')
            ? sessionStorage.getItem('currentCountryCode')?.replace('+', '')
            : '91';

        const headers = {
            'Content-Type': 'application/json',
            country: country || 'INDIA'
        };

        setIsLoading(true);
        axios
            .post(
                process.env.REACT_APP_ONBOARDING_SERVER2 + 'auth/verify-otp',
                {
                    mode: 'mobile',
                    countryCode,
                    mobile: number,
                    otp: inputOTP,
                    email: props?.email ?? null
                },
                { headers }
            )
            .then(({ data }) => {
                console.log('otp vefied', data);
                if (data?.statusCode !== 201) {
                    setIncorrectOTP(true);
                    return;
                }
                if (data?.statusCode === 401) {
                    setIncorrectOTP(true);
                    return;
                }

                if (data?.statusCode === 400) {
                    setIncorrectOTP(true);
                    return;
                }

                console.log('data from verify otp', data?.data);
                setShowOtpBox(false);
                setotpVerfied(true);
                // debugger;
                const { authToken, instanceId, userId, isNewUser } = data?.data || {};

                setauthData({
                    authToken,
                    instanceId
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <div>
            {/* <div>{JSON.stringify(showToaster, null, 4)}</div> */}
            {showToaster && (
                <div className="w-full h-auto">
                    <ToastMessage showToaster={showToaster} msg={toasterMsg} />
                </div>
            )}{' '}
            <div>
                <PhoneNumber
                    // @ts-ignore
                    // disabled={name.length < 3}
                    preFilled={number}
                    setter={setNumber}
                    onNext={handlePhoneNumber}
                />

                <section>
                    <Transition
                        show={showOtpBox}
                        enter="transition-all duration-700 transform-gpu"
                        enterFrom="opacity-30  -translate-y-10 transform-gpu"
                        enterTo="opacity-100 translate-y-0 transform-gpu"
                        leave="transition-all duration-500 transform-gpu"
                        leaveFrom="opacity-100 translate-y-0 transform-gpu"
                        leaveTo="opacity-30 -translate-y-[2rem] transform-gpu"
                    >
                        <br />

                        <p className="font-roboto font-medium text-lg text-[#242f35]">Enter OTP</p>
                        <OTPInput
                            onNext={handleVerifyOTP}
                            setter={setInputOTP}
                            setIncorrectOTP={setIncorrectOTP}
                            incorrectOTP={incorrectOTP}
                            otpReset={otpReset}
                            // @ts-ignore
                            setOtpReset={setOtpReset}
                        />

                        {resend ? (
                            <div
                                className="otp-page-resend-otp text-center text-sm flex justify-start w-full"
                                onClick={() => handlePhoneNumber()}
                            >
                                Resend OTP
                            </div>
                        ) : (
                            <div
                                className="otp-page-resend-countdown text-center text-sm"
                                style={{ cursor: 'auto' }}
                            >
                                Resend in 00:
                                {countDown < 10 ? '0' + countDown : countDown}
                            </div>
                        )}
                    </Transition>

                    <div className="incorrect-otp-otp-page text-[#fb7882] text-sm">
                        {incorrectOTP && 'Incorrect OTP'}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default MobileInputBox;
