import { useNavigate } from 'react-router-dom';

export const TopHeader = ({ icon, content }: { icon: string; content: string }) => {
    const navigate = useNavigate();

    return (
        <div
            className="flex mx-[20px] gap-[20px] items-center py-[20px] h-[64px]"
            onClick={() => navigate(-1)}
        >
            <img src={icon} alt="arrow-left" />
            <h2 className="text-[#242F35] text-[21px] font-bold">{content}</h2>
        </div>
    );
};
