const PaymentInfo = (props: any) => {
    const { state } = props;
    const detail = state;

    return (
        <div className="mt-[24px] flex flex-col items-center">
            <h2 className="text-[#242F35] text-[18px] font-medium">You shall be paying</h2>
            <h2 className="mt-[30px] text-[#242F35] text-[21px] font-bold">Rs {detail?.amount}</h2>
            <p className="mx-[20px] mt-[24px] border border-[#367E8A] border-dotted w-full"></p>
        </div>
    );
};

export default PaymentInfo;
