import { useState, useEffect } from 'react';
import './AskQuestionExpert.css';
import { lazyWithRetry } from '../../../utils/LazyWithRetry';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useUserData } from '../../../utils/hooks/useUserData';
import CrossIcon from '../../../assets/icons/CrossIcon.svg';
import { DefaultUser, SendEvents } from '../../../utils/utils';
const Header = lazyWithRetry(() => import('../../Header/Header'));

const ButtonSecondry1 = lazyWithRetry(() => import('../../V2Components/ButtonSecondry/index1'));
const ButtonPrimary = lazyWithRetry(() => import('../../V2Components/ButtonPrimary'));
const BreadCrumbs = lazyWithRetry(() => import('../../V2Components/BreadCrumbs'));
const REACT_APP_ASKEXPERTS_SERVER = process.env.REACT_APP_ASKEXPERTS_SERVER;
const AskQuestion = ({
    data,
    setter,
    setActive,
    req,
    setAnonymous,
    setParentQuestionId,
    setNav
}: any) => {
    const { userData: user_data } = useUserData();
    const [isChecked, setisChecked] = useState(false);
    const { t } = useTranslation();
    req.title.length > 19 ? setActive(true) : setActive(false);
    const [recommendedQs, setRecommendedQs] = useState<any>([]);
    const [showMoreSuggestions, setShowMoreSuggestions] = useState(false);
    // useEffect(() => {
    //     const question = sessionStorage.getItem('question');
    //     const parentId = sessionStorage.getItem('parent-question-id');
    //     if (parentId) {
    //         setParentQuestionId(parentId);
    //         sessionStorage.removeItem('parent-question-id');
    //         if (question) {
    //             setter(question);
    //             const isAnonymous = sessionStorage.getItem('question-privacy');
    //             if (isAnonymous) {
    //                 setAnonymous(isAnonymous == '1' ? true : false);
    //                 setNav(2);
    //                 sessionStorage.removeItem('question-privacy');
    //             }
    //             sessionStorage.removeItem('question');
    //         }
    //     }
    // });
    const AskTopData: any = sessionStorage.getItem('pt_user_data');

    const question_number = JSON.parse(AskTopData);

    // const fetchQuestionRecommndations = async () => {};

    const getQuestionRecommndations = async () => {
        const res = await axios.get(REACT_APP_ASKEXPERTS_SERVER + '/question/recommended', {
            headers: {
                instanceId: user_data?.instanceId?.toString() || '',
                authToken: user_data?.authToken || ''
            }
        });
        if (res.data.statusCode !== 200) return;
        setRecommendedQs(res.data.data);
    };

    useEffect(() => {
        getQuestionRecommndations();
    }, []);

    const overlay: any = {
        height: '100%',
        width: '100%',
        // position: 'fixed',
        StayInPlaceZIndex: '1',
        SitOnTopLeft: '0',
        top: '0',
        // backgroundColor: 'rgb(0,0,0, .5)',
        // BlackFallbackColorBackgroundColor: 'rgba(0,0,0, 0.9)',
        BlackWOpacityOverflowX: 'hidden',
        DisableHorizontalScrollTransition: '0.5s',
        zIndex: '55'
    };

    const overlayContent: any = {
        position: 'relative',
        top: '20%',
        width: '100%',
        textAlign: 'center',
        marginTop: '30px',
        color: 'white'
    };

    const MoreQsnPopUp = () => {
        return (
            <div
                style={overlay}
                className="fixed md:static bg-black bg-opacity-50 md:bg-none md:bg-opacity-0"
                onClick={() => {
                    setShowMoreSuggestions(false);
                }}
            >
                <div style={overlayContent}>
                    <div
                        className="flex justify-end w-full 
                "
                    >
                        <img src={CrossIcon} className="h-10 w-10 mx-5 my-2" />
                    </div>

                    <div
                        className=" sm:h-[calc(100vh-60px)] md:h-auto bg-white
              w-full rounded-t-[20px]  "
                    >
                        <div
                            className="p-5 font-roboto text-base font-bold bg-gradient-to-r from-[#FFEED9] to-[#FFE0E3] rounded-t-[20px] 
                 text-[#323232] text-left"
                        >
                            Suggestions based on your search
                        </div>
                        <div className="flex flex-wrap space-4 mr-4  mt-5 mx-2 ">
                            {recommendedQs.map((item: any, index: number) => {
                                return (
                                    <button
                                        key={index}
                                        className={`text-[#606A7B] text-left border-[1.5px] border-[#CED2D9] px-4 py-[0.365rem] rounded-[1.563rem]  font-roboto font-normal text-sm mx-2 my-1 focus:bg-[#367E8A] focus:text-white ${
                                            index === recommendedQs.length - 1
                                                ? 'mb-20 md:mb-16'
                                                : null
                                        }
                                    }`}
                                        onClick={() => {
                                            setter(item);
                                            setShowMoreSuggestions(!showMoreSuggestions);
                                        }}
                                    >
                                        {item}
                                    </button>
                                );
                            })}
                            {recommendedQs.length === 0 && (
                                <button className="text-[#606A7B] border-[1.5px] border-[#CED2D9] px-4 py-[0.365rem] rounded-[1.563rem]  font-roboto font-normal text-sm mx-2 my-1 focus:bg-[#367E8A] focus:text-white ">
                                    No questions avilable
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`bg-[#FBFCFD] ${showMoreSuggestions ? `sm:bg-white` : ''}`}>
            {showMoreSuggestions && <MoreQsnPopUp />}
            {!showMoreSuggestions && (
                <div className="aqeas ">
                    <div className="aqeas1">
                        <img
                            src={data.avatar}
                            alt=""
                            className="!h-[25px] !w-[25px] rounded-full border object-cover"
                            loading="lazy"
                        />
                        <div className="text-[16px] mt-1 ml-2 capitalize">
                            {data.parentName ?? data.name}
                        </div>
                    </div>
                    <textarea
                        onClick={() =>
                            SendEvents(user_data?.id ?? DefaultUser, 'btn-click', `btn_typing_talk`)
                        }
                        style={{
                            height: `${window.innerHeight / 4}px`
                        }}
                        autoFocus={true}
                        className={`w-full px-2 py-1  outline-0 !outline-none`}
                        value={req.title}
                        onChange={(e) => setter(e.target.value)}
                        placeholder={t('Talk to like minded parents. Type your concern here')}
                    ></textarea>
                    {/* suggestion card */}
                    {recommendedQs && recommendedQs.length > 0 && (
                        <section className=" !bg-white  rounded-lg border-[#EEEEEE] border-[0.5px] shadow mb-10">
                            {recommendedQs.length !== 0 && (
                                <button
                                    className="w-full flex justify-end"
                                    onClick={() => {
                                        //
                                        setShowMoreSuggestions(!showMoreSuggestions);
                                    }}
                                >
                                    <p className="m-4  text-[#367E8A] font-roboto font-bold text-xs underline">
                                        Suggest More
                                    </p>
                                </button>
                            )}
                            <div className="flex flex-wrap space-4 mr-4 my-4">
                                {recommendedQs.slice(0, 3).map((item: any, index: number) => {
                                    return (
                                        <button
                                            key={index}
                                            className="text-[#606A7B] text-left border-[1.5px] border-[#CED2D9] px-4 py-[0.365rem] rounded-[1.563rem]  font-roboto font-normal text-sm mx-2 my-1 focus:bg-[#367E8A] focus:text-white"
                                            onClick={() => {
                                                setter(item);
                                            }}
                                        >
                                            {item}
                                        </button>
                                    );
                                })}
                            </div>
                        </section>
                    )}
                    <hr style={{ border: '0.500415px dashed #367E8A' }}></hr>
                    <div className="aqeas3">
                        <input
                            checked={isChecked}
                            onChange={() =>
                                setisChecked((s) => {
                                    setAnonymous(!s);
                                    return !s;
                                })
                            }
                            type="checkbox"
                            name=""
                            id="anonymus"
                        />
                        <label className="mt-2" htmlFor="anonymus">
                            {t('ask')} {t('anonymusly')}
                        </label>
                    </div>
                    {/* <div className="mt-[30px] text-[#787878]  pr-[20px]">
                <p className=" ">
                    {question_number?.remainingQuestions}/{' '}
                    {t(
                        'free_questions_remaining_for_the_month_To_get_unlimited_questions_benefits'
                    )}
                    . Join
                    <span className="text-[#242F35] font-[500]"> Parentune PLUS</span> today
                </p>
            </div> */}
                </div>
            )}
        </div>
    );
};

export default AskQuestion;
