import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoadBlockState {
    data: {
        live: number;
        parentTalks: number;
        blogs: number;
        babyName: number;
    };
}
// const roadBlockLocalData = sessionStorage.getItem('roadBlock');

const initialState: RoadBlockState = {
    data:
        // roadBlockLocalData
        // ? JSON.parse(roadBlockLocalData)
        // :
        {
            live: 0,
            parentTalks: 0,
            blogs: 0,
            babyName: 0
        }
};

export const RoadBlockSlice = createSlice({
    name: 'roadBlock',
    initialState,
    reducers: {
        addLive: (state, action: PayloadAction<number>) => {
            state.data.live += action.payload;
            // sessionStorage.setItem('roadBlock', JSON.stringify(state.data));
        },
        addParentTalks: (state, action: PayloadAction<number>) => {
            state.data.parentTalks += action.payload;
            // sessionStorage.setItem('roadBlock', JSON.stringify(state.data));
        },
        addBlogs: (state, action: PayloadAction<number>) => {
            state.data.blogs += action.payload;
            // sessionStorage.setItem('roadBlock', JSON.stringify(state.data));
        },
        addBabyName: (state, action: PayloadAction<number>) => {
            state.data.babyName += action.payload;
            // sessionStorage.setItem('roadBlock', JSON.stringify(state.data));
        }
    }
});
export const { addLive, addParentTalks, addBlogs, addBabyName } = RoadBlockSlice.actions;
export default RoadBlockSlice.reducer;
