import React from 'react';
import '../Workshop/LiveChat.css';
import Overlay from '../../components/Overlay/Overlay';
import { ConsentData } from '../../api/Consent';
import { useUserData } from '../../utils/hooks/useUserData';
// import { Content } from './T&C';
const Content: any = {
    // position: 'relative',
    // top: '20%',
    width: '100%',
    // marginTop: '30px',

    color: 'white'
};
const TermAndConditions = (props: any) => {
    const { userData: user_data } = useUserData();
    // const handleModalClose = (e: any) => {
    //     props.setConsentGiven(true);
    // };
    return (
        <div>
            <Overlay Content={Content}>
                <div className=" pt-3  text-sm rounded relative font-roboto pr-1 pl-2 overflow-auto text-black  max-w-[400px] mx-auto   bg-white">
                    <div className="h-full max-h-[90vh]  relative">
                        <div className="mb-3  font-medium">
                            We have updated our{' '}
                            <a
                                className="text-blue-400"
                                href="https://www.parentune.com/terms-conditions"
                            >
                                Terms and conditions
                            </a>
                        </div>
                        <p className="mb-3 font-medium">
                            To understand all the changes please read the updates in full, but there
                            are some of the key changes we&apos;have made
                        </p>
                        <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                            <li>
                                Information Collected by the Website: Means of data collection on
                                Parentune are - Contact Forms (The contact forms on the website are
                                made to simplify the process of data presentation by collecting the
                                user interests, child’s DOB ,mobile_number , etc), Brand Offers,
                                Special offers, sampling, emailers, Login and Signup. By creating a
                                user account, visitors agree to the terms and conditions of the
                                website. The data collected may consist of personal or non-personal
                                information. We may store, collect, use and process your Information
                                subject to compliance under applicable laws of India. The website
                                collects user information to provide suggestions tailored as per the
                                parent’s/child’s profile and keep their feed personalized in line
                                with this information .
                            </li>{' '}
                            <li>
                                Opt-in Mails and Other ways of data usage by the Company:The company
                                can use data collected through the website in various ways such as-
                                a) Opt-in Mails- Company sends customized mails on the users opting
                                for newsletters or notification. Users can anytime unsubscribe from
                                the service b) Feedbacks: The Company may contact its visitors or
                                users for feedback on any new feature or services. If not
                                interested, users can drop a mail on helpdesk une.com c)
                                Geographical Data: we use geographical or demographical data to
                                provide location wise customized data. D) Cookies and IP data are
                                used to provide most relevant results through search. The website
                                also stores data other than the content which provides information
                                on personal identity in the form of IP and cookies. This data is
                                used to personalize the content to each parent’s profile as per
                                their child’s age, the parents’ concerns/interests and so on. It is
                                henceforth considered under the category of non
                                personal-identifiable data which also includes search history,
                                queries submitted, interests, date and time, domain, advertisement
                                response. We can use the data collected through our channel in the
                                analysis of trends and it can also be forwarded to the brands
                                depending upon the usage history. We share your personal information
                                with Brands looking to get in touch with you for promotional and/or
                                sampling purposes based on your browsing behavior or expression of
                                interest regarding promotions, workshops, Expert advice, courses,
                                sampling,special offers, who may further contact you and process
                                your information for the said purposes
                            </li>
                        </ul>
                        <div className="sticky bg-white bottom-0 py-3">
                            <button
                                onClick={() => {
                                    ConsentData(user_data);
                                    props.setConsentGiven(true);
                                    const _data = {
                                        Consent: true
                                    };
                                    // sessionStorage.setItem('consent', JSON.stringify({ _data }));
                                    sessionStorage.removeItem('consent');
                                    localStorage.removeItem('consent');
                                }}
                                className="primary-cta-lg !py-[0.6rem] w-full"
                            >
                                Agree and continue
                            </button>

                            <button
                                onClick={() => {
                                    props.setConsentGiven(true);
                                    const _data = {
                                        Consent: true
                                    };
                                    sessionStorage.setItem('consent', JSON.stringify({ _data }));
                                }}
                                className="bg-slate-200 w-full mt-2 text-black px-[2rem] py-[0.6rem] rounded-[1.563rem] max-h-[2.813rem] font-roboto font-medium text-sm leading-4"
                            >
                                Remind me later
                            </button>
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    );
};

export default TermAndConditions;
