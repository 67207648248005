import arrowrighticon from '../../assets/giftpage/arrow-right.svg';

const Input = ({
    id,
    label,
    placeholder,
    name,
    onChange,
    error,
    value
}: {
    id: number;
    label: string;
    placeholder: string;
    name: string;
    value: string;
    onChange?: (e: any) => void;
    error?: string;
}) => {
    if (id === 2) {
        return (
            <>
                <div className="flex items-center mt-[21px] border border-[#C4C4C4] rounded p-[3px] h-[50px]">
                    <h3 className="pr-[12px]">{label}</h3>
                    <div className="bg-[#E5E5E5] w-[1px] h-[20px]"></div>
                    <input
                        type="number"
                        className="flex-1 w-full"
                        name={name}
                        value={value.length > 10 ? value.substring(2, 12) : value.substring(0, 10)}
                        onChange={onChange}
                    />
                    {/* <div className="w-[44px] h-[44px] rounded-[2px] flex justify-center items-center pl-1 bg-[#C4C4C4]">
                        <img src={arrowrighticon} alt="arrowBtn-icon" />
                    </div> */}
                </div>
                {error !== '' && <span className="text-[12px] text-red-600">{error}</span>}
            </>
        );
    }

    if (id === 3) {
        return (
            <>
                <div className="mt-[23px]">
                    <h3>{label}</h3>
                    <textarea
                        name={name}
                        className="outline-none  resize-none mt-[18px] w-full h-[95px] border border-[#C4C4C4] rounded p-[16px] "
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder}
                    />
                </div>
                {error !== '' && <span className="text-[12px] text-red-600">{error}</span>}
            </>
        );
    }

    return (
        <div className="mt-[20px]">
            <h3>{label}</h3>
            <input
                name={name}
                type="text"
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                className="w-full h-[58px] border border-[#C4C4C4] p-[16px] mt-[18px] rounded"
            />
            {error !== '' && <span className="text-[12px] text-red-600">{error}</span>}
        </div>
    );
};

export default Input;
