import axios from 'axios';
import { useQuery } from 'react-query';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();
export const breastFeedingEvents = () => {
    return useQuery(
        ['workShop'],
        () => {
            return axios
                .get(process.env.REACT_APP_LIVEEVENTS_SERVER + 'breastfeeding/list', {
                    headers: {
                        lang: SelectedLanguage
                    }
                })
                .then((res) => {
                    console.log(res);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        {
            staleTime: 10 * 60 * 60 * 1000
        }
    );
};
export const breastFeedingBlogs = () => {
    return useQuery(
        ['blogs'],
        () => {
            return axios
                .get(process.env.REACT_APP_BLOGS_SERVER + 'breastfeeding/list', {
                    headers: {
                        lang: SelectedLanguage
                    }
                })
                .then((res) => {
                    console.log(res);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        {
            staleTime: 10 * 60 * 60 * 1000
        }
    );
};

export const breastFeedingContents = (SelectedLanguage: any) => {
    return useQuery(
        ['contents'],
        () => {
            return axios
                .get(process.env.REACT_APP_CONTENT_SERVER + 'content/breastfeeding', {
                    headers: {
                        lang: SelectedLanguage
                    }
                })
                .then((res) => {
                    console.log(res);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        {
            staleTime: 10 * 60 * 60 * 1000
        }
    );
};
