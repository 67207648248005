import React from 'react';

function NavigateNextIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="16"
            fill="none"
            viewBox="0 0 10 16"
            {...props}
        >
            <path
                fill="#fff"
                d="M8.303 6.929l.882.88a.397.397 0 01.009.562l-7.39 7.517a.396.396 0 01-.56-.008L.162 14.797a.397.397 0 01-.008-.56L6.205 8.08.168 1.806a.392.392 0 01.009-.555l1.07-1.07a.392.392 0 01.555-.009L8.303 6.93z"
            ></path>
        </svg>
    );
}

export default NavigateNextIcon;
