import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import { useUserData } from '../../../utils/hooks/useUserData';
import FormProgress from '../../FormProgress/FormProgress';
import EditProfileParentSelect from '../../Profile/EditProfileParentSelect/EditProfileParentSelect';
import { GetUserProfile } from '../../../api/OnBoarding/GetUserProfile';
import { UpdateProfile } from '../../../api/OnBoarding/UpdateProfile';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import { useOnboardingData } from '../../../utils/hooks/useOnboardingData';
import { handleSteps } from '../../../store/slices/onboardingSlice';
import { useNavigate } from 'react-router-dom';
import pushEventToGtm from '../../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';

const IdentifyYourSelf = (props: any) => {
    const { nav, setNav, selectedData, setSelectedData, isLoading, setIsLoading } = props;
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const dispatch = useAppDispatch();
    const { userData: user_data } = useUserData();
    const { onboardingSteps, previousStep } = useOnboardingData();
    const navigate = useNavigate();
    const PlanningForPregnancy = props.isPlanningForPregnancy ?? false;
    // @ts-ignore
    const onboardingDataLocal: any = JSON.parse(localStorage.getItem('onboarding_data'));

    const isForHaveaChild = onboardingSteps[1] === 'HaveChild' ? true : false;
    const isForKeenToAdopt = onboardingSteps[1] === 'KeenToAdopt' ? true : false;

    useEffect(() => {
        window.history.pushState(null, '', '/register');
        // navigate(window.location.pathname);

        // Listen for popstate event to prevent back navigation
        const handlePopState = () => {
            //   navigate(window.location.pathname);
            window.history.pushState(null, '', '/register');
            setNav(onboardingSteps[onboardingSteps.length - 1]); // Keep the user on the same page
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    const handleSubmitIdentifyYourSelf = async () => {
        if (isForHaveaChild || localStorage.getItem('HaveChild_step') === '1') {
            console.log('/');
            const data = { ...selectedData?.response, childs: [...onboardingDataLocal] };
            console.log('data', data);
            setIsLoading(true);

            const onSucess = function onSucessUpdateProfile(d: any) {
                console.log('date', d);
                if (d.statusCode !== 200) return;
                console.log('done');
                GetUserProfile(
                    (s: any) => {
                        console.log('sucess', s);
                    },
                    (e: any) => {
                        console.log('error', e);
                    },
                    user_data
                );
                setIsLoading(false);
                setNav('ChooseAvatar');
            };

            const onFailure = function onFailureUpdateProfile(e: any) {
                console.log('Error', e);
                setIsLoading(false);
            };

            UpdateProfile(onSucess, onFailure, user_data, 5, data);

            return;
        } else if (isForKeenToAdopt) {
            const maxAdoptionYear = onboardingDataLocal?.selectedAgeGroup.split('-')[1];
            const minAdoptionYear = onboardingDataLocal?.selectedAgeGroup.split('-')[0];
            let data = { ...selectedData?.response, minAdoptionYear, maxAdoptionYear };
            console.log('data', data);

            const childs = onboardingDataLocal?.data;

            if (childs) {
                data = { ...data, childs };
            }

            setIsLoading(true);

            const onSucess = function onSucessUpdateProfile(d: any) {
                // console.log('date', d);
                if (d.statusCode !== 200) return;
                // console.log('done');
                GetUserProfile(
                    (s: any) => {
                        console.log('sucess', s);
                    },
                    (e: any) => {
                        console.log('error', e);
                    },
                    user_data
                );
                setIsLoading(false);
                setNav('ChooseAvatar');
            };

            const onFailure = function onFailureUpdateProfile(e: any) {
                console.log('Error', e);
                setIsLoading(false);
            };

            UpdateProfile(onSucess, onFailure, user_data, 8, data);

            return;
        }
        let step;
        step = PlanningForPregnancy ? 10 : 4;

        let data = { ...selectedData?.response, dueDate: onboardingDataLocal?.dueDate };

        const childs = onboardingDataLocal?.data;

        if (childs) {
            step = 13;
            data = { ...data, childs };
        }

        console.log('data', data);
        setIsLoading(true);

        function onSucessUpdateProfile(d: any) {
            console.log('date', d);
            if (d.statusCode !== 200) return;
            console.log('done');
            GetUserProfile(
                (s: any) => {
                    console.log('sucess', s);
                },
                (e: any) => {
                    console.log('error', e);
                },
                user_data
            );
            setIsLoading(false);
            setNav('ChooseAvatar');
        }

        function onFailureUpdateProfile(e: any) {
            console.log('Error', e);
            setIsLoading(false);
        }

        UpdateProfile(onSucessUpdateProfile, onFailureUpdateProfile, user_data, step, data);
        pushEventToGtm('planning-pregnancy-user-onboard', {
            'planning-pregnancy': 'planning-pregnancy-next',
            url: window.location.pathname,
            newGuestUid
        });
    };

    // const handleBack = () => {
    //     console.log('handleBack called');
    //     let steps: any = localStorage.getItem('onboarding_step');
    //     steps = JSON.parse(steps);
    //     console.log('steps', steps);
    //     if (steps.length === 1) setNav('SetupProfile');
    //     setNav(steps[steps.length - 2]);
    // };

    const handleBack = () => {
        dispatch(handleSteps());
        if (!onboardingSteps) return;
        setNav(onboardingSteps && onboardingSteps.at(-2));
    };

    useEffect(() => {
        // localStorage.removeItem('onboarding_data');
        window?.scrollTo({ top: 0, behavior: 'smooth' });
        localStorage.getItem('onboarding_nav') === 'HaveChild'
            ? localStorage.setItem('HaveChild_step', '1')
            : null;
    }, []);

    return (
        <main>
            {/* {JSON.stringify(onboardingSteps)} onboardingSteps */}
            <div className="animate-fade-in-left h-[calc(100vh_-_10vh)] max-w-4xl mx-auto pt-3 flex flex-col px-4">
                <FormProgress onBack={handleBack} dots={4} progress={3} />
                <h1 className="font-proxima font-bold text-xl text-[#242F35] mb-8">
                    How do you identify yourself
                </h1>
                <form className="my-2 flex flex-col gap-2">
                    <EditProfileParentSelect
                        setSelectedData={setSelectedData}
                        nav={nav}
                        isForHaveaChild={isForHaveaChild}
                        isForKeenToAdopt={isForKeenToAdopt}
                    />
                </form>
                {/* {JSON.stringify(selectedData?.response)} */}

                <div className="flex-1 flex flex-col justify-end pb-3">
                    <ButtonPrimary
                        size="large"
                        isLoading={isLoading}
                        text="Next"
                        onClick={() => handleSubmitIdentifyYourSelf()}
                        disabled={!selectedData}
                    />
                </div>
            </div>
        </main>
    );
};

export default IdentifyYourSelf;
