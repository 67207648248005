import { t } from 'i18next';

const Content = {
    SEO: {
        BABY_NAMES: {
            MAIN_HEADING: {
                HOME: 'African Baby Names for New Born',
                PATTERN1: 'African Baby Names Starting With {char}',
                RELIGION: {
                    MUSLIM: {
                        HOME: t('page_title_origin'),
                        PATTERN1: t('page_title_origin_alphabet')
                    },
                    CHRISTIAN: {
                        HOME: t('page_title_origin'),
                        PATTERN1: t('page_title_origin_alphabet')
                    }
                },

                BOY: {
                    HOME: t('page_title_gender'),
                    PATTERN1: t('page_title_gender_alphabet'),
                    RELIGION: {
                        MUSLIM: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        CHRISTIAN: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        }
                    }
                },
                GIRL: {
                    HOME: t('page_title_gender'),
                    PATTERN1: t('page_title_gender_alphabet'),
                    RELIGION: {
                        MUSLIM: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        CHRISTIAN: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        }
                    }
                }
            }
        }
    }
};

export default Content;
