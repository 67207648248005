import { t } from 'i18next';
import { toCapitalize } from '../../utils/utils';
import { Constants } from '../Constants/Constants';
import Content from './Content';
import './BabyNameViewAll.module.css';
import { countryMapped3 } from '../../pages/BabyName/utils';
export const renderDynamicTitile = (
    char2: string,
    religion: string,
    gender: string,
    country: any
) => {
    // @ts-ignore
    let h1Tag = `${
        // @ts-ignore
        countryMapped3[country === 'South Africa' ? 'south-africa' : country?.toLowerCase()]
    } Baby Names for New Born`;
    let p1Tag =
        'We offer you modern, yet traditional 50,000+ trending baby names with their meanings that will help you pick the best option of all while deciding on the perfect name that will give your child a distinct identity . These names have been compiled after a lot of research to make it a unique experience for you . An alphabetic listing of names along with their meanings has been made which will not only make your search easier but simpler too.';
    console.log('char2', char2, gender, religion);

    if (char2 && religion && gender) {
        if (gender === Constants.GENDER.BOY) {
            if (religion === Constants.RELIGION.MUSLIM) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.BOY.RELIGION.MUSLIM.PATTERN1.replace(
                    '{char}',
                    char2?.toLocaleUpperCase()
                )
                    .replace('{gender}', toCapitalize(t(gender)))
                    .replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('muslim_baby_boy_name');
            } else if (religion === Constants.RELIGION.CRISTION) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.BOY.RELIGION.CHRISTIAN.PATTERN1.replace(
                    '{char}',
                    char2?.toLocaleUpperCase()
                )
                    .replace('{gender}', toCapitalize(t(gender)))
                    .replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('christian_baby_boy_name');
            }
        } else if (gender === Constants.GENDER.GIRL) {
            if (religion === Constants.RELIGION.MUSLIM) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.GIRL.RELIGION.MUSLIM.PATTERN1.replace(
                    '{char}',
                    char2?.toLocaleUpperCase()
                )
                    .replace('{gender}', toCapitalize(t(gender)))
                    .replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('muslim_baby_girl_name');
            } else if (religion === Constants.RELIGION.CRISTION) {
                h1Tag =
                    Content.SEO.BABY_NAMES.MAIN_HEADING.GIRL.RELIGION.CHRISTIAN.PATTERN1.replace(
                        '{char}',
                        char2?.toLocaleUpperCase()
                    )
                        .replace('{gender}', toCapitalize(t(gender)))
                        .replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('christian_baby_girl_name');
            }
        }
    } else if (char2 && religion && !gender) {
        if (religion === Constants.RELIGION.MUSLIM) {
            h1Tag = t('page_title_origin_alphabet')
                .replace('{char}', char2?.toLocaleUpperCase())
                .replace('{religion}', toCapitalize(t(religion)));
            p1Tag = t('muslim_baby_name');
        } else if (religion === Constants.RELIGION.CRISTION) {
            h1Tag = t('page_title_origin_alphabet')
                .replace('{char}', char2?.toLocaleUpperCase())
                .replace('{religion}', toCapitalize(t(religion)));
            p1Tag = t('christian_baby_name');
        }
    } else if (char2 && gender && !religion) {
        if (gender === Constants.GENDER.BOY) {
            h1Tag = t('page_title_gender_alphabet')
                .replace('{char}', char2?.toLocaleUpperCase())
                .replace('{gender}', toCapitalize(t(gender)));
            p1Tag = t('baby_boy_name');
        } else if (gender === Constants.GENDER.GIRL) {
            h1Tag = t('page_title_gender_alphabet')
                .replace('{char}', char2?.toLocaleUpperCase())
                .replace('{gender}', toCapitalize(t(gender)));
            p1Tag = t('baby_girl_name');
        }
    } else if (gender && religion && !char2) {
        if (gender === Constants.GENDER.BOY) {
            if (religion === Constants.RELIGION.MUSLIM) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.BOY.RELIGION.MUSLIM.HOME.replace(
                    '{gender}',
                    toCapitalize(t(gender))
                ).replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('muslim_baby_boy_name');
            } else if (religion === Constants.RELIGION.CRISTION) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.BOY.RELIGION.CHRISTIAN.HOME.replace(
                    '{gender}',
                    toCapitalize(t(gender))
                ).replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('christian_baby_boy_name');
            }
        } else if (gender === Constants.GENDER.GIRL) {
            if (religion === Constants.RELIGION.MUSLIM) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.GIRL.RELIGION.MUSLIM.HOME.replace(
                    '{gender}',
                    toCapitalize(t(gender))
                ).replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('muslim_baby_girl_name');
            } else if (religion === Constants.RELIGION.CRISTION) {
                h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.GIRL.RELIGION.CHRISTIAN.HOME.replace(
                    '{gender}',
                    toCapitalize(t(gender))
                ).replace('{religion}', toCapitalize(t(religion)));
                p1Tag = t('christian_baby_girl_name');
            }
        }
    } else if (gender && !religion && !char2) {
        if (gender === Constants.GENDER.BOY) {
            h1Tag = t('page_title_gender').replace('{gender}', toCapitalize(t(gender)));
            p1Tag = t('baby_boy_name');
        } else if (gender === Constants.GENDER.GIRL) {
            h1Tag = t('page_title_gender').replace('{gender}', toCapitalize(t(gender)));
            p1Tag = t('baby_girl_name');
        }
    } else if (religion && !gender && !char2) {
        if (religion === Constants.RELIGION.MUSLIM) {
            h1Tag = t('page_title_origin').replace('{religion}', toCapitalize(t(religion)));
            p1Tag = t('muslim_baby_name');
        } else if (religion === Constants.RELIGION.CRISTION) {
            h1Tag = t('page_title_origin').replace('{religion}', toCapitalize(t(religion)));
            p1Tag = t('christian_baby_name');
        }
    } else if (char2 && !gender && !religion) {
        h1Tag = Content.SEO.BABY_NAMES.MAIN_HEADING.PATTERN1?.replace(
            '{char}',
            char2?.toLocaleUpperCase()
        );
        p1Tag = t('baby_names_all_landing_content');
    }

    return (
        <>
            <h1 className="text-xl font-medium find-name-sub-heading">{h1Tag}</h1>
            <p
                className="a-tag text-base mt-1 font-normal find-name-para"
                dangerouslySetInnerHTML={{ __html: `${p1Tag}` }}
            ></p>
        </>
    );
};
