import axios from 'axios';

export function UnsubscribeAPI(
    userId: any,
    data: any,
    onSuccess: (arg0: any) => void | any,
    onFailure: (arg0: any) => void | any
) {
    let config: any;

    config = {
        method: 'post',
        url: process.env.REACT_APP_CONTENT_SERVER + 'content/unsubscribeEmail',
        data: {
            userId: userId,
            unsubscribeComment: data.feedback
        }
    };

    if (userId) {
        axios(config)
            .then((res) => {
                onSuccess(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return;
    }
}
