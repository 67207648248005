import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './DOBInput.css';
// import PtCalender from '../../components/Pt-calender/index';

const DOBInput = (props: any) => {
    const { age, setAge, isForDueDate } = props;
    const [Datevalue, onChangeDate] = useState<Value>(null);
    const [showCalender, setShowCalender] = useState(false);
    const isForChild = props.isForChild ?? false;
    const isForEditProfile = props?.isForEditProfile ?? false;
    const isForImmunifyMeForm = props.isForImmunifyMeForm ?? false;

    const [displayDate, setDisplayDate] = useState({
        DD: 'DD',
        MM: 'MM',
        YYYY: 'YYYY'
    });

    //handle Age change input field
    const handleAge = (e: any) => {
        const indianTimeZoneOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
        const dateInIndianTimeZone = new Date(e.getTime() + indianTimeZoneOffset);

        const year = dateInIndianTimeZone.getFullYear();
        const month = String(dateInIndianTimeZone.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(dateInIndianTimeZone.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setDisplayDate((prev: any) => ({ ...prev, DD: day, MM: month, YYYY: year }));

        setAge(formattedDate);
    };

    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(currentDate.getMonth() + 9);
    // console.log('max', maxDate);

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const getMinDate = () => {
        const currentDate = new Date();
        if (isForImmunifyMeForm) return undefined;
        return isForChild
            ? new Date(currentDate.setFullYear(currentDate.getFullYear() - 16))
            : currentDate;
    };

    useEffect(() => {
        if (!age) return;
        onChangeDate(age ? new Date(age) : null);
        handleAge(new Date(age));
    }, []);

    return (
        <>
            {!isForChild && (
                <p className="font-normal text-sm font-roboto text-[#242F35] mb-4">
                    {/* {isForEditProfile ? 'Due Date ' : ' What’s your due date of delivery?'} */}
                    {/* {isForEditProfile
                        ? 'Due Date'
                        : props?.isForImmunifyMeForm
                        ? 'Date of Birth'
                        : ' What’s your due date of delivery?'} */}
                    {props?.title ?? ' What’s your due date of delivery?'}
                </p>
            )}
            {showCalender && (
                <DatePicker
                    isOpen={true}
                    calendarIcon={null}
                    dayPlaceholder={'DD'}
                    yearPlaceholder="YYYY"
                    monthPlaceholder="MM"
                    format="dd-MM-yy"
                    className={'DOBInput'}
                    nativeInputAriaLabel="Date"
                    minDate={getMinDate()}
                    maxDate={isForChild || isForImmunifyMeForm ? yesterday : maxDate}
                    onChange={(value: any) => {
                        onChangeDate(value);
                        handleAge(value);
                        sessionStorage.setItem('button-first-time', 'false');
                    }}
                    value={Datevalue}
                    onCalendarClose={() => setShowCalender(false)}
                    defaultActiveStartDate={
                        Datevalue
                            ? undefined
                            : isForChild
                            ? yesterday
                            : age
                            ? new Date(age)
                            : currentDate
                    }
                />
            )}
            {/* {JSON.stringify(showCalender)} showCalender */}
            {/* <PtCalender
                date={Datevalue}
                setDate={onChangeDate}
                onCalendarClose={() => setShowCalender(false)}
            /> */}
            {/* {showCalender && (
                <PtCalender
                    date={Datevalue}
                    setDate={onChangeDate}
                    onCalendarClose={() => setShowCalender(false)}
                />
            )} */}
            {/* {JSON.stringify(Datevalue)} */}
            <section className="flex justify-start items-center space-x-7 ">
                <div
                    onClick={() => {
                        setShowCalender((prev: boolean) => !prev);
                    }}
                    className="flex justify-center items-center content-center min-h-[53px] min-w-[53px] border border-[#C4C4C4] rounded-md "
                >
                    {displayDate.DD}
                </div>
                <div
                    onClick={() => {
                        setShowCalender((prev: boolean) => !prev);
                    }}
                    className="flex justify-center items-center content-center min-h-[53px] min-w-[53px]  border border-[#C4C4C4] rounded-md"
                >
                    {displayDate.MM}
                </div>
                <div
                    onClick={() => {
                        setShowCalender((prev: boolean) => !prev);
                    }}
                    className="flex justify-center items-center content-center min-h-[53px] min-w-[53px] w-auto border border-[#C4C4C4] rounded-md px-4 py-[14px]"
                >
                    {displayDate.YYYY}
                </div>
            </section>
        </>
    );
};

export default React.memo(DOBInput);
