import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { Constants } from '../../components/Constants/Constants';
import { BabyNameBottom1Skelatal } from '../../components/Loader/Others/Skeletal';
import DoesURLContainMuslim, { DoesURLContainChristian } from '../../utils/DoesURLcontainsMuslim';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
import { useUserData } from '../../utils/hooks/useUserData';
import { capitalize } from '../../utils/utils';
import styles from './BabyNameBottome1.module.css';
import LoadingLayout from '../../components/V2Components/LoadingLayout';

import CopyLinkIcon from '../../assets/icons/CopyLinkIcon';
import FacebookShareIcon from '../../assets/icons/FacebookShareIcon';
import WhatsappShareIcon from '../../assets/icons/WhatsappShareIcon';
const LoginPopup = lazyWithRetry(() => import('../../components/LoginPopup/LoginPopup')); //not part page 0
// const NativeAdComponentWrapper = lazyWithRetry(
//     () => import('../../components/adComponent/NativeAdComponentWrapper')
// );
import Gutter from '../../components/Gutter/Gutter';
import BoyIcon from '../../components/Icons/BoyIcon';
import GirlIcon from '../../components/Icons/GirlIcon';
import ImageCarouselForBabyNames from '../../components/ImageCarousal/ImageCarouselForBabyNames'; //part of page 0
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import { useCookies } from 'react-cookie';
import PurposeCard from '../../components/PurposeCard/PurposeCard';
import TextView from '../../components/Button/TextView/TextView';
const ProductServicesCarousel = lazyWithRetry(
    () => import('../../components/ImageCarousal/ProductServicesCarousel')
);
const AdComponent = lazyWithRetry(() => import('../../components/adComponent/AdComponent'));
const LiveChatAdComponent = lazyWithRetry(
    () => import('../../components/adComponent/LiveChatAdComponent')
);

const DfpCompnent = lazyWithRetry(() => import('../../components/adComponent/DfpCompnent'));
const SelectedLanguage = Geti18nLang();

interface BabyNameBottom1Props {
    gender: string;
    religion: string;
    Name: string;
    startLength: number;
    endLength: number;
    fixedLength: number;
    size: number;
    lang?: any;
    page?: number;
    setPage?: any;
    setShare?: React.Dispatch<React.SetStateAction<boolean>>;
    setCol?: any;
    setlen?: React.Dispatch<React.SetStateAction<number>>;
    setBtnCol?: any;
    share?: any;
    url?: string;
    setBorder?: any;
    keyWord?: string;
    setPageCount?: any;
    setPageCountOnScroll?: any;
    setScroll?: any;
}

const babybm = 'https://img1.parentune.com/web/assests_v2/babybm.webp';
const Bookmarked = 'https://img1.parentune.com/web/assests_v2/Bookmarked.webp';
const Shape = 'https://img1.parentune.com/web/assets/images/Icon/Share.svg';
const BabyNameBottom1 = (props: BabyNameBottom1Props) => {
    const [showPurposeCard, setshowPurposeCard] = useState(true);

    const [hideBookMarkInMobileView, sethideBookMarkInMobileView] = useState<boolean>(false);
    const { userData: user_data } = useUserData();
    const [imgvalue, setimgvalue] = useState<number>(0);
    const [nextpage, setnextpage] = useState(1);
    const [whatsappName, setWhatsappname] = useState('');
    const [link, setLink] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [fetching, setfetching] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);

    const [triggerStorage, setTriggerStorage] = useState<boolean>(false);
    const { ref, inView } = useInView();

    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [isBookmark, setIsBookmark] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [charr, setcharr] = useState(searchParams.get('page'));
    const questionContainerRef = useRef<any>(null);
    const [modalType, setModalType] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [guestCookies] = useCookies();
    const [paginationCounter, setPaginationCounter] = useState(50);

    // updating bookmark data

    const { status, data, refetch } = useInfiniteQuery(
        [
            process.env.REACT_APP_BABYNAME + 'list-baby-names' + props.startLength,
            props.gender,
            props.Name,
            props.religion,
            SelectedLanguage,
            props.url,
            props.endLength,
            props.fixedLength,
            props.keyWord
        ],
        async ({ pageParam = 1 }) => {
            // await setnextpage(pageParam);
            return axios
                .get(process.env.REACT_APP_BABYNAME + 'list-baby-names', {
                    headers: {
                        userId: user_data?.userId?.toString() || '',
                        instanceId: user_data?.instanceId?.toString() || '',
                        authToken: user_data?.authToken || '',
                        lang: SelectedLanguage || 'en',
                        guestUid: guestCookies['ptgc']
                    },
                    params: {
                        name: props.Name ? props.Name : '',
                        origin: props.religion ? capitalize(props.religion) : '',
                        gender: props.gender ? props.gender : '',
                        page:
                            pageParam == 1
                                ? searchParams.get('page')
                                    ? searchParams.get('page')
                                    : pageParam
                                : pageParam,
                        count: props.size,
                        action: 'getName',
                        show_bookmark: pageParam === 1 ? 1 : 0,
                        startLength: props.startLength,
                        endLength: props.endLength,
                        fixedLength: props.fixedLength,
                        keyWord: props.keyWord
                    }
                })
                .then((res) => {
                    let page =
                        pageParam == 1
                            ? searchParams.get('page')
                                ? searchParams.get('page')
                                : pageParam
                            : pageParam;
                    setnextpage(page);
                    props.setPageCount(res.data.total);
                    nextpage > 1 ? props.setScroll(false) : props.setScroll(true);
                    // props?.setPageCountOnScroll(page);
                    return res.data;
                })
                .finally(() => setfetching(false));
        },
        {
            getNextPageParam: (lastPage: any) =>
                lastPage.next_page === nextpage ? undefined : lastPage.next_page,
            staleTime: 10 * 60 * 60 * 1000
        }
    );

    useEffect(() => {
        //handle redirect

        if (
            (data?.pages[0]?.baby_names?.length !== 0 ||
                data?.pages[0]?.bookmarked_baby_names?.length !== 0) &&
            status === 'success'
        ) {
            // If conditions are met, do nothing (return null or perform your logic)
            // You might replace 'null' with your desired logic if needed
            null;
        } else {
            const abc = setTimeout(() => {
                console.log('redirecting you');
                navigate('/baby-names');
                // Add your redirection logic here
            }, 2000);

            return () => clearTimeout(abc); // Clean up the timeout on component unmount
        }
    }, [data]);

    useEffect(() => {
        if (sessionStorage.getItem('userOnMobile') == 'true') {
            sethideBookMarkInMobileView(true);
        }
        // if (inView && !isFetchingNextPage) {
        //     fetchNextPage();
        //     if (nextpage > 1) {
        //         navigate(`${location.pathname}?page=${nextpage}`);
        //     }
        //     props?.setPageCountOnScroll(nextpage);
        // }
    }, [inView]);

    // hide bookmark in mobile app

    useEffect(() => {
        if (nextpage > 1) {
            navigate(`${location.pathname}?page=${nextpage}`);
        }

        if ('setPageCountOnScroll' in props) {
            props?.setPageCountOnScroll(nextpage);
        }
    }, [nextpage]);

    const HandlePageCounter = (e: any, isFilters = true) => {
        // window.location.href += '?page='+e;

        setSearchParams({ page: e });
        setDataChanged(!dataChanged);
        refetch();
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const bmId: any = localStorage.getItem('bm');
        if (bmId && user_data) {
            setimgvalue(parseInt(bmId));
            // setIsBookmark(parseInt(bmId));
            axios
                .post(
                    process.env.REACT_APP_BABYNAME + 'bookmark',
                    {
                        nameId: parseInt(bmId)
                    },
                    {
                        headers: {
                            userId: user_data ? user_data.userId?.toString() : '',
                            authToken: user_data ? user_data.authToken : '',
                            instanceId: user_data ? user_data.instanceId : ''
                        }
                    }
                )
                .then((res) => {
                    setloading(false);
                })
                .catch((error) => {
                    return;
                    setloading(false);
                })
                .finally(() => {
                    localStorage.removeItem('bm');
                });
        }
    }, [user_data]);

    const numberOfButtons = paginationCounter / props.size;

    const getPaginationButtons = () => {
        let paginationButtons = [];
        for (let i = 1; i <= numberOfButtons; i++) {
            paginationButtons.push(
                <div key={`paginationCounter${i}`}>
                    <TextView
                        // className={border}
                        customStyle={
                            charr && charr === `${i}`
                                ? { opacity: '1', color: '#008080', fontWeight: 'bold' }
                                : { color: 'black' }
                        }
                        onClick={() => HandlePageCounter(i)}
                        text={`${i}`}
                        size={'small'}
                    />
                </div>
            );
        }
        return paginationButtons;
    };

    useEffect(() => {
        const handlePurposeCardShowHide = async () => {
            // const PurposeCardsExistsInLocalStorage = await localStorage.getItem('PurposeCardsData');
            // if (PurposeCardsExistsInLocalStorage) {
            //     const data = JSON.parse(PurposeCardsExistsInLocalStorage);
            //     const ky = window.location.pathname.substring(1);

            //     if (data[ky] == 1) {
            //         setshowPurposeCard(true);
            //     }
            // } else {
            //     setshowPurposeCard(false);
            // }

            const PurposeCardsExistsInLocalStorage = await sessionStorage.getItem(
                'PurposeCardsData'
            );

            // let shouldShowPurposeCard: any = localStorage.getItem('shouldShowPurposeCard');
            // // @ts-ignore
            // shouldShowPurposeCard = JSON.parse(shouldShowPurposeCard);
            // console.table({ shouldShowPurposeCard });

            // if (!shouldShowPurposeCard) return;

            // if (PurposeCardsExistsInLocalStorage) {
            //     const data = JSON.parse(PurposeCardsExistsInLocalStorage);
            //     const ky = window.location.pathname.substring(1);

            //     if (data[ky] == 1) {
            //         setshowPurposeCard(true);
            //     }
            // } else {
            //     setshowPurposeCard(false);
            // }
        };
        handlePurposeCardShowHide();
    }, [sessionStorage.getItem('PurposeCardsData')]);
    const [ID, setID] = useState<number>(0);

    const clicked = (id: number) => {
        setShow(!show);
        setID(id);
    };

    const skeletalLayout = <BabyNameBottom1Skelatal />;

    const langUrl = SelectedLanguage === 'en' ? '' : `/${SelectedLanguage}`;
    const inappParam = '?inapp=1';

    let redirect_path = `${langUrl}/baby-names/detail/NAME_DETAILS/NAME_ID${
        hideBookMarkInMobileView ? inappParam : ''
    }`;

    const descriptionView = (description: string) => {
        let view: any;
        if (description?.length <= 90) {
            view = <div>{description}</div>;
        } else {
            view = (
                <>
                    <div>
                        {description?.slice(0, 90)}
                        <span>...</span>
                    </div>
                </>
            );
        }

        return view;
    };

    const [shareUrl, setShareUrl] = useState<string>('');

    const handleShareClick = (name: string, id: string) => {
        let url =
            SelectedLanguage === 'en'
                ? `/baby-names/detail/${name}/${id}`
                : `/${SelectedLanguage}/baby-names/detail/${name}/${id}`;
        setShareUrl(`${window.location.origin}${url}`);
    };

    const handleModalClose = (e: any) => {
        if (questionContainerRef && questionContainerRef?.current.contains(e.target)) {
            let el = e.target.textContent;
            let el2 = e.target.getAttribute('class');
            if (el2 === 'svg-icon') {
                setShowModal(false);
            } else {
                if (el === 'Done' || el === 'Skip') {
                    setShowModal(false);
                } else return;
            }
        } else {
            setShowModal(false);
        }
    };
    const handleNativeShare = (name: string, id: string) => {
        const url =
            SelectedLanguage === 'en'
                ? `/baby-names/detail/${name}/${id}`
                : `/${SelectedLanguage}/baby-names/detail/${name}/${id}`;
        if (navigator.share) {
            navigator
                .share({
                    title: 'Parentune',
                    url: `${window.location.origin}${url}`
                })
                .then(() => {
                    return;
                })
                .catch(console.error);
        } else {
            // fallback
        }
    };

    const AD_SERIVINGS = [
        {
            type: 'AstroCarousel',
            index: 7,
            PageIndex: 0
        },
        {
            type: 'ServiceCarousel',
            index: 9,
            PageIndex: 2
        }
    ];

    const child = (
        <div>
            <SocialShare
                title={`Hey! I liked this beautiful name (${whatsappName}) on Parentune. `}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                shareUrl={shareUrl}
            />
            <div>
                {JSON.stringify(data?.pages)}
                {((data?.pages[0]?.baby_names?.length != 0 ||
                    data?.pages[0]?.bookmarked_baby_names?.length != 0) &&
                    status === 'success') ||
                status === 'loading' ? (
                    // md:grid md:grid-cols-2 md:items-center md:gap-x-10 lg:grid lg:grid-cols-2 lg:items-center lg:ml-2 lg:gap-x-12
                    <div className="columns-1 md:columns-2">
                        {props.size !== 5 &&
                            data?.pages.map((list) =>
                                list.bookmarked_baby_names.map((c: any) => (
                                    <>
                                        <div
                                            className="break-inside-avoid flex flex-col"
                                            key={c.id}
                                        >
                                            <div
                                                className="flex  lg:h-12 justify-between "
                                                key={c.id}
                                            >
                                                <a
                                                    href={`${redirect_path
                                                        .replace('NAME_DETAILS', c.name)
                                                        .replace('NAME_ID', c.id)}`}
                                                >
                                                    <div
                                                        className={
                                                            'flex gap-x-3 ml-2 relative cursor-pointer ' +
                                                            styles.suggestion
                                                        }
                                                        data-name={c.name}
                                                        data-description={c.description}
                                                        data-gender={c.gender}
                                                        data-religion={c.origin}
                                                        data-id={c.id}
                                                        key={c.id}
                                                        style={{
                                                            minWidth: '280px',
                                                            width: '290px'
                                                        }}
                                                    >
                                                        <div>
                                                            {c.gender == 'boy' && (
                                                                <span className={styles.nameIcon}>
                                                                    <BoyIcon />
                                                                </span>
                                                            )}
                                                            {c.gender == 'girl' && (
                                                                <span className={styles.nameIcon}>
                                                                    <GirlIcon />
                                                                </span>
                                                            )}
                                                            {c.gender == 'other' && (
                                                                <span className={styles.nameIcon}>
                                                                    <BoyIcon />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div className="flex justify-between">
                                                                <p
                                                                    className={
                                                                        'text-base font-medium ' +
                                                                        styles.suggestedName
                                                                    }
                                                                >
                                                                    {c.name}
                                                                </p>
                                                            </div>
                                                            <div
                                                                onClick={() => setShow(false)}
                                                                className={
                                                                    'text-sm font-normal ' +
                                                                    styles.suggestionPara
                                                                }
                                                            >
                                                                {descriptionView(c.description)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className="flex flex-col ">
                                                    <div className="flex  gap-x-2 right-1 w-[38px]">
                                                        <div className="flex flex-col">
                                                            {!hideBookMarkInMobileView && (
                                                                <BookmarkImg1
                                                                    imgvalue={imgvalue}
                                                                    setlink={setLink}
                                                                    c={c}
                                                                    setshow={setShow}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Gutter
                                                styles={{
                                                    borderBottom: '1px solid #DADADA',
                                                    paddingBottom: '26px',
                                                    marginRight: '10px'
                                                }}
                                            />
                                        </div>
                                    </>
                                ))
                            )}

                        {data?.pages.map((list: any, pageIndex: number) =>
                            list.baby_names?.map((c: any, index: number) => {
                                const ad = AD_SERIVINGS.find(
                                    (ad) => ad.index === index && ad.PageIndex === pageIndex
                                );
                                if (ad) {
                                    switch (ad.type) {
                                        case 'AstroCarousel':
                                            return (
                                                <div className="" key={`astrocarousal`}>
                                                    {!DoesURLContainMuslim() ? (
                                                        !DoesURLContainChristian() ? (
                                                            <div className="p-2">
                                                                {' '}
                                                                <ImageCarouselForBabyNames
                                                                    imagesArray={[
                                                                        {
                                                                            url: 'https://img1.parentune.com/temp/baby-names-carousels-ver2.webp'
                                                                        },
                                                                        {
                                                                            url: 'https://img1.parentune.com/temp/baby-names-carousels2-ver2.webp'
                                                                        }
                                                                    ]}
                                                                />
                                                            </div>
                                                        ) : null
                                                    ) : null}
                                                </div>
                                            );
                                        case 'ServiceCarousel':
                                            return (
                                                <div key={'serviceCarousal'} className="pt-4 pb-2 ">
                                                    <Suspense fallback={null}>
                                                        {' '}
                                                        <ProductServicesCarousel
                                                            pageType={'babyNameList'}
                                                        />
                                                    </Suspense>
                                                </div>
                                            );
                                    }
                                } else if (pageIndex == 0 && index === 11 && showPurposeCard)
                                    return (
                                        <div className="break-inside-avoid my-5 flex justify-center items-center">
                                            <PurposeCard />
                                        </div>
                                    );
                                else if (pageIndex > 0 && (pageIndex * 15 + index) % 10 == 0) {
                                    return (
                                        <Suspense fallback={null}>
                                            <AdComponent slot_id={index} pageInfo="babyname_list" />
                                        </Suspense>
                                    );
                                } else if (pageIndex > 0 && (pageIndex * 15 + index) % 15 == 0) {
                                    return (
                                        <Suspense fallback={null}>
                                            <LiveChatAdComponent />
                                        </Suspense>
                                    );
                                } else if ((pageIndex + 2 * 15 + index + 2) % 7 == 0) {
                                    return (
                                        <Suspense fallback={null}>
                                            <DfpCompnent />
                                        </Suspense>
                                    );
                                } else {
                                    return (
                                        <>
                                            {/* baby name list */}
                                            <div
                                                className="break-inside-avoid flex flex-col"
                                                key={`babyNameList${c.id}`}
                                            >
                                                <div
                                                    className="break-inside-avoid flex  lg:h-12 justify-between"
                                                    key={c.id}
                                                >
                                                    <a
                                                        href={`${redirect_path
                                                            .replace('NAME_DETAILS', c.name)
                                                            .replace('NAME_ID', c.id)}`}
                                                    >
                                                        <div
                                                            className={
                                                                'flex gap-x-3 ml-2 relative cursor-pointer ' +
                                                                styles.suggestion
                                                            }
                                                            data-name={c.name}
                                                            data-description={c.description}
                                                            data-gender={c.gender}
                                                            data-religion={c.origin}
                                                            data-id={c.id}
                                                            key={c.id}
                                                            style={{
                                                                minWidth: '280px',
                                                                width: '290px'
                                                            }}
                                                        >
                                                            <div>
                                                                {c.gender == 'boy' && (
                                                                    <span
                                                                        className={styles.nameIcon}
                                                                    >
                                                                        <BoyIcon />
                                                                    </span>
                                                                )}
                                                                {c.gender == 'girl' && (
                                                                    <span
                                                                        className={styles.nameIcon}
                                                                    >
                                                                        <GirlIcon />
                                                                    </span>
                                                                )}
                                                                {c.gender == 'other' && (
                                                                    <span
                                                                        className={styles.nameIcon}
                                                                    >
                                                                        <BoyIcon />
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <div className="break-inside-avoid flex justify-between">
                                                                    <p
                                                                        className={
                                                                            'text-base font-medium ' +
                                                                            styles.suggestedName
                                                                        }
                                                                    >
                                                                        {c.name}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    onClick={() => setShow(false)}
                                                                    className={
                                                                        'text-sm font-normal ' +
                                                                        styles.suggestionPara
                                                                    }
                                                                >
                                                                    {descriptionView(c.description)}
                                                                </div>

                                                                {/* {show && ID === c.id && (
                                                            <div
                                                                onClick={(e) => {

                                                                    setIsOpen(true), setShow(!show);
                                                                    // props.setCol('rgba(0, 0, 0, 0.65)');
                                                                    // props.setBorder('border-gray-600');
                                                                    // props.setBtnCol('#e3ab57');
                                                                    setWhatsappname(c.name);
                                                                    e.stopPropagation();
                                                                    handleShareClick(c.name, c.id);
                                                                }}
                                                                className="cursor-pointer absolute top-6 right-3 flex items-center bg-white pl-2  pr-2 pt-0.5  border-2"
                                                            >
                                                                <img
                                                                    className="w-5 bg-white"
                                                                    src={Shape}
                                                                ></img>
                                                                <span className="ml-2">Share</span>
                                                            </div>
                                                        )} */}
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className="break-inside-avoid flex flex-col ">
                                                        <div className="flex gap-x-2 right-1 w-[38px]">
                                                            <div className="flex flex-col">
                                                                {!hideBookMarkInMobileView && (
                                                                    <BookmarkImg
                                                                        imgvalue={imgvalue}
                                                                        setlink={setLink}
                                                                        c={c}
                                                                        setshow={setShow}
                                                                        setShowModal={setShowModal}
                                                                        setModalType={setModalType}
                                                                    />
                                                                )}
                                                            </div>
                                                            {/* <div
                                                            onClick={() => clicked(c.id)}
                                                            className="h-4 cursor-pointer"
                                                        >
                                                            <BsThreeDotsVertical />
                                                        </div> */}

                                                            {/* <div
                                                                onClick={(e) => {
                                                                    setIsOpen(true), setShow(!show);
                                                                    // props.setCol('rgba(0, 0, 0, 0.65)');
                                                                    // props.setBorder('border-gray-600');
                                                                    // props.setBtnCol('#e3ab57');
                                                                    setWhatsappname(c.name);
                                                                    e.stopPropagation();
                                                                    handleShareClick(c.name, c.id);
                                                                }}
                                                                className="cursor-pointer absolute top-6 right-3 flex items-center bg-white pl-2  pr-2 pt-0.5  border-2"
                                                            > */}
                                                            {/* <img
                                                                onClick={(e) => {
                                                                    if (
                                                                        window.innerWidth < 600 &&
                                                                        !hideBookMarkInMobileView
                                                                    ) {
                                                                        return handleNativeShare(
                                                                            c.name,
                                                                            c.id
                                                                        );
                                                                    }
                                                                    setIsOpen(true), setShow(!show);
                                                                    // props.setCol('rgba(0, 0, 0, 0.65)');
                                                                    // props.setBorder('border-gray-600');
                                                                    // props.setBtnCol('#e3ab57');
                                                                    setWhatsappname(c.name);
                                                                    e.stopPropagation();
                                                                    handleShareClick(c.name, c.id);
                                                                }}
                                                                className="w-[16px] h-[16px] bg-white"
                                                                src={Shape}
                                                            ></img> */}
                                                            {/* <span className="ml-2">Share</span> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Gutter
                                                    styles={{
                                                        borderBottom: '1px solid #DADADA',
                                                        paddingBottom: '26px',
                                                        marginRight: '10px',
                                                        marginBottom: '10px'
                                                    }}
                                                />
                                            </div>
                                        </>
                                    );
                                }
                            })
                        )}
                    </div>
                ) : (
                    <div className="flex justify-center items-center w-full text-black">
                        <p className="font-medium text-lg justify-center">
                            No matches found for your filters. Kindly relook at your filters once.
                        </p>
                    </div>
                )}
                {/* {hasNextPage && <div ref={ref}>{skeletalLayout}</div>} */}
            </div>
            {showModal ? (
                <LoginPopup
                    showModal={showModal}
                    setShowModal={setShowModal}
                    questionContainerRef={questionContainerRef}
                    modalType={modalType}
                ></LoginPopup>
            ) : null}
        </div>
    );

    return (
        <Suspense fallback={skeletalLayout}>
            <LoadingLayout
                skelton={skeletalLayout}
                child={child}
                isLoading={fetching || status === 'loading'}
            />
        </Suspense>
    );
};
const BookmarkImg = ({ c, setshow, imgvalue, setShowModal, setModalType }: any) => {
    const [isBookmark, setIsBookmark] = useState(true);
    const { isLoggedIn } = useUserData();
    const navigate = useNavigate();
    const { userData: user_data } = useUserData();
    const handleBookmark = (c: { id: { toString: () => string } }) => {
        setshow(false);
        setModalType(Constants.MODAL_TYPE.BOOKMARK);
        setShowModal(true);
        localStorage.setItem('bm', c.id.toString());
        // navigate('/register');
    };
    if (!isLoggedIn) {
        return (
            <img
                onClick={() => handleBookmark(c)}
                className="h-4 mr-1 cursor-pointer "
                alt="bookmark"
                src={Bookmarked}
                loading="lazy"
            ></img>
        );
    }

    return isBookmark && imgvalue != c.id ? (
        <img
            width={12}
            onClick={async () => {
                setshow(false);
                setIsBookmark(false);
                await axios
                    .post(
                        process.env.REACT_APP_BABYNAME + 'bookmark',
                        {
                            nameId: c.id
                        },
                        {
                            headers: {
                                userId: user_data ? user_data.userId?.toString() : '',
                                authToken: user_data ? user_data.authToken : '',
                                instanceId: user_data ? user_data.instanceId : ''
                            }
                        }
                    )
                    .then((res) => {
                        return;
                    })
                    .catch((error) => console.log(error));
            }}
            className="h-4 mr-1 cursor-pointer  "
            alt="bookmark"
            src={Bookmarked}
        ></img>
    ) : (
        <img
            width={12}
            onClick={async () => {
                setshow(false);
                setIsBookmark(true);
                await axios
                    .post(
                        process.env.REACT_APP_BABYNAME + 'delete-bookmark',
                        {
                            nameId: c.id
                        },
                        {
                            headers: {
                                userId: user_data ? user_data.userId?.toString() : '',
                                authToken: user_data ? user_data.authToken : '',
                                instanceId: user_data ? user_data.instanceId : ''
                            }
                        }
                    )
                    .then((res) => {
                        return;
                    })
                    .catch((error) => console.log(error));
            }}
            className="h-4 mr-1 cursor-pointer  "
            alt="bookmark"
            src={babybm}
        ></img>
    );
};

const BookmarkImg1 = ({ c, setshow }: any) => {
    const [isBookmark, setIsBookmark] = useState(true);
    const { isLoggedIn } = useUserData();
    const navigate = useNavigate();
    const { userData: user_data } = useUserData();

    const handleBookmark = (c: { id: { toString: () => string } }) => {
        setshow(false);
        localStorage.setItem('bm', c.id.toString());
        // navigate('/register');
    };

    if (!isLoggedIn) {
        return (
            <img
                onClick={() => handleBookmark(c)}
                className="h-4 mr-1 cursor-pointer  "
                alt="bookmark"
                src={Bookmarked}
            ></img>
        );
    }

    return isBookmark ? (
        <img
            onClick={async () => {
                setshow(false);
                setIsBookmark(false);
                await axios
                    .post(
                        process.env.REACT_APP_BABYNAME + 'delete-bookmark',
                        {
                            nameId: c.id
                        },
                        {
                            headers: {
                                userId: user_data ? user_data.userId?.toString() : '',
                                authToken: user_data ? user_data.authToken : '',
                                instanceId: user_data ? user_data.instanceId : ''
                            }
                        }
                    )
                    .then((res) => {
                        return;
                    })
                    .catch((error) => console.log(error));
            }}
            className="h-4 mr-1 cursor-pointer  "
            alt="bookmark"
            src={babybm}
            width="12px"
            height="16px"
        ></img>
    ) : (
        <img
            onClick={async () => {
                setshow(false);
                setIsBookmark(true);
                await axios
                    .post(
                        process.env.REACT_APP_BABYNAME + 'bookmark',
                        {
                            nameId: c.id
                        },
                        {
                            headers: {
                                userId: user_data ? user_data.userId?.toString() : '',
                                authToken: user_data ? user_data.authToken : '',
                                instanceId: user_data ? user_data.instanceId : ''
                            }
                        }
                    )
                    .then((res) => {
                        return;
                    })
                    .catch((error) => console.log(error));
            }}
            className="h-4 mr-1 cursor-pointer "
            alt="bookmark"
            src={Bookmarked}
        ></img>
    );
};

interface SocialShareProps {
    isOpen: boolean;
    title?: string;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    shareUrl?: string | null;
}
export const SocialShare = ({ isOpen, title, setIsOpen, shareUrl }: SocialShareProps) => {
    const [copySuccess, setCopySuccess] = useState<any>('Copy Link');
    const copyToClipboard = (copyMe: string) => {
        const textField = document.createElement('textarea');
        textField.innerText = copyMe;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopySuccess('Copied!');
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0  overflow-y-auto"
                style={{ zIndex: 60 }}
                onClose={() => {
                    setIsOpen(false);
                }}
            >
                <div>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-30"
                    >
                        <Dialog.Overlay className="fixed bg-black bg-opacity-30 inset-0" />
                    </Transition.Child>
                    <span
                        className="inline-block h-screen align-middle bg-slate-500"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-30 scale-95"
                    >
                        <div
                            style={{
                                maxWidth: '1000px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                justifyContent: 'space-evenly'
                            }}
                            className="sticky border bottom-0 bg-white  flex justify-between items-center h-[112px] "
                        >
                            <WhatsappShareButton
                                url={shareUrl ? shareUrl : window?.location.href}
                                title={title}
                                className="outline-none"
                            >
                                {/* <WhatsappIcon className="w-10 h-10 mx-auto" round /> */}

                                <WhatsappShareIcon className="mx-auto" />
                                <p className={styles.shareLogoText}>Whatsapp</p>
                            </WhatsappShareButton>
                            <FacebookShareButton
                                url={shareUrl ? shareUrl : window?.location.href}
                                title={title}
                            >
                                {/* <FacebookIcon className="w-10 h-10 mx-auto" round /> */}
                                <FacebookShareIcon className="mx-auto" />
                                <p className={styles.shareLogoText}>Facebook</p>
                            </FacebookShareButton>

                            <div>
                                <CopyLinkIcon
                                    className="mx-auto cursor-pointer"
                                    onClick={(e: any) =>
                                        copyToClipboard(shareUrl ? shareUrl : window.location.href)
                                    }
                                />

                                <p className={styles.shareLogoText}>{copySuccess}</p>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};
export default BabyNameBottom1;
