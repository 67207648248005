function SmallArrowRight(props) {
    return (
        <svg
            width={6}
            height={9}
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Small Arrow"
            className={props.className}
            {...props}
        >
            <path
                d="M5.243 4.049a.64.64 0 010 .903L1.591 8.605A.64.64 0 01.687 7.7l3.2-3.2-3.2-3.2a.64.64 0 11.904-.905L5.243 4.05z"
                fill="#000"
            />
        </svg>
    );
}

export default SmallArrowRight;
