// @ts-nocheck
import React, { Suspense, useLayoutEffect, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import './App.css';
import { lazyWithRetry } from './utils/LazyWithRetry';
import Loader from './components/Loader/Loader';
import RowLoader from './components/Loader/RowLoader';
const Session = lazyWithRetry(() => import('./pages/Session/Session'));
const ProductListLandinPage = lazyWithRetry(
    () => import('./pages/ProductList/ProductListLandinPage')
);
const ChatIntakeForm = lazyWithRetry(() => import('./pages/Session/ChatIntakeForm'));
const AgentSession = lazyWithRetry(() => import('./pages/Session/AgentSession'));
import { useUserData } from './utils/hooks/useUserData';

const TabFooter = lazyWithRetry(() => import('./components/TabFooter/TabFooter'));
const ProtectedRoute = lazyWithRetry(() => import('./ProtectedRoute'));
const AnimationContainer = lazyWithRetry(
    () => import('./components/V2Components/AnimationContainer/AnimationContainer')
);
const BlogPage = lazyWithRetry(() => import('./pages/BlogPage/BlogPage'));
const Stories = lazyWithRetry(() => import('./pages/Stories/Stories'));

const BlogDetailsPage = lazyWithRetry(() => import('./pages/BlogDetailsPage/BlogDetailsPage'));
const RoadBlock = lazyWithRetry(() => import('./components/RoadBlock/RoadBlock'));
const PlusPage = lazyWithRetry(() => import('./pages/PlusPage/PlusPage'));
const DailyFocus = lazyWithRetry(() => import('./pages/DailyFocus/DailyFocusWrapper/DailyFocus'));
const DailyFocusGuestPage = lazyWithRetry(() => import('./pages/DailyFocusGuest/DailyFocusGuest'));
import fetchToken from './api/Auth';
const PlanDetailpage = lazyWithRetry(
    () => import('./pages/PlusSubscription/PlanDetailPage/PlanDetailpage')
);
const Talk = lazyWithRetry(() => import('./pages/TalkModule/Talk'));
const TalkDetail = lazyWithRetry(() => import('./pages/TalkModule/TalkDetail'));
const PreviousTalks = lazyWithRetry(() => import('./pages/TalkModule/PreviousTalks'));
const PlanDetailpageAstro = lazyWithRetry(
    () => import('./pages/PlusSubscription/PlanDetailPageAstro/PlanDetailpage')
);
const RenewalPage = lazyWithRetry(() => import('./pages/Renewal/RenewalPage'));
const PlanDetailpageExpert = lazyWithRetry(
    () => import('./pages/PlusSubscription/PlanDetailPageExpert/PlanDetailpage')
);

const PlanDetailpageCalender = lazyWithRetry(
    () => import('./pages/PlusSubscription/PlanDetailPageCalender/PlanDetailpage')
);

import { addUserdata, updateUserData } from './store/slices/userDataSlice';
// import { updateConsent } from './store/slices/userDataSlice';

const Subcribe = lazyWithRetry(() => import('./pages/Subscribe/Subcribe'));
const AskExperts = lazyWithRetry(() => import('./pages/AskExpertModule/AskExperts/AskExperts'));
const AnswerDetail = lazyWithRetry(() => import('./pages/AskExpertModule/AnswerDetail'));
const QuestionsAsked = lazyWithRetry(() => import('./pages/AskExpertModule/QuestionsAsked'));
const AskQuestionExpert = lazyWithRetry(
    () => import('./components/AskExpertModule/AskQuestionExpert/AskQuestionExpert')
);
const OurExpertFullView = lazyWithRetry(() => import('./components/OurExperts/OurExpertFullView'));
const Coupon = lazyWithRetry(() => import('./pages/Couponpage/Coupon/Coupon'));
const RedirectToQuestionDetail = lazyWithRetry(
    () => import('./components/Redirection/RedirectToQuestionDetail')
);
const RedirectToTalkDetail = lazyWithRetry(
    () => import('./components/Redirection/RedirectToTalkDetail')
);
import NavBar from './components/NavBar/NavBar';
const BabyName = lazyWithRetry(() => import('./pages/BabyName/BabyName'));
const NameDetail = lazyWithRetry(() => import('./components/NameDetail/NameDetails'));
const BabyNameViewAll = lazyWithRetry(() => import('./components/BabyNameViewAll/BabyNameViewAll'));
const SessionDetails = lazyWithRetry(() => import('./pages/Session/SessionDetails'));
import PageTracking from './pages/PageTracking';
import MixPanelPageTracking from './pages/MixPanel';
const isFirebaseSupported = lazyWithRetry(() => import('../src/firebase'));
// const NotificationToster = lazyWithRetry(() => import('./components/NotificationToaster'));
import ReactGA from 'react-ga4';

//import i18n from './i18n/config';

function ScrollToTop() {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        if (!pathname.includes('register')) localStorage.setItem('pathname', pathname);
    }, [pathname]);

    return null;
}

const ExpertListLandinPage = lazyWithRetry(() => import('./pages/ExpertList/ExpertListLandinPage'));
const MentalHealthLandingPage = lazyWithRetry(
    () => import('./pages/ExpertList/MentalHealthConsultation')
);
const LiveEventsListing = lazyWithRetry(() => import('./pages/Workshop/LiveEventsListing'));
const Sampling = lazyWithRetry(() => import('./pages/Sampling/Sampling'));
import { Cookies, useCookies } from 'react-cookie';
import { useAppDispatch } from './store/store';
import BreastFeeding from './pages/BreastFeeding';
import RegisterPage from './pages/RegisterPage/RegisterPage';
const MyProfile = lazyWithRetry(() => import('./pages/MyProfile/MyProfile'));
const ProfilePage = lazyWithRetry(() => import('./components/Profile/ProfilePage/ProfilePage'));

const CommingSoon = lazyWithRetry(() => import('./pages/ComingSoon/ComingSoon'));
import TermAndConditions from './pages/TermsAndConditions/TermAndConditions';

const EventCalendar = lazyWithRetry(() => import('./pages/eventCalendar/eventCalendar'));
const Event = lazyWithRetry(() => import('./pages/Workshop/Event'));
const AboutUsNew = lazyWithRetry(() => import('./pages/AboutUs/AboutUsNew'));

import Gallery from './pages/AboutUs/Gallery';
import AskQuestionExpertCopy from './components/AskExpertModule/AskQuestionExpert/AskQuestionExpertCopy';
import ShortTermPlanWorkShop from './pages/ShortTermPlan/ShortTermPlanWorkshop';
import { useOnboardingData } from './utils/hooks/useOnboardingData';
import { updatePathName } from './store/slices/onboardingSlice';
import EditProfileNew from './pages/EditProfileNew/EditProfileNew';
import { Geti18nLang } from './utils/i18n utils/Geti18nLang';
import { HideHeaderInTheseRoutes, handlePurposeCards, isSocialMediaBrowser } from './utils/utils';
import { GetUserProfile } from './api/OnBoarding/GetUserProfile';
import AppMetaInfo from './components/App/AppMetaInfo';
import Overlay from './components/Overlay/Overlay';
import Unsubscribe from './pages/Unsubscribe/Unsubscribe';
import useFetchUserLocation from './utils/hooks/useFetchUserLocation';
import BabyNameViewAllAfrica from './components/BabyNameViewAllAfrica/BabyNameViewAllAfrica';
import NameDetailAfrica from './components/NameDetailAfrica/NameDetails';
import LandingPage from './pages/LandingPage/LandingPage';
import BookingSummary from './pages/BookingSummary/BookingSummary';
import PaymentSummary from './pages/PaymentSummary/PaymentSummary';
import Congrutulation from './pages/Congratulation/Congratulation';
import ChoosePlanPage from './pages/ChoosePlan/ChoosePlan';
import ChoosePlanPageDetail from './pages/ChoosePlan/ChoosePlanDetail';
import Myprofile from './pages/Renewal/Myprofile';
// import GenderPopup from './components/GenderPopup/GenderPopup';
import MyOrderPage from './pages/MyOrderPage/MyOrderPage';
import { updateUserLocation } from './store/slices/utilsSlice';
import DailyFocusGuest from './components/DailyFocusGuest/DailyFocusGuestWidget';
import GiftPage from './pages/GiftPage/GiftPage';
import GiftActivationPage from './pages/GiftActivationPage/GiftActivationPage';
import GenderPopup from './components/GenderPopup/GenderPopup';
import { PopUpAdHm } from './components/adComponent/AdHM';
import ProfileVerified from './components/Profile/ProfilePage/ProfileVerified';
const UpgradeToApp = lazyWithRetry(() => import('./components/UpgradeToApp/UpgradeToApp'));
const HowToUseParentune = lazyWithRetry(
    () => import('./components/Onboarding/HowToUseParentune/HowToUseParentune')
);

const ForceLogout = lazyWithRetry(() => import('./components/ForceLogout/ForceLogout'));
const PreSchoolDetail = lazyWithRetry(() => import('./components/PreSchool/PreSchoolDetail'));

const AboutUs = lazyWithRetry(() => import('./pages/AboutUs/AboutUs'));
const ShopCalendar = lazyWithRetry(() => import('./pages/ShopCalendar/ShopCalendar'));

const NonLogin = lazyWithRetry(
    () => import('./pages/PlusSubscription/PlanDetailPage/nonLogin/NonLogin')
);

const PreScoolHome = lazyWithRetry(() => import('./components/PreSchool/PreScoolHome'));

const ShortTermPlan = lazyWithRetry(() => import('./pages/ShortTermPlan/ShortTermPlan'));
const ImmunifyMe = lazyWithRetry(() => import('./pages/ImmunifyMe/ImmunifyMe'));

const GStudio = lazyWithRetry(() => import('./pages/GStudioModule/StudioReports'));

function App() {
    const [cookies, setCookie] = useCookies(['_ppmgi']);
    const [hideHeaderForMobile, setHideHeaderForMobile] = useState(false);
    const searchParams = new URLSearchParams(document.location.search);
    const { userData: user_data, isLoggedIn } = useUserData();
    const { pathName } = useOnboardingData();
    const dispatch = useAppDispatch();
    const [lastScrollY, setLastScrollY] = useState(0);
    const [hideHeaderonScroll, sethideHeaderonScroll] = useState(false);
    const [hideHader, sethideHader] = useState(false);
    const [guestCookies] = useCookies();
    const newGuestUid = guestCookies['ptgc'];

    // const navigate = useNavigate();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    useEffect(() => {
        document.addEventListener('click', (event) => {
            if (HideHeaderInTheseRoutes.includes(pathName)) sethideHader(true);
            else sethideHader(false);
        });

        if (window.location.pathname.includes('/gift/')) sethideHader(true);
        else sethideHader(false);

        window.addEventListener('popstate', () => {
            setTimeout(() => {
                if (HideHeaderInTheseRoutes.includes(pathName)) sethideHader(true);
                else sethideHader(false);
            }, 0);
        });
    }, [pathName]);

    useEffect(() => {
        if (!newGuestUid && !user_data) return;

        if (!user_data) {
            ReactGA.set({
                userId: newGuestUid
            });
            return;
        } else {
            ReactGA.set({
                userId: user_data?.id
            });
            handlePurposeCards();
        }
    }, [newGuestUid, user_data]);

    // useEffect(() => {
    //     if (!/^\/parent-blog\//.test(pathName)) return;

    //     const controlNavbar = () => {
    //         if (window.scrollY > lastScrollY) {
    //             // if scroll down hide the navbar
    //             sethideHeaderonScroll(true);
    //         } else {
    //             // if scroll up show the navbar
    //             sethideHeaderonScroll(false);
    //         }

    //         // remember current page location to use in the next move
    //         setLastScrollY(window.scrollY);
    //     };
    //     window.addEventListener('scroll', controlNavbar);

    //     // cleanup function
    //     return () => {
    //         window.removeEventListener('scroll', controlNavbar);
    //     };
    // }, [lastScrollY, pathName]);

    useEffect(() => {
        useFetchUserLocation().then((data) => {
            dispatch(updateUserLocation(data));
        });
        const handlehieght = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        const handleOnCloseTab = () => {
            const PurposeCardsData = sessionStorage.getItem('PurposeCardsData');
            const data = JSON.parse(PurposeCardsData);
            if (!data) return;
        };

        window.addEventListener('resize', handlehieght);
        window.addEventListener('beforeunload', handleOnCloseTab);

        return () => {
            window.removeEventListener('resize', handlehieght);
            window.removeEventListener('beforeunload', handleOnCloseTab);
        };
    }, []);

    useEffect(() => {
        const path = window.location.pathname;

        const regex = /^\/user\/edit-profile\/.*$/;
        if (regex.test(path)) {
            window.location.pathname = '/edit-profile';
        }

        dispatch(updatePathName(path));
    }, [window.location.pathname]);

    useEffect(() => {
        if (pathName !== '/register') {
            localStorage.removeItem('onboarding_step');
            localStorage.removeItem('onboarding_nav');
            localStorage.removeItem('onboarding_nav_previous');
        }
    }, [pathName]);

    const xhrFetchToken = (ppmgi: any) => {
        let params = {
            token: ppmgi ? ppmgi : null
        };
        fetchToken(params, fetchTokenOnSuccess, fetchTokenOnFailure);
    };
    const fetchTokenOnSuccess = (data: any) => {
        console.log('on sucess 161', data);
        const dataNew = {
            data: data,
            userData: user_data
        };
        localStorage.setItem(cookies._ppmgi, JSON.stringify(data));
        dispatch(updateUserData(dataNew));
        dataNew.data.consentGiven === false && dispatch(updateConsent(dataNew));
        // calling new profile api for webview users
        GetUserProfile(
            async (data) => {
                // console.log('Sucess', data);
                dispatch(addUserdata({ ...data?.data }));
            },
            (err) => {
                console.error('Error: ', err);
            },
            { instanceId: data?.instanceId, authToken: data?.token, userId: data?.id }
        );
    };

    const fetchTokenOnFailure = (error) => {
        console.log(error);
    };

    useEffect(() => {
        if (searchParams.get('inapp') === '1') {
            setHideHeaderForMobile(true);
            sessionStorage.setItem('userOnMobile', 'true');
            const ppmgi = searchParams.get('ppmgi');
            document.cookie =
                '_ppmgi' + '=' + ppmgi + ';expires=Thu, 01 Jan 2024 00:00:00 GMT;path=/;';
            if (ppmgi && !isLoggedIn && searchParams.get('inapp') === '1') {
                console.log('calling api');
                xhrFetchToken(ppmgi);
            }
        }

        if (sessionStorage.getItem('userOnMobile') == 'true') {
            setHideHeaderForMobile(true);
        }
        // if (searchParams.get('ppmgi') && !isLoggedIn && !cookies['_ppmgi']) {
        //     document.cookie = `_ppmgi=${searchParams.get('ppmgi')};max-age=86400 * 365; path=/`;
        //     window.location.reload();
        // }

        //  else if (cookie) {
        //     xhrFetchToken();
        // }
    }, [window.location]);

    const NotFound = () => {
        const SelectedLanguage = Geti18nLang();
        const url = SelectedLanguage === 'en' ? '/' : `${SelectedLanguage}/`;
        useEffect(() => {
            window.location.assign('/');
        }, []);
        return null;
    };

    return (
        <div className="max-w-full mx-auto site-spacing">
            {/* <Suspense fallback={null}>
                {isFirebaseSupported && !isSocialMediaBrowser() && <NotificationToster />}
            </Suspense> */}
            <AppMetaInfo />

            <Router>
                <Suspense fallback={null}>
                    {/* <div>pathName: {JSON.stringify(pathName, null, 4)}</div> */}
                    {/* <GuestCookieId /> */}
                    {hideHeaderForMobile || hideHeaderonScroll || hideHader ? null : <NavBar />}
                    {/* {!user_data && <GenderPopup />} */}
                    <UpgradeToApp />
                </Suspense>
                <Suspense fallback={null}>
                    <ScrollToTop />
                    {/* <GuestCookieId isLoggedIn={false} /> */}
                    <PageTracking />
                    <MixPanelPageTracking />
                    <Routes>
                        {' '}
                        <Route path="/gift/:orderid" element={<GiftActivationPage />} />
                        <Route path="/giftpage" element={<GiftPage />} />
                        <Route path="/booking-summary" element={<BookingSummary />} />
                        <Route path="/payment-summary" element={<PaymentSummary />} />
                        <Route path="/congrutulation" element={<Congrutulation />} />
                        <Route
                            path="/register"
                            element={
                                <>
                                    <AnimationContainer>
                                        <RegisterPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        {/* <Route
                            path="/xtest"
                            element={
                                <>
                                    <AnimationContainer>
                                        <TestPage />
                                    </AnimationContainer>
                                </>
                            }
                        /> */}
                        <Route
                            path="/:lng/register"
                            element={
                                <>
                                    <AnimationContainer>
                                        <RegisterPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/user/verified"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <ProfileVerified />
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/edit-profile/"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <EditProfileNew />
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/edit-profile/"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <EditProfileNew />
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/user/edit-profile/:id"
                            element={
                                <>
                                    <ProtectedRoute></ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/user/profile/:userID"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <ProfilePage />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/user/profile/:userID"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <ProfilePage />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner/child-psychologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner/gynaecologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="expert-corner/pediatrician"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/child-psychologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/gynaecologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/pediatrician"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/my-profile"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <Myprofile />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/school"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/school"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/school"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/school"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/school/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/school/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/preschool"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/preschool"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/preschool"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/preschool"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/preschool/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/preschool/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/preschool/:oldURL/"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/school/:oldURL/"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/gynaecologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/gynaecologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/gynaecologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/gynaecologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/gynaecologist/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/gynaecologist/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/child-psychologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/child-psychologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/child-psychologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/child-psychologist"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/child-psychologist/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/child-psychologist/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />{' '}
                        <Route
                            path="/pediatrician"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/pediatrician"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/pediatrician"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/pediatrician"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/pediatrician/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/pediatrician/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/register"
                            element={
                                <>
                                    <AnimationContainer>
                                        <RegisterPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/register"
                            element={
                                <>
                                    <AnimationContainer>
                                        <RegisterPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/parent-talk"
                            element={
                                <>
                                    <Talk />
                                    {/* <RoadBlock /> */}
                                    <TabFooter tab="parent-talk" />
                                </>
                            }
                        />
                        <Route
                            path="/parent-talk/:interest"
                            element={
                                <>
                                    <Talk />
                                    {/* <RoadBlock /> */}
                                    <TabFooter tab="parent-talk" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-talk/:interest"
                            element={
                                <>
                                    <Talk />
                                    {/* <RoadBlock /> */}
                                    <TabFooter tab="parent-talk" />
                                </>
                            }
                        />
                        <Route
                            path="/parent-talk/prevtalks"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <PreviousTalks />
                                        {/* <RoadBlock /> */}
                                        <TabFooter tab="parent-talk" />
                                    </ProtectedRoute>
                                </>
                            }
                        />{' '}
                        <Route
                            path="/:lng/parent-talk/prevtalks"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <PreviousTalks />
                                        {/* <RoadBlock /> */}
                                        <TabFooter tab="parent-talk" />
                                    </ProtectedRoute>
                                </>
                            }
                        />{' '}
                        <Route
                            path="/parent-talk/starttalk"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AskQuestionExpertCopy />
                                        {/* <RoadBlock /> */}
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-talk/starttalk"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AskQuestionExpertCopy />
                                        {/* <RoadBlock /> */}
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-talk"
                            element={
                                <>
                                    <Talk />
                                    {/* <RoadBlock /> */}
                                    <TabFooter tab="parent-talk" />
                                </>
                            }
                        />
                        <Route
                            path="/parent-talk/details/:talkId"
                            element={
                                <>
                                    <TalkDetail />
                                    {/* <TabFooter tab="parent-talk" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-talk/details/:talkId"
                            element={
                                <>
                                    <TalkDetail />
                                    {/* <TabFooter tab="parent-talk" /> */}
                                </>
                            }
                        />
                        <Route path="/parent-talk/:title/:id" element={<RedirectToTalkDetail />} />
                        <Route
                            path="/:lng/parent-talk/:title/:id"
                            element={<RedirectToTalkDetail />}
                        />
                        <Route
                            path="/baby-names"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        {/* baby name africa home page */}
                        <Route
                            path="/baby-names/africa"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/kenya"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/south-africa"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/ghana"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        {/* <Route
                            path="/baby-names/uganda"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        /> */}
                        <Route
                            path="/baby-names/nigeria"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        {/* africa details */}
                        <Route
                            path="/baby-names/ghana/:type"
                            element={
                                <>
                                    <BabyNameViewAllAfrica />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                        <BabyNameViewAll />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        {/* <Route
                            path="/baby-names/uganda/:type"
                            element={
                                <>
                                    <BabyNameViewAllAfrica />
                                    <RoadBlock itemType="baby-names" />

                                   
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        /> */}
                        <Route
                            path="/baby-names/africa/:type"
                            element={
                                <>
                                    <BabyNameViewAllAfrica />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                        <BabyNameViewAll />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/kenya/:type"
                            element={
                                <>
                                    <BabyNameViewAllAfrica />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                        <BabyNameViewAll />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/nigeria/:type"
                            element={
                                <>
                                    <BabyNameViewAllAfrica />
                                    <RoadBlock itemType="baby-names" />
                                    {/* <AnimationContainer>
                                        <BabyNameViewAll />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/south-africa/:type"
                            element={
                                <>
                                    <BabyNameViewAllAfrica />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                        <BabyNameViewAll />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        {/* end */}
                        <Route
                            path="/:lng/baby-names"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BabyName />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/:type"
                            element={
                                <>
                                    <BabyNameViewAll />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                        <BabyNameViewAll />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/baby-names/:type"
                            element={
                                <>
                                    <BabyNameViewAll />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                            <BabyNameViewAll />
                                            <RoadBlock itemType="baby-names" />
                                        </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/:type/:subtype"
                            element={
                                <>
                                    <BabyNameViewAll />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                            <BabyNameViewAll />
                                            <RoadBlock itemType="baby-names" />
                                        </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/baby-names/:type/:subtype"
                            element={
                                <>
                                    <BabyNameViewAll />
                                    <RoadBlock itemType="baby-names" />

                                    {/* <AnimationContainer>
                                            <BabyNameViewAll />
                                            <RoadBlock itemType="baby-names" />
                                        </AnimationContainer> */}
                                    <TabFooter tab="baby-names" />
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetail />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/:lng/baby-names/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetail />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        {/* african country detail page */}
                        <Route
                            path="/baby-names/africa/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetailAfrica />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/ghana/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetailAfrica />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/south-africa/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetailAfrica />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        {/* <Route
                            path="/baby-names/uganda/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetailAfrica />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                </>
                            }
                        /> */}{' '}
                        <Route
                            path="/baby-names/kenya/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetailAfrica />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/baby-names/nigeria/detail/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <NameDetailAfrica />
                                        <RoadBlock itemType="baby-names" />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="baby-names" /> */}
                                </>
                            }
                        />
                        {/* end */}
                        <Route
                            path="/terms-conditions"
                            element={
                                <>
                                    <TermAndConditions />
                                </>
                            }
                        />
                        <Route
                            path="/parent-blog"
                            element={
                                <>
                                    <BlogPage />
                                    <RoadBlock itemType="listing" />
                                    {/* <PopUpAdHm /> */}

                                    {/* <AnimationContainer>
                                        <BlogPage />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="parent-blog" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-blog"
                            element={
                                <>
                                    <BlogPage />
                                    <RoadBlock itemType="listing" />

                                    {/* <AnimationContainer>
                                        <BlogPage />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="parent-blog" />
                                </>
                            }
                        />
                        <Route
                            path="/parent-blog/:blogId"
                            element={
                                <>
                                    <BlogPage />
                                    <RoadBlock itemType="listing" />

                                    {/* <AnimationContainer>
                                        <BlogPage />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="parent-blog" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-blog/:blogId"
                            element={
                                <>
                                    {/* <AnimationContainer> */}
                                    <BlogPage />
                                    <RoadBlock itemType="listing" />
                                    {/* </AnimationContainer> */}
                                    <TabFooter tab="parent-blog" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-blog/"
                            element={
                                <>
                                    <BlogPage />
                                    <RoadBlock itemType="listing" />

                                    {/* <AnimationContainer>
                                        <BlogPage />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer> */}
                                    <TabFooter tab="parent-blog" />
                                </>
                            }
                        />
                        <Route
                            path="/parent-blog/:title/:id"
                            element={
                                <>
                                    <BlogDetailsPage path={window.location.pathname} />
                                    <RoadBlock itemType="blog" />

                                    {/* <AnimationContainer>
                                        <BlogDetailsPage path={window.location.pathname} />
                                        <RoadBlock itemType="blog" />
                                    </AnimationContainer> */}
                                </>
                            }
                        />
                        <Route
                            path="/parent-blog/:title/:id/continue-reading"
                            element={
                                <>
                                    <BlogDetailsPage path={window.location.pathname} />
                                    <RoadBlock itemType="blog" />
                                    {/* <AnimationContainer>
                                        <BlogDetailsPage path={window.location.pathname} />
                                        <RoadBlock itemType="blog" />
                                    </AnimationContainer> */}
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-blog/:title/:id"
                            element={
                                <>
                                    <BlogDetailsPage path={window.location.pathname} />
                                    <RoadBlock itemType="blog" />

                                    {/* <AnimationContainer>
                                        <BlogDetailsPage path={window.location.pathname} />
                                        <RoadBlock itemType="blog" />
                                    </AnimationContainer> */}
                                </>
                            }
                        />
                        <Route
                            path="/breastfeeding-week"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BreastFeeding />
                                    </AnimationContainer>
                                    <TabFooter tab="livechats" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/breastfeeding-week"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BreastFeeding />
                                    </AnimationContainer>
                                    <TabFooter tab="livechats" />
                                </>
                            }
                        />
                        <Route
                            path="/livechats"
                            element={
                                <>
                                    <AnimationContainer>
                                        <LiveEventsListing />
                                        <RoadBlock itemType="listing" />
                                        {/* <PopUpAdHm /> */}
                                    </AnimationContainer>
                                    <TabFooter tab="livechats" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/livechats"
                            element={
                                <>
                                    <AnimationContainer>
                                        <LiveEventsListing />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="livechats" />
                                </>
                            }
                        />
                        <Route
                            path="/livechats/:filter"
                            element={
                                <>
                                    <AnimationContainer>
                                        <LiveEventsListing />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="livechats" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/livechats/:filter"
                            element={
                                <>
                                    <AnimationContainer>
                                        <LiveEventsListing />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="livechats" />
                                </>
                            }
                        />
                        <Route
                            path="/livechats/:title/:liveId"
                            element={
                                <>
                                    {/* <Event /> */}
                                    <LandingPage />
                                    <RoadBlock itemType="listing" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/livechats/:title/:liveId"
                            element={
                                <>
                                    {/* <Event /> */}
                                    <LandingPage />
                                    <RoadBlock itemType="listing" />
                                </>
                            }
                        />
                        <Route
                            path="/event-calendar"
                            element={
                                <>
                                    <AnimationContainer>
                                        <EventCalendar />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/event-calendar"
                            element={
                                <>
                                    <AnimationContainer>
                                        <EventCalendar />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/parent-blog/:title/:id/continue-reading"
                            element={
                                <>
                                    <AnimationContainer>
                                        <BlogDetailsPage path={window.location.pathname} />
                                        <RoadBlock itemType="blog" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <>
                                    <PlusPage />
                                    <TabFooter tab="" />
                                </>
                            }
                        />
                        <Route
                            path={'/PlanDetailpage/:id/:productType'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/PlanDetailpage/:id/:productType'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        {/* slot booking route */}
                        <Route
                            path={'/astrologer/expert/:id'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpageAstro />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/astrologer/expert/:id'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpageAstro />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/PlanDetailpage/register/:id/:productType'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <NonLogin />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/PlanDetailpage/register/:id/:productType'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <NonLogin />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/daily-focus"
                            element={
                                <ProtectedRoute>
                                    <DailyFocus />
                                    {/* <AnimationContainer>
                                        <DailyFocus />
                                    </AnimationContainer> */}
                                    <TabFooter tab="daily-focus" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/guest-daily-focus"
                            element={
                                <>
                                    <DailyFocus />
                                    {/* <AnimationContainer>
                                            <DailyFocus />
                                        </AnimationContainer> */}
                                    <TabFooter tab="daily-focus" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/daily-focus"
                            element={
                                <ProtectedRoute>
                                    <DailyFocus />

                                    {/* <AnimationContainer>
                                        <DailyFocus />
                                    </AnimationContainer> */}
                                    <TabFooter tab="daily-focus" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/subscribe/calendar"
                            element={
                                <>
                                    <AnimationContainer>
                                        {/* <CommingSoon /> */}
                                        <ShopCalendar />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/subscribe/calendar"
                            element={
                                <>
                                    <AnimationContainer>
                                        {/* <CommingSoon /> */}
                                        <ShopCalendar />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/calendar/:id'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpageCalender />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/calendar/:id'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpageCalender />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/subscribe"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Subcribe />
                                    </AnimationContainer>
                                    <TabFooter tab="plus" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/subscribe"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Subcribe />
                                    </AnimationContainer>
                                    <TabFooter tab="plus" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng"
                            element={
                                <>
                                    <PlusPage />

                                    <TabFooter tab="" />
                                </>
                            }
                        />
                        <Route
                            path="/referral"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Coupon />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/renew"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <RenewalPage />
                                        </AnimationContainer>
                                    </ProtectedRoute>

                                    {/* <TabFooter tab="" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/:lang/renew"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <RenewalPage />
                                        </AnimationContainer>
                                    </ProtectedRoute>

                                    {/* <TabFooter tab="" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/:lang/referral"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Coupon />
                                    </AnimationContainer>
                                    {/* <TabFooter tab="" /> */}
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/:interest"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner/:interest"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner"
                            element={
                                <>
                                    {/* <RoadBlock /> */}
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        {/* <Route
                            path="/login/:cookie"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Dummylogin />
                                    </AnimationContainer>
                                    {<TabFooter tab="" />}
                                </>
                                
                        */}
                        <Route
                            path="/:lng/expert-corner/interest"
                            element={
                                <>
                                    {/* <RoadBlock /> */}
                                    <AnimationContainer>
                                        <AskExperts />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                    <TabFooter tab="expert-corner" />
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner/detail/:id"
                            element={
                                <>
                                    {/* <RoadBlock /> */}
                                    <AnimationContainer>
                                        <AnswerDetail path={window.location.pathname} />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/detail/:id"
                            element={
                                <>
                                    {/* <RoadBlock /> */}
                                    <AnimationContainer>
                                        <AnswerDetail path={window.location.pathname} />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        {/* <Route
                            path="/expert-corner/:old-title/:id"
                            render={{(match) => <Navigate to="/expert-corner/detail/:id" />}}

                        /> */}
                        <Route
                            path="/expert-corner/:title/:id"
                            element={<RedirectToQuestionDetail />}
                        />
                        <Route
                            path="/:lng/expert-corner/:title/:id"
                            element={<RedirectToQuestionDetail />}
                        />
                        <Route
                            path="/expert-corner/askquestion"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <AskQuestionExpert />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/askquestion"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <AskQuestionExpert />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner/questionsasked"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <QuestionsAsked />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/questionsasked"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <QuestionsAsked />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/expert-corner/OurExpertFullView"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <OurExpertFullView />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/my-orders"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <MyOrderPage />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert-corner/OurExpertFullView"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <OurExpertFullView />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/users/appointments"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <Session />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/users/appointments"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <Session />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/agents/appointments"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <AgentSession />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/services/astrologer"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ProductListLandinPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/services/astrologer'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <ProductListLandinPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/services/astrologer/muslim"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ProductListLandinPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/services/astrologer/muslim'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <ProductListLandinPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/astrologer/session/:orderId"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <SessionDetails />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/astrologer/session/:orderId"
                            element={
                                <>
                                    <ProtectedRoute>
                                        <AnimationContainer>
                                            <SessionDetails />
                                        </AnimationContainer>
                                    </ProtectedRoute>
                                </>
                            }
                        />
                        <Route
                            path="/astrologer/intake/:orderId/:redirect"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ChatIntakeForm />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/astrologer/intake/:orderId/:redirect"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ChatIntakeForm />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/reports"
                            element={
                                <>
                                    <AnimationContainer>
                                        <GStudio />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        {/* expert-consultaiotn */}
                        <Route
                            path="/services/expert-consultation"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ExpertListLandinPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/services/mental-health-consultation"
                            element={
                                <>
                                    <AnimationContainer>
                                        <MentalHealthLandingPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/services/mental-health-consultation"
                            element={
                                <>
                                    <AnimationContainer>
                                        <MentalHealthLandingPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/services/expert-consultation'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <ExpertListLandinPage />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/consult/expert/:id'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpageExpert />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path={'/:lng/consult/expert/:id'}
                            element={
                                <>
                                    <AnimationContainer>
                                        <PlanDetailpageExpert />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/expert/session/:orderId"
                            element={
                                <>
                                    <AnimationContainer>
                                        <SessionDetails />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/expert/session/:orderId"
                            element={
                                <>
                                    <AnimationContainer>
                                        <SessionDetails />
                                    </AnimationContainer>
                                </>
                            }
                        />{' '}
                        <Route
                            path="/services/short-term-plan"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ShortTermPlan />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/services/short-term-plan"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ShortTermPlan />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/workshop/short-term-plan"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ShortTermPlanWorkShop />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/workshop/short-term-plan"
                            element={
                                <>
                                    <AnimationContainer>
                                        <ShortTermPlanWorkShop />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/sampling"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Sampling />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/about-us"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AboutUsNew />
                                    </AnimationContainer>
                                    <TabFooter tab="" />
                                </>
                            }
                        />
                        <Route
                            path="/about-us/gallery"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Gallery />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/about-us"
                            element={
                                <>
                                    <AnimationContainer>
                                        <AboutUsNew />
                                    </AnimationContainer>
                                    <TabFooter tab="" />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/about-us/gallery"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Gallery />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/stories/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Stories />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/stories/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <Stories />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/whats-new"
                            element={
                                <>
                                    <CommingSoon />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/whats-new"
                            element={
                                <>
                                    <CommingSoon />
                                </>
                            }
                        />
                        <Route
                            path="/how-to-use-parentune"
                            element={
                                <>
                                    <HowToUseParentune />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/how-to-use-parentune"
                            element={
                                <>
                                    <HowToUseParentune />
                                </>
                            }
                        />
                        <Route
                            path="/immunifyMe"
                            element={
                                <>
                                    <ImmunifyMe />
                                </>
                            }
                        />
                        <Route
                            path="/:lng/immunifyMe"
                            element={
                                <>
                                    <ImmunifyMe />
                                </>
                            }
                        />
                        <Route
                            path="/hospital"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/hospital"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/hospital"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/hospital"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreScoolHome />
                                        <RoadBlock itemType="listing" />
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:city/hospital/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/:lng/:city/hospital/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/hospital/:name/:id"
                            element={
                                <>
                                    <AnimationContainer>
                                        <PreSchoolDetail />
                                        {/* <RoadBlock itemType="preschool" /> */}
                                    </AnimationContainer>
                                </>
                            }
                        />
                        <Route
                            path="/choose-plan"
                            element={
                                <>
                                    <ChoosePlanPage />
                                </>
                            }
                        />
                        <Route
                            path="/choose-plan/:id"
                            element={
                                <>
                                    <ChoosePlanPageDetail />
                                </>
                            }
                        />
                        <Route
                            path="/force-logout/"
                            element={
                                <>
                                    <ForceLogout />
                                </>
                            }
                        />
                        <Route path="/unsubscribe" element={<Unsubscribe />} />
                        <Route path="/:lng/*" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </Router>
        </div>
    );
}

// function getDataLayerVariables() {
//     try {
//         const { userData: user_data, isLoggedIn, guestUid } = useUserData();

//         const ptPageParams = getPageInfo();

//         const ptUserParams = new Map();
//         ptUserParams.set('pt_userId', 0);
//         ptUserParams.set('pt_userLang', SelectedLanguage);
//         ptUserParams.set('pt_isPlusUser', 0);

//         const ptSessionParams = new Map();
//         ptSessionParams.set('pt_sessionId', getGuestUid(isLoggedIn));
//         ptSessionParams.set('pt_firstSession', 0);
//         ptSessionParams.set('pt_platform', 'web');

//         //let adPageInfo = new Array();

//         if (user_data?.userId) {
//             //
//             ptUserParams.set('pt_userId', user_data?.userId);
//         }

//         let userFields = ['pt_userId', 'pt_userLang', 'pt_isPlusUser'];
//         //let userFields = ['pt_userId', 'pt_userLang', 'pt_guestAgeGroups', 'pt_guestTopics', 'pt_userAgeGroups', 'pt_userTopics', 'pt_isPlusUser'];
//         let pageFields = [
//             'pt_page',
//             'pt_pageType',
//             'pt_itemId',
//             'pt_itemType',
//             'pt_pageLang',
//             'pt_pageCharset',
//             'pt_pageAgeGroups',
//             'pt_pageTopics',
//             'pt_pageCorners',
//             'pt_pageParam1',
//             'pt_pageParam2',
//             'pt_pageParam3'
//         ];
//         let sessionFields = [
//             'pt_sessionId',
//             'pt_firstSession',
//             'pt_utm_source',
//             'pt_utm_campaign',
//             'ptutm_content',
//             'pt_utm_medium',
//             'pt_utm_term',
//             'pt_platform'
//         ];
//         let zeroSupportedFields = ['pt_userId', 'pt_firstSession', 'pt_isPlusUser'];

//         const dataLayerVariables = new Map();
//         for (let i in userFields) {
//             if (ptUserParams.get(userFields[i])) {
//                 dataLayerVariables.set(userFields[i], ptUserParams.get(userFields[i]));
//             }
//         }

//         for (let i in pageFields) {
//             if (ptSessionParams.get(pageFields[i])) {
//                 dataLayerVariables.set(pageFields[i], ptSessionParams.get(pageFields[i]));
//             }
//         }

//         for (let i in sessionFields) {
//             if (ptSessionParams.get(sessionFields[i])) {
//                 dataLayerVariables.set(sessionFields[i], ptSessionParams.get(sessionFields[i]));
//             }
//         }

//         let paddedFields = [
//             'pt_guestAgeGroups',
//             'pt_guestTopics',
//             'pt_userAgeGroups',
//             'pt_userTopics',
//             'pt_pageAgeGroups',
//             'pt_pageTopics'
//         ];
//         for (let i in paddedFields) {
//             if (dataLayerVariables.get(paddedFields[i])) {
//                 dataLayerVariables.set(
//                     paddedFields[i],
//                     getPaddedCommaSeparatedIds(dataLayerVariables.get(paddedFields[i]))
//                 );
//             }
//         }

//         for (const [key, value] of dataLayerVariables.entries()) {
//             console.log('hmm', key, value);

//             // if(key in zeroSupportedFields){
//             //     //unset
//             //     dataLayerVariables.delete(key);
//             //     continue;
//             // }

//             let valTemp = value;
//             if (Array.isArray(value)) {
//                 if (key in paddedFields) {
//                     valTemp = getPaddedCommaSeparatedIds(value);
//                 } else {
//                     valTemp = implodeArray(value);
//                 }
//             }

//             dataLayerVariables.set(key, valTemp);
//         }

//         let obj = Object.fromEntries(dataLayerVariables);

//         return JSON.stringify(obj);
//     } catch (err) {
//         console.log('getDataLayerVariables:err', err);
//         return '';
//     }
// }

// function implodeArray(arrayInput: Array<any>) {
//     console.log('arrayInput', arrayInput);
//     return arrayInput.join(',');
// }

// function getPaddedCommaSeparatedIds(ids: Array<any>) {
//     return implodeArray(ids);
// }

// function PageTracking2() {
//     console.log('window.dataLayer');
//     let dataLayer = getDataLayerVariables();

//     console.log('dataLayer', dataLayer);
//     if (dataLayer == '') {
//         TagManager.dataLayer({
//             dataLayer: {
//                 pt_userId: '0',
//                 pt_userLang: 'en',
//                 pt_isPlusUser: '0'
//             }
//         });
//     } else {
//         TagManager.dataLayer({
//             dataLayer: {
//                 dataLayer
//             }
//         });
//     }
//     TagManager.dataLayer({
//         dataLayer: {
//             event: 'pageview'
//         }
//     });

//     return null;
// }

// function getPageInfo() {
//     const ptUserParams = new Map();

//     if (location?.pathname == 'parent-blog') {
//         ptUserParams.set('pt_page', 'blog-list');
//         ptUserParams.set('pt_pageType', 'list');
//         ptUserParams.set('pt_itemType', 'blog');
//         return ptUserParams;
//     } else if (location?.pathname?.includes('parent-blog')) {
//         ptUserParams.set('pt_page', 'blog-list');
//         ptUserParams.set('pt_pageType', 'details');
//         ptUserParams.set('pt_itemType', 'blog');
//         return ptUserParams;
//     } else if (location?.pathname == 'baby-names') {
//         ptUserParams.set('pt_page', 'baby-names');
//         ptUserParams.set('pt_pageType', 'list');
//         ptUserParams.set('pt_itemType', 'baby-names');
//         return ptUserParams;
//     } else if (location?.pathname?.includes('baby-names')) {
//         ptUserParams.set('pt_page', 'baby-names');
//         ptUserParams.set('pt_pageType', 'details');
//         ptUserParams.set('pt_itemType', 'baby-names');
//         return ptUserParams;
//     } else {
//         ptUserParams.set('pt_page', location?.pathname);
//         return ptUserParams;
//     }
// }

// function getGuestUid(isLoggedIn: boolean) {
//     const cookieName = 'ptgc';
//     const [cookies, setCookie] = useCookies([cookieName]);

//     let cookieValue = cookies['ptgc'];
//     let randomId = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     if (!cookieValue && !isLoggedIn) {
//         randomId = md5(
//             [...Array(12)].reduce((a) => a + randomId[~~(Math.random() * randomId.length)], '')
//         );

//         setCookie(cookieName, '' + randomId, { path: '/', maxAge: 86400 * 365 });
//         // console.log('g1', cookieValue);
//         return randomId;
//     } else {
//         return cookieValue;
//     }
// }

export default App;
