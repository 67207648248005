const TopHeader = () => {
    return (
        <div className="flex justify-between">
            <div className="flex gap-6 justify-between items-center bg-[#F4D64A]">
                <div>
                    <img
                        src="https://parntune-assets.b-cdn.net/images/about_us/child_img.jpg"
                        alt="hero-img"
                        className="rounded-tr-[8px] aspect-square object-cover"
                        width={135}
                        height={128}
                        loading="lazy"
                    />
                </div>
                <div className="flex-1">
                    <h2 className="text-[22px] text-black font-medium">{`Track your child's development`}</h2>
                </div>
            </div>
        </div>
    );
};

export default TopHeader;
