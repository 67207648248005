import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BreadCrumbs from '../V2Components/BreadCrumbs';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
import TextView from '../Button/TextView/TextView';
import SmallArrowRight from '../Icons/SmallArrowRight';
import Gutter from '../Gutter/Gutter';
import ButtonWithIcon from '../Button/ButtonWithIcon/ButtonWithIcon';
import GenderMale from '../Icons/genderMale';
import GenderFemale from '../Icons/genderFemale';
import TextViewSecond from '../Button/TextViewSecond/TextViewSecond';

//import { useUserData } from '../../utils/hooks/useUserData';
import { renderDynamicTitile } from './DynamicTitle';
import { changeMetaTag } from './MetaTag';
import { getPagesNewObj } from './ViewAllBreadCrumbs';
const Overlay = lazyWithRetry(() => import('../Overlay/Overlay'));
const ButtonSecondry1 = lazyWithRetry(() => import('../V2Components/ButtonSecondry/index1'));
const BabynamesLogin = lazyWithRetry(() => import('../Icons/BabynamesLogin'));
import { Buffer } from 'buffer';
import { useCookies } from 'react-cookie';
import BabyNameFilter from '../BabyNameFilter/BabyNameFilter';
//const BabyNameBottom1 = lazyWithRetry(() => import('../../pages/BabyNameBottom1/BabyNameBottom1'));
//const BabyNameBottom1 = lazyWithRetry(() => import('../../pages/BabyNameBottom1/BabyNameBottom1'));
import BabyNameBottom1 from '../../pages/BabyNameBottom1Africa/BabyNameBottom1';

import Header from '../Header/Header';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import { useUserData } from '../../utils/hooks/useUserData';
import { useOnboardingData } from '../../utils/hooks/useOnboardingData';
import { countryMapped } from '../../pages/BabyName/utils';
import Content from './Content';
const filter = 'https://img1.parentune.com/images/icons/filter.svg';
const SelectedLanguage = Geti18nLang();

const BabyNamesLoginPopUp = 'https://img1.parentune.com/images/creative_02.jpg';
const BabyNameViewAllAfrica = () => {
    //console.log('BabyNameViewAllAfrica');

    const { pathName } = useOnboardingData();

    //const { isLoggedIn, userData: user_data } = useUserData();
    //pushEventToGtm('page_view', { pt_page: 'BabyNameViewAllAfrica' });
    const [vis, setvis] = useState(false);
    const [guestCookies, setguestCookie, removeCookie] = useCookies();

    const [col, setcol] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [col1, setcol1] = useState('');
    const [charr, setcharr] = useState('');
    const [char2, setchar2] = useState('');
    const [btnId, setBtnId] = useState('');
    const [border, setborder] = useState('');
    const [btnId1, setBtnId1] = useState('');
    const [lang, setlang] = useState('en');
    const [btncol, setbtncol] = useState('#FE992F');
    const [url1, seturl1] = useState('/baby-names');
    const [startLength, setstartLength] = useState<any>('');
    const [endLength, setendLength] = useState<any>('');
    const [fixedLength, setfixedLength] = useState<any>('');
    const [gender, setGender] = useState('');
    const [religion, setreligion] = useState('');
    const [page, setPage] = useState<number>(1);
    const arrowLeftRow = useRef<HTMLSpanElement>(null);
    const [pageCount, setPageCount] = useState<any>(0);
    const [pageCountOnScroll, setPageCountOnScroll] = useState<any>(0);
    const [isFilters, setIsFilters] = useState(false);
    const [userScrolledPages, setUserScrolledPages] = useState(-1);
    const [showLoginRegister, setShowLoginRegister] = useState(false);
    const [currentPage, setCurrentPage] = useState('1');
    const [char, setchar] = useState<string>('');
    const [currentCountry, setCurrentCountry] = useState<any>(null);

    const { userData: user_data } = useUserData();
    const [bottomInterlinkingText, setBottomInterlinkingText] =
        useState<string>('Baby Names Start With ');
    const [bottomInterlinkingRedirectKey, setBottomInterlinkingRedirectKey] = useState<string>(
        SelectedLanguage === 'en'
            ? `/baby-names/starting-letter-`
            : `/${SelectedLanguage}/baby-names/starting-letter-`
    );
    const [share, setshare] = useState(false);

    const location = useLocation();
    const [scroll, setScroll] = useState<boolean>(false);
    const Filter = () => {
        setvis(true);
    };
    const [clearFilter, setClearFilter] = useState<boolean>(false);
    const filterclear = () => {
        let url: any =
            SelectedLanguage === 'en' ? '/baby-names/all' : `/${SelectedLanguage}/baby-names/all`;
        setClearFilter(!clearFilter);
        setBtnId('');
        setBtnId('');
        setBtnId1('');
        setreligion('');
        setGender('');
        setendLength('');
        setfixedLength('');
        setstartLength('');
        setchar2('');
        setcharr('');
        seturl1(url);
        navigate(url);
    };

    const Religion = ['Any', 'Hindu', 'Muslim', 'Christian', 'Sikh'];

    const HandleName = (e: string, isFilters = true) => {
        setcharr(e);
        setchar(e);
        setchar2(e);
        // setchar2(e);
        setIsFilters(isFilters);
    };

    useEffect(() => {
        const country = window.location.pathname.split('/')[2];

        // @ts-ignore
        // @ts-ignore

        setCurrentCountry(countryMapped[country]);

        // setCurrentCountry;
    }, [pathName]);

    useEffect(() => {
        if (gender && !religion) {
            setBottomInterlinkingText(`${gender}` + ' Baby Names Start With ');
        } else if (religion && !gender) {
            setBottomInterlinkingText(`${religion}` + ' Baby Names Start With ');
        } else if (religion && gender) {
            setBottomInterlinkingText(`${religion}` + ' ' + `${gender}` + ' Names Start With ');
        } else {
            setBottomInterlinkingText('Baby Names Start With ');
        }
    }, [gender, religion]);

    useEffect(() => {
        if (!isFilters) return;

        // todo enable it later

        return;

        if (gender && !religion) {
            seturl1(
                SelectedLanguage === 'en'
                    ? `/baby-names/${gender}/starting-with-${char}`
                    : `/${SelectedLanguage}/baby-names/${gender}/starting-with-${char}`
            );
            navigate(
                SelectedLanguage === 'en'
                    ? `/baby-names/${gender}/starting-with-${char}`
                    : `/${SelectedLanguage}/baby-names/${gender}/starting-with-${char}`
            );
            setBottomInterlinkingRedirectKey(
                SelectedLanguage === 'en'
                    ? `/baby-names/${gender}/starting-with-`
                    : `/${SelectedLanguage}/baby-names/${gender}/starting-with-`
            );
        } else if (religion && !gender) {
            seturl1(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}-names-starting-with-${char}`
                    : `/${SelectedLanguage}/baby-names/${religion}-names-starting-with-${char}`
            );
            navigate(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}-names-starting-with-${char}`
                    : `/${SelectedLanguage}/baby-names/${religion}-names-starting-with-${char}`
            );
            setBottomInterlinkingRedirectKey(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}-names-starting-with-`
                    : `/${SelectedLanguage}/baby-names/${religion}-names-starting-with-`
            );
        } else if (religion && gender && !char) {
            seturl1(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}/${gender}-names`
                    : `/${SelectedLanguage}/baby-names/${religion}/${gender}-names`
            );
            navigate(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}/${gender}-names`
                    : `/${SelectedLanguage}/baby-names/${religion}/${gender}-names`
            );
        } else if (religion && gender && char) {
            seturl1(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}/${gender}-names-starting-with-${char}`
                    : `/${SelectedLanguage}/baby-names/${religion}/${gender}-names-starting-with-${char}`
            );
            navigate(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}/${gender}-names-starting-with-${char}`
                    : `/${SelectedLanguage}/baby-names/${religion}/${gender}-names-starting-with-${char}`
            );
            setBottomInterlinkingRedirectKey(
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}/${gender}-names-starting-with-`
                    : `/${SelectedLanguage}/baby-names/${religion}/${gender}-names-starting-with-`
            );
        } else {
            seturl1(
                SelectedLanguage === 'en'
                    ? `/baby-names/starting-letter-${char}`
                    : `/${SelectedLanguage}/baby-names/starting-letter-${char}`
            );
            navigate(
                SelectedLanguage === 'en'
                    ? `/baby-names/starting-letter-${char}`
                    : `/${SelectedLanguage}/baby-names/starting-letter-${char}`
            );
            setBottomInterlinkingRedirectKey(
                SelectedLanguage === 'en'
                    ? `/baby-names/starting-letter-`
                    : `/${SelectedLanguage}/baby-names/starting-letter-`
            );
        }
    }, [isFilters, char, gender, religion]);
    const FinterBack = () => {
        setvis(!vis);
        setcol1('');
    };
    const navigate = useNavigate();
    const characters = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
    ];
    //const paginationCounter = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

    const params = useParams();
    const { type } = useParams();
    console.log('type', type);
    useEffect(() => {
        if (sessionStorage.getItem('userOnMobile') == 'true') return;

        if (!user_data) {
            if (sessionStorage.getItem('_bnlp')) {
                setShowLoginRegister(true); //make it true to enable login
            }
            if (userScrolledPages === 4) {
                setShowLoginRegister(true); //make it true to enable login
                sessionStorage.setItem('_bnlp', '1');
            } else {
                setUserScrolledPages(userScrolledPages + 1);
            }
        }
        if (user_data && user_data?.profileComplete < 40) {
            setShowLoginRegister(true);
        }
    }, [location.search]);

    useEffect(() => {
        const { fixedLength, startLength, endLength }: any = (location && location.state) || {};
        setfixedLength(fixedLength);
        setstartLength(startLength);
        setendLength(endLength);
    }, []);

    function getUrl(url: string) {
        if (religion && gender && !char2) {
            url =
                SelectedLanguage === 'en'
                    ? `/baby-names/${religion}/${gender}-names`
                    : `${url}/${religion}/${gender}-names`;
            seturl1(url);
            navigate(url);
        } else if (gender) {
            url = SelectedLanguage === 'en' ? `/baby-names/${gender}` : `${url}/${gender}`;
        } else if (religion) {
            url = SelectedLanguage === 'en' ? `/baby-names/${religion}` : `${url}/${religion}`;
        }

        if (char2 != '') {
            url = SelectedLanguage === 'en' ? '/baby-names' : `/${SelectedLanguage}/baby-names`;
            if (!religion && !gender) {
                url = `${url}/starting-letter-${char2}`;
            } else if (religion && !gender) {
                url = `${url}/${religion}-names-starting-with-${char2}`;
            } else if (!religion && gender) {
                url = `${url}/${gender}/starting-with-${char2}`;
            } else if (religion && gender) {
                url = `${url}/${religion}/${gender}-names-starting-with-${char2}`;
            }
        }

        seturl1(url);
        if (religion || gender || char2) {
            navigate(url);
        }
        // return SelectedLanguage === 'en' ? '/baby-names/all' : `/${SelectedLanguage}/baby-names/all`;
    }

    useEffect(() => {
        let url = SelectedLanguage === 'en' ? '/baby-names' : `/${SelectedLanguage}/baby-names`;
        if (!vis) {
            getUrl(url);
        }
    }, [vis]);

    useEffect(() => {
        if (!['starting-letter', 'names-starting-with', 'all'].some((str) => type?.includes(str))) {
            // navigate('/baby-names');
        }

        if (params.type === 'all') {
            if (params.lng) {
                setlang(params.lng);
            }
            return;
        }
        if (params) {
            const routes: any = [...Object.values(params)];
            if (routes.length === 1) {
                const type: any = routes[0];
                if (religionTypes.join('').includes(type)) {
                    setreligion(type);
                    setBtnId1(type);
                } else if (type.includes('starting-letter')) {
                    setchar(type.split('-')[2]);
                    setcharr(type.split('-')[2]);
                    setchar2(type.split('-')[2]);
                } else if (type.includes('names-starting-with')) {
                    setreligion(type.split('-')[0]);
                    setBtnId1(type.split('-')[0]);
                    setchar(type.split('-')[4]);
                    setcharr(type.split('-')[4]);
                    setchar2(type.split('-')[4]);
                } else {
                    setGender(type.split('-')[0]);
                    setBtnId(type.split('-')[0]);
                }
            } else if (routes.length === 2) {
                if (params.lng) {
                    setlang(params.lng);
                    const type: any = routes[1];
                    if (religionTypes.join('').includes(type)) {
                        setreligion(type);
                        setBtnId1(type);
                    } else if (genderTypes.join('').includes(type)) {
                        setGender(type);
                        setBtnId(type);
                    } else if (type.includes('names-starting-with')) {
                        let name: string = type.split('-')[0];
                        if (religionTypes.join('').includes(name)) {
                            setreligion(name);
                            setBtnId1(name);
                        } else {
                            setGender(name);
                            setBtnId(name);
                        }
                        setchar(type.split('-')[4]);
                        setcharr(type.split('-')[4]);
                        setchar2(type.split('-')[4]);
                    } else if (type.includes('starting-letter')) {
                        setchar(type.split('-')[2]);
                        setcharr(type.split('-')[2]);
                        setchar2(type.split('-')[2]);
                    }
                    return;
                }
                const type: any = routes[0];
                const subtype: any = routes[1];

                if (religionTypes.join('').includes(type)) {
                    if (subtype.includes('names-starting-with')) {
                        setreligion(type);
                        setBtnId1(type);
                        setGender(subtype.split('-')[0]);
                        setBtnId(subtype.split('-')[0]);
                        setchar(subtype.split('-')[4]);
                        setcharr(subtype.split('-')[4]);
                        setchar2(subtype.split('-')[4]);
                    } else {
                        setreligion(type);
                        setBtnId1(type);
                        setGender(subtype.split('-')[0]);
                        setBtnId(subtype.split('-')[0]);
                    }
                } else if (genderTypes.join('').includes(type)) {
                    setGender(type);
                    setBtnId(type);
                    setchar(subtype.split('-')[2]);
                    setcharr(subtype.split('-')[2]);
                    setchar2(subtype.split('-')[2]);
                }
            } else if (routes.length === 3) {
                const routeName: any = routes[2];
                if (routeName.includes('names-starting-with')) {
                    setlang(routes[0] ? routes[0] : '');
                    setreligion(religionTypes?.includes(routes[1]) ? routes[1] : '');
                    setBtnId1(routes[1] ? routes[1] : '');

                    setGender(routes[2] ? routes[2].split('-')[0] : '');
                    setBtnId(routes[2] ? routes[2].split('-')[0] : '');
                    setchar(routes[2] ? routes[2].split('-')[4] : '');
                    setcharr(routes[2] ? routes[2].split('-')[4] : '');
                    setchar2(routes[2] ? routes[2].split('-')[4] : '');
                } else if (routeName.includes('starting-letter')) {
                    const routeName1: any = routes[1];
                    setlang(routes[0] ? routes[0] : '');
                    if (genderTypes.join('').includes(routeName1)) {
                        setGender(routes[1] ? routes[1] : '');
                        setBtnId(routes[1] ? routes[1] : '');
                        setreligion('');
                        setBtnId1('');
                    } else {
                        setreligion(routes[1] ? routes[1] : '');
                        setBtnId1(routes[1] ? routes[1] : '');
                        setGender('');
                        setBtnId('');
                    }
                    setchar(routes[2] ? routes[2].split('-')[2] : '');
                    setcharr(routes[2] ? routes[2].split('-')[2] : '');
                    setchar2(routes[2] ? routes[2].split('-')[2] : '');
                } else if (routeName.includes('starting-with')) {
                    const routeName1: any = routes[1];
                    setlang(routes[0] ? routes[0] : '');
                    if (genderTypes.join('').includes(routeName1)) {
                        setGender(routes[1] ? routes[1] : '');
                        setBtnId(routes[1] ? routes[1] : '');
                        setreligion('');
                        setBtnId1('');
                    } else {
                        setreligion(routes[1] ? routes[1] : '');
                        setBtnId1(routes[1] ? routes[1] : '');
                        setGender('');
                        setBtnId('');
                    }
                    setchar(routes[2] ? routes[2].split('-')[2] : '');
                    setcharr(routes[2] ? routes[2].split('-')[2] : '');
                    setchar2(routes[2] ? routes[2].split('-')[2] : '');
                } else {
                    setlang(routes[0] ? routes[0] : '');
                    setreligion(routes[1] ? routes[1] : '');
                    setBtnId1(routes[1] ? routes[1] : '');
                    setGender(routes[2] ? routes[2].split('-')[0] : '');
                    setBtnId(routes[2] ? routes[2].split('-')[0] : '');
                    setchar('');
                    setcharr('');
                    setchar2('');
                }
            }
        }
    }, [params]);

    const SliderRef = useRef<HTMLDivElement>(null);
    const scrollFront = () => {
        if (SliderRef.current) SliderRef.current.scrollLeft += 100;
    };

    const scrollBack = () => {
        if (SliderRef.current) SliderRef.current.scrollLeft -= 100;
    };
    const [touchX, setTouchX] = useState([0, 0]);

    const touchXRef = useRef(touchX);

    const updatetouchX = (x: any) => {
        touchXRef.current = x;
        setTouchX(x);
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) =>
        updatetouchX([event.changedTouches[0].clientX, event.timeStamp]);

    const handleTouchMove = (event: TouchEvent) => {
        event.preventDefault();
        if (event.changedTouches[0].clientX > touchXRef.current[0]) {
            if (SliderRef.current)
                SliderRef.current.scrollLeft -=
                    (10 * (event.changedTouches[0].clientX - touchXRef.current[0])) /
                    (event.timeStamp - touchXRef.current[1]);
            updatetouchX([event.changedTouches[0].clientX, event.timeStamp]);
        } else if (event.changedTouches[0].clientX < touchXRef.current[0]) {
            if (SliderRef.current)
                SliderRef.current.scrollLeft -=
                    (10 * (event.changedTouches[0].clientX - touchXRef.current[0])) /
                    (event.timeStamp - touchXRef.current[1]);
            updatetouchX([event.changedTouches[0].clientX, event.timeStamp]);
        }
    };

    const scrollVIew: any = () => {
        if (SliderRef.current !== null) {
            return window.scrollTo(0, SliderRef.current.offsetTop - 35);
        } else {
            return window.scrollTo(0, 250);
        }
    };

    useEffect(() => {
        // SliderRef.current && SliderRef.current.addEventListener('touchmove', handleTouchMove);
    }, []);

    // const filterItem = (item: string) => {
    //     return !['hi', '', 'te', 'ta', 'mr', 'en'].includes(item);
    // };

    // const getHref = (index: number) => {
    //     const pathList = location.pathname.split('/').filter(filterItem);
    //     let hrefNew = SelectedLanguage === 'en' ? '/' : '/' + SelectedLanguage + '/';
    //     for (let i = 0; i <= pathList.length; i++) {
    //         if (i <= index) {
    //             if (pathList[i - 1]) {
    //                 hrefNew = `${hrefNew}${pathList[i - 1]}/`;
    //             }
    //         }
    //     }
    //     return hrefNew;
    // };

    // const getNameForBreadcrumb = (path: string, type = null) => {
    //     if (type) {
    //         return t('page_title_alphabet').replaceAll('{char}', path);
    //     } else if (path.includes('starting-with')) {
    //         let char: any = path.split('-').pop();
    //         return t('page_title_alphabet').replaceAll('{char}', char);
    //     } else if (path.includes('starting-letter')) {
    //         let char: any = path.split('-').pop();
    //         return t('page_title_alphabet').replaceAll('{char}', char);
    //     } else if (path.split('-').includes(gender)) {
    //         return t('br_gender').replaceAll('{gender}', t(gender));
    //     } else if (religionTypes.includes(path)) {
    //         return SelectedLanguage === 'en'
    //             ? t('br_title_origin').replaceAll('{religion}', t(religion))
    //             : t('page_title_origin').replaceAll('{religion}', t(religion));
    //     }
    //     return t(path.replaceAll('-', ' '));
    // };

    const pages = getPagesNewObj({ gender, religion, religionTypes });
    //const currentURL = window.location.href;
    //const redirect = Buffer.from(currentURL).toString('base64');
    //console.log('redirect', redirect)
    // useEffect(() => {
    //     if (scroll) {
    //         scrollVIew();
    //         return setScroll(false);
    //     } else;
    // });
    return (
        <>
            {showLoginRegister && (
                <Overlay>
                    <div className="h-full fixed top-0 backdrop-blur-sm w-screen flex justify-center items-center ">
                        <div className="bg-white text-black m-[10px] rounded-md overflow-hidden md:max-w-[40%]">
                            {/* <BabynamesLogin /> */}
                            <div className="flex justify-center items-center">
                                <img src={BabyNamesLoginPopUp} className="" />
                            </div>
                            <div className="p-[19px]">
                                <h2 className="text-lg font-roboto">
                                    {t('Choose a Unique name for your Baby')}
                                </h2>

                                <ButtonSecondry1
                                    onClick={() => {
                                        //redirect bash code is harded
                                        navigate('/register', {
                                            replace: true,
                                            state: { from: location }
                                        });
                                    }}
                                    className="mt-[30px] text-[19px] bg-[#367e8a] text-white border-none"
                                    text={
                                        user_data?.profileComplete < 40
                                            ? 'Complete your Profile'
                                            : 'SIGN IN'
                                    }
                                    customStyle={{ padding: '10px 5px' }}
                                />
                            </div>
                        </div>
                    </div>
                </Overlay>
            )}
            {changeMetaTag(pageCount, pageCountOnScroll)}
            {/* <section className="mt-1 p-2  bg-white">
                <ImageCarouselForBabyNames
                    imagesArray={[
                        {
                            url: 'https://img1.parentune.com/temp/baby-name/1.jpg'
                        },
                        {
                            url: 'https://img1.parentune.com/temp/baby-name/2.jpg'
                        },
                        {
                            url: 'https://img1.parentune.com/temp/baby-name/3.jpg'
                        },
                        {
                            url: 'https://img1.parentune.com/temp/baby-name/4.jpg'
                        }
                    ]}
                />
            </section> */}
            <div className="flex items-center pl-2 pr-4 xl:pl-0 xl:pr-0 max-w-[1000px] mx-auto">
                <div className="flex-1">
                    {/* hiiii todo */}
                    <BreadCrumbs pages={pages} />
                </div>

                <div
                    onClick={Filter}
                    className="flex-initial w-6 cursor-pointer hover:color-red-500"
                >
                    <img
                        className=""
                        alt="filter"
                        src={filter}
                        style={{ width: '25px', height: '50px' }}
                    ></img>
                </div>
            </div>
            <div
                className="pb-[60px]"
                style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}
            >
                <div className="pl-3 pr-3">
                    <div className="flex-1 mt-5">
                        {/* {JSON.stringify(Content.SEO.BABY_NAMES.MAIN_HEADING.BOY.HOME)} */}
                        {renderDynamicTitile(char2, religion, gender, currentCountry)}
                        {/* <h1 className="text-xl font-medium find-name-sub-heading">{h1Tag}</h1> */}
                        {/* <p
                            className="a-tag text-base mt-1 font-normal find-name-para"
                            dangerouslySetInnerHTML={{
                                __html: `We offer you modern, yet traditional 50,000+ trending baby names with their meanings that will help you pick the best option of all while deciding on the perfect name that will give your child a distinct identity . These names have been compiled after a lot of research to make it a unique experience for you . An alphabetic listing of names along with their meanings has been made which will not only make your search easier but simpler too.`
                            }}></p> */}
                    </div>
                </div>
                <div
                    onClick={() => {
                        share && setshare(false), share && setcol('');
                    }}
                    style={{
                        backgroundColor: `${col1} ${col}`,
                        // maxWidth: '1000px',
                        marginLeft: '16px',
                        marginRight: '16px',
                        marginTop: '20px'
                    }}
                >
                    <div className="pt-5">
                        <div className="flex items-center">
                            <div onClick={scrollBack} className="bg-white cursor-pointer pr-2">
                                {/* <IoIosArrowBack className=" h-8 w-8" /> */}
                                <span className="rotateSVG" ref={arrowLeftRow}>
                                    <SmallArrowRight />
                                </span>
                            </div>
                            <div
                                onTouchStart={handleTouchStart}
                                ref={SliderRef}
                                className="flex overflow-hidden overflow-x-scroll scrollbar-hide scroll-smooth"
                            >
                                {characters.map((ch) => {
                                    return (
                                        <div key={`characters${characters.indexOf(ch)}`}>
                                            <div
                                                onClick={() => HandleName(ch)}
                                                className={`flex items-center justify-center  min-w-[31px] min-h-[31px] rounded-full uppercase ${
                                                    charr &&
                                                    charr[0] === ch &&
                                                    `bg-[#FB7882] text-white`
                                                }`}
                                            >
                                                {ch}
                                            </div>

                                            {/* <TextView
                                                customStyle={
                                                    charr && charr[0] === ch
                                                        ? {
                                                              opacity: '1'
                                                          }
                                                        : {}
                                                }
                                                className={border}
                                                onClick={() => HandleName(ch)}
                                                text={ch}
                                                size={'small'}
                                            /> */}
                                        </div>
                                    );
                                })}
                            </div>
                            <div onClick={scrollFront} className="bg-white  cursor-pointer p-2">
                                <span>
                                    <SmallArrowRight />
                                </span>
                            </div>
                        </div>
                        <Gutter
                            styles={{
                                borderBottom: '1px solid #DADADA',
                                paddingBottom: '8px',
                                marginBottom: '20px'
                            }}
                        />
                        {!vis && (
                            <BabyNameBottom1
                                setScroll={setScroll}
                                fixedLength={fixedLength}
                                endLength={endLength}
                                startLength={startLength}
                                gender={gender}
                                religion={religion}
                                lang={lang}
                                setShare={setshare}
                                setCol={setcol}
                                setBtnCol={setbtncol}
                                share={share}
                                Name={charr?.length > 0 ? charr[0] : ''}
                                setPage={setPage}
                                page={page}
                                size={15}
                                url={url1}
                                setBorder={setborder}
                                keyWord={char2}
                                setPageCount={setPageCount}
                                setPageCountOnScroll={setPageCountOnScroll}
                                country={
                                    currentCountry == 'South Africa'
                                        ? 'southAfrica'
                                        : currentCountry
                                }
                            />
                        )}
                        {/* <h3 style={{ padding: '1.4rem', fontWeight: 'bold' }}>
                            Explore Baby Names With Letter
                        </h3>
                        <div
                            onTouchStart={handleTouchStart}
                            ref={SliderRef}
                            className="flex overflow-hidden scrollbar-hide scroll-smooth flex-wrap text-[#377e8b] font-semibold"
                         >
                            {characters.map((ch) => {
                                return (
                                    <div key={`characters${characters.indexOf(ch)}`} className="">
                                        <a href={`${bottomInterlinkingRedirectKey}` + ch}>
                                            <TextView
                                                customStyle={
                                                    charr && charr[0] === ch
                                                        ? {
                                                              opacity: '1',
                                                              color: '#377e8b',
                                                              fontWeight: '500',
                                                              fontFamily: 'Roboto',
                                                              fontStyle: 'normal',
                                                              fontSize: '16px',
                                                              lineHeight: '19px',
                                                              textTransform: 'capitalize'
                                                          }
                                                        : {
                                                              opacity: '1',
                                                              color: 'black',
                                                              fontFamily: 'Roboto',
                                                              fontStyle: 'normal',
                                                              fontSize: '16px',
                                                              lineHeight: '19px',
                                                              textTransform: 'capitalize'
                                                          }
                                                }
                                                className={border}
                                                onClick={() => HandleName(ch)}
                                                text={bottomInterlinkingText + ch}
                                                size={'small'}
                                            />
                                        </a>
                                    </div>
                                );
                            })}
                        </div> */}
                    </div>

                    <Transition appear show={vis} as={Fragment}>
                        <Dialog
                            onClose={() => setvis(false)}
                            as="div"
                            className="fixed  m-auto z-50 inset-0  overflow-y-auto mx-auto md:overflow-y-visible md:w-2/5 md:h-5/6 lg:w-2/5 lg:h-5/6"
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed bg-black bg-opacity-30 inset-0 " />
                            </Transition.Child>
                            <span
                                className="inline-block h-screen align-middle bg-slate-500"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="sticky bottom-0 mt-48 md:mt-auto md:fixed md:top-10 md:left-1/3 md:right-1/3 md:bottom-10">
                                    <div className="bg-white border rounded-t-3xl md:h-full md:left-1/3 md:right-1/3 md:overflow-y-auto md:rounded-3xl  ">
                                        <div className="flex justify-between pl-5 pb-5 pr-5 pt-5 rounded-t-3xl items-center sticky top-0 md-static bg-white">
                                            <Header
                                                back
                                                onBack={FinterBack}
                                                textType="p"
                                                headerText={t('Filter')}
                                            />{' '}
                                            <span
                                                onClick={() => filterclear()}
                                                className="text-blue-400 cursor-pointer para-5"
                                            >
                                                {t('Clear Filter')}
                                            </span>
                                        </div>
                                        <div className="pl-5 pr-5 pt-5">
                                            <h2 className="text-lg font-medium mt-3 mb-2 heading-2 ">
                                                {t('Gender')}
                                            </h2>
                                            <div className="flex flex-wrap">
                                                <ButtonWithIcon
                                                    customStyleMain={
                                                        btnId === 'boy'
                                                            ? { background: '#fb7882' }
                                                            : {}
                                                    }
                                                    customStyleText={
                                                        btnId === 'boy' ? { color: '#ffffff' } : {}
                                                    }
                                                    startIcon={
                                                        btnId === 'boy' ? (
                                                            <GenderMale color="#ffffff" />
                                                        ) : (
                                                            <GenderMale />
                                                        )
                                                    }
                                                    text={t('Boy')}
                                                    size="small"
                                                    className="mr-2 bg-slate-200"
                                                    onClick={() => {
                                                        setGender('boy');
                                                        setBtnId('boy');
                                                    }}
                                                />
                                                <ButtonWithIcon
                                                    customStyleMain={
                                                        btnId === 'girl'
                                                            ? { background: '#fb7882' }
                                                            : {}
                                                    }
                                                    customStyleText={
                                                        btnId === 'girl' ? { color: '#ffffff' } : {}
                                                    }
                                                    startIcon={
                                                        btnId === 'girl' ? (
                                                            <GenderFemale color="#ffffff" />
                                                        ) : (
                                                            <GenderFemale />
                                                        )
                                                    }
                                                    text={t('Girl')}
                                                    size="small"
                                                    className="mr-2 bg-slate-200"
                                                    onClick={() => {
                                                        setGender('girl');
                                                        setBtnId('girl');
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="pl-5 pr-5 pt-5">
                                            <h2 className="text-lg font-medium mt-3 mb-2 heading-2">
                                                {t('Religion')}
                                            </h2>
                                            <div className="flex flex-wrap">
                                                {/* <ButtonWithIcon
                                                    customStyleMain={
                                                        btnId1 === 'hindu'
                                                            ? { background: '#fb7882' }
                                                            : {}
                                                    }
                                                    customStyleText={
                                                        btnId1 === 'hindu'
                                                            ? { color: '#ffffff' }
                                                            : {}
                                                    }
                                                    text={t('Hindu')}
                                                    size="small"
                                                    className="mr-2 mt-1 bg-slate-200"
                                                    onClick={() => {
                                                        setreligion('hindu');
                                                        setBtnId1('hindu');
                                                    }}
                                                /> */}
                                                <ButtonWithIcon
                                                    customStyleMain={
                                                        btnId1 === 'muslim'
                                                            ? { background: '#fb7882' }
                                                            : {}
                                                    }
                                                    customStyleText={
                                                        btnId1 === 'muslim'
                                                            ? { color: '#ffffff' }
                                                            : {}
                                                    }
                                                    text={t('Muslim')}
                                                    size="small"
                                                    onClick={() => {
                                                        setreligion('muslim');
                                                        setBtnId1('muslim');
                                                    }}
                                                    className="mr-2 mt-1 bg-slate-200"
                                                />
                                                <ButtonWithIcon
                                                    customStyleMain={
                                                        btnId1 === 'christian'
                                                            ? { background: '#fb7882' }
                                                            : {}
                                                    }
                                                    customStyleText={
                                                        btnId1 === 'christian'
                                                            ? { color: '#ffffff' }
                                                            : {}
                                                    }
                                                    text={t('Christian')}
                                                    size="small"
                                                    className="mr-2 mt-1 bg-slate-200"
                                                    onClick={() => {
                                                        setreligion('christian');
                                                        setBtnId1('christian');
                                                    }}
                                                />
                                                {/* <ButtonWithIcon
                                                    customStyleMain={
                                                        btnId1 === 'sikh'
                                                            ? { background: '#fb7882' }
                                                            : {}
                                                    }
                                                    customStyleText={
                                                        btnId1 === 'sikh'
                                                            ? { color: '#ffffff' }
                                                            : {}
                                                    }
                                                    text={t('Sikh')}
                                                    onClick={() => {
                                                        setreligion('sikh');
                                                        setBtnId1('sikh');
                                                    }}
                                                    size="small"
                                                    className="mr-2 mt-1 bg-slate-200"
                                                /> */}
                                            </div>
                                        </div>
                                        <div className="pl-5 pr-5 pt-5">
                                            <h3 className="text-lg font-medium mt-3 mb-2 heading-2">
                                                {t('Starts With')}
                                            </h3>
                                            <div className="flex flex-wrap items-center justify-left gap-6">
                                                {characters.map((ch, index) => {
                                                    return (
                                                        <TextViewSecond
                                                            customStyle={
                                                                charr?.length === 2 &&
                                                                charr[0] === ch
                                                                    ? {
                                                                          background: '#F18A1C',
                                                                          borderRadius: '2.19048px',
                                                                          color: '#FFFFFF'
                                                                      }
                                                                    : charr === ch
                                                                    ? {
                                                                          background: '#F18A1C',
                                                                          borderRadius: '2.19048px',
                                                                          color: '#FFFFFF'
                                                                      }
                                                                    : {}
                                                            }
                                                            key={index}
                                                            onClick={() => HandleName(ch, false)}
                                                            text={ch}
                                                            size={'small'}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <BabyNameFilter
                                                two={false}
                                                setstartLength={setstartLength}
                                                setfixedLength={setfixedLength}
                                                setendLength={setendLength}
                                                handleclick={FinterBack}
                                                fixedLength={fixedLength}
                                                endLength={endLength}
                                                startLength={startLength}
                                                setchar2={setchar2}
                                                charr={charr?.length > 0 ? charr[0] : ''}
                                                char2={char2?.length > 0 ? char2 : ''}
                                                clearFilter={clearFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </Dialog>
                    </Transition>
                </div>
            </div>
        </>
    );
};

const religionTypes = ['muslim', 'hindu', 'jain', 'christian', 'sikh'];

const genderTypes = ['boy', 'girl'];

export default BabyNameViewAllAfrica;
