import axios from 'axios';

export function getPostCardData(
    params: {
        itemType: string;
        id?: number | string;
        user_data: any;
        language: string;
    },
    onSuccess: (arg0: any) => void,
    onFailure: (arg0: any) => any
) {
    const { user_data } = params;
    axios({
        method: 'get',
        url: process.env.REACT_APP_CONTENT_SERVER + 'content/content-stats',
        params: {
            itemType: params.itemType,
            itemId: params.id
        },
        headers: {
            lang: params.language,
            userId: user_data?.userId?.toString() || '',
            instanceId: user_data?.instanceId?.toString() || '',
            authToken: user_data?.authToken || ''
        }
    })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}
