import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { SendEvents } from '../../utils/utils';
import { useUserData } from '../../utils/hooks/useUserData';

const PaymentSuccessFull = 'https://img1.parentune.com/web/assests_v2/PaymentSuccessFull.webp';
const PaymentFailed = 'https://img1.parentune.com/web/assests_v2/PaymentFailed.webp';
const AskDocNestleSuccess = (props: any) => {
    const { t } = useTranslation();
    const { id, url, success } = props;
    console.log(props);
    // const [fail, setFail] = useState(!success);
    const Screenheight = innerHeight;
    const navigate = useNavigate();
    const { userData: user_data, isLoggedIn } = useUserData();

    const closeHandler = () => {
        SendEvents(
            user_data?.userId,
            'btn-click',
            url.includes('livechats')
                ? 'btn_back_sucess_page_workshop'
                : 'btn_back_sucess_page_question'
        );

        const newUrl = url.includes('livechats') ? '/livechats#PlusListing' : '/expert-corner';
        navigate(newUrl);
        url.includes('livechats') ? null : window.location.reload();
    };

    const continueHandler = () => {
        SendEvents(
            user_data?.userId,
            'btn-click',
            url.includes('livechats')
                ? 'btn_sucess_page_watch_now_pii'
                : 'btn_sucess_page_ask_now_pii'
        );
        navigate(url);
        url.includes('livechats') ? null : window.location.reload();
    };
    // const nextHandler = () => {
    //     window.location.replace('https://parentuneplus.page.link/register');
    // };
    // console.log(1);
    return (
        <>
            <div className="px-4 site-spacing" style={{ height: Screenheight - 60 }}>
                <h1 className=" font-proxima flex items-center" onClick={closeHandler}>
                    <MdClose className="text-[35px] text-[#787878]" />
                    <span className="text-[21px] font-[700] ml-[27px]"> Order Summary</span>
                </h1>
                <div className="flex flex-col justify-between items-center  mt-[46px]">
                    <div>
                        <h1 className="text-[18px] leading-[21px] font-[500] font-roboto capitalize flex items-center">
                            <img
                                src={!success ? PaymentFailed : PaymentSuccessFull}
                                alt=""
                                className="w-[18px] h-[18px]"
                                loading="lazy"
                            />

                            <span className="ml-[11px]">
                                {!success ? t('Payment_failed') : 'Congratulations!'}
                            </span>
                        </h1>
                    </div>

                    <p className="text-[#787878] text-[1rem] leading-[26px] mb-[24px] mt-4 text-center mx-5">
                        {/* {t('Transaction_ID')}: {id} */}
                        {url.includes('livechats')
                            ? 'You have earned a Parenting Course Credit. You can watch the Workshop now.'
                            : 'You have earned an ‘Ask Doctor’ Credit. You can ask your query to the Doctor now. '}

                        <br />
                        <p className="mt-5 text-sm text-gray dark:text-gray font-roboto">
                            {url.includes('livechats')
                                ? `3 out every 4 parents who go through a parentune course has rated
                                parentune 5 stars out of 5.`
                                : `96% Parents’ queries are resolved within 45 min by Parentune Doctors & Experts `}
                        </p>
                    </p>

                    {!success ? (
                        <>
                            <h1 className="font-roboto text-[16px] leading-[26px] mt-[20px]  w-full">
                                {t('Payment_failed')}.<br />
                                {t('Please_pay_again_using_another_method')}
                            </h1>
                            <p className="text-[#787878] text-left w-full mt-[24px]">
                                {t('Contact_support_if_youre_facing_problems')}
                            </p>
                        </>
                    ) : (
                        <>
                            <h1 className="font-[500] leading-[21px] text-[18px] font-roboto mt-[24px]">
                                {/* Your Short Term Plan is Now Activated */}
                                {url.includes('livechats')
                                    ? 'Watch Workshops now'
                                    : 'Ask Doctor Anytime'}
                            </h1>
                        </>
                    )}
                    <button
                        className="text-[14px] leading-[16px] uppercase font-roboto rounded-[17.5px] bg-[#367E8A] text-white py-[10px] px-[103px] mt-[40px]  font-[500]"
                        onClick={continueHandler}
                    >
                        {url.includes('livechats') ? 'WATCH NOW' : 'ASK NOW'}
                    </button>
                </div>
            </div>
        </>
    );
};

const calculateSubscriptionValidTill = (duration_type: string, duration: string) => {
    if (duration_type == 'month' || duration_type == 'months') {
        const d = new Date();
        d.setMonth(d.getMonth() + parseInt(duration));
        return d.toLocaleDateString();
    }
    return new Date();
};
export default AskDocNestleSuccess;
