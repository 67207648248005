import { useState } from 'react';
import { useUserData } from '../../utils/hooks/useUserData';
import Input from './Input';

const SenderDetail = () => {
    const { userData } = useUserData();

    const [senderDetail, setSenderDetail] = useState({
        name: userData.name ?? '',
        email: userData.email ?? '',
        mobile: userData.mobile ?? ''
    });

    const onChange = (e: any) => {
        if (e.target.name == 'mobile' && e.target.value.length > 10) return;

        setSenderDetail((prevData: any) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <>
            <h2 className="mt-[29px] text-[#242F35] text-[18px] font-medium text-center">
                Enter Sender and Receiver Detail{' '}
            </h2>
            <div className="mx-[13px] p-[7px] mt-[41px] sender-shadow pt-[18px] pb-[38px]">
                <h2 className="text-[#242F35] text-[18px] font-medium">Sender Detail </h2>
                <Input
                    id={1}
                    label={'Name'}
                    placeholder="Name"
                    name="name"
                    value={senderDetail.name}
                    onChange={onChange}
                />
                <Input
                    id={1}
                    label="Email"
                    placeholder="Email"
                    name="email"
                    value={senderDetail.email}
                    onChange={onChange}
                />
                <Input
                    id={2}
                    label="+91"
                    placeholder=""
                    name="mobile"
                    value={senderDetail.mobile}
                    onChange={onChange}
                />
            </div>
        </>
    );
};

export default SenderDetail;
