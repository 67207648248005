import React, { Fragment } from 'react';
// import { classNames } from '../../../utils/utils';
import { AiFillCaretDown } from 'react-icons/ai';
import { Menu, Transition } from '@headlessui/react';
import { BsGlobe2 } from 'react-icons/bs';
interface DropDownProps {
    label?: string;
    selected: {
        label: string;
        value: string;
    };
    footerDropdown: boolean;
    setSelected: ({ label, value }: { label: string; value: string }) => void;
    options: { value: string; label: string }[];
    rounded?: boolean;
    Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

const DropDown = (props: DropDownProps) => {
    const { label, selected, setSelected, options, rounded = false, Icon, footerDropdown } = props;

    return (
        <div className="w-full min-w-full flex items-center justify-center">
            <BsGlobe2 className="text-gray-500 mr-[8px]" />
            <label htmlFor={label} className="block text-sm font-medium text-gray-700">
                {label}
            </label>

            <Menu as="div" className="relative flex-shrink-0 w-min whitespace-nowrap">
                <Menu.Button className="flex flex-row items-center text-sm font-medium gap-1 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700">
                    {selected.label}{' '}
                    <AiFillCaretDown
                        style={{ transform: footerDropdown ? 'rotate(180deg)' : '' }}
                    />
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="origin-top-right z-10 absolute left-0 mt-2 w-min rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        style={{ top: footerDropdown ? '-200px' : '' }}
                    >
                        {options.map((option) => (
                            <Menu.Item
                                key={option.label}
                                // onClick={() => {
                                //     setSelected({
                                //         label: option.label,
                                //         value: option.value
                                //     });
                                // }}
                            >
                                <a
                                    onClick={() => {
                                        setSelected({
                                            label: option.label,
                                            value: option.value
                                        });
                                    }}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                >
                                    {option.label}
                                </a>
                                {/* <option className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                                    {option.label}
                                </option> */}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

export default DropDown;
