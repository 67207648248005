import React from 'react';

const PaswordViewIcon = (props: any) => (
    // @ts-ignore
    // const {enabled} = props;
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.2052 12.3895C22.3231 10.1078 20.7917 8.13449 18.8003 6.7136C16.8089 5.2927 14.4448 4.48646 12.0002 4.39453C9.55556 4.48646 7.19146 5.2927 5.20007 6.7136C3.20868 8.13449 1.67729 10.1078 0.795169 12.3895C0.735595 12.5543 0.735595 12.7348 0.795169 12.8995C1.67729 15.1813 3.20868 17.1546 5.20007 18.5755C7.19146 19.9964 9.55556 20.8026 12.0002 20.8945C14.4448 20.8026 16.8089 19.9964 18.8003 18.5755C20.7917 17.1546 22.3231 15.1813 23.2052 12.8995C23.2647 12.7348 23.2647 12.5543 23.2052 12.3895ZM12.0002 19.3945C8.02517 19.3945 3.82517 16.447 2.30267 12.6445C3.82517 8.84203 8.02517 5.89453 12.0002 5.89453C15.9752 5.89453 20.1752 8.84203 21.6977 12.6445C20.1752 16.447 15.9752 19.3945 12.0002 19.3945Z"
            fill={props?.enabled ? '#EB5757' : '#787878'}
        />
        <path
            d="M12 8.14453C11.11 8.14453 10.24 8.40845 9.49994 8.90292C8.75991 9.39738 8.18314 10.1002 7.84254 10.9225C7.50195 11.7447 7.41283 12.6495 7.58647 13.5224C7.7601 14.3954 8.18869 15.1972 8.81802 15.8265C9.44736 16.4558 10.2492 16.8844 11.1221 17.0581C11.995 17.2317 12.8998 17.1426 13.7221 16.802C14.5443 16.4614 15.2471 15.8846 15.7416 15.1446C16.2361 14.4046 16.5 13.5345 16.5 12.6445C16.5 11.4511 16.0259 10.3065 15.182 9.46255C14.3381 8.61864 13.1935 8.14453 12 8.14453ZM12 15.6445C11.4067 15.6445 10.8266 15.4686 10.3333 15.1389C9.83994 14.8093 9.45543 14.3408 9.22836 13.7926C9.0013 13.2444 8.94189 12.6412 9.05765 12.0593C9.1734 11.4773 9.45912 10.9428 9.87868 10.5232C10.2982 10.1037 10.8328 9.81793 11.4147 9.70218C11.9967 9.58642 12.5999 9.64583 13.1481 9.87289C13.6962 10.1 14.1648 10.4845 14.4944 10.9778C14.8241 11.4712 15 12.0512 15 12.6445C15 13.4402 14.6839 14.2032 14.1213 14.7659C13.5587 15.3285 12.7957 15.6445 12 15.6445Z"
            fill={props?.enabled ? '#EB5757' : '#787878'}
        />
    </svg>
);

export default PaswordViewIcon;
