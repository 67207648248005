import axios from 'axios';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
// import i18n from '../../i18n/config';
// const SelectedLanguage = Geti18nLang()

function bookmarkWorskshop(
    props: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any }
) {
    const { id, user_data } = props;
    return axios
        .post(
            process.env.REACT_APP_CONTENT_SERVER + 'content/bookmark',
            {
                itemType: 'live_event',
                itemId: parseInt(id)
            },
            {
                headers: {
                    authToken: user_data?.authToken ?? '',
                    instanceId: user_data.instanceId ?? '',
                    userId: user_data.userId ?? ''
                }
            }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}
export default bookmarkWorskshop;
