import axios from 'axios';

export const ConsentData = (user_data: any) => {
    const API: any = process.env.REACT_APP_CONTENT_SERVER + 'content/giveConsent';
    // const { userData: user_data } = useUserData();
    axios
        .post(process.env.REACT_APP_CONTENT_SERVER + 'content/giveConsent', null, {
            headers: {
                userId: user_data?.userId?.toString() || '',
                instanceId: user_data?.instanceId?.toString() || '',
                authToken: user_data?.authToken || ''
            }
        })
        .then((response) => {
            // Request was successful, handle the response here
            console.log('Response:', response.data);
        });
};
export const ConsentDataGet = (user_data: any, onSuccess: any) => {
    // const { userData: user_data } = useUserData();
    axios
        .get(process.env.REACT_APP_CONTENT_SERVER + 'content/consent', {
            headers: {
                userId: user_data?.userId?.toString() || '',
                instanceId: user_data?.instanceId?.toString() || '',
                authToken: user_data?.authToken || ''
            }
        })
        .then((response) => {
            // Request was successful, handle the response here
            console.log('Response:', response.data);
            onSuccess(response.data);
        });
};
