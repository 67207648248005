function FilterIcon(props) {
    const { color } = props;
    return (
        <svg
            width={22}
            height={12}
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.857 11h4.286M1 1h20H1zm3.571 5H17.43 4.57z"
                stroke={color ? color : '#151E26'}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default FilterIcon;
