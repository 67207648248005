import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addUserdata, updateUserData } from '../../store/slices/userDataSlice';
import { useUserData } from '../../utils/hooks/useUserData';
import PaymentBtn from './PaymentBtn';
import { validUserCrendential } from './validateCredential';
import WorkShopInputForm from './WorkShopInputForm';

function capitalizeText(text: string) {
    return text
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
}

const WorkShopInput = (props: any) => {
    const { state } = props;

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const { userData } = useUserData();

    const [infoDetail, setInfoDetail] = useState({
        name: '',
        number: '',
        email: '',
        error: {
            name: '',
            number: '',
            email: ''
        }
    });

    const handleChange = (e: any) => {
        setInfoDetail((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (item: number) => (item === 1 ? getPlus() : getEventWorkshop());

    const getEventWorkshop = () => {
        const { errors, isValid } = validUserCrendential(infoDetail);

        if (!isValid) {
            setInfoDetail((prev) => ({
                ...prev,
                error: errors
            }));
            return;
        }

        if (isValid) {
            const updatedInfo = {
                email: infoDetail.email,
                mobile: infoDetail.number
            };

            dispatch(addUserdata({ ...updatedInfo }));
            navigate(`/PlanDetailpage/${state.id}/live_event`);
        }
    };

    const getPlus = () => {
        navigate('/PlanDetailpage/1/subscription', {
            replace: true,
            state: {
                from: location,
                planType: 'Choose-a-plan'
            }
        });
    };

    return (
        <>
            <div className="mx-[20px] my-[24px]">
                <h2>This workshop is for:</h2>
                <div className="mt-[37px] flex justify-between">
                    <h2
                        className={`flex flex-1 justify-center w-full ${
                            isVisible === false
                                ? 'text-[#242F35] border-b-[3px]  border-black'
                                : 'text-[#787878]'
                        }   px-[16px] text-[16px] font-normal  pb-2`}
                        onClick={() => setIsVisible(false)}
                    >
                        {capitalizeText(userData.name)}
                    </h2>
                    <div className="flex-1"></div>
                    {/* <h2
                    className={` flex flex-1 justify-center w-full${
                        isVisible === true
                            ? 'text-[#242F35] border-b-[3px]  border-black'
                            : 'text-[#787878]'
                    }   px-[16px] text-[16px] font-normal  pb-2`}
                    onClick={() => setState(true)}
                >
                    Someone else
                </h2> */}
                </div>
                {isVisible && (
                    <WorkShopInputForm
                        userData={userData}
                        infoDetail={infoDetail}
                        handleChange={handleChange}
                        status={state.status}
                        id={2}
                    />
                )}
                {!isVisible && (
                    <WorkShopInputForm
                        userData={userData}
                        infoDetail={infoDetail}
                        handleChange={handleChange}
                        status={state.status}
                        id={1}
                    />
                )}
            </div>
            <PaymentBtn onSubmit={handleSubmit} status={state.status} />
        </>
    );
};

export default WorkShopInput;
