import axios from 'axios';

function GuestActivityLog(params: any, onSuccess: any, onFailure: any) {
    axios
        .post(process.env.REACT_APP_QUEUE_SERVER + 'guest/log', {
            guestUid: params.guestUid,
            action: params.action,
            actionType: params.actionType,
            lang: params.lang,
            platform: 'WEB'
        })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export default GuestActivityLog;
