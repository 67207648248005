export const isDueDateChanged = (firstDueDate, secondDueDate) =>
    new Date(firstDueDate).toISOString().substr(0, 10) !==
    new Date(secondDueDate).toISOString().substr(0, 10);

export const checkifChildDataChanged = (x, y) => {
    if (x?.length !== y?.length) return true;
    return x.some((childX) => {
        const childY = y.find((item) => item.id === childX.id);
        if (!childY) return false;

        // Normalize the dates to YYYY-MM-DD format
        const normalizeDate = (dateStr) => {
            const date = new Date(dateStr);
            const pad = (num) => String(num).padStart(2, '0');
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
        };

        const dobX = normalizeDate(childX.dob);
        const dobY = normalizeDate(childY.dob);

        if (childX.name.length < 3) return false;

        return childX.name !== childY.name || dobX !== dobY;
    });
};

export const checkIfDataisNotNull = (obj) =>
    obj.filter(({ name, ...rest }) => {
        // const isNotEmpty = Object.values(rest).every((val) => val !== '');
        const childDob = rest.dob !== '';
        const isValidName = name !== '' && name.length > 2;
        return isValidName && childDob;
    });

export const languageOption = [
    { value: 'en', label: 'English' },
    { value: 'hi', label: 'हिंदी' },
    { value: 'ta', label: 'தமிழ்' },
    { value: 'te', label: 'తెలుగు' },
    { value: 'mr', label: 'मराठी' }
];
