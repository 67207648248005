import './LandingPage.css';
import HeroHeader from './HeroHeader';
import SeesionHeader from './SessionHeader';
import DoctorHeader from './DoctorHeader';
import WhoCourseABout from './WhoCourseAbout';
import Button from './CtaButton';
import WhatGetYou from './WhatGetYou';
import QuestionReview from './QuestionReview';
import WhoCourseFor from './WhoCourseFor';
import FellowParents from './FellowParents';
import { useEffect, useState } from 'react';
import { getWorkshopDetails } from '../../api/LearnLandingPage/LearnLandingPage';
import RelatedEvents from '../../components/RelatedEvents/RelatedEvents';
import { useNavigate, useParams } from 'react-router-dom';
import { EventSkeletel } from '../Workshop/EventDetailComponents/EventSkeletel';
import { useUserData } from '../../utils/hooks/useUserData';
import CanWatchWorkshop from '../../api/workshop/CanWatchWorkshop';
import LoginPopUp1 from '../../components/PreSchool/LoginPopUp1';

function LandingPage() {
    const [openDialog, setOpenDialog] = useState(false);
    const { title, liveId } = useParams();
    const { userData: user_data, isLoggedIn } = useUserData();
    const [hideFullDescription, setHideFullDescription] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [workshopdetails, setWorkshopDetails] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [videoOnOff, setVideoOnOff] = useState(false);
    const [watchOnOff, setWatchOnOff] = useState(false);
    const [canWatch, setCanWatch] = useState<boolean>(false);
    const [hasworkshopconsumed, sethasworkshopconsumed] = useState(false);

    const { userData } = useUserData();
    const navigate = useNavigate();
    const isForNestleOffer = Number(userData?.workshopCreditLeft) > 0;
    const _nestle_free_workshop = JSON.parse(localStorage.getItem('_nestle_free_workshop')!) ?? '';

    // @ts-ignore
    if (isNaN(liveId)) navigate('/livechats');

    const handleVideo = () => {
        if (
            workshopdetails.ageGroup === '1 to 3 years' ||
            workshopdetails.ageGroup === '3 to 7 years'
        ) {
            if (!isLoggedIn) return setOpenDialog(true);
            if (isLoggedIn && user_data?.profileComplete < 40) return setOpenDialog(true);
        }

        if (isForNestleOffer) {
            setVideoOnOff(true);
            setWatchOnOff(true);
            return;
        }

        if (workshopdetails.amount && canWatch === false) {
            userData &&
                navigate('/booking-summary', {
                    state: {
                        id: workshopdetails.id,
                        start_date: workshopdetails.start_date,
                        title: workshopdetails.title,
                        amount: workshopdetails.amount,
                        avatar: workshopdetails.expertdetails[0].avatar,
                        name: workshopdetails.expertdetails[0].name,
                        specialization: workshopdetails.expertdetails[0].specialization
                    }
                });

            !userData && navigate('/register');
        }

        if (workshopdetails.status === 'upcoming') {
            return;
        }

        if (!workshopdetails.amount) {
            setVideoOnOff(true);
            setWatchOnOff(true);
            return;
        }

        if (workshopdetails.amount && workshopdetails.status === 'archive' && canWatch === true) {
            setVideoOnOff(true);
            setWatchOnOff(true);
            return;
        }

        if (workshopdetails.amount && workshopdetails.status === 'live' && canWatch === true) {
            setVideoOnOff(true);
            setWatchOnOff(true);
            return;
        }
    };

    const handleScroll = () => {
        if (hideFullDescription) {
            setScrollPosition(window.scrollY);
        } else {
            window.scrollTo(0, scrollPosition);
        }
    };

    const onSuccess = (response: any) => {
        console.log('data! ', response.eventData);

        if (isForNestleOffer) {
            response.eventData.amount = 0;
        }

        if (
            response.eventData.ageGroup === '1 to 3 years' ||
            response.eventData.ageGroup === '3 to 7 years'
        ) {
            response.eventData.amount = 0;
        }

        console.log('data:', response.eventData);
        setWorkshopDetails(response.eventData);
        setLoading(false);
    };

    const onError = (error: any) => {
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
    };

    useEffect(() => {
        if (userData && _nestle_free_workshop !== '' && _nestle_free_workshop.value == liveId) {
            setCanWatch(true);
            return;
        }
        CanWatchWorkshop(
            user_data,
            (data) => {
                setCanWatch(data.canWatch);
            },
            (error) => {
                console.log('error', error);
            },
            liveId
        );
    }, [window.location.pathname, canWatch]);

    useEffect(() => {
        setLoading(true);

        getWorkshopDetails(parseInt(`${liveId}`), onSuccess, onError);

        return () => {
            setVideoOnOff(false);
            setWatchOnOff(false);
        };
    }, [liveId]);

    if (error) {
        return (
            <div className="flex flex-col justify-center items-center w-full h-screen">
                <span>Error occured!</span>
                <span className="font-medium">{errorMessage}</span>
            </div>
        );
    }

    if (loading) {
        return <EventSkeletel />;
    }

    return (
        <div className="flex justify-center">
            {openDialog && (
                <LoginPopUp1
                    isLoggedIn={isLoggedIn}
                    path={window.location.pathname.slice(1).split('/')}
                />
            )}
            <div className="max-w-4xl w-full">
                {workshopdetails.embeddetails && (
                    <HeroHeader
                        handleVideo={handleVideo}
                        videoOnOff={videoOnOff}
                        setVideoOnOff={setVideoOnOff}
                        workshopdetails={workshopdetails}
                    />
                )}

                {workshopdetails.offset && (
                    <SeesionHeader
                        eventDetails={workshopdetails}
                        canWatch={canWatch}
                        setCanWatch={setCanWatch}
                        videoOnOff={videoOnOff}
                        setVideoOnOff={setVideoOnOff}
                        hasworkshopconsumed={hasworkshopconsumed}
                    />
                )}

                {workshopdetails.courseabout !== null ? (
                    <div>
                        {workshopdetails.expertdetails && (
                            <DoctorHeader
                                expert_details={workshopdetails.expertdetails}
                                rating_count={workshopdetails.ratingcount}
                            />
                        )}

                        {workshopdetails.courseabout && (
                            <WhoCourseABout course_about={workshopdetails.courseabout} />
                        )}
                        <FellowParents />

                        <QuestionReview
                            id={parseInt(`${liveId}`)}
                            workshopdetails={workshopdetails}
                        />

                        {workshopdetails.whatyougetdata && (
                            <WhatGetYou what_you_get_data={workshopdetails.whatyougetdata} />
                        )}
                        {workshopdetails.coursefordata && (
                            <WhoCourseFor course_for_data={workshopdetails.coursefordata} />
                        )}
                    </div>
                ) : (
                    <>
                        {workshopdetails.expertdetails && (
                            <DoctorHeader
                                expert_details={workshopdetails.expertdetails}
                                rating_count={workshopdetails.ratingcount}
                            />
                        )}
                        <div className="mx-5 mt-[16px]">
                            <div className="text-lg text-[#262626] font-medium my-2 ">
                                What is this Course about?
                            </div>
                            <div
                                className="mt-4  text-base  text-[#242F35] font-normal  [&_p]:text-black font-roboto "
                                dangerouslySetInnerHTML={{
                                    __html:
                                        workshopdetails &&
                                        workshopdetails.description &&
                                        workshopdetails.description.length > 200 &&
                                        hideFullDescription
                                            ? `${workshopdetails.description.slice(0, 200)}...`
                                            : workshopdetails.description
                                }}
                            />
                            {workshopdetails?.description?.length > 200 && (
                                <div
                                    className="text-sm text-gray-500 mt-2 cursor-pointer"
                                    onClick={() => {
                                        setHideFullDescription((prev) => !prev), handleScroll();
                                    }}
                                >
                                    {hideFullDescription ? 'Show More' : 'Show Less'}
                                </div>
                            )}
                        </div>

                        <QuestionReview
                            id={parseInt(`${liveId}`)}
                            workshopdetails={workshopdetails}
                        />
                    </>
                )}
                {workshopdetails && (
                    <Button
                        workshopdetails={workshopdetails}
                        handleVideo={handleVideo}
                        watchOnOff={watchOnOff}
                        isForNestleOffer={isForNestleOffer}
                        canWatch={canWatch}
                        sethasworkshopconsumed={sethasworkshopconsumed}
                        hasworkshopconsumed={hasworkshopconsumed}
                    />
                )}
                {workshopdetails.id && (
                    <RelatedEvents target="live_event" itemId={workshopdetails.id} />
                )}
                <div className="mt-16"></div>
            </div>
        </div>
    );
}

export default LandingPage;
