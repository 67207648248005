import React, { useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon, MinusIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils/utils';

import './EventDetailsQuestions.css';

type EventDetailsQuestionsType = {
    quickLinks: { question: string; answer: string }[];
    contentTypeHtml?: boolean;
    darkMode?: boolean;
};
type QuestionComponentType = {
    question: string;
    answer: string;
    title?: string;
    desc?: string;
    contentTypeHtml?: boolean;
    darkMode?: boolean;
};
const QuestionComponent = (props: QuestionComponentType) => {
    // const [isOpen, setOpen] = useState(false);
    const [toggle, setToggle] = useState(true);

    const toggleHandler = () => {
        toggle === true ? setToggle(false) : setToggle(true);
    };

    return (
        <>
            {/* <div className="edqcm">
                <div className="edqcmq">{props.title}</div>
                <div className="edqcmo">
                    <span onClick={() => setOpen((s) => !s)}> {isOpen ? '-' : '+'} </span>
                </div>
            </div>
            {isOpen && <div className="edqcmc" dangerouslySetInnerHTML={{ __html: props.desc }} />} */}
            <Disclosure
                as="div"
                key={props?.question}
                className={`${
                    props?.darkMode ? 'bg-black' : 'bg-white text-[#323A41]'
                }  py-3 px-5 `}
            >
                {({ open }) => (
                    <>
                        <div className={` ${toggle ? 'border-b-2' : ' '} border-dotted pb-4`}>
                            <Disclosure.Button
                                className="text-left w-full flex justify-between items-start "
                                onClick={toggleHandler}
                            >
                                <h1
                                    className={`md:font-medium font-normal text-[16px] ${
                                        props?.darkMode ? 'text-white' : 'text-[#323A41]'
                                    }  font-roboto leading-[26px]`}
                                >
                                    {props.question ? props.question : props.title}
                                </h1>
                                <span className="ml-6 h-7 flex items-center">
                                    {/* <ChevronDownIcon
                                        className={classNames(
                                            open ? '-rotate-180' : 'rotate-0',
                                            'h-6 w-6 transform'
                                        )}
                                        aria-hidden="true"
                                    /> */}
                                    {props?.darkMode ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            aria-hidden="true"
                                            className={` ${
                                                toggle ? 'block' : 'hidden'
                                            } z-10 w-5 fill-white block`}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 4v16m8-8H4"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <PlusIcon
                                            // fill={props?.darkMode ? 'white' : 'black'}
                                            className={` z-10 w-5 fill-white ${
                                                toggle ? 'block' : 'hidden'
                                            }`}
                                        />
                                    )}

                                    {props?.darkMode ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="white"
                                            aria-hidden="true"
                                            className={` ${
                                                !toggle ? 'block' : 'hidden'
                                            } z-10 w-5 fill-white block`}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M20 12H4"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <MinusIcon
                                            className={`  z-10 w-5 ${toggle ? 'hidden' : 'block'}`}
                                        />
                                    )}
                                </span>
                            </Disclosure.Button>
                        </div>
                        <Transition
                            enter="transition duration-200 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-100 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel
                                as="dd"
                                className="mt-2 pr-12 border-b-2 border-dotted pb-3"
                            >
                                <p className="md:font-medium font-normal md:text-base text-sm text-gray-500 answer-acordian">
                                    {props.answer ? (
                                        props?.contentTypeHtml ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: props.answer
                                                }}
                                            ></div>
                                        ) : (
                                            props.answer
                                        )
                                    ) : (
                                        () => {
                                            if (props.desc)
                                                <div
                                                    contentEditable="true"
                                                    dangerouslySetInnerHTML={{
                                                        __html: props.desc
                                                    }}
                                                ></div>;
                                        }
                                    )}
                                </p>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </>
    );
};

function EventDetailsQuestions(props: EventDetailsQuestionsType) {
    return (
        // <div>
        //     {props.quickLinks.map((d, i) => (
        //         <div key={i}>
        //             <QuestionComponent {...d} />
        //             <hr style={{ borderTop: 'dotted 1px', margin: '0 20px' }} />
        //         </div>
        //     ))}

        // </div>
        <>
            {props?.quickLinks?.length < 1 ? (
                <></>
            ) : (
                <dl className="mt-6 flex flex-col gap-2 mb-4">
                    {props.quickLinks.map((faq, i) => (
                        <QuestionComponent
                            key={i}
                            {...faq}
                            contentTypeHtml={props?.contentTypeHtml ?? false}
                            darkMode={props?.darkMode ?? false}
                        />
                    ))}
                </dl>
            )}
        </>
    );
}

export default EventDetailsQuestions;
