import { useLocation, useNavigate } from 'react-router';
import ParentuneLogo from '../../assets/icons/ParentuneLogo';
import { Constants } from '../Constants/Constants';
import CloseIcon from '../Icons/CloseIcon';
import Overlay from '../Overlay/Overlay';
import SubmitButton from '../SubmitButton/SubmitButton';
import styles from './loginPopup.module.css';
import { Buffer } from 'buffer';

type PopupProps = {
    questionContainerRef?: any;
    modalType?: number;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};
function LoginPopup(props: PopupProps) {
    const handleModalClose = (e: any) => {
        // if (props.questionContainerRef && props.questionContainerRef?.current.contains(e.target)) {
        //     let el = e.target.textContent;
        //     let el2 = e.target.getAttribute('class');
        //     if (el2 === 'svg-icon') {
        //         props.setShowModal(false);
        //     } else {
        //         if (el === 'Done' || el === 'Skip') {
        //             props.setShowModal(false);
        //         } else return;
        //     }
        // } else {
        props.setShowModal(false);
        // }
    };

    const modalHeaderView = () => {
        return (
            <span
                className={styles.closeIcon}
                style={{ margin: '0 20px' }}
                onClick={handleModalClose}
            >
                <CloseIcon />
            </span>
        );
    };

    const modalContentView = () => {
        let view = <p></p>;
        if (props.modalType === Constants.MODAL_TYPE.SUPPORT) {
            view = <p>Please Login/Signup to support</p>;
        } else if (props.modalType === Constants.MODAL_TYPE.REPLY) {
            view = <p>Please login or register to continue</p>;
        } else if (props.modalType === Constants.MODAL_TYPE.BOOKMARK) {
            view = <p>Please login or register to continue</p>;
        } else if (props.modalType === Constants.MODAL_TYPE.COMMENT) {
            view = <p>Please login or register to continue</p>;
        }
        return view;
    };

    const modalActionView = () => {
        let view = <button></button>;
        const navigate = useNavigate();
        const location = useLocation();
        if (
            [
                Constants.MODAL_TYPE.SUPPORT,
                Constants.MODAL_TYPE.REPLY,
                Constants.MODAL_TYPE.BOOKMARK,
                Constants.MODAL_TYPE.COMMENT
            ].includes(Constants.MODAL_TYPE.COMMENT)
        ) {
            //$redirect_key=$request->path();
            const currentURL = window.location.href;
            const redirect = Buffer.from(currentURL).toString('base64');

            view = (
                <SubmitButton
                    transparent
                    handleSubmit={() =>
                        navigate('/register', {
                            replace: true,
                            state: { from: location }
                        })
                    }
                    buttonText={'Continue'}
                    className={styles._abc}
                />
            );
        }
        return view;
    };

    const toRegister = (url: string) => {
        open(url, '_self');
    };

    return (
        <Overlay handleModalClose={handleModalClose}>
            <div className="instant-box">
                <div className={styles.container} ref={props.questionContainerRef}>
                    <div className={styles.header}>{modalHeaderView()}</div>
                    <ParentuneLogo />
                    <div className={styles.content}>{modalContentView()}</div>
                    <div className={styles.footer}>{modalActionView()}</div>
                </div>
            </div>
        </Overlay>
    );
}

export default LoginPopup;
