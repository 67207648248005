import { useEffect, useState } from 'react';
import { useUserData } from '../../../utils/hooks/useUserData';
import axios from 'axios';

export function useFetchInterest() {
    const { userData: user_data } = useUserData();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchInterest();
    }, []);

    const fetchInterest = async () => {
        const config = {
            headers: {
                authToken: user_data?.authToken ?? '',
                instanceId: user_data?.instanceId ?? ''
            }
        };

        setLoading(true);
        try {
            const res = await axios.get(
                process.env.REACT_APP_ONBOARDING_SERVER2 + 'interests',
                config
            );
            setError(null);
            // console.log('res', res?.data?.data);
            return setData(res?.data?.data);
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { error, loading, data };
}
