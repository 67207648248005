function Loader() {
    return (
        <div className="animate-pulse max-w-[1000px] ml-auto mr-auto px-4">
            <div className="flex w-full gap-4 flex-col ">
                <div className="flex gap-2">
                    <div className="h-16 w-16 flex-shrink-0 rounded-full bg-gray-300"></div>
                    <div className="flex flex-col w-full justify-around">
                        <div className="h-4 w-1/4 bg-gray-300"></div>
                        <div className="h-4 w-1/3 bg-gray-300"></div>
                    </div>
                </div>
                <div className="h-4 w-full bg-gray-300"></div>
                <div className="h-0.5 w-full bg-gray-300"></div>
                <div className="flex gap-2">
                    <div className="h-16 w-16 flex-shrink-0 rounded-full bg-gray-300"></div>
                    <div className="flex flex-col w-full justify-around">
                        <div className="h-4 w-full bg-gray-300"></div>
                        <div className="h-4 w-full bg-gray-300"></div>
                    </div>
                </div>
            </div>
            <div className="h-6 w-full bg-gray-300 mt-2.5"></div>
            {Array(2)
                .fill(0)
                .map((item, index) => (
                    <div key={index} className="h-48 w-full bg-gray-300 mt-2.5"></div> //fixed duplicate key issue
                ))}
        </div>
    );
}

export default Loader;
