import { useEffect } from 'react';

const WorkShopInputForm = (props: any) => {
    const { userData, infoDetail, handleChange, id, status } = props;

    const getName = () => {
        const event = {
            target: {
                name: 'name',
                value: `${id == 1 && userData.name ? userData.name : ''}`
            }
        };

        return event;
    };

    const getNumber = () => {
        const event = {
            target: {
                name: 'number',
                value: `${
                    id == 1 && userData.mobile.length === 12
                        ? userData.mobile.slice(2)
                        : userData.mobile
                        ? userData.mobile
                        : ''
                }`
            }
        };

        return event;
    };

    const getEmail = () => {
        const event = {
            target: {
                name: 'email',
                value: `${id == 1 && userData.email ? userData.email : ''}`
            }
        };

        return event;
    };

    useEffect(() => {
        handleChange(getName());
        handleChange(getNumber());
        handleChange(getEmail());
    }, []);

    return (
        <form className="mt-[30px] text-[16px]">
            <p>Please provide the following information:</p>
            <div className="mt-[24px] flex flex-col gap-[16px]">
                <label htmlFor="fullname">Full name</label>
                <input
                    type="text"
                    id="fullname"
                    name="name"
                    value={infoDetail.name}
                    onChange={handleChange}
                    className=" bg-[#FCFCFC] border border-[#D7D7D7] w-full h-[55px] rounded px-[20px] py-[15px]"
                    placeholder="name"
                />
                {infoDetail.error.name !== '' && (
                    <span className="text-red-600 text-[12px]">{infoDetail.error.name}</span>
                )}
            </div>

            <div className="mt-[24px] flex flex-col gap-[16px]">
                <label htmlFor="mobilenumber">Mobile Number</label>
                <input
                    type="number"
                    name="number"
                    value={infoDetail.number.slice(0, 10)}
                    onChange={handleChange}
                    id="mobilenumber"
                    className=" bg-[#FCFCFC] border border-[#D7D7D7] w-full h-[55px] rounded px-[20px] py-[15px]"
                    placeholder="number"
                />
                {infoDetail.error.number !== '' && (
                    <span className="text-red-600 text-[12px]">{infoDetail.error.number}</span>
                )}
            </div>

            <div className="mt-[24px] flex flex-col gap-[16px]">
                <label htmlFor="mobilenumber">Email Id</label>
                <input
                    type="email"
                    name="email"
                    value={infoDetail.email}
                    onChange={handleChange}
                    id="email"
                    className=" bg-[#FCFCFC] border border-[#D7D7D7] w-full h-[55px] rounded px-[20px] py-[15px]"
                    placeholder="email"
                />
                {infoDetail.error.email !== '' && (
                    <span className="text-red-600 text-[12px]">{infoDetail.error.email}</span>
                )}
            </div>

            <p className="mt-[24px] text-[#787878]">
                A reminder will be sent to your registered mobile & email ID.
            </p>
        </form>
    );
};

export default WorkShopInputForm;
