import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';
import { useUserData } from '../utils/hooks/useUserData';

const MixPanelPageTracking: React.FC = () => {
    const { userData: user_data, consent, isLoggedIn, guestUid } = useUserData();
    const location = useLocation();
    useEffect(() => {
        const { domContentLoadedEventEnd, navigationStart } = window.performance.timing;
        const loadTime =
            domContentLoadedEventEnd - navigationStart > 0
                ? domContentLoadedEventEnd - navigationStart
                : 0;
        mixpanel.init('4ed56dbae24376e7669b051fe525819c');
        mixpanel.identify(isLoggedIn ? user_data?.userId : guestUid);
        mixpanel.track('Page_View', { path: location, loadTime: loadTime });
        return () => {
            mixpanel.reset();
        };
    }, []);

    return null;
};

export default MixPanelPageTracking;
