import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HomePageType } from '../../types/HomePageType';

interface HomePageState {
    data: HomePageType;
    loading: boolean;
}

const initialState: HomePageState = {
    data: {
        awards: {
            cta_label: '',
            cta_target: '#',
            images: [],
            sub_text: '',
            title_text: ''
        },
        works: [],
        brands: {
            images: [],
            sub_text: '',
            title_text: ''
        },
        doctors: {
            cta_label: '',
            cta_target: '',
            doctors: [],
            sub_text: '',
            title_text: ''
        },
        faq: {
            question: []
        },
        head: {
            images: [],
            sub_text: '',
            title_text: ''
        },
        influencer: {
            cta_label: 'Join',
            cta_target: 'https://www.parentune.com/mombassador-program',
            images: [],
            sub_text: '',
            title_text: ''
        },
        interest: {
            cta_label: '',
            cta_target: '',
            interest: [],
            title_text: ''
        },
        offer: {
            images: [],
            sub_text: '',
            title_text: ''
        },
        stats: {
            stats: [],
            title_text: ''
        },
        support: {
            ask: {
                description: '',
                image: '',
                sub_text: '',
                title_text: ''
            },
            blogs: {
                description: '',
                image: '',
                sub_text: '',
                title_text: ''
            },
            talks: {
                description: '',
                image: '',
                sub_text: '',
                title_text: ''
            },
            title_text: ''
        },
        testimonial: {
            testionial: [],
            title_text: ''
        },
        parentsplusyou: {
            image: ''
        }
    },
    loading: false
};

export const HomePageSlice = createSlice({
    name: 'homePage',
    initialState,
    reducers: {
        addHomeData: (state, action: PayloadAction<HomePageType>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            const loading = action.payload;
            if (loading !== undefined) {
                state.loading = loading;
            }
        }
    }
});
export const { addHomeData, setLoading } = HomePageSlice.actions;
export default HomePageSlice.reducer;
