import { useLocation, useNavigate } from 'react-router-dom';

const PaymentBtn = (props: any) => {
    const { onSubmit, status } = props;
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="px-[20px] pt-[24px] bg-[#F7F7F7]">
            <h2 className="text-black font-medium text-[16px">GET PLUS - Save Rs 99</h2>
            <h2 className="text-black font-normal text-[16px]">
                Get unlimited content by top experts. Connect with doctors and more.{' '}
            </h2>

            <div className="mt-[62px] flex justify-between pb-[20px]">
                <button
                    className="rounded-[50px] border border-[#E8E8E9] py-[15px] px-[32px] h-[45px] flex justify-center items-center"
                    onClick={() => onSubmit(1)}
                >
                    GET PLUS
                </button>
                <button
                    className="bg-[#367E8A] rounded-[50px]  py-[15px] px-[32px] h-[45px] text-white flex justify-center items-center"
                    onClick={() => onSubmit(2)}
                >
                    {' '}
                    SKIP AND PAY
                </button>
            </div>
        </div>
    );
};
export default PaymentBtn;
