// @ts-nocheck
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, memo } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Mousewheel, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import './RenewalCarousel.css';
// import './ChoosePlanCard.css';

function RenewalCarousel(props: any) {
    const { data, isPlus } = props;
    const navigate = useNavigate();

    const [currentSwiperSlideIndex, setcurrentSwiperSlideIndex] = useState(0);

    return (
        <div className=" w-full  ">
            <Swiper
                onSlideChange={(e) => {
                    setcurrentSwiperSlideIndex(e.activeIndex);
                    // setSlideIndex(e.activeIndex);
                }}
                pagination={{
                    clickable: true
                }}
                spaceBetween={window.innerWidth > 700 ? 8 : 8}
                effect="creative"
                // slidesPerView={2}
                // loopFillGroupWithBlank={true}
                // breakpoints={{
                //     slidesPerView: 2,
                //     spaceBetween: 20
                // }}
                mousewheel={{ forceToAxis: true }}
                modules={[Pagination, Navigation, Mousewheel]}
                className={`flex justify-center  items-center customPaginationStyle  ${
                    isPlus ? `my-profile-carousel-dark` : `my-profile-carousel-light`
                } `}
                slidesPerView={window.innerWidth > 700 ? 3 : 1.1}
                centeredSlides={window.innerWidth < 700}
            >
                {data.map((item: any, index: number) => (
                    <SwiperSlide
                        onClick={() => {
                            switch (currentSwiperSlideIndex) {
                                case 0:
                                    navigate('/expert-corner', {
                                        replace: true
                                    });
                                    break;
                                case 1:
                                    navigate('/livechats', {
                                        replace: true
                                    });
                                    break;
                                case 2:
                                    navigate('/parent-blog', {
                                        replace: true
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }}
                        className="mb-12 "
                        key={index}
                    >
                        <div className="w-full h-full">
                            <section className="bg-[#FFFDFB] rounded-[10px] min-h-[160px] max-h-0 sm:max-h-[200px]">
                                <img
                                    className="min-h-[160px] max-h-0 sm:max-h-[200px] w-full rounded-[10px] object-scale-down "
                                    src={item?.asset}
                                />
                            </section>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default RenewalCarousel;
