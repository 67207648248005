import './FormProgress.css';

const arrowback = 'https://img1.parentune.com/web/assests_v2/arrowback.webp';
import BlackBackIcon from '../../assets/icons/BackBlackIcon.svg';
function FormProgress(props: { onBack: () => void; dots: number; progress: number }) {
    const showProgressBar = window.location.pathname === '/edit-profile/';
    const formSelected = (n: number) =>
        n <= props.progress ? 'form-progress-circle-selected' : 'form-progress-circle';

    const displayFormProgress = () => {
        return [...Array(props.dots)].map((_, i) => (
            <div key={i} className={formSelected(i + 1)} />
        ));
    };

    return (
        <div className="form-progress-container mt-2">
            <img style={{ cursor: 'pointer' }} onClick={props.onBack} src={BlackBackIcon} alt="" />
            {!showProgressBar && <div className="form-progress-bar">{displayFormProgress()}</div>}
        </div>
    );
}

export default FormProgress;
