import { classNames } from '../../../utils/utils';
import * as React from 'react';

type ButtonSecondryType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyle?: React.CSSProperties;
};

const ButtonSecondry = (props: ButtonSecondryType) => {
    const { size, text, className, onClick, disabled = false, customStyle } = props;

    return (
        <button
            style={customStyle}
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                size === 'small'
                    ? `px-3.5 py-1.5 text-sm w-min`
                    : size === 'medium'
                    ? 'px-5 py-2.5 text-sm w-min'
                    : `w-full px-5 py-3 text-sm`,
                disabled ? 'opacity-30' : 'hover:bg-gray-50',
                'inline-flex  border-2 items-center whitespace-nowrap justify-center rounded-full  border-grey shadow-sm font-medium  text-grey-700 bg-white  focus:outline-none ',
                className
            )}
        >
            {text}
        </button>
    );
};
export default ButtonSecondry;
