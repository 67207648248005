import { Suspense, useRef } from 'react';
import Loader from '../components/Loader/Loader';
import {
    breastFeedingEvents,
    breastFeedingBlogs,
    breastFeedingContents
} from '../api/BreastFeeding';

import LiveEventCard from '../components/Live/LiveEventCard';
import BlogPostCard from '../components/BlogPostCard/BlogPostCard';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
import { Helmet } from 'react-helmet';

const DayTitleGenerator = (day: any) => {
    const special = [
        'zeroth',
        '1st',
        '2nd',
        '3rd',
        '4th',
        '5th',
        '6th',
        '7th',
        '8th',
        '9th',
        '10th'
    ];
    return special[day];
};

const BreastFeeding = () => {
    const arrowfront = 'https://img1.parentune.com/web/assests_v2/arrowfront.webp';

    const SelectedLanguage = Geti18nLang();
    const { data: EventData } = breastFeedingEvents();
    const { data: BlogData } = breastFeedingBlogs();
    const { data: content } = breastFeedingContents(SelectedLanguage);
    return (
        <div className="w-full mx-auto">
            <Helmet>
                <meta name="keywords" content={`Breastfeeding Week 1st-7th Aug`} />
                <meta name="title" content={`Breastfeeding Week 1st-7th Aug`} />
                <meta name="description" content={`Breastfeeding Week 1st-7th Aug`} />
                <title>{`Breastfeeding Week 1st-7th Aug`}</title>
            </Helmet>

            <div className="max-w-[900px] mb-12 mx-auto">
                <div className="p-2 mt-5">
                    <div className="text-lg font-semibold">Breastfeeding Week 1st-7th Aug</div>
                    <p className="text-sm  text-[#242F35] break-words mb-5 find-name-para leading-5">
                        {content && content.data.description}
                    </p>
                    <img
                        className="w-full max-w-[900px] mb-5 mx-auto"
                        src={content && content.data.banner}
                    />
                </div>
                <div className="mx-2  my-5">
                    {SelectedLanguage === 'hi' || SelectedLanguage === 'mr'
                        ? listHi.map((item: any, index: number) => (
                              <a href={item.url} key={index}>
                                  <div
                                      className={`w-full p-3 ${
                                          index !== listEn.length - 1 ? 'border-b-2' : ''
                                      } flex items-center hover:bg-blue-50 justify-between border-2`}
                                  >
                                      <div>
                                          <div className="text-sm font-medium">
                                              {`Day ${index + 1}, ${DayTitleGenerator(
                                                  index + 1
                                              )} Aug`}
                                          </div>
                                          <p className="text-xs font-normal">{item.title}</p>
                                      </div>
                                      <img className="w-2 h-2" src={arrowfront} />
                                  </div>
                              </a>
                          ))
                        : listEn.map((item: any, index: number) => (
                              <a href={item.url} key={index}>
                                  <div
                                      className={`w-full p-3 ${
                                          index !== listEn.length - 1 ? 'border-b-0' : ''
                                      } flex items-center hover:bg-blue-50 justify-between border-2`}
                                  >
                                      <div>
                                          <div className="text-sm font-medium">
                                              {`Day ${index + 1}, ${DayTitleGenerator(
                                                  index + 1
                                              )} Aug`}
                                          </div>
                                          <p className="text-xs font-normal">{item.title}</p>
                                      </div>
                                      <img className="w-2 h-2" src={arrowfront} />
                                  </div>
                              </a>
                          ))}
                </div>
                <Suspense fallback={<Loader />}>
                    <div>
                        <div className="flex mx-4 flex-wrap items-center justify-center gap-24 mb-16 max-w-[900px] ">
                            {EventData &&
                                EventData.response.map((item: any, index: number) => (
                                    <LiveEventCard key={index} {...item} />
                                ))}
                        </div>
                    </div>
                </Suspense>
                <Suspense fallback={<Loader />}>
                    <div>
                        <div className="flex flex-wrap items-center gap-16 justify-center max-w-[900px] mx-auto">
                            {BlogData &&
                                BlogData.list.map((item: any, index: number) => (
                                    <BlogPostCard key={index} {...item} />
                                ))}
                        </div>
                    </div>
                </Suspense>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export default BreastFeeding;

const listEn = [
    {
        url: 'https://www.parentune.com/livechats/breastfeeding-challenges-expert-tips-for-new-moms/730',
        title: 'Breastfeeding Challenges - Expert Tips for New Moms'
    },
    {
        url: 'https://www.parentune.com/livechats/nutrition-for-pregnant-and-breastfeeding-moms/108',
        title: 'What to eat & what not to eat - Nutrition for Breastfeeding Moms'
    },
    {
        url: 'https://www.parentune.com/livechats/ask-all-your-questions-related-to-breastfeeding/60',
        title: 'Ask all your questions related to Breastfeeding'
    },
    {
        url: 'https://www.parentune.com/livechats/ask-all-your-questions-related-to-breastfeeding/81',
        title: 'Having trouble breastfeeding your baby? Want to know more about milk production?'
    },
    {
        url: 'https://www.parentune.com/livechats/post-delivery-diet-for-lactating-moms/733',
        title: 'Post Delivery Diet For Lactating Moms'
    },
    {
        url: 'https://www.parentune.com/livechats/post-delivery-diet-for-lactating-moms/734',
        title: 'Why You Should Continue Breastfeeding?'
    },
    {
        url: 'https://www.parentune.com/livechats/post-delivery-diet-for-lactating-moms/735',
        title: 'Breastfeeding Secrets Every New Mom Should Know'
    }
];

const listHi = [
    {
        url: 'https://www.parentune.com/livechats/food-and-nutrition-for-new-moms-after-childbirth/615',
        title: 'स्तनपान कराने वाली मां का आहार कैसा होना चाहिए?'
    },
    {
        url: 'https://www.parentune.com/livechats/how-to-increase-your-breast-milk-supply/510',
        title: 'मां का दूध बढ़ाने के लिए क्या है कारगर उपाय?'
    },
    {
        url: 'https://www.parentune.com/livechats/how-to-lose-weight-after-delivery/299',
        title: 'स्तनपान कराने वााली मां अपने वजन को कैसे करें नियंत्रित?'
    },
    {
        url: 'https://www.parentune.com/livechats/postpartum-diet-plan/689',
        title: 'स्तनपान कराने वाली मां को क्या खाना चाहिए और क्या नहीं?'
    }
];
