import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useUserData } from '../utils/hooks/useUserData';

import ReactGA from 'react-ga';
import { Buffer } from 'buffer';
//import GA4PageTracking from '../utils/GA4PageTracking';
import md5 from 'md5';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();

ReactGA.initialize(
    process.env.REACT_APP_GA_TRACKING_ID ? process.env.REACT_APP_GA_TRACKING_ID : ''
);

function setGA4GuestData(sessionId: string) {
    ReactGA.set({ pt_sessionId: sessionId });
    ReactGA.set({ pt_userLang: SelectedLanguage });
    ReactGA.set({ pt_isPlusUser: 0 });
    ReactGA.set({ pt_firstSession: 0 });

    ReactGA.set({ pt_guestAgeGroups: '' });
    ReactGA.set({ pt_guestTopics: '' });
    ReactGA.set({ pt_userTopics: '' });
}

function PageTracking() {
    const [cookies, setCookie] = useCookies(['_pptr', 'ptgc']);
    const { userData: user_data, isLoggedIn, guestUid } = useUserData();
    const location = useLocation();

    function setGuestUid(isLoggedIn: boolean) {
        const cookieName = 'ptgc';

        let cookieValue = cookies['ptgc'];
        let randomId = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        if (!cookieValue && !isLoggedIn) {
            randomId = md5(
                [...Array(12)].reduce((a) => a + randomId[~~(Math.random() * randomId.length)], '')
            );

            setCookie(cookieName, '' + randomId, { path: '/', maxAge: 86400 * 365 });
            // console.log('g1', cookieValue);
        }

        return randomId;
    }

    useEffect(() => {
        const urlPath = window.location.pathname + window.location.search;

        if (!urlPath.includes('daily-focus')) {
            //we are not sending page view event from here... it's going from code level as we have redirect to plus
            // console.log('path...');
            //  GA4PageTracking(urlPath);
            ReactGA.pageview(urlPath);
        }

        if (user_data?.userId) {
            ReactGA.set({ pt_userId: user_data?.userId });
            ReactGA.set({ pt_platform: 'web' });
        } else {
            ReactGA.set({ pt_userId: 0 });
            ReactGA.set({ pt_platform: 'web' });
        }

        let _pptr = cookies['_pptr'];
        if (_pptr) {
            //php logic case
            _pptr = Buffer.from(_pptr, 'base64').toString('ascii');
            _pptr = _pptr.replace('1fj44', '');
            _pptr = _pptr.replace('55pdjks', '');
            _pptr = JSON.parse(_pptr);

            ReactGA.set({ pt_sessionId: _pptr.pt_sessionId });
            ReactGA.set({ pt_userLang: _pptr.pt_userLang });
            ReactGA.set({ pt_isPlusUser: _pptr.pt_isPlusUser });
            ReactGA.set({ pt_firstSession: _pptr.pt_firstSession });

            ReactGA.set({ pt_guestAgeGroups: _pptr.pt_guestAgeGroups });
            ReactGA.set({ pt_guestTopics: _pptr.pt_guestTopics });
            ReactGA.set({ pt_userTopics: _pptr.pt_userTopics });
        } else if (cookies['ptgc']) {
            //react with already guest id
            setGA4GuestData(cookies['ptgc']);
        } else {
            //react without guest id
            setGA4GuestData(setGuestUid(isLoggedIn));
        }

        if (location?.pathname == 'parent-blog') {
            ReactGA.set({ pt_page: 'blog-list' });
            ReactGA.set({ pt_pageType: 'list' });
            ReactGA.set({ pt_itemType: 'blog' });
        } else if (location?.pathname?.includes('parent-blog')) {
            ReactGA.set({ pt_page: 'blog-list' });
            ReactGA.set({ pt_pageType: 'details' });
            ReactGA.set({ pt_itemType: 'blog' });
        } else if (location?.pathname == 'baby-names') {
            ReactGA.set({ pt_page: 'baby-names' });
            ReactGA.set({ pt_pageType: 'list' });
            ReactGA.set({ pt_itemType: 'baby-names' });
        } else if (location?.pathname?.includes('baby-names')) {
            ReactGA.set({ pt_page: 'baby-names' });
            ReactGA.set({ pt_pageType: 'details' });
            ReactGA.set({ pt_itemType: 'baby-names' });
        } else {
            ReactGA.set({ pt_page: location?.pathname });
        }
        // ReactGA.set({ pt_page: _pptr.pt_userTopics });
        // ReactGA.set({ pt_pageType: _pptr.pt_userTopics });
        // ReactGA.set({ pt_itemType: _pptr.pt_userTopics });

        //console.log(ReactGA)
        //console.log(urlPath)

        //ReactGA.
    }, [location]);
    return null;
}

export default PageTracking;
