import { classNames } from '../../../utils/utils';
import * as React from 'react';
import styles from './buttonWithIconTwo.module.css';

type ButtonWithIconTwoType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyleMain?: React.CSSProperties;
    customStyleText?: React.CSSProperties;
    startIcon?: any;
    badge?: boolean;
    children?: any;
};

const ButtonWithIconTwo = (props: ButtonWithIconTwoType) => {
    const {
        size,
        text,
        className,
        onClick,
        disabled = false,
        customStyleMain,
        customStyleText,
        startIcon,
        badge
    } = props;

    const buttonView = () => {
        return (
            <div className="min-w-[120px]" onClick={onClick}>
                <div className={styles.chipTextIconW}>
                    {startIcon ? <span>{props.children} </span> : null}

                    <button
                        type="button"
                        disabled={disabled}
                        className=" font-roboto text-sm font-medium text-[#151E2699] text-opacity-60"
                        style={customStyleText}
                    >
                        {text}
                    </button>
                    {badge ? (
                        <span
                            className={styles.newIconW + ' para-8'}
                            style={{ padding: '2px', background: '#FFFFFF', order: '2' }}
                        >
                            New
                        </span>
                    ) : null}
                </div>
            </div>
        );
    };

    return buttonView();
};
export default ButtonWithIconTwo;
