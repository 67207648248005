const DoesURLContainMuslim = () => {
    const url = window.location.href;
    return url.includes('muslim');
};

export const DoesURLContainChristian = () => {
    const url = window.location.href;
    return url.includes('christian');
};

export default DoesURLContainMuslim;
