export const staticChoosePlanData = [
    {
        plan_id: 1,
        banner: 'https://parntune-assets.b-cdn.net/images/plus_plan_banner.png',
        price: 295.0,
        claims: '96% PLUS Parents resolved their concerns with Doctor\u2019s advice within 45 min',
        description: {
            data_monthly: [
                {
                    text: 'Ask Doctors anytime - 24x7',
                    isLocked: 'False'
                },
                {
                    text: 'Verified Content by Doctors',
                    isLocked: 'False'
                },
                {
                    text: 'Learn from Expert Video Courses',
                    isLocked: 'False'
                },
                {
                    text: 'Personalised Daily Focus',
                    isLocked: 'False'
                },
                {
                    text: 'Save up to GH\u20b5 714',
                    isLocked: 'False'
                },
                {
                    text: 'Connect with verified parents',
                    isLocked: 'False'
                },
                {
                    text: '7 Days Money Back Guarantee',
                    isLocked: 'True'
                }
            ],
            data_annual: [
                {
                    text: 'Ask Doctors anytime - 24x7',
                    isLocked: 'False'
                },
                {
                    text: 'Verified Content by Doctors',
                    isLocked: 'False'
                },
                {
                    text: 'Learn from Expert Video Courses',
                    isLocked: 'False'
                },
                {
                    text: 'Personalised Daily Focus',
                    isLocked: 'False'
                },
                {
                    text: 'Save up to GH\u20b5 714',
                    isLocked: 'False'
                },
                {
                    text: 'Connect with verified parents',
                    isLocked: 'False'
                },
                {
                    text: '7 Days Money Back Guarantee',
                    isLocked: 'False'
                }
            ]
        },
        tagged_as: 'Recommended',
        plan_name: 'PLUS',
        button_background: 'https://parntune-assets.b-cdn.net/images/plus_button_background.png',
        cost_per_day: 1,
        cost_per_day_new: 0.81,
        cta: 'STARTS AT GH\u20b5 0.81/DAY'
    },
    {
        plan_id: 11,
        banner: 'https://parntune-assets.b-cdn.net/images/pro_plan_banner.png',
        price: 59.0,
        claims: '86% PRO Parents go through content & advice from Doctors every week',
        description: {
            data_monthly: [
                {
                    isLocked: 'False',
                    text: 'Verified Content by Doctors'
                },
                {
                    isLocked: 'False',
                    text: 'Unlimited access to Blogs & Vlogs'
                },
                {
                    isLocked: 'True',
                    text: 'Ask Doctors anytime - 24x7'
                },
                {
                    isLocked: 'True',
                    text: 'Learn from Expert Video Courses'
                },
                {
                    isLocked: 'False',
                    text: 'Save GH\u20b5 10 to GH\u20b5 102'
                },
                {
                    isLocked: 'False',
                    text: 'Connect with verified parents'
                },
                {
                    isLocked: 'True',
                    text: '7 Days Money Back Guarantee'
                }
            ],
            data_annual: [
                {
                    isLocked: 'False',
                    text: 'Verified Content by Doctors'
                },
                {
                    isLocked: 'False',
                    text: 'Unlimited access to Blogs & Vlogs'
                },
                {
                    isLocked: 'True',
                    text: 'Ask Doctors anytime - 24x7'
                },
                {
                    isLocked: 'True',
                    text: 'Learn from Expert Video Courses'
                },
                {
                    isLocked: 'False',
                    text: 'Save GH\u20b5 10 to GH\u20b5 102'
                },
                {
                    isLocked: 'False',
                    text: 'Connect with verified parents'
                },
                {
                    isLocked: 'False',
                    text: '7 Days Money Back Guarantee'
                }
            ]
        },
        tagged_as: 'POPULAR',
        plan_name: 'PRO',
        button_background: 'https://parntune-assets.b-cdn.net/images/pro_button_background.png',
        cost_per_day: 0,
        cta: 'STARTS AT GH\u20b5 0.16/DAY',
        cost_per_day_new: 0.16
    },
    {
        plan_id: 0,
        banner: 'https://parntune-assets.b-cdn.net/images/basic_plan_banner.png',
        price: null,
        claims: 'Millions of Parents learn from each other every day',
        description: {
            data_monthly: [
                {
                    isLocked: 'True',
                    text: 'Verified Content by Doctors'
                },
                {
                    isLocked: 'False',
                    text: 'Limited access to Blogs & Vlogs'
                },
                {
                    isLocked: 'True',
                    text: 'Ask Doctors anytime - 24x7'
                },
                {
                    isLocked: 'True',
                    text: 'Video Courses from Top Experts'
                },
                {
                    isLocked: 'False',
                    text: 'Short term plans start from  GH\u20b5 8'
                },
                {
                    isLocked: 'False',
                    text: 'Connect with verified parents'
                },
                {
                    isLocked: 'False',
                    text: 'Community Support'
                }
            ],
            data_annual: [
                {
                    isLocked: 'True',
                    text: 'Verified Content by Doctors'
                },
                {
                    isLocked: 'False',
                    text: 'Limited access to Blogs & Vlogs'
                },
                {
                    isLocked: 'True',
                    text: 'Ask Doctors anytime - 24x7'
                },
                {
                    isLocked: 'True',
                    text: 'Video Courses from Top Experts'
                },
                {
                    isLocked: 'False',
                    text: 'Short term plans start from  GH\u20b5 8'
                },
                {
                    isLocked: 'False',
                    text: 'Connect with verified parents'
                },
                {
                    isLocked: 'False',
                    text: 'Community Support'
                }
            ]
        },
        tagged_as: '',
        plan_name: 'BASIC',
        button_background: 'https://parntune-assets.b-cdn.net/images/basic_button_background.png',
        cost_per_day: 0,
        cta: 'STARTS AT GH\u20b5 0/DAY',
        cost_per_day_new: 0
    }
];
