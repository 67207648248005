import React, { useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Gutter from '../Gutter/Gutter';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
interface hinduprop {
    setreligion: React.Dispatch<React.SetStateAction<string>>;
    setGender: React.Dispatch<React.SetStateAction<string>>;
    forAfricanCountries?: boolean;
    country?: any;
}
const SelectedLanguage = Geti18nLang();
const BabyNameHindu = (props: hinduprop) => {
    const { t } = useTranslation();
    const [hindu, sethindu] = useState(false);
    const [sikh, setsikh] = useState(false);
    const [muslim, setmuslim] = useState(false);
    const [christan, setchristan] = useState(false);
    const Hindu = () => {
        sethindu(!hindu);
    };
    const Sikh = () => {
        setsikh(!sikh);
    };
    const Muslim = () => {
        setmuslim(!muslim);
    };
    const Christian = () => {
        setchristan(!christan);
    };
    return (
        <div>
            <h3 className="font-medium text-lg mb-4 heading-1">
                {props?.forAfricanCountries
                    ? `Baby names in ${props?.country} `
                    : t('Baby names in Indian Religions')}
            </h3>
            <div className="box-1">
                {!props?.forAfricanCountries && (
                    <section>
                        <div className="p-2">
                            <div onClick={Hindu}>
                                <div className="flex justify-between items-center cursor-pointer font-normal m-1 mb-3">
                                    <Link
                                        to={
                                            SelectedLanguage === 'en'
                                                ? `/baby-names/hindu`
                                                : `/${SelectedLanguage}/baby-names/hindu`
                                        }
                                    >
                                        <p className="para-2">{t('Hindu Baby names')}</p>
                                    </Link>
                                    {/* <div className="mt-2">
                                <AiOutlineDown />
                            </div> */}
                                </div>
                                <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />

                                {/* {hindu && ( */}
                                <div className="">
                                    <Link
                                        to={
                                            SelectedLanguage === 'en'
                                                ? `/baby-names/hindu/boy-names`
                                                : `/${SelectedLanguage}/baby-names/hindu/boy-names`
                                        }
                                        className="flex text-base font-normal m-1"
                                    >
                                        {/* <span className="mt-1 bg-gray-200 h-6 w-6 pt-1 pl-1 rounded-full">
                                        <BsGenderMale />
                                    </span> */}
                                        <p className="para-3">
                                            {t('Hindu')} {t('Baby Boy Names')}
                                        </p>
                                    </Link>
                                    <Link
                                        to={
                                            SelectedLanguage === 'en'
                                                ? `/baby-names/hindu/girl-names`
                                                : `/${SelectedLanguage}/baby-names/hindu/girl-names`
                                        }
                                        className="flex m-1"
                                    >
                                        <p className="para-3">
                                            {' '}
                                            {t('Hindu')} {t('Baby Girl Names')}
                                        </p>
                                    </Link>
                                </div>
                                {/* )} */}
                            </div>
                        </div>

                        <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />

                        <div className="p-2">
                            <div onClick={Sikh}>
                                <div className="flex justify-between cursor-pointer font-normal m-1 mb-3">
                                    <Link
                                        to={
                                            SelectedLanguage === 'en'
                                                ? `/baby-names/sikh`
                                                : `/${SelectedLanguage}/baby-names/sikh`
                                        }
                                    >
                                        <p className="para-2">{t('Sikh Baby names')}</p>
                                    </Link>
                                    {/* <div className="mt-2">
                                <AiOutlineDown />
                            </div> */}
                                </div>
                                <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />

                                {/* {sikh && ( */}
                                <div className="">
                                    <Link
                                        to={
                                            SelectedLanguage === 'en'
                                                ? `/baby-names/sikh/boy-names`
                                                : `/${SelectedLanguage}/baby-names/sikh/boy-names`
                                        }
                                        className="flex text-base font-normal m-1"
                                    >
                                        <p className="para-3">
                                            {' '}
                                            {t('Sikh')} {t('Baby Boy Names')}
                                        </p>
                                    </Link>
                                    {/* <Gutter styles={{ borderBottom: '1px solid #DADADA' }} /> */}
                                    <Link
                                        to={
                                            SelectedLanguage === 'en'
                                                ? `/baby-names/sikh/girl-names`
                                                : `/${SelectedLanguage}/baby-names/sikh/girl-names`
                                        }
                                        className="flex m-1"
                                    >
                                        <p className="para-3">
                                            {' '}
                                            {t('Sikh')} {t('Baby Girl Names')}
                                        </p>
                                    </Link>
                                </div>
                                {/* )} */}
                            </div>
                        </div>

                        <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />
                    </section>
                )}
                <div className="p-2">
                    <div onClick={Muslim}>
                        <div className="flex justify-between cursor-pointer font-normal m-1 mb-3">
                            <Link
                                to={
                                    SelectedLanguage === 'en'
                                        ? `/baby-names/muslim`
                                        : `/${SelectedLanguage}/baby-names/muslim`
                                }
                            >
                                <p className="para-2">{t('Muslim Baby names')}</p>
                            </Link>
                            {/* <div className="mt-2">
                                <AiOutlineDown />
                            </div> */}
                        </div>
                        <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />

                        {/* {muslim && ( */}
                        <div className="">
                            <Link
                                to={
                                    SelectedLanguage === 'en'
                                        ? `/baby-names/muslim/boy-names`
                                        : `/${SelectedLanguage}/baby-names/muslim/boy-names`
                                }
                                className="flex text-base font-normal m-1"
                            >
                                <p className="para-3">
                                    {' '}
                                    {t('Muslim')} {t('Baby Boy Names')}
                                </p>
                            </Link>
                            <Link
                                to={
                                    SelectedLanguage === 'en'
                                        ? `/baby-names/muslim/girl-names`
                                        : `/${SelectedLanguage}/baby-names/muslim/girl-names`
                                }
                                className="flex m-1"
                            >
                                <p className="para-3">
                                    {' '}
                                    {t('Muslim')} {t('Baby Girl Names')}
                                </p>
                            </Link>
                        </div>
                        {/* )} */}
                    </div>
                </div>
                <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />
                <div className="p-2">
                    <div onClick={Christian}>
                        <div className="flex justify-between cursor-pointer font-normal m-1 mb-3">
                            <Link
                                to={
                                    SelectedLanguage === 'en'
                                        ? `/baby-names/christian`
                                        : `/${SelectedLanguage}/baby-names/christian`
                                }
                            >
                                <p className="para-2">{t('Christian Baby names')}</p>
                            </Link>
                            {/* <div className="mt-2">
                                <AiOutlineDown />
                            </div> */}
                        </div>
                        <Gutter styles={{ borderBottom: '1px solid #DADADA' }} />

                        {/* {christan && ( */}
                        <div className="">
                            <Link
                                to={
                                    SelectedLanguage === 'en'
                                        ? `/baby-names/christian/boy-names`
                                        : `/${SelectedLanguage}/baby-names/christian/boy-names`
                                }
                                className="flex text-base font-normal m-1"
                            >
                                <p className="para-3">
                                    {t('Christian')} {t('Baby Boy Names')}
                                </p>
                            </Link>
                            <Link
                                to={
                                    SelectedLanguage === 'en'
                                        ? `/baby-names/christian/girl-names`
                                        : `/${SelectedLanguage}/baby-names/christian/girl-names`
                                }
                                className="flex m-1"
                            >
                                <p className="para-3">
                                    {t('Christian')} {t('Baby Girl Names')}
                                </p>
                            </Link>
                        </div>
                        {/* )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BabyNameHindu;
