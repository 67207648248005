import { useEffect, useState } from 'react';
import { getActiveGifts } from '../../api/GiftPage/GiftPage';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { ActiveGift, ActiveGiftDataType } from '../../components/V2Components/LazyLoader/types';
import { Geti18nLang } from '../i18n utils/Geti18nLang';
import { useUserData } from './useUserData';
const SelectedLanguage = Geti18nLang();

const useActiveGiftData = (orderId: string | undefined) => {
    const { userData: user_data } = useUserData();
    const [content, setContent] = useState<ActiveGift | null>(null);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        getGifts();
    }, []);

    const getGifts = () => {
        let params: any = {
            user_data: user_data,
            language: SelectedLanguage || 'en',
            orderId: Number(orderId)
        };
        getActiveGifts(params, onSuccess, onFailure);
    };

    const onSuccess = (data: ActiveGiftDataType) => {
        setContent(data.data);
    };

    const onFailure = (error: any) => {
        setError(error.message);
    };

    return { content: content, error: error };
};

export default useActiveGiftData;
