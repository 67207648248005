import { useNavigate } from 'react-router-dom';
import { ProfileVerifiedIcon } from '../../../assets/icons/giftIcons/Icons';
import { useUserData } from '../../../utils/hooks/useUserData';
import { languages } from '../../../utils/utils';
import Header from '../../Header/Header';

function ProfileVerified() {
    const navigate = useNavigate();
    const { userData: user_data } = useUserData();

    const handleOnClick = () => {
        setTimeout(() => {
            let lng = window?.location.pathname.slice(1, 3);
            let language = languages.includes(lng);
            console.log('language', language);
            if (language) {
                console.log('lng', lng);
                navigate(`/${lng}/user/profile/${user_data?.id}`);
            } else {
                navigate(`/user/profile/${user_data?.id}`);
            }
        }, 1000);
    };
    return (
        <main className=" max-w-4xl mx-auto flex flex-col w-full h-full relative">
            <Header back onBack={() => navigate(-1)} headerText="" />
            <h2 className="text-center text-black text-[24px] font-medium mt-16">
                Congratulations!
            </h2>

            <div className="w-full flex-1 flex flex-col justify-center  items-center">
                <div className="mx-4 my-8">
                    <ProfileVerifiedIcon />
                </div>
                <p className="text-center text-[18px] font-normal">
                    Your profile has been successfully <br /> verified.
                </p>
            </div>

            <div className="fixed bottom-0 left-0 right-0 px-4 py-2 w-full flex justify-center">
                <button
                    className="max-w-4xl w-full h-[45px]  bg-[#367E8A] text-white rounded-full"
                    onClick={handleOnClick}
                >
                    DONE
                </button>
            </div>
        </main>
    );
}

export default ProfileVerified;
