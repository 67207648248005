import axios from 'axios';
import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useLocation, useNavigate } from 'react-router-dom';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import { SendEvents } from '../../utils/utils';
const SelectedLanguage = Geti18nLang();

const ParentDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const _guest_due = JSON.parse(sessionStorage.getItem('_guest_due')!) ?? '';
    const [parentDetails, setParentDetails] = useState({
        dob: {
            dd: _guest_due.dd || '',
            mm: _guest_due.mm || '',
            yyyy: _guest_due.yyyy || ''
        },
        parentoption: _guest_due.parentoption || '',
        error: {
            dob: '',
            parentoption: ''
        }
    });

    const [dateopen, setDateOpen] = useState(false);
    const [buttonenable, setButtonEnable] = useState(false);
    const today = new Date();

    useEffect(() => {
        if (parentDetails.dob.dd !== '' && parentDetails.parentoption !== '') {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }, [parentDetails.parentoption, parentDetails.dob.dd]);

    const handleAge = (e: any) => {
        const indianTimeZoneOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
        const dateInIndianTimeZone = new Date(e.getTime() + indianTimeZoneOffset);

        const year = dateInIndianTimeZone.getFullYear();
        const month = String(dateInIndianTimeZone.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(dateInIndianTimeZone.getDate()).padStart(2, '0');
        console.log(day, month, year);
        setParentDetails((prevState: any) => ({
            ...prevState,
            dob: {
                dd: day,
                mm: month,
                yyyy: year
            }
        }));
        sessionStorage.removeItem('_guest_dob');
    };

    const handleParentOption = (args: string) => {
        setParentDetails((prevState: any) => ({
            ...prevState,
            parentoption: args
        }));
    };

    const handleSubmitGuestUser = () => {
        const isGuest = true;
        const guest_uid = localStorage.getItem('guest_uid');
        const language = SelectedLanguage ? SelectedLanguage : 'en';
        const date = `${parentDetails.dob.yyyy}-${parentDetails.dob.mm}-${parentDetails.dob.dd}`;
        const isPreg = '0';

        if (guest_uid) {
            SendEvents(guest_uid, 'impression', 'btn-clicked', 'daily-focus-guest-widget');

            navigate('/guest-daily-focus', {
                state: { isGuest, language, date, isPreg, guest_uid, pathname: location.pathname }
            });

            const _guest_due = {
                dd: parentDetails.dob.dd,
                mm: parentDetails.dob.mm,
                yyyy: parentDetails.dob.yyyy,
                parentoption: parentDetails.parentoption
            };
            sessionStorage.setItem('_guest_due', JSON.stringify(_guest_due));
        }
    };

    return (
        <>
            <div className="mt-[21px] mx-[18px]">
                <div>
                    <label
                        htmlFor=""
                        className="text-[14px] text-[#242F35] font-medium"
                    >{`My Child's Date of Birth`}</label>
                    <div className="flex gap-[28px] mt-[18px]" onClick={() => setDateOpen(true)}>
                        <input
                            type="text"
                            placeholder="DD"
                            value={parentDetails.dob.dd}
                            readOnly
                            className="text-[16px] h-[54px] w-[53px] border border-[#C4C4C4] px-[12px] py-[14px] text-center rounded"
                        />
                        <input
                            type="text"
                            placeholder="MM"
                            readOnly
                            value={parentDetails.dob.mm}
                            className="text-[16px] h-[54px] w-[53px] border border-[#C4C4C4] px-[12px] py-[14px] text-center rounded"
                        />
                        <input
                            type="text"
                            placeholder="YYYY"
                            readOnly
                            value={parentDetails.dob.yyyy}
                            className="text-[16px] h-[54px] w-[75px] border border-[#C4C4C4] px-[12px] py-[14px] text-center rounded"
                        />
                    </div>
                </div>

                <div className="mt-[16px]">
                    <label htmlFor="" className="text-[14px] text-[#242F35] font-medium">
                        I am a
                    </label>
                    <div className="mt-[16px] flex gap-[24px]">
                        <button
                            onClick={() => handleParentOption('Mother')}
                            className={`h-[35px]  rounded-full px-[10px] py-[16px] ${
                                parentDetails.parentoption === 'Mother'
                                    ? 'bg-[#FC6681] text-white'
                                    : 'text-[#242F35] border-2 border-[#CED2D9]'
                            }  text-[14px] font-normal flex justify-center items-center`}
                        >
                            Mother
                        </button>
                        <button
                            onClick={() => handleParentOption('Father')}
                            className={`h-[35px]  rounded-full px-[10px] py-[16px] ${
                                parentDetails.parentoption === 'Father'
                                    ? 'bg-[#FC6681] text-white'
                                    : 'text-[#242F35] border-2 border-[#CED2D9]'
                            }  text-[14px] font-normal flex justify-center items-center`}
                        >
                            Father
                        </button>
                    </div>
                </div>

                <div className="mt-[29px] mb-[20px] flex justify-center">
                    <button
                        disabled={!buttonenable}
                        className={`${
                            buttonenable ? 'bg-[#367E8A] text-white' : 'bg-[#CED2D9] text-white'
                        } rounded-full  text-[14px] font-medium px-[18px] py-[8px] w-full h-[45px]`}
                        onClick={handleSubmitGuestUser}
                    >
                        TRACK NOW
                    </button>
                </div>
            </div>
            {dateopen && (
                <DatePicker
                    isOpen={true}
                    calendarIcon={null}
                    dayPlaceholder={'DD'}
                    yearPlaceholder="YYYY"
                    monthPlaceholder="MM"
                    format="dd-MM-yy"
                    nativeInputAriaLabel="Date"
                    onChange={handleAge}
                    onCalendarClose={() => setDateOpen(false)}
                    minDate={new Date(today.getFullYear() - 4, today.getMonth(), today.getDate())}
                    maxDate={today}
                />
            )}
        </>
    );
};

export default ParentDetails;
