import * as React from 'react';

function EyeIcon(props) {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g cliPath="url(#clip0_1192_14056)">
                <path
                    d="M6.9999 9.42266C5.66436 9.42266 4.57764 8.33594 4.57764 7.00039C4.57764 5.66484 5.66436 4.57812 6.9999 4.57812C8.33545 4.57812 9.42217 5.66484 9.42217 7.00039C9.42217 8.33594 8.33545 9.42266 6.9999 9.42266ZM6.9999 5.03516C5.91572 5.03516 5.03467 5.91621 5.03467 7.00039C5.03467 8.08457 5.91572 8.96562 6.9999 8.96562C8.08408 8.96562 8.96514 8.08457 8.96514 7.00039C8.96514 5.91621 8.08408 5.03516 6.9999 5.03516Z"
                    fill="white"
                />
                <path
                    d="M7.00024 11.1275C6.123 11.1275 5.21274 10.9155 4.29487 10.4978C3.56743 10.1665 2.83491 9.70565 2.11509 9.12802C0.895068 8.14794 0.144775 7.18056 0.113037 7.13994C0.0495605 7.05742 0.0495605 6.94316 0.113037 6.86064C0.144775 6.82001 0.893799 5.85263 2.11509 4.87256C2.83491 4.29492 3.5687 3.83408 4.29487 3.50273C5.21147 3.08506 6.12173 2.87305 7.00024 2.87305C7.87876 2.87305 8.78774 3.08506 9.70561 3.50273C10.4331 3.83408 11.1656 4.29492 11.8854 4.87256C13.1067 5.85263 13.8557 6.82001 13.8875 6.86064C13.9509 6.94316 13.9509 7.05742 13.8875 7.13994C13.8557 7.18056 13.1067 8.14794 11.8854 9.12802C11.1656 9.70565 10.4331 10.1665 9.70561 10.4978C8.78774 10.9168 7.87749 11.1275 7.00024 11.1275V11.1275ZM0.589111 7.00029C0.840479 7.30117 1.49429 8.04384 2.41089 8.7789C3.10024 9.33114 3.79976 9.7704 4.49165 10.0852C5.34731 10.4737 6.19155 10.6718 7.00151 10.6718C7.81147 10.6718 8.65571 10.475 9.51138 10.0852C10.2033 9.7704 10.9028 9.33114 11.5921 8.7789C12.5075 8.04511 13.1625 7.30243 13.4139 7.00029C13.1625 6.69941 12.5087 5.95673 11.5921 5.22168C10.9015 4.66943 10.202 4.23017 9.51011 3.91533C8.65444 3.52685 7.81021 3.32881 7.00024 3.32881C6.18901 3.32881 5.34224 3.52685 4.4853 3.91787C3.79341 4.23398 3.09136 4.67451 2.402 5.22802C1.48921 5.96181 0.839209 6.70068 0.589111 7.00029V7.00029Z"
                    fill="white"
                />
                <path
                    d="M7.9001 7.321C7.77441 7.321 7.67158 7.21816 7.67158 7.09248C7.67158 6.72178 7.36943 6.4209 7 6.4209C6.87432 6.4209 6.77148 6.31807 6.77148 6.19238C6.77148 6.0667 6.87432 5.96387 7 5.96387C7.62207 5.96387 8.12861 6.47041 8.12861 7.09248C8.12861 7.21816 8.02705 7.321 7.9001 7.321Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1192_14056">
                    <rect
                        width="13.9775"
                        height="13"
                        fill="white"
                        transform="translate(0.0112305 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default EyeIcon;
