import axios from 'axios';
import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useNavigate } from 'react-router-dom';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import { SendEvents } from '../../utils/utils';

const SelectedLanguage = Geti18nLang();

const Pregnancy = () => {
    const navigate = useNavigate();

    const _guest_dob = JSON.parse(sessionStorage.getItem('_guest_dob')!) ?? '';
    const [PregnancyDetails, setPregnancyDetails] = useState({
        due: {
            dd: _guest_dob.dd || '',
            mm: _guest_dob.mm || '',
            yyyy: _guest_dob.yyyy || ''
        },
        parentoption: _guest_dob.parentoption || '',
        error: {
            due: '',
            parentoption: ''
        }
    });

    const [dateopen, setDateOpen] = useState(false);
    const [buttonenable, setButtonEnable] = useState(false);
    const today = new Date();

    useEffect(() => {
        if (PregnancyDetails.due.dd !== '' && PregnancyDetails.parentoption !== '') {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }, [PregnancyDetails.parentoption, PregnancyDetails.due.dd]);

    const handleAge = (e: any) => {
        const indianTimeZoneOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
        const dateInIndianTimeZone = new Date(e.getTime() + indianTimeZoneOffset);

        const year = dateInIndianTimeZone.getFullYear();
        const month = String(dateInIndianTimeZone.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(dateInIndianTimeZone.getDate()).padStart(2, '0');
        console.log(day, month, year);
        setPregnancyDetails((prevState: any) => ({
            ...prevState,
            due: {
                dd: day,
                mm: month,
                yyyy: year
            }
        }));
        sessionStorage.removeItem('_guest_due');
    };

    const handleParentOption = (args: string) => {
        setPregnancyDetails((prevState: any) => ({
            ...prevState,
            parentoption: args
        }));
    };

    const handleSubmitGuestUser = () => {
        const isGuest = true;
        const guest_uid = localStorage.getItem('guest_uid');
        const language = SelectedLanguage ? SelectedLanguage : 'en';
        const date = `${PregnancyDetails.due.yyyy}-${PregnancyDetails.due.mm}-${PregnancyDetails.due.dd}`;
        const isPreg = '1';

        if (guest_uid) {
            SendEvents(guest_uid, 'impression', 'btn-clicked', 'daily-focus-guest-widget');
            navigate('/guest-daily-focus', {
                state: { isGuest, language, date, isPreg, guest_uid }
            });

            const _guest_dob = {
                dd: PregnancyDetails.due.dd,
                mm: PregnancyDetails.due.mm,
                yyyy: PregnancyDetails.due.yyyy,
                parentoption: PregnancyDetails.parentoption
            };
            sessionStorage.setItem('_guest_dob', JSON.stringify(_guest_dob));
        }
    };

    return (
        <>
            <div className="mt-[21px] mx-[18px]">
                <div>
                    <label
                        htmlFor=""
                        className="text-[14px] text-[#242F35] font-medium"
                    >{`My Due Date`}</label>
                    <div className="flex gap-[28px] mt-[18px]" onClick={() => setDateOpen(true)}>
                        <input
                            type="text"
                            placeholder="DD"
                            value={PregnancyDetails.due.dd}
                            className="text-[16px] h-[54px] w-[53px] border border-[#C4C4C4] px-[12px] py-[14px] text-center rounded"
                            readOnly
                        />
                        <input
                            type="text"
                            placeholder="MM"
                            value={PregnancyDetails.due.mm}
                            className="text-[16px] h-[54px] w-[53px] border  border-[#C4C4C4] px-[12px] py-[14px] text-center rounded"
                            readOnly
                        />
                        <input
                            type="text"
                            value={PregnancyDetails.due.yyyy}
                            placeholder="YYYY"
                            className="text-[16px] h-[54px] w-[75px] border border-[#C4C4C4] px-[12px] py-[14px] text-center rounded"
                            readOnly
                        />
                    </div>
                </div>

                <div className="mt-[16px]">
                    <label htmlFor="" className="text-[14px] text-[#242F35] font-medium">
                        I am a
                    </label>
                    <div className="mt-[16px] flex gap-[24px]">
                        <button
                            onClick={() => handleParentOption('Mother')}
                            className={`h-[35px]  rounded-full px-[10px] py-[16px] ${
                                PregnancyDetails.parentoption === 'Mother'
                                    ? 'bg-[#FC6681] text-white'
                                    : 'text-[#242F35] border-2 border-[#CED2D9]'
                            }  text-[14px] font-normal flex justify-center items-center`}
                        >
                            Mother
                        </button>
                        <button
                            onClick={() => handleParentOption('Father')}
                            className={`h-[35px]  rounded-full px-[10px] py-[16px] ${
                                PregnancyDetails.parentoption === 'Father'
                                    ? 'bg-[#FC6681] text-white'
                                    : 'text-[#242F35] border-2 border-[#CED2D9]'
                            }  text-[14px] font-normal flex justify-center items-center`}
                        >
                            Father
                        </button>
                    </div>
                </div>

                <div className="mt-[29px] mb-[20px] flex justify-center">
                    <button
                        disabled={!buttonenable}
                        className={` ${
                            buttonenable == true
                                ? 'bg-[#367E8A] text-white'
                                : 'bg-[#CED2D9] text-white'
                        } rounded-full  text-[14px] font-medium px-[18px] py-[8px] w-full h-[45px]`}
                        onClick={handleSubmitGuestUser}
                    >
                        TRACK NOW
                    </button>
                </div>
            </div>
            {dateopen && (
                <DatePicker
                    isOpen={true}
                    calendarIcon={null}
                    dayPlaceholder={'DD'}
                    yearPlaceholder="YYYY"
                    monthPlaceholder="MM"
                    format="dd-MM-yy"
                    nativeInputAriaLabel="Date"
                    onChange={handleAge}
                    onCalendarClose={() => setDateOpen(false)}
                    minDate={today}
                    maxDate={new Date(today.getFullYear(), today.getMonth() + 9, today.getDate())}
                />
            )}
        </>
    );
};

export default Pregnancy;
