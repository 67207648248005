import React, { useState } from 'react';
import { DropDownData } from './utils';

const KeenToAdoptAgeGroupDropdown = (props: any) => {
    const { selectedAgeGroup, setSelectedAgeGroup } = props;
    const [showDropDown, setShowDropDown] = useState(false);
    // const [selectedAgeGroup, setSelectedAgeGroup] = useState<any>(null);

    return (
        <div className="w-full">
            <button
                onClick={() => setShowDropDown((prev) => !prev)}
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="text-[#787878] border focus:outline-none font-normal font-roboto rounded-lg text-sm px-5 py-3 text-center flex justify-between items-center mb-3 w-full"
                type="button"
            >
                {selectedAgeGroup ?? 'Select age group'}
                <svg
                    className={`w-2.5 h-2.5 ml-2.5 ${showDropDown ? 'rotate-180' : ''}`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                    />
                </svg>
            </button>

            {showDropDown && (
                <div
                    id="dropdown"
                    className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow mt-2 w-full"
                >
                    <ul
                        className="py-2 text-sm text-gray-700"
                        aria-labelledby="dropdownDefaultButton"
                    >
                        {DropDownData.map((item, index) => (
                            <li key={index}>
                                <p
                                    onClick={() => {
                                        setSelectedAgeGroup(item.value);
                                        setShowDropDown((prev) => !prev);
                                    }}
                                    className="block px-4 py-3 hover:bg-[linear-gradient(103.83deg,_#ffeed9_39.52%,_#fb7882_147.52%)]"
                                >
                                    {item.label}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default KeenToAdoptAgeGroupDropdown;
