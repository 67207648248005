import axios from 'axios';

function FetchMyOrders(params: any, onSuccess: any, onFailure: any) {
    axios
        .get(process.env.REACT_APP_SUBSCRIPTION_SERVER + 'myBookings?pageNo=1', {
            headers: {
                Authtoken: params?.authToken,
                Instanceid: params?.instanceId
            }
        })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export default FetchMyOrders;
