import React from 'react';
import { Helmet } from 'react-helmet';

const AppMetaInfo = () => {
    return (
        <div>
            <Helmet>
                <meta
                    name="keywords"
                    content="Best Parenting Website, Parenting websites India, Parenting workshop Which gadget for my child, Children and Internet, Best parenting books, Aggression in Children,  Hobby Classes, Parenting Workshop, Parenting guide, Child Psychologist in Delhi, Parenting sites, How to parent a child, Tips for parents, parenting information, parenting advice"
                />
                <meta
                    name="title"
                    content="Parentune - Best Parenting Website parent workshop in India"
                />
                <meta name="image" content="https://img1.parentune.com/web/images/logo.png" />
                <meta
                    name="description"
                    content="Need reliable parenting advice? Join Parentune, a rapidly growing pro-parent community of verified parents, and receive a personalized parenting support for your child!"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="Parentune" />
                <meta
                    name="twitter:title"
                    content="Parentune - Best Parenting Website parent workshop in India"
                />
                <meta
                    name="twitter:description"
                    content="Need reliable parenting advice? Join Parentune, a rapidly growing pro-parent community of verified parents, and receive a personalized parenting support for your child!"
                />
                <meta
                    name="twitter:image:src"
                    content="https://img1.parentune.com/web/images/logo.png"
                />
                <meta name="twitter:creator" content="Parentune" />
                <meta property="og:type" content="article" />
                <meta
                    property="og:title"
                    content="Parentune - Best Parenting Website parent workshop in India"
                />
                <meta
                    property="og:image"
                    content="https://img1.parentune.com/web/images/logo.png"
                />
                <meta
                    property="og:description"
                    content="Need reliable parenting advice? Join Parentune, a rapidly growing pro-parent community of verified parents, and receive a personalized parenting support for your child!"
                />
                <meta property="og:site_name" content="Parentune" />
            </Helmet>
        </div>
    );
};

export default AppMetaInfo;
