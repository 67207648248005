import { Menu, Transition } from '@headlessui/react';
import DisclosureNew from './Disclosure';
import FilterIcon from '../Icons/FilterIcon';
import { clearFilters } from '../../store/slices/filterSlice';
import { useAppDispatch } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { useFilterData } from '../../utils/hooks/useFilterData';

export default function Filter() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { topicId, ageGroup } = useFilterData();

    const handleClear = () => {
        dispatch(clearFilters({ topicId: null, ageGroup: null }));
    };

    const getUrl = (val: string) => {
        let url = '/';

        let topicId = val;

        if (ageGroup && topicId) {
            url = `https://www.parentune.com/search?keyword=&filterAgeGroup=${ageGroup}&filter_topic=${topicId}&submit=`;
        } else if (ageGroup && !topicId) {
            url = `https://www.parentune.com/search?keyword=&filterAgeGroup=${ageGroup}&filter_topic=&submit=`;
        } else if (topicId && !ageGroup) {
            url = `https://www.parentune.com/search?keyword=&filterAgeGroup=&filter_topic=${topicId}&submit=`;
        }

        return url;
    };

    const handleApply = (val: string) => {
        let url = getUrl(val);
        if (val) {
            window.location.assign(url);
        }
    };

    return (
        <div className="flex items-center justify-center p">
            <div className="relative inline-block text-left">
                <Menu>
                    {({ open }) => (
                        <span className="rounded-md shadow-sm">
                            <Menu.Button
                                aria-label="exapand  age groups"
                                className={`inline-flex justify-center w-full px-1 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white rounded-md hover:text-gray-500 focus:outline-1 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800`}
                            >
                                <span>
                                    <FilterIcon color={open ? '#377E8B' : ''} />
                                </span>
                            </Menu.Button>
                            <Transition
                                show={open}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    static
                                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                >
                                    <DisclosureNew handleApply={handleApply} />
                                </Menu.Items>
                            </Transition>
                        </span>
                    )}
                </Menu>
            </div>
        </div>
    );
}
