import backicon from '../../assets/booksummary/back-icon.svg';
import { Navigate, useLocation } from 'react-router-dom';
import { TopHeader } from './TopHeader';
import PaymentInfo from './PaymentInfo';
import { DoctorCard } from './DoctorCard';
import { DateSummary } from './DateSummary';
import WorkShopInput from './WorkShopInput';
import { useUserData } from '../../utils/hooks/useUserData';

interface LocationState {
    start_date: string;
    title: string;
    amount: string;
    avatar: string;
    name: string;
    specialization: string;
    id: number;
    status: string;
}

function BookingSummary() {
    const location = useLocation();
    const state = location.state as LocationState;
    const { userData } = useUserData();

    if (!userData) return <Navigate to="/livechats" />;

    if (!state) {
        return <Navigate to="/livechats" />;
    }

    return (
        <>
            {
                <div className="flex justify-center">
                    <div className="max-w-4xl w-full">
                        <TopHeader icon={backicon} content="Booking Summary" />
                        <PaymentInfo state={state} />
                        <DoctorCard state={state} />
                        <DateSummary state={state} />
                        <WorkShopInput state={state} />
                    </div>
                </div>
            }
        </>
    );
}

export default BookingSummary;
