import * as React from 'react';

function ArrowupIcon(props) {
    return (
        <svg
            width={12}
            height={7}
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 6.333a.833.833 0 01-.534-.192l-5-4.166A.834.834 0 011.533.69l4.466 3.734 4.467-3.6a.833.833 0 011.175.125.834.834 0 01-.117 1.216l-5 4.025A.833.833 0 016 6.333z"
                fill={props.color ? props.color : '#C4C4C4'}
            />
        </svg>
    );
}

export default ArrowupIcon;
