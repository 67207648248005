import { t } from 'i18next';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';

export type MakeBreadCrumbsTypes = {
    gender: string;
    religion: string;
    religionTypes: string[];
};

const filterItem = (item: string) => {
    return !['hi', '', 'te', 'ta', 'mr', 'en'].includes(item);
};

const SelectedLanguage = Geti18nLang();

const getPagesNewObj = ({ gender, religion, religionTypes }: MakeBreadCrumbsTypes) => {
    const { pathname } = location;

    const pageList = pathname.split('/').filter(filterItem);
    let pageListNew: { name: string; href: string }[] = [];
    let char: any = '';
    let pageLength = pageList.length;

    if (pageLength === 1) {
        return [];
    } else if (pageLength === 2) {
        if (religionTypes.includes(pageList[1])) {
            religion = pageList[1];
        }
        if (genderList.includes(pageList[1])) {
            gender = pageList[1];
        }
        if (pageList[1].includes('names-starting-with')) {
            char = pageList[1].split('-').pop();
        } else if (pageList[1].includes('starting-letter')) {
            char = pageList[1].split('-').pop();
        }
    } else if (pageLength === 3) {
        if (religionTypes.includes(pageList[1])) {
            religion = pageList[1];
        }
        if (genderList.includes(pageList[1].split('-')[0])) {
            gender = pageList[1].split('-')[0];
        }
        if (pageList[2].includes('names-starting-with')) {
            char = pageList[2].split('-').pop();
        } else if (pageList[2].includes('starting-with')) {
            char = pageList[2].split('-').pop();
        }
    }

    console.log('pathLinks', pathLinks);

    pathLinks.forEach((item) => {
        let link = SelectedLanguage === 'en' ? item.link : `/${SelectedLanguage}${item.link}`;
        link = link.replaceAll('{char}', char);
        link = link.replaceAll('{gender}', gender);
        link = link.replaceAll('{religion}', religion);

        console.log('link', link);
        if (pathname === link) {
            let baseUrl =
                SelectedLanguage === 'en'
                    ? '/baby-names/'
                    : '/' + SelectedLanguage + '/baby-names/';
            if (item.type === 1) {
                pageListNew.push({
                    name: t('baby names'),
                    href: baseUrl
                });
            } else if (item.type === 2) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name: t('page_title_alphabet').replaceAll('{char}', t(char)),
                        href: baseUrl + 'all'
                    }
                );
            } else if (item.type === 3) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name: t('br_gender').replaceAll('{gender}', t(gender)),
                        href: baseUrl + gender
                    }
                );
            } else if (item.type === 5) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name:
                            SelectedLanguage === 'en'
                                ? t('br_title_origin').replaceAll('{religion}', t(religion))
                                : t('br_title_origin').replaceAll('{religion}', t(religion)),
                        href: baseUrl + gender
                    }
                );
            } else if (item.type === 6) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name: t('br_title_origin').replaceAll('{religion}', t(religion)),
                        href: baseUrl + religion
                    },
                    {
                        name: t('page_title_alphabet')
                            .replaceAll('{char}', t(char))
                            .replaceAll('{religion}', t(religion)),
                        href: baseUrl + gender
                    }
                );
            } else if (item.type === 4) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name: t('br_gender').replaceAll('{gender}', t(gender)),
                        href: baseUrl + gender
                    },
                    {
                        name: t('page_title_alphabet').replaceAll('{char}', t(char)),
                        href: baseUrl + char
                    }
                );
            } else if (item.type === 7) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name: t('br_title_origin').replaceAll('{religion}', t(religion)),
                        href: baseUrl + religion
                    },
                    {
                        name: t('br_gender').replaceAll('{gender}', t(gender)),
                        href: baseUrl + gender
                    }
                );
            } else if (item.type === 8) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: baseUrl
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    },
                    {
                        name: t('br_title_origin').replaceAll('{religion}', t(religion)),
                        href: baseUrl + religion
                    },
                    {
                        name: t('br_gender').replaceAll('{gender}', t(gender)),
                        href: baseUrl + gender
                    },
                    {
                        name: t('page_title_alphabet').replaceAll('{char}', t(char)),
                        href: baseUrl + char
                    }
                );
            } else if (item.type === 9) {
                pageListNew.push(
                    {
                        name: t('baby names'),
                        href: '/baby-names/kenya'
                    },
                    {
                        name: t('all'),
                        href: baseUrl + 'all'
                    }
                );
            }
        }
    });
    // todo fix this

    return [
        {
            name: t('baby names'),
            href: '/baby-names/africa'
        },
        {
            name: t('all'),
            href: '' + 'all'
        }
    ];
    console.log('pageListNew', pageListNew);

    return pageListNew;
};

export { getPagesNewObj };

const pathLinks = [
    {
        link: '/baby-names',
        type: 1
    },
    {
        link: '/baby-names/starting-letter-{char}',
        type: 2
    },
    {
        link: '/baby-names/{gender}',
        type: 3
    },
    {
        link: '/baby-names/{gender}/starting-with-{char}',
        type: 4
    },
    {
        link: '/baby-names/{religion}',
        type: 5
    },
    {
        link: '/baby-names/{religion}-names-starting-with-{char}',
        type: 6
    },
    {
        link: '/baby-names/{religion}/{gender}-names',
        type: 7
    },
    {
        link: '/baby-names/{religion}/{gender}-names-starting-with-{char}',
        type: 8
    },
    {
        link: '/baby-names/all',
        type: 9
    }
];

const genderList = ['boy', 'girl'];
