import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import roadBlockSlice from './slices/roadBlockSlice';
import userDataSlice from './slices/userDataSlice';
import homePageSlice from './slices/homePageSlice';
import filterSlice from './slices/filterSlice';
import useBasicInfoSlice from './slices/useBasicInfoSlice';
import onboardingSlice from './slices/onboardingSlice';
import utilsSlice from './slices/utilsSlice';

export const store = configureStore({
    reducer: {
        user: userDataSlice,
        userBasicInfo: useBasicInfoSlice,
        roadBlock: roadBlockSlice,
        homePage: homePageSlice,
        filter: filterSlice,
        onboarding: onboardingSlice,
        utilSlice: utilsSlice
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
