import React from 'react';
import { Helmet } from 'react-helmet';

interface GiftMetaTagsProps {
    senderName: string | undefined;
    image: string;
    url: string;
}
const GiftMetaTags = (props: GiftMetaTagsProps) => {
    const { senderName, image, url } = props;

    return (
        <Helmet>
            {/* Open Graph meta tags for Facebook, LinkedIn, WhatsApp */}
            <meta property="og:title" content="Congratulations Sangeet!" />
            <meta
                property="og:description"
                content={`You have received a special gift from ${senderName} — a Parentune Plus subscription. Enjoy the benefits and join 5 million parents on this enriching journey.`}
            />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Parentune" />

            {/* Twitter Card meta tags for Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Congratulations Sangeet!" />
            <meta
                name="twitter:description"
                content={`You have received a special gift from ${senderName} — a Parentune Plus subscription. Enjoy the benefits and join 5 million parents on this enriching journey.`}
            />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:site" content="@YourTwitterHandle" />

            {/* General meta tags (in case any other platform needs these) */}
            <meta
                name="description"
                content={`You have received a special gift from ${senderName}.`}
            />
            <meta name="author" content="Parentune" />
        </Helmet>
    );
};

export default GiftMetaTags;
