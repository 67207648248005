import React, { useCallback, useEffect, useRef, useState } from 'react';
import './EditProfile.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import AddChildComponent from '../../../AddChildComponent copy/AddChildComponent';
import DOBForm from '../../../DOBForm/DOBForm';
import Header from '../../../Header/Header';
import InputBox from '../../../V2Components/InputBox';
import DropDown from '../../../V2Components/DropDown';
import ButtonPrimary from '../../../V2Components/ButtonPrimary';
import { useProfile, useProfileEdit } from '../../../../api/profile';
import LoadingLayout from '../../../V2Components/LoadingLayout';
import { useUserData } from '../../../../utils/hooks/useUserData';
import SelectionForm from '../../../../parts/SelectionForm/SelectionForm';
import { StageData } from '../../EditProfileParentSelect/utils';
import { UpdateProfile } from '../../../../api/OnBoarding/UpdateProfile';
import { GetUserProfile } from '../../../../api/OnBoarding/GetUserProfile';
import { useDispatch } from 'react-redux';
import { addUserdata } from '../../../../store/slices/userDataSlice';
import OnboardingPage from '../../../../pages/OnboardingPage/OnboardingPage';
import { setForEditProfile } from '../../../../store/slices/onboardingSlice';
import { useOnboardingData } from '../../../../utils/hooks/useOnboardingData';
import ToastMessage from '../../../Toast/ToastMessage';
import DOBInput from '../../../../pages/ParentTypeInputPage/DOBInput';
import ProfilePic from '../../../ProfilePic/ProfilePic';
import {
    checkIfDataisNotNull,
    checkifChildDataChanged,
    isDueDateChanged,
    languageOption
} from './utils.js';
import axios from 'axios';
import {
    SendEvents,
    languages,
    country_codes_w_phone,
    phoneNumberLengths,
    phoneRegexByCountryList
} from '../../../../utils/utils';
import { t } from 'i18next';
import { ProfileSkeleton } from '../../../Loader/Others/Skeletal';
import { PhoneIcon } from '@heroicons/react/outline';
import PhoneNumber from '../../../PhoneNumber/PhoneNumber';
import NavigateNextIcon from '../../../../assets/icons/NavigateNextIcon';
import OTPInput from '../../../OTPInput/OTPInput';
import { emailRegex, TIMER_LENGTH } from '../../../../pages/OTPPage/utils';
import useFetchUserLocation from '../../../../utils/hooks/useFetchUserLocation';
import { ProfileEditIcon } from '../../../../assets/icons/giftIcons/Icons';

interface VerifyInfoType {
    phonenumber: string;
    email: string;
    error: {
        phonenumber: string;
        email: string;
    };
}

interface LocationType {
    verify: boolean;
}

function EditProfile() {
    //pushEventToGtm('page_view', { pt_page: 'EditProfile' });
    let timerPhone: NodeJS.Timer;
    let timerEmail: NodeJS.Timer;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as LocationType | undefined;
    const [inputOTPPhone, setInputOTPPhone] = useState('');
    const [inputOTPEmail, setInputOTPEmail] = useState('');

    const [phoneincorrectOTP, setPhoneIncorrectOTP] = useState(false);
    const [emailincorrectOTP, setEmailIncorrectOTP] = useState(false);

    const [otpResetPhone, setOtpResetPhone] = useState<string>('');
    const [otpResetEmail, setOtpResetEmail] = useState<string>('');

    const [resendforPhone, setResendforPhone] = useState(false);
    const [resendforEmail, setResendforEmail] = useState(false);

    const [numberVerifyIconClick, setNumberVerifyIconClick] = useState(false);
    const phoneNumberRef = useRef<HTMLInputElement | null>(null);
    const [emailVerifyIconClick, setEmailVerifyIconClick] = useState(false);
    const emailRef = useRef<HTMLInputElement | null>(null);

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [dob, setDob] = useState('');
    const [phoneRegexByCountry, setPhoneRegexByCountry] = useState<RegExp>(/^\+91[1-9][0-9]{9}$/);
    const [phoneLengthByCountry, setPhoneLengthByCountry] = useState<number>(10);
    const [localtionDetails, setLocationDetails] = useState({
        countrycode: '',
        countryname: ''
    });
    const [phoneOtpStatus, setPhoneOtpStatus] = useState<boolean>(false);
    const [emailOtpStatus, setEmailOtpStatus] = useState<boolean>(false);
    const [err, setErr] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);
    const [isVerifiedInfo, setIsVerifyInfo] = useState({
        phonenumber: '',
        email: ''
    });

    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [isValidNumber, setIsValidNumber] = useState<boolean>(false);
    const [countDownPhone, setcountDownPhone] = useState(TIMER_LENGTH);
    const [countDownEmail, setcountDownEmail] = useState(TIMER_LENGTH);
    const [verifyInfo, setVerifyInfo] = useState<VerifyInfoType>({
        phonenumber: '',
        email: '',
        error: {
            phonenumber: '',
            email: ''
        }
    });
    const [initialVerifyInfo, setInitialVerifyInfo] = useState({
        phonenumber: '',
        email: ''
    });

    const lenPhone = inputOTPPhone.length;
    const lenEmail = inputOTPEmail.length;
    const dispatch = useDispatch();

    const { userData: user_data } = useUserData();

    const [selectParentType, setSelectParentType] = useState(false);

    // const { data, isLoading } = useProfile(parseInt(user_data?.id ?? ''));

    useEffect(() => {
        if (numberVerifyIconClick) {
            phoneNumberRef.current?.focus();
        }
    }, [numberVerifyIconClick]);

    useEffect(() => {
        if (emailVerifyIconClick) {
            emailRef.current?.focus();
        }
    }, [emailVerifyIconClick]);

    const [details, setDetails] = useState<any>(null);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_ONBOARDING_SERVER2 + 'auth/verify-profile?verifyMode=both', {
                headers: {
                    authToken: user_data ? user_data.authToken : '',
                    instanceId: user_data ? user_data.instanceId : '',
                    platform: 'web'
                }
            })
            .then((res) => {
                console.log('data:', res.data);

                if (res.data?.data.emailVerified && res.data?.data.userEmail) {
                    setIsVerifyInfo((prev) => ({
                        ...prev,
                        email: res.data?.data.userEmail
                    }));
                } else {
                    setEmailVerifyIconClick(true);
                }

                if (res.data?.data.mobileVerified && res.data?.data.userMobile) {
                    setIsVerifyInfo((prev) => ({
                        ...prev,
                        phonenumber: res.data?.data.userMobile.substring(2)
                    }));
                } else {
                    setNumberVerifyIconClick(true);
                }
            })
            .catch((error) => {
                console.log('error:', error);
            });
    }, []);

    useEffect(() => {
        if (localStorage.getItem('login_data')) {
            // console.log('hii');
            setShowToaster(true);
            setTimeout(() => {
                setResendforPhone(true);
                setResendforEmail(true);
            }, TIMER_LENGTH * 1000);
        }
    }, [localStorage.getItem('login_data')]);

    useEffect(() => {
        // console.log('countDown', countDown);
        if (countDownPhone > 0) {
            timerPhone = setInterval(() => {
                setcountDownPhone(countDownPhone - 1);
            }, 1000);
            return () => clearInterval(timerPhone);
        }
    }, [countDownPhone]);

    useEffect(() => {
        // console.log('countDown', countDown);
        if (countDownEmail > 0) {
            timerEmail = setInterval(() => {
                setcountDownEmail(countDownEmail - 1);
            }, 1000);
            return () => clearInterval(timerEmail);
        }
    }, [countDownEmail]);

    useEffect(() => {
        setPhoneIncorrectOTP(false);
    }, [inputOTPPhone]);

    useEffect(() => {
        setEmailIncorrectOTP(false);
    }, [inputOTPEmail]);

    useEffect(() => {
        if (inputOTPPhone.length !== 4) return;
        isValidNumber && handleVerifyOTP('mobile');
    }, [inputOTPPhone]);

    useEffect(() => {
        if (inputOTPEmail.length !== 4) return;
        isValidEmail && handleVerifyOTP('email');
    }, [inputOTPEmail]);

    useEffect(() => {
        useFetchUserLocation()
            .then((res) => {
                setLocationDetails((prev) => ({
                    ...prev,
                    countrycode: res?.country_code || '+91',
                    countryname: res?.country_name || 'India'
                }));

                const countryNameCode = Object.keys(country_codes_w_phone).find((key) => {
                    return country_codes_w_phone[key] === res?.country_code;
                });

                if (countryNameCode) {
                    setPhoneLengthByCountry(phoneNumberLengths[countryNameCode]);
                    setPhoneRegexByCountry(phoneRegexByCountryList[countryNameCode]);
                }
            })
            .catch((err) => {
                console.log('error:', err);
            });
    }, []);

    const FetchProfileData = async () => {
        return axios
            .get(
                process.env.REACT_APP_ONBOARDING_SERVER2 +
                    `profile?type=advanced&userId=${user_data?.id}`,
                {
                    headers: {
                        authToken: user_data ? user_data.authToken : '',
                        instanceId: user_data ? user_data.instanceId : '',
                        platform: 'web'
                    }
                }
            )
            .then((res: any) => {
                // dispatch();
                dispatch(addUserdata({ ...res?.data?.data }));
                setDetails(res.data?.data);
                setVerifyInfo((prev) => ({
                    ...prev,
                    email: res.data?.data.email,
                    phonenumber: res.data?.data.mobile.substring(2)
                }));
                setInitialVerifyInfo((prev) => ({
                    ...prev,
                    email: res.data?.data.email,
                    phonenumber: res.data?.data.mobile.substring(2)
                }));
            })
            .catch((error) => console.log(error));
    };
    useEffect(() => {
        FetchProfileData();
    }, [window.location.pathname]);

    const [parentTypeInputComponent, setParentTypeInputComponent] = useState<any[]>([]);
    const [otherChildren, setOtherChildren] = useState<any[]>([]);
    const [childDataNew, setChildDataNew] = useState<any>(null);
    const [x, setData] = useState<any>(null);
    const [showToaster, setShowToaster] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<any>(null);
    const [avatar, setAvatar] = useState<
        | {
              name: string;
              url: string;
          }
        | undefined
    >({ name: 'Guest', url: user_data?.avatar });
    const [enablesaveBtn, setEnableSaveBtn] = useState(false);
    const [isLoading, setisLoading] = useState(true);

    const { isForEditProfile } = useOnboardingData();

    // todo on this if toaster not working properly
    // useEffect(() => {
    //     if (!showToaster) return;
    //     setTimeout(() => {
    //         setShowToaster(false);
    //     }, 2000);
    //     console.log('showToaster >>>> called ', showToaster);
    // }, [showToaster]);

    useEffect(() => {
        if (!x || !name) return;

        // console.log(' x', x);
        // console.log('checkIfDataisNotNull', checkIfDataisNotNull(x).length);

        if (x.length === checkIfDataisNotNull(x).length) setEnableSaveBtn(true);
        else setEnableSaveBtn(false);

        if (name.length < 3) setEnableSaveBtn(false);
        // else setEnableSaveBtn(false);

        if (sessionStorage.getItem('button-first-time') === 'true') {
            setEnableSaveBtn(false);
        }
        // if (verifyInfo.email && !emailRegex.test(verifyInfo.email)) setEnableSaveBtn(false);
        // if (verifyInfo.phonenumber && verifyInfo.phonenumber.length !== phoneLengthByCountry)
        //     setEnableSaveBtn(false);
        // if (verifyInfo.error.email || verifyInfo.error.phonenumber) setEnableSaveBtn(false);
        // if (isVerifiedInfo.email && verifyInfo.email !== isVerifiedInfo.email)
        //     setEnableSaveBtn(false);
        // if (isVerifiedInfo.phonenumber && verifyInfo.phonenumber !== isVerifiedInfo.phonenumber)
        //     setEnableSaveBtn(false);

        // console.log(name.length < 3, 'name.length < 3');

        // console.log(checkifChildDataChanged(x, user_data?.kidsList));
        // if (checkifChildDataChanged(x, user_data?.kidsList)) setEnableSaveBtn(true);
        // else {
        //     setEnableSaveBtn(false);
        // }
    }, [name, x]);

    const handleSuccessUpdateProfile = (s: any) => {
        if (s.statusCode !== 200) return;

        GetUserProfile(
            async (data: any) => {
                dispatch(addUserdata({ ...data?.data }));
                setProfileLoading(false);
            },
            (err) => {
                console.error('Error: ', err);
                setProfileLoading(false);
            },
            user_data
        );
    };

    const handleFailureUpdateProfile = (error: any) => {
        console.log('handleFailureUpdateProfile', error);
    };

    const NavigateToProfile = async () => {
        setTimeout(() => {
            let lng = window?.location.pathname.slice(1, 3);
            let language = languages.includes(lng);

            if (!state?.verify && isVerifiedInfo.phonenumber && isVerifiedInfo.email) {
                navigate('/user/verified');
                return true;
            }

            if (language) {
                navigate(`/${lng}/user/profile/${user_data?.id}`);
            } else {
                navigate(`/user/profile/${user_data?.id}`);
            }
        }, 1000);
        return true;
    };
    const UpdateProfileHandler = async () => {
        setShowToaster(true);
        await UpdateName();
        await handleAddChild();
        await handleDueDateOfDelivery();
        await AddChildWhenNoKidsList();
        await NavigateToProfile();
    };

    const AddChildWhenNoKidsList = async () => {
        if (details?.kidsList.length > 0) return;
        if (x.length !== checkIfDataisNotNull(x).length) return;
        const data = {
            parentType: user_data?.parentType,
            parentStage: 'Parent',
            childs: x
        };

        setProfileLoading(true);

        const res = await UpdateProfile(
            handleSuccessUpdateProfile,
            handleFailureUpdateProfile,
            user_data,
            5,
            data
        );

        console.log('AddChildWhenNoKidsList', res);
        return true;
    };

    const UpdateName = async (): Promise<any> => {
        if (details?.name === name) return;
        if (name.length < 3) return;
        setProfileLoading(true);
        const res = await UpdateProfile(
            handleSuccessUpdateProfile,
            handleFailureUpdateProfile,
            user_data,
            1,
            {
                name
            }
        );

        console.log('res', res);
        return true;
    };

    const handleAddChild = async () => {
        console.log('handleAddChild()');
        if (!checkifChildDataChanged(x, user_data?.kidsList)) return;

        console.log(x, otherChildren);
        const data = {
            parentType: user_data?.parentType,
            parentStage: 'Parent',
            childs: x
        };

        setProfileLoading(true);

        // todo:  enable this later

        const res = await UpdateProfile(
            handleSuccessUpdateProfile,
            handleFailureUpdateProfile,
            user_data,
            5,
            data
        );

        console.log('res', res);
        // console.log('childDataNew', childDataNew);
    };

    // const { mutateAsync, isLoading: profileLoading } = useProfileEdit(parseInt(id ?? ''));

    useEffect(() => {
        if (!details) return;

        console.log('details:', details);
        console.log('dddddd', details?.dueDate);
        setName(details?.name ?? '');
        setDueDate(details?.dueDate);
        setType(details?.parentType ?? '');
        setDob(details?.dueDate ?? '');
        setOtherChildren([...details.kidsList]);
        setisLoading(false);
    }, [details]);

    useEffect(() => {
        if (!user_data) return;

        const kidsFromUserData = user_data?.kidsList.map((kid: any) => {
            return {
                name: kid?.name,
                id: kid?.id,
                gender: kid?.gender,
                dob: new Date(kid?.dob).toISOString().substr(0, 10)
            };
        });
        setChildDataNew([...kidsFromUserData]);
    }, [user_data]);

    // console.log(data);

    // const handleRemoveChild = (kid: any, index: any) => {
    //     const data = [...otherChildren];
    //     setOtherChildren([...data.filter((_, i) => i !== index)]);
    // };

    const handleDueDateOfDelivery = async () => {
        if (!dueDate) return false;
        const hasDueDateChanged = isDueDateChanged(dueDate, details?.dueDate);

        if (details?.kidsList.length > 0) {
            if (!hasDueDateChanged) return;
        }

        const body = {
            parentType: details?.parentType,
            parentStage: details?.parentStage,
            dueDate: new Date(dueDate).toISOString().substr(0, 10)
        };
        console.log('body for dueDate', body);

        const res = await UpdateProfile(
            handleSuccessUpdateProfile,
            handleFailureUpdateProfile,
            user_data,
            4,
            body
        );

        console.log('handleDueDateOfDelivery', res);

        return true;
        // const DueDateData = await ProfileUpdate(
        //     user.AuthData.authToken,
        //     user.AuthData.instanceId,
        //     body,
        //     4
        // );
        // if (DueDateData.statusCode !== 200) {
        //     ToastMessage('Unable to update profile please try again', -100);
        //     return false;
        // }
    };

    const HandleSlotSlectionChange = (e: any) => {
        const val = e.target.value;
        const ans = languageOption.find((i) => i.label === val)?.value;

        // console.log('ans', ans);

        if (window.history?.pushState) {
            window.history.pushState(
                'Parentune',
                '',
                ans == 'en' ? '/edit-profile' : `/${ans}/edit-profile`
            );
            window.location.reload();
        } else {
            // Fallback for browsers that do not support the History API
            window.location.hash = window.location.href;
        }
    };

    const handleVerifyOTP = (type: string) => {
        let payload = {};
        if (type.toLowerCase() === 'email') {
            payload = {
                mode: 'email',
                email: verifyInfo.email,
                otp: inputOTPEmail
            };
        }

        if (type.toLowerCase() === 'mobile') {
            payload = {
                mode: 'mobile',
                mobile: verifyInfo.phonenumber,
                country: localtionDetails.countryname.toUpperCase() || 'INDIA',
                countryCode: localtionDetails.countrycode.substring(1) || '91',
                otp: inputOTPPhone
            };
        }

        axios
            .post(process.env.REACT_APP_ONBOARDING_SERVER2 + 'auth/verify-profile-otp', payload, {
                headers: {
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || ''
                }
            })
            .then((res) => {
                if (res.data.statusCode === 400) {
                    if (type.toLowerCase() === 'email') {
                        setEmailIncorrectOTP(true);
                    }

                    if (type.toLowerCase() === 'mobile') {
                        setPhoneIncorrectOTP(true);
                    }

                    return;
                }
                if (type.toLowerCase() === 'mobile') {
                    setPhoneOtpStatus(false);
                    setIsValidNumber(false);
                    setIsVerifyInfo((prev) => ({
                        ...prev,
                        phonenumber: verifyInfo.phonenumber
                    }));
                    setNumberVerifyIconClick(false);
                    setEnableSaveBtn(true);
                    setInitialVerifyInfo((prev) => ({
                        ...prev,
                        phonenumber: verifyInfo.phonenumber
                    }));
                }

                if (type.toLowerCase() === 'email') {
                    setEmailOtpStatus(false);
                    setIsValidEmail(false);
                    setIsVerifyInfo((prev) => ({
                        ...prev,
                        email: verifyInfo.email
                    }));
                    setEmailVerifyIconClick(false);
                    setEnableSaveBtn(true);
                    setInitialVerifyInfo((prev) => ({
                        ...prev,
                        email: verifyInfo.email
                    }));
                }

                if (type.toLowerCase() === 'email') {
                    SendEvents(
                        user_data.userId,
                        'impression',
                        'profile-verify-otp-email',
                        'verified-profile'
                    );
                } else {
                    SendEvents(
                        user_data.userId,
                        'impression',
                        'profile-verify-otp-mobile',
                        'verified-profile'
                    );
                }
            })
            .catch((error) => {
                console.log('error:', error);
            });
    };

    const handleSendOtp = (type: string) => {
        if (type.toLowerCase() === 'mobile') {
            if (!phoneRegexByCountry.test(localtionDetails.countrycode + verifyInfo.phonenumber)) {
                setVerifyInfo((prev) => ({
                    ...prev,
                    error: {
                        ...prev.error,
                        phonenumber: 'This mobile number is invalid'
                    }
                }));
                return;
            }

            setInputOTPPhone('');
            setPhoneIncorrectOTP(false);
            setResendforPhone(false);
            setcountDownPhone(TIMER_LENGTH);
            setPhoneIncorrectOTP(false);
        }

        if (type.toLowerCase() === 'email') {
            if (!emailRegex.test(verifyInfo.email)) {
                setVerifyInfo((prev) => ({
                    ...prev,
                    error: {
                        ...prev.error,
                        phonenumber: 'This email  is invalid'
                    }
                }));
                return;
            }
            setInputOTPEmail('');
            setEmailIncorrectOTP(false);
            setResendforEmail(false);
            setcountDownEmail(TIMER_LENGTH);
            setEmailIncorrectOTP(false);
        }

        let payload = {};
        if (type.toLowerCase() === 'mobile') {
            payload = {
                mode: 'mobile',
                mobile: verifyInfo.phonenumber,
                country: localtionDetails.countryname.toUpperCase() || 'INDIA',
                countryCode: localtionDetails.countrycode.substring(1) || '91'
            };
        }

        if (type.toLowerCase() === 'email') {
            payload = {
                mode: 'email',
                email: verifyInfo.email
            };
        }

        if (type.toLowerCase() === 'email') {
            setTimeout(() => {
                setResendforEmail(true);
            }, TIMER_LENGTH * 1000);
        }

        if (type.toLowerCase() === 'mobile') {
            setTimeout(() => {
                setResendforPhone(true);
            }, TIMER_LENGTH * 1000);
        }

        axios
            .post(process.env.REACT_APP_ONBOARDING_SERVER2 + 'auth/verify-profile', payload, {
                headers: {
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || ''
                }
            })
            .then((res) => {
                if (res.data.statusCode === 400) {
                    setIsValidNumber(false);
                    if (type.toLowerCase() === 'mobile') {
                        setVerifyInfo((prev) => ({
                            ...prev,
                            error: {
                                ...prev.error,
                                phonenumber: 'This mobile number already exists'
                            }
                        }));
                    }
                    if (type.toLowerCase() === 'email') {
                        setVerifyInfo((prev) => ({
                            ...prev,
                            error: {
                                ...prev.error,
                                email: 'This email already exists'
                            }
                        }));
                    }
                    return;
                }

                if (type.toLowerCase() === 'mobile') {
                    setPhoneOtpStatus(true);
                }

                if (type.toLowerCase() === 'email') {
                    setEmailOtpStatus(true);
                }

                if (
                    type.toLowerCase() === 'mobile' &&
                    !(verifyInfo.phonenumber === isVerifiedInfo.phonenumber)
                ) {
                    setIsValidNumber(true);
                }

                if (
                    type.toLowerCase() === 'email' &&
                    !(verifyInfo.email === isVerifiedInfo.email)
                ) {
                    setIsValidEmail(true);
                }

                setVerifyInfo((prev) => ({
                    ...prev,
                    error: {
                        ...prev.error,
                        phonenumber: '',
                        email: ''
                    }
                }));

                console.log('otp:', res.data);
            })
            .catch((err) => {
                console.log('error:', err);
            });
    };

    const component = (
        <section className="w-full h-full relative">
            {showToaster && (
                <div className="w-full h-auto">
                    <ToastMessage showToaster={showToaster} msg="Profile updated successfully" />
                </div>
            )}
            <div className="max-w-4xl mx-auto font-roboto text-sm font-normal fixed bottom-0 left-0 right-0 z-40 px-4 py-2 ">
                <ButtonPrimary
                    className="max-w-4xl mx-auto font-roboto text-sm font-normal"
                    isLoading={profileLoading}
                    disabled={!enablesaveBtn}
                    onClick={UpdateProfileHandler}
                    size="large"
                    text="Save changes"
                />
            </div>

            <div className="max-w-4xl pt-0 px-2 py-4 md:w-10/12 mx-auto flex flex-col gap-3 animate-fade-in-left">
                <Header back onBack={() => navigate(-1)} headerText="Edit Profile" />
                {!(isVerifiedInfo.email && isVerifiedInfo.phonenumber) && (
                    <h2 className="text-[18px] font-medium font-roboto text-black mx-4 my-2">
                        Get verified with OTP verification of Mobile/Email
                    </h2>
                )}
                <div
                    onClick={() => {
                        SendEvents(
                            user_data?.userId,
                            'btn-click',
                            'edit_profile_change_avatar_btn_click'
                        );
                    }}
                >
                    <ProfilePic setter={setAvatar} avatar={avatar} removeBorder={true} />
                </div>

                {/* <img className="h-32 w-32 rounded-full mx-auto border" src={details?.avatar}></img> */}
                <main className="flex flex-col gap-3 mx-3">
                    <InputBox
                        type={'name'}
                        name="name"
                        input={name}
                        setInput={(e: any) => {
                            if (/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\0-9]/g.test(e)) return;
                            if (e[0] == ' ') return;
                            setName(e);
                            sessionStorage.setItem('button-first-time', 'false');
                        }}
                        placeholder="Your name"
                        label={t('Your name')}
                        maxLength={25}
                    />

                    <InputBox
                        isSelect
                        onClick={() => {
                            SendEvents(
                                user_data?.userId,
                                'btn-click',
                                'edit_profile_change_parent_btn_click'
                            );

                            setSelectParentType(true);
                        }}
                        type="text"
                        name="type"
                        input={type}
                        setInput={setType}
                        placeholder="Father"
                        label="I'm a"
                        optionLabel=""
                    />

                    <>
                        <div className="text-sm font-normal text-[#242F35] font-roboto flex justify-between items-center mt-2">
                            <h2>Verify Mobile</h2>
                            {verifyInfo.phonenumber &&
                                isVerifiedInfo.phonenumber === verifyInfo.phonenumber && (
                                    <span>Verified</span>
                                )}
                        </div>
                        <div className="flex justify-between items-center border rounded pl-4">
                            <div>
                                {localtionDetails.countrycode
                                    ? localtionDetails.countrycode
                                    : '+91'}
                            </div>
                            <div className="onfphnck2" />
                            <input
                                ref={phoneNumberRef}
                                className="w-full flex-1 bg-white"
                                disabled={!numberVerifyIconClick}
                                onChange={(e) => {
                                    if (
                                        initialVerifyInfo.phonenumber ===
                                        e.target.value.slice(0, phoneLengthByCountry)
                                    ) {
                                        setEnableSaveBtn(false);
                                    } else {
                                        setEnableSaveBtn(false);
                                    }

                                    if (
                                        isVerifiedInfo.phonenumber ===
                                        e.target.value.slice(0, phoneLengthByCountry)
                                    ) {
                                        setIsValidNumber(false);
                                    }
                                    setVerifyInfo((prev) => ({
                                        ...prev,
                                        phonenumber: e.target.value.slice(0, phoneLengthByCountry)
                                    }));
                                }}
                                onFocus={() =>
                                    setVerifyInfo((prev) => ({
                                        ...prev,
                                        error: { ...prev.error, phonenumber: '' }
                                    }))
                                }
                                // onKeyDown={(e) =>
                                //     e.key === 'Enter' &&
                                //     !(isVerifiedInfo.phonenumber === verifyInfo.phonenumber) &&
                                //     handleSendOtp('mobile')
                                // }
                                placeholder="Phone Number"
                                type="number"
                                value={verifyInfo.phonenumber}
                            />
                            {verifyInfo.phonenumber &&
                            isVerifiedInfo.phonenumber === verifyInfo.phonenumber &&
                            !numberVerifyIconClick ? (
                                <button
                                    className="h-[48px] px-4"
                                    onClick={() => {
                                        setVerifyInfo((prev) => ({
                                            ...prev,
                                            phonenumber: prev.phonenumber
                                        }));
                                        setNumberVerifyIconClick(true);
                                    }}
                                >
                                    <ProfileEditIcon />
                                </button>
                            ) : (
                                <button
                                    className={`${
                                        verifyInfo.phonenumber &&
                                        verifyInfo.phonenumber === isVerifiedInfo.phonenumber
                                            ? 'onfphnck3'
                                            : phoneOtpStatus
                                            ? 'onfphnck3'
                                            : verifyInfo.phonenumber.length === phoneLengthByCountry
                                            ? 'onfphnck3-filled'
                                            : 'onfphnck3'
                                    }`}
                                    onClick={() => handleSendOtp('mobile')}
                                    disabled={
                                        verifyInfo.phonenumber &&
                                        verifyInfo.phonenumber === isVerifiedInfo.phonenumber
                                            ? true
                                            : phoneOtpStatus
                                            ? true
                                            : verifyInfo.phonenumber.length === phoneLengthByCountry
                                            ? false
                                            : true
                                    }
                                >
                                    <NavigateNextIcon />
                                </button>
                            )}
                        </div>
                        {verifyInfo.error.phonenumber && (
                            <span className="text-[12px] text-red-600">
                                {verifyInfo.error.phonenumber}
                            </span>
                        )}
                        {/* otp-number */}
                        {phoneOtpStatus && (
                            <>
                                <h5 className="enter__otp__title">Enter OTP</h5>
                                <OTPInput
                                    onNext={() => handleVerifyOTP('mobile')}
                                    setter={(value) => setInputOTPPhone(value)}
                                    setIncorrectOTP={setPhoneIncorrectOTP}
                                    incorrectOTP={phoneincorrectOTP}
                                    otpReset={otpResetPhone}
                                    setOtpReset={() => setOtpResetPhone('')}
                                />
                                {lenPhone == 0 &&
                                    (resendforPhone ? (
                                        <div>
                                            <div
                                                className="text-center text-[18px] cursor-pointer font-medium text-[#367E8A]"
                                                onClick={() => {
                                                    handleSendOtp('mobile');
                                                }}
                                            >
                                                Resend OTP
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div
                                                className="otp-page-resend-countdown  cursor-pointer text-[#367E8A] text-center text-sm"
                                                style={{ cursor: 'auto' }}
                                            >
                                                Resend in 00:
                                                {countDownPhone < 10
                                                    ? '0' + countDownPhone
                                                    : countDownPhone}
                                            </div>
                                        </div>
                                    ))}
                            </>
                        )}
                        <>
                            <div className="text-sm font-normal text-[#242F35] font-roboto flex justify-between items-center mt-2">
                                <h2>Verify email address</h2>
                                {verifyInfo.email && isVerifiedInfo.email === verifyInfo.email && (
                                    <span>Verified</span>
                                )}
                            </div>
                            <div className="flex justify-between items-center border rounded pl-4">
                                <input
                                    className="w-full flex-1 bg-white"
                                    ref={emailRef}
                                    disabled={!emailVerifyIconClick}
                                    onChange={(e) => {
                                        if (initialVerifyInfo.email === e.target.value) {
                                            setEnableSaveBtn(false);
                                        } else {
                                            setEnableSaveBtn(false);
                                        }

                                        if (isVerifiedInfo.email === e.target.value) {
                                            setIsValidEmail(false);
                                        }
                                        setVerifyInfo((prev) => ({
                                            ...prev,
                                            email: e.target.value
                                        }));
                                    }}
                                    onFocus={() =>
                                        setVerifyInfo((prev) => ({
                                            ...prev,
                                            error: { ...prev.error, email: '' }
                                        }))
                                    }
                                    // onKeyDown={(e) =>
                                    //     e.key === 'Enter' &&
                                    //     !(isVerifiedInfo.email === verifyInfo.email) &&
                                    //     handleSendOtp('email')
                                    // }
                                    placeholder="Email"
                                    type="email"
                                    value={verifyInfo.email}
                                />
                                {verifyInfo.email &&
                                verifyInfo.email === isVerifiedInfo.email &&
                                !emailVerifyIconClick ? (
                                    <button
                                        className="h-[48px] px-4"
                                        onClick={() => {
                                            setVerifyInfo((prev) => ({
                                                ...prev,
                                                email: prev.email
                                            }));
                                            setEmailVerifyIconClick(true);
                                        }}
                                    >
                                        <ProfileEditIcon />
                                    </button>
                                ) : (
                                    <button
                                        className={`${
                                            verifyInfo.email &&
                                            verifyInfo.email === isVerifiedInfo.email
                                                ? 'onfphnck3'
                                                : emailOtpStatus
                                                ? 'onfphnck3'
                                                : emailRegex.test(verifyInfo.email)
                                                ? 'onfphnck3-filled'
                                                : 'onfphnck3'
                                        }`}
                                        onClick={() => handleSendOtp('email')}
                                        disabled={
                                            verifyInfo.email &&
                                            verifyInfo.email === isVerifiedInfo.email
                                                ? true
                                                : emailOtpStatus
                                                ? true
                                                : emailRegex.test(verifyInfo.email)
                                                ? false
                                                : true
                                        }
                                    >
                                        <NavigateNextIcon />
                                    </button>
                                )}
                            </div>
                            {verifyInfo.error.email && (
                                <span className="text-[12px] text-red-600">
                                    {verifyInfo.error.email}
                                </span>
                            )}
                            {/* otp-email */}
                            {emailOtpStatus && (
                                <>
                                    <h5 className="enter__otp__title">Enter OTP</h5>
                                    <OTPInput
                                        onNext={() => handleVerifyOTP('email')}
                                        setter={(value) => setInputOTPEmail(value)}
                                        setIncorrectOTP={setEmailIncorrectOTP}
                                        incorrectOTP={emailincorrectOTP}
                                        otpReset={otpResetEmail}
                                        setOtpReset={() => setOtpResetEmail('')}
                                    />
                                    {lenEmail == 0 &&
                                        (resendforEmail ? (
                                            <div>
                                                <div
                                                    className=" cursor-pointer text-center text-[18px] font-medium text-[#367E8A]"
                                                    onClick={() => {
                                                        handleSendOtp('email');
                                                    }}
                                                >
                                                    Resend OTP
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div
                                                    className="otp-page-resend-countdown cursor-pointer  text-[#367E8A] text-center text-sm"
                                                    style={{ cursor: 'auto' }}
                                                >
                                                    Resend in 00:
                                                    {countDownEmail < 10
                                                        ? '0' + countDownEmail
                                                        : countDownEmail}
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}
                        </>
                    </>

                    {/* {details?.dueDate && dueDate && (
                    <div>
                        {(typeof dueDate === 'string').toString()}
                        {typeof details?.dueDate}
                    </div>
                )}
                {dueDate && (
                    <div>
                        dueDate:{' '}
                        {JSON.stringify(
                            new Date(dueDate ?? '').toISOString().substr(0, 10),
                            null,
                            4
                        )}
                    </div>
                )}
                {details?.dueDate && (
                    <div>
                        userdata:{' '}
                        {JSON.stringify(
                            new Date(details?.dueDate ?? '').toISOString().substr(0, 10),
                            null,
                            4
                        )}
                    </div>
                )} */}
                    {dueDate && (
                        <div className="mt-5">
                            {' '}
                            <DOBInput
                                title={'Due Date'}
                                age={dueDate}
                                setAge={setDueDate}
                                isForDueDate={true}
                                isForEditProfile={true}
                            />
                        </div>
                    )}

                    {/* {otherChildren?.map((kid, index) => (
                    <div className="flex flex-col gap-2" key={index}>
                        <InputBox
                            optionLabel="Remove"
                            optionAction={() => handleRemoveChild(kid, index)}
                            input={otherChildren[index]?.name}
                            label="Child's Name"
                            name="Child name"
                            placeholder="Child's Name"
                            setInput={(e) => setNewChild(e, index, 'name')}
                            type={'text'}
                        />
                        <DOBForm
                            value="dob"
                            setter={(e: any) => setNewChild(e, index, 'dob')}
                            dob={kid.dob}
                            err={err}
                            setErr={setErr}
                        />
                    </div>
                ))} */}

                    {/* <div>childDataNew1: EditProfile.tsx {JSON.stringify(childDataNew, null, 4)}</div> */}
                    <div
                        onClick={() => {
                            SendEvents(
                                user_data?.userId,
                                'btn-click',
                                'edit_profile_add_child_btn_click'
                            );
                        }}
                        className="mb-8"
                    >
                        <AddChildComponent
                            setDataForProfile={setData}
                            data={childDataNew}
                            atLeastOneChild={true}
                        />
                    </div>
                    {/* <InputBox
                    type="text"
                    name="location"
                    input={location}
                    setInput={setLocation}
                    placeholder="Mumbai, India"
                    label="Location"
                /> */}

                    {/* <DropDown
                    footerDropdown={false}
                    selected={languages}
                    setSelected={(e) => setLanguages(e)}
                    label="Languages"
                    options={languagesOption}
                /> */}

                    {/* <section>
                    <label
                        htmlFor="Choose_Slot"
                        className="font-roboto font-normal text-sm leading-[18.75px] mb-3 mt-5"
                    >
                        Languages
                    </label>

                    <select
                        className=" font-semibold border-[#C4C4C4] rounded-[5px] border w-full h-[55px] text-[16px] leading-[26px] pl-[20px] my-4 "
                        // value={languageOption[0]}
                        onChange={HandleSlotSlectionChange}
                    >
                        {languageOption.map((slot: any, index: number) => (
                            <option key={slot.slotId} value={slot.slotId}>
                                {slot?.label}
                            </option>
                        ))}
                    </select>
                </section> */}
                </main>
            </div>
        </section>
    );

    //for chaning parent type

    if (selectParentType) {
        console.log('line 212 edit profile tsx rendered');
        dispatch(setForEditProfile(true));
        UpdateName();
        return (
            <div className="px-4">
                {/* <Header
                    isForOnboarding
                    back
                    onBack={() => setSelectParentType(false)}
                    headerText="Select option"
                />
                <SelectionForm
                    onComplete={() => setSelectParentType(false)}
                    // heading="Choose one of these options"
                    // options={user_data?.parentTypeComponent}
                    options={StageData.SetupProfileData}
                    passInput
                /> */}
                <OnboardingPage />
            </div>
        );
    }

    return (
        <LoadingLayout
            skelton={
                <div className="max-w-4xl mx-auto flex flex-col px-4">
                    <Header back onBack={() => navigate(-1)} headerText="Profile" />
                    <ProfileSkeleton />
                </div>
            }
            child={component}
            isLoading={isLoading}
        />
    );
}

export default EditProfile;
