// import OTPPage from '../OTPPage/OTPPage';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
const OTPPage = lazyWithRetry(() => import('../OTPPage/OTPPage'));
// // import ParentTypePage from '../ParentTypePage/ParentTypePage';
// const ParentTypePage = lazyWithRetry(() => import('../ParentTypePage/ParentTypePage'));
// // import AvatarSelectPage from '../AvatarselectPage/AvatarSelectPage';
// const AvatarSelectPage = lazyWithRetry(() => import('../AvatarselectPage/AvatarSelectPage'));
// // import InterestsPage from '../InterestsPage/InterestsPage';
// const InterestsPage = lazyWithRetry(() => import('../InterestsPage/InterestsPage'));
// // import OnboardingPage from '../OnboardingPage/OnboardingPage';
const OnboardingPage = lazyWithRetry(() => import('../OnboardingPage/OnboardingPage'));
// import ProfileSummaryPage from '../ProfileSummaryPage/ProfileSummaryPage';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useUserData } from '../../utils/hooks/useUserData';
// import ParentTypeInputPage from '../ParentTypeInputPage/ParentTypeInputPage';
// const ParentTypeInputPage = lazyWithRetry(
//     () => import('../ParentTypeInputPage/ParentTypeInputPage')
// );
import Loader from '../../components/Loader/Loader';
import { updatePathName } from '../../store/slices/onboardingSlice';
import { useAppDispatch } from '../../store/store';
import { Buffer } from 'buffer';
import WelcomePage from '../../components/WelcomePage/WelcomePage';
import { clearUserData } from '../../store/slices/userDataSlice';
import pushEventToGtm from '../../utils/pushEventToGtm';
// import pushEventToGtm from '../../utils/pushEventToGtm';

interface LocationState {
    from?: {
        pathname: string;
    };
}

function RegisterPage(props: any) {
    pushEventToGtm('page_view', { pt_page: 'register' });
    const { userData: user_data } = useUserData();
    const [pathName, setPathName] = useState<string>('/');
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [welcomePageRedirect, setWelcomePageRedirect] = useState<any>('true');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as LocationState;
    console.log(state);

    useEffect(() => {
        const pathname = localStorage.getItem('pathname');
        if (pathname) {
            setPathName(pathname);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (localStorage.getItem('welcomePage')) {
            setWelcomePageRedirect(localStorage.getItem('welcomePage'));
        }
    }, []);

    const handleEditClick = (val: string) => {
        localStorage.setItem('redirectNav', val);
        setShowOnboarding(true);
    };

    useEffect(() => {
        const url = state?.from?.pathname || state?.from;
        console.log('OnboardingPageOnboardingPage', state?.from?.pathname);

        if (!url) return;
        sessionStorage.setItem('urlFromOnboarding', JSON.stringify(url));
        console.log('OnboardingPageOnboardingPage', state?.from?.pathname);
    }, [state]);

    const handleEditBack = () => {
        localStorage.clear();
        dispatch(clearUserData());
        setShowOnboarding(false);
    };
    useEffect(() => {
        // const handlePurposeCards = () => {
        //     if (!user_data) return;
        //     const PurposeCardsExistsInLocalStorage = sessionStorage.getItem('PurposeCardsData');
        //     const PurposeCardsData = {
        //         'parent-talk': 1,
        //         'baby-names/all': 1,
        //         'expert-corner': 1
        //     };
        //     if (!PurposeCardsExistsInLocalStorage)
        //         sessionStorage.setItem('PurposeCardsData', JSON.stringify(PurposeCardsData));
        // };
        // handlePurposeCards();
        // if (user_data?.step === '' && user_data.isNewUser === false) {
        //     dispatch(updatePathName('/'));
        // } else if (user_data?.step === '' && !user_data.isNewUser) {
        //     dispatch(updatePathName('/'));
        // }
    }, [user_data, dispatch]);

    const getPathComponent = () => {
        if (showOnboarding) {
            return <OnboardingPage lastpath={state} />;
        } else {
            if (!user_data) return <OTPPage lastpath={state} />;
            else if (user_data?.profileComplete < 40) {
                return <OnboardingPage lastpath={state} />;
            } else if (
                user_data?.step === '' &&
                !user_data.isNewUser &&
                localStorage.getItem('gift-user') === '1'
            ) {
                return <Navigate to="/my-profile" />;
            } else if (user_data?.step === '' && !user_data.isNewUser) {
                const pathNameFromLocationState = state?.from ?? '/';
                let redirectPage = '/';
                // @ts-ignore
                if (pathNameFromLocationState?.pathname?.charAt(0) === '/') {
                    // @ts-ignore
                    redirectPage = pathNameFromLocationState?.pathname;
                } else if (
                    typeof pathNameFromLocationState === 'string' &&
                    pathNameFromLocationState.length > 5
                ) {
                    redirectPage = pathNameFromLocationState;
                }
                // @ts-ignore
                if (welcomePageRedirect == 'true' && !user_data.isNewUser) {
                    return (
                        <WelcomePage onEditClick={handleEditClick} handleBack={handleEditBack} />
                    );
                } else {
                    return <Navigate to={redirectPage} replace={true} />;
                }
            } else {
                return <OnboardingPage lastpath={state} />;
            }
        }
    };
    return <Suspense fallback={<Loader />}>{getPathComponent()}</Suspense>;
}

export default RegisterPage;
