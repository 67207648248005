import React, { useEffect, useState } from 'react';
import './ToastMessage.css';

const ToastMessage: React.FC<{ msg: any; showToaster: any; setShowToaster?: any }> = ({
    msg,
    showToaster,
    setShowToaster
}) => {
    const [show, setShow] = useState(false);
    const [animationName, setanimationName] = useState('bottom-[6rem]');

    useEffect(() => {
        setShow(true);
        console.log('timer start');
        const timer = setTimeout(() => {
            // if (showToaster) setShowToaster(false);
            console.log('timer done');
            setanimationName('onboarding-toast-animation');
        }, 2500); // Automatically hide after 2 seconds

        return () => {
            clearTimeout(timer);
            setShow(false);
        };
    }, [showToaster]);

    return (
        <>
            {showToaster && (
                <div>
                    <div
                        className={` fixed z-50 h-auto w-full bottom-24  bg-transparent rounded-sm  left-1/2 transform -translate-x-1/2 ${animationName} toastercontainer`}
                    >
                        <p className="bg-[#f4d64a] px-[5px] text-sm rounded-lg py-[5px] shadow-xl font-normal font-roboto  w-fit text-center">
                            {msg}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ToastMessage;
