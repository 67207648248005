import tick from '../../assets/landing/tick.svg';

interface WhoCourseABoutType {
    image: string;
    text: string;
}

const WhoCourseAbout = (props: any) => {
    const { course_about } = props;

    return (
        <>
            {course_about && course_about.data.length > 0 && (
                <div className="mt-[21px] px-[20px] pt-[24px] pb-[40.83px] bg-[rgba(217, 217, 217, .15)]">
                    <h2 className="text-[#262626] text-[18px] font-medium">
                        What is this Course about?
                    </h2>
                    <div className="mt-[18px] flex flex-col gap-2">
                        {course_about.data.map((item: WhoCourseABoutType, index: number) => {
                            return (
                                <div className="flex gap-[30.67px]" key={index}>
                                    <img
                                        src={tick}
                                        alt="tick-icon"
                                        // width={24}
                                        className="aspect-square"
                                    />{' '}
                                    <p className="text-[#000000] text-[14px] font-normal">
                                        {item.text}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default WhoCourseAbout;
