import axios from 'axios';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
import useFetchUserLocation from '../utils/hooks/useFetchUserLocation';
import { country_codes_africa, country_codes_africaWithCountry } from '../utils/utils';
// import i18n from '../i18n/config';
const SelectedLanguage = Geti18nLang();
async function getNameDetail(
    params: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any }
) {
    const { user_data } = params;
    const { country } = await useFetchUserLocation();
    let currentCountry = '';

    if (country_codes_africa.includes(country)) {
        // @ts-ignore
        currentCountry = country_codes_africaWithCountry[country];
    }

    const headers = {
        userId: user_data?.userId?.toString() || '',
        instanceId: user_data?.instanceId?.toString() || '',
        authToken: user_data?.authToken || '',
        lang: SelectedLanguage || 'en'
    };

    // Add country header only if currentCountry is not null
    if (currentCountry) {
        // @ts-ignore
        headers['country'] = currentCountry;
    }

    return axios
        .get(process.env.REACT_APP_BABYNAME + 'baby-name', {
            headers,
            params: {
                id: params.id
            }
        })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export default getNameDetail;
