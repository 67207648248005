import * as React from 'react';

function SearchIcon(props) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill={props.color}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.544 17.683c1.135.479 2.34.723 3.581.723a9.054 9.054 0 003.579-.726 9.169 9.169 0 002.923-1.972 9.169 9.169 0 002.695-6.504A9.169 9.169 0 0016.627 2.7 9.168 9.168 0 0010.123.005 9.168 9.168 0 003.619 2.7 9.168 9.168 0 00.926 9.207 9.168 9.168 0 003.62 15.71a9.169 9.169 0 002.923 1.972zM4.426 3.506a8.006 8.006 0 015.699-2.36 8 8 0 015.696 2.363 8.006 8.006 0 012.36 5.698 8.006 8.006 0 01-2.36 5.699 8.006 8.006 0 01-5.698 2.36 8.006 8.006 0 01-5.699-2.36 8.006 8.006 0 01-2.36-5.699 8.01 8.01 0 012.362-5.7z"
                fill="#151E26"
                fillOpacity={0.6}
            />
            <path
                d="M21.303 22a.568.568 0 01-.404-.167l-4.231-4.231a.57.57 0 11.808-.808l4.23 4.23a.57.57 0 01-.403.975z"
                fill={props.color}
                fillOpacity={0.6}
            />
        </svg>
    );
}

export default SearchIcon;
