import React from 'react';
import EditProfile from '../../components/Profile/ProfilePage/FellowParent/EditProfile';

const EditProfileNew = () => {
    return (
        <div>
            <EditProfile />
        </div>
    );
};

export default EditProfileNew;
