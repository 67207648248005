import * as React from 'react';

function BellIcon(props) {
    return (
        <svg
            width={20}
            height={22}
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.39 14.81c.039.056.077.112.113.168.367.523.697 1.057.701 1.833 0 .57-.336 1.388-1.937 2.045-1.077.442-2.561.747-4.306.886-.386 1.298-1.559 2.247-2.942 2.247-1.395 0-2.572-.96-2.951-2.274-1.734-.14-3.216-.449-4.3-.897C1.17 18.154.833 17.344.833 16.78c0-.782.343-1.298.675-1.796l.018-.027c.038-.058.076-.116.117-.175.513-.767 1.094-1.635 1.094-4.431V8.917c0-1.507.532-2.943 1.536-4.15.906-1.085 2.164-1.921 3.561-2.37A2.193 2.193 0 0110.016.02a2.193 2.193 0 012.18 2.404c1.402.46 2.662 1.301 3.564 2.38 1.005 1.202 1.536 2.633 1.536 4.14v1.435c0 2.796.58 3.664 1.094 4.431zM11.08 2.117a1.07 1.07 0 00-2.13.018c.329-.056.701-.103 1.042-.105.45-.003.749.02 1.088.087zm-1.066 18.75c-.746 0-1.397-.443-1.726-1.093l.171.008.194.008.615.027.314.013.183.008.124.006.044.002c.017 0 .032 0 .043.002h.045a.74.74 0 00.121-.011c.236-.011.913-.034 1.593-.054-.33.646-.976 1.083-1.72 1.083zm2.66-2.23c1.716-.124 3.155-.407 4.162-.822.915-.377 1.243-.769 1.243-1.004 0-.449-.207-.776-.498-1.189l-.011-.016c-.02-.028-.038-.056-.056-.084l-.056-.084c-.272-.404-.608-.908-.864-1.697-.282-.88-.422-1.979-.422-3.358V8.948c0-1.24-.441-2.424-1.276-3.422-.798-.958-1.933-1.696-3.193-2.08-.823-.248-1.043-.295-1.707-.293-.585.002-1.406.193-1.68.27-1.253.37-2.383 1.103-3.184 2.065-.834 1-1.276 2.188-1.276 3.43v1.436c0 1.38-.137 2.476-.422 3.357-.255.792-.592 1.294-.863 1.698a5.56 5.56 0 01-.05.076c-.022.03-.042.061-.062.092l-.02.032-.002.003c-.282.422-.485.726-.485 1.17 0 .224.325.618 1.243 1 1.015.421 2.455.708 4.164.83a713.264 713.264 0 002.616.115 660.777 660.777 0 012.699-.09z"
                fill="#151E26"
                fillOpacity={0.6}
            />
        </svg>
    );
}

export default BellIcon;
