import axios from 'axios';
import { User } from '../../types/user';

export const getPlans = async (onSuccess: (data: any) => void, onFailure: (error: any) => any) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_SUBSCRIPTION_SERVER + 'plans/details',
            {
                params: {
                    planType: 'PLUS'
                }
            }
        );

        const responsedata = await response.data;
        onSuccess(responsedata);
    } catch (error) {
        onFailure(error);
    }
};

export const getActiveGifts = async (
    params: {
        user_data: User;
        language: string;
        orderId: number;
    },
    onSuccess: (data: any) => void,
    onFailure: (error: any) => any
) => {
    try {
        const { user_data } = params;
        const response = await axios.get(
            process.env.REACT_APP_SUBSCRIPTION_SERVER + 'getgiftdetail',
            {
                params: {
                    orderId: params.orderId
                },
                headers: {
                    lang: params.language,
                    userId: user_data?.userId?.toString() || '',
                    instanceId: user_data?.instanceId?.toString() || '',
                    authToken: user_data?.authToken || ''
                }
            }
        );

        const responsedata = await response.data;
        onSuccess(responsedata);
    } catch (error) {
        onFailure(error);
    }
};
