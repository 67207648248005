import * as React from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/Content/Content';

export interface MetaTagProps {
    title?: string;
    metaTitle?: string;
    keywords?: string;
    description?: string;
    canonicalLink?: string;
    ampHtml?: string;
    alAndroidUrl?: string;
    appleItuneApp?: string;
    count?: number;
    metaKeywords?: string;
    metaTwitterTitle?: string;
    metaTwitterDescription?: string;
    metaDescription?: string;
    metaOgTitle?: string;
    metaOgDescription?: string;
    metaOgUrl?: string;
    metaAlAndroidUrl?: string;
    metaAppleItuneApp?: string;
    char?: string;
    pageType?: number;
    pageCountOnScroll?: any;
    current_page_selected_lang?: any;
    primary_lang?: any;
}

function MetaTag(props: MetaTagProps) {
    let char = props?.char?.split('-').pop();
    char = char?.toUpperCase();
    let canonicalLink = 'https://www.parentune.com' + window?.location?.pathname;

    let updatedTitle: any = props.count ? `${props?.count} ${props?.title}` : props.title;
    let updateMetaTitle: any = props.count
        ? `${props?.count} ${props?.metaTitle}`
        : props.metaTitle;
    let metaDescription = `${props?.metaDescription}`;
    let twitterTitle: any = props.count
        ? `${props?.count} ${props?.metaTwitterTitle}`
        : props.metaTwitterTitle;
    let metaTwitterDescription = `${props?.metaTwitterDescription}`;
    let metaOgTitle: any = props.count
        ? `${props?.count} ${props?.metaOgTitle}`
        : props.metaOgTitle;
    let metaOgDescription = `${props?.metaOgDescription}`;
    let metaOgUrl = `${props?.metaOgUrl}`;
    // let alAndroidUrl = `${props?.alAndroidUrl}`;
    let appleItuneApp = `${props?.appleItuneApp}`;
    let current_page_selected_lang = `${props?.current_page_selected_lang}`;
    let primary_lang = `${props?.primary_lang}`;

    //console.log('current_page_selected_lang', current_page_selected_lang)
    //console.log('primary_lang', primary_lang)

    if (char) {
        //canonicalLink = window?.location?.href;
        updatedTitle = updatedTitle.replaceAll('A', `${char}`);
        updateMetaTitle = updateMetaTitle.replaceAll('A', `${char}`);
        metaDescription = metaDescription.replaceAll('A', `${char}`);
        twitterTitle = twitterTitle.replaceAll('A', `${char}`);
        metaTwitterDescription = metaTwitterDescription.replaceAll('A', `${char}`);
        metaOgTitle = metaOgTitle.replaceAll('A', `${char}`);
        metaOgDescription = metaOgDescription.replaceAll('A', `${char}`);
        metaOgUrl = metaOgUrl.replaceAll('A', `${char}`);
        // alAndroidUrl = alAndroidUrl.replaceAll('A', `${char}`);
        appleItuneApp = appleItuneApp.replaceAll('A', `${char}`);
    }

    if (props?.pageCountOnScroll && props?.pageCountOnScroll !== 1) {
        updatedTitle = updatedTitle + ' | page - ' + props.pageCountOnScroll;
    }
    if (props?.pageCountOnScroll && props?.pageCountOnScroll !== 1) {
        updateMetaTitle = updateMetaTitle + ' | page - ' + props.pageCountOnScroll;
    }
    if (props?.pageCountOnScroll && props?.pageCountOnScroll !== 1) {
        twitterTitle = twitterTitle + ' | page - ' + props.pageCountOnScroll;
    }
    if (props?.pageCountOnScroll && props?.pageCountOnScroll !== 1) {
        metaOgTitle = metaOgTitle + ' | page - ' + props.pageCountOnScroll;
    }

    // if (props.pageType === 1) {
    //     canonicalLink = window?.location?.href;
    // }

    const getNextMetaTagParams = () => {
        let currentLocation = window.location?.href;
        let prevUrl = null;
        let nextUrl = null;

        if (props?.pageCountOnScroll > 1) {
            let page: any = currentLocation.split('?')?.pop();
            if (page) {
                if (page === 'page=2') {
                    prevUrl = currentLocation.replace('?page=2', '');
                    nextUrl = currentLocation.replace('page=2', 'page=3');
                } else {
                    let num = parseInt(page.split('=')?.pop());
                    prevUrl = currentLocation.replace(page, 'page=' + (num - 1));
                    nextUrl = currentLocation.replace(page, 'page=' + (num + 1));
                }
            }
        }

        return { prevUrl, currentLocation, nextUrl };
    };

    const { prevUrl, currentLocation, nextUrl } = getNextMetaTagParams();

    return (
        <Helmet>
            <meta name="theme-color" content="#ffffff" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="https://img1.parentune.com/images/logo/ParentuneFavicon.png"
            />
            <link rel="dns-prefetch" href="https://www.parentune.com" />
            <link rel="dns-prefetch" href="https://img1.parentune.com" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* {nextUrl || prevUrl ? null : <meta name="robots" content="index, follow" />} */}
            <meta name="generator" content="Parentune" />
            {prevUrl ? <link rel="prev" href={prevUrl} /> : null}
            {nextUrl ? <link rel="next" href={nextUrl} /> : null}
            {/* {nextUrl || prevUrl ? <meta name="robots" content={'noindex,follow'} /> : null} */}

            {current_page_selected_lang == primary_lang ? (
                <meta name="robots" content="index, follow" />
            ) : (
                <meta name="robots" content={'noindex,follow'} />
            )}

            <meta
                name="google-site-verification"
                content="fe9G53IP506VvljmNqGxKdBQZZfNzuAslU6WKZ8jazM"
            />

            <link
                rel="icon"
                type="image/png"
                href="https://img1.parentune.com/images/logo/ParentuneFavicon.png"
                sizes="32x32"
            />
            <link
                rel="icon"
                type="image/png"
                href="https://img1.parentune.com/images/logo/ParentuneFavicon.png"
                sizes="16x16"
            />
            <link
                rel="shortcut icon"
                href="https://img1.parentune.com/images/logo/ParentuneFavicon.png"
            />
            <link rel="manifest" href="https://www.parentune.com/react/build/manifest.json" />
            <meta name="image" content="https://img1.parentune.com/web/images/logo.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="Parentune" />
            <meta
                name="twitter:image:src"
                content="https://img1.parentune.com/web/images/logo.png"
            />
            <meta name="twitter:creator" content="Parentune-Pregnancy,Parenting" />

            <meta property="fb:app_id" content="331498810359251" />
            <meta property="og:type" content="article" />
            <meta property="og:image" content="https://img1.parentune.com/web/images/logo.png" />
            <meta property="og:site_name" content="Parentune" />
            <meta property="article:author" content="http://fb.com/parentune" />
            <meta property="article:publisher" content="https://www.parentune.com" />
            <meta property="fb:pages" content="120033631475442,338119813349681" />
            <meta name="facebook-domain-verification" content="8hw77zb7v05agvjmiul7bl4sc03kub" />
            <link rel="canonical" href={canonicalLink} />
            <title>{updatedTitle}</title>
            <meta name="keywords" content={props?.metaKeywords} />
            <meta name="title" content={updateMetaTitle} />
            <meta property="og:title" content={metaOgTitle} />
            <meta name="twitter:title" content={twitterTitle} />
            <meta name="twitter:description" content={metaTwitterDescription} />
            <meta name="description" content={metaDescription} />
            <meta property="og:description" content={metaOgDescription} />
            {/* <meta property="al:android:url" content={alAndroidUrl} /> */}
            <meta name="apple-itunes-app" content={appleItuneApp} />
        </Helmet>
    );
}

export default MetaTag;
