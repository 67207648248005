import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import CopyLinkIcon from '../../assets/icons/CopyLinkIcon';
import FacebookShareIcon from '../../assets/icons/FacebookShareIcon';
import WhatsappShareIcon from '../../assets/icons/WhatsappShareIcon';
import styles from './SocialShare.module.css';

interface SocialShareProps {
    isOpen: boolean;
    title?: string;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    shareUrl?: string | null;
    fbshow?: boolean;
}

export const SocialShare = ({
    isOpen,
    title,
    setIsOpen,
    shareUrl,
    fbshow = false
}: SocialShareProps) => {
    const [copySuccess, setCopySuccess] = useState<any>('Copy Link');

    console.log('title:', title);

    useEffect(() => {
        if (!isOpen) return;
        setCopySuccess('Copy Link');
    }, [isOpen]);
    const copyToClipboard = (copyMe: string) => {
        const textField = document.createElement('textarea');
        textField.innerText = copyMe;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopySuccess('Copied!');
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0  overflow-y-auto"
                style={{ zIndex: 60 }}
                onClose={() => {
                    setIsOpen(false);
                }}
            >
                <div>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed bg-black bg-opacity-30 inset-0" />
                    </Transition.Child>
                    <span
                        className="inline-block h-screen align-middle bg-slate-500"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            style={{
                                maxWidth: '1000px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                justifyContent: 'space-evenly'
                            }}
                            className="sticky border bottom-0 bg-white  flex justify-between items-center h-[112px]"
                        >
                            <WhatsappShareButton
                                url={shareUrl ? shareUrl : window?.location.href}
                                title={title + '/n'}
                                className="outline-none"
                            >
                                {/* <WhatsappIcon className="w-10 h-10 mx-auto" round /> */}

                                <WhatsappShareIcon className="mx-auto" />
                                <p className={styles.shareLogoText}>Whatsapp</p>
                            </WhatsappShareButton>
                            {!fbshow ? (
                                <FacebookShareButton
                                    url={shareUrl ? shareUrl : window?.location.href}
                                    title={title}
                                >
                                    {/* <FacebookIcon className="w-10 h-10 mx-auto" round /> */}
                                    <FacebookShareIcon className="mx-auto" />
                                    <p className={styles.shareLogoText}>Facebook</p>
                                </FacebookShareButton>
                            ) : null}
                            <div>
                                <CopyLinkIcon
                                    className="mx-auto cursor-pointer"
                                    onClick={(e: any) =>
                                        copyToClipboard(
                                            shareUrl
                                                ? title + '/n' + shareUrl
                                                : window.location.href
                                        )
                                    }
                                />

                                <p className={styles.shareLogoText}>{copySuccess}</p>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};
