import * as React from 'react';

function TrendingIcon(props) {
    return (
        <svg
            width={21}
            height={12}
            viewBox="0 0 21 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20.4.249L14.12.928l2.228 2.156L12 7.29l-4.133-4-7.268 7.034 1.227 1.188 6.041-5.847 4.133 4 5.574-5.394L19.7 6.327 20.4.25z"
                fill="#000"
            />
        </svg>
    );
}

export default TrendingIcon;
