import axios from 'axios';

export const getWorkshopDetails = async (
    itemid: number,
    onSucess: (args: any) => void,
    onError: (args: any) => void
) => {
    try {
        const API = process.env.REACT_APP_LIVEEVENTS_SERVER;
        const response = await axios({
            method: 'get',
            url: `${API}v2/detail?itemId=${itemid}`
        });

        if (!response) return onError('response failed!');
        return onSucess(response.data);
    } catch (error) {
        return onError(error);
    }
};
