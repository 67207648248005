import axios from 'axios';
import { useQuery } from 'react-query';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
// import i18n from '../../i18n/config';
const SelectedLanguage = Geti18nLang();

export const CanWatchWorkshop = (
    user_data: any,
    onSuccess: { (data: any): void; (arg0: any): void },
    onFailure: { (error: string): void; (arg0: any): any },
    id: any = ''
) => {
    const API: any = process.env.REACT_APP_LIVEEVENTS_SERVER + 'v2/canWatch';
    axios
        .get(API, {
            headers: {
                lang: SelectedLanguage || 'en',
                instanceId: user_data?.instanceId ? user_data?.instanceId : '',
                authToken: user_data?.authToken ? user_data?.authToken : '',
                itemid: id ?? ''
            },
            params: {}
        })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((e) => {
            onFailure(e);
        });
};

export default CanWatchWorkshop;
