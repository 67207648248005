interface NoDataProps {
    title: string;
}

function NoData(props: NoDataProps) {
    const { title } = props;

    return (
        <div className="flex justify-center items-center mt-8 text-black opacity-50 text-[18px] font-normal font-roboto">
            <h2>{title}</h2>
        </div>
    );
}

export default NoData;
