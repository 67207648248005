import React, { useState } from 'react';
import Overlay from '../Overlay/Overlay';
import styles from './Testimonal.module.css';
import { TextWrapper } from '../TextWrapper/TextWrapper';
import OverlayTestimonal from '../PlusComponents/Overlay Testimonal/OverlayTestimonal';
const img1 = 'https://img1.parentune.com/web/assets/images/rating.svg';
function Testimonal(props: any) {
    const { data } = props;
    const [showPopUp, setShowPopUp] = useState(false);

    return (
        <>
            <div
                className="hover:scale-[1.02] duration-200 ease-in-out h-[200px] overflow-hidden w-[218px] pb-2"
                style={{
                    border: '0.5px solid #E5E5E5',
                    boxShadow: '0px 9.00746px 15.0124px rgba(196, 196, 196, 0.2)',
                    borderRadius: '4.00332px',
                    maxWidth: '400px',
                    backgroundColor: 'white',
                    padding: '20px',
                    minWidth: '230px',
                    margin: '20px 20px 20px 0'
                }}
                onClick={() => {
                    setShowPopUp(true);
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <img
                        style={{
                            width: '36px',
                            height: '36px',
                            borderRadius: '10px'
                        }}
                        src={data?.avatar}
                        alt=""
                    />
                    <div
                        style={{
                            marginLeft: '20px'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: '500'
                            }}
                        >
                            {data?.userName}
                        </div>
                        <div>
                            <img
                                style={{
                                    width: '50px'
                                }}
                                src={img1}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    <TextWrapper line={4}>
                        <h1 className="font-[400] text-[16px]">{data?.text}</h1>
                    </TextWrapper>
                </div>
            </div>
            {showPopUp && (
                <Overlay>
                    <div className="instant-box">
                        <div className={styles.container}>
                            <div className={styles.content}>
                                <OverlayTestimonal
                                    setClose={() => {
                                        setShowPopUp(false);
                                        console.log('error');
                                        console.log(showPopUp);
                                    }}
                                    data={data}
                                />
                            </div>
                        </div>
                    </div>
                </Overlay>
            )}
        </>
    );
}

export default Testimonal;
