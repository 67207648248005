import { t } from 'i18next';

const Content = {
    SEO: {
        BABY_NAMES: {
            HOME: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names',
                // // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names?amp=true',
                BABY_NAME_TITLE:
                    'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                    DESCRIPTION:
                        'Find a new, meaningful yet modern name for your new born child from the latest 2024 name list of top, unique and cute baby names. Search beautiful names with meaning for new born kids and children by specific alphabets.',
                    KEYWORDS: `indian baby names, modern indian baby names, unique baby names, baby names with meanings`,
                    TWITTER_TITLE:
                        'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your new born child from the latest 2024 name list of top, unique and cute baby names. Search beautiful names with meaning for new born kids and children by specific alphabets.',
                    OG_TITLE: 'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                    OG_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your new born child from the latest 2024 name list of top, unique and cute baby names. Search beautiful names with meaning for new born kids and children by specific alphabets.',
                    OG_URL: 'https://www.parentune.com/baby-names'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names',
                APPLE_ITUNES_APP: 'app-id=1039105238, app-argument=www.parentune.com/baby-names',
                WITH_LETTER: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/starting-letter-a',
                    BABY_NAME_TITLE:
                        'Unique &amp; Modern Indian Baby Names Starting With A With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique &amp; Modern Indian Baby Names Starting With A With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your new born child from the  latest 2024 name list of unique and cute baby names. Search beautiful names starting with A with meaning by gender and religion.',
                        KEYWORDS: `indian baby names, modern Indian baby names, unique baby names, baby names with meanings`,
                        TWITTER_TITLE:
                            'Unique &amp; Modern Indian Baby Names Starting With A With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your new born child from the  latest 2024 name list of unique and cute baby names. Search beautiful names starting with A with meaning by gender and religion.',
                        OG_TITLE:
                            'Unique &amp; Modern Indian Baby Names Starting With A With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your new born child from the  latest 2024 name list of unique and cute baby names. Search beautiful names starting with A with meaning by gender and religion.',
                        OG_URL: 'https://www.parentune.com/baby-names/starting-letter-a'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/starting-letter-a',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/starting-letter-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/starting-letter-a'
                }
            },
            HINDU: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names/hindu',
                // // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names?amp=true',
                BABY_NAME_TITLE: 'Unique Indian Hindu Baby Names List With Meaning | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique Indian Hindu Baby Names List With Meaning | Parentune.com',
                    DESCRIPTION:
                        'Unique Indian names for newborn Hindu baby with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    KEYWORDS:
                        'indian baby names, indian hindu baby names, hindu baby names 2024, modern hindu names list, baby names with meanings',
                    TWITTER_TITLE:
                        'Unique Indian Hindu Baby Names List With Meaning | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Unique Indian names for newborn Hindu baby with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_TITLE: 'Unique Indian hindu Baby Names List With Meaning | Parentune.com',
                    OG_DESCRIPTION:
                        'Unique Indian names for newborn Hindu baby with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_URL: 'https://www.parentune.com/baby-names/hindu'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names/hindu',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/hindu',
                APPLE_ITUNES_APP:
                    'app-id=1039105238, app-argument=www.parentune.com/baby-names/hindu',
                WITH_LETTER: {
                    CANONICAL_LINK:
                        'https://www.parentune.com/baby-names/hindu-names-starting-with-a',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/boy/starting-with-a?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Indian hindu Baby Names starting with A with meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Indian hindu Baby Names starting with A with meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Names, Hindu. Search a new beautiful with combination of A: ',
                        KEYWORDS:
                            'indian baby names, indian hindu baby names starting with A, modern Hindu baby names A, baby names with meanings',
                        TWITTER_TITLE:
                            'Unique Indian hindu Baby Names starting with A with meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Names, Hindu. Search a new beautiful with combination of A: ',
                        OG_TITLE:
                            'Unique Indian hindu Baby Names starting with A with meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Names, Hindu. Search a new beautiful with combination of A: ',
                        OG_URL: 'https://www.parentune.com/baby-names/hindu-names-starting-with-a'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/hindu-names-starting-with-a',
                    AL_ANDROID_URL:
                        'parentune://www.parentune.com/baby-names/hindu-names-starting-with-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/hindu-names-starting-with-a'
                }
            },
            MUSLIM: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names/muslim',
                // // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names?amp=true',
                BABY_NAME_TITLE:
                    'Unique Islamic muslim Baby Names List With Meaning | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique Islamic muslim Baby Names List With Meaning | Parentune.com',
                    DESCRIPTION:
                        'Unique Indian names for newborn Muslim baby with Meaning. Find a latest 2024 name list of top, cute and modern Muslim baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    KEYWORDS:
                        'indian baby names, muslim baby names, islamic baby names, unique muslim baby names, muslim names, baby names with meanings',
                    TWITTER_TITLE:
                        'Unique Islamic muslim Baby Names List With Meaning | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Unique Indian names for newborn Muslim baby with Meaning. Find a latest 2024 name list of top, cute and modern Muslim baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_TITLE: 'Unique Islamic muslim Baby Names List With Meaning | Parentune.com',
                    OG_DESCRIPTION:
                        'Unique Indian names for newborn Muslim baby with Meaning. Find a latest 2024 name list of top, cute and modern Muslim baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_URL: 'https://www.parentune.com/baby-names/muslim'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names/muslim',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/muslim',
                APPLE_ITUNES_APP:
                    'app-id=1039105238, app-argument=www.parentune.com/baby-names/muslim',
                WITH_LETTER: {
                    CANONICAL_LINK:
                        'https://www.parentune.com/baby-names/muslim-names-starting-with-a',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/boy/starting-with-a?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Islamic muslim Baby Names Starting With A with meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Islamic muslim Baby Names Starting With A with meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Names, Muslim. Search a new beautiful with combination of A: ',
                        KEYWORDS: 'indian baby names, muslim baby names starting with A',
                        TWITTER_TITLE:
                            'Unique Islamic muslim Baby Names Starting With A with meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Names, Muslim. Search a new beautiful with combination of A: ',
                        OG_TITLE:
                            'Unique Islamic muslim Baby Names Starting With A with meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Names, Muslim. Search a new beautiful with combination of A: ',
                        OG_URL: 'https://www.parentune.com/baby-names/muslim-names-starting-with-a'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/muslim-names-starting-with-a',
                    AL_ANDROID_URL:
                        'parentune://www.parentune.com/baby-names/muslim-names-starting-with-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/muslim-names-starting-with-a'
                }
            },
            SIKH: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names/sikh',
                // // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names?amp=true',
                BABY_NAME_TITLE:
                    'Unique punjabi / sikh Baby Names List With Meaning | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique punjabi / sikh Baby Names List With Meaning | Parentune.com',
                    DESCRIPTION:
                        'Unique Indian names for newborn Sikh baby with Meaning. Find a latest 2024 name list of top, cute and modern Sikh baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    KEYWORDS:
                        'indian baby names, indian sikh baby names, sikh baby names, unique sikh baby names',
                    TWITTER_TITLE:
                        'Unique punjabi / sikh Baby Names List With Meaning | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Unique Indian names for newborn Sikh baby with Meaning. Find a latest 2024 name list of top, cute and modern Sikh baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_TITLE: 'Unique punjabi / sikh Baby Names List With Meaning | Parentune.com',
                    OG_DESCRIPTION:
                        'Unique Indian names for newborn Sikh baby with Meaning. Find a latest 2024 name list of top, cute and modern Sikh baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_URL: 'https://www.parentune.com/baby-names/sikh'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names/sikh',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/sikh',
                APPLE_ITUNES_APP:
                    'app-id=1039105238, app-argument=www.parentune.com/baby-names/sikh',
                WITH_LETTER: {
                    CANONICAL_LINK:
                        'https://www.parentune.com/baby-names/sikh-names-starting-with-a',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/boy/starting-with-a?amp=true',
                    BABY_NAME_TITLE:
                        'Unique punjabi / sikh Baby Names Starting With A with meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique punjabi / sikh Baby Names Starting With A with meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Names, Sikh. Search a new beautiful with combination of A: ',
                        KEYWORDS: 'indian baby names',
                        TWITTER_TITLE:
                            'Unique punjabi / sikh Baby Names Starting With A with meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Names, Sikh. Search a new beautiful with combination of A: ',
                        OG_TITLE:
                            'Unique punjabi / sikh Baby Names Starting With A with meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Names, Sikh. Search a new beautiful with combination of A: ',
                        OG_URL: 'https://www.parentune.com/baby-names/sikh-names-starting-with-a'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/sikh-names-starting-with-a',
                    AL_ANDROID_URL:
                        'parentune://www.parentune.com/baby-names/sikh-names-starting-with-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/sikh-names-starting-with-a'
                }
            },
            CHRISTION: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names/christian',
                // // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names?amp=true',
                BABY_NAME_TITLE:
                    'Unique christian / catholic Baby Names List With Meaning | Parentune.comUnique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                META_TAG: {
                    TITLE: 'Unique christian / catholic Baby Names List With Meaning | Parentune.comUnique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    DESCRIPTION:
                        'Unique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    KEYWORDS:
                        'indian baby names, indian christian baby names, christian baby names, unique christian baby names',
                    TWITTER_TITLE:
                        'Unique christian / catholic Baby Names List With Meaning | Parentune.comUnique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    TWITTER_DESCRIPTION:
                        'Unique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_TITLE:
                        'Unique christian / catholic Baby Names List With Meaning | Parentune.comUnique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_DESCRIPTION:
                        'Unique Indian names for newborn Christian baby with Meaning. Find a latest 2024 name list of top, cute and modern Christian baby Names. Search a new beautiful name for your baby by alphabets &amp; letters.',
                    OG_URL: 'https://www.parentune.com/baby-names/christian'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names/christian',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/christian',
                APPLE_ITUNES_APP:
                    'app-id=1039105238, app-argument=www.parentune.com/baby-names/christian',
                WITH_LETTER: {
                    CANONICAL_LINK:
                        'https://www.parentune.com/baby-names/christian-names-starting-with-a',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/boy/starting-with-a?amp=true',
                    BABY_NAME_TITLE:
                        'Unique christian / catholic Baby Names Starting With A List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique christian / catholic Baby Names Starting With A List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic &amp; modern baby Names, Christian. Search a new beautiful with combination of A: ',
                        KEYWORDS: 'indian baby names, christian baby names starting with A',
                        TWITTER_TITLE:
                            'Unique christian / catholic Baby Names Starting With A List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic &amp; modern baby Names, Christian. Search a new beautiful with combination of A: ',
                        OG_TITLE:
                            'Unique christian / catholic Baby Names Starting With A List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic &amp; modern baby Names, Christian. Search a new beautiful with combination of A: ',
                        OG_URL: 'https://www.parentune.com/baby-names/christian-names-starting-with-a'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/christian-names-starting-with-a',
                    AL_ANDROID_URL:
                        'parentune://www.parentune.com/baby-names/christian-names-starting-with-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/christian-names-starting-with-a'
                }
            },
            BOY: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names/boy',
                // // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names/boy?amp=true',
                BABY_NAME_TITLE:
                    'Unique Indian Baby Boy Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique Indian Baby Boy Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                    DESCRIPTION:
                        'Find a new, meaningful yet modern name for your Boy child from the latest 2024 name list unique and cute baby Boy names. Search beautiful names with meaning by specific religion & alphabets.',
                    KEYWORDS:
                        'indian baby names, indian boy baby names, boy baby names, unique boy baby names, baby names for boy',
                    TWITTER_TITLE:
                        'Unique Indian Baby Boy Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your Boy child from the latest 2024 name list unique and cute baby Boy names. Search beautiful names with meaning by specific religion & alphabets.',
                    OG_TITLE:
                        'Unique Indian Baby Boy Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                    OG_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your Boy child from the latest 2024 name list unique and cute baby Boy names. Search beautiful names with meaning by specific religion & alphabets.',
                    OG_URL: 'https://www.parentune.com/baby-names/boy'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names/boy',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/boy',
                APPLE_ITUNES_APP:
                    'app-id=1039105238, app-argument=www.parentune.com/baby-names/boy',
                WITH_LETTER: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/boy/starting-with-a',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/boy/starting-with-a?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Indian Baby Boy Names Starting With A with Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Indian Baby Boy Names Starting With A with Meaning | Parentune.com',
                        DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your Boy child from the  latest 2024 name list unique and cute baby Boy names. Search beautiful names starting with A letter with meaning by specific religion & alphabets',
                        KEYWORDS: 'indian baby names',
                        TWITTER_TITLE:
                            'Unique Indian Baby Boy Names Starting With A with Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your Boy child from the  latest 2024 name list unique and cute baby Boy names. Search beautiful names starting with A letter with meaning by specific religion & alphabets',
                        OG_TITLE:
                            'Unique Indian Baby Boy Names Starting With A with Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your Boy child from the  latest 2024 name list unique and cute baby Boy names. Search beautiful names starting with A letter with meaning by specific religion & alphabets',
                        OG_URL: 'https://www.parentune.com/baby-names/boy'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/boy/starting-with-a',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/boy/starting-with-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/boy/starting-with-a'
                },

                HINDU: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/hindu/boy-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/hindu/boy-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Indian Hindu Baby Boy Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Indian Hindu Baby Boy Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Boy with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        KEYWORDS:
                            'indian baby names, indian boy baby names, hindu boy baby names, unique boy hindu names',
                        TWITTER_TITLE:
                            'Unique Indian Hindu Baby Boy Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Boy with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_TITLE:
                            'Unique Indian Hindu Baby Boy Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Boy with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/hindu/boy-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/hindu/boy-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/hindu/boy-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/hindu/boy-names',
                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/hindu/boy-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/hindu/boy-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique Indian hindu Baby Boy Names starting with A with meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique Indian hindu Baby Boy Names starting with A with meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique Indian names for newborn Hindu baby Boy Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Boy Names, Hindu. Search a new beautiful with combination of A ',
                            KEYWORDS:
                                'indian baby names, hindu boy baby names starting with A, hindu baby names for boy starting with A ',
                            TWITTER_TITLE:
                                'Unique Indian hindu Baby Boy Names starting with A with meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique Indian names for newborn Hindu baby Boy Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Boy Names, Hindu. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique Indian hindu Baby Boy Names starting with A with meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique Indian names for newborn Hindu baby Boy Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Boy Names, Hindu. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/hindu/boy-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/hindu/boy-names-starting-with-a',
                        AL_ANDROID_URL:
                            'al:android:url" content="parentune://www.parentune.com/baby-names/hindu/boy-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'apple-itunes-app" content="app-id=1039105238, app-argument=www.parentune.com/baby-names/hindu/boy-names-starting-with-a'
                    }
                },

                SIKH: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/sikh/boy-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/sikh/boy-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique punjabi / sikh Baby Boy Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique punjabi / sikh Baby Boy Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Boy with Meaning. Find a latest 2024 name list of top punjabi, cute and modern Sikh baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        KEYWORDS: 'indian baby names, sikh boy baby names ',
                        TWITTER_TITLE:
                            'Unique punjabi / sikh Baby Boy Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Boy with Meaning. Find a latest 2024 name list of top punjabi, cute and modern Sikh baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_TITLE:
                            'Unique punjabi / sikh Baby Boy Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Boy with Meaning. Find a latest 2024 name list of top punjabi, cute and modern Sikh baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/sikh/boy-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/sikh/boy-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/sikh/boy-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/sikh/boy-names',
                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/sikh/boy-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/sikh/boy-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique punjabi / sikh Baby Boy Names Starting With A with meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique punjabi / sikh Baby Boy Names Starting With A with meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique punjabi names for newborn Sikh baby Boy Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Boy Names, Sikh. Search a new beautiful with combination of A ',
                            KEYWORDS:
                                'indian baby names, sikh baby names starting with A, punjabi boy baby names',
                            TWITTER_TITLE:
                                'Unique punjabi / sikh Baby Boy Names Starting With A with meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique punjabi names for newborn Sikh baby Boy Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Boy Names, Sikh. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique punjabi / sikh Baby Boy Names Starting With A with meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique punjabi names for newborn Sikh baby Boy Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Boy Names, Sikh. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/sikh/boy-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/sikh/boy-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/sikh/boy-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/sikh/boy-names-starting-with-a'
                    }
                },
                MUSLIM: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/muslim/boy-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/muslim/boy-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Islamic muslim Baby Boy Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Islamic muslim Baby Boy Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Boy with Meaning. Find a latest 2024 name list of top islamic, cute and modern Muslim baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        KEYWORDS: 'indian baby names, muslim boy baby names',
                        TWITTER_TITLE:
                            'Unique Islamic muslim Baby Boy Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Boy with Meaning. Find a latest 2024 name list of top islamic, cute and modern Muslim baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_TITLE:
                            'Unique Islamic muslim Baby Boy Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Boy with Meaning. Find a latest 2024 name list of top islamic, cute and modern Muslim baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/muslim/boy-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/muslim/boy-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/muslim/boy-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/muslim/boy-names',
                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/muslim/boy-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/muslim/boy-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique Islamic muslim Baby Boy Names Starting With A with meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique Islamic muslim Baby Boy Names Starting With A with meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique islamic names for newborn muslim baby Boy Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Boy Names, Muslim. Search a new beautiful with combination of A ',
                            KEYWORDS: 'indian baby names, muslim boy baby names starting with A',
                            TWITTER_TITLE:
                                'Unique Islamic muslim Baby Boy Names Starting With A with meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique islamic names for newborn muslim baby Boy Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Boy Names, Muslim. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique Islamic muslim Baby Boy Names Starting With A with meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique islamic names for newborn muslim baby Boy Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Boy Names, Muslim. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/muslim/boy-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/muslim/boy-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/muslim/boy-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/muslim/boy-names-starting-with-a'
                    }
                },
                CHRISTIAN: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/christian/boy-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/christian/boy-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique christian / catholic Baby Boy Names List With Meaning | Parentune.com ',
                    META_TAG: {
                        TITLE: 'Unique christian / catholic Baby Boy Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Boy with Meaning. Find a latest 2024 name list having biblical, catholic & modern Christian baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        KEYWORDS: 'indian baby names',
                        TWITTER_TITLE:
                            'Unique christian / catholic Baby Boy Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Boy with Meaning. Find a latest 2024 name list having biblical, catholic & modern Christian baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_TITLE:
                            'Unique christian / catholic Baby Boy Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Boy with Meaning. Find a latest 2024 name list having biblical, catholic & modern Christian baby Boy Names. Search a new beautiful name for your baby Boy by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/christian/boy-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/christian/boy-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/christian/boy-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/christian/boy-names',
                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/christian/boy-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/christian/boy-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique christian / catholic Baby Boy Names Starting With A List With Meaning. | Parentune.comUnique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                        META_TAG: {
                            TITLE: 'Unique christian / catholic Baby Boy Names Starting With A List With Meaning. | Parentune.comUnique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                            DESCRIPTION:
                                'Unique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                            KEYWORDS: 'indian baby names',
                            TWITTER_TITLE:
                                'Unique christian / catholic Baby Boy Names Starting With A List With Meaning. | Parentune.comUnique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                            TWITTER_DESCRIPTION:
                                'Unique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique christian / catholic Baby Boy Names Starting With A List With Meaning. | Parentune.comUnique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                            OG_DESCRIPTION:
                                'Unique catholic names for newborn Christian baby Boy Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Boy Names, Christian. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/christian/boy-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/christian/boy-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/christian/boy-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/christian/boy-names-starting-with-a'
                    }
                }
            },

            GIRL: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names/girl',
                // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names/girl?amp=true',
                BABY_NAME_TITLE:
                    'Unique Indian Baby Girl Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique Indian Baby Girl Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                    DESCRIPTION:
                        'Find a new, meaningful yet modern name for your Girl child from the latest 2024 name list unique and cute baby Girl names. Search beautiful names with meaning by specific religion & alphabets.',
                    KEYWORDS:
                        'indian baby names, girl baby names, indian girl baby names, baby names for girl',
                    TWITTER_TITLE:
                        'Unique Indian Baby Girl Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your Girl child from the latest 2024 name list unique and cute baby Girl names. Search beautiful names with meaning by specific religion & alphabets.',
                    OG_TITLE:
                        'Unique Indian Baby Girl Names List 2024 With Meaning. Cute Names For  Kids. | Parentune.com',
                    OG_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your Girl child from the latest 2024 name list unique and cute baby Girl names. Search beautiful names with meaning by specific religion & alphabets.',
                    OG_URL: 'https://www.parentune.com/baby-names/girl'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names/girl',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/girl',
                APPLE_ITUNES_APP:
                    'app-id=1039105238, app-argument=www.parentune.com/baby-names/girl',

                WITH_LETTER: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/girl/starting-with-a',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/girl/starting-with-a?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Indian Baby Girl Names Starting With A with Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Indian Baby Girl Names Starting With A with Meaning | Parentune.com',
                        DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your Girl child from the  latest 2024 name list unique and cute baby Girl names. Search beautiful names starting with A letter with meaning by specific religion & alphabets',
                        KEYWORDS: 'indian baby names, girl baby names starting with A',
                        TWITTER_TITLE:
                            'Unique Indian Baby Girl Names Starting With A with Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your Girl child from the  latest 2024 name list unique and cute baby Girl names. Search beautiful names starting with A letter with meaning by specific religion & alphabets',
                        OG_TITLE:
                            'Unique Indian Baby Girl Names Starting With A with Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Find a new, meaningful yet modern name Starting With A letter for your Girl child from the  latest 2024 name list unique and cute baby Girl names. Search beautiful names starting with A letter with meaning by specific religion & alphabets',
                        OG_URL: 'https://www.parentune.com/baby-names/girl/starting-with-a'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/girl/starting-with-a',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/girl/starting-with-a',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/girl/starting-with-a'
                },

                SIKH: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/sikh/girl-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/sikh/girl-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique punjabi / sikh Baby Girl Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique punjabi / sikh Baby Girl Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Girl with Meaning. Find a latest 2024 name list of top punjabi, cute and modern Sikh baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        KEYWORDS: 'indian baby names',
                        TWITTER_TITLE:
                            'Unique punjabi / sikh Baby Girl Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Girl with Meaning. Find a latest 2024 name list of top punjabi, cute and modern Sikh baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_TITLE:
                            'Unique punjabi / sikh Baby Girl Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique punjabi names for newborn Sikh baby Girl with Meaning. Find a latest 2024 name list of top punjabi, cute and modern Sikh baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/sikh/girl-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/sikh/girl-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/sikh/girl-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/sikh/girl-names',

                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/sikh/girl-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/sikh/girl-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique punjabi / sikh Baby Girl Names Starting With A with meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique punjabi / sikh Baby Girl Names Starting With A with meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique punjabi names for newborn Sikh baby Girl Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Girl Names, Sikh. Search a new beautiful with combination of A ',
                            KEYWORDS: 'indian baby names',
                            TWITTER_TITLE:
                                'Unique punjabi / sikh Baby Girl Names Starting With A with meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique punjabi names for newborn Sikh baby Girl Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Girl Names, Sikh. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique punjabi / sikh Baby Girl Names Starting With A with meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique punjabi names for newborn Sikh baby Girl Starting With A letter with Meaning. Find a latest 2024 name list of punjabi, cute and modern baby Girl Names, Sikh. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/sikh/girl-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'ndroid-app://com.parentune.app/https/www.parentune.com/baby-names/sikh/girl-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/sikh/girl-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/sikh/girl-names-starting-with-a'
                    }
                },
                HINDU: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/hindu/girl-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/hindu/girl-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Indian Hindu Baby Girl Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Indian Hindu Baby Girl Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Girl with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        KEYWORDS:
                            'indian baby names, indian girl baby names, hindu girl baby names',
                        TWITTER_TITLE:
                            'Unique Indian Hindu Baby Girl Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Girl with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_TITLE:
                            'Unique Indian Hindu Baby Girl Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique Indian names for newborn Hindu baby Girl with Meaning. Find a latest 2024 name list of top, cute and modern Hindu baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/hindu/girl-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/hindu/girl-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/hindu/girl-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/hindu/girl-names',

                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/hindu/girl-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/hindu/girl-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique Indian hindu Baby Girl Names starting with A with meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique Indian hindu Baby Girl Names starting with A with meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique Indian names for newborn Hindu baby Girl Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Girl Names, Hindu. Search a new beautiful with combination of A ',
                            KEYWORDS: 'indian baby names, girl baby names starting with A',
                            TWITTER_TITLE:
                                'Unique indian hindu Baby Girl Names starting with A with meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique Indian names for newborn Hindu baby Girl Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Girl Names, Hindu. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique Indian hindu Baby Girl Names starting with A with meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique Indian names for newborn Hindu baby Girl Starting With A letter with Meaning. Find latest 2024 name list of cute and modern baby Girl Names, Hindu. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/hindu/girl-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/hindu/girl-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/hindu/girl-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/hindu/girl-names-starting-with-a'
                    }
                },
                MUSLIM: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/muslim/girl-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/muslim/girl-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique Islamic muslim Baby Girl Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique Islamic muslim Baby Girl Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Girl with Meaning. Find a latest 2024 name list of top islamic, cute and modern Muslim baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        KEYWORDS:
                            'indian baby names, muslim girl baby names, girl baby names muslim',
                        TWITTER_TITLE:
                            'Unique Islamic muslim Baby Girl Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Girl with Meaning. Find a latest 2024 name list of top islamic, cute and modern Muslim baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_TITLE:
                            'Unique Islamic muslim Baby Girl Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique islamic names for newborn muslim baby Girl with Meaning. Find a latest 2024 name list of top islamic, cute and modern Muslim baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/muslim/girl-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/muslim/girl-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/muslim/girl-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/muslim/girl-names',
                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'canonical" href="https://www.parentune.com/baby-names/muslim/girl-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/muslim/girl-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique Islamic muslim Baby Girl Names Starting With A with meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique Islamic muslim Baby Girl Names Starting With A with meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique islamic names for newborn muslim baby Girl Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Girl Names, Muslim. Search a new beautiful with combination of A ',
                            KEYWORDS: 'indian baby names, muslim girl baby names starting with A',
                            TWITTER_TITLE:
                                'Unique Islamic muslim Baby Girl Names Starting With A with meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique islamic names for newborn muslim baby Girl Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Girl Names, Muslim. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique Islamic muslim Baby Girl Names Starting With A with meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique islamic names for newborn muslim baby Girl Starting With A letter with Meaning. Find a latest 2024 name list of islamic/arabic modern baby Girl Names, Muslim. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/muslim/girl-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/muslim/girl-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/muslim/girl-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/muslim/girl-names-starting-with-a'
                    }
                },
                CHRISTIAN: {
                    CANONICAL_LINK: 'https://www.parentune.com/baby-names/christian/girl-names',
                    // // BABY_NAME_AMP_HTML:
                    //                       'https://www.parentune.com/baby-names/christian/girl-names?amp=true',
                    BABY_NAME_TITLE:
                        'Unique christian / catholic Baby Girl Names List With Meaning | Parentune.com',
                    META_TAG: {
                        TITLE: 'Unique christian / catholic Baby Girl Names List With Meaning | Parentune.com',
                        DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Girl with Meaning. Find a latest 2024 name list having biblical, catholic & modern Christian baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        KEYWORDS: 'indian baby names, christian baby names',
                        TWITTER_TITLE:
                            'Unique christian / catholic Baby Girl Names List With Meaning | Parentune.com',
                        TWITTER_DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Girl with Meaning. Find a latest 2024 name list having biblical, catholic & modern Christian baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_TITLE:
                            'Unique christian / catholic Baby Girl Names List With Meaning | Parentune.com',
                        OG_DESCRIPTION:
                            'Unique catholic names for newborn Christian baby Girl with Meaning. Find a latest 2024 name list having biblical, catholic & modern Christian baby Girl Names. Search a new beautiful name for your baby Girl by alphabets & letters.',
                        OG_URL: 'https://www.parentune.com/baby-names/christian/girl-names'
                    },
                    ALTERNATE_LINK:
                        'android-app://com.parentune.app/https/www.parentune.com/baby-names/christian/girl-names',
                    AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names/christian/girl-names',
                    APPLE_ITUNES_APP:
                        'app-id=1039105238, app-argument=www.parentune.com/baby-names/christian/girl-names',
                    WITH_LETTER: {
                        CANONICAL_LINK:
                            'https://www.parentune.com/baby-names/christian/girl-names-starting-with-a',
                        // // BABY_NAME_AMP_HTML:
                        // 'https://www.parentune.com/baby-names/christian/girl-names-starting-with-a?amp=true',
                        BABY_NAME_TITLE:
                            'Unique christian / catholic Baby Girl Names Starting With A List With Meaning. | Parentune.com',
                        META_TAG: {
                            TITLE: 'Unique christian / catholic Baby Girl Names Starting With A List With Meaning. | Parentune.com',
                            DESCRIPTION:
                                'Unique catholic names for newborn Christian baby Girl Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Girl Names, Christian. Search a new beautiful with combination of A ',
                            KEYWORDS: 'indian baby names',
                            TWITTER_TITLE:
                                'Unique christian / catholic Baby Girl Names Starting With A List With Meaning. | Parentune.com',
                            TWITTER_DESCRIPTION:
                                'Unique catholic names for newborn Christian baby Girl Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Girl Names, Christian. Search a new beautiful with combination of A ',
                            OG_TITLE:
                                'Unique christian / catholic Baby Girl Names Starting With A List With Meaning. | Parentune.com',
                            OG_DESCRIPTION:
                                'Unique catholic names for newborn Christian baby Girl Starting With A letter with Meaning. Find a latest 2024 name list having biblical, catholic & modern baby Girl Names, Christian. Search a new beautiful with combination of A ',
                            OG_URL: 'https://www.parentune.com/baby-names/christian/girl-names-starting-with-a'
                        },
                        ALTERNATE_LINK:
                            'android-app://com.parentune.app/https/www.parentune.com/baby-names/christian/girl-names-starting-with-a',
                        AL_ANDROID_URL:
                            'parentune://www.parentune.com/baby-names/christian/girl-names-starting-with-a',
                        APPLE_ITUNES_APP:
                            'app-id=1039105238, app-argument=www.parentune.com/baby-names/christian/girl-names-starting-with-a'
                    }
                }
            },
            NAME_DETAIL: {
                CANONICAL_LINK: 'https://www.parentune.com/baby-names',
                // BABY_NAME_AMP_HTML: 'https://www.parentune.com/baby-names?amp=true',
                BABY_NAME_TITLE:
                    'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                META_TAG: {
                    TITLE: 'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                    DESCRIPTION:
                        'Find a new, meaningful yet modern name for your new born child from the latest 2024 name list of top, unique and cute baby names. Search beautiful names with meaning for new born kids and children by specific alphabets.',
                    KEYWORDS: 'indian baby names',
                    TWITTER_TITLE:
                        'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                    TWITTER_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your new born child from the latest 2024 name list of top, unique and cute baby names. Search beautiful names with meaning for new born kids and children by specific alphabets.',
                    OG_TITLE: 'Unique & Modern Baby Names List 2024 With Meaning. | Parentune.com',
                    OG_DESCRIPTION:
                        'Find a new, meaningful yet modern name for your new born child from the latest 2024 name list of top, unique and cute baby names. Search beautiful names with meaning for new born kids and children by specific alphabets.',
                    OG_URL: 'https://www.parentune.com/baby-names'
                },
                ALTERNATE_LINK:
                    'android-app://com.parentune.app/https/www.parentune.com/baby-names',
                AL_ANDROID_URL: 'parentune://www.parentune.com/baby-names',
                APPLE_ITUNES_APP: 'app-id=1039105238, app-argument=www.parentune.com/baby-names'
            },
            MAIN_HEADING: {
                HOME: t('page_title_landing'),
                PATTERN1: t('page_title_alphabet'),
                RELIGION: {
                    HINDU: {
                        HOME: t('page_title_origin'),
                        PATTERN1: t('page_title_origin_alphabet')
                    },
                    SIKH: {
                        HOME: t('page_title_origin'),
                        PATTERN1: t('page_title_origin_alphabet')
                    },
                    MUSLIM: {
                        HOME: t('page_title_origin'),
                        PATTERN1: t('page_title_origin_alphabet')
                    },
                    CHRISTIAN: {
                        HOME: t('page_title_origin'),
                        PATTERN1: t('page_title_origin_alphabet')
                    }
                },

                BOY: {
                    HOME: t('page_title_gender'),
                    PATTERN1: t('page_title_gender_alphabet'),
                    RELIGION: {
                        HINDU: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        SIKH: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        MUSLIM: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        CHRISTIAN: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        }
                    }
                },
                GIRL: {
                    HOME: t('page_title_gender'),
                    PATTERN1: t('page_title_gender_alphabet'),
                    RELIGION: {
                        HINDU: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        SIKH: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        MUSLIM: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        },
                        CHRISTIAN: {
                            HOME: t('page_title_gender_origin'),
                            PATTERN1: t('page_title_gender_origin_alphabet')
                        }
                    }
                }
            }
        }
    }
};

export default Content;
