import './InterestsPage.css';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { addUserdata, setUserData } from '../../store/slices/userDataSlice';
import { useUserData } from '../../utils/hooks/useUserData';
import ButtonPrimary from '../../components/V2Components/ButtonPrimary';
import pushEventToGtm from '../../utils/pushEventToGtm';
import InterestTopic from '../../components/InterestTopic/InterestTopic';
import ToastMessage from '../../components/Toast/ToastMessage';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { useFetchInterest } from './hooks/useFetchInterest';
import InterestTagsList from './InterestTagsList';
import { useOnboardingData } from '../../utils/hooks/useOnboardingData';
import { handleInterestsSubmit } from './utils';
import { handleSteps } from '../../store/slices/onboardingSlice';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

//---------------------------------------
// ALERT:
// this componenet is not being used
//---------------------------------------

// const REACT_APP_ONBOARDING_SERVER = process.env.REACT_APP_ONBOARDING_SERVER;
const REACT_APP_ONBOARDING_SERVER = null;

function InterestsPage(props: {
    onClick?: () => void;
    handleBack?: () => void;
    setNav?: any;
    isForEditProfile?: boolean;
}) {
    pushEventToGtm('page_view', { pt_page: 'InterestsPage' });
    const { setNav } = props;
    const dispatch = useAppDispatch();
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const { userData: user_data } = useUserData();
    const [isLoading, setIsLoading] = useState(false);
    const [ChoosedInterest, setChoosedInterest] = useState<any>([]);
    const [interestFlag, setInterestFlag] = useState(true);
    const [pickedInterestForNextPage, setpickedInterestForNextPage] = useState<any>([]);
    const [enableToastMsg, SetEnableToastMsg] = useState(false);
    const { loading, data: interests, error } = useFetchInterest();
    const { onboardingSteps } = useOnboardingData();
    const { isForEditProfile } = useOnboardingData();
    const navigate = useNavigate();

    const handleNext = async () => {
        handleInterestsSubmit(
            user_data,
            ChoosedInterest,
            setIsLoading,
            SetEnableToastMsg,
            dispatch,
            setNav ?? null,
            props?.isForEditProfile ?? false,
            props?.handleBack ?? null
        );
        pushEventToGtm('select-interest-user-onboard', {
            'select-interest': 'select-interest-next',
            url: window.location.pathname,
            newGuestUid
        });
    };
    const handleBack = () => {
        dispatch(handleSteps());
        if (!onboardingSteps) return;
        console.log('nav>>>', onboardingSteps.at(-2));
        setNav(onboardingSteps && onboardingSteps.at(-2));
    };

    const handleInterestSelection = (id: number, interest_name: string, isUserInterest: any) => {
        if (isUserInterest) {
            setChoosedInterest(ChoosedInterest.filter((i: any) => i !== id));
            setInterestFlag(false);
            setpickedInterestForNextPage((prev: any) =>
                prev.filter((name: string) => name !== interest_name)
            );
        } else {
            if (ChoosedInterest.includes(id)) {
                setChoosedInterest(ChoosedInterest.filter((i: any) => i !== id));
                setpickedInterestForNextPage((prev: any) =>
                    prev.filter((name: any) => name !== interest_name)
                );
            } else {
                setChoosedInterest([...ChoosedInterest, id]);
                setpickedInterestForNextPage((prev: any) => [...prev, interest_name]);
            }
            setInterestFlag(false);
        }
        if (isUserInterest && !ChoosedInterest.includes(id)) {
            setChoosedInterest([...ChoosedInterest, id]);
        }
    };

    return (
        <div className="max-w-4xl mx-auto pt-3 flex flex-col px-4 min-h-screen">
            {/* {enableToastMsg && (
                <ToastMessage msg={'Interest Updated, You will feel the difference'} />
            )} */}
            <Header
                onBack={props.handleBack ? props.handleBack : handleBack}
                back
                headerText="Choose Interests"
            />
            Select 3 or more topics of interest to personalize your experience.
            <div>
                <br />
                <div className="">
                    {interests &&
                        Object.keys(interests).length > 0 &&
                        Object.keys(interests).map((key, index) => (
                            <div key={index}>
                                <InterestTagsList
                                    ChoosedInterest={ChoosedInterest}
                                    setChoosedInterest={setChoosedInterest}
                                    handleInterestSelection={handleInterestSelection}
                                    interests={interests}
                                    key={index}
                                    keyx={key}
                                    interestFlag={interestFlag}
                                />
                            </div>
                        ))}
                </div>
            </div>
            <div className="flex-1"></div>
            <div className="sticky bottom-0 pb-3 pt-3 bg-white flex justify-center">
                <ButtonPrimary
                    size="large"
                    text={isForEditProfile ? 'SAVE' : 'Next'}
                    isLoading={isLoading}
                    onClick={handleNext}
                    disabled={ChoosedInterest?.length < 3}
                />
            </div>
        </div>
    );
}

export default InterestsPage;
