import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { WorkShopLogCountHandler } from '../../components/WorkshopViewButton/utils';
import { addUserdata } from '../../store/slices/userDataSlice';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
import { useUserData } from '../../utils/hooks/useUserData';

interface ButtonType {
    workshopdetails: any;
    handleVideo?: any;
    watchOnOff: boolean;
    canWatch: boolean;
    isForNestleOffer: any;
    hasworkshopconsumed: boolean;
    sethasworkshopconsumed: any;
}

const Button = (props: ButtonType) => {
    const {
        workshopdetails,
        handleVideo,
        watchOnOff,
        canWatch,
        isForNestleOffer,
        hasworkshopconsumed,
        sethasworkshopconsumed
    } = props;
    const navigate = useNavigate();
    const { userData } = useUserData();
    const location = useLocation();
    const dispatch = useDispatch();
    const [currency, setCurrency] = useState<string>('');

    useEffect(() => {
        const getCurrency = async () => {
            const { country, currency_symbol } = await useFetchUserLocation();
            setCurrency(currency_symbol);
        };

        getCurrency();
    }, []);

    const handleNestleUSer = () => {
        if (isForNestleOffer) {
            handleVideo();
            window.scroll(0, 0);
            sethasworkshopconsumed(true);
            OneFreeLearnCreditHandler();
        }
    };

    const OneFreeLearnCreditHandler = async () => {
        const res = await WorkShopLogCountHandler({
            user_data: userData,
            workshopId: workshopdetails.id
        });
        if (res?.statusCode != '200') return;
        GetUserProfile(
            async (data) => {
                dispatch(addUserdata({ ...data?.data }));
                const config = {
                    value: workshopdetails?.id, // The data you want to store
                    expiry: new Date().getTime() + 30 * 24 * 60 * 60 * 1000 // Expiration time: current time + 30 days
                };

                // Store the object as a JSON string in localStorage
                localStorage.setItem('_nestle_free_workshop', JSON.stringify(config));
            },
            (err) => {
                console.error('Error: ', err);
            },
            userData
        );
    };

    const handleUser = () => {
        userData &&
            navigate('/booking-summary', {
                state: {
                    id: workshopdetails.id,
                    start_date: workshopdetails.start_date,
                    title: workshopdetails.title,
                    amount: workshopdetails.amount,
                    avatar: workshopdetails.expertdetails[0].avatar,
                    name: workshopdetails.expertdetails[0].name,
                    specialization: workshopdetails.expertdetails[0].specialization,
                    status: workshopdetails.status
                }
            });
        !userData && navigate('/register', { state: { from: location } });
    };

    if (isForNestleOffer) {
        return (
            <>
                {!hasworkshopconsumed && isForNestleOffer && (
                    <div className="fixed bottom-0 left-0  z-50  w-full flex justify-center">
                        <div className="max-w-4xl w-full  flex justify-between items-center px-[22px]  py-4  bg-white">
                            <h2 className="text-[#373737] text-[16px] font-roboto font-medium">
                                Use 1 Learn credit
                            </h2>
                            <button
                                className="w-[181px] h-[45px] bg-[#377E8B] rounded-[25px] text-white"
                                onClick={() => handleNestleUSer()}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (workshopdetails.status === 'archive' && !workshopdetails.amount) {
        return (
            <>
                {!watchOnOff && (
                    <div className="flex justify-center">
                        <div
                            className="w-full fixed bottom-0 left-0  right-0 z-50  flex justify-center items-center px-[22px]  py-4  bg-white"
                            onClick={() => {
                                handleVideo();
                                window.scroll(0, 0);
                            }}
                        >
                            <button className="max-w-4xl w-full h-[45px] bg-[#377E8B] rounded-[25px] text-white">
                                WATCH NOW
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (workshopdetails.status === 'archive' && workshopdetails.amount && canWatch) {
        return (
            <>
                {!watchOnOff && (
                    <div className="flex justify-center">
                        <div
                            className="w-full fixed bottom-0 left-0  right-0 z-50  flex justify-center items-center px-[22px]  py-4  bg-white"
                            onClick={() => {
                                handleVideo();
                                window.scroll(0, 0);
                            }}
                        >
                            <button className="max-w-4xl w-full h-[45px] bg-[#377E8B] rounded-[25px] text-white">
                                WATCH NOW
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (canWatch) {
        return null;
    }

    if (workshopdetails.status === 'upcoming' && !workshopdetails.amount) {
        return null;
    }

    if (workshopdetails.status === 'live' && !workshopdetails.amount) {
        return null;
    }

    return (
        <>
            {workshopdetails.amount && (
                <div className="fixed bottom-0 left-0  z-50  w-full flex justify-center">
                    <div className="max-w-4xl w-full  flex justify-between items-center px-[22px]  py-4  bg-white">
                        <h2 className="text-[#373737] text-[24px] font-medium">
                            {currency ? currency : '₹'} {workshopdetails.amount}
                        </h2>
                        <button
                            className="w-[181px] h-[45px] bg-[#377E8B] rounded-[25px] text-white"
                            onClick={() => handleUser()}
                        >
                            BUY NOW
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Button;
