import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterStateType {
    topicId?: number | null;
    ageGroup?: string | null;
    searchVal?: string | null;
}

const initialState: FilterStateType = {
    topicId: null,
    ageGroup: null,
    searchVal: ''
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        addFilter: (state, action: PayloadAction<FilterStateType>) => {
            const { topicId, ageGroup, searchVal } = action.payload;
            if (topicId) {
                state.topicId = action.payload.topicId;
            }

            if (ageGroup) {
                state.ageGroup = action.payload.ageGroup;
            }

            if (searchVal) {
                state.searchVal = action.payload.searchVal;
            }
        },

        clearFilters: (state, action: PayloadAction<FilterStateType>) => {
            state.topicId = null;
            state.ageGroup = null;
            state.searchVal = '';
        }
    }
});
export const { addFilter, clearFilters } = filterSlice.actions;
export default filterSlice.reducer;
