import axios from 'axios';
function NestleWorkshop(params: any, onSuccess: any, onFailure?: any) {
    axios
        .post(
            process.env.REACT_APP_SUBSCRIPTION_SERVER + 'askDocNestle/workshop',
            {
                productId: params?.productId
            },
            {
                headers: {
                    instanceId: params?.user_data?.instanceId?.toString() || '',
                    authToken: params?.user_data?.authToken || '',
                    mobile: params?.mobile ?? '',
                    email: params?.email ?? '',
                    latitude: params?.latitude,
                    longitude: params?.longitude
                }
            }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}

export default NestleWorkshop;
