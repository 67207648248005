import React from 'react';
import ReactDOM from 'react-dom';
import { overlay, overlayContent } from './OverlayStyles';

export interface OverlayProps {
    handleModalClose?: any;
    Content?: any;
    isForMileStoneCard?: any;
    OverlayStyle?: any;
}

class Overlay extends React.Component<OverlayProps> {
    overlayContainer: any;
    constructor(props: OverlayProps) {
        super(props);
        // Create container DOM element and append to DOM.
        this.overlayContainer = document.createElement('div');
        this.overlayContainer.className = 'overlay-custom';

        document?.body?.appendChild(this.overlayContainer);
    }

    componentWillUnmount() {
        document?.body?.removeChild(this.overlayContainer);
        document.body.style.overflow = '';
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
    }

    render() {
        return ReactDOM.createPortal(
            <div
                style={{ ...overlay, ...this?.props?.OverlayStyle }}
                className={`${this.props.Content ? 'flex justify-center items-center ' : ''}`}
                onClick={this.props.handleModalClose}
            >
                <div
                    style={this.props.Content ? this.props.Content : overlayContent}
                    onClick={(e) => e.stopPropagation()}
                >
                    {this.props.children}
                </div>
            </div>,
            this.overlayContainer
        );
    }
}

export default Overlay;
