import React, { useState } from 'react';
import { useUserData } from '../../utils/hooks/useUserData';
import { UnsubscribeAPI } from '../../api/Unsubscribe';
import { useSearchParams, Navigate } from 'react-router-dom';

const Unsubscribe: React.FC = () => {
    const { userData: user_data } = useUserData();

    const [feedback, setFeedback] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);

    const [searchParams] = useSearchParams();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Perform validation here if needed
        if (user_data) {
            UnsubscribeAPI(
                searchParams.get('userId'),
                {
                    feedback
                },
                (success: any) => {
                    if (success.statusCode !== 200) return;
                    console.log('Unsubscribed');
                },
                (failure: any) => console.log('failure', failure)
            );
        }
        // Submit logic, e.g., send feedback to server
        setSubmitted(true);
    };

    return (
        <>
            {!searchParams.get('userId') || !searchParams.get('emailId') ? (
                <Navigate to="/" />
            ) : searchParams.get('emailId') && searchParams.get('userId') ? (
                <div className="flex flex-col bg-gray-100 font-roboto w-screen items-center">
                    {submitted !== true ? (
                        <div className="flex flex-col space-y-8 items-center justify-center md:w-1/2 w-full px-6 py-12 bg-white rounded-lg shadow-md text-center">
                            <img
                                className="w-32"
                                src="https://parntune-assets.b-cdn.net/Parentune%20Logos-02%202.png"
                                alt="Logo"
                            />
                            <div
                                style={{
                                    backgroundImage:
                                        'url(https://ayush-parentune-test.b-cdn.net/Group%201.png)',
                                    backgroundSize: '100% 100%'
                                }}
                                className="flex items-center justify-center font-medium bg-100% text-2xl h-44 md:h-52 w-full mx-2"
                            >
                                <h3>{searchParams.get('emailId')}</h3>
                            </div>
                            <h2 className="text-2xl font-medium">
                                Unsubscribe me from the mailing list
                            </h2>
                            <p className="">
                                Please do share your feedback. How can we do better 🤔
                            </p>
                            {submitted ? (
                                <p>Feedback submitted. Thank you!</p>
                            ) : (
                                <form
                                    className="flex flex-col items-center w-full"
                                    onSubmit={handleSubmit}
                                >
                                    <textarea
                                        className="w-full h-32 px-4 py-2 border border-gray-300 rounded-lg mb-4"
                                        placeholder="Feedback"
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                    ></textarea>
                                    <button
                                        type="submit"
                                        className="px-8 py-3 bg-[#377E8B] text-white rounded-full text-sm"
                                    >
                                        UNSUBSCRIBE
                                    </button>
                                </form>
                            )}
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center space-y-8 divide-y-2 items-center md:w-1/2 w-full h-screen px-6 py-12 bg-white rounded-lg shadow-md text-center">
                            <h3 className="text-2xl font-medium">It&#39;s Done</h3>
                            <div className="flex flex-col space-y-8 pt-8">
                                <h3 className="text-2xl font-medium">
                                    {searchParams.get('emailId')}
                                </h3>
                                <p>
                                    We have unsubscribed you from the mailing list. We wish you the
                                    best always & hope to find your support in our vision of
                                    supporting parents 🙏
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex justify-center items-center font-roboto h-screen">
                    Email doesn&#39;t exist for user or User id not found
                </div>
            )}
        </>
    );
};

export default Unsubscribe;
