import axios from 'axios';

export function getComments(
    params: {
        language: string;
        pageNumber: any;
        itemType: any;
        id: any;
        user_data: any;
        count: string;
    },
    onSuccess: (arg0: any) => void,
    onFailure: (arg0: any) => any
) {
    console.log('getComments');
    const { user_data } = params;

    console.log('count', params.count);

    axios({
        method: 'get',
        url: process.env.REACT_APP_CONTENT_SERVER + 'content/comment',
        params: {
            itemId: params.id,
            itemType: params.itemType,
            pageNumber: params.pageNumber,
            count: params.count
        },
        headers: {
            lang: params.language,
            userId: user_data?.userId?.toString() || '',
            instanceId: user_data?.instanceId?.toString() || '',
            authToken: user_data?.authToken || ''
        }
    })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => onFailure(error));
}
