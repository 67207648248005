import { SwiperSlide, Swiper } from 'swiper/react';
import { Mousewheel, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './gallery.css'; // Import the updated CSS file
import Overlay from '../../components/Overlay/Overlay';
import CrossIcon from '../../assets/icons/CrossIcon.svg';

const InnerGalary = (props: any) => {
    let images = props.images;
    return (
        <div className="h-[100%]">
            <Overlay Content={Content}>
                <div className="sh w-full border-b-2 flex justify-between px-3 py-4 items-center text-black bg-white ">
                    <img
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => props.setImageIndex(-1)}
                        src="https://img1.parentune.com/images/icons/CrossIcon.svg"
                    />
                    <div className="text-xl">
                        {props.imageIndex + 1}/{images.length}
                    </div>
                </div>
                <div className="slider-container relative bg-white ">
                    <div className="swiper-button-prev"></div>
                    <Swiper
                        onSlideChange={(e) =>
                            console.log('slide change', props.setImageIndex(e.activeIndex))
                        }
                        pagination={true}
                        effect="creative"
                        initialSlide={props.imageIndex}
                        mousewheel={{ forceToAxis: true }}
                        modules={[Pagination, Navigation, Mousewheel]}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next'
                        }}
                        className="flex justify-center md:mb-14 items-center"
                        slidesPerView={1}
                    >
                        {images.map((item: any, index: number) => (
                            <SwiperSlide className="mb-12" key={index}>
                                <div className=" w-full h-full flex items-center justify-center mx-auto">
                                    <img className="max-w-[900px] w-full" src={item.url} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-button-next "></div>
                </div>
            </Overlay>
        </div>
    );
};

export default InnerGalary;

// import { Content } from './T&C';
const Content: any = {
    width: '100%',
    // margin-left:"auto",
    // margin-right:"auto",
    height: '100%',
    color: 'white'
};
