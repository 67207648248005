import React, { useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon, MinusIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils/utils';

import './EventDetailsQuestions.css';

type EventDetailsQuestionsType = {
    quickLinks: { question: string; answer: string }[];
    contentTypeHtml?: boolean;
};
type QuestionComponentType = {
    Q?: any;
    answer?: string;
    title?: string;
    desc?: string;
    A?: any;
};
const QuestionComponent = (props: QuestionComponentType) => {
    // const [isOpen, setOpen] = useState(false);
    const [toggle, setToggle] = useState(true);

    const toggleHandler = () => {
        toggle === true ? setToggle(false) : setToggle(true);
    };

    return (
        <>
            {/* <div className="edqcm">
                <div className="edqcmq">{props.title}</div>
                <div className="edqcmo">
                    <span onClick={() => setOpen((s) => !s)}> {isOpen ? '-' : '+'} </span>
                </div>
            </div>
            {isOpen && <div className="edqcmc" dangerouslySetInnerHTML={{ __html: props.desc }} />} */}
            <Disclosure as="div" key={props?.Q?.en} className="bg-white py-3 px-5 rounded-xl">
                {({ open }) => (
                    <>
                        <div className={` ${toggle ? 'border-b-2' : ' '} border-dotted pb-4`}>
                            <Disclosure.Button
                                className="text-left w-full flex justify-between items-start "
                                onClick={toggleHandler}
                            >
                                <h1 className="md:font-medium font-normal text-[16px] text-[#323A41]  font-roboto leading-[26px]">
                                    {props?.Q?.en}

                                    {/* <div>{JSON.stringify(props, null, 4)} props </div> */}
                                </h1>
                                <span className="ml-6 h-7 flex items-center">
                                    {/* <ChevronDownIcon
                                        className={classNames(
                                            open ? '-rotate-180' : 'rotate-0',
                                            'h-6 w-6 transform'
                                        )}
                                        aria-hidden="true"
                                    /> */}
                                    <PlusIcon
                                        fill="black"
                                        className={` z-10 w-5 ${toggle ? 'block' : 'hidden'}`}
                                    />
                                    <MinusIcon
                                        className={`  z-10 w-5 ${toggle ? 'hidden' : 'block'}`}
                                    />
                                </span>
                            </Disclosure.Button>
                        </div>
                        <Transition
                            enter="transition duration-200 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-100 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel
                                as="dd"
                                className="mt-2 pr-12 border-b-2 border-dotted pb-3"
                            >
                                <p className="md:font-medium font-normal md:text-base text-sm text-gray-500 answer-acordian">
                                    {props.A?.en
                                        ? // props?.contentTypeHtml ? (
                                          //     <div
                                          //         dangerouslySetInnerHTML={{
                                          //             __html: props.answer
                                          //         }}></div>
                                          // ) : (
                                          //     props.A?.en
                                          // )
                                          props.A?.en
                                        : null}
                                </p>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </>
    );
};

function EventDetailsQuestions2(props: EventDetailsQuestionsType) {
    return (
        // <div>
        //     {props.quickLinks.map((d, i) => (
        //         <div key={i}>
        //             <QuestionComponent {...d} />
        //             <hr style={{ borderTop: 'dotted 1px', margin: '0 20px' }} />
        //         </div>
        //     ))}

        // </div>
        <>
            {props?.quickLinks?.length < 1 ? (
                <></>
            ) : (
                <dl className="mt-6 flex flex-col gap-2 mb-4">
                    {props.quickLinks.map((faq, i) => (
                        <QuestionComponent key={i} {...faq} />
                    ))}
                </dl>
            )}
        </>
    );
}

export default EventDetailsQuestions2;
