import clockicon from '../../assets/booksummary/clock.svg';
import calendaricon from '../../assets/booksummary/calendar.svg';

export const DateSummary = (props: any) => {
    const { state } = props;
    const detail = state;
    // Create a Date object
    const dateObj = new Date(detail?.start_date);

    // Extract date in DD-MM-YYYY format
    const date = `${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()}`;

    // Extract time in 12-hour format with AM/PM
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const time = `${hours}:${minutes} ${ampm}`;

    return (
        <>
            {
                <div className="mx-[20px] my-[18px] flex items-center justify-between">
                    <div className="flex items-center gap-[12px]">
                        <img src={calendaricon} alt="calendar" />
                        <h2 className="text-[16px] text-[#242F35] font-normal">{date}</h2>
                    </div>
                    <span className="border border-[#E5E5E5] w-0 h-[30px]"></span>
                    <div className="flex items-center gap-[12px]">
                        <img src={clockicon} alt="clock" />
                        <h2 className="text-[16px] text-[#242F35] font-normal">{time}</h2>
                    </div>
                </div>
            }
        </>
    );
};
