import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import { useUserData } from '../../../utils/hooks/useUserData';
import FormProgress from '../../FormProgress/FormProgress';
import TextInputForm from '../../TextInputForm/TextInputForm';
import EditProfileParentSelect from '../../Profile/EditProfileParentSelect/EditProfileParentSelect';
import { GetUserProfile } from '../../../api/OnBoarding/GetUserProfile';
import { addUserdata, clearUserData } from '../../../store/slices/userDataSlice';
import { UpdateProfile } from '../../../api/OnBoarding/UpdateProfile';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import { navMap } from './utils';
import { useNavigate } from 'react-router-dom';
import { setForEditProfile, updatePathName } from '../../../store/slices/onboardingSlice';
import { useOnboardingData } from '../../../utils/hooks/useOnboardingData';
import pushEventToGtm from '../../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';

const SetupProfile = (props: any) => {
    const { nav, setNav, selectedData, setSelectedData, isLoading, setIsLoading } = props;
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const dispatch = useAppDispatch();
    const [pathname, setpathname] = useState(localStorage.getItem('pathname') ?? '');
    const { userData: user_data } = useUserData();
    const [name, setName] = useState<string>('');
    const navigate = useNavigate();

    const { isForEditProfile } = useOnboardingData();

    const handleSubmitNameUpdate = async () => {
        setIsLoading(true);
        const handleSuccessUpdateProfile = (s: any) => {
            if (s.statusCode !== 200) return;
            const { userId, instanceId, authToken } = user_data;

            GetUserProfile(
                async (data: any) => {
                    dispatch(addUserdata({ ...data?.data }));
                    setIsLoading(false);
                    // @ts-ignore
                    setNav(navMap[selectedData.id]);
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );
        };

        const handleFailureUpdateProfile = (e: any) => {
            setIsLoading(false);
            console.log('Error: ', e);
        };

        UpdateProfile(handleSuccessUpdateProfile, handleFailureUpdateProfile, user_data, 1, {
            name
        });

        pushEventToGtm('setup-profile-user-onboard', {
            'setup-profile': 'setup-profile-next',
            url: window.location.pathname,
            newGuestUid
        });
    };

    useEffect(() => {
        localStorage.removeItem('onboarding_data');
        localStorage.setItem('welcomePage', 'false');
        // console.log('hiii', selectedData);
        if (selectedData) window?.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, [selectedData]);

    useEffect(() => {
        if (isForEditProfile) {
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 1000);
        }
    }, []);

    return (
        <main>
            {/* {JSON.stringify(selectedData)} */}
            <div
                style={{ height: '90vh' }}
                className="animate-fade-in-left max-w-4xl mx-auto pt-3 flex flex-col px-4"
            >
                <FormProgress
                    onBack={() => {
                        if (isForEditProfile) {
                            navigate('/parent-blog');
                        }
                        console.log(pathname);
                        dispatch(updatePathName('/'));
                        // dispatch(clearUserData());
                        document.cookie = '_ppmgi=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                        navigate('/parent-blog');
                    }}
                    dots={4}
                    progress={1}
                />
                <h1 className="font-proxima font-bold text-xl text-[#242F35] mb-8">
                    {isForEditProfile ? 'Select Options' : 'Set up your profile'}
                </h1>

                <section className="my-2 flex flex-col gap-2">
                    {!isForEditProfile && (
                        <TextInputForm
                            PreValue={user_data?.name}
                            optionLabel="Remove"
                            optionAction={() => console.log('open action')}
                            setter={(e: any) => {
                                setName(e.name);
                            }}
                            value={'name'}
                            keys={1}
                            heading="Full Name"
                            placeholder="Enter full Name"
                            errorMessage="Enter valid name"
                            maxLen={20}
                            ForName
                        />
                    )}
                    <p className="font-roboto font-normal text-base text-[#242F35] mt-2">
                        Choose one of the options
                    </p>
                    <EditProfileParentSelect setSelectedData={setSelectedData} nav={nav} />
                </section>

                <div className="flex-1 flex flex-col justify-end pb-3 mt-12">
                    <ButtonPrimary
                        size="large"
                        isLoading={isLoading}
                        text="Next"
                        onClick={() => handleSubmitNameUpdate()}
                        disabled={
                            isForEditProfile ? !selectedData : name.length < 3 || !selectedData
                        }
                    />
                </div>
            </div>
        </main>
    );
};

export default SetupProfile;
