export function SendIcon(props: any) {
    const { color } = props;

    return (
        <svg
            width={30}
            height={27}
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.213 10.412L4.01.227a2.85 2.85 0 00-3.007.492 2.947 2.947 0 00-.91 2.947l2.154 8.568h10.548c.486 0 .88.399.88.891a.885.885 0 01-.88.891H2.246L.09 22.584a2.947 2.947 0 00.911 2.947 2.85 2.85 0 003.007.492l24.204-10.185C29.315 15.375 30 14.335 30 13.125c0-1.21-.685-2.25-1.787-2.713z"
                fill={color}
            />
        </svg>
    );
}
