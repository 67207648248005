import Content from '../Content/Content';
import MetaTag from '../../pages/BabyName/MetaTag';
import { Constants } from '../Constants/Constants';

export const changeMetaTag = (pageCount: number, pageCountOnScroll: any) => {
    let path = location.pathname;
    const isInArray = (el: string) => path && path.split('/').includes(el);
    let canonicalLink = 'https://www.parentune.com' + window?.location?.pathname;
    //console.log('canonicalLink', canonicalLink)
    let metaTagView: any = (
        <MetaTag
            pageCountOnScroll={pageCountOnScroll}
            title={Content.SEO.BABY_NAMES.HOME.BABY_NAME_TITLE}
            metaTitle={Content.SEO.BABY_NAMES.HOME.META_TAG.TITLE}
            metaTwitterTitle={Content.SEO.BABY_NAMES.HOME.META_TAG.TWITTER_TITLE}
            keywords={Content.SEO.BABY_NAMES.HOME.META_TAG.KEYWORDS}
            description={Content.SEO.BABY_NAMES.HOME.META_TAG.DESCRIPTION}
            canonicalLink={canonicalLink}
            alAndroidUrl={Content.SEO.BABY_NAMES.HOME.AL_ANDROID_URL}
            appleItuneApp={Content.SEO.BABY_NAMES.HOME.APPLE_ITUNES_APP}
            count={pageCount}
            metaKeywords={Content.SEO.BABY_NAMES.HOME.META_TAG.KEYWORDS}
            metaOgTitle={Content.SEO.BABY_NAMES.HOME.META_TAG.OG_TITLE}
            metaTwitterDescription={Content.SEO.BABY_NAMES.HOME.META_TAG.TWITTER_DESCRIPTION}
            metaDescription={Content.SEO.BABY_NAMES.HOME.META_TAG.DESCRIPTION}
            metaOgDescription={Content.SEO.BABY_NAMES.HOME.META_TAG.OG_DESCRIPTION}
            metaAlAndroidUrl={Content.SEO.BABY_NAMES.HOME.AL_ANDROID_URL}
            metaAppleItuneApp={Content.SEO.BABY_NAMES.HOME.APPLE_ITUNES_APP}
        />
    );
    const elements = ['hi', 'en', 'mr', 'ta', 'te'];
    const isIArrayNew = elements.some(isInArray);
    let pathList = path.split('/');

    if (isIArrayNew) {
        if (pathList?.length === 4) {
            if (pathList[3] === 'boy') {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.BOY.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.BOY.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.BOY.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.BOY.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.BOY.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.BOY.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.BOY.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.BOY.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.BOY.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.BOY.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.BOY.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.BOY.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[3] === 'girl') {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.GIRL.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.GIRL.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.GIRL.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.GIRL.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.GIRL.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.GIRL.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.GIRL.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.GIRL.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.GIRL.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.GIRL.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.GIRL.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[3].includes(Constants.WITH_LETTER.STARTING_LETTER)) {
                let lastFilter = pathList[3];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                        keywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        char={lastFilter}
                    />
                );
            } else if (pathList[3] === Constants.RELIGION.HINDU) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HINDU.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HINDU.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.HINDU.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.HINDU.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HINDU.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HINDU.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HINDU.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HINDU.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HINDU.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HINDU.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.HINDU.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.HINDU.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HINDU.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.HINDU.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[3] === Constants.RELIGION.MUSLIM) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.MUSLIM.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.MUSLIM.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.MUSLIM.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[3] === Constants.RELIGION.SIKH) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.SIKH.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.SIKH.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.SIKH.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.SIKH.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.SIKH.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.SIKH.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.SIKH.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.SIKH.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.SIKH.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.SIKH.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.SIKH.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.SIKH.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.SIKH.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.SIKH.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[3] === Constants.RELIGION.CRISTION) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.CHRISTION.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.CHRISTION.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.CHRISTION.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.CHRISTION.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.CHRISTION.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[3].includes(Constants.RELIGION_LETTER.HINDU_NAMES)) {
                let lastFilter = pathList[3];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HINDU.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        char={lastFilter}
                    />
                );
            } else if (pathList[3].includes(Constants.RELIGION_LETTER.MUSLIM_NAMES)) {
                let lastFilter = pathList[3];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        char={lastFilter}
                    />
                );
            } else if (pathList[3].includes(Constants.RELIGION_LETTER.CHRISTIAN_NAMES)) {
                let lastFilter = pathList[3];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.KEYWORDS}
                        description={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG
                                .TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.AL_ANDROID_URL
                        }
                        metaAppleItuneApp={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        char={lastFilter}
                    />
                );
            } else if (pathList[3].includes(Constants.RELIGION_LETTER.SIKH_NAMES)) {
                let lastFilter = pathList[3];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.SIKH.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.APPLE_ITUNES_APP}
                        char={lastFilter}
                    />
                );
            }
        } else if (pathList?.length === 5) {
            if (pathList[3] === Constants.GENDER.BOY) {
                if (pathList[4].includes('starting-with')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                            keywords={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.KEYWORDS}
                            description={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.AL_ANDROID_URL}
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                }
            } else if (pathList[3] === Constants.GENDER.GIRL) {
                if (pathList[4].includes('starting-with')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                            keywords={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.KEYWORDS}
                            description={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                }
            } else if (pathList[3].includes(Constants.WITH_LETTER.STARTING_LETTER)) {
                let lastFilter = pathList[3];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                        keywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        char={lastFilter}
                    />
                );
            } else if (pathList[3] === Constants.RELIGION.HINDU) {
                if (pathList[4].includes('boy-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4].includes('girl-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.HINDU.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.HINDU.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.HINDU.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.HINDU.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.HINDU.APPLE_ITUNES_APP}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.HINDU.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                        />
                    );
                }
            } else if (pathList[3] === Constants.RELIGION.MUSLIM) {
                if (pathList[4].includes('boy-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4].includes('girl-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.TITLE
                            }
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG
                                    .TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.MUSLIM.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.MUSLIM.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.MUSLIM.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.MUSLIM.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.MUSLIM.APPLE_ITUNES_APP}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.MUSLIM.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.MUSLIM.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.MUSLIM.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.MUSLIM.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.MUSLIM.APPLE_ITUNES_APP}
                        />
                    );
                }
            } else if (pathList[3] === Constants.RELIGION.CRISTION) {
                if (pathList[4].includes('boy-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.TITLE
                            }
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4].includes('girl-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.BABY_NAME_TITLE
                            }
                            metaTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.TITLE
                            }
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.AL_ANDROID_URL}
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.AL_ANDROID_URL}
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.BABY_NAME_TITLE
                            }
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.AL_ANDROID_URL}
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.AL_ANDROID_URL}
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                        />
                    );
                }
            } else if (pathList[3] === Constants.RELIGION.SIKH) {
                if (pathList[4].includes('boy-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.KEYWORDS}
                            description={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4].includes('girl-names-starting')) {
                    let lastFilter = pathList[4];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.SIKH.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.SIKH.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.SIKH.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.SIKH.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.SIKH.APPLE_ITUNES_APP}
                        />
                    );
                } else if (pathList[4] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.HINDU.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                        />
                    );
                }
            }
        }
    } else {
        if (pathList?.length === 3) {
            if (pathList[2] === 'boy') {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.BOY.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.BOY.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.BOY.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.BOY.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.BOY.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.BOY.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.BOY.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.BOY.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.BOY.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.BOY.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.BOY.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.BOY.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[2] === 'girl') {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.GIRL.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.GIRL.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.GIRL.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.GIRL.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.GIRL.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.GIRL.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.GIRL.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.GIRL.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.GIRL.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.GIRL.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.GIRL.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[2].includes(Constants.WITH_LETTER.STARTING_LETTER)) {
                let lastFilter = pathList[2];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                        keywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        char={lastFilter}
                    />
                );
            } else if (pathList[2] === Constants.RELIGION.HINDU) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HINDU.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HINDU.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.HINDU.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.HINDU.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HINDU.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HINDU.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HINDU.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HINDU.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HINDU.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HINDU.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.HINDU.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.HINDU.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HINDU.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.HINDU.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[2] === Constants.RELIGION.MUSLIM) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.MUSLIM.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.MUSLIM.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.MUSLIM.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[2] === Constants.RELIGION.SIKH) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.SIKH.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.SIKH.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.SIKH.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.SIKH.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.SIKH.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.SIKH.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.SIKH.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.SIKH.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.SIKH.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.SIKH.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.SIKH.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.SIKH.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.SIKH.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.SIKH.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[2] === Constants.RELIGION.CRISTION) {
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.CHRISTION.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.TITLE}
                        metaTwitterTitle={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.TWITTER_TITLE}
                        keywords={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.CHRISTION.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.CHRISTION.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.DESCRIPTION}
                        metaOgDescription={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.OG_DESCRIPTION}
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.CHRISTION.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.CHRISTION.APPLE_ITUNES_APP}
                    />
                );
            } else if (pathList[2].includes(Constants.RELIGION_LETTER.HINDU_NAMES)) {
                let lastFilter = pathList[2];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HINDU.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={
                            Content.SEO.BABY_NAMES.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        char={lastFilter}
                    />
                );
            } else if (pathList[2].includes(Constants.RELIGION_LETTER.MUSLIM_NAMES)) {
                let lastFilter = pathList[2];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.MUSLIM.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={
                            Content.SEO.BABY_NAMES.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        char={lastFilter}
                    />
                );
            } else if (pathList[2].includes(Constants.RELIGION_LETTER.CHRISTIAN_NAMES)) {
                let lastFilter = pathList[2];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.KEYWORDS}
                        description={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.CHRISTION.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG
                                .TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.AL_ANDROID_URL
                        }
                        metaAppleItuneApp={
                            Content.SEO.BABY_NAMES.CHRISTION.WITH_LETTER.APPLE_ITUNES_APP
                        }
                        char={lastFilter}
                    />
                );
            } else if (pathList[2].includes(Constants.RELIGION_LETTER.SIKH_NAMES)) {
                let lastFilter = pathList[2];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        keywords={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.SIKH.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.SIKH.WITH_LETTER.APPLE_ITUNES_APP}
                        char={lastFilter}
                    />
                );
            }
        } else if (pathList?.length === 4) {
            if (pathList[2] === Constants.GENDER.BOY) {
                if (pathList[3].includes('starting-with')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                            keywords={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.KEYWORDS}
                            description={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.AL_ANDROID_URL}
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                }
            } else if (pathList[2] === Constants.GENDER.GIRL) {
                if (pathList[3].includes('starting-with')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                            keywords={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.KEYWORDS}
                            description={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                }
            } else if (pathList[2].includes(Constants.WITH_LETTER.STARTING_LETTER)) {
                let lastFilter = pathList[2];
                metaTagView = (
                    <MetaTag
                        pageCountOnScroll={pageCountOnScroll}
                        title={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.BABY_NAME_TITLE}
                        metaTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TITLE}
                        metaTwitterTitle={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_TITLE
                        }
                        // ampHtml={Content.SEO.BABY_NAMES.BOY.WITH_LETTER.BABY_NAME_AMP_HTML}
                        keywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        description={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION}
                        canonicalLink={canonicalLink}
                        alAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        appleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        count={pageCount}
                        metaKeywords={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.KEYWORDS}
                        metaOgTitle={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_TITLE}
                        metaTwitterDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.TWITTER_DESCRIPTION
                        }
                        metaDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.DESCRIPTION
                        }
                        metaOgDescription={
                            Content.SEO.BABY_NAMES.HOME.WITH_LETTER.META_TAG.OG_DESCRIPTION
                        }
                        metaAlAndroidUrl={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.AL_ANDROID_URL}
                        metaAppleItuneApp={Content.SEO.BABY_NAMES.HOME.WITH_LETTER.APPLE_ITUNES_APP}
                        char={lastFilter}
                    />
                );
            } else if (pathList[2] === Constants.RELIGION.HINDU) {
                if (pathList[3].includes('boy-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3].includes('girl-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.HINDU.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.HINDU.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.HINDU.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.HINDU.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.HINDU.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.HINDU.APPLE_ITUNES_APP}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.HINDU.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                        />
                    );
                }
            } else if (pathList[2] === Constants.RELIGION.MUSLIM) {
                if (pathList[3].includes('boy-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3].includes('girl-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.TITLE
                            }
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG
                                    .TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.MUSLIM.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.MUSLIM.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.MUSLIM.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.MUSLIM.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.MUSLIM.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.MUSLIM.APPLE_ITUNES_APP}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.MUSLIM.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.MUSLIM.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.MUSLIM.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.MUSLIM.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.MUSLIM.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.MUSLIM.APPLE_ITUNES_APP}
                        />
                    );
                }
            } else if (pathList[2] === Constants.RELIGION.CRISTION) {
                if (pathList[3].includes('boy-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.TITLE
                            }
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3].includes('girl-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.BABY_NAME_TITLE
                            }
                            metaTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.TITLE
                            }
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.META_TAG
                                    .OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.AL_ANDROID_URL}
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.CHRISTIAN.AL_ANDROID_URL}
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.BABY_NAME_TITLE
                            }
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.AL_ANDROID_URL}
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.AL_ANDROID_URL}
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.CHRISTIAN.WITH_LETTER.APPLE_ITUNES_APP
                            }
                        />
                    );
                }
            } else if (pathList[2] === Constants.RELIGION.SIKH) {
                if (pathList[3].includes('boy-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.KEYWORDS}
                            description={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.BOY.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3].includes('girl-names-starting')) {
                    let lastFilter = pathList[3];
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.TWITTER_TITLE
                            }
                            keywords={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            description={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            canonicalLink={canonicalLink}
                            alAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            appleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            count={pageCount}
                            metaKeywords={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.KEYWORDS
                            }
                            metaOgTitle={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.OG_TITLE
                            }
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG
                                    .TWITTER_DESCRIPTION
                            }
                            metaDescription={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.DESCRIPTION
                            }
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.AL_ANDROID_URL
                            }
                            metaAppleItuneApp={
                                Content.SEO.BABY_NAMES.GIRL.SIKH.WITH_LETTER.APPLE_ITUNES_APP
                            }
                            char={lastFilter}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.BOY) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.BOY.SIKH.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.BOY.SIKH.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.BOY.SIKH.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.BOY.SIKH.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.BOY.SIKH.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.BOY.SIKH.APPLE_ITUNES_APP}
                        />
                    );
                } else if (pathList[3] === Constants.GENDER_2.GIRL) {
                    metaTagView = (
                        <MetaTag
                            pageCountOnScroll={pageCountOnScroll}
                            title={Content.SEO.BABY_NAMES.GIRL.HINDU.BABY_NAME_TITLE}
                            metaTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TITLE}
                            metaTwitterTitle={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_TITLE
                            }
                            keywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            description={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            canonicalLink={canonicalLink}
                            alAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            appleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                            count={pageCount}
                            metaKeywords={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.KEYWORDS}
                            metaOgTitle={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_TITLE}
                            metaTwitterDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.TWITTER_DESCRIPTION
                            }
                            metaDescription={Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.DESCRIPTION}
                            metaOgDescription={
                                Content.SEO.BABY_NAMES.GIRL.HINDU.META_TAG.OG_DESCRIPTION
                            }
                            metaAlAndroidUrl={Content.SEO.BABY_NAMES.GIRL.HINDU.AL_ANDROID_URL}
                            metaAppleItuneApp={Content.SEO.BABY_NAMES.GIRL.HINDU.APPLE_ITUNES_APP}
                        />
                    );
                }
            }
        }
    }
    return metaTagView;
};
