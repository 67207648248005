import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Babyname, BabynameAPI, BabynameUpdate } from '../types/babyname';
import { useUserData } from '../utils/hooks/useUserData';
import { capitalize } from '../utils/utils';
// import { useTranslation } from 'react-i18next';
import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();
export const useBabyNames = (
    pageNumber: number,
    gender: string,
    lang: string,
    religion: string,
    Name: string,
    startLength: number,
    endLength: number,
    fixedLength: number,
    size: number
) => {
    // const { i18n } = useTranslation();

    const { userData: user_data } = useUserData();

    return useQuery(
        [
            'babyname',
            pageNumber,
            gender,
            lang,
            religion,
            Name,
            startLength,
            endLength,
            fixedLength,
            size
        ],
        () => {
            return axios
                .get(process.env.REACT_APP_BABYNAME + 'list-baby-names', {
                    headers: {
                        userId: user_data?.userId?.toString() || '',
                        instanceId: user_data?.instanceId?.toString() || '',
                        authToken: user_data?.authToken || '',
                        lang: SelectedLanguage || 'en'
                    },
                    params: {
                        name: Name ? Name : '',
                        origin: religion ? capitalize(religion) : '',
                        gender: gender ? gender : '',
                        page: pageNumber,
                        count: size,
                        action: 'getName',
                        startLength: startLength,
                        endLength: endLength,
                        fixedLength: fixedLength
                    }
                })
                .then((res) => {
                    return res.data as BabynameAPI;
                })
                .catch((error) => console.log(error));
        },
        {
            staleTime: 10 * 60 * 60 * 1000
        }
    );
};

export const useEditBabyName = () => {
    // const { i18n } = useTranslation();
    const { userData: user_data } = useUserData();
    const queryClient = useQueryClient();
    return useMutation(({ id, action }: { id: string; action: string }) => {
        return axios
            .post(
                process.env.REACT_APP_BABYNAME + 'baby-names',
                {
                    userId: user_data && user_data.userId,
                    instanceId: user_data && user_data.instanceId,
                    authToken: user_data && user_data.authToken,
                    action: action,
                    nameId: id
                },

                {
                    headers: {
                        lang: SelectedLanguage,
                        userId: user_data?.userId?.toString() || '',
                        instanceId: user_data?.instanceId?.toString() || '',
                        authToken: user_data?.authToken || ''
                    }
                }
            )
            .then((res) => {
                return res.data as BabynameUpdate;
            })
            .catch((error) => console.log(error));
    });
};

export const TopBabynames = (tag_Name: string, country?: string) => {
    // const { i18n } = useTranslation();
    const { userData: user_data } = useUserData();

    return useQuery(
        ['babyname', tag_Name, country],
        () => {
            const headers = {
                userId: user_data?.userId?.toString() || '',
                instanceId: user_data?.instanceId?.toString() || '',
                authToken: user_data?.authToken || '',
                lang: SelectedLanguage || 'en'
            };

            // @ts-ignore
            if (country) headers['country'] = country;

            return axios
                .get(process.env.REACT_APP_BABYNAME + 'baby-names-category', {
                    headers,
                    params: {
                        tagName: tag_Name
                    }
                })
                .then((res) => {
                    return res.data as BabynameAPI;
                })
                .catch((error) => console.log(error));
        },
        {
            staleTime: 10 * 60 * 60 * 1000
        }
    );
};
