import clock from '../../assets/landing/clock.svg';
import askdoctor from '../../assets/landing/doctor.svg';
import talk from '../../assets/landing/talk.svg';
import earnbadge from '../../assets/landing/earnbadge.svg';

import WhoCourse from './WhoCourseFor';
import { useState } from 'react';

const WhatGetYou = (props: any) => {
    const { what_you_get_data } = props;

    const [data, setData] = useState<any>(what_you_get_data.data);

    // const data = [
    //     {
    //         id: 1,
    //         text: '42 Min',
    //         icon: clock
    //     },
    //     {
    //         id: 2,
    //         text: 'Ask Question',
    //         icon: askdoctor
    //     },
    //     {
    //         id: 3,
    //         text: 'Parents Talk',
    //         icon: talk
    //     },
    //     {
    //         id: 4,
    //         text: 'Earn Badges',
    //         icon: earnbadge
    //     }
    // ];

    return (
        <>
            <div className="mt-[41px] mx-5">
                <h2 className="text-center text-[#262626] text-[21px] font-medium">
                    What do you get?
                </h2>
                <div className="mt-[40px] grid grid-cols-2 gap-[45px]">
                    {data.map((item: any, index: number) => (
                        <div
                            key={index}
                            className="flex flex-col gap-[19px] justify-center items-center"
                        >
                            <img
                                src={item.image}
                                alt={item.text}
                                className="w-[44px] h-[44px] aspect-square object-contain"
                            />
                            <h2 className="text-center">{item.text}</h2>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default WhatGetYou;
