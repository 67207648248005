// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import PreSchoolLogin from './PreSchoolLogin';
import ButtonSecondry1 from '../V2Components/ButtonSecondry/index1';
import Overlay from '../Overlay/Overlay';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router';
import { SendEvents } from '../../utils/utils';
import { useCookies } from 'react-cookie';
import { useUserData } from '../../utils/hooks/useUserData';

const pathMap = {
    pediatrician: 'Find the Best pediatrician near you',
    'child-psychologist': 'Find the Best child psychologist near you',
    preschool: 'Find the Best Preschool near you',
    school: 'Find the Best school near you',
    livechats: 'Make informed decisions. Get guided by Doctors & Child Specialists.'
};
const LoginPopUp = (props: any) => {
    const { isLoggedIn, path } = props;
    const { userData: user_data } = useUserData();
    const [loginPopup, setLoginPopup] = useState(false);
    const search = window.location.search;
    const [redirect, setRedirect] = useState('');
    const isInApp = sessionStorage.getItem('userOnMobile') === 'true';
    const params = new URLSearchParams(search);
    const pageNumber = params.get('page');
    const utm_source = params.get('utm_source');
    const navigate = useNavigate();
    const location = useLocation();

    console.log('data:', path);

    const [guestCookies] = useCookies();
    const GuestUid = guestCookies['ptgc'];

    useEffect(() => {
        if (isLoggedIn && user_data?.profileComplete < 40) {
            setLoginPopup(true);
        } else {
            if (isLoggedIn) return;
        }
        setRedirect(Buffer.from(window.location.href).toString('base64'));
        utm_source == 'facebook' && setLoginPopup(true);
        // const handleScroll = () => window.scrollY > 800 && setLoginPopup(true);
        // window.addEventListener('scroll', handleScroll);
        // return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoggedIn, pageNumber]);

    return (
        <div>
            {/* {loginPopup && !isInApp && <PreSchoolLogin />} */}
            <Overlay>
                <div className="h-full  cursor-pointer fixed top-0 backdrop-blur-sm w-screen flex justify-center items-center ">
                    <div className="bg-white text-black  m-[10px] rounded-md overflow-hidden md:max-w-[40%]">
                        {['pediatrician', 'child-psychologist', 'hospital'].includes(path) ? (
                            <div className="w-full h-full">
                                <img
                                    className="w-full h-full"
                                    src="https://img1.parentune.com/images/creative_03.jpg"
                                    alt="doctor-img"
                                />
                            </div>
                        ) : path.includes('livechats') ? (
                            <div className="w-full h-full">
                                <img
                                    className="w-full h-full"
                                    src="https://img1.parentune.com/images/creative_03.jpg"
                                    alt="doctor-img"
                                />
                            </div>
                        ) : (
                            <div className="w-full h-full">
                                <img
                                    className="w-[360px] sm:w-full h-full"
                                    src="https://img1.parentune.com/images/creative_01.jpg"
                                    alt="doctor-img"
                                />
                            </div>
                        )}

                        <p className="text-lg font-roboto mt-2">
                            {t(pathMap[Array.isArray(path) ? 'livechats' : path])}
                        </p>
                        <ButtonSecondry1
                            onClick={() => {
                                SendEvents(
                                    GuestUid,
                                    'impression',
                                    'btn-cliked',
                                    'signup-popup-btn'
                                );
                                navigate('/register', {
                                    replace: false,
                                    state: { from: location }
                                });
                            }}
                            className="mt-[20px] mb-[30px] w-[92%] text-[16px] bg-[#367e8a] text-white border-none"
                            text={
                                user_data?.profileComplete < 40
                                    ? 'Complete your profile'
                                    : 'SIGN IN'
                            }
                            customStyle={{ padding: '10px 5px' }}
                        />
                    </div>
                </div>
            </Overlay>
        </div>
    );
};

export default LoginPopUp;
