import styles from './styles.module.css';
import styles2 from './styles2.module.css';

export function BlogPageSkeletal() {
    return (
        <div className="grid md:grid-cols-2 gap-8 grid-cols-1 px-2 py-10">
            {[1, 2].map((_, index) => (
                <div
                    key={index}
                    className="flex border  w-[360px] rounded-[10px] shadow-md flex-col items-center mx-auto animate-pulse"
                >
                    <div className="w-full bg-gray-300 h-40 border-gray-400 rounded-t-[10px]" />
                    <div className="w-full bg-gray-300 h-40 opacity-60 rounded-b-[10px]">
                        <div className="h-4 w-[90%] mt-4 ml-4   bg-gray-500"> </div>
                        <div className="h-4 w-[30%] mt-2 ml-4  bg-gray-500"> </div>
                        <div className="flex justify-start items-center   ml-4 mt-6  space-x-2">
                            <div className="h-8 w-8 rounded-full bg-gray-500"></div>
                            <div className="h-4 w-[50%] bg-gray-500"></div>
                        </div>
                        <div className="flex justify-start items-center  ml-4 mt-2  space-x-8">
                            <div className="h-4 w-24  bg-gray-500"></div>
                            <div className="h-4 w-24 bg-gray-500"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export function AskTopQuestion() {
    return (
        <div className="mx-4 md:mx-56 ">
            <div className=" ml-3 ">
                <div className="flex items-start mt-5 space-x-10 ">
                    <div className="h-12 w-12 bg-gray-400 animate-pulse rounded-full"></div>
                    <div className="h-6 ml-3 w-[30%] bg-gray-400  animate-pulse mt-2"></div>
                </div>
                <section className="ml-[90px] mt-5">
                    <div className="h-5 w-[85%] bg-gray-400 mt-7 animate-pulse"></div>

                    <div className="h-5 w-[65%]  bg-gray-400 mt-1 animate-pulse"></div>
                </section>
                <section className="ml-[90px] mt-5">
                    <div className="h-5 w-[75%] bg-gray-400 mt-7 animate-pulse"></div>
                    <div className="h-5 w-[85%]  bg-gray-400 mt-1 animate-pulse"></div>
                    <div className="h-5 w-[45%]  bg-gray-400 mt-1 animate-pulse"></div>
                </section>
                <section className="ml-[90px] mt-5  flex justify-end">
                    <div
                        className="h-8 rounded-full w-28
                         bg-gray-400 mt-7 animate-pulse"
                    ></div>
                </section>
            </div>
        </div>
    );
}

export function LiveSkeletal() {
    return (
        <div className="">
            <div className="md:grid md:grid-cols-2  gap-8 grid-cols-1  px:2 sm:px-16 ">
                {Array(window.innerWidth < 720 ? 2 : 4)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            key={index}
                            className="flex  w-[320px] border rounded-md shadow-md p-10 flex-col mx-auto animate-pulse mt-6"
                        >
                            <div className="flex gap-4">
                                <div className="bg-gray-300 flex-shrink-0 rounded-md h-32 w-28">
                                    {' '}
                                </div>
                                <div className="flex flex-col w-full justify-between">
                                    <div className="bg-gray-300 rounded-md h-12 w-full"></div>
                                    <div className="bg-gray-300 rounded-md h-12 w-full"></div>
                                </div>
                            </div>
                            <div className="flex mt-10 gap-4">
                                <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                            </div>

                            <div className="animate-pulse w-2/4 mt-5 ml-auto  px-4 bg-gray-300 h-10 rounded-full border-gray-400" />
                        </div>
                    ))}
            </div>
        </div>
    );
}

export function QuestionSkeletal() {
    return (
        <div className="grid md:grid-cols-2 gap-8 grid-cols-1 px-0 sm:px-10 py-10">
            {[1, 2, 3, 4].map((_, index) => (
                <div
                    key={index}
                    className="flex w-5/6 border rounded-md shadow-md gap-8 p-6 flex-col mx-auto animate-pulse"
                >
                    <div className="flex gap-4">
                        <div className="bg-gray-300 flex-shrink-0 rounded-full h-10 w-10"> </div>
                        <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                    </div>
                    <div className="flex gap-4">
                        <div className="bg-gray-300 rounded-full flex-shrink-0 h-10 w-10"> </div>
                        <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                    </div>
                    <div className="animate-pulse w-full px-4 bg-gray-300 h-10 rounded-full border-gray-400" />
                </div>
            ))}
        </div>
    );
}

export function TalkSkeletal() {
    return (
        <div className="grid md:grid-cols-2 gap-8 grid-cols-1 py-8">
            {Array(window.innerWidth < 720 ? 2 : 4)
                .fill(0)
                .map((_, index: any) => (
                    <div
                        key={index}
                        className="flex w-full border rounded-md shadow-md gap-4  flex-col mx-auto animate-pulse "
                    >
                        <div className="flex gap-4">
                            <div className="bg-gray-300 flex-shrink-0 rounded-full h-10 w-10">
                                {' '}
                            </div>
                            <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                        </div>
                        <div className="bg-gray-300 rounded-md h-14 w-full"></div>
                        <div className="bg-gray-300 rounded-md h-0.5 w-full"></div>
                        <div className="flex gap-4">
                            <div className="bg-gray-300 flex-shrink-0 rounded-full h-10 w-10">
                                {' '}
                            </div>
                            <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export function UpcominEventSkeletal() {
    return (
        <div className="pb-12 px-8">
            <div className="md:grid md:grid-cols-2 hidden  gap-8 grid-cols-1 px-2 ">
                {[1, 2].map((_, index) => (
                    <div
                        key={index}
                        className="flex w-[70%]  border rounded-md shadow-md gap-4 p-6 py-10 flex-col mx-auto animate-pulse"
                    >
                        <div className="flex gap-4">
                            <div className="bg-gray-300 flex-shrink-0 rounded-md h-32 w-28"> </div>
                            <div className="flex flex-col w-full justify-between">
                                <div className="bg-gray-300 rounded-md h-12 w-full"></div>
                                <div className="bg-gray-300 rounded-md h-12 w-full"></div>
                            </div>
                        </div>
                        <div className="flex mt-10 gap-4">
                            <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                        </div>

                        <div className="animate-pulse w-2/4 mt-5 ml-auto  px-4 bg-gray-300 h-10 rounded-full border-gray-400" />
                    </div>
                ))}
            </div>
            <div className="grid md:hidden pb-12 md:grid-cols-2 gap-8 grid-cols-1 px-2 ">
                {[1].map((_, index) => (
                    <div
                        key={index}
                        className="flex w-full  border rounded-md shadow-md gap-4 p-6 py-10 flex-col mx-auto animate-pulse"
                    >
                        <div className="flex gap-4">
                            <div className="bg-gray-300 flex-shrink-0 rounded-md h-28 w-28"> </div>
                            <div className="flex flex-col w-full justify-between">
                                <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                                <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                            </div>
                        </div>
                        <div className="flex mt-10 gap-4">
                            <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                        </div>

                        <div className="animate-pulse w-2/4 mt-5 ml-auto  px-4 bg-gray-300 h-10 rounded-full border-gray-400" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export function FilterSkeletal() {
    return (
        <div className="flex flex-nowrap gap-2 mt-4 px-4 scrollbar-hide overflow-auto">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((_, index) => (
                <div
                    className="animate-pulse flex-shrink-0 w-40 bg-gray-300 h-10 rounded-full border-gray-400"
                    key={index}
                />
            ))}
        </div>
    );
}

export function AskExpertSkelatal(props: any) {
    return (
        <div className="grid md:grid-cols-2 gap-8 grid-cols-1 py-8">
            {Array(window.innerWidth < 720 ? 2 : 4)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={index}
                        className="flex w-[350px] border rounded-md shadow-md gap-8 p-6 flex-col mx-auto animate-pulse"
                    >
                        <div className="flex gap-4">
                            <div className="bg-gray-300 flex-shrink-0 rounded-full h-10 w-10">
                                {' '}
                            </div>
                            <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                        </div>
                        <div className="flex gap-4">
                            <div className="bg-gray-300 rounded-full flex-shrink-0 h-10 w-10">
                                {' '}
                            </div>
                            <div className="bg-gray-300 rounded-md h-10 w-full"></div>
                        </div>
                        <div className="animate-pulse w-full px-4 bg-gray-300 h-10 rounded-full border-gray-400" />
                    </div>
                ))}
        </div>
    );
}

export function BabyNameBottom1Skelatal() {
    return (
        <div className="grid md:grid-cols-2 gap-8 grid-cols-1 px-2 py-10">
            {Array(8)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={`babyNameBottomSkeletal${index}`}
                        className="flex gap-2 flex-row animate-pulse"
                    >
                        <div className="h-6 flex-shrink-0 w-6 rounded-full bg-gray-400"></div>
                        <div className="flex gap-2 flex-col w-full">
                            <div className="h-4 w-1/3 bg-gray-400"></div>
                            <div className="h-4 full bg-gray-400"></div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export function TrendingBoyNameSkeletal() {
    return (
        <div className="grid sm:grid-cols-4 gap-8 grid-cols-2 px-2 py-10">
            {Array(10)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={`trendingBoySkeletal${index}`}
                        className="flex gap-2 flex-row animate-pulse"
                    >
                        <div className="h-6 flex-shrink-0 w-6 rounded-full bg-gray-400"></div>
                        <div className="flex gap-2 flex-col w-full">
                            {/* <div className="h-4 w-1/3 bg-gray-400"></div> */}
                            <div className="h-4 full bg-gray-400"></div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export function TrendingGirlNameSkeletal() {
    return (
        <div className="grid sm:grid-cols-4 gap-8 grid-cols-2 px-2 py-10">
            {Array(10)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={`trendingGirlSkeletal${index}`}
                        className="flex gap-2 flex-row animate-pulse"
                    >
                        <div className="h-6 flex-shrink-0 w-6 rounded-full bg-gray-400"></div>
                        <div className="flex gap-2 flex-col w-full">
                            {/* <div className="h-4 w-1/3 bg-gray-400"></div> */}
                            <div className="h-4 full bg-gray-400"></div>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export function StreakLoader() {
    return (
        <>
            <div className={`${styles.f2} animate-pulse`}>
                <div className={`${styles.f2_1} animate-pulse`}>
                    <div className={`${styles.f2_1_1} animate-pulse`}>
                        <div className={`${styles.f2_1_1_1} animate-pulse flex`}>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <p className={`${styles.f2_1_1_1__1_text2} animate-pulse`}></p>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <p className={`${styles.f2_1_1_1__1_text2} animate-pulse`}></p>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.f2_1_1} animate-pulse`}>
                        <div className={`${styles.f2_1_1_1_new} animate-pulse flex`}>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <p className={`${styles.f2_1_1_1__1_text2} animate-pulse`}></p>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <p className={`${styles.f2_1_1_1__1_text2} animate-pulse`}></p>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                            <div className={`${styles.f2_1_1_1__2_cross} animate-pulse`}>
                                <div className={`${styles.f2_1_1_1__2_1} animate-pulse`}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export const DailyFocusCardLoader = () => {
    return (
        <a className={`${styles2.card_w} bg-gray-300 animate-pulse`}>
            <div className={`${styles2.f1} rounded bg-gray-300 animate-pulse`}>
                <div style={{ top: '10px' }}>
                    <div className="animate-pulse"></div>
                </div>
            </div>
            <div className={`${styles2.f2} animate-pulse mt-2`}>
                <div className={`${styles2.f2_1} rounded-full bg-gray-300 animate-pulse`}>
                    <div
                        className={`${styles2.f2_1_text} rounded-full bg-gray-300 animate-pulse`}
                    ></div>
                    <div
                        className={`${styles2.f2_1_text} rounded-full bg-gray-300 animate-pulse`}
                    ></div>
                    <div className={`${styles2.f2_1_1} rounded-full bg-gray-300 animate-pulse`}>
                        <div
                            className={`${styles2.f2_1_1_clock} rounded-full bg-gray-300 animate-pulse`}
                        ></div>
                        <div
                            className={`${styles2.f2_1_1_text} rounded-full bg-gray-300 animate-pulse`}
                        ></div>
                    </div>
                </div>
                <div className={`${styles2.f2_text} rounded-full bg-gray-300 animate-pulse`}></div>
            </div>
        </a>
    );
};

export const ProfileSkeleton = () => (
    <div className="flex w-full gap-4 flex-col animate-pulse">
        <div className="flex w-full justify-around">
            <div className="h-28 w-28 flex-shrink-0 bg-gray-300 rounded-full"></div>
            <div className="flex flex-col w-1/3 justify-between">
                <div className="h-8 w-full bg-gray-300"></div>
                <div className="h-6 w-full bg-gray-300"></div>
                <div className="h-4 w-full bg-gray-300"></div>
                <div className="h-4 w-full bg-gray-300"></div>
            </div>
        </div>
        <div className="h-1 w-full bg-gray-300"></div>
        <div className="h-10 w-full rounded-full bg-gray-300"></div>
        <div className="h-1 w-full bg-gray-300"></div>
        <div className="flex w-full gap-3 flex-col">
            <div className="h-6 w-1/3 bg-gray-300"></div>
            <div className="h-2 w-2/3 bg-gray-300"></div>
            <div className="flex justify-around mt-2">
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
            </div>
        </div>
        {/* <div className="h-1 w-full bg-gray-300"></div>
        <div className="flex w-full gap-3 flex-col">
            <div className="h-6 w-1/3 bg-gray-300"></div>
            <div className="h-2 w-2/3 bg-gray-300"></div>
            <div className="flex justify-around mt-2">
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
            </div>
        </div>
        <div className="h-1 w-full bg-gray-300"></div>
        <div className="flex w-full gap-3 flex-col">
            <div className="h-6 w-1/3 bg-gray-300"></div>
            <div className="h-2 w-2/3 bg-gray-300"></div>
            <div className="flex justify-around mt-2">
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                    <div className="h-2 w-12 bg-gray-300"></div>
                </div>
            </div>
        </div>
        <div className="h-1 w-full bg-gray-300"></div>
        <div className="flex w-full gap-3 flex-col">
            <div className="h-6 w-1/3 bg-gray-300"></div>
            <div className="h-44 w-full bg-gray-300"></div>
            <div className="h-44 w-full bg-gray-300"></div>
            <div className="h-10 w-full bg-gray-300 rounded-full mt-2"></div>
        </div> */}
    </div>
);
