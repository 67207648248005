import './RelatedEvents.css';
import ListingFunction from '../../utils/ListingFunction';
import { useRelatedData } from '../../api/related';
import LoadingLayout from '../V2Components/LoadingLayout';
import { useTranslation } from 'react-i18next';
import {
    BlogPageSkeletal,
    LiveSkeletal,
    QuestionSkeletal,
    TalkSkeletal
} from '../Loader/Others/Skeletal';
import React, { useEffect, useState, useRef } from 'react';

type RelatedEventsType = {
    eventData?: any;
    itemId?: number | undefined | string;
    id?: number | undefined | string;
    title?: string;
    primaryAgeGroup?: string;
    offset?: {
        support?: string | number;
        shares?: string;
    };
    expertDetails?: {
        name?: string;
        specialization?: string;
    }[];
    quickLinks?: never[];
    embedDetails?: {
        video?: string;
        iframe?: string;
    };
    target: string;
};

function RelatedEvents(props: RelatedEventsType) {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [relatedData, setRelatedData] = useState<any[]>([]);
    const contentEndRef = useRef<HTMLDivElement>(null);
    const { data: relatedList } = useRelatedData(
        props.itemId ? props.itemId.toString() : '',
        props.target,
        page
    );

    useEffect(() => {
        console.log(relatedList);
        if (loading && relatedList !== undefined && relatedList !== null) {
            setRelatedData((prevData) => [...prevData, ...relatedList]);
            setHasMore(relatedList.length > 0);
            setLoading(false);
        }
    }, [loading, relatedList]);

    const handleScroll = () => {
        const scrollContainer = contentEndRef.current;
        if (
            scrollContainer &&
            scrollContainer.scrollHeight - scrollContainer.scrollTop ===
                scrollContainer.clientHeight
        ) {
            setPage((prevPage) => prevPage + 1);
            setLoading(true);
        }
    };

    useEffect(() => {
        const scrollContainer = contentEndRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading, hasMore]);

    useEffect(() => {
        setPage(1);
        setLoading(true);
        setRelatedData([]); // Reset related data when the component mounts or when props change
    }, [props.itemId, props.target]);

    return (
        <div
            className="my-4 pl-2 border-slate-300 overflow-y-scroll scrollbar-hide "
            id="parent-container"
            ref={contentEndRef}
        >
            <div className="p-4">
                <h3 className="related-events-title">
                    {props.target === 'blog' && t('Related Blogs & Vlogs')}
                    {props.target === 'live_event' && t('Related Workshops')}
                    {props.target === 'question' && t('Related Questions')}
                    {props.target === 'talk' && t('Related Talks')}
                </h3>
                <LoadingLayout
                    child={<>{ListingFunction(relatedData)}</>}
                    isLoading={loading}
                    skelton={getSkeletonComponent(props.target)}
                />
            </div>
        </div>
    );
}

const getSkeletonComponent = (target: string) => {
    switch (target) {
        case 'blog':
            return <BlogPageSkeletal />;
        case 'live_event':
            return <LiveSkeletal />;
        case 'question':
            return <QuestionSkeletal />;
        case 'talk':
            return <TalkSkeletal />;
        default:
            return null;
    }
};

export default React.memo(RelatedEvents);
