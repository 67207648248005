import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { useUserData } from '../../utils/hooks/useUserData';
import pushEventToGtm from '../../utils/pushEventToGtm';
import SetupProfile from '../../components/Onboarding/SetupProfile/SetupProfile';
import NewlyMarried from '../../components/Onboarding/NewlyMarried/NewlyMarried';
import DueDatePage from '../../components/Onboarding/DueDatePage/DueDatePage';
import HaveChild from '../../components/Onboarding/HaveChild/HaveChild';
import PlanningForPregnancy from '../../components/Onboarding/PlanningForPregnancy/PlanningForPregnancy';
import KeenToAdopt from '../../components/Onboarding/KeenToAdopt/KeenToAdopt';
import ChooseAvatar from '../../components/Onboarding/ChooseAvatar/ChooseAvatar';
import ProfileSummary from '../../components/Onboarding/ProfileSummary/ProfileSummary';
import HowToUseParentune from '../../components/Onboarding/HowToUseParentune/HowToUseParentune';
import IdentifyYourSelf from '../../components/Onboarding/IdentifyYourSelf/IdentifyYourSelf';
import {
    UpdateOnboardingSteps,
    UpdatePreviousStep,
    defaultOnboardingSteps
} from '../../store/slices/onboardingSlice';
import ChooseLanguage from '../../components/Onboarding/ChooseLangugage/ChooseLanguage';
import { useOnboardingData } from '../../utils/hooks/useOnboardingData';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { addUserdata } from '../../store/slices/userDataSlice';
import InterestsPage from '../InterestsPage/InterestsPage';

function OnboardingPage(props: any) {
    const { lastpath } = props;

    const location = useLocation();
    // @ts-ignore
    const { state } = location || {};
    const dispatch = useAppDispatch();
    pushEventToGtm('page_view', { pt_page: 'ParentTypeInputPage' });
    const { isForEditProfile } = useOnboardingData();
    const { userData: user_data } = useUserData();

    // @ts-ignore
    const [nav, setNav] = useState<string>(localStorage.getItem('redirectNav') ?? 'SetupProfile');
    console.log(nav);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedData, setSelectedData] = useState<any>(null);

    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log('isForEditProfile', isForEditProfile);
    //     if (!isForEditProfile) {
    //         props?.setSelectParentType(false);
    //     }
    // }, [JSON.stringify(isForEditProfile)]);

    useEffect(() => {
        console.table({ nav });
        if (nav === 'ChooseAvatar' && isForEditProfile) {
            GetUserProfile(
                async (data: any) => {
                    dispatch(addUserdata({ ...data?.data }));
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );
            return navigate(`/user/profile/${user_data?.id}`);
            // return navigate(`/edit-profile/`);
        }

        if (nav === 'SetupProfile') {
            dispatch(defaultOnboardingSteps());
        } else {
            dispatch(UpdatePreviousStep(nav));
            dispatch(UpdateOnboardingSteps(nav));
        }
    }, [nav]);

    const navigateToStep = (step: string) => {
        // Push state for each step to prevent back navigation
        window.history.pushState(null, '', window.location.pathname);
        setNav(step); // Update the state to show the corresponding component
    };

    switch (nav) {
        case 'ChooseaLanguage':
            return (
                <ChooseLanguage
                    nav={nav}
                    setNav={setNav}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );

        case 'SetupProfile':
            return (
                <SetupProfile
                    nav={nav}
                    setNav={setNav}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );
        case 'HaveChild':
            return (
                <HaveChild
                    nav={nav}
                    setNav={navigateToStep}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );
        case 'DueDatePage':
            return (
                <DueDatePage
                    nav={nav}
                    setNav={navigateToStep}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );
        case 'PlanningForPregnancy':
            return (
                <PlanningForPregnancy
                    nav={nav}
                    setNav={navigateToStep}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );
        case 'NewlyMarried':
            return (
                <NewlyMarried
                    nav={nav}
                    setNav={navigateToStep}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );
        case 'KeenToAdopt':
            return (
                <KeenToAdopt
                    nav={nav}
                    setNav={navigateToStep}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );
        case 'ChooseAvatar':
            return <ChooseAvatar setNav={navigateToStep} />;
        case 'ChooseInterests':
            return <InterestsPage setNav={navigateToStep} />;
        case 'ProfileSummary':
            return <ProfileSummary setNav={navigateToStep} lastpath={lastpath} />;
        case 'HowToUseParentune':
            return <HowToUseParentune />;
        case 'IdentifyYourSelf':
            return (
                <IdentifyYourSelf
                    nav={nav}
                    setNav={navigateToStep}
                    isLoading={isLoading}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setIsLoading={setIsLoading}
                />
            );

        default:
            break;
    }

    return <></>;
}

export default OnboardingPage;
