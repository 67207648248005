import axios from 'axios';
import { useQuery } from 'react-query';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
// import i18n from '../../i18n/config';
const SelectedLanguage = Geti18nLang();

export const CallAPifunction = async (user_data: any) => {
    const API: any = process.env.REACT_APP_ASKEXPERTS_SERVER + 'question/attributes';

    const res = await axios.get(API, {
        headers: {
            lang: SelectedLanguage || 'en',
            userId: user_data ? user_data.userId : null,
            authToken: user_data ? user_data.authToken : null,
            mode: 'question/attributes'
        },
        params: {}
    });
    return res.data;
};

const Attributes = (user_data: any) => {
    return useQuery('QuestionAsked', () => CallAPifunction(user_data));
};

export default Attributes;
