import AdHM from '../components/adComponent/AdHM';
import DailyFocusGuestWidget from '../components/DailyFocusGuest/DailyFocusGuestWidget';
import DailyFocusGuest from '../components/DailyFocusGuest/DailyFocusGuestWidget';
import { lazyWithRetry } from '../utils/LazyWithRetry';

const NoEvents = lazyWithRetry(() => import('../components/NoEvents/NoEvents'));
const BlogPostCard = lazyWithRetry(() => import('../components/BlogPostCard/BlogPostCard'));
const LiveEventCard = lazyWithRetry(() => import('../components/Live/LiveEventCard'));
const BecomeBlogger = lazyWithRetry(() => import('../components/BecomeBlogger/BecomeBlogger'));
const AskExpertComponent = lazyWithRetry(
    () => import('../components/AskExpertComponent/AskExpertComponent')
);
const ParentuneCard = lazyWithRetry(() => import('../components/ParentuneCard/ParentuneCard'));
const OurExperts = lazyWithRetry(() => import('../components/OurExperts/OurExperts'));
const ExpertQandA = lazyWithRetry(
    () => import('../components/AskExpertModule/ExpertQandA/ExpertQandA')
);
const TalkComponent = lazyWithRetry(
    () => import('../components/TalksModule/TalkComponent/TalkComponent')
);

const AdComponent = lazyWithRetry(() => import('../components/adComponent/AdComponent'));
const DfpCompnent = lazyWithRetry(() => import('../components/adComponent/DfpCompnent'));

const PurposeCard = lazyWithRetry(() => import('../components/PurposeCard/PurposeCard'));
const ProductServicesCarousel = lazyWithRetry(
    () => import('../components/ImageCarousal/ProductServicesCarousel')
);

// @ts-ignore
const LiveChatAdComponent = lazyWithRetry(
    () => import('../components/adComponent/LiveChatAdComponent')
);

const AddVideoComponent = lazyWithRetry(
    () => import('../components/adComponent/AddVideoComponent')
);
//import ImageCarouselForBabyNames from '../components/ImageCarousal/ImageCarouselForBabyNames';

export default function ListingFunction(
    eventsList: any,
    propsObj?: any,
    mode?: any,
    showPurposeCard?: boolean
) {
    let liveEventState = 2;
    // console.log(eventsList, propsObj, mode);
    if (eventsList === undefined || eventsList?.length === 0) {
        return <NoEvents />;
    }
    const ad_frequency = process.env.REACT_APP_AD_FREQUENCY_ON_LISTING
        ? parseInt(process.env.REACT_APP_AD_FREQUENCY_ON_LISTING)
        : 4;

    // console.log(ad_frequency);
    let addRef: HTMLDivElement | null = null;

    const blogView = (event: any, i: any) => {
        const idxForAd = i + 1;
        // console.log('idxForAd', ad_frequency, idxForAd);

        // if (
        //     localStorage.getItem('UserGender') === 'female' &&
        //     localStorage.getItem('UserGenderFemale')
        // ) {
        //     if (idxForAd === 8 || idxForAd === 32 || idxForAd === 64 || idxForAd === 128) {
        //         return <AdHM />;
        //     }
        // }

        if (idxForAd === 8) {
            return <DailyFocusGuestWidget />;
        } else if (idxForAd % 41 === 0) {
            return <DailyFocusGuestWidget />;
        } else if (idxForAd % ad_frequency === 0) {
            return (
                <AdComponent
                    slot_id={propsObj?.pageNumber}
                    pageInfo="blog_list"
                    pageNumber={propsObj?.pageNumber}
                />
            );
        } else if (idxForAd % 7 === 0) {
            return (
                <div>
                    <DfpCompnent />
                </div>
            );
        } else if (idxForAd === 6) {
            return <LiveChatAdComponent />;
        }
        // else if (idxForAd % 13 === 0) {
        //     return <AddVideoComponent />;
        // }
        else {
            // if (i == 2 && showPurposeCard)
            //     return (
            //         <div>
            //             <PurposeCard />
            //         </div>
            //     );
            if (i === 5) {
                return <ProductServicesCarousel pageType={'blogList'} />;
            } else {
                return (
                    <div key={i} className="flex items-center justify-center">
                        <BlogPostCard {...event} propsObj={propsObj} />
                    </div>
                );
            }
        }
    };
    const expertView = (event: any, i: any, count: number) => {
        // if (count % ad_frequency === 0) {

        if (count === 8) {
            return <DailyFocusGuestWidget />;
        } else if (count % 41 === 0) {
            return (
                <>
                    <DailyFocusGuestWidget />
                    {/* <AddVideoComponent /> */}
                </>
            );
        } else if (i == process.env.REACT_APP_EXPERT_BECOME_A_BLOGER_INDEX_ON_LISTING) {
            let slot_id = count / ad_frequency;
            return (
                <div key={i} className=" md:grid md:place-content-center">
                    <BecomeBlogger />
                </div>
            );
        } else if (i == process.env.REACT_APP_EXPERT_PRODUCT_CAROUSEL_INDEX_ON_LISTING) {
            return <DfpCompnent />;
        } else {
            if (i == 9 && showPurposeCard)
                return (
                    <div className="flex justify-center items-center">
                        <PurposeCard />
                    </div>
                );
            if (i == process.env.REACT_APP_EXPERT_OUR_EXPERT_INDEX_ON_LISTING) {
                // return '';
                return (
                    <div className="md:col-span-2 col-span-1">
                        <OurExperts />
                    </div>
                );
            } else {
                return (
                    <div key={i} className="flex items-center justify-center my-6">
                        <ExpertQandA B2CRenewalCardIndex={i === 2 ? true : false} {...event} />

                        <hr />
                    </div>
                );
            }
        }
    };

    const WorkShopView = (event: any, i: any, count: number) => {
        // console.log('count', event, count);

        // if (
        //     localStorage.getItem('UserGender') === 'female' &&
        //     localStorage.getItem('UserGenderFemale')
        // ) {
        //     if (count === 8 || count === 32 || count === 64 || count === 128) {
        //         return <AdHM />;
        //     }
        // }

        if (count === 8) {
            return <DailyFocusGuestWidget />;
        } else if (count % 41 === 0) {
            return (
                <>
                    {/* <AddVideoComponent /> */}
                    <DailyFocusGuestWidget />
                </>
            );
        } else if (count % ad_frequency === 0) {
            return (
                <>
                    <AdComponent
                        slot_id={0}
                        pageInfo="workshop_list"
                        pageNumber={propsObj?.pageNumber ?? 1}
                    />
                </>
            );
        } else if (count % 7 === 0) {
            return (
                <>
                    <DfpCompnent />
                </>
            );
        } else {
            if (i === 5) {
                return (
                    <>
                        <ProductServicesCarousel pageType={'blogList'} />
                    </>
                );
            } else {
                return (
                    <div key={i} className="flex items-center justify-center">
                        <LiveEventCard {...event} mode={mode} />
                    </div>
                );
            }
        }
    };

    const TalkView = (event: any, i: any, count: number) => {
        if (count === 8) {
            return <DailyFocusGuestWidget />;
        } else if (count % 41 === 0) {
            return <DailyFocusGuestWidget />;
        } else if (count % ad_frequency === 0) {
            return (
                <AdComponent slot_id={0} pageInfo="blog_list" pageNumber={propsObj?.pageNumber} />
            );
        } else if (i == 9 && showPurposeCard)
            return (
                <div className="my-4  flex justify-center items-center">
                    <PurposeCard />
                </div>
            );
        else if (count % 7 === 0) {
            return <DfpCompnent />;
        } else {
            if (i === 5) {
                return <ProductServicesCarousel pageType={'blogList'} />;
            } else {
                return (
                    <div key={i} className="flex items-center justify-center">
                        <TalkComponent {...event} mode={mode} />
                    </div>
                );
            }
        }
    };

    const renderEventStatus = (status: any) => {
        switch (status) {
            case 0:
                return 'Live Workshops';
            case 1:
                return 'Upcoming Workshops';
            default:
                return 'Recorded Workshops';
        }
    };

    const CustomStyleForTalk = window.location.pathname.includes('/parent-talk') ?? false;

    return (
        <div
            className={`grid md:grid-cols-2 md:justify-around grid-cols-1  md:px-10 ${
                CustomStyleForTalk ? 'md:gap-x-5' : ''
            }`}
        >
            {eventsList &&
                eventsList.map((event: any, i: number) => {
                    switch (event && event.type) {
                        case 'workshop':
                            return WorkShopView(event, i, i + 1);
                        // (
                        //     <>
                        //         <div key={i} className="flex items-end justify-center">
                        //             <LiveEventCard {...event} mode={mode} />
                        //         </div>
                        //     </>
                        // );
                        // }

                        case 'blog':
                            return blogView(event, i);
                        case 'question':
                            return expertView(event, i, i + 1);
                        // return (
                        //     <div key={i} className="flex items-center justify-center my-6">
                        //         <ExpertQandA {...event} />
                        //         <hr />
                        //     </div>
                        // );
                        case 'talk':
                            return TalkView(event, i, i + 1);
                        // return (
                        //     // <div className="grid md:grid-cols-3 gap-2 grid-cols-1">
                        //     <div key={i} className="flex items-center justify-center">
                        //         <TalkComponent {...event} />
                        //     </div>
                        //     // </div>
                        // );
                        case 'ad':
                            switch (event.adType) {
                                case 'become-blogger':
                                    return (
                                        // <div className="grid md:grid-cols-3 gap-2 grid-cols-1">
                                        <div key={i} className="md:col-span-3 col-span-1">
                                            <BecomeBlogger />
                                        </div>
                                        // </div>
                                    );
                                case 'gift':
                                    return '';
                                case 'ask-expert':
                                    return (
                                        // <div className="grid md:grid-cols-3 gap-2 grid-cols-1">
                                        <div key={i} className="md:col-span-3 col-span-1">
                                            <AskExpertComponent />
                                        </div>
                                        // </div>
                                    );
                                case 'parentune-card':
                                    return (
                                        // <div className="grid md:grid-cols-3 gap-2 grid-cols-1">
                                        <div key={i} className="md:col-span-3 col-span-1">
                                            <ParentuneCard />
                                        </div>
                                        // </div>
                                    );
                                case 'vip':
                                    return '';
                                case 'baby-names':
                                    return '';
                                case 'our-experts':
                                    return (
                                        // <div className="grid md:grid-cols-3 gap-2 grid-cols-1">
                                        <div key={i} className="md:col-span-3 col-span-1">
                                            <OurExperts {...event} />
                                        </div>
                                        // </div>
                                    );
                                case 'refer':
                                    return '';
                                default:
                                    return '';
                            }
                        default:
                            return (
                                <div key={i} className="flex items-center justify-center">
                                    <TalkComponent {...event} />
                                </div>
                            );
                    }
                })}
        </div>
    );
}
