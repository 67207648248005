import React, { useEffect, useState } from 'react';
import { useUserData } from '../../utils/hooks/useUserData';
import FetchMyOrders from '../../api/MyOrders/MyOrders';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import ArrowRight from '../../components/Icons/ArrowRight';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';

const MyOrderPage = () => {
    const { userData: user_data } = useUserData();
    const [ordersData, setOrdersData] = useState<any>([]);
    const [currency, setCurrency] = useState<string>('₹');
    const navigate = useNavigate();

    useEffect(() => {
        const getCurrency = async () => {
            const { currency_symbol } = await useFetchUserLocation();
            setCurrency(currency_symbol);
        };

        getCurrency();
    }, []);

    useEffect(() => {
        FetchMyOrders(
            user_data,
            (s: any) => {
                setOrdersData(s?.data);
            },
            (f: any) => console.log('faile', f)
        );
    }, []);

    function parseAndFormatDate(dateString: string) {
        // Parse the input date string
        const date = new Date(dateString);

        // Define options for formatting the date
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        };

        // Format the date using the specified options
        // @ts-ignore
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        // Adjust the format to match the required "Mon, 20 May 2024 13:28:44 GMT"
        const finalFormattedDate = formattedDate.replace(',', '').replace(' GMT', ' GMT');

        return finalFormattedDate;
    }

    const Card = ({ item }: { item: any }) => {
        let url = '/';

        if (item?.productType == 'Plus_Annual_Subscription') url = '/my-profile';
        else if (item?.productType == 'subscription_question') url = '/services/short-term-plan';

        return (
            <section onClick={() => navigate('/my-profile')}>
                <div className="flex flex-col px-5   w-full">
                    <div className="w-full text-sm font-bold text-gray-800">
                        {item?.productType == 'subscription_question' &&
                            `Short Term Plan on ${parseAndFormatDate(item?.cdate)} `}
                        {item?.productType == 'Plus_Annual_Subscription' &&
                            `Plus  Plan on ${parseAndFormatDate(item?.cdate)} `}
                    </div>
                    <div className="flex justify-between  mt-4 text-base font-medium">
                        <img
                            loading="lazy"
                            src={item?.productImage}
                            className="shrink-0 max-w-full border-[0.5px]  rounded-lg aspect-[1.02] border-[#D7D7D7] w-[123px]  "
                        />
                        <div className="flex flex-col self-start mt-1.5">
                            <div className="text-black">{item?.title}</div>
                            <div className="mt-2 font-bold tracking-normal text-gray-800 leading-[150%] flex justify-between items-center">
                                {currency || '₹'}
                                {item?.amount} <ArrowRight />
                            </div>
                            <div className="justify-center self-end px-4 py-2.5 mt-7 text-xs tracking-normal text-white bg-[#377E8B] rounded-3xl">
                                Order Summery
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
    return (
        <div className="site-spacing min-h-screen">
            <div className="">
                <Header
                    BottomShadow
                    // isDarkMode={user_data?.subscriptionInfo == 'plus'}
                    back
                    onBack={() => navigate(-1)}
                    headerText={'My orders'}
                    textClass={`font-proxima font-bold text-[21px`}
                />
            </div>

            {ordersData.length == 0 && (
                <div className="h-[90svh] w-full grid place-content-center text-xl slideUpFast font-roboto font-semibold">
                    You don&#39;t have any orders
                </div>
            )}

            {ordersData && ordersData.length > 0 && (
                <section className="flex flex-col h-full w-full px-5 space-y-8 mt-6">
                    {/* list */}

                    {ordersData.map((item: any, index: number) => (
                        <Card key={index} item={item} />
                    ))}
                </section>
            )}
            {/* <p className='font-bold'></p> */}
        </div>
    );
};

export default MyOrderPage;
