import { getBenefitFeature } from '../../components/Constants/Constants';

interface PlusBenefitType {
    title: string;
    description: string;
    icon: JSX.Element;
}
const PlusBenefit = () => {
    const content = getBenefitFeature('PLUS');

    return (
        <div className="linear-bg px-5 py-[24px]">
            <h2 className="text-[#323232] text-[18px] text-center font-medium">PLUS benefits</h2>
            <div className="flex flex-col">
                {content &&
                    content.length > 0 &&
                    content.map((item: PlusBenefitType, index: number) => (
                        <PlusBenefitItem {...item} key={index} />
                    ))}
            </div>
        </div>
    );
};

export default PlusBenefit;

const PlusBenefitItem = (props: PlusBenefitType) => {
    const { title, description, icon } = props;

    return (
        <div className="mt-[16px] flex gap-[10px] bg-[#FFFFFF] rounded-[10px] px-[16px] py-[20px]">
            <div className="h-[50px] w-[50px]  rounded-full bg-[#FFF3D5] flex justify-center items-center">
                {icon}
            </div>
            <div className="flex-[0.9] flex flex-col gap-[5px]">
                <h2 className="text-[#242F35] text-[14px] font-semibold">{title}</h2>
                <p className="text-[#787878] text-[12px] font-normal">{description}</p>
            </div>
        </div>
    );
};
