import { Fragment, useEffect, useState, Suspense, useCallback, useLayoutEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
    classNames,
    country_codes_africa,
    country_codes_africaWithCountry,
    DefaultUser,
    getHost,
    HideHeaderInTheseRoutes,
    isUserSubscribedPlus,
    isUserSubscribedPro,
    NEXTJSROUTE,
    SendEvents
} from '../../utils/utils';
import { useUserData } from '../../utils/hooks/useUserData';
import { useAppDispatch } from '../../store/store';
import md5 from 'md5';
import {
    clearUserData,
    updateUserData,
    updateConsent,
    setGuestUid,
    clearGuestUid,
    addUserdata
} from '../../store/slices/userDataSlice';
import DropDown from '../V2Components/DropDown';
import { BsGlobe2 } from 'react-icons/bs';
import { useCookies } from 'react-cookie';
import fetchToken from '../../api/Auth';
import SearchIcon from '../Icons/SearhIcon';
import { Helmet } from 'react-helmet';

// using this package for helmet as top one is depricated
//import { Helmet, HelmetProvider } from 'react-helmet-async';
import Filter from '../Filter/Filter';
import { addHomeData, setLoading } from '../../store/slices/homePageSlice';
import { useFilterData } from '../../utils/hooks/useFilterData';

import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import BellIcon from '../Icons/BellIcon';
import UserIcon from '../Icons/UserIcon';
import { getPincode } from '../../api/Pincode';
//import GuestCookieId from '../../utils/GuestCookieId';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
import Overlay from '../Overlay/Overlay';
import SamplingPopup from '../SamplingPopup/SamplingPopup';

import TermAndConditions from '../../pages/TermsAndConditions/TermAndConditions';
import { ConsentDataGet } from '../../api/Consent';
import GuestCookieId from '../../utils/GuestCookieId';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { updatePathName } from '../../store/slices/onboardingSlice';
import { useOnboardingData } from '../../utils/hooks/useOnboardingData';
import { useUtilsData } from '../../utils/hooks/useUtislData';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
const MobileMenu = lazyWithRetry(() => import('../MobileMenu/MobileMenu'));
// @ts-ignore
const LogoTextIcon = lazyWithRetry(() => import('../../assets/icons/LogoTextIcon'));
// import ParentuneDiwaliAnimated from '../../assets/icons/ParentuneDiwaliAnimated.gif';

let currLang: any;

function NavBar() {
    // we are calling this guestCookie component to add ptgc in cookies if it is not there in cookies
    //<GuestCookieId />;
    const [consentGiven, setConsentGiven] = useState(true);
    const location = useLocation();
    const [country, setcountry] = useState<any>(null);

    const [hideHader, sethideHader] = useState(false);

    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([
        '_ppmgi',
        'ptLoc',
        'new_user',
        'user_profile_created'
    ]);
    const dispatch = useAppDispatch();
    const { userData: user_data, consent, isLoggedIn, guestUid } = useUserData();
    const [showImmunifyMePage, setShowImmunifyMePage] = useState(false);
    const [showTandCPage, setShowTandCPage] = useState(false);
    const { pathName } = useOnboardingData();

    useEffect(() => {
        const val = window.innerWidth < 700 && window.location.pathname == '/immunifyMe';
        setShowImmunifyMePage(val ?? false);
        console.log('showImmunifyMePage', showImmunifyMePage, val);
    }, [window.location.pathname, window.innerWidth]);

    useEffect(() => {
        if (!isLoggedIn) {
            if (!guestUid) {
                //no guestUid
                if (guestCookies['ptgc']) {
                    // if ptgc exists in cookies
                    if (
                        guestCookies['ptgc'] != guestUid &&
                        '"' + guestCookies['ptgc'] + '"' != guestUid //if ptgc and guestUid are not equal
                    ) {
                        const dataNew = {
                            guestUid: guestCookies['ptgc']
                        };
                        dispatch(setGuestUid(dataNew)); // changeing guestUid to ptgc
                        // removeCookie('ptgc');
                    }
                } else {
                    // it meanes ptgc is not available so setting it , we have to make sure this remains consistent in php too
                    let randomId = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    randomId = md5(
                        [...Array(12)].reduce(
                            (a) => a + randomId[~~(Math.random() * randomId.length)],
                            ''
                        )
                    );
                    dispatch(setGuestUid(randomId)); //setting up guestuid
                    setguestCookie('ptgc', randomId); // saving ptgc in cookies
                }
            } else {
                // if guestuid is there
                if (guestCookies['ptgc']) {
                    //if ptgc is there in cookies
                    if (
                        guestCookies['ptgc'] != guestUid ||
                        '"' + guestCookies['ptgc'] + '"' != guestUid
                    ) {
                        // if ptgc and guestUid not equal
                        const dataNew = {
                            guestUid: guestCookies['ptgc']
                        };
                        dispatch(setGuestUid(dataNew)); // changing guestuid to ptgc
                    }
                } else {
                    // not ptgc
                    let randomId = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    randomId = md5(
                        [...Array(12)].reduce(
                            (a) => a + randomId[~~(Math.random() * randomId.length)],
                            ''
                        )
                    );
                    dispatch(setGuestUid(randomId)); // changing guestUid to make consistent with ptgc
                    setguestCookie('ptgc', randomId); // setting ptgc
                }
            }
        }
    });

    useEffect(() => {
        let cookie = cookies['_ppmgi'];

        const xhrFetchToken = () => {
            let params = {
                token: cookies ? cookies['_ppmgi'] : null,
                guestUid: newGuestUid
            };
            fetchToken(params, fetchTokenOnSuccess, fetchTokenOnFailure);
        };

        const fetchTokenOnSuccess = (data: any) => {
            let dataNew = {
                data: data,
                userData: user_data
            };
            console.log('fetchTokenOnSuccess', data);
            localStorage.setItem(cookies._ppmgi, JSON.stringify(data));
            dispatch(updateUserData(dataNew));
            dataNew.data.consentGiven === false && dispatch(updateConsent(dataNew));
        };

        const fetchTokenOnFailure = () => {
            console.log('');
        };

        if (cookie && !user_data) {
            xhrFetchToken();
        }
    }, [user_data]);

    useEffect(() => {
        const func = async () => {
            const { country } = await useFetchUserLocation();

            if (country_codes_africa.includes(country)) {
                console.log('countrycountry', country);
                console.log(
                    'country_codes_africaWithCountry',
                    country_codes_africaWithCountry['ZA']
                );
                // @ts-ignore
                setcountry(country_codes_africaWithCountry[country]);
                // @ts-ignore
                console.log('>>>>>>>', country_codes_africaWithCountry[country]);
            }
        };
        func();

        if (
            user_data &&
            !sessionStorage.getItem('consent') &&
            !sessionStorage.getItem('consentCalled')
        ) {
            ConsentDataGet(user_data, (e: any) => {
                if (e.data.consent === false) {
                    let dataNew = {
                        data: { consentGiven: e.data.consent },
                        userData: user_data
                    };
                    consentGiven !== e.data.consent && setConsentGiven(e.data.consent);
                    dispatch(updateConsent(dataNew));
                }
                sessionStorage.setItem('consentCalled', JSON.stringify({ consent: true }));
            });
        }
        let a: any = sessionStorage.getItem('consent');
        let b: any = localStorage.getItem('consent');
        !a && b && setConsentGiven(false);
    }, []);
    useEffect(() => {
        if (consent !== undefined) setConsentGiven(consent);
    }, [consent]);
    const { topicId, ageGroup } = useFilterData();
    const [guestCookies, setguestCookie, removeCookie] = useCookies();
    const { userSubscriptionSelectedStatus } = useUtilsData();

    const [language, setLanguage] = useState<{ value: string; label: string }>(languageOption[0]);
    const [clicked, setClicked] = useState<
        Array<{
            name: string;
            href: string;
        }>
    >([]);

    const [userAvatar, setUserAvatar] = useState(
        'https://img1.parentune.com/images/avatar/62_1457676535_batch.png'
    );

    const { t, i18n } = useTranslation();

    const [width, setWidth] = useState<number>(window.innerWidth);

    const onSuccessRequest = () => {
        setCookie('ptLoc', 'true', { path: '/', maxAge: 86400 * 365 });
    };
    const onFailureRequest = () => {
        console.log('Location Tracking failed');
    };
    const newGuestUid = guestCookies['ptgc'];
    const isLocationExist = guestCookies['ptLoc'];

    function getLatLanDetails() {
        if (isLocationExist) {
            return;
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (showPosition) {
                    if (showPosition.coords.latitude && showPosition.coords.longitude) {
                        // if (newGuestUid) {
                        //     const params: any = {
                        //         guestUid: newGuestUid,
                        //         latitude: showPosition.coords.latitude,
                        //         longitude: showPosition.coords.longitude
                        //     };
                        //     if (!isLocationExist) {
                        //         getPincode(params, onSuccessRequest, onFailureRequest);
                        //     }
                        // } else {
                        const params: any = {
                            user_data: user_data,
                            latitude: showPosition.coords.latitude,
                            longitude: showPosition.coords.longitude
                        };
                        if (!isLocationExist) {
                            getPincode(params, onSuccessRequest, onFailureRequest);
                        }
                        // }
                    }
                },
                function (positionError) {
                    setCookie('ptLoc', 'false', { path: '/', maxAge: 86400 });
                }
            );
        } else {
            return;
        }
    }
    useEffect(() => {
        if (isLoggedIn && !window.location.pathname.includes('/force-logout')) {
            // setTimeout(() => {
            //     getLatLanDetails(); commented this part as we are stopping collecting location information from user as the same we are now getting from ga4
            // }, 15000);

            GetUserProfile(
                async (data) => {
                    // console.log('Sucess', data);
                    dispatch(addUserdata({ ...data?.data }));
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );
        }
        let cookie = cookies['_ppmgi'];

        if (cookie && !isLoggedIn) {
            // xhrFetchToken();
        }

        let lng = location.pathname.slice(1, 3);
        let language = ['hi', 'en', 'ta', 'te', 'mr'].includes(lng);
        if (!language) {
            lng = 'en';
        }
        currLang = lng;
        i18n.changeLanguage(lng);
        localStorage.setItem('currentLang', lng);

        // xhrGetHomePageData();
        setLanguage(languageOption.find((item) => item.value === lng) ?? languageOption[0]);
        window.addEventListener('resize', handleWindowResize);

        // if (!isLoggedIn) {
        //     if (!guestUid) {
        //         if (guestCookies['ptgc']) {
        //             if (
        //                 guestCookies['ptgc'] != guestUid &&
        //                 '"' + guestCookies['ptgc'] + '"' != guestUid
        //             ) {
        //                 const dataNew = {
        //                     guestUid: guestCookies['ptgc']
        //                 };
        //                 dispatch(setGuestUid(dataNew));
        //                 removeCookie('ptgc');
        //             }
        //         } else {
        //             let randomId = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //             randomId = md5(
        //                 [...Array(12)].reduce(
        //                     (a) => a + randomId[~~(Math.random() * randomId.length)],
        //                     ''
        //                 )
        //             );
        //             dispatch(setGuestUid(randomId));
        //             setguestCookie('ptgc', randomId);
        //         }
        //     }
        // } else {
        //     if (!guestCookies['ptgc']) {
        //         return;
        //     } else {
        //         removeCookie('ptgc');
        //     }

        //     if (guestUid) {
        //         dispatch(clearGuestUid(''));
        //     }
        // }
    }, []);

    useEffect(() => {
        if (user_data) {
            if (user_data.avatar) {
                setUserAvatar(user_data.avatar);
            }
        }
    }, [user_data]);

    useEffect(() => {
        if (window.location.pathname == '/services/short-term-plan') setShowTandCPage(false);
        else if (window.location.pathname == '/workshop/short-term-plan') setShowTandCPage(false);
        else if (localStorage.getItem('ComingFromFreeWorkshop') !== null) setShowTandCPage(false);
        else if (localStorage.getItem('ComingFromFreeQuestion') !== null) setShowTandCPage(false);
        else setShowTandCPage(true);

        // console.log('on locaiton change in navbar');
        const path = window.location.pathname;

        const regex = /^\/livechats\/[^/]+\/\d+$/;
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-line
        const regex2 = /baby-names\/[^\/]+\/detail\//; // eslint-disable-line

        if (
            [
                '/register',
                '/how-to-use-parentune',
                '/services/short-term-plan',
                '/workshop/short-term-plan',
                '/expert-corner/questionsasked',
                '/PlanDetailpage',
                'my-profile'
            ].includes(path) ||
            regex.test(path) ||
            regex2.test(path)
        )
            sethideHader(true);
        else sethideHader(false);

        if (window.location.pathname.includes('baby-names/detail/')) sethideHader(true);
        if (window.location.pathname.includes('/choose-plan')) sethideHader(true);
        if (window.location.pathname.includes('/PlanDetailpage')) sethideHader(true);
        if (window.location.pathname.includes('/consult/expert')) sethideHader(true);
        if (window.location.pathname.includes('/astrologer/expert')) sethideHader(true);
        if (window.location.pathname.includes('/my-profile')) sethideHader(true);
        if (window.location.pathname.includes('/my-orders')) sethideHader(true);
        // if (/^\/parent-blog\//.test(window.location.pathname)) sethideHader(true);

        // if (window.location.pathname.includes('/services/')) sethideHader(true);
    }, [location]);

    useEffect(() => {
        const path = window.location.pathname;

        const regex = /^\/user\/edit-profile\/.*$/;
        if (regex.test(path)) {
            window.location.pathname = '/edit-profile';
        }

        dispatch(updatePathName(path));
    }, [location]);

    window.onpopstate = function () {
        const path = window.location.pathname;
        dispatch(updatePathName(path));
    };

    // const xhrGetHomePageData = () => {
    //     let params: any = {
    //         user_data: user_data,
    //         language: i18n.language ? i18n.language : 'en',
    //         agegroup_id: topicId,
    //         interest_id: ageGroup
    //     };
    //     dispatch(setLoading(true));
    //     getHomePageData(params, onSuccess, onFailure);
    // };

    // const onSuccess = (data: any) => {
    //     dispatch(addHomeData(data));
    //     dispatch(setLoading(false));
    // };

    // const onFailure = (error: any) => {
    //     console.log(error);
    // };

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    };

    const setSelected: any = useCallback((item: any) => {
        setLanguage(item);
        i18n.changeLanguage(item.value);

        if (languageOption.map((item) => item.value).includes(location.pathname.slice(1, 3))) {
            // if lang picked and lang in url is same do nothing
            if (item.value === location.pathname.slice(1, 3)) return;
            if (location.pathname.includes('parent-blog/')) {
                if (item.value === 'en') {
                    navigate('parent-blog');
                } else {
                    navigate(`/${item.value}/parent-blog`);
                }
            } else if (item.value === 'en') {
                navigate({
                    pathname: window.location.pathname.slice(3),
                    search: location.search
                });
            } else {
                navigate({
                    pathname: `/${item.value}${window.location.pathname.slice(3)}`,
                    search: location.search
                });
            }
        } else {
            // if no lang in url then do this
            if (location.pathname.includes('parent-blog/')) {
                navigate(item.value === 'en' ? 'parent-blog' : `/${item.value}/parent-blog`);
            } else {
                navigate({
                    pathname:
                        item.value === 'en'
                            ? window.location.pathname
                            : `/${item.value}${window.location.pathname}`,
                    search: location.search
                });
            }
        }
        window.location.reload();
    }, []);

    const navBarLinkView = () => {
        let view: JSX.Element[] = [];
        mobileLinks2.forEach((item, index) => {
            if (item.type === 6) {
                view.push(
                    <Menu
                        key={`mobileLinks2_${index}`}
                        as="div"
                        style={{}}
                        className="relative flex-shrink-0 border-transparent text- text-gray-500 hover:border-orange-500 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm pt-track-click"
                        data-ptname={`navbar_${item.label}`}
                    >
                        <div key={`mobileLinks2_2_${index}`}>
                            <Menu.Button
                                className={`rounded-full flex focus:outline-none font-medium ${styles.navlinkText}`}
                                key={`mobileLinks2_2_10_${index}`}
                            >
                                {t('Services' as keyof typeof t)}
                            </Menu.Button>
                        </div>
                        {ServiceListView()}
                    </Menu>
                );
            } else if (item.type === 2) {
                view.push(
                    <Menu
                        key={`mobileLinks2__${index}`}
                        as="div"
                        className="relative flex-shrink-0 border-transparent text-gray-500 hover:border-orange-500 max-w-[130px;] hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm pt-track-click"
                        data-ptname={`navbar_${item.label}`}
                    >
                        <div key={`mobileLinks2_2_${index}`}>
                            <Menu.Button
                                className={`bg-white rounded-full flex text-sm focus:outline-none font-medium ${styles.navlinkText}`}
                                key={`mobileLinks2_2_5_${index}`}
                            >
                                {t('Blogs & Vlogs' as keyof typeof t)}
                            </Menu.Button>
                        </div>
                        {BlogsVlogsView()}
                    </Menu>
                );
            } else if (item.type === 7) {
                view.push(
                    <Menu
                        key={`mobileLinks2__${index}`}
                        as="div"
                        className="relative flex-shrink-0 border-transparent text-gray-500 hover:border-orange-500 max-w-[130px;] hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm pt-track-click"
                        data-ptname={`navbar_${item.label}`}
                    >
                        <div key={`mobileLinks2_2_${index}`}>
                            <Menu.Button
                                className={`bg-white rounded-full flex text-sm focus:outline-none font-medium ${styles.navlinkText}`}
                                key={`mobileLinks2_2_5_${index}`}
                            >
                                {t('See More' as keyof typeof t)}
                            </Menu.Button>
                        </div>
                        {SeeMoreView()}
                    </Menu>
                );
            }
            // else if (item.type === 5) {
            //     view.push(
            //         <Link
            //             key={`${item.label}_a`}
            //             to={item.href}
            //             className={classNames(
            //                 location.pathname === item.href || location.pathname === `${item.href}`
            //                     ? 'border-orange-500 text-gray-900'
            //                     : 'border-transparent text-gray-500 hover:border-orange-500 hover:text-gray-700',
            //                 ` inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${styles.navlinkText}`
            //             )}
            //         >
            //             {t(item.label as keyof typeof t)}
            //         </Link>
            //     );
            // }
            else if ([1, 5, 6, 7].includes(item.type)) {
                // php routes
                view.push(
                    <a
                        style={{ maxWidth: '105px' }}
                        key={`mobileLinks2__${index}`}
                        // href={item.href}
                        href={i18n.language === 'en' ? item.href : `/${i18n.language}${item.href}`}
                        className={classNames(
                            location.pathname === item.href || location.pathname === `${item.href}`
                                ? 'border-orange-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover:border-orange-500 hover:text-gray-700',
                            ` inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${styles.navlinkText} pt-track-click`
                        )}
                        data-ptname={`navbar_${item.label}`}
                    >
                        {t(item.label as keyof typeof t)}
                    </a>
                );
            } else {
                view.push(
                    <a
                        style={{ maxWidth: '105px' }}
                        key={`mobileLinks2__${index}`}
                        href={i18n.language === 'en' ? item.href : `/${i18n.language}${item.href}`}
                        className={classNames(
                            location.pathname === item.href || location.pathname === `${item.href}`
                                ? 'border-orange-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover:border-orange-500 hover:text-gray-700',
                            ` inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${styles.navlinkText} pt-track-click`
                        )}
                        data-ptname={`navbar_${item.label}`}
                    >
                        {t(item.label as keyof typeof t)}
                    </a>
                );
            }
        });
        return view;
    };

    const ServiceListView = () => {
        let view: JSX.Element[] = [];
        let view2: JSX.Element[] = [];
        ServicesList.map((item, index) => {
            // @ts-ignore
            if ([20, 28].includes(item.type)) {
                view.push(
                    <Menu.Item key={`ServicesList_${index}`}>
                        {({ active }) => (
                            <Link
                                key={`ServicesList_2_${index}`}
                                onClick={() => {
                                    if (
                                        clicked.length > 0 &&
                                        clicked.find((data) => item.href === data.href)
                                    ) {
                                        return;
                                    }
                                    setClicked((prev) => {
                                        return [
                                            ...prev,
                                            {
                                                name: item.label,
                                                href: item.href
                                            }
                                        ];
                                    });
                                }}
                                to={
                                    i18n.language === 'en'
                                        ? item.href
                                        : `/${i18n.language}${item.href}`
                                }
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                            >
                                {t(item.label as keyof typeof t)}
                            </Link>
                        )}
                    </Menu.Item>
                );
            } else {
                view.push(
                    <Menu.Item key={`ServicesList_${index}`}>
                        {({ active }) => (
                            <a
                                key={`ServicesList2_${index}`}
                                onClick={() => {
                                    if (
                                        clicked.length > 0 &&
                                        clicked.find((data) => item.href === data.href)
                                    ) {
                                        return;
                                    }
                                    setClicked((prev) => {
                                        return [
                                            ...prev,
                                            {
                                                name: item.label,
                                                href: item.href
                                            }
                                        ];
                                    });
                                }}
                                href={`${item.href}${isOvulation(item)}`}
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                            >
                                {t(item.label as keyof typeof t)}
                            </a>
                        )}
                    </Menu.Item>
                );
            }
        });
        view2.push(
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                key={`view2_`}
            >
                <Menu.Items
                    key="service-menu"
                    className="origin-top-right z-10 absolute top-2 left-0 mt-[30px] w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    {view}
                </Menu.Items>
            </Transition>
        );
        return view2;
    };
    const SeeMoreView = () => {
        let view = SeeMoreList.map((item, index) => {
            return (
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    key={`SeeMoreView_${index}`}
                >
                    <Menu.Items
                        key={`SeeMoreView_${index}`}
                        className=" z-10 absolute left-0 top-[38px] w-48 rounded-md shadow-sm py-1 bg-white ring-1 ring-stone-50 ring-opacity-0 focus:outline-none"
                    >
                        {SeeMoreList.map((item) => (
                            <Menu.Item key={`SeeMoreView2_${index}`}>
                                {({ active }) => (
                                    <a
                                        key={`SeeMoreView3_${index}`}
                                        onClick={() => {
                                            if (
                                                clicked.length > 0 &&
                                                clicked.find((data) => item.href === data.href)
                                            ) {
                                                return;
                                            }
                                            setClicked((prev) => {
                                                return [
                                                    ...prev,
                                                    {
                                                        name: item.label,
                                                        href: item.href
                                                    }
                                                ];
                                            });
                                        }}
                                        href={
                                            i18n.language === 'en'
                                                ? item.href
                                                : `/${i18n.language}${item.href}`
                                        }
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t(item.label as keyof typeof t)}
                                    </a>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            );
        });
        return view;
    };
    const AskDoctorView = () => {
        let view = DoctorPanelList.map((item, index) => {
            return (
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    key={`DoctorPanelList_${index}`}
                >
                    <Menu.Items
                        key={`DoctorPanelList2_${index}`}
                        className="origin-top-right z-10 absolute left-0 mt-[30px] w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        {DoctorPanelList.map((item) => (
                            <Menu.Item key={`DoctorPanelList3_${index}`}>
                                {({ active }) => (
                                    <Link
                                        key={`DoctorPanelList4_${index}`}
                                        onClick={() => {
                                            if (
                                                clicked.length > 0 &&
                                                clicked.find((data) => item.href === data.href)
                                            ) {
                                                return;
                                            }
                                            setClicked((prev) => {
                                                return [
                                                    ...prev,
                                                    {
                                                        name: item.label,
                                                        href: item.href
                                                    }
                                                ];
                                            });
                                        }}
                                        to={
                                            i18n.language === 'en'
                                                ? item.href
                                                : `/${i18n.language}${item.href}`
                                        }
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t(item.label as keyof typeof t)}
                                    </Link>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            );
        });
        return view;
    };

    const BlogsVlogsView = () => {
        let view: JSX.Element[] = [];
        let view2: JSX.Element[] = [];
        BlogsVlogs.map((item, index) => {
            if (item.subtype === 1) {
                view.push(
                    <Menu.Item key={`BlogsVlogs_${index}`}>
                        {({ active }) => (
                            <a
                                key={`blogsVlogs2_${index}`}
                                onClick={() => {
                                    if (
                                        clicked.length > 0 &&
                                        clicked.find((data) => item.href === data.href)
                                    ) {
                                        return;
                                    }
                                    setClicked((prev) => {
                                        return [
                                            ...prev,
                                            {
                                                name: item.label,
                                                href: item.label
                                            }
                                        ];
                                    });
                                }}
                                href={
                                    i18n.language === 'en'
                                        ? item.href
                                        : `/${i18n.language}${item.href}`
                                }
                                className={
                                    classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                    ) + 'pt-track-click'
                                }
                                data-ptname={`blog_navbar_${item.label}`}
                            >
                                {t(item.label as keyof typeof t)}
                            </a>
                        )}
                    </Menu.Item>
                );
            } else {
                view.push(
                    <Menu.Item key={`BlogsVlogs5_${index}`}>
                        {({ active }) => (
                            <Link
                                key={`BlogsVlogs4_${index}`}
                                onClick={() => {
                                    if (
                                        clicked.length > 0 &&
                                        clicked.find((data) => item.href === data.href)
                                    ) {
                                        return;
                                    }
                                    setClicked((prev) => {
                                        return [
                                            ...prev,
                                            {
                                                name: item.label,
                                                href: item.label
                                            }
                                        ];
                                    });
                                }}
                                to={
                                    i18n.language === 'en'
                                        ? item.href
                                        : `/${i18n.language}${item.href}`
                                }
                                state={{ topicId: item.topicId }}
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                            >
                                {t(item.label as keyof typeof t)}
                            </Link>
                        )}
                    </Menu.Item>
                );
            }
        });
        view2.push(
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                key={`BlogsVlogs_vie2_`}
            >
                <Menu.Items
                    key="service-menu"
                    className="origin-top-right z-10 absolute top-2 left-0 mt-[30px] w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    {view}
                </Menu.Items>
            </Transition>
        );
        return view2;
    };
    const TalkListView = () => {
        let view = Talk.map((item, index) => {
            return (
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    key={`Talk_${index}`}
                >
                    <Menu.Items
                        key={`Talk2_${index}`}
                        className="origin-top-right z-10 absolute left-0 mt-[30px] w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        {Talk.map((item) => (
                            <Menu.Item key={`Talk3_${index}`}>
                                {({ active }) => (
                                    <Link
                                        key={`Talk4_${index}`}
                                        onClick={() => {
                                            if (
                                                clicked.length > 0 &&
                                                clicked.find((data) => item.href === data.href)
                                            ) {
                                                return;
                                            }
                                            setClicked((prev) => {
                                                return [
                                                    ...prev,
                                                    {
                                                        name: item.label,
                                                        href: item.href
                                                    }
                                                ];
                                            });
                                        }}
                                        to={
                                            i18n.language === 'en'
                                                ? item.href
                                                : `/${i18n.language}${item.href}`
                                        }
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t(item.label as keyof typeof t)}
                                    </Link>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            );
        });
        return view;
    };

    const profileUrl = `${getHost()}user/profile/${user_data?.userId}`;

    const logoSchemaView = () => {
        const data = {
            '@context': 'https://schema.org/',
            '@graph': [
                {
                    '@type': 'Organization',
                    name: 'Parentune',
                    legalName: 'Parentune',
                    description: 'Parentune is the only Parent focused specialized program',
                    url: 'https://parentune.com/',
                    logo: 'https://img1.parentune.com/images/parentune-orng-horizontal.png',
                    foundingDate: '2012',
                    founders: [
                        {
                            '@type': 'Person',
                            givenName: 'Nitin',
                            familyName: 'Nitin',
                            jobTitle: {
                                '@type': 'DefinedTerm',
                                name: 'Co-Founder & CEO',
                                sameAs: 'https://in.linkedin.com/in/nitinparentune'
                            }
                        }
                    ],
                    address: {
                        '@type': 'PostalAddress',
                        streetAddress:
                            'SCO 83 U & I Corporate Building 2nd Floor VR 1, Sector 29, Gurugram, Haryana 122022',
                        addressLocality: 'Gurugram',
                        addressRegion: 'Haryana',
                        postalCode: '122022',
                        addressCountry: 'IN'
                    },
                    sameAs: [
                        'https://www.facebook.com/Parentune/',
                        'https://in.linkedin.com/company/parentune-com',
                        'https://www.instagram.com/parentunelive/',
                        'https://x.com/parentune'
                    ],
                    contactPoint: [
                        {
                            '@type': 'ContactPoint',
                            telephone: '+91-7982328956',
                            contactType: 'help',
                            areaServed: 'IN'
                        }
                    ]
                },
                {
                    '@type': 'ItemList',
                    name: 'Unacademy',
                    description:
                        'Get Unlimited Access to interactive Live Classes on India’s largest online learning platform - Unacademy. Start connecting with Top Educators today.',
                    itemListOrder: 'http://schema.org/ItemListOrderAscending',
                    itemListElement: [
                        {
                            '@type': 'Product',
                            name: 'Parentune Short term plan',
                            url: 'https://www.parentune.com/services/short-term-plan',
                            description: 'Parentune Short term plan'
                        }
                    ]
                }
            ]
        };

        return JSON.stringify(data);
    };

    const newUser = cookies['new_user'];
    const profileCreated = cookies['user_profile_created'];
    // console.log(`{/${currLang}}/register`);

    console.log('>>>>>', country);
    return window.location?.pathname.includes('/stories') ||
        window.location?.pathname.includes('/renew') ||
        showImmunifyMePage ||
        hideHader ? null : (
        <>
            {pathName != '/register' &&
                pathName != '/sampling' &&
                userSubscriptionSelectedStatus == null &&
                country?.includes('india') && <Overlay>{/* <SamplingPopup /> */}</Overlay>}
            {consentGiven === false && showTandCPage && (
                <TermAndConditions consentGiven={consentGiven} setConsentGiven={setConsentGiven} />
            )}
            <Helmet>
                <script type="application/ld+json">{logoSchemaView()}</script>
            </Helmet>
            {/* @ts-ignore */}
            {window.location.pathname != '/register' &&
            window.location.pathname != `/${currLang}/register` ? (
                <div className="bg-white  shadow sticky top-0 z-[49] px-0 ">
                    <div className="flex justify-between items-center !max-w-[1250px] site-spacing">
                        <div className="w-full pr-2 sm:px-4 xl:px-8">
                            <div
                                className={`flex justify-between items-center ${
                                    user_data?.subscriptionInfo?.includes('pro')
                                        ? `h-[73px]`
                                        : 'h-[60px]'
                                }`}
                            >
                                <div className="flex px-2 xl:px-0">
                                    {width <= 1280 && (
                                        <Suspense fallback={null}>
                                            <MobileMenu
                                                setSelected={setSelected}
                                                selected={language}
                                                options={languageOption}
                                            />
                                        </Suspense>
                                    )}

                                    <div
                                        onClick={() =>
                                            navigate(
                                                i18n.language === 'en' ? '/' : `/${i18n.language}/`
                                            )
                                        }
                                        className="flex-shrink-0 flex items-center"
                                        aria-label="logotexticon"
                                    >
                                        <span className="block z-20">
                                            <LogoTextIcon />
                                            {/* <img
                                                src={ParentuneDiwaliAnimated}
                                                className="w-[216px] -ml-5 "
                                            /> */}
                                        </span>
                                    </div>
                                    <div className="flex-shrink-0 mx-4 xl:flex hidden items-center border-[1.5px] border-gray-400 px-[11px] py-[2px] rounded-2xl">
                                        <DropDown
                                            footerDropdown={false}
                                            rounded
                                            Icon={BsGlobe2}
                                            selected={language}
                                            setSelected={(item) => {
                                                setSelected(item);
                                            }}
                                            options={languageOption}
                                        />
                                    </div>
                                </div>

                                <div className="hidden mx-auto xl:flex xl:space-x-4 ">
                                    {navBarLinkView()}
                                    {window.location.pathname != '/' ? (
                                        <SearchIcon
                                            onClick={() => {
                                                SendEvents(
                                                    user_data?.id ?? DefaultUser,
                                                    'btn-click',
                                                    'btn_search'
                                                );

                                                window.location.assign(
                                                    'https://www.parentune.com/search?keyword=&filterAgeGroup=&filter_topic=&submit='
                                                );
                                            }}
                                            className="h-6 w-6 border-none pt-track-click"
                                            data-ptname={`search_icon_btn`}
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <div className="xl:hidden flex items-center justify-end px-2 ">
                                    <button
                                        type="button"
                                        className="flex-shrink-0 h-min bg-white pr-8 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none"
                                    >
                                        <span className="sr-only">View notifications</span>
                                        <SearchIcon
                                            onClick={() => {
                                                SendEvents(
                                                    user_data?.id ?? DefaultUser,
                                                    'btn-click',
                                                    'btn_search'
                                                );

                                                window.location.assign(
                                                    'https://www.parentune.com/search?keyword=&filterAgeGroup=&filter_topic=&submit='
                                                );
                                            }}
                                            className="h-6 w-6 border-none pt-track-click"
                                            data-ptname={`search_icon_btn`}
                                            aria-hidden="true"
                                        />
                                    </button>
                                    {user_data ? (
                                        <a className="z-[52]" href="/notifications">
                                            <BellIcon
                                                className="pt-track-click"
                                                data-ptname={`notification_icon_btn`}
                                            />
                                        </a>
                                    ) : (
                                        <Filter />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.nameBtnW}>
                            <div className="xl:ml-4 hidden xl:flex items-center">
                                <div className=" xl:ml-4 flex  xl:items-center">
                                    {/* Profile dropdown */}
                                    {isLoggedIn && (
                                        <Menu as="div" className="mr-4 relative flex-shrink-0">
                                            <div>
                                                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none">
                                                    <span className="sr-only">Open user menu</span>
                                                    <span className="h-10 w-10 rounded-full flex justify-center items-center">
                                                        {user_data && user_data?.avatar ? (
                                                            <>
                                                                <div
                                                                    className={`relative ${
                                                                        user_data &&
                                                                        user_data.isSubscribed
                                                                            ? 'pb-3'
                                                                            : ''
                                                                    } `}
                                                                >
                                                                    <img
                                                                        className="h-10 w-10 rounded-full border-2 object-cover"
                                                                        src={user_data?.avatar}
                                                                        alt="User Profile pic"
                                                                    />

                                                                    {user_data &&
                                                                        (isUserSubscribedPlus(
                                                                            user_data
                                                                        ) ||
                                                                            isUserSubscribedPro(
                                                                                user_data
                                                                            )) && (
                                                                            <button
                                                                                className={`bg-[#367E8A]  text-white px-1 pb-[1px] text-[10px] h-4  leading-3  rounded right-0 left-0 absolute ${
                                                                                    user_data?.subscriptionInfo?.includes(
                                                                                        'pro'
                                                                                    )
                                                                                        ? '-bottom-3'
                                                                                        : 'bottom-0'
                                                                                } `}
                                                                            >
                                                                                {isUserSubscribedPlus(
                                                                                    user_data
                                                                                )
                                                                                    ? 'PLUS'
                                                                                    : user_data?.subscriptionInfo?.toUpperCase()}
                                                                            </button>
                                                                        )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <UserIcon />
                                                        )}
                                                    </span>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {user_data ? (
                                                        <>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `user/profile/${user_data?.userId}`
                                                                            )
                                                                        }
                                                                        className={classNames(
                                                                            active
                                                                                ? 'bg-gray-100'
                                                                                : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        Your Profile
                                                                    </div>
                                                                )}
                                                            </Menu.Item>

                                                            {user_data?.isExpert ? (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <a
                                                                            href="https://www.parentune.com/expert-panel/dashboard"
                                                                            className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700"
                                                                        >
                                                                            Expert Dashboard
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            ) : null}

                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        onClick={() => {
                                                                            // removeCookie('_ppmgi');
                                                                            document.cookie =
                                                                                '_ppmgi=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

                                                                            dispatch(
                                                                                clearUserData()
                                                                            );
                                                                            localStorage.clear();
                                                                            window.location.reload();
                                                                        }}
                                                                        className={classNames(
                                                                            active
                                                                                ? 'bg-gray-100'
                                                                                : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        Log out
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        </>
                                                    ) : (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="/register"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    Sign in
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    )}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    )}
                                </div>
                            </div>
                            {isLoggedIn ? null : (
                                <button
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="bg-[#367E8A]  !mr-0 text-white px-[1.125rem] rounded-[1.563rem]  font-roboto font-medium text-sm leading-4 !py-2 !max-h-10"
                                    onClick={() => {
                                        dispatch(updatePathName('/register'));
                                        navigate('/register', {
                                            replace: false,
                                            state: { from: location }
                                        });
                                    }}
                                >
                                    {t('join_parentune')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

const navLinks = [
    {
        label: 'Learn',
        href: '/livechats'
    },
    {
        label: 'Parent Talks',
        href: '/parent-talk'
    },
    {
        label: 'Blogs & Vlogs',
        href: '/parent-blog'
    },
    {
        label: 'Ask Doctors',
        href: '/expert-corner'
    },

    {
        label: 'Plus',
        href: '/'
    }
];

const servicesLinks = [
    {
        label: 'Baby Names',
        href: '/baby-names'
    }
];

const mobileLinks2 = [
    {
        label: 'Talk',
        type: 1,
        href: '/parent-talk'
    },
    {
        label: 'Blogs & Vlogs',
        type: 2,
        href: '/parent-blog'
    },

    {
        label: 'Ask Doctors',
        type: 3,
        href: '/expert-corner'
    },
    {
        label: 'Parentune Plus',
        type: 4,
        href: '/subscribe'
    },
    {
        label: 'Learn',
        type: 5,
        href: '/livechats'
    },
    {
        label: 'Services',
        type: 6,
        href: '/proparent-services'
    },

    {
        label: 'See More',
        type: 7,
        href: '/advertise'
    }
];

const ServicesList = [
    {
        label: 'Expert Consultation',
        href: '/services/expert-consultation',
        subtype: 1
    },
    {
        label: 'Coloring pages',
        href: '/coloring-pages',
        subtype: 2
    },
    {
        label: 'Due Date Calculator',
        href: '/pregnancy-due-date-calculator',
        subtype: 3
    },
    {
        label: 'Baby Names',
        href: '/baby-names',
        subtype: 4
    },
    {
        label: 'Babynames Consultation',
        type: 20,
        href: '/services/astrologer'
    },
    {
        label: 'My Sessions',
        type: 28,
        href: '/users/appointments'
    },
    {
        label: 'Ovulation Calculator',
        href: NEXTJSROUTE.ovulation,
        subtype: 5
    },
    {
        label: 'Pet Names',
        subType: 5,
        href: NEXTJSROUTE.petnames
    },
    {
        label: 'Preschool',
        href: '/preschool',
        subtype: 6
    },
    {
        label: 'School',
        subtype: 10,
        href: '/school'
    },
    {
        label: 'Pediatrician',
        href: '/pediatrician',
        subtype: 10
    },
    {
        label: 'Gynecologist',
        subtype: 10,
        href: '/gynaecologist'
    },
    {
        label: 'Child Psychologist',
        subtype: 10,
        href: '/child-psychologist'
    },
    {
        label: 'Maternity Hospital',
        subtype: 10,
        href: '/hospital'
    }
];

const DoctorPanelList = [
    {
        label: "Doctor's Panel",
        href: '/expert-panel',
        subtype: 6
    },
    {
        label: 'Health Corner',
        href: '/become-expert',
        subtype: 7
    },
    {
        label: 'Nutrition Corner',
        href: '/become-expert',
        subtype: 7
    },
    {
        label: 'Join Doctor;s Panel',
        href: '/become-expert',
        subtype: 7
    },
    {
        label: 'Child Psychology Corner',
        href: '/become-expert',
        subtype: 7
    },
    {
        label: 'Education Corner',
        href: '/become-expert',
        subtype: 7
    }
];
const BlogsVlogs = [
    {
        label: 'Blogs & Vlogs',
        href: '/parent-blog',
        subtype: 0
    },
    {
        label: 'Write a blog',
        href: '/become-a-blogger',
        subtype: 1
    },
    {
        label: 'Pregnancy',
        href: '/parent-blog/pregnancy',
        subtype: 14,
        topicId: 16
    },
    {
        label: 'Breastfeeding',
        href: '/parent-blog/breastfeeding',
        subtype: 3,
        topicId: 18
    },
    {
        label: 'Parenting',
        href: '/parent-blog/parenting',
        subtype: 4,
        topicId: 1
    },
    {
        label: 'Education & Learning',
        href: '/parent-blog/education-learning',
        subtype: 5,
        topicId: 2
    },
    {
        label: 'Career',
        href: '/parent-blog/career',
        subtype: 6,
        topicId: 3
    },
    {
        label: 'Hobbies',
        href: '/parent-blog/hobbies',
        subtype: 7,
        topicId: 4
    },
    {
        label: 'Babycare',
        href: '/parent-blog/babycare',
        subtype: 8,
        topicId: 5
    },
    {
        label: 'Health',
        href: '/parent-blog/health',
        subtype: 9,
        topicId: 6
    },
    {
        label: 'Child Psychology & Behaviour',
        href: '/parent-blog/child-psychology-behaviour',
        subtype: 10,
        topicId: 7
    },
    {
        label: 'Special Needs',
        href: '/parent-blog/special-needs',
        subtype: 11,
        topicId: 8
    },
    {
        label: 'Outdoor Activities & Events',
        href: '/parent-blog/outdoor-activities-events',
        subtype: 12,
        topicId: 9
    },
    {
        label: 'Food & Nutrition',
        href: '/parent-blog/food-nutrition',
        subtype: 13,
        topicId: 10
    }
];

const Talk = [
    {
        label: 'Parenting',
        href: '/expert-panel',
        subtype: 1
    },
    {
        label: 'Education & Learning',
        href: '/become-expert',
        subtype: 2
    },
    {
        label: 'Career',
        href: '/become-expert',
        subtype: 3
    },
    {
        label: 'Hobbies',
        href: '/become-expert',
        subtype: 4
    },
    {
        label: 'Babycare',
        href: '/become-expert',
        subtype: 5
    },
    {
        label: 'Health',
        href: '/become-expert',
        subtype: 6
    },
    {
        label: 'Child Psychology & Behaviour',
        href: '/become-expert',
        subtype: 7
    },
    {
        label: 'Special Needs',
        href: '/become-expert',
        subtype: 8
    },
    {
        label: 'Outdoor Activities & Events',
        href: '/become-expert',
        subtype: 9
    },
    {
        label: 'Food & Nutrition',
        href: '/become-expert',
        subtype: 10
    }
];

const SeeMoreList = [
    //     Parentune Plus
    // Parentune @ work
    // Advertise
    // Careers
    // Contact Us
    // About us
    // Mombassador
    // Parentoon of the day
    {
        label: 'Parentune Plus',
        href: '/subscribe',
        subtype: 1
    },
    {
        label: 'Parentune @ work',
        href: '/work',
        subtype: 2
    },
    {
        label: 'Advertise',
        href: '/advertise',
        subtype: 3
    },
    {
        label: 'Careers',
        href: '/careers',
        subtype: 4
    },
    {
        label: 'Contact Us',
        href: '/contact-us',
        subtype: 5
    },
    {
        label: 'About us',
        href: '/about-us',
        subtype: 6
    },
    {
        label: 'Mombassador',
        href: '/mombassador-program',
        subtype: 7
    },
    {
        label: 'Parentoon of the day',
        href: '/parentoon-of-the-day',
        subtype: 8
    }
];

const languageOption = [
    { value: 'en', label: 'English' },
    { value: 'hi', label: 'हिंदी' },
    { value: 'mr', label: 'मराठी' },
    { value: 'te', label: 'తెలుగు' },
    { value: 'ta', label: 'தமிழ்' }
];

export default NavBar;

const isOvulation = (item: any): string => {
    if (item.label == 'Ovulation Calculator' || item.label == 'Pet Names') {
        let user_data = JSON.parse(localStorage.getItem('user_data')!) || '';

        if (user_data === '') return `?user=${encodeURIComponent('false')}`;

        return `?data=${encodeURIComponent(
            JSON.stringify({ authToken: user_data.authToken, instanceId: user_data.instanceId })
        )}`;
    }

    return '';
};
