import axios from 'axios';
import { useQuery } from 'react-query';
import { useUserData } from '../utils/hooks/useUserData';

import { Geti18nLang } from '../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();
export const useRelatedData = (id: string, type: string, page: number) => {
    const { userData: user_data, guestUid } = useUserData();

    return useQuery(
        ['related', id, type, page],
        () => {
            return axios
                .get(process.env.REACT_APP_CONTENT_SERVER + 'content/related', {
                    params: {
                        itemId: id,
                        targetType: type,
                        itemType: type,
                        pageNumber: page
                    },
                    headers: {
                        lang: SelectedLanguage,
                        userId: user_data ? user_data.userId?.toString() : '',
                        authToken: user_data ? user_data.authToken : '',
                        instanceId: user_data ? user_data.instanceId : '',
                        guestUid: guestUid ? guestUid : ''
                    }
                })
                .then((res) => {
                    if (type === 'talk') return res.data.list;
                    else return res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        {
            staleTime: 10 * 60 * 60 * 1000
        }
    );
};
