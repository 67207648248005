import { useLocation } from 'react-router-dom';
import crossicon from '../../assets/booksummary/cross-icon.svg';
import doneicon from '../../assets/booksummary/done.svg';
import { DateSummary } from '../BookingSummary/DateSummary';
import { DoctorCard } from '../BookingSummary/DoctorCard';
import { TopHeader } from '../BookingSummary/TopHeader';

interface LocationState {
    start_date: string;
    title: string;
    amount: string;
    avatar: string;
    name: string;
    specialization: string;
}

function PaymentSummary() {
    const location = useLocation();
    const state = location.state as LocationState;

    return (
        <div className="flex justify-center">
            <div className="max-w-4xl w-full">
                <TopHeader icon={crossicon} content="Payment Summary" />
                <PaymentInfo state={state} />
                <DoctorCard state={state} />
                <DateSummary state={state} />
                <FeedBack />
            </div>
        </div>
    );
}

export default PaymentSummary;

const PaymentInfo = (props: any) => {
    const { state } = props;
    const detail = state;

    return (
        <div className="mt-[24px] flex flex-col items-center">
            <div className="flex justify-center items-center gap-[8px]">
                <img src={doneicon} alt="doneIcon" />
                <h2 className="text-[#242F35] text-[18px] font-medium">Payment Successful</h2>
            </div>
            <h2 className="mt-[30px] text-[#242F35] text-[21px] font-bold">Rs {detail.amount}</h2>
            <h2 className="mt-[20px] text-[#787878] text-[16px] font-normal">
                Transaction ID : 1PRTUN554
            </h2>
            <p className="mx-[20px] mt-[24px] border border-[#367E8A] border-dotted w-full"></p>
        </div>
    );
};

const FeedBack = () => {
    return (
        <div className="mx-[20px] pb-[20px] text-[12px] text-[#787878] text-center">
            <h2>Thank you for your purchase. </h2>
            <h3>A reminder has been sent to kachwallasana@gmail.com</h3>
        </div>
    );
};
