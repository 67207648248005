import './SubmitButton.css';

import { MdNavigateNext } from 'react-icons/md';
import { classNames } from '../../utils/utils';

function SubmitButton(props: any) {
    let buttonName = 'submit-button';
    buttonName += props.inactive ? ' inactive' : props.transparent ? ' transparent' : '';

    return (
        <>
            {props.isLoading ? (
                <button
                    type="button"
                    data-ptname={props?.dataPtname && props.dataPtname}
                    onClick={!props.inactive ? props.handleSubmit : undefined}
                    disabled={props.isLoading}
                    className={classNames(
                        props.size === 'small'
                            ? 'py-1.5 px-2 text-sm min-w-min w-24'
                            : props.size === 'medium'
                            ? 'py-2.5 px-2.5 text-sm w-40'
                            : 'w-full px-3 py-3 text-sm',
                        props.isLoading ? 'opacity-30' : 'hover:bg-cyan-800',
                        props.rounded ? 'rounded-md' : 'rounded-full',
                        'inline-flex items-center flex-shrink-0 whitespace-nowrap justify-center border border-transparent font-medium shadow-sm text-white bg-cyan-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600',
                        props.className,
                        buttonName
                    )}
                >
                    <div
                        className={classNames(
                            props.size === 'small'
                                ? 'px-3.5'
                                : props.size === 'medium'
                                ? 'px-5'
                                : 'w-full  px-5  text-sm',
                            'flex justify-center w-24 items-center'
                        )}
                    >
                        <div
                            style={{ borderTopColor: 'transparent' }}
                            className="w-5 ml-1 h-5 border-4 border-darkblue-300 border-solid rounded-full animate-spin"
                        ></div>
                    </div>
                </button>
            ) : (
                <div
                    className={`${buttonName} ${props.className}  pt-track-click`}
                    data-ptname="ask_an_expert_btn_cta"
                    data-pttype="button"
                    data-ptposition="top"
                    onClick={!props.inactive ? props.handleSubmit : undefined}
                >
                    {props.buttonText}
                    {props.next && <MdNavigateNext fill="white" />}
                </div>
            )}
        </>
    );
}

export default SubmitButton;
