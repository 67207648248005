import React, { useEffect, useRef, useState } from 'react';
// import './AddChildComponent.css';
import PlusIcon from '../../assets/PlusIcon.svg';
import TextInputForm from '../TextInputForm/TextInputForm';
import DOBInput from '../../pages/ParentTypeInputPage/DOBInput';
// import CrossIcon from '../../assets/icons/CrossIconDOB.svg';
import NameInput from './NameInput';

type ChildDataType = {
    name: string;
    dob: string;
    id: number;
    gender: string;
};

type AddChildComponentType = {
    onClick?: () => void;
    atLeastOneChild?: boolean;
    setData?: any;
    data?: any;
    isForProfile?: any;
};

function AddChildComponent(props: AddChildComponentType) {
    const { atLeastOneChild, setData } = props;
    console.log(props.data);
    // const [childData, setChildData] = useState<ChildDataType[]>(props.data ? props.data : []);
    const [childData, setChildData] = useState<ChildDataType[]>(() => {
        if (props.data?.length > 0) return props.data;
        const storedData = localStorage.getItem('onboarding_data');
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                // Check if dueDate key exists in the stored data
                return parsedData.dueDate ? parsedData.data : parsedData;
            } catch (error) {
                console.error('Failed to parse stored onboarding data:', error);
            }
        }

        // Default case if no data is found or parse fails
        return atLeastOneChild ? [{ id: 1, name: '', gender: 'Male', dob: '' }] : [];
    });

    const xref = useRef(false);

    useEffect(() => {
        // if (props.data?.length === 0) {
        //     setChildData([{ id: 1, name: '', gender: 'Male', dob: '' }]);
        // } else {
        //     setChildData(props?.data);
        // }
        xref.current = true;
    }, [props?.data]);

    // useEffect(() => {
    //     console.log('xref.current: ', xref.current);
    //     if (xref.current) {
    //         // setChildData(props?.data);
    //         console.log('data set on line 45');
    //     }
    // }, [xref?.current]);

    useEffect(() => {
        if (!setData) return;
        // if (props?.isForProfile) return;
        setData(childData);
    }, [childData]);

    const handleRemoveItem = (id: number) => {
        console.log('handle remove id', id);
        setChildData((oldValues: any) => {
            return oldValues.filter((child: any) => child.id !== id);
        });
    };

    const updateChildName = (newName: any, index: number) => {
        const updatedChildData = [...childData];
        // @ts-ignore
        updatedChildData[index].name = newName;
        setChildData(updatedChildData);
    };
    const updateChildDob = (dob: string, index: number) => {
        setChildData((prevChildData) =>
            prevChildData.map((child, i) => (i === index ? { ...child, dob: dob } : child))
        );
    };

    return (
        <div>
            {/* add child box */}
            {childData && childData.length > 0 && (
                <section>
                    {childData.map((child: any, index) => (
                        <div key={index}>
                            <NameInput
                                child={child}
                                onChangeName={(newName: any) => updateChildName(newName, index)}
                                onChangeDob={(dob: any) => updateChildDob(dob, index)}
                                index={index}
                                atLeastOneChild={atLeastOneChild}
                                handleRemoveItem={handleRemoveItem}
                                id={child.id}
                            />
                        </div>
                    ))}
                </section>
            )}

            {/* {JSON.stringify(childData)} */}
            {/* add child plus btn */}
            {childData && childData.length < 3 && (
                <section
                    onClick={() => {
                        console.log('open add child');
                        setChildData((prevChildData: any) => [
                            ...prevChildData,
                            {
                                id: childData.length + 1,
                                name: '',
                                gender: 'Male',
                                dob: ''
                            }
                        ]);
                    }}
                    className="flex justify-start items-center space-x-2 my-6"
                >
                    <img src={PlusIcon} alt="Add Child Icon" />
                    <p className="text-[#242F35] font-roboto font-normal text-sm">
                        Add another child
                    </p>
                </section>
            )}
        </div>
    );
}

export default AddChildComponent;
