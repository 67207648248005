import { useEffect, useRef, useState } from 'react';
import './LiveEventCard.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserData } from '../../utils/hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { BiChevronRight } from 'react-icons/bi';
import ButtonLiveChat from '../V2Components/ButtonLiveChat';
import { Constants } from '../Constants/Constants';
import bookmarkWorskshop from '../../api/workshop/bookmark';
import Overlay from '../Overlay/Overlay';
import EventCountdown from '../../utils/GetTimeLeft';
import Modal from '../Overlay/Modal';
import { useCookies } from 'react-cookie';
import GuestActivityLog from '../../api/ActivityLog/GuestActivityLog';
import ContentProtectionCTA from '../ContentProtectionCTA/ContentProtectionCTA';
import { SendEvents } from '../../utils/utils';
const blank = 'https://img1.parentune.com/web/assests_v2/blank.webp';
const LockIconSvg = 'https://img1.parentune.com/images/icons/content%20Lock%20icon.svg';
interface LiveEventCardProps {
    amount: any;
    description: string;
    expert_details: {
        avatar: string;
        desc: string;
        details: string;
        name: string;
        specialization: string;
    }[];
    id: number;
    time_data: string;
    is_bookmarked: boolean;
    isUnlock: boolean;
    isBookmarked: boolean;
    age_group_label: string;
    primary_age_group: number;
    start_date: string;
    summary: string;
    thumb_image: string;
    title: string;
    type: string;
    url: string;
    isRegistered?: boolean;
    mode: any;
    status: any;
}

function LiveEventCard(props: LiveEventCardProps) {
    const { lng } = useParams();
    const navigate = useNavigate();
    const { userData: user_data, canWatchWorkshops, isLoggedIn } = useUserData();
    // console.log(user_data);
    const [remaining, setRemaining] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [toast, setToast] = useState(false);
    const [guestCookies] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const ToasterHandler = () => {
        setToast(true);
        setTimeout(() => {
            setToast(false);
        }, 2000);
    };
    // const config = {
    //     maxCount: 1
    // };

    function add3Dots(text: any, limit: any) {
        if (!lng) {
            return text.length > limit ? `${text.substring(0, limit)}...` : text;
        }
        if (lng == 'hi') {
            return text.length > limit ? `${text.substring(0, limit + 20)}...` : text;
        }
        if (lng == 'mr') {
            return text.length > limit ? `${text.substring(0, limit + 15)}...` : text;
        }

        if (lng == 'ta') {
            return text.length > limit - 10 ? `${text.substring(0, limit - 20)}...` : text;
        }

        if (lng == 'te') {
            return text.length > limit - 10 ? `${text.substring(0, limit - 15)}...` : text;
        }
    }
    // useEffect(() => {
    //     if (props.status === 'upcoming') {
    //         // setShowTime(true);
    //         // setInterval(() => {
    //         //     timeString = countDown(props.start_date);
    //         //     console.log(timeString);
    //         //     setRemaining(timeString);
    //         // }, 1000);
    //         let a = EventCountdown(props.start_date);
    //         console.log(a);
    //         setRemaining(a);
    //     }
    // }, []);

    const { t } = useTranslation();

    const [isBookMarked, setisBookMarked] = useState<boolean>(
        props.is_bookmarked || props.isBookmarked
    );
    useEffect(() => {
        if (user_data) {
            const preLoginLiveBookmarkData = sessionStorage.getItem('pt-live-card-bm');
            if (preLoginLiveBookmarkData) {
                const preLoginLiveBookmarkDataObj = JSON.parse(preLoginLiveBookmarkData);
                if (props.id === preLoginLiveBookmarkDataObj.itemId && !props.is_bookmarked) {
                    setisBookMarked(true);
                    bookmarkWorskshop(
                        { user_data: user_data, id: props.id },
                        (data) => {
                            console.log(data);
                        },
                        (error) => {
                            console.log(error);
                            sessionStorage.removeItem('pt-live-card-bm');
                        }
                    );
                }
            }
        }
    });
    const time_dateSplit = (str: any) => {
        let arr = str.split(' | ');
        if (arr[0].endsWith('11')) {
            return `${arr[0]}th | ${arr[1]}`;
        }
        if (arr[0].endsWith('12')) {
            return `${arr[0]}th | ${arr[1]}`;
        }
        if (arr[0].endsWith('13')) {
            return `${arr[0]}th | ${arr[1]}`;
        }
        if (arr[0].endsWith('1')) {
            return `${arr[0]}st | ${arr[1]}`;
        }
        if (arr[0].endsWith('2')) {
            return `${arr[0]}nd | ${arr[1]}`;
        }
        if (arr[0].endsWith('3')) {
            return `${arr[0]}rd | ${arr[1]}`;
        } else {
            return `${arr[0]}th | ${arr[1]}`;
        }
    };
    const handleBookmark = () => {
        // };
        if (sessionStorage.getItem('pt-live-card-bm')) {
            sessionStorage.removeItem('pt-live-card-bm');
        }
        if (!user_data) {
            // sessionStorage.setItem(
            //     'pt-live-card-bm',
            //     JSON.stringify({
            //         itemType: props.type,
            //         itemId: props.id
            //     })
            // );
            setShowModal(true);
            setModalType(Constants.MODAL_TYPE.BOOKMARK);
        } else {
            setisBookMarked(!isBookMarked);
            ToasterHandler();
            bookmarkWorskshop(
                { user_data: user_data, id: props.id },
                (data) => {
                    // api.info({
                    //     message: `${
                    //         isBookMarked === true
                    //             ? 'Event has been removed from your Bookmarks'
                    //             : 'Event has been Saved to your Bookmarks'
                    //     }`,
                    //     duration: 2,
                    //     placement
                    // });
                    // console.log(data);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };
    const onSuccessLog = (data: any) => {
        console.log(data);
    };
    const onFailureLog = (data: any) => {
        console.log(data);
    };

    const handleLiveEventClick = () => {
        // window.location.href = '/livechats/' + props.url + '/' + props.id;
        navigate(`/livechats/${props.url}/${props?.id}`);
    };
    const handleLiveEventBtnClick = () => {
        if (newGuestUid) {
            let params = {
                guestUid: newGuestUid,
                action: 'workshop_view',
                actionType: 'click',
                lang: 'en'
            };
            GuestActivityLog(params, onSuccessLog, onFailureLog);
        }

        navigate(`/livechats/${props.url}/${props?.id}`);

        // window.location.href = '/livechats/' + props.url + '/' + props.id;
    };

    const isForNestleOffer = Number(user_data?.workshopCreditLeft) > 0;

    // console.log(canWatchWorkshop);
    const displayLiveEventCardExperts = (
        expertDetails: {
            avatar: string;
            desc: string;
            details: string;
            name: string;
            specialization: string;
        }[]
    ) => {
        if (expertDetails) {
            if (expertDetails.length <= 1) {
                if (expertDetails[0].desc.slice(expertDetails[0].name.length).startsWith(',')) {
                    return (
                        <div className={'lecmi2 mr-[21px]'}>
                            <img
                                src={expertDetails[0].avatar}
                                alt={expertDetails[0].name}
                                className="object-cover"
                            />
                            <div className="live-event-card-expert-details ml-3 mt-4 w-[44%]">
                                <div
                                    className=""
                                    style={{
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto',
                                        fontSize: '16'
                                    }}
                                >
                                    {expertDetails[0].name}
                                </div>
                                <div
                                    style={{
                                        textOverflow: 'ellipsis',
                                        width: '130px',
                                        overflow: 'hidden',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {expertDetails[0].desc.slice(expertDetails[0].name.length + 2)}
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={'lecmi2 mr-[21px]'}>
                            <img src={expertDetails[0].avatar} alt={expertDetails[0].name} />
                            <div className="live-event-card-expert-details ml-3">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto',
                                        fontSize: '16'
                                    }}
                                >
                                    {expertDetails[0].name}
                                </div>
                                <div
                                    style={{
                                        textOverflow: 'ellipsis',
                                        width: '130px',
                                        overflow: 'hidden',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {expertDetails[0].desc}
                                </div>
                            </div>
                        </div>
                    );
                }
            } else if (expertDetails.length == 2) {
                return (
                    <div className={'lecmi2-moreExperts'}>
                        <img
                            src={expertDetails[0].avatar}
                            alt={expertDetails[0].name}
                            style={{ minWidth: '88px', minHeight: '91px' }}
                        />
                        <img
                            src={expertDetails[1].avatar}
                            alt={expertDetails[1].name}
                            style={{ minWidth: '88px', minHeight: '91px' }}
                        />
                    </div>
                );
            } else {
                {
                    console.log(expertDetails);
                }
                return (
                    // ==========================================
                    <div className={'lecmi2-moreExperts'}>
                        <img
                            src={expertDetails[0].avatar}
                            alt={expertDetails[0].name}
                            style={{ minWidth: '88px', minHeight: '91px' }}
                        />
                        <img
                            src={expertDetails[1].avatar}
                            alt={expertDetails[1].name}
                            style={{ minWidth: '88px', minHeight: '91px' }}
                        />
                        <img
                            src={expertDetails[2].avatar}
                            alt={expertDetails[2].name}
                            style={{ minWidth: '88px', minHeight: '91px' }}
                        />
                    </div>
                    // ============================================
                );
            }
        } else {
            return (
                <div className="lecmi2">
                    <img src={blank} alt="avatar" />
                    <div className="live-event-card-expert-details">
                        <div style={{ fontWeight: 'bold', margin: '5px 0' }}>{'  '}</div>
                        <div
                            style={{
                                textOverflow: 'ellipsis',
                                width: '140px',
                                overflow: 'hidden'
                            }}
                        >
                            {'  '}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const isFreeWorkShop =
        props.amount == null ||
        props.isRegistered === true ||
        user_data?.isSubscribed === true ||
        props.amount == 0;
    return (
        <>
            {/* {contextHolder} */}
            <div
                className="lecm "
                onClick={() =>
                    SendEvents(
                        user_data?.userId,
                        'btn-click',
                        'btn_live_event',
                        props?.id.toString()
                    )
                }
            >
                <div className="lecmi1 w-full ">
                    {/* ---------------------------------------------------------------------- */}
                    {props.status === 'upcoming' &&
                        new Date(props.start_date + '+5:30').getTime() - new Date().getTime() >
                            0 && <EventCountdown date={props.start_date} />}
                    <div
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                            return handleBookmark();
                        }}
                    >
                        {/* {console.log(user_data)} */}
                        {isBookMarked ? (
                            // <FaBookmark className="lecmb1" size="1.5em" fill="#FB7882" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-white"
                                width="15"
                                height="20"
                                viewBox="0 0 17 24"
                                fill="#ffffff"
                            >
                                <path
                                    d="M16.15 23.9978C15.9421 23.9984 15.7412 23.9222 15.5853 23.7835L8.5 17.4311L1.41472 23.7835C1.29211 23.8934 1.14058 23.9652 0.978406 23.9902C0.816236 24.0152 0.650359 23.9923 0.500791 23.9243C0.351222 23.8564 0.224353 23.7462 0.135492 23.6072C0.0466315 23.4681 -0.000424248 23.3061 2.88219e-06 23.1407V3.42825C0.000987211 2.51933 0.359517 1.64792 0.996927 1.00521C1.63434 0.362501 2.49857 0.000992509 3.4 0H13.6C14.5014 0.000992509 15.3657 0.362501 16.0031 1.00521C16.6405 1.64792 16.999 2.51933 17 3.42825V23.1407C17 23.368 16.9104 23.586 16.751 23.7467C16.5916 23.9075 16.3754 23.9978 16.15 23.9978Z"
                                    fill="#FB7882"
                                />
                            </svg>
                        ) : (
                            // <FaRegBookmark className="lecmb2 " size="1.5em" fill="#787878" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="20"
                                viewBox="0 0 15 20"
                                fill="none"
                            >
                                <path
                                    d="M13.35 18.9983C13.191 18.9988 13.0374 18.9416 12.9182 18.8376L7.5 14.0733L2.08185 18.8376C1.98808 18.9201 1.8722 18.9739 1.74819 18.9926C1.62418 19.0114 1.49733 18.9942 1.38296 18.9432C1.26858 18.8923 1.17156 18.8097 1.10361 18.7054C1.03566 18.6011 0.999676 18.4796 1 18.3555V3.57119C1.00075 2.8895 1.27492 2.23594 1.76236 1.75391C2.24979 1.27188 2.91067 1.00074 3.6 1H11.4C12.0893 1.00074 12.7502 1.27188 13.2376 1.75391C13.7251 2.23594 13.9992 2.8895 14 3.57119V18.3555C14 18.526 13.9315 18.6895 13.8096 18.8101C13.6877 18.9306 13.5224 18.9983 13.35 18.9983Z"
                                    stroke="#424242"
                                    strokeWidth="1.5"
                                />
                            </svg>
                        )}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24" fill="none">
                        <path d="M16.15 23.9978C15.9421 23.9984 15.7412 23.9222 15.5853 23.7835L8.5 17.4311L1.41472 23.7835C1.29211 23.8934 1.14058 23.9652 0.978406 23.9902C0.816236 24.0152 0.650359 23.9923 0.500791 23.9243C0.351222 23.8564 0.224353 23.7462 0.135492 23.6072C0.0466315 23.4681 -0.000424248 23.3061 2.88219e-06 23.1407V3.42825C0.000987211 2.51933 0.359517 1.64792 0.996927 1.00521C1.63434 0.362501 2.49857 0.000992509 3.4 0H13.6C14.5014 0.000992509 15.3657 0.362501 16.0031 1.00521C16.6405 1.64792 16.999 2.51933 17 3.42825V23.1407C17 23.368 16.9104 23.586 16.751 23.7467C16.5916 23.9075 16.3754 23.9978 16.15 23.9978Z" fill="#787878"/>
                    </svg> */}
                        {/* <path xmlns="http://www.w3.org/2000/svg" d="M16.15 23.9978C15.9421 23.9984 15.7412 23.9222 15.5853 23.7835L8.5 17.4311L1.41472 23.7835C1.29211 23.8934 1.14058 23.9652 0.978406 23.9902C0.816236 24.0152 0.650359 23.9923 0.500791 23.9243C0.351222 23.8564 0.224353 23.7462 0.135492 23.6072C0.0466315 23.4681 -0.000424248 23.3061 2.88219e-06 23.1407V3.42825C0.000987211 2.51933 0.359517 1.64792 0.996927 1.00521C1.63434 0.362501 2.49857 0.000992509 3.4 0H13.6C14.5014 0.000992509 15.3657 0.362501 16.0031 1.00521C16.6405 1.64792 16.999 2.51933 17 3.42825V23.1407C17 23.368 16.9104 23.586 16.751 23.7467C16.5916 23.9075 16.3754 23.9978 16.15 23.9978Z" fill="#787878"/> */}
                    </div>
                </div>
                <div onClick={handleLiveEventClick}>
                    <div className="pt-[1rem] ">
                        {displayLiveEventCardExperts(props.expert_details)}
                    </div>
                    <div className="lecmi3">
                        <div
                            style={{
                                height: '45px',
                                fontWeight: '500',
                                fontSize: '18px',
                                fontFamily: 'roboto',
                                lineHeight: props.expert_details.length >= 2 ? '26px' : '26px',
                                textOverflow: 'ellipsis'
                                // overflow: 'hidden',
                            }}
                        >
                            {add3Dots(props.title, 53)}
                        </div>
                        <div className="my-[16px]">
                            <hr style={{ width: '132px', border: '0.5px dashed #367E8A' }} />
                        </div>
                        <div style={{ marginTop: '5px', fontSize: '16px' }}>
                            {props.age_group_label}
                        </div>

                        {props.status == 1 ? (
                            <div style={{ fontSize: '16px', height: '48px' }}>
                                {time_dateSplit(props?.time_data)}
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className=" flex justify-end items-end  flex-col w-full mt-[20px]">
                            <div className="flex flex-col">
                                {/* todo  */}

                                {user_data &&
                                    user_data?.nestleWorkshopId == props.id.toString() && (
                                        <p className="w-auto flex justify-center items-center mx-3 font-medium font-roboto text-sm text-[#787878] mb-2">
                                            Opened with GIFT
                                        </p>
                                    )}

                                {!isFreeWorkShop && isForNestleOffer && (
                                    <p className="w-auto flex justify-center items-center mx-3 font-medium font-roboto text-sm text-[#787878] mb-2">
                                        1 Learn Credit
                                    </p>
                                )}
                                <ButtonLiveChat
                                    dataPtName={`pt-view-workshop_${props.id}`}
                                    className="pt-track-click"
                                    Icon={BiChevronRight}
                                    onClick={handleLiveEventBtnClick}
                                    text={props.status === 'upcoming' ? 'VIEW' : 'WATCH NOW'}
                                    customStyle={{ alignItems: 'center' }}
                                />
                            </div>
                            {/* {console.log(props)} */}
                            {props.mode == 'upcoming' ? (
                                <></>
                            ) : (
                                <>
                                    {/* {props.amount == null ||
                                    props.isRegistered === true ||
                                    props.amount == 0 ? (
                                        ''
                                    ) : (
                                        <div className="live-event-card-amount font-normal">
                                            {props.amount && 'Rs ' + props.amount}
                                        </div>
                                    )} */}

                                    {props.amount == null ||
                                    props.isRegistered === true ||
                                    user_data?.isSubscribed === true ||
                                    props.isUnlock === true ||
                                    props.amount == 0 ||
                                    props.age_group_label === '1 to 3 years' ||
                                    props.age_group_label === '3 to 7 years' ||
                                    canWatchWorkshops ? (
                                        <div className="live-event-card-amount-onlyforplus">
                                            <div className="flex items-center justify-center mr-[8px]  h-[23px] w-[21px] rounded-full overflow-hidden bg-[#FBF2C9]">
                                                {/* <img
                                            src="https://img1.parentune.com/images/UnLock.png"
                                            width="23px"
                                            height="23px"
                                        /> */}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 7 8"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M2.83374 3.55556H2.7923C2.43426 3.55556 2.1451 3.87416 2.1451 4.26717V7.28839C2.1451 7.68215 2.43486 8 2.7923 8H6.3528C6.71084 8 7 7.6814 7 7.28839V4.26717C7 3.87341 6.71024 3.55556 6.3528 3.55556H3.48106V1.9125C3.48106 0.856481 2.70173 0 1.74053 0C0.779121 0 0 0.856222 0 1.9125V4.42821C0 4.62458 0.144907 4.78377 0.32366 4.78377C0.502412 4.78377 0.64732 4.62458 0.64732 4.42821V1.9125C0.64732 1.2489 1.13668 0.711111 1.74053 0.711111C2.34417 0.711111 2.83374 1.24915 2.83374 1.9125V3.55556ZM4.7914 5.72717C4.90314 5.64808 4.97713 5.51018 4.97713 5.3533C4.97713 5.10784 4.79599 4.90886 4.57255 4.90886C4.34911 4.90886 4.16798 5.10784 4.16798 5.3533C4.16798 5.51022 4.242 5.64814 4.35378 5.72723L4.17505 6.47136C4.14726 6.5682 4.20367 6.6467 4.30344 6.6467H4.84175C4.94082 6.6467 4.99782 6.56784 4.97014 6.47136L4.7914 5.72717Z"
                                                        fill="#262626"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    ) : isForNestleOffer ? null : user_data &&
                                      user_data?.nestleWorkshopId == props.id.toString() ? (
                                        <section className="live-event-card-amount-onlyforplus slideUpFast">
                                            <ContentProtectionCTA
                                                place="UnlockWithCta"
                                                cta={'Opened with Gift'}
                                            />
                                        </section>
                                    ) : (
                                        <section className="live-event-card-amount-onlyforplus slideUpFast">
                                            <ContentProtectionCTA
                                                place="LearnListing"
                                                cta={t('Only for PLUS members')}
                                            />
                                        </section>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {showModal ? (
                        <Modal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            // questionContainerRef={liveEventCardRef}
                            modalType={modalType}
                        ></Modal>
                    ) : (
                        <></>
                    )}
                </div>
                {isLoggedIn && toast && (
                    <Overlay>
                        <div className="relative w-full h-screen">
                            <div className="absolute bottom-[-35vh] inset-0 flex items-center justify-center">
                                <div
                                    className="w-fit shadow-xl bg-[#f4d64a]  text-black p-2 px-4 rounded-md"
                                    style={{ textShadow: '0px 0px 10px 10px' }}
                                >
                                    <p>
                                        {isBookMarked
                                            ? 'Event has been Saved to your Bookmarks'
                                            : 'Event has been Removed from your Bookmarks'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Overlay>
                    // <div></div>
                )}
            </div>
        </>
    );
}

export default LiveEventCard;
