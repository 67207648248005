import React, { useEffect, useState } from 'react';

interface ScrollBarImageType {
    imgArray: string[] | undefined;
}

function ScrollBarImage(props: ScrollBarImageType) {
    const { imgArray } = props;
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % (imgArray?.length || 1));
        }, 1000);

        return () => clearInterval(interval);
    }, [imgArray]);

    const getTransformStyle = (index: number): React.CSSProperties => {
        const totalImages = imgArray?.length || 1;
        const relativeIndex = (index - currentIndex + totalImages) % totalImages;

        const scale = relativeIndex === 1 ? 1.2 : 0.7;
        const translateX = `${(relativeIndex - 1) * (window.innerWidth <= 600 ? 140 : 170)}px`;

        return {
            transform: `translateX(${translateX}) scale(${scale})`,
            transformOrigin: 'bottom center',
            position: 'absolute',
            bottom: '0',
            transition: 'transform 0.8s ease-in-out'
        };
    };

    // Dynamic width based on screen size
    const getContainerWidth = (): string => {
        return window.innerWidth <= 600 ? '360px' : '420px';
    };

    const getImageSize = (): { width: number; height: number } => {
        return window.innerWidth <= 600
            ? { width: 100, height: 70 } // smaller images on mobile
            : { width: 120, height: 80 }; // larger images on desktop
    };

    return (
        <div
            className="flex justify-center items-center mt-4 mx-auto"
            style={{ width: getContainerWidth(), height: '225px' }}
        >
            <div
                className="relative flex justify-center items-end"
                style={{ width: '100%', height: '225px' }}
            >
                {imgArray?.map((imgItem: string, index: number) => (
                    <img
                        key={`${imgItem}-${index}`}
                        src={imgItem}
                        alt="giftImg"
                        width={getImageSize().width}
                        height={getImageSize().height}
                        className="absolute object-contain z-50"
                        style={getTransformStyle(index)}
                    />
                ))}
                <div
                    className="absolute left-0 bottom-0 bg-[#FFF3D5] rounded-full"
                    style={{
                        width: window.innerWidth <= 600 ? '100px' : '120px',
                        height: window.innerWidth <= 600 ? '100px' : '120px',
                        borderBottomLeftRadius: '0px'
                    }}
                ></div>
                <div
                    className="absolute right-0 bottom-0 bg-[#FFF3D5] rounded-full"
                    style={{
                        width: window.innerWidth <= 600 ? '100px' : '120px',
                        height: window.innerWidth <= 600 ? '100px' : '120px',
                        borderBottomRightRadius: '0px'
                    }}
                ></div>
            </div>
        </div>
    );
}

export default ScrollBarImage;
