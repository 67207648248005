import './ProfilePic.css';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { useUserData } from '../../utils/hooks/useUserData';
import { AvatarUpload } from '../../api/OnBoarding/AvatarUpload';
// const cameraicon = 'https://img1.parentune.com/web/assests_v2/cameraicon.webp';
import cameraicon from '../../assets/icons/CameraIcon.svg';
import GallaryIcon from '../../assets/icons/GallaryIcon.svg';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { useDispatch } from 'react-redux';
import { addUserdata } from '../../store/slices/userDataSlice';

interface ProfilePicProps {
    setter: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string;
                  url: string;
              }
            | undefined
        >
    >;
    avatar: any;
    removeBorder?: boolean;
}

function ProfilePic(props: ProfilePicProps) {
    const { userData: user_data } = useUserData();

    const [showProfilePicModal, setShowProfilePicModal] = useState(false);
    const [uploadingAvatar, setUploadingAvatar] = useState(false);

    const dispatch = useDispatch();

    const handleAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUploadingAvatar(true);
        if (!e.target.files) return;

        props.setter({
            name: e.target.files[0].name,
            url: URL.createObjectURL(e.target.files[0])
        });

        setShowProfilePicModal(false);

        const AvatarUploadOnSuccess = (s: any) => {
            console.log('sucesss: ', s);
            setUploadingAvatar(false);
            if (s?.statusCode !== 200) {
                alert('Something went wrong please try again');
                return;
            }
            GetUserProfile(
                async (data: any) => {
                    dispatch(addUserdata({ ...data?.data }));
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );
        };
        const AvatarUploadOnFailure = (e: any) => {
            console.log('Error: ', e);
            setUploadingAvatar(false);
        };
        AvatarUpload(AvatarUploadOnSuccess, AvatarUploadOnFailure, user_data, e.target.files[0]);
    };

    return (
        <div className="profile-pic-container">
            {uploadingAvatar ? (
                <section className="h-32 w-32 bg-white rounded-full relative">
                    <div className="absolute h-32 w-32 rounded-full bg-white bg-opacity-50"></div>
                    <img
                        src={props.avatar?.url}
                        className="border border-orange-400 object-cover rounded-full h-32 w-32"
                    />

                    <div className="absolute inset-0 flex items-center justify-center">
                        <div
                            className="inline-block h-[22px] w-[22px] animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-success motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
                        </div>
                    </div>
                </section>
            ) : (
                <img
                    src={props.avatar?.url}
                    className={`${
                        props?.removeBorder ? '' : `border border-orange-400`
                    }  object-cover rounded-full h-32 w-32`}
                />
            )}
            <div className="update-profile-pic" onClick={() => setShowProfilePicModal(true)}>
                <img src={cameraicon} className="fill-orange-300" alt="" />
            </div>
            <Transition appear show={showProfilePicModal} as={Fragment}>
                <Dialog
                    onClose={() => setShowProfilePicModal(false)}
                    as="div"
                    className="fixed inset-0 z-50  overflow-y-auto max-w-7xl mx-auto"
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed bg-black bg-opacity-30 inset-0 " />
                    </Transition.Child>
                    <span
                        className="inline-block h-screen align-middle bg-slate-500"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="sticky border bottom-0 px-6 py-4 bg-white  h-min rounded-t-3xl">
                            <div className=" ">
                                <Dialog.Title
                                    as="h1"
                                    className={
                                        'text-[#242F35] font-roboto font-medium text-lg text-left  w-full'
                                    }
                                >
                                    Profile photo
                                </Dialog.Title>
                                <section className="flex flex-col justify-center items-center">
                                    <div>
                                        <label htmlFor="avatar-select">
                                            <div className="">
                                                <img
                                                    src={GallaryIcon}
                                                    alt=""
                                                    className="w-12 object-contain  cursor-pointer rounded-full"
                                                />
                                            </div>
                                        </label>
                                        <input
                                            id="avatar-select"
                                            className="hidden"
                                            type="file"
                                            name="avatar_file"
                                            onChange={handleAvatar}
                                            accept="image/png, image/gif, image/jpeg"
                                        />
                                    </div>
                                    <div className="text-left text-[#151E26C2]">Gallery</div>
                                </section>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </div>
    );
}

export default ProfilePic;
