import './TextInputForm.css';
import { useEffect, useRef, useState } from 'react';

function TextInputForm(props: {
    heading: string;
    placeholder?: string;
    setter: any;
    value: string;
    errorMessage?: string;
    pattern?: string;
    keys?: number;
    optionAction?: () => void;
    optionLabel?: string;
    PreValue?: string;
    maxLen?: number;
    ForName?: boolean;
    autoFocusedEnable?: boolean;
}) {
    const [formVal, setFormVal] = useState(props.PreValue || '');
    const InputRef: any = useRef(null);
    // const [focused, setfocused] = useState(false);

    useEffect(() => {
        if (props?.autoFocusedEnable) setTimeout(() => InputRef?.current?.focus(), 100);
    }, [props?.autoFocusedEnable]);

    useEffect(() => {
        const detailsData = {} as any;
        detailsData[props.value] = formVal;
        props.setter(detailsData);
    }, [formVal]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let txt = event.target.value;

        if (props?.ForName) {
            //eslint-disable-line
            if (/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\0-9]/g.test(txt)) return;
            txt = txt.trimStart();
        }

        setFormVal(txt);
    };

    const handlefocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        // setfocused(true);
    };

    const showOptionLabel = props.keys && props.keys > 1 && props.optionAction ? true : false;
    return (
        <div className="text-input-form-container">
            <div className="flex justify-between">
                {props.heading}
                {showOptionLabel && (
                    <span onClick={props.optionAction} className="text-red-400 text-sm underline">
                        {props.optionLabel}
                    </span>
                )}
            </div>
            <input
                ref={InputRef}
                value={formVal}
                onChange={(e) => {
                    //
                    handleChange(e);
                }}
                placeholder={props.placeholder}
                maxLength={props?.maxLen ?? 256}
                type="text"
                pattern={props.pattern}
                required
                // autoFocus={props?.autoFocusedEnable ?? false}
                className=" text-input-form-form border-[0.5px] border-[#C4C4C4] rounded-md"
                onBlur={(e) => handlefocus(e)}
                // focused={focused.toString()}
            />
            <span className="text-red-600 errormsg mt-0 text-sm pt-0">{props.errorMessage}</span>
        </div>
    );
}
export default TextInputForm;
