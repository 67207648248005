import ReactGA from 'react-ga';
declare global {
    interface Window {
        dataLayer: any;
    }
}
function pushEventToGtm(eventName: string, eventParams?: any) {
    let event: { event: string } | typeof eventParams = {};

    if (window.location.hostname.toLowerCase() !== 'www.parentune.com') return;
    if (typeof eventParams != 'undefined') {
        Object.keys(eventParams).map((key) => ReactGA.set({ key: eventParams[key] }));
        ReactGA.event({
            category: 'User',
            action: eventName
        });

        event['event'] = eventName;
    } else {
        event = { event: eventName };
    }

    if (eventName != 'page_view') {
        ReactGA.set;
    }
}
export default pushEventToGtm;
