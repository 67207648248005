import { classNames } from '../../../utils/utils';
import * as React from 'react';
import './btnLg.css';

type BtnLgType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyle?: React.CSSProperties;
};

const BtnLg = (props: BtnLgType) => {
    const { size, text, className, onClick, disabled = false, customStyle } = props;

    return (
        <div className="flex justify-center items-center w-full">
            <button
                style={customStyle}
                type="button"
                onClick={onClick}
                disabled={disabled}
                className="secondary-cta-lg !w-full  pt-track-click"
                data-ptname={`baby-name-btn-${props.text}`}
            >
                {text}
            </button>
        </div>
    );
};
export default BtnLg;
