function GirlIcon(props) {
    return (
        <svg
            width={14}
            height={26}
            viewBox="0 0 14 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.602.723c-1.69 0-3.38.641-4.669 1.93a6.604 6.604 0 000 9.337 6.577 6.577 0 003.818 1.871v4.592H.13v1.701H5.75v5.06h1.701v-5.06h5.621v-1.7H7.452V13.86a6.575 6.575 0 003.818-1.87 6.604 6.604 0 000-9.338A6.579 6.579 0 006.602.723zm0 1.777a4.82 4.82 0 013.41 8.232 4.82 4.82 0 01-6.82 0A4.82 4.82 0 016.602 2.5z"
                fill="#000"
            />
        </svg>
    );
}

export default GirlIcon;
