// @ts-nocheck
import { useState, useEffect } from 'react';
import './AskQuestionExpertCopy.css';
import { lazyWithRetry } from '../../../utils/LazyWithRetry';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../../utils/hooks/useUserData';

import Next from './NextButton';
import AskQuestion from './AskQuestion copy';
import { useSearchParams } from 'react-router-dom';
import Attributes from '../../../api/askDoctor/Attributes';
import StatusBar from './StatusBar';
import AskedQuestionFunction from '../../../api/askDoctor/AskedQuestion';
import Overlay from '../../Overlay/Overlay';

import { Geti18nLang } from '../../../utils/i18n utils/Geti18nLang';
import axios from 'axios';
import ZohoChat from '../../ZohoChat/ZohoChat';
import { SendEvents } from '../../../utils/utils';
const Header = lazyWithRetry(() => import('../../Header/Header'));
// const pushEventToGtm = lazyWithRetry(() => import('../../../utils/pushEventToGtm'));
const ButtonSecondry1 = lazyWithRetry(() => import('../../V2Components/ButtonSecondry/index1'));
const ButtonPrimary = lazyWithRetry(() => import('../../V2Components/ButtonPrimary'));
const BreadCrumbs = lazyWithRetry(() => import('../../V2Components/BreadCrumbs'));
const REACT_APP_ASKEXPERTS_SERVER = process.env.REACT_APP_ASKEXPERTS_SERVER;
const instant = 'https://img1.parentune.com/web/assests_v2/instant.webp';
const SelectedLanguage = Geti18nLang();
function AskQuestionExpertCopy() {
    //pushEventToGtm('pageview', { pt_page: 'ask-expert' });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setshow] = useState('');
    const [show1, setshow1] = useState('');
    const [nav, setNav] = useState(1);
    const [activeDropdown, setActiveDropdown] = useState(1);
    const [active1, setActive1] = useState(false);
    const [col, setcol] = useState('#367E8A');
    const [col1, setcol1] = useState('white');
    const { userData: user_data } = useUserData();
    const [isLoading, setIsLoading] = useState(false);
    const trans = t('Choose_Age_Group');
    const [value, setValue] = useState(trans);
    // const trans1 = t('Choose_an_Expert');

    const [value1, setValue1] = useState(t('Choose an interest'));
    const [postLocation, setPostLocation] = useState('Post your question');
    const [file, setFile] = useState([]);
    const [anonymous, setAnonymous] = useState(false);
    const [parentQuestionId, setParentQuestionId] = useState(null);
    const [irCheck, SetIrCheck] = useState(false);
    const [questionId, setQuestionId] = useState();

    const [req, setReq] = useState({
        title: '',
        ageGroupId: 0,
        topicId: 0,
        userId: user_data && user_data.userId,
        authToken: user_data && user_data.authToken,
        isNearby: 0,
        isAnonymous: 0,
        latitude: null,
        longitude: null
    });
    const [specializations, setSpecialization] = useState([]);
    const [interest, setInterest] = useState({
        Health: [
            {
                interest_id: 22,
                interest_name: 'Vaccination ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 24,
                interest_name: 'Fetal Development ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 26,
                interest_name: 'Disease management & Selfcare ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 28,
                interest_name: 'Daily Tips ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 30,
                interest_name: 'Developmental Milestones',
                agegroup_id: 2,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 32,
                interest_name: 'Breastfeeding',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 34,
                interest_name: 'Speech & Hearing',
                agegroup_id: 2,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 36,
                interest_name: 'Eyecare & Vision ',
                agegroup_id: 2,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 100,
                interest_name: 'Immunity',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 102,
                interest_name: 'Dental care',
                agegroup_id: 2,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 104,
                interest_name: 'Colic & Digestion',
                agegroup_id: 2,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 23,
                interest_name: 'Tests ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 27,
                interest_name: 'Pregnancy by week',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 31,
                interest_name: 'Physical Development ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 35,
                interest_name: 'Hormonal Changes',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 21,
                interest_name: 'High risk Pregnancy',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 29,
                interest_name: 'Home Remedies',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 37,
                interest_name: 'Corona virus',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 33,
                interest_name: 'Weight',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            },
            {
                interest_id: 25,
                interest_name: 'Birthing - delivery ',
                agegroup_id: 1,
                group_name: 'Health',
                isUserInterest: false
            }
        ],
        Nutrition: [
            {
                interest_id: 39,
                interest_name: 'Nutritious foods',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 40,
                interest_name: 'Foods to Avoid',
                agegroup_id: 2,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 42,
                interest_name: 'Food habits ',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 44,
                interest_name: 'Fussy eater ',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 46,
                interest_name: 'Recipes',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 41,
                interest_name: 'Daily Tips ',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 45,
                interest_name: 'Food for Growth',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 43,
                interest_name: 'Food plan ',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            },
            {
                interest_id: 38,
                interest_name: 'Caloric Recommendations',
                agegroup_id: 1,
                group_name: 'Nutrition',
                isUserInterest: false
            }
        ]
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const previousQuestionuestionId = searchParams.get('previousQuestionuestionId');
    const specialization = searchParams.get('specialization');
    // console.log(specializations);
    const age = searchParams.get('age');
    const ageID = searchParams.get('ageGroupID');
    const followUp = searchParams.get('followUp');
    const confrim = searchParams.get('confrim');
    const [imagePrivacy, setImagePrivacy] = useState(false);
    const [location, setLocation] = useState<any>({ latitude: null, longitude: null });

    useEffect(() => {
        FetchInterest();
    }, []);

    useEffect(() => {
        if (nav === 2 && activeDropdown === 3) {
            if (!location.latitude || !location.longitude) {
                // Check if latitude or longitude is missing
                requestLocation();
            } else {
                // @ts-ignore
                setReq((s) => {
                    return {
                        ...s,
                        latitude: location.latitude,
                        longitude: location.longitude
                    };
                });
            }
        }
    }, [location, nav, activeDropdown]);

    const requestLocation = () => {
        if ('geolocation' in navigator) {
            // Check if location permission is already granted
            navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
                if (permissionStatus.state === 'granted') {
                    // Permission already granted, get the location
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
                            setLocation({ latitude, longitude });
                        },
                        (error) => {
                            console.error('Error getting location:', error);
                        }
                    );
                } else if (permissionStatus.state === 'prompt') {
                    // Permission not yet granted, prompt the user for permission

                    // Attach an event listener to a button or UI element that triggers permission
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            console.log('recived location from user');
                            const { latitude, longitude } = position.coords;
                            setLocation({ latitude, longitude });
                        },
                        (error) => {
                            console.error('Error getting location:', error);
                            // if (error.code === 1)
                            //     alert(
                            //         "You have denied permisson location, either enable it and reload or you won't be able ask NearBy questions"
                            //     );
                        }
                    );
                    // } else if (permissionStatus.state === 'denied') {
                    //     alert(
                    //         "You have denied permisson location, either enable it and reload or you won't be able ask NearBy questions"
                    //     );
                }
            });
        } else {
            console.error('Geolocation is not available in your browser');
        }
    };

    useEffect(() => {
        if (nav !== 1 && req.ageGroupId !== 0 && req.topicId != 0) {
            setActive1(true);
        }
    }, [req]);
    useEffect(() => {
        if (nav === 2 && req.ageGroupId === 0 && req.topicId === 0) {
            setActive1(false);
        }
    }, [nav]);

    const { status, data } = Attributes(user_data);

    const checkLang = (lang: any) => {
        if (lang === 'en') {
            return false;
        }
        return true;
    };

    const FetchInterest = async () => {
        console.log('fetching interrest 188');
        const res = await axios.get('https://api9.parentune.com/user/v1/interests', {
            params: {},
            headers: {
                lang: SelectedLanguage,
                userId: user_data ? user_data.userId?.toString() : '',
                authToken: user_data ? user_data.authToken : '',
                instanceId: user_data ? user_data.instanceId : ''
            }
        });
        console.log('198', res.data);
        // @ts-ignore
        if (res?.data?.statusCode !== 200) return;

        setInterest(res.data?.data);
    };

    useEffect(() => {
        if (data) {
            setSpecialization(data.specializations);
        }
    }, [status, data]);

    useEffect(() => {
        // @ts-ignore
        setReq((s) => {
            return {
                ...s,
                isAnonymous: anonymous ? 1 : 0
            };
        });
    }, [anonymous]);

    const ageGroup = [
        { id: 1, name: 'Pregnancy' },
        { id: 2, name: '0 to 1 years' },
        { id: 3, name: '1 to 3 years' },
        { id: 4, name: '3 to 7 years' },
        { id: 5, name: '7 to 11 years' }
    ];

    const postQuestionLocation = location.latitude
        ? [
              {
                  id: 0,
                  name: 'Entire Parent network',
                  img: 'https://img1.parentune.com/images/logo/ParentuneFavicon.png'
              },
              {
                  id: 1,
                  name: 'Parents near you',
                  img: 'https://img1.parentune.com/images/icons/location.svg'
              }
          ]
        : [
              {
                  id: 0,
                  name: 'Entire Parent network',
                  img: 'https://img1.parentune.com/images/logo/ParentuneFavicon.png'
              }
          ];

    const onSuccesCallAPi = (data: any) => {
        console.log('onSuccesCallAPi', data);
        const { talkId } = data.data;
        setIsLoading(false);

        if (!talkId) return;
        let url = '';
        checkLang(SelectedLanguage) ? (url = `/${SelectedLanguage}`) : (url = '');

        return navigate(`${url}/parent-talk/details/${talkId}`);
    };

    const onAPIfail = (e: any) => {
        let url = '';
        checkLang(SelectedLanguage) ? (url = `/${SelectedLanguage}`) : (url = '');
        console.log('onAPIfail', e);
        alert('Opps something went wrong');
        navigate('/');
    };

    const handleSubmit = () => {
        setIsLoading(true);
        sessionStorage.removeItem('pt_user_data');
        const formData = new FormData();
        file?.forEach((file, i) => {
            formData.append(`image${i + 1}`, file);
        });
        formData.append('title', req.title);
        if (parentQuestionId !== null) {
            formData.append('parentId', parentQuestionId);
            localStorage.removeItem('parent-question-id');
        }

        formData.append('isAnonymous', req.isAnonymous.toString());
        formData.append('ageGroupId', req.ageGroupId.toString());
        formData.append('topicId', req.topicId.toString());
        formData.append('isNearby', req.isNearby.toString());
        if (req.isNearby === 1 && req.latitude) {
            // @ts-ignore
            formData.append('latitude', req?.latitude.toString());
            // @ts-ignore
            formData.append('longitude', req?.longitude.toString());
        }

        AskedQuestionFunction(formData, user_data, onSuccesCallAPi, onAPIfail, true);
    };

    const nextButtonHandler = () => {
        active1 && nav !== 3 ? setNav((s) => s + 1) : handleSubmit();
    };

    const nav2 = () => {
        return (
            <>
                <div className="asqenav2  " style={{ backgroundColor: `${show}` }}>
                    <div className="asqenav2i !relative">
                        <div
                            onClick={() => setNav(1)}
                            style={{
                                padding: '20px',
                                position: 'relative',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            className="aqedds arrow  "
                        >
                            {/* {req.question.length > 20 && (
                                        <h1>{req.question.slice(0, 20)} ...</h1>
                                    )}
                                    {req.question.length <= 20 && (
                                        <h1>{req.question.slice(0, 20)}</h1>
                                    )} */}
                            <h1>{t('Start a talk')}</h1>
                        </div>
                    </div>

                    <div className="asqenav2i">
                        <div className="aqedds check-2 mb-5">
                            <div
                                onClick={() => {
                                    setActiveDropdown((pre) => (pre === 1 ? 0 : 1));
                                }}
                                className={activeDropdown !== 1 ? 'aqedds2 ' : 'aqedds21'}
                            >
                                {value1}
                            </div>
                            {activeDropdown == 1 && (
                                <div className="abcd px-5 text-left">
                                    {/* {interest?.map((d: any, i) => (
                                        <>
                                            <div
                                                key={d.id}
                                                onClick={() => {
                                                    console.log('first');
                                                    setActiveDropdown(2);
                                                    if (value1 !== d?.name) {
                                                        setValue1(d?.name);
                                                        setReq((s) => {
                                                            return {
                                                                ...s,
                                                                topicId: d?.id
                                                            };
                                                        });
                                                    } else {
                                                        setValue1(d?.name);
                                                        setReq((s: any) => {
                                                            return {
                                                                ...s,
                                                                topicId: d.id
                                                            };
                                                        });
                                                    }
                                                }}
                                                className={`${
                                                    value1 === d.name
                                                        ? 'mx-2 bg-gradient-to-r from-[#FFEED9] to-[#FFE0E3]'
                                                        : ''
                                                } font-roboto`}>
                                                <span
                                                    className={`text-[#6F6D6D] text-[1rem] font-roboto font-[400] leading-[26px]`}>
                                                    {d.name}
                                                </span>
                                            </div>
                                            <hr
                                                style={{
                                                    border: '0.5px dashed #367E8A'
                                                }}></hr>
                                        </>
                                    ))} */}
                                    {/* @ts-ignore */}
                                    <div>
                                        {Object.keys(interest).map((category, i) => (
                                            <div key={i} className="p-2">
                                                <div className="font-bold">{category}</div>
                                                {interest[category].map((interest, j) => (
                                                    <p key={j}>
                                                        <div
                                                            key={interest.interest_id}
                                                            onClick={() => {
                                                                setActiveDropdown(2);
                                                                SendEvents(
                                                                    user_data?.userid ??
                                                                        DefaultUser,
                                                                    'btn-click',
                                                                    `btn_item_interest`,
                                                                    interest?.intereset_id
                                                                );

                                                                if (
                                                                    value1 !==
                                                                    interest.interest_name
                                                                ) {
                                                                    setValue1(
                                                                        interest.interest_name
                                                                    );
                                                                    setReq((s) => ({
                                                                        ...s,
                                                                        topicId:
                                                                            interest.interest_id
                                                                    }));
                                                                } else {
                                                                    setValue1(
                                                                        interest.interest_name
                                                                    );
                                                                    setReq((s) => ({
                                                                        ...s,
                                                                        topicId:
                                                                            interest.interest_id
                                                                    }));
                                                                }
                                                            }}
                                                            className={`${
                                                                value1 === interest.interest_name
                                                                    ? ' bg-gradient-to-r from-[#FFEED9] to-[#FFE0E3]'
                                                                    : ''
                                                            } font-roboto py-2 hover:bg-gradient-to-r from-[#FFEED9] to-[#FFE0E3]`}
                                                        >
                                                            <span className="text-[#6F6D6D] text-[1rem] font-roboto font-[400] leading-[26px]">
                                                                {interest.interest_name}
                                                            </span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                border: '0.5px dashed #367E8A'
                                                            }}
                                                        ></div>
                                                    </p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="aqedds check-3 mb-5">
                            <div
                                onClick={() => {
                                    setActiveDropdown((pre) => (pre === 2 ? 0 : 2));
                                }}
                                className={activeDropdown !== 2 ? 'aqedds2 ' : 'aqedds21'}
                            >
                                {value}
                            </div>
                            {activeDropdown == 2 && (
                                <div className="aqedds1 text-left pb-2  ">
                                    {ageGroup.map((d, i) => (
                                        <>
                                            <div
                                                className={`${
                                                    value === d.name
                                                        ? 'mx-2 bg-gradient-to-r from-[#FFEED9] to-[#FFE0E3]'
                                                        : ''
                                                } font-roboto`}
                                                key={i}
                                                onClick={() => {
                                                    SendEvents(
                                                        user_data?.id ?? DefaultUser,
                                                        'btn-click',
                                                        `btn_item_age_group`,
                                                        d?.id
                                                    );

                                                    setActiveDropdown(3);
                                                    if (value !== d.name) {
                                                        setValue(d.name);
                                                        setReq((s) => {
                                                            return {
                                                                ...s,
                                                                ageGroupId: d.id
                                                            };
                                                        });
                                                    } else {
                                                        setValue(d.name);
                                                        setReq((s) => {
                                                            return {
                                                                ...s,
                                                                ageGroupId: d.id
                                                            };
                                                        });
                                                    }
                                                }}
                                            >
                                                <span className="text-[#6F6D6D] text-[1rem] font-roboto font-[400] leading-[26px]">
                                                    {d.name}
                                                </span>
                                            </div>
                                            <hr
                                                style={{
                                                    border: '0.5px dashed #367E8A'
                                                }}
                                            ></hr>
                                        </>
                                    ))}
                                </div>
                            )}
                        </div>{' '}
                        <div className="aqedds check-3 mb-20">
                            <div
                                onClick={() => {
                                    setActiveDropdown((pre) => (pre === 3 ? 0 : 3));
                                }}
                                className={activeDropdown !== 3 ? 'aqedds2 ' : 'aqedds21'}
                            >
                                {t(postLocation)}
                            </div>
                            {activeDropdown == 3 && (
                                <div className="aqedds1 text-left pb-2  ">
                                    {postQuestionLocation.map((d, i) => (
                                        <>
                                            <div
                                                className={`${
                                                    postLocation === d.name
                                                        ? 'mx-2 bg-gradient-to-r from-[#FFEED9] to-[#FFE0E3]'
                                                        : ''
                                                } font-roboto`}
                                                key={i}
                                                onClick={() => {
                                                    setActiveDropdown(0);
                                                    SendEvents(
                                                        user_data?.id ?? DefaultUser,
                                                        'btn-click',
                                                        `${
                                                            d?.id == 0
                                                                ? 'btn_entire_parentune_network'
                                                                : 'btn_parents_near_you'
                                                        }`
                                                    );
                                                    if (postLocation !== d.name) {
                                                        setPostLocation(d.name);
                                                        setReq((s) => {
                                                            return {
                                                                ...s,
                                                                isNearby: d.id
                                                            };
                                                        });
                                                    } else {
                                                        setPostLocation(d.name);
                                                        setReq((s) => {
                                                            return {
                                                                ...s,
                                                                isNearby: d.id
                                                            };
                                                        });
                                                    }
                                                }}
                                            >
                                                <div className="flex justify-between !p-0">
                                                    {' '}
                                                    <span className="text-[#6F6D6D] text-[1rem] font-roboto font-[400] leading-[26px]">
                                                        {d.name}
                                                    </span>
                                                    <img src={d.img} className="w-6 h-6" />
                                                </div>
                                            </div>
                                            <hr
                                                style={{
                                                    border: '0.5px dashed #367E8A'
                                                }}
                                            ></hr>
                                        </>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="max-w-[800px] m-auto">
            <div style={{ backgroundColor: `${show}` }}>
                <div>
                    <div className=" flex justify-between items-center ">
                        <Header
                            back
                            onBack={() => (nav === 1 ? navigate(-1) : setNav((s) => s - 1))}
                            headerText={t('Start a talk')}
                        />
                        <a
                            href="javascript:window.ZohoHCAsap.Actions.Open()"
                            className="text-[#367E8A] font-roboto text-[16px] mr-[30px]"
                        >
                            {t('GET HELP')}
                        </a>
                    </div>

                    <StatusBar nav={nav} />
                    {nav === 1 ? (
                        <AskQuestion
                            setActive={setActive1}
                            setAnonymous={setAnonymous}
                            data={{
                                avatar: user_data?.avatar,
                                parentName: user_data?.name
                            }}
                            req={req}
                            setter={(x: any) =>
                                setReq((s) => {
                                    return { ...s, title: x };
                                })
                            }
                            setParentQuestionId={setParentQuestionId}
                            setNav={setNav}
                        />
                    ) : null}
                    {nav === 2 || nav === 3 ? nav2() : null}
                    <div
                        className={`justify-between p-4 pt-5 `}
                        style={{
                            backgroundColor: 'white',
                            maxWidth: '1400px',
                            width: '100%'
                        }}
                    >
                        <Next
                            isLoading={isLoading}
                            onclick={() => {
                                if (nav === 1) {
                                    SendEvents(
                                        user_data?.userId,
                                        'btn-click',
                                        'btn_next_step_last_talk'
                                    );
                                } else if (nav === 3) {
                                    SendEvents(user_data?.userId, 'btn-click', 'btn_post_talk');
                                }

                                nextButtonHandler();
                            }}
                            show1={show1}
                            req={req}
                            file={file}
                            setFile={setFile}
                            setImagePrivacy={setImagePrivacy}
                            active1={active1}
                            photo={nav === 3}
                            content={nav === 3 ? 'POST' : t('Next')}
                            col={col}
                            disabled={!active1}
                            col1={col1}
                            setIsLoading={setIsLoading}
                            nav={nav}
                            setNav={setNav}
                            isForTalk={true}
                        />
                    </div>
                </div>

                {irCheck && (
                    <Overlay>
                        <div className="w-80 h-80 items-center bg-white p-5 justify-center right-0  m-auto left-0 text-center top-5 bottom-0 rounded">
                            <div className=" text-center justify-center">
                                <img className="ml-auto mr-auto" src={instant} loading="lazy"></img>
                                <h1 className="text-black text-[18px] leading-[21.9px] font-500 mt-[12px] font-bold">
                                    {t('Instant')}
                                </h1>
                            </div>
                            <h1 className="mt-6 text-black  leading-[26px]  text-[1rem] font-500  ">
                                {t(
                                    'Your_question_has_been_answered_with_an_Automated_Expert_Response'
                                )}
                            </h1>
                            <p className="font-normal text-[#6F6D6D] text-[14px] leading-[26px] mt-5">
                                {t('Please_share_your_feedback_with_us')}
                            </p>
                            <div className=" my-[24px]">
                                <ButtonSecondry1
                                    onClick={() => {
                                        let url = '';
                                        checkLang(SelectedLanguage)
                                            ? (url = `/${SelectedLanguage}`)
                                            : (url = '');
                                        if (previousQuestionuestionId) {
                                            navigate(
                                                `${url}/expert-corner/detail/${previousQuestionuestionId}`
                                            );
                                        } else {
                                            navigate(`${url}/expert-corner/detail/${questionId}`);
                                        }

                                        SetIrCheck(false);
                                    }}
                                    text={t('VIEW ANSWER')}
                                    className="text-[#787878] leading-[16px] font-[500] border-[#787878]"
                                />
                            </div>
                        </div>
                    </Overlay>
                )}
            </div>
            <ZohoChat />
        </div>
    );
}

export default AskQuestionExpertCopy;
