import { DoctorIcon, VideoIcon, UnlockIcon } from '../../assets/icons/giftIcons/Icons';

export const Constants = {
    RELIGION: {
        HINDU: 'hindu',
        MUSLIM: 'muslim',
        CRISTION: 'christian',
        SIKH: 'sikh'
    },
    GENDER: {
        BOY: 'boy',
        GIRL: 'girl'
    },
    GENDER_2: {
        BOY: 'boy-names',
        GIRL: 'girl-names'
    },
    PAGE_TYPE: {
        NAME_DETAIL: 1
    },
    RELIGION_LETTER: {
        HINDU_NAMES: 'hindu-names-starting-with',
        MUSLIM_NAMES: 'muslim-names-starting-with',
        SIKH_NAMES: 'sikh-names-starting-with',
        CHRISTIAN_NAMES: 'christian-names-starting-with'
    },
    WITH_LETTER: {
        STARTING_WITH: 'starting-with',
        STARTING_LETTER: 'starting-letter'
    },
    MODAL_TYPE: {
        REPLY: 1,
        SUPPORT: 2,
        BOOKMARK: 3,
        COMMENT: 4
    },
    BREAK_POINTS: {
        SM: 640,
        MD: 768,
        LG: 1024,
        XL: 1280
    },
    DEVICE: {
        MOBILE: 1,
        DESKTOP: 2
    },
    STREAK_STATUS: {
        MISSED: false,
        COMPLETED: true
    },
    DAILY_FOCUS_CONTENT_TYPE: {
        BLOG: 'blog',
        WORKSHOP: 'workshop',
        TALK: 'talk',
        ASK_DOCTOR: 'question',
        STORIES: 'story',
        DOCTOR_CARD: 'mandatoryQuestionCard',
        MILESTONE_CARD: 'daily_milestone'
    }
};

export function formatDate(dateString) {
    const date = new Date(dateString || '');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export const getBenefitFeature = (type) => {
    switch (type) {
        case 'PLUS':
            return [
                {
                    title: 'Ask Doctors Anytime - 24 x 7',
                    description: 'Chat with Child Specialists, get verified & prompt answers. ',
                    icon: <DoctorIcon />
                },
                {
                    title: 'Learn Important Concepts ',
                    description: 'Unlimited access, comprehensive understanding ',
                    icon: <VideoIcon />
                },
                {
                    title: 'Unlock All Daily Focus',
                    description: 'Follow the Daily Good Habit & unlock all activities ',
                    icon: <UnlockIcon />
                }
            ];
    }
};
