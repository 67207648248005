import { XIcon } from '@heroicons/react/outline';
const img1 = 'https://img1.parentune.com/web/assets/images/rating.svg';
const OverlayTestimonal = ({ data, setClose }: { data: any; setClose: () => void }) => {
    return (
        <>
            <div
                className="hover:scale-[1.02] duration-200 ease-in-out h-auto overflow-hidden  m-auto pb-2 relative"
                style={{
                    border: '0.5px solid #E5E5E5',
                    boxShadow: '0px 9.00746px 15.0124px rgba(196, 196, 196, 0.2)',
                    borderRadius: '4.00332px',

                    backgroundColor: 'white',
                    padding: '20px',

                    margin: '20px 20px 20px 0'
                }}
            >
                <XIcon
                    onClick={setClose}
                    className="h-6 w-8 cursor-pointer text-black absolute right-0 top-0"
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <img
                        style={{
                            width: '36px',
                            height: '36px',
                            borderRadius: '5px'
                        }}
                        src={data?.avatar}
                        alt=""
                    />
                    <div
                        style={{
                            marginLeft: '20px'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: '500'
                            }}
                        >
                            {data?.userName}
                        </div>
                        <div>
                            <img
                                style={{
                                    width: '50px'
                                }}
                                src={img1}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="">{data?.text}</div>
            </div>
        </>
    );
};

export default OverlayTestimonal;
