import { useState } from 'react';
import done from '../../assets/landing/done.svg';

const WhoCourseFor = (props: any) => {
    const { course_for_data } = props;

    const [data, setData] = useState<any>(course_for_data);
    // const data = [
    //     {
    //         id: 1,
    //         text: 'Pregnancy & 0-1 yrs',
    //         bg: false
    //     },
    //     {
    //         id: 2,
    //         text: 'Grand Parents',
    //         bg: true
    //     },
    //     {
    //         id: 3,
    //         text: 'Teachers',
    //         bg: false
    //     },
    //     {
    //         id: 4,
    //         text: 'Parents',
    //         bg: true
    //     },
    //     {
    //         id: 5,
    //         text: 'Guardians',
    //         bg: false
    //     },
    //     {
    //         id: 6,
    //         text: 'Caregivers',
    //         bg: true
    //     }
    // ];

    return (
        <div className="mt-[41px] mx-5">
            <h2 className="text-center text-[#262626] text-[21px] font-medium">
                Who is this course for?
            </h2>
            <div className="mt-[40px]">
                {data.map((item: any, index: number) => (
                    <div
                        key={index}
                        className={`flex justify-between items-center h-[53px] px-[6px] even:bg-[#fff9eb] even:border-y-[#FE992F66] even:border-y`}
                    >
                        <h2 className="text-[#242F35] text-[12px] font-normal">{item}</h2>
                        <span className="w-[16.26px] h-[16.26px] rounded-full flex justify-center items-center bg-[#FE992F]">
                            <img src={done} alt={item} />
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhoCourseFor;
