import React, { useRef, useState } from 'react';
import PaswordViewIcon from '../../../assets/icons/PasswordViewIcon';
import './inputBox.css';
// import PaswordViewIcon from '../../../assets/icons/PasswordViewIcon.svg';
type InputBoxType = {
    Icon?: (props: React.ComponentProps<'svg'> | any) => JSX.Element;
    label?: string;
    placeholder: string;
    input: string;
    setInput: React.Dispatch<React.SetStateAction<string>>;
    type: React.HTMLInputTypeAttribute;
    labelClass?: string;
    name: string;
    isSelect?: boolean;
    onClick?: () => void;
    optionLabel?: string;
    optionAction?: () => void;
    onNext?: () => void;
    PasswordIcon?: boolean;
    inValidPassword?: boolean;
    maxLength?: number;
};

const InputBox = (props: InputBoxType) => {
    const {
        Icon,
        label,
        placeholder,
        input,
        setInput,
        type,
        name,
        isSelect = false,
        onClick,
        optionLabel,
        optionAction,
        onNext
    } = props;
    const InputBoxRef = useRef(null);
    const [inputType, setInputType] = useState(type);

    return (
        <div className="transition-all translate-y-2">
            <label
                htmlFor={label}
                className={
                    props.labelClass
                        ? props.labelClass
                        : 'text-sm font-normal text-[#242F35] font-roboto flex justify-between'
                }
            >
                {label}
                {optionLabel && (
                    <span onClick={optionAction} className="text-[#787878] text-sm ">
                        {optionLabel}
                    </span>
                )}
            </label>
            <div onClick={onClick} className="mt-1 relative rounded-md shadow-sm border">
                <input
                    ref={InputBoxRef}
                    type={isSelect ? 'button' : inputType}
                    name={name}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className={`relative block text-left w-full  border-red-300 px-4 py-2 text-black focus:outline-none focus:border-cyan-600  sm:text-sm rounded-md h-[55px] font-roboto ${
                        props?.PasswordIcon ? 'pr-12' : 'pr-10'
                    } ${props?.inValidPassword ? 'text-[#EB5757] error' : ''}`}
                    placeholder={placeholder}
                    maxLength={props?.maxLength ?? 100}
                    onKeyDown={(e) => onNext && e.key === 'Enter' && onNext()}
                />
                {Icon && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <Icon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                    </div>
                )}
                {props?.PasswordIcon && (
                    <div
                        // @ts-ignore
                        onClick={() =>
                            setInputType((prevType) =>
                                prevType === 'password' ? 'text' : 'password'
                            )
                        }
                        className="absolute  right-2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
                    "
                    >
                        <PaswordViewIcon enabled={inputType == 'text'} />
                    </div>
                )}
            </div>
            {props?.inValidPassword && (
                <p className="text-[#EB5757] mt-1 font-roboto font-light text-sm">
                    Incorrect {type === 'email' ? 'Email' : 'Password'}
                </p>
            )}

            {/* <p className="mt-0.5 text-sm text-red-600" id="email-error">
				Your password must be less than 4 characters.
			</p> */}
        </div>
    );
};

export default InputBox;
