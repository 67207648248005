import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import InnerGalary from './InnerGalary';
const intro = [
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2011_12.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2013_14.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2015.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2016-17.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2018.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2019.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2020.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2021.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder2/gallery_images_2022.jpg'
    }
];
const intro1 = [
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2011_12.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2013_14.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2015.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2016_17.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2018.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2019.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2020.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2021.jpg'
    },
    {
        url: 'https://img1.parentune.com/images/about_us_images/folder3/detailed_gallery_2022.jpg'
    }
];
const Gallery = () => {
    const galleryItems = [];
    const [imageIndex, setImageIndex] = useState(-1);
    const navigate = useNavigate();

    return (
        <div className="max-w-[1000px] mx-auto w-full">
            <Header back onBack={() => navigate(-1)} headerText={'Gallery'} />
            <div>
                <img onClick={() => setImageIndex(0)} className="w-full" src={intro[0].url} />
                <div className="flex w-full justify-between mt-2">
                    <img onClick={() => setImageIndex(1)} className="w-1/2" src={intro[1].url} />
                    <img onClick={() => setImageIndex(2)} className="w-1/2" src={intro[2].url} />
                </div>
                <img onClick={() => setImageIndex(3)} className="w-full" src={intro[3].url} />
                <div className="flex w-full justify-between mt-2">
                    <img onClick={() => setImageIndex(4)} className="w-1/2" src={intro[4].url} />
                    <img onClick={() => setImageIndex(5)} className="w-1/2" src={intro[5].url} />
                </div>
                <img onClick={() => setImageIndex(6)} className="w-full" src={intro[6].url} />
                <div className="flex w-full justify-between mt-2">
                    <img onClick={() => setImageIndex(7)} className="w-1/2" src={intro[7].url} />
                    <img onClick={() => setImageIndex(8)} className="w-1/2" src={intro[8].url} />
                </div>
            </div>
            {imageIndex !== -1 && (
                <InnerGalary
                    setImageIndex={setImageIndex}
                    imageIndex={imageIndex}
                    images={intro1}
                />
            )}
        </div>
    );
};

export default Gallery;
