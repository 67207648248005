import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
// import i18n from '../../i18n/config';
import BtnLg from '../Button/BtnLg/BtnLg';
import TextViewSecond from '../Button/TextViewSecond/TextViewSecond';
import ButtonPrimary from '../V2Components/ButtonPrimary';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
const SelectedLanguage = Geti18nLang();

interface filterprops {
    two: boolean;
    handleclick: () => void;
    setstartLength: React.Dispatch<React.SetStateAction<string>>;
    setfixedLength: React.Dispatch<React.SetStateAction<string>>;
    setendLength: React.Dispatch<React.SetStateAction<string>>;
    setchar2: React.Dispatch<React.SetStateAction<string>>;
    charr: string;
    char2?: string;
    setcharr?: React.Dispatch<React.SetStateAction<string>>;
    show?: boolean;
    setBtnIdd?: React.Dispatch<React.SetStateAction<string>>;
    setBtnId?: React.Dispatch<React.SetStateAction<string>>;
    setBtnId1?: React.Dispatch<React.SetStateAction<string>>;
    setreligion?: React.Dispatch<React.SetStateAction<string>>;
    setgender?: React.Dispatch<React.SetStateAction<string>>;
    fixedLength: number | string;
    endLength: number | string;
    startLength: number | string;
    clearFilter?: boolean;
}
const BabyNameFilter = (props: filterprops) => {
    const [combiCharbtnId, setCombiCharBtnId] = useState<string | undefined>('');
    const [minLength, setMinLength] = useState<any>('');
    const [maxLength, setMaxLength] = useState<any>('');
    const [exactLength, setExactLength] = useState<any>('');

    const { char2, fixedLength, endLength, startLength } = props;

    useEffect(() => {
        setExactLength(fixedLength);
        setMinLength(startLength);
        setMaxLength(endLength);
        let isChecked = fixedLength ? true : false;
        setlen(isChecked);
    }, [fixedLength, endLength, startLength]);

    useEffect(() => {
        setCombiCharBtnId(char2);
    }, [char2]);

    const handleClick = (ch: string) => {
        // if (btnIds.includes(`${props.charr}${ch}`)) {
        //     setBtnIds(btnIds.filter((item) => item !== `${props.charr}${ch}`));
        // } else {
        //     setBtnIds([...btnIds, `${props.charr}${ch}`]);
        // }
        // props.setchar2(btnIds.reduce((acc, cur) => acc + cur + '|', ''));
        // // props.setchar2((prev) => `${prev}${props.charr}${ch}|`);
        setCombiCharBtnId(`${props.charr}${ch}`);
        props.setchar2(`${props.charr}${ch}`);
    };
    const [len, setlen] = useState(false);
    // const [btnIds, setBtnIds] = useState<string[]>([]);
    // useEffect(() => {
    //     props.setchar2(btnIds.reduce((acc, cur) => acc + cur + '|', ''));
    // }, [btnIds]);
    // useEffect(() => {
    //     setBtnIds([]);
    // }, [props.charr, props.clearFilter]);

    const handleKeyPress = (event: any) => !/[0-9]/.test(event.key) && event.preventDefault();
    const Startlength = (e: any) => {
        setMinLength(e.target.value);
        props.setstartLength(e.target.value);
        props.setfixedLength('');
    };
    const Endlength = (e: any) => {
        setMaxLength(e.target.value);
        props.setendLength(e.target.value);
        props.setfixedLength('');
    };
    const Fixedlength = (e: any) => {
        setExactLength(e.target.value);
        props.setstartLength('');
        props.setendLength('');
        props.setfixedLength(e.target.value);
    };
    const Clearfilter = () => {
        // setBtnIds([]);
        setMinLength('');
        setMaxLength('');
        setExactLength('');
        props.setBtnId && props.setBtnId('');
        props.setBtnId1 && props.setBtnId1('');
        props.setreligion && props.setreligion('');
        props.setgender && props.setgender('');
        props.setendLength('');
        props.setfixedLength('');
        props.setstartLength('');
        props.setchar2('');
        props.setcharr && props.setcharr('');
        props.setBtnIdd && props.setBtnIdd('');
    };
    const Leng = (e: any) => {
        // e.preventDefault()
        setlen(!len);
    };
    const characters = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
    ];
    return (
        <div>
            {props.charr && SelectedLanguage === 'en' && (
                <div className="pl-1 pt-5 pb-5">
                    <h3 className="text-lg font-medium mt-4 mb-2 heading-2">
                        Choose a combination
                    </h3>
                    <div className="flex flex-wrap items-center justify-left gap-6">
                        {characters.map((ch) => {
                            return (
                                <>
                                    <TextViewSecond
                                        customStyle={
                                            combiCharbtnId === `${props.charr}${ch}`
                                                ? {
                                                      background: '#F18A1C',
                                                      borderRadius: '2.19048px',
                                                      color: '#FFFFFF'
                                                  }
                                                : {}
                                        }
                                        text={`${props.charr}${ch}`}
                                        size={'small'}
                                        onClick={() => handleClick(ch)}
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>
            )}
            {!len && (
                <div>
                    <h3 className="text-lg font-medium heading-2 mt-4 mb-2">{t('Name Length')}</h3>
                    <div className="flex items-center mt-2 mb-2">
                        <input
                            onChange={Startlength}
                            maxLength={2}
                            onKeyPress={handleKeyPress}
                            className="p-0.5 border-2 h-12 w-14 mr-3 bottom-border pt-track-click"
                            data-ptname="baby-name-filter-min-length"
                        ></input>
                        <p>To</p>
                        <input
                            onChange={Endlength}
                            maxLength={2}
                            type="text"
                            onKeyPress={handleKeyPress}
                            className="ml-3 p-0.5  border-2 h-12 w-14 bottom-border pt-track-click"
                            data-ptname="baby-name-filter-max-length"
                            value={maxLength}
                        ></input>
                    </div>
                    <p className="text-xs font-normal text-gray-400">
                        {t('Min/Max values can range from 2 -20 only')}
                    </p>
                </div>
            )}
            {len && (
                <div>
                    <h3 className="text-lg font-medium mt-4 mb-2 heading-2">{t('Name Length')}</h3>
                    <input
                        onChange={Fixedlength}
                        maxLength={2}
                        type="text"
                        onKeyPress={handleKeyPress}
                        className="border-2  h-12 w-14 p-0.5 bottom-border pt-track-click"
                        data-ptname="baby-name-filter-min-length"
                        value={exactLength}
                    ></input>
                </div>
            )}
            <div className="flex items-center mt-4 mb-5 md:mb-32 md:mr-16 lg:mr-16">
                <input
                    onChange={Leng}
                    checked={len}
                    className="w-6 h-6 mr-2"
                    type="checkbox"
                ></input>
                <h2 className="text-base font-normal">{t('Show Exact Length')}</h2>
            </div>
            {!props.two && (
                <div className="pb-5 md:-translate-x-1/2 md:left-1/2 md:fixed md:bottom-2 md:w-9/12">
                    <ButtonPrimary onClick={props.handleclick} text={t('Apply')} />
                </div>
            )}
            {props.two && (
                <div className="flex justify-between space-x-8 md:space-x-20">
                    <BtnLg
                        customStyle={{ width: '148px' }}
                        onClick={props.handleclick}
                        text={t('Apply')}
                    ></BtnLg>
                    <BtnLg
                        customStyle={{ width: '148px' }}
                        onClick={Clearfilter}
                        text={'CLEAR FILTER'}
                    ></BtnLg>
                </div>
            )}
        </div>
    );
};

export default BabyNameFilter;
