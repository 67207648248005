import { classNames } from '../../../utils/utils';
import * as React from 'react';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';

type ButtonPrimaryType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    className?: string;
    Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
    customStyle?: React.CSSProperties;
    rounded?: boolean;
    dataPtname?: string;
};

const ButtonPrimary = (props: ButtonPrimaryType) => {
    const { t } = useTranslation();
    const {
        size,
        Icon,
        dataPtname,
        text,
        onClick,
        disabled = false,
        isLoading = false,
        className,
        customStyle,
        rounded = false
    } = props;
    return (
        <button
            data-ptname={dataPtname ? dataPtname : ''}
            type="button"
            onClick={onClick}
            style={customStyle}
            disabled={disabled || isLoading}
            className={classNames(
                size === 'small'
                    ? 'py-1.5 px-2 text-sm min-w-min w-24'
                    : size === 'medium'
                    ? 'py-2.5 px-2.5 text-sm w-40'
                    : 'w-full px-3 py-3 text-sm',
                disabled || isLoading ? 'bg-[#CED2D9]' : 'hover:bg-[#377E8B]',
                rounded ? 'rounded-md' : 'rounded-full',
                `inline-flex items-center flex-shrink-0 whitespace-nowrap justify-center border border-transparent font-medium shadow-sm text-white ${
                    disabled || isLoading ? 'bg-[#CED2D9]' : 'bg-[#377E8B]'
                }  focus:outline-none`,
                className
            )}
        >
            {isLoading ? (
                <div
                    className={classNames(
                        size === 'small'
                            ? 'px-3.5 py-3'
                            : size === 'medium'
                            ? 'px-5 py-4'
                            : 'w-full  px-5  text-sm',
                        'flex justify-center w-24 items-center'
                    )}
                >
                    <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-5 ml-1 h-5 border-4 border-darkblue-300 border-solid rounded-full animate-spin"
                    ></div>
                </div>
            ) : (
                <div
                    className="flex gap-1 justify-center items-center text-[14px] pt-track-click"
                    data-ptname={dataPtname}
                >
                    {Icon && <Icon className="h-6 w-6" />}
                    {text.toUpperCase()}
                </div>
            )}
        </button>
    );
};

export default ButtonPrimary;
