import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import { useUserData } from '../../../utils/hooks/useUserData';
import FormProgress from '../../FormProgress/FormProgress';
import TextInputForm from '../../TextInputForm/TextInputForm';
import EditProfileParentSelect from '../../Profile/EditProfileParentSelect/EditProfileParentSelect';
import { GetUserProfile } from '../../../api/OnBoarding/GetUserProfile';
import { addUserdata } from '../../../store/slices/userDataSlice';
import { UpdateProfile } from '../../../api/OnBoarding/UpdateProfile';
import ButtonPrimary from '../../V2Components/ButtonPrimary';
import pushEventToGtm from '../../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';

const NewlyMarried = (props: any) => {
    const { nav, setNav, selectedData, setSelectedData, isLoading, setIsLoading } = props;
    const [guestCookies, setguestCookie] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const dispatch = useAppDispatch();
    const { userData: user_data } = useUserData();
    const [name, setName] = useState<string>('');

    const handleNewlyMarried = async () => {
        const data = { ...selectedData?.response };
        setIsLoading(true);

        function onSucessUpdateProfile(d: any) {
            console.log('date', d);
            if (d.statusCode !== 200) return;
            console.log('done');

            GetUserProfile(
                (s: any) => {
                    console.log('sucess', s);
                    dispatch(addUserdata({ ...s }));
                },
                (e: any) => {
                    console.log('error', e);
                },
                user_data
            );

            setIsLoading(false);
            setNav('ChooseAvatar');
        }

        function onFailureUpdateProfile(e: any) {
            console.log('Error', e);
            setIsLoading(false);
        }

        UpdateProfile(onSucessUpdateProfile, onFailureUpdateProfile, user_data, 9, data);
        pushEventToGtm('newly-married-user-onboard', {
            'newly-married': 'newly-married-next',
            url: window.location.pathname,
            newGuestUid
        });
    };
    useEffect(() => {
        // Handle popstate to control system back navigation
        const handlePopState = () => {
            setNav('SetupProfile');
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        window?.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <main>
            <div className="animate-fade-in-left h-[calc(100vh_-_10vh)] max-w-4xl mx-auto pt-3 flex flex-col px-4">
                <FormProgress
                    onBack={() => {
                        setNav('SetupProfile');
                    }}
                    dots={4}
                    progress={2}
                />
                <h1 className="font-proxima font-bold text-xl text-[#242F35] mb-8">
                    How do you identify yourself
                </h1>
                <form className="my-2 flex flex-col gap-2">
                    <EditProfileParentSelect setSelectedData={setSelectedData} nav={nav} />
                </form>

                {/* {JSON.stringify(selectedData)} */}

                <div className="flex-1 flex flex-col justify-end pb-3">
                    <ButtonPrimary
                        size="large"
                        isLoading={isLoading}
                        text="Next"
                        onClick={() => handleNewlyMarried()}
                        disabled={!selectedData}
                    />
                </div>
            </div>
        </main>
    );
};

export default NewlyMarried;
