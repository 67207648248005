import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../components/Constants/Constants';
import ArrowLeft from '../../components/Icons/ArrowLeft';
import { BenefitArray } from '../../components/V2Components/LazyLoader/types';
import useActiveGiftData from '../../utils/hooks/useActiveGiftData';
import { useUserData } from '../../utils/hooks/useUserData';
import BenefitItem from './BenefitItem';
import GiftMetaTags from './GiftMetaTags';
import ScrollBarImage from './ScrollBarImage';

function GiftActivationPage() {
    const { orderid } = useParams();
    const { content, error } = useActiveGiftData(orderid);
    const { userData: user_data } = useUserData();
    const navigate = useNavigate();

    const handleActivation = () => {
        if (!user_data) {
            return navigate('/register');
        }

        if (user_data) {
            if (localStorage.getItem('gift-user') === '1') {
                return navigate('/my-profile');
            } else {
                return navigate('/');
            }
        }
    };

    if (error) {
        return (
            <main className="max-w-4xl mx-auto h-screen flex justify-center items-center">
                {' '}
                <h2>{error}</h2>{' '}
            </main>
        );
    }

    return (
        <>
            <GiftMetaTags
                senderName={content?.textReceiver}
                image="https://parntune-assets.b-cdn.net/images/gift/gift-image.jpg"
                url={`/gift/${orderid}`}
            />
            <main className="max-w-4xl mx-auto">
                <header className="flex mx-4 items-center my-4">
                    <div onClick={() => navigate('/parent-blog')} className="cursor-pointer">
                        {' '}
                        <ArrowLeft color="#787878" />
                    </div>
                    <h2 className="ml-4 text-[21px]  text-black font-bold">Blogs/Vlogs</h2>
                </header>

                <section className="mx-4  text-center">
                    <h1 className="text-[22px] font-medium" style={{ color: '#F68B45' }}>
                        Congratulations!
                    </h1>
                    <p className="text-[#000000] text-[14px] font-normal">
                        {content?.textReceiver}
                    </p>
                </section>

                <ScrollBarImage imgArray={content?.imgArray} />

                <section className="sm:bg-white bg-[#F9F9F9] pb-4">
                    <div className="mx-4 text-center">
                        <h2 className="text-[#000000] text-[22px] font-medium pt-4">
                            {content?.textReceiverName}
                        </h2>
                        <p className="text-[#000000] text-[16px] font-normal mt-[14px]">
                            {content?.benText}
                        </p>
                    </div>

                    <div className="mx-4 mt-10 grid grid-cols-2 place-items-center ">
                        {content?.BenefitArray.map((item: BenefitArray, index: number) => (
                            <BenefitItem item={item} key={item.Text} index={index + 1} />
                        ))}
                    </div>

                    <section className="mt-10 mx-4 mb-4 text-center">
                        <p className="text-[14px] text-black font-normal">
                            Valid Till : {formatDate(content?.validTill)}
                        </p>
                        <button
                            className="bg-[#377E8B] h-[48px] rounded-full w-full text-white mt-4"
                            onClick={handleActivation}
                        >
                            ACTIVATE YOUR GIFT
                        </button>
                    </section>
                </section>
            </main>
        </>
    );
}

export default GiftActivationPage;
