import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import english from './locales/en/translation.json';
import hindi from './locales/hi/translation.json';
import marathi from './locales/mr/translation.json';
import tamil from './locales/ta/translation.json';
import telugu from './locales/te/translation.json';

export const resources = {
    en: {
        translation: english
    },
    hi: {
        translation: hindi
    },
    mr: {
        translation: marathi
    },
    ta: {
        translation: tamil
    },
    te: {
        translation: telugu
    }
} as const;

i18n.use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        defaultNS: 'translation',
        fallbackLng: 'en',
        ns: ['translation'],
        keySeparator: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        detection: {
            order: ['localStorage']
        }
    });

export default i18n;
