import share from '../../assets/landing/share.svg';
import send from '../../assets/landing/send.svg';
import ratingStar from '../../assets/landing/filled-star-icon.svg';
import emptyStar from '../../assets/landing/star-icon.svg';
import { useEffect, useRef, useState } from 'react';
import { getComments } from '../../api/blog/post/Comments';
import i18n from '../../i18n/config';
import { Question } from '../../components/Question/Question';
import axios from 'axios';
import { useUserData } from '../../utils/hooks/useUserData';
import CourseRating from './CourseRating';
import LoginPopup from '../../components/LoginPopup/LoginPopup';
import { Constants } from '../../components/Constants/Constants';
import { SendIcon } from '../../assets/icons/SendIcon';
import { classNames } from '../../utils/utils';
import NoData from './NoData';

type CommentsType = {
    comment: string;
    date?: string;
    isSupported: boolean;
    itemId: number;
    replyCount: number;
    supportCount: number;
    userAvatar: string | null;
    userDetail: string | null;
    userName: string | null;
};

const QuestionReview = (props: any) => {
    const { id, workshopdetails } = props;

    const [state, setstate] = useState(false);
    const [totalQuestionComments, setTotalQuestionComments] = useState<CommentsType[]>([]);
    const [comments, setComments] = useState<CommentsType[]>([]);
    const [reviews, setReviews] = useState<any>();
    const { userData: user_data } = useUserData();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(0);
    const liveEventDetailCardRef = useRef<any>(null);
    const [socketAvailable, setSocketAvailable] = useState<boolean>(false);

    // USED FOR SENDING COMMENTS TO LIVE EVENTS
    const sendMessageToPage = (page: any, msgValue: any, skipConnection: any) => {
        if (socketAvailable) {
            return;
        }
        const msg: any = { type: 'pmessage', page: page, message: JSON.stringify(msgValue) };
        if (typeof skipConnection != 'undefined') {
            msg.skipConnection = 1;
        }
        console.log(msg);
        // anchorToBottome();
        // socket.send(JSON.stringify(msg));
    };

    const xhrGetPostCardData = (all = '') => {
        let params = {
            id: id,
            itemType: 'live_event',
            pageNumber: 1,
            language: i18n.language,
            user_data: user_data,
            count: all
        };
        getComments(params, onSuccessGetPostCardData, onFailureGetPostCardData);
    };

    const getReviews = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_CONTENT_SERVER}content/feedback?itemId=${id}&itemType=live_event`,
                {
                    headers: {
                        lang: 'en',
                        platform: 'web',
                        userId: user_data ? user_data.userId?.toString() : '',
                        authToken: user_data ? user_data.authToken : '',
                        instanceId: user_data ? user_data.instanceId : '',
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response) setReviews(response.data.feedbacks);
        } catch (error) {
            console.log(error);
        }
    };

    const onSuccessGetPostCardData = (data: any) => {
        if (data) {
            if (data?.comments.length > 3) {
                setComments(data.comments.slice(0, 3));
            } else {
                setComments(data.comments);
            }

            setTotalQuestionComments(data.comments);
            setstate(false);
        }
    };

    const onFailureGetPostCardData = () => {
        console.log('error in fetching comments');
    };

    useEffect(() => {
        xhrGetPostCardData('all');
        getReviews();
    }, []);

    const handleAddComment = (message: string, setMessage: any) => {
        axios
            .post(
                process.env.REACT_APP_CONTENT_SERVER + 'content/comment/add',
                {
                    itemType: 'live_event',
                    comment: message,
                    parentItemType: 'live_event',
                    parentItemId: id,
                    itemId: id.toString()
                },
                {
                    headers: {
                        lang: i18n.language,
                        userId: user_data?.userId || '',
                        authToken: user_data?.authToken || '',
                        instanceId: user_data?.instanceId || ''
                    }
                }
            )
            .then(({ data }) => {
                const messagePacket = {
                    comment: message,
                    isSupported: false,
                    itemId: data.itemId,
                    replyCount: 0,
                    supportCount: 0,
                    userAvatar: user_data && user_data.avatar,
                    userName: user_data.name ? user_data.name : user_data.parentName,
                    userDetail: null
                };

                setTotalQuestionComments((s: any) => {
                    return [messagePacket, ...s];
                });

                setComments((s: any) => {
                    return [messagePacket, ...s];
                });

                if (workshopdetails.status === 'live') {
                    const socketPayload = {
                        type: 'comment',
                        msgdata: {
                            name: user_data.name ? user_data.name : user_data.parentName,
                            userAvatar: user_data && user_data.avatar,
                            commentRawText: message,
                            commentText: message,
                            userId: user_data && user_data.userId,
                            id: data.itemId,
                            cdate: new Date().toLocaleDateString('en-us', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            }),
                            totalQuestions: comments.length + 1,
                            replyLink: '',
                            isReply: false,
                            stickerUrl: ''
                        }
                    };

                    sendMessageToPage('live-session-' + id, socketPayload, false);
                }

                setMessage('');
            });
    };

    const handleOnChange = () => {
        setShowModal(true);
        setModalType(Constants.MODAL_TYPE.BOOKMARK);
    };

    const handleShowAllComments = () => {
        setComments(totalQuestionComments);
    };

    const handleViewLessComments = () => {
        setComments(totalQuestionComments.slice(0, 3));
        window.scrollTo(0, 0);
    };

    return (
        <>
            {showModal ? (
                <LoginPopup
                    showModal={showModal}
                    setShowModal={setShowModal}
                    questionContainerRef={liveEventDetailCardRef}
                    modalType={modalType}
                ></LoginPopup>
            ) : null}
            {comments && comments.length > 0 && (
                <CourseRating rating_count={workshopdetails.ratingcount} />
            )}

            {
                <div className="relative">
                    {/* Top headlines for Question & reviews */}

                    <div className="border-b">
                        <div className="flex justify-between">
                            <button
                                className={classNames(
                                    'flex-1 flex justify-center text-[#151E26C2] text-[18px] font-medium font-roboto',
                                    !state && 'border-black border-b-[3px] mx-4'
                                )}
                                onClick={() => setstate(false)}
                            >
                                Questions
                            </button>
                            <button
                                className={classNames(
                                    'flex-1 flex justify-center text-[#151E26C2] text-[18px] font-medium font-roboto',
                                    state && 'border-black border-b-[3px] mx-4'
                                )}
                                onClick={() => setstate(true)}
                            >
                                Reviews
                            </button>
                        </div>
                    </div>

                    {/* Questions      */}
                    {!state && (
                        <>
                            {comments && comments.length > 0 ? (
                                <>
                                    <div className="relative">
                                        <div
                                            className={`text-lg font-semibold     px-2  z-10 w-full`}
                                        >
                                            {comments && comments.length > 0 && (
                                                <h2 className="pt-[15px] ">{`Questions (${totalQuestionComments.length})`}</h2>
                                            )}
                                        </div>
                                        <div className="bg-[#FBFDFC]  ">
                                            {comments?.length > 0 &&
                                                [...comments]?.map((c: CommentsType) => (
                                                    <div
                                                        className="comments"
                                                        key={c.itemId}
                                                        style={{
                                                            padding: '2px 5px 10px ',
                                                            backgroundColor: '#FBFCFD'
                                                        }}
                                                    >
                                                        <Question
                                                            type="comment"
                                                            {...c}
                                                            parentItemId={id}
                                                            parentItemType="live_event"
                                                        />
                                                    </div>
                                                ))}
                                            {totalQuestionComments.length > 3 &&
                                                totalQuestionComments?.length - comments?.length >
                                                    0 && (
                                                    <button
                                                        onClick={handleShowAllComments}
                                                        className="px-4 text-[14px] text-[#151E26C2] font-medium"
                                                    >
                                                        View all{' '}
                                                        {totalQuestionComments?.length -
                                                            comments?.length}{' '}
                                                        comments
                                                    </button>
                                                )}
                                            {totalQuestionComments.length > 3 &&
                                                totalQuestionComments?.length - comments?.length ===
                                                    0 && (
                                                    <button
                                                        onClick={handleViewLessComments}
                                                        className="px-4 text-[14px] text-[#151E26C2] font-medium"
                                                    >
                                                        View less
                                                    </button>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <NoData key="no-questions" title="No Questions" />
                            )}
                            {workshopdetails.status !== 'archive' && (
                                <PostQuestion
                                    add={1}
                                    userAvatar={user_data && user_data.avatar}
                                    handleAddComment={handleAddComment}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                        </>
                    )}

                    {/* Reviews  */}
                    {state &&
                        (reviews && reviews.length > 0 ? (
                            <>
                                <div className="relative  p-[1px] mt-[15px] ">
                                    <div className=" text-lg font-semibold     px-2  z-10 w-full">
                                        {reviews && reviews.length > 0 && (
                                            <h2 className="pt-[15px] ">{`Reviews (${reviews.length})`}</h2>
                                        )}
                                    </div>
                                    <div className="bg-[#FBFDFC]  ">
                                        {reviews?.length > 0 &&
                                            [...reviews].reverse()?.map((c: any) => (
                                                <div
                                                    className="comments"
                                                    key={c.itemId}
                                                    style={{
                                                        padding: '2px 5px 10px ',
                                                        backgroundColor: '#FBFCFD'
                                                    }}
                                                >
                                                    <User {...c} />
                                                </div>
                                            ))}
                                        {/* {workshopdetails.status !== 'archive' && (
                                        <PostQuestion
                                            add={2}
                                            userAvatar={user_data && user_data.avatar}
                                            handleAddComment={handleAddComment}
                                            handleOnChange={handleOnChange}
                                        />
                                    )} */}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <NoData key="no-reviews" title="No Reviews" />
                        ))}
                </div>
            }
        </>
    );
};

export default QuestionReview;

const User = (props: any) => {
    const { userData } = useUserData();
    const {
        feedback_id,
        feedback_text,
        message,
        userAvatar,
        userDetail,
        userName,
        user_id,
        rating
    } = props;

    return (
        <>
            <div className="ml-2 flex gap-2 mt-[34px]">
                {userData && (
                    <img
                        src={userAvatar}
                        alt="question-hero"
                        className="w-[29px] h-[29px] rounded-full"
                    />
                )}
                {!userData && (
                    <img
                        src={userAvatar}
                        alt="question-hero"
                        className="w-[29px] h-[29px] rounded-full"
                    />
                )}
                {userData && (
                    <div className="flex flex-col">
                        <h2 className="text-[#262626] text-[16px] font-normal">{userName}</h2>
                        {rating === 0 || !rating ? null : (
                            <div className="flex gap-1 items-center">
                                {Array.from({ length: 5 }, (_, index) => {
                                    if (index + 1 <= rating) {
                                        return <img src={ratingStar} width={16} />;
                                    }
                                    return <img src={emptyStar} width={16} />;
                                })}
                            </div>
                        )}
                    </div>
                )}

                {!userData && (
                    <div className="flex flex-col">
                        <h2 className="text-[#262626] text-[16px] font-normal">{userName}</h2>
                        {rating === 0 || !rating ? null : (
                            <div className="flex gap-1 items-center">
                                {Array.from({ length: 5 }, (_, index) => {
                                    if (index + 1 <= rating) {
                                        return <img src={ratingStar} width={16} />;
                                    }
                                    return <img src={emptyStar} width={16} />;
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {message && (
                <p className="text-[16px] h-[max-content] rounded  bg-white p-[16px] border border-gray-200 mt-[14px] ">
                    {message}
                </p>
            )}

            {!message && feedback_text && (
                <p className="text-[16px] h-[max-content] rounded  bg-white p-[16px] border border-gray-200 mt-[14px] ">
                    {feedback_text}
                </p>
            )}
        </>
    );
};

const PostQuestion = ({
    add,
    userAvatar,
    handleAddComment,
    handleOnChange
}: {
    add: number;
    userAvatar: string;
    handleAddComment: (message: string, setMessage: any) => void;
    handleOnChange: any;
}) => {
    const [buttonChange, setButtonChange] = useState(false);
    const [buttonTeal, setButtonTeal] = useState(false);
    const [message, setMessage] = useState<string>('');
    const { userData } = useUserData();

    const handleonChange = (e: any) => {
        if (!userData) {
            handleOnChange();
            return;
        }

        if (e.target.value.trim() === '') {
            setButtonTeal(false);
        } else {
            setButtonTeal(true);
        }
        setMessage(e.target.value);
    };

    const handleOnClick = () => {
        setButtonChange(true);
    };

    return (
        <section className="max-w-4xl mx-auto fixed bottom-0 left-0 right-0 z-50 bg-white">
            <div className="flex items-center justify-between border-t border-[#C4C4C4] h-[75px]  shadow-md px-2 ">
                <div className="flex-1 flex items-center gap-2">
                    {!userAvatar && (
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10 19.143A9.143 9.143 0 1010 .857a9.143 9.143 0 000 18.286z"
                                stroke="#73787D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                            <path
                                d="M16.857 15.714C16.1 13.115 13.2 12.257 10 12.257c-3.117 0-6.023.993-6.857 3.457"
                                stroke="#73787D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                            <path
                                clipRule="evenodd"
                                d="M10 3.143a3.428 3.428 0 013.428 3.428v2.286a3.428 3.428 0 11-6.857 0V6.57A3.429 3.429 0 0110 3.143v0z"
                                stroke="#73787D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    )}
                    {userAvatar && (
                        <img
                            src={userAvatar}
                            alt="profile-icon"
                            className="rounded-full w-[24px] h-[24px]"
                        />
                    )}
                    <input
                        type="text"
                        placeholder={
                            !buttonChange ? 'Share your thoughts/query' : 'Have a question? Ask now'
                        }
                        value={message}
                        onClick={handleOnClick}
                        onChange={(e) => handleonChange(e)}
                        className="w-full"
                    />
                </div>
                <div>
                    {buttonChange && !buttonTeal && <SendIcon color="#CED2D9" />}
                    {buttonChange && buttonTeal && (
                        <div onClick={() => handleAddComment(message, setMessage)}>
                            <SendIcon color="#367E8A" />
                        </div>
                    )}
                    {!buttonChange && <img src={share} alt="share" />}
                </div>
            </div>
        </section>
    );
};
