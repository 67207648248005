import React from 'react';

const StatusBar = ({ nav }: any) => {
    const x = {
        backgroundColor: '#F4D64A'
    };

    return (
        <div className="flex justify-center mt-[24px] ">
            <div
                style={nav > 0 ? { backgroundColor: '#F4D64A' } : {}}
                className={` aqesb1 !w-[17%] !h-[2px] `}
            ></div>
            <div
                style={nav > 1 ? { backgroundColor: '#F4D64A' } : {}}
                className={`aqesb1 !w-[17%] !h-[2px] ml-4 `}
            ></div>
            <div
                style={nav > 2 ? { backgroundColor: '#F4D64A' } : {}}
                className={`aqesb1 !w-[17%] !h-[2px] ml-4 `}
            ></div>
        </div>
    );
};
export default StatusBar;
