import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import NavigateNextIcon from '../../assets/icons/NavigateNextIcon';
import './PhoneNumber.css';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
import { country_codes_africaWithCountry, country_codes_w_phone } from '../../utils/utils';

// import { MdNavigateNext } from 'react-icons/md';
interface PhoneNumberProps {
    setter: (number: string) => void;
    onNext: any;
    preFilled?: any;
    disabled?: boolean;
}

type CountryCode = 'GH' | 'NG' | 'KE' | 'TZ' | 'UG' | 'ZA';

// Later in the component

function PhoneNumber(props: PhoneNumberProps) {
    const [filled, setFilled] = useState<boolean>(false);
    const [phone, setPhone] = useState<any>(props?.preFilled ?? '');
    const { disabled } = props;

    const [country, setCountry] = useState<CountryCode | null>(null);

    useEffect(() => {
        const userLocation = localStorage.getItem('UserLocation');
        if (!userLocation) return;
        const { country } = JSON.parse(userLocation);

        setCountry(country);

        // @ts-ignore
        sessionStorage.setItem('currentCountryCode', country_codes_w_phone[country]);
    }, []);

    useEffect(() => {
        console.log('country', country);
        const Limit = !country?.includes('IN') ? 9 : 10;
        setFilled(phone.length >= Limit && !disabled);
    }, [country, phone, disabled]);

    useEffect(() => {
        console.log('props?.preFilled', props);
        if (props?.preFilled) setPhone(props?.preFilled);
    }, [props]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        let num;

        if (country === 'ZA') {
            num = event.target.value.slice(0, 9);
        } else {
            num = event.target.value.slice(0, 10);
        }

        if (isNaN(parseInt(num))) {
            setPhone('');
            props.setter('');
            return;
        }

        setPhone(num);
        props.setter(num);
    };

    // const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
    //     !/[0-9]/.test(event.key) && event.preventDefault();

    // const countries = [
    //     {
    //         country: 'IN',
    //         value: '+91'
    //     },
    //     {
    //         country: 'GH',
    //         value: '+233'
    //     },
    //     {
    //         country: 'NG',
    //         value: '+234'
    //     },
    //     {
    //         country: 'KE',
    //         value: '+254'
    //     },
    //     {
    //         country: 'TZ',
    //         value: '+255'
    //     },
    //     {
    //         country: 'UG',
    //         value: '+256'
    //     },
    //     {
    //         country: 'ZA',
    //         value: '+27'
    //     }
    // ];

    // const HandleCountryChange = (e: any) => {
    //     const selectedValue = e?.target?.value;

    //     // Split the selected value into country code and value
    //     const [countryCode, value] = selectedValue.split(':');

    //     console.log('value:', value, 'countrycode: ', countryCode);

    //     console.log('countryCodeFromSelection', countryCode, value);

    //     const userLocation = localStorage.getItem('UserLocation');

    //     if (!userLocation) return;

    //     const jsonVal = JSON.parse(userLocation);

    //     const data = {
    //         country_code: value,
    //         // @ts-ignore
    //         country_name: country_codes_africaWithCountry[countryCode],
    //         country: countryCode
    //     };

    //     sessionStorage.setItem('currentCountryCode', value);

    //     // @ts-ignore
    //     localStorage.setItem('UserLocation2', JSON.stringify(data));
    //     // console.log(data, '>>>>>>>');
    // };

    return (
        <div className="onfphnck">
            <div className="onfphnck1">{country ? country_codes_w_phone[country] : '+91'}</div>

            {/* <select
                className=" font-semibold rounded-[5px] w-[115px] h-[48px] text-[16px]  outline-none "
                onChange={HandleCountryChange}
            >
                {countries.map((slot: any, index: number) => (
                    <option key={slot.country} value={`${slot.country}:${slot.value}`}>
                        {slot?.value}
                    </option>
                ))}
            </select> */}

            <div className="onfphnck2" />
            <input
                className="phone__number__input"
                onChange={handleChange}
                onKeyDown={(e) => (e.key === 'Enter' && filled ? props.onNext() : null)}
                placeholder={t('Phone Number')}
                type="number"
                value={phone}
            />
            <button
                onClick={filled ? props.onNext : null}
                className={filled ? 'onfphnck3-filled' : 'onfphnck3'}
            >
                <NavigateNextIcon />
            </button>
        </div>
    );
}

export default PhoneNumber;
