import { BsFillPlayFill } from 'react-icons/bs';
import PlusVideoComponent from '../../../components/PlusComponents/PlusVideoComponent';
import React, { useState } from 'react';
import Overlay from '../../../components/Overlay/Overlay';
import styles from './style.module.css';
// swiper imports
// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// @ts-ignore
import { Mousewheel, Pagination } from 'swiper';

const CardWithVideoButton = (props: any) => {
    const [showFullVideo, setShowFullVideo] = useState(false);
    const { data } = props;
    // console.log(data);
    const [src, setSrc] = useState('');

    return (
        <div className="w-full  bg-white  ">
            {/* <div className="m-auto  w-[320px] mt-[27px] shadow-lg rounded h-auto relative pb-1">
                <div className="absolute rounded h-[166px]  bg-[#000000] opacity-50 w-full"></div>
                <BsFillPlayFill
                    className=" absolute text-[6rem] top-[2rem] left-[7rem] z-20 text-[white] cursor-pointer"
                    onClick={() => {
                        setShowFullVideo(true);
                    }}
                />
                <div className="W-[320px] h-[166px]  grid place-content-center">
                    <img src={data[0]?.banner_portrait_image} alt="img" />
                </div>
                <div className=" m-4 h-full ">
                    <p className="font-[400] text-[14px] text-[#787878] text-center leading-[22px] font-roboto">
                        {data[0]?.text}
                    </p>
                    <h1 className="text-[14px] font-[400] mt-3 text-center text-[#000000] font-roboto leading-[16px]">
                        {data[0]?.userName}, {data[0]?.parentType}
                    </h1>
                </div>
            </div> */}

            {/* {data.map((item: any, index: any) => (
                <div key={index}>{item.banner_portrait_image}</div>
            ))} */}

            <section>
                <div className="  cursor-pointer  ">
                    <Swiper
                        pagination={props?.pagination !== false ? { clickable: true } : false}
                        direction={'horizontal'}
                        mousewheel={{ forceToAxis: true }}
                        modules={[Pagination, Mousewheel]}
                        className={`${styles.mySwiper}`}
                        slidesPerView={window.innerWidth > 700 ? 2 : 1}
                    >
                        {data?.map((item: any, index: any) => (
                            <SwiperSlide key={index}>
                                <div className="m-auto   w-[320px] mt-[27px] shadow-lg rounded h-auto relative pb-1">
                                    {/* <div className="absolute rounded h-[166px]  bg-[#000000] opacity-50 w-full mb-4 bb"></div> */}
                                    <BsFillPlayFill
                                        className=" absolute text-[6rem] top-[3rem] left-[7rem] z-20 text-[white] cursor-pointer "
                                        onClick={() => {
                                            setSrc(item.thumb_video);
                                            setShowFullVideo(true);
                                        }}
                                    />
                                    <div className="W-[320px] h-[300px] overflow-hidden   grid place-content-center  brightness-50">
                                        <img
                                            src={item?.banner_portrait_image}
                                            alt="img"
                                            className="rounded-t  object-cover absolute top-0 z-10"
                                            loading="lazy"
                                        />
                                    </div>

                                    {/* {props.page ? ( */}
                                    <div className=" bg-white absolute text-center  flex flex-col items-center px-3 py-3 bottom-0 brightness-100 ">
                                        <p className="text-[#242F35]  text-base font-normal leading-6 font-roboto">
                                            {/* {item?.text} */}
                                            {item.text}
                                        </p>
                                        <h1 className=" text-[#242F35] my-3 text-lg font-medium leading-6 font-roboto ">
                                            {item?.userName}, {item?.parentType}
                                        </h1>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>

            {showFullVideo ? (
                <Overlay>
                    <div className="instant-box">
                        <div className={styles.container}>
                            <div className={styles.content}>
                                <PlusVideoComponent
                                    setClose={() => setShowFullVideo(false)}
                                    src={src}
                                />
                                {console.log(src)}
                            </div>
                        </div>
                    </div>
                </Overlay>
            ) : null}
        </div>
    );
};

export default React.memo(CardWithVideoButton);
