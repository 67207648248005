import booksummaryimg from '../../assets/booksummary/booksummary-img.svg';

export const DoctorCard = (props: any) => {
    const { state } = props;
    const detail = state;

    return (
        <>
            <div className="flex justify-center">
                <div className="max-w-[360px] w-full mx-[20px] mt-[24px] p-[24px] shadow-md flex flex-col gap-5 rounded">
                    <div className=" flex gap-[30px] items-center">
                        <div>
                            <img
                                src={detail?.avatar}
                                alt="doctorImg"
                                className="aspect-square w-[100px] h-full"
                            />
                        </div>
                        <div className="text-[16px]">
                            <h2 className="text-[#262626] font-bold">{detail?.name}</h2>
                            <p className="text-[#262626] font-normal">
                                {detail?.specialization.length > 20
                                    ? `${detail?.specialization.slice(0, 20)}...`
                                    : detail?.specialization}
                            </p>
                        </div>
                    </div>
                    <h2 className="text-[18px] text-[#262626] font-medium">{detail?.title}</h2>
                </div>
            </div>
        </>
    );
};
