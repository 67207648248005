import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
//api9.parentune.com/workshop/count?id=493
const CountUser = (id: number, onSuccess: (arg: any) => void) => {
    axios
        .get(
            'https://api9.parentune.com/workshop/count',
            {
                params: {
                    id: id.toString()
                }
            }

            // {
            //     headers: {
            //     }
            // }
        )
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((error) => console.log(error));
};

export default CountUser;
