function GenderFemale(props) {
    return (
        <svg
            width={10}
            height={17}
            viewBox="0 0 10 17"
            fill={props.color}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5 0a4.568 4.568 0 00-3.24 1.34 4.584 4.584 0 000 6.48 4.565 4.565 0 002.65 1.3v3.187H.508v1.18H4.41V17h1.18v-3.512h3.902v-1.18H5.59V9.119a4.564 4.564 0 002.65-1.298 4.584 4.584 0 000-6.481A4.566 4.566 0 005 0zm0 1.234a3.346 3.346 0 012.367 5.714 3.346 3.346 0 01-4.734 0A3.346 3.346 0 015 1.233z"
                fill={props.color}
            />
        </svg>
    );
}

export default GenderFemale;
