import React, { useEffect } from 'react';
import Header from '../Header/Header';
import { useUserData } from '../../utils/hooks/useUserData';
import cameraicon from '../../assets/icons/CameraIcon.svg';
import { ProfileEditIcon } from '../../assets/icons/giftIcons/Icons';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import ButtonPrimary from '../V2Components/ButtonPrimary';
interface WelcomePageProps {
    onEditClick: (val: any) => void;
    handleBack: () => void;
}

interface LocationState {
    from?: {
        pathname: string;
    };
}
const WelcomePage: React.FC<WelcomePageProps> = ({ onEditClick, handleBack }) => {
    const { userData: user_data } = useUserData();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as LocationState;
    console.log(user_data);
    const handleAge = (e: any) => {
        const date = new Date(e);
        return date.toLocaleDateString('en-GB');
    };
    const handleRedirection = () => {
        const pathNameFromLocationState = state?.from ?? '/';
        let redirectPage = '/';
        // @ts-ignore

        if (pathNameFromLocationState?.pathname?.charAt(0) === '/') {
            // @ts-ignore
            redirectPage = pathNameFromLocationState?.pathname;
        } else if (
            typeof pathNameFromLocationState === 'string' &&
            pathNameFromLocationState.length > 5
        ) {
            redirectPage = pathNameFromLocationState;
        }
        navigate(-1);
    };
    return (
        <section className="w-full h-full relative">
            <div className="max-w-4xl pt-0 px-2  md:w-10/12 mx-auto flex flex-col gap-3 animate-fade-in-left min-h-screen">
                <div className="">
                    <Header
                        onBack={handleBack}
                        back
                        headerText={`Welcome back, ${user_data?.name.split(' ')[0]}`}
                    />

                    <div className="profile-pic-container">
                        <img
                            src={user_data?.avatar}
                            className="object-cover h-24 w-24 rounded-full mx-auto"
                        />
                        <div className="update-profile-pic">
                            <img src={cameraicon} className="fill-orange-300" alt="" />
                        </div>
                    </div>
                    <div className="text-center max-w-xs mx-auto">
                        <p className="">
                            We are here to support you with personalized advice for your child.
                            Support fellow parents & get helped by like minded parents & experts -
                            24x7.
                        </p>
                    </div>
                    <div className="m-3">
                        <div className="mb-2">
                            <div className="flex justify-between">
                                <h2 className="text-[#242f35] font-light">Your name</h2>
                                <div>
                                    <ProfileEditIcon onClick={() => onEditClick('SetupProfile')} />
                                </div>
                            </div>
                            <h2 className="font-medium">{user_data?.name}</h2>
                            <p className="font-light">{user_data?.parentType}</p>
                        </div>
                        <div className="h-[1px] w-full bg-slate-300"></div>
                        <div className="my-2">
                            {user_data.dueDate && (
                                <>
                                    <div className="flex justify-between">
                                        <h2 className="text-[#242f35] font-light">Due date</h2>
                                        <div>
                                            <ProfileEditIcon
                                                onClick={() => onEditClick('DueDatePage')}
                                            />
                                        </div>
                                    </div>
                                    <h2 className="font-medium">{handleAge(user_data?.dueDate)}</h2>
                                </>
                            )}
                        </div>
                        {user_data.dueDate && <div className="h-[1px] w-full bg-slate-300"></div>}
                        <div className="mt-2 mb-10">
                            <div className="flex justify-between">
                                <h2 className="text-[#242f35] font-light">Childs name</h2>
                                <div>
                                    <ProfileEditIcon onClick={() => onEditClick('HaveChild')} />
                                </div>
                            </div>
                            <div></div>
                            {user_data.kidsList.length > 0 &&
                                user_data?.kidsList.map((item: any, index: any) => {
                                    return (
                                        <div key={index}>
                                            <h2 className="font-medium">{item.name}</h2>
                                            <p className="mb-2 font-normal text-slate-500">
                                                {handleAge(item.dob)}
                                            </p>
                                            <div className="h-[1px] w-full bg-slate-300"></div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0 pb-3 pt-3 bg-white flex justify-center">
                    <ButtonPrimary text="Next" onClick={() => handleRedirection()} />
                </div>
            </div>
        </section>
    );
};

export default WelcomePage;
