import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toggleofficon from '../../assets/landing/toggle-off.svg';
import toggleonicon from '../../assets/landing/toggle-on.svg';
import reminderclock from '../../assets/landing/reminder-clock.svg';
import lock from '../../assets/landing/lock.svg';
import open_lock from '../../assets/landing/open-lock.svg';

import DialogModel from '../../components/DialogModel/DialogModel';
import SetReminder from './SetReminder';
import { SocialShare } from '../BabyNameBottom1Africa/BabyNameBottom1';
import { useUserData } from '../../utils/hooks/useUserData';
import { postSuppport } from '../../api/postSupport';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import { getPostCardData } from '../../api/blog/post/PostCard';
import i18n from '../../i18n/config';
import axios from 'axios';
import { DefaultUser, eventDateConverter, SendEvents } from '../../utils/utils';
import CanWatchWorkshop from '../../api/workshop/CanWatchWorkshop';
import Reminder from '../Workshop/Reminder/Reminder';

const notSupported = 'https://img1.parentune.com/web/assets/images/Icon/Support.svg';
const supported = 'https://img1.parentune.com/web/assets/images/Icon/isSupported.svg';

const SelectedLanguage = Geti18nLang();

const SeesionHeader = (props: any) => {
    const { eventDetails, canWatch, videoOnOff, setVideoOnOff, hasworkshopconsumed } = props;
    const { userData: user_data } = useUserData();

    const [likesCount, setLikesCount] = useState<number>(0);
    const [isSupport, setSupport] = useState(eventDetails?.isLiked);
    const [eventData, setEventData] = useState<{
        comments_count: number;
        is_bookmarked: boolean;
        is_comments: boolean;
        is_liked: boolean;
        is_shared: boolean;
        likes_count: number;
        responseMsg: string;
        shares_count: number;
    }>();

    const [shares, setShares] = useState<number>(parseInt(eventDetails?.offset?.shares));
    const [isVisible, setIsVisible] = useState(false);
    const [isThankYou, setIsThankYou] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [handleShareIcon, setHandleShareIcon] = useState(false);

    const navigate = useNavigate();

    // const popUpDialog = (
    //     <DialogModel closeModel={() => setIsVisible(true)}>
    //         <div onClick={(e: any) => e.stopPropagation()}>
    //             <SetReminder
    //                 setIsThankYou={() => {
    //                     setIsVisible(!isVisible);
    //                     setIsThankYou(!isThankYou);
    //                 }}
    //                 closeModel={() => {
    //                     setIsVisible(!isVisible);
    //                 }}
    //             />
    //         </div>
    //     </DialogModel>
    // );

    const thankYouDialog = (
        <DialogModel closeModel={() => setIsThankYou(!isThankYou)}>
            <div
                onClick={(e: any) => e.stopPropagation()}
                className="flex flex-col gap-[20px] justify-center items-center"
            >
                <h2>Thanks, we’ll remind you</h2>
                <button
                    className="w-[74px] h-[30px] rounded-full bg-[#367E8A] text-white text-[14px] font-medium"
                    onClick={() => setIsThankYou(!isThankYou)}
                >
                    DONE
                </button>
            </div>
        </DialogModel>
    );

    const handleSupport = () => {
        if (!user_data) {
            navigate('/register', {
                replace: true,
                state: { from: `/livechats/title/${eventDetails.id}` }
            });
            return;
        }
        setSupport((s: boolean) => {
            console.log('status:', s);

            if (s === false) {
                setLikesCount(likesCount + 1);
            } else {
                setLikesCount(likesCount === 0 ? 0 : likesCount - 1);
            }
            return !s;
        });

        const params: any = {
            user_data: user_data,
            itemType: eventDetails.itemType,
            itemId: eventDetails.id
        };
        postSuppport(params);
    };

    const onSuccessData = (data: any) => {
        console.log('data-sucess!', data);
        return setEventData(data), setLikesCount(data?.likes_count), setSupport(data?.is_liked);
    };

    const onFailureData = (data: any) => {
        console.log('error', data);
    };

    const handleShare = async () => {
        // const params: any = {
        //     user_data: user_data,
        //     itemType: eventDetails.itemType,
        //     itemId: eventDetails.id
        // };

        if (!user_data) {
            navigate('/register', {
                replace: true,
                state: { from: `/livechats/title/${eventDetails.id}` }
            });
            return;
        }

        SendEvents(user_data?.id ?? DefaultUser, 'btn-click', `btn_event_support`);

        const params = {
            itemType: 'live-events',
            id: eventDetails.id,
            language: i18n.language,
            user_data: user_data
        };
        // getPostCardData(params, onSuccessData, onFailureData);

        setIsOpen(true);
        return await axios
            .post(
                process.env.REACT_APP_CONTENT_SERVER + 'content/share',
                {
                    itemType: eventDetails.itemType,
                    itemId: eventDetails.id
                },
                {
                    headers: {
                        userId: user_data?.userId?.toString() || '',
                        instanceId: user_data?.instanceId?.toString() || '',
                        authToken: user_data?.authToken || '',
                        lang: i18n.language || 'en'
                    }
                }
            )
            .then((response: any) => {
                response.status == 200 ? setShares(shares + 1) : setShares(shares);
                console.log(response);
            });
    };

    useEffect(() => {
        const params = {
            itemType: eventDetails.itemType,
            id: eventDetails.id,
            language: SelectedLanguage,
            user_data: user_data
        };

        getPostCardData(params, onSuccessData, onFailureData);
    }, [eventDetails.id]);

    return (
        <>
            {isThankYou && thankYouDialog}
            <div className="mx-5 flex justify-between my-[18px]">
                <div className="flex items-center gap-[12px]">
                    {canWatch || !eventDetails.amount || hasworkshopconsumed ? (
                        <img src={open_lock} width={24} alt="open-icon" className="aspect-square" />
                    ) : (
                        <img src={lock} width={24} className="aspect-square" alt="lock-icon" />
                    )}

                    {canWatch || !eventDetails.amount || hasworkshopconsumed ? (
                        <></>
                    ) : (
                        <span>Only for PLUS</span>
                    )}
                </div>

                <div className="flex gap-4 items-center">
                    <div className="flex items-center gap-[8px]">
                        <img
                            src={isSupport == true ? supported : notSupported}
                            alt="support"
                            width={24}
                            className="aspect-square"
                            onClick={handleSupport}
                        />
                        {eventData && (
                            <span className="text-[#787878] text-[14px] font-normal">
                                {eventData && likesCount === 0 ? 'Support' : writeLike(likesCount)}
                            </span>
                        )}
                    </div>

                    <div className="flex items-center gap-[8px]">
                        <div>
                            {handleShareIcon ? (
                                <svg
                                    onClick={() => {
                                        handleShare();
                                        setHandleShareIcon(false);
                                    }}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="#FB7882"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.5015 16.7514C17.1584 16.0227 18.1056 15.5665 19.1589 15.5665L19.1589 15.5665C21.1381 15.5665 22.7473 17.1809 22.747 19.1801V19.1802C22.747 21.1781 21.1377 22.7934 19.1584 22.7934C17.1791 22.7934 15.5699 21.1785 15.5699 19.1802C15.5699 19.1819 15.5698 19.1817 15.57 19.1763L15.5704 19.1663L15.571 19.1505L15.5807 18.8302L15.2936 18.6876L7.53478 14.8344L7.25127 14.6937L7.00273 14.8897C6.39294 15.3707 5.62646 15.6572 4.7921 15.6572C2.81268 15.6572 1.20312 14.0426 1.20312 12.0444C1.20312 10.0466 2.81281 8.43126 4.7921 8.43126C5.81155 8.43126 6.73093 8.85936 7.38532 9.54869L7.64633 9.82365L7.98153 9.64654L15.3351 5.76114L15.6394 5.60036L15.5979 5.25873C15.58 5.11218 15.5704 4.96547 15.5704 4.81827C15.5704 2.81951 17.1796 1.20508 19.1589 1.20508C21.1382 1.20508 22.7474 2.81951 22.7474 4.81827C22.7474 6.81611 21.1382 8.43098 19.1589 8.43098C18.2455 8.43098 17.4131 8.08776 16.7787 7.52081L16.5194 7.28907L16.2119 7.45154L8.6435 11.4506L8.36646 11.597L8.3774 11.9101C8.37778 11.9211 8.37815 11.9313 8.3785 11.9409C8.37999 11.982 8.3811 12.0126 8.3811 12.0444C8.3811 12.2656 8.36045 12.4822 8.32202 12.6932L8.25464 13.0633L8.59151 13.2306L15.9077 16.8645L16.2475 17.0333L16.5015 16.7514Z"
                                        stroke="none"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    onClick={() => {
                                        handleShare();
                                        setHandleShareIcon(true);
                                    }}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.5015 16.7514C17.1584 16.0227 18.1056 15.5665 19.1589 15.5665L19.1589 15.5665C21.1381 15.5665 22.7473 17.1809 22.747 19.1801V19.1802C22.747 21.1781 21.1377 22.7934 19.1584 22.7934C17.1791 22.7934 15.5699 21.1785 15.5699 19.1802C15.5699 19.1819 15.5698 19.1817 15.57 19.1763L15.5704 19.1663L15.571 19.1505L15.5807 18.8302L15.2936 18.6876L7.53478 14.8344L7.25127 14.6937L7.00273 14.8897C6.39294 15.3707 5.62646 15.6572 4.7921 15.6572C2.81268 15.6572 1.20312 14.0426 1.20312 12.0444C1.20312 10.0466 2.81281 8.43126 4.7921 8.43126C5.81155 8.43126 6.73093 8.85936 7.38532 9.54869L7.64633 9.82365L7.98153 9.64654L15.3351 5.76114L15.6394 5.60036L15.5979 5.25873C15.58 5.11218 15.5704 4.96547 15.5704 4.81827C15.5704 2.81951 17.1796 1.20508 19.1589 1.20508C21.1382 1.20508 22.7474 2.81951 22.7474 4.81827C22.7474 6.81611 21.1382 8.43098 19.1589 8.43098C18.2455 8.43098 17.4131 8.08776 16.7787 7.52081L16.5194 7.28907L16.2119 7.45154L8.6435 11.4506L8.36646 11.597L8.3774 11.9101C8.37778 11.9211 8.37815 11.9313 8.3785 11.9409C8.37999 11.982 8.3811 12.0126 8.3811 12.0444C8.3811 12.2656 8.36045 12.4822 8.32202 12.6932L8.25464 13.0633L8.59151 13.2306L15.9077 16.8645L16.2475 17.0333L16.5015 16.7514Z"
                                        stroke="#787878"
                                    />
                                </svg>
                            )}
                        </div>
                        <span className="text-[#787878] text-[14px] font-normal">
                            {!shares ? 'Share' : shares}
                        </span>
                    </div>
                </div>
            </div>

            <div className="mx-5  mt-[22px]">
                <h2 className="text-[21px] font-medium text-[#262626]">{eventDetails.title}</h2>
            </div>
            {eventDetails.startDate && eventDetails.status === 'upcoming' && (
                <SessionFreeOrPaid
                    start_date={eventDetails.startDate}
                    end_date={eventDetails.endDate}
                />
            )}
            {eventDetails.status === 'upcoming' && (
                <div className="mx-4">
                    <Reminder eventDetail={eventDetails} setIsThankYou={setIsThankYou} />
                </div>
            )}
            {isThankYou && thankYouDialog}
            <SocialShare isOpen={isOpen} setIsOpen={setIsOpen} title={eventDetails?.title} />
        </>
    );
};

export default SeesionHeader;

const SessionFreeOrPaid = (props: any) => {
    const { start_date, end_date } = props;

    // Format the date
    const formattedDate: string = eventDateConverter(start_date, end_date);

    return (
        <div className="mx-5 mt-2 flex justify-between items-center">
            <div className="text-[#787878] text-[14px] font-normal">{formattedDate}</div>
        </div>
    );
};

const writeLike = (n: number) => {
    if (n < 1000) return n;
    n = n / 1000;
    let result = `${n % 1 !== 0 ? n.toFixed(1) : n}k`;
    return result;
};
