import styles from './textViewSecond.module.css';

type TextViewSecondType = {
    size?: 'small' | 'medium' | 'large';
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    customStyle?: React.CSSProperties;
    startIcon?: any;
    customClass?: any;
};

const TextViewSecond = (props: TextViewSecondType) => {
    const {
        size,
        text,
        className,
        onClick,
        disabled = false,
        customClass,
        customStyle,
        startIcon
    } = props;

    const buttonView = () => {
        return (
            <div className={customClass}>
                <div className={styles.chipMain}>
                    <div className={styles.chipTextIconWrapper}>
                        {startIcon ? <span>{props.startIcon} </span> : null}
                        <button
                            style={customStyle}
                            type="button"
                            onClick={onClick}
                            disabled={disabled}
                            className={styles.chipText}
                        >
                            {text}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return buttonView();
};
export default TextViewSecond;
