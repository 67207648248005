import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cookieExists } from '../../utils/utils';
interface UtilsState {
    isSideBarOpen: boolean;
    userSubscriptionSelectedStatus: string | null;
    location?: null | any;
}

const newUser = cookieExists('new_user');
const profileCreated = cookieExists('user_profile_created');

const UserLocation = localStorage.getItem('UserLocation');
const userLocation = UserLocation ? JSON.parse(UserLocation) : null;

const userSubscriptionSelectedStatusIntialValue =
    (newUser || profileCreated) == true ? null : 'basic';

console.log('newUser cookied', profileCreated, newUser);
const initialState: UtilsState = {
    isSideBarOpen: false,
    userSubscriptionSelectedStatus: userSubscriptionSelectedStatusIntialValue,
    location: userLocation
};

export const utilsSlice = createSlice({
    name: 'utilsSlice',
    initialState,
    reducers: {
        getSideBarStatus: (state: any) => {
            return state?.isSideBarOpen;
        },
        updateSideBarStatus: (state: any, action: PayloadAction<boolean>) => {
            state.isSideBarOpen = action.payload;
        },
        updateUserSubscriptionSelectedStatus: (state: any, action: PayloadAction<string>) => {
            state.userSubscriptionSelectedStatus = action.payload;
        },
        updateUserLocation: (state: any, action: PayloadAction<string>) => {
            state.location = action.payload;
        }
    }
});
export const {
    getSideBarStatus,
    updateSideBarStatus,
    updateUserSubscriptionSelectedStatus,
    updateUserLocation
} = utilsSlice.actions;
export default utilsSlice.reducer;
