export const checkIfChildDataisNull = (data, isNotRequired) => {
    if (isNotRequired && data?.length === 0) return false;
    if (data?.length === 0) return true;
    for (let i = 0; i < data?.length; i++) {
        const child = data[i];
        if (
            child?.name === null ||
            child?.name === '' ||
            child?.name?.length < 3 ||
            child?.dob === null ||
            child?.dob === '' ||
            child?.gender === null ||
            child?.gender === ''
        ) {
            return true;
        }
    }
    return false;
};
