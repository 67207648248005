import './Header.css';
import ArrowLeft from '../Icons/ArrowLeft';
import BlackBackIcon from '../../assets/icons/BackBlackIcon.svg';
import { useEffect, useState } from 'react';
import { VibrationActive } from '../../utils/utils';

function Header(props: {
    back?: boolean;
    textType?: string;
    onBack?: () => void;
    headerText: string;
    textClass?: string;
    isForOnboarding?: boolean;
    isDarkMode?: boolean;
    BottomShadow?: boolean;
}) {
    let { textType } = props;
    let textClass = 'header-text text-xl font-bold';
    if (textType && textType === 'p') {
        textClass = textClass + ' para-4';
    }
    const [animatedClass, setanimatedClass] = useState('');
    // const onBackHandler = () => {
    //     setanimatedClass(props?.BottomShadow ? '' : 'slideToLeft ');

    //     setTimeout(() => {
    //         if (props?.onBack) {
    //             VibrationActive([10]);
    //             props?.onBack();
    //         }
    //     }, 350);
    // };
    return (
        <div
            className={`header-container ${
                props.isForOnboarding ? 'mt-6 mb-8' : 'p-5'
            } ${animatedClass} cursor-pointer ${props?.BottomShadow && ' shadow-border-header '} `}
            onClick={props.onBack}
        >
            {props.back && (
                // <img className="header-icon " src={arrowback} alt="" onClick={props.onBack} />

                <span className="header-icon">
                    {/* <ArrowLeft color="#787878" /> */}

                    {props?.isDarkMode ? (
                        <svg
                            width={14}
                            height={28}
                            viewBox="0 0 14 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.50328 12.3382L0.180659 13.8433C-0.0550051 14.1123 -0.0607943 14.5416 0.167346 14.8014L11.2514 27.6425C11.4796 27.9029 11.8559 27.8957 12.0916 27.6273L13.7141 25.7797C13.9497 25.5113 13.9555 25.0827 13.7274 24.8222L4.65031 14.3057L13.7057 3.58786C13.9321 3.3307 13.9263 2.90671 13.6929 2.64032L12.0866 0.811823C11.8533 0.546099 11.481 0.538839 11.2546 0.797304L1.50326 12.3382L1.50328 12.3382Z"
                                fill="white"
                            />
                        </svg>
                    ) : (
                        <img src="https://img1.parentune.com/images/immunify_BackBlackIcon.svg" />
                    )}
                </span>
            )}

            {props.headerText && (
                <h3
                    className={
                        props.textClass
                            ? `${props.textClass}`
                            : 'header-text text-xl font-bold ml-1'
                    }
                >
                    {' '}
                    {props.headerText}{' '}
                </h3>
            )}
        </div>
    );
}

export default Header;
