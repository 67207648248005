import { t } from 'i18next';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { xhrDeleteBookMark, xhrSaveBookMark } from '../../api/Bookmark';
import getNameDetail from '../../api/NameDetailServices';
import MetaTag from '../../pages/BabyName/MetaTag';
import BabyNameBottom2 from '../../pages/BabyNameBottom2/BabyNameBottom2';
import TrendingBoyName from '../../pages/TrendingBoyName/TrendingBoyName';
import TrendingGirlName from '../../pages/TrendingGirlName/TrendingGirlName';
import { useUserData } from '../../utils/hooks/useUserData';
import { Bookmark } from '../Bookmark/Bookmark';
import ButtonWithIconTwo from '../Button/ButtonWithIconTwo/ButtonWithIconTwo';
import { Constants } from '../Constants/Constants';
import Content from '../Content/Content';
import Gutter from '../Gutter/Gutter';
import BoyIcon from '../Icons/BoyIcon';
import GirlIcon from '../Icons/GirlIcon';
import ShareIcon from '../Icons/ShareIcon';
import LoginPopup from '../LoginPopup/LoginPopup';
import { SocialShare } from '../SocialShare/SocialShare';
import BreadCrumbs from '../V2Components/BreadCrumbs';
import NativeAdComponentWrapper from '../adComponent/NativeAdComponentWrapper';
import styles from './NameDetail.module.css';
import { useTranslation } from 'react-i18next';
import BabyNameHindu from '../BabyNameHindu/BabyNameHindu';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import DfpCompnent from '../adComponent/DfpCompnent';
import { country_codes_africa, country_codes_africaWithCountry } from '../../utils/utils';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
import Header from '../Header/Header';

export interface NameDetailProps {
    name?: string;
    description?: string;
    gender?: string;
    religion?: string;
}

export interface Babyname {
    cdate: string | null;
    description: string;
    gender: string;
    id: number;
    name: string;
    name_letter: string;
    origin: string;
    primary_lang: string;
    sort_order: number;
}
const SelectedLanguage = Geti18nLang();
function NameDetailAfrica() {
    const location = useLocation();
    const { userData: user_data, isLoggedIn } = useUserData();
    const [nameData, setnameData] = React.useState({} as any);
    const [babyNameBookMarkData, setBabyNameBookMarkData] = React.useState<Babyname[]>([]);
    const [hideBookMarkInMobileView, sethideBookMarkInMobileView] = useState<boolean>(false);

    const [imgvalue, setimgvalue] = useState<number>(0);
    const [link, setLink] = useState('');
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [whatsappName, setWhatsappname] = useState('');
    const [isActiveBookmark, setIsActiveBookmark] = useState(false);
    const questionContainerRef = useRef<any>(null);
    const [modalType, setModalType] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [country, setcountry] = useState<any>(null);

    let { name, description, gender, religion, id } = {} as any;

    const xhrGetNameData = () => {
        let id = location.pathname.split('/').pop();
        let args = {
            id: id,
            user_data: user_data
        };
        getNameDetail(args, onSuccess, onFailure);
    };

    const onSuccess = (data: any) => {
        const dataN = data.baby_name;
        let name = dataN.name;
        let description = dataN.description;
        let gender = dataN.gender;
        let id = dataN.id;
        let religion = dataN.origin;
        let isBookmark = data.isBookmarked;
        let primary_lang = dataN.primary_lang;
        setnameData({ name, description, gender, religion, id, primary_lang });
        setIsActiveBookmark(isBookmark);
        setWhatsappname(name);
    };

    // hide bookmark in mobile app
    useEffect(() => {
        if (window.location.href.slice(window.location.href.length - 8) === '?inapp=1') {
            sethideBookMarkInMobileView(true);
        }
    }, [window.location]);

    const hideHeaderForMobile = sessionStorage.getItem('userOnMobile') ?? false;

    // ignore
    const onFailure = (error: string) => {
        console.log(error);
        if (error) navigate('/baby-names');
    };

    React.useEffect(() => {
        const func = async () => {
            const { country } = await useFetchUserLocation();

            if (country_codes_africa.includes(country)) {
                console.log('countrycountry', country);
                console.log(
                    'country_codes_africaWithCountry',
                    country_codes_africaWithCountry['ZA']
                );
                // @ts-ignore
                setcountry(country_codes_africaWithCountry[country]);
                // @ts-ignore
                console.log('>>>>>>>', country_codes_africaWithCountry[country]);
            }
        };
        func();

        xhrGetNameData();
    }, [location]);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        id = params.get('id');
        name = params.get('name');
        description = params.get('description');
        gender = params.get('gender');
        religion = params.get('religion');
        const isBookmark: any = params.get('isBookmarked');

        setnameData({ name, description, gender, religion, id });
        setIsActiveBookmark(isBookmark);
        setWhatsappname(name);
    }, [location]);

    const pages: any = [
        {
            name: t('baby names'),
            href: country ? '/baby-names/africa' : `/baby-names`,
            current: true
        },
        {
            name: t('name_detail'),
            href: `/baby-names/detail`,
            current: true
        }
    ];

    const genderIconView = () => {
        if (nameData?.gender === 'boy') {
            return <BoyIcon size={12} />;
        } else if (nameData?.gender === 'girl') {
            return (
                <svg
                    width="13"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.04687 7.72044C4.89866 7.60919 5.67753 7.16558 6.22494 6.47993C6.77234 5.79428 7.04713 4.91813 6.99337 4.02983C6.93961 3.14153 6.56133 2.30786 5.93555 1.69852C5.30977 1.08919 4.48352 0.75 3.625 0.75C2.76648 0.75 1.94023 1.08919 1.31445 1.69852C0.688667 2.30786 0.310392 3.14153 0.256629 4.02983C0.202866 4.91813 0.477657 5.79428 1.02506 6.47993C1.57247 7.16558 2.35134 7.60919 3.20313 7.72044V8.62579H1.09375V9.50053H3.20313V11.25H4.04687V9.50053H6.15625V8.62579H4.04687V7.72044ZM1.09375 4.25212C1.09375 3.7331 1.24221 3.22573 1.52035 2.79418C1.79848 2.36264 2.19381 2.02628 2.65633 1.82766C3.11886 1.62904 3.62781 1.57708 4.11882 1.67833C4.60984 1.77959 5.06086 2.02952 5.41486 2.39652C5.76886 2.76352 6.00994 3.23111 6.10761 3.74016C6.20528 4.2492 6.15515 4.77684 5.96357 5.25636C5.77198 5.73587 5.44755 6.14571 5.03129 6.43406C4.61502 6.72241 4.12563 6.87632 3.625 6.87632C2.95391 6.87551 2.31053 6.59877 1.836 6.10681C1.36147 5.61486 1.09454 4.94785 1.09375 4.25212V4.25212Z"
                        fill="#242F35"
                    />
                </svg>
            );
        } else {
            return <BoyIcon size={12} />;
        }
    };

    const handleModalClose = (e: any) => {
        if (questionContainerRef && questionContainerRef?.current.contains(e.target)) {
            let el = e.target.textContent;
            let el2 = e.target.getAttribute('class');
            if (el2 === 'svg-icon') {
                setShowModal(false);
            } else {
                if (el === 'Done' || el === 'Skip') {
                    setShowModal(false);
                } else return;
            }
        } else {
            setShowModal(false);
        }
    };

    const saveBookmark = (c: { id: { toString: () => string } }) => {
        if (isLoggedIn) {
            let params = {
                id: c.id,
                user_data: user_data
            };
            setIsActiveBookmark(true);
            xhrSaveBookMark(params, onSaveSuccess, onSaveFailure);
        } else {
            setModalType(Constants.MODAL_TYPE.BOOKMARK);
            setShowModal(true);
        }
    };

    const onSaveSuccess = () => {
        xhrGetNameData();
    };

    const onSaveFailure = () => {
        setIsActiveBookmark(false);
        console.log('error in save');
    };

    const deleteBookMark = (c: { id: { toString: () => string } }) => {
        if (isLoggedIn) {
            let params = {
                id: c.id,
                user_data: user_data
            };
            setIsActiveBookmark(false);
            xhrDeleteBookMark(params, onDeleteSuccess, onDeleteFailure);
        } else {
            setModalType(Constants.MODAL_TYPE.BOOKMARK);
            setShowModal(true);
        }
    };

    const onDeleteSuccess = () => {
        xhrGetNameData();
    };

    const onDeleteFailure = () => {
        setIsActiveBookmark(true);
        console.log('error in save');
    };

    const changeMetaTag = () => {
        return (
            <MetaTag
                title={nameData.name}
                metaTitle={nameData.name}
                metaTwitterTitle={nameData.name}
                keywords={Content.SEO.BABY_NAMES.HOME.META_TAG.KEYWORDS}
                description={nameData.description}
                canonicalLink={window?.location?.href}
                alAndroidUrl={Content.SEO.BABY_NAMES.HOME.AL_ANDROID_URL}
                appleItuneApp={Content.SEO.BABY_NAMES.HOME.APPLE_ITUNES_APP}
                metaKeywords={Content.SEO.BABY_NAMES.HOME.META_TAG.KEYWORDS}
                metaOgTitle={nameData.name}
                metaTwitterDescription={nameData.description}
                metaDescription={nameData.description}
                metaOgDescription={nameData.description}
                metaAlAndroidUrl={Content.SEO.BABY_NAMES.HOME.AL_ANDROID_URL}
                metaAppleItuneApp={Content.SEO.BABY_NAMES.HOME.APPLE_ITUNES_APP}
                current_page_selected_lang={SelectedLanguage}
                primary_lang={nameData.primary_lang}
            />
        );
    };
    return (
        <>
            {changeMetaTag()}
            <SocialShare
                title={`I found this beautiful name on Parentune! Check out its meaning.`}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            <div
                className="bg-[#F7F7F7] pb-[50px] mx-auto"
                style={{
                    maxWidth: '1000px'
                }}
            >
                {!hideHeaderForMobile && (
                    <div className="bg-white">
                        <Header
                            back
                            onBack={() => {
                                let newPath = window.location.pathname
                                    .split('/')
                                    .slice(0, -3)
                                    .join('/');
                                newPath += '/all';
                                navigate(newPath);
                            }}
                            headerText={'Name Details'}
                        />
                    </div>
                )}

                {/* <BreadCrumbs pages={pages} /> */}
                <div className="px-5">
                    <div className="flex flex-col items-center">
                        <section
                            className={`${
                                hideHeaderForMobile
                                    ? 'bg-white w-full m-5 border border-[#151E26] rounded-xl border-opacity-30'
                                    : 'bg-white w-full m-5 border border-[#151E26] rounded-xl border-opacity-30'
                            }  `}
                        >
                            <div className="mt-7 mx-[73px] font-roboto md:text-center">
                                <h1 className="text-[#242F35] font-medium text-[21px]">
                                    {nameData?.name}
                                </h1>
                                <p className=" text-xs font-normal  text-[#787878]">Meaning</p>
                                <p className="text-base font-normal text-[#242F35]">
                                    {nameData?.description}
                                </p>
                            </div>

                            <Gutter styles={{ height: '35px' }} />
                            <div className="flex items-center justify-center gap-8 font-normal">
                                <div className="flex flex-col items-start justify-center">
                                    <p className="text-[#787878]  text-xs">Gender</p>
                                    <div className="flex items-center space-x-2 mt-1">
                                        <div className=" flex justify-center items-center rounded-full bg-[#F3F3F4] p-1 border-[0.5px] border-[#151E2626] border-opacity-20">
                                            <div className=" min-w-[25px] min-h-[25px] flex justify-center items-center">
                                                {genderIconView()}
                                            </div>
                                        </div>
                                        <p className="">
                                            {nameData?.gender == 'girl' ? 'Girl' : 'Boy'}
                                        </p>
                                    </div>
                                </div>
                                <Gutter
                                    styles={{
                                        height: '40px',
                                        border: '0.5px solid #EEEEEE'
                                    }}
                                />
                                <div className="flex flex-col items-start justify-center">
                                    <p className="text-[#787878]  text-xs">Religion</p>
                                    <div className="mt-2">
                                        <p className="">{nameData?.religion}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <Gutter styles={{ height: '19px', border: '0.5px solid #EEEEEE' }} /> */}
                            <div className=" w-full border-t-[0.5px] border-t-[#151E261A] mt-5 ">
                                <footer className="flex justify-between items-center  mx-12 md:mx-72 lg:mx-[375px]   my-5">
                                    <div className={styles.col1}>
                                        <Bookmark
                                            babyNameBookMarkData={babyNameBookMarkData}
                                            imgvalue={imgvalue}
                                            setlink={setLink}
                                            c={nameData}
                                            setshow={setShow}
                                            setIsActiveBookmark={setIsActiveBookmark}
                                            isActiveBookmark={isActiveBookmark}
                                            isLoggedIn={isLoggedIn}
                                            user_data={user_data}
                                            saveBookmark={saveBookmark}
                                            deleteBookMark={deleteBookMark}
                                        />
                                    </div>
                                    <div className={styles.col1}>
                                        <div
                                            onClick={() => {
                                                setIsOpen(true), setShow(!show);
                                            }}
                                            className="flex justify-start items-center space-x-3 "
                                        >
                                            <svg
                                                width="24"
                                                height="20"
                                                viewBox="0 0 24 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.924 19.9516C12.9999 19.9855 13.0807 20 13.1615 20C13.3206 20 13.4749 19.9395 13.5924 19.8283L23.2645 10.4289C23.3821 10.3152 23.4482 10.1604 23.4482 9.99835C23.4482 9.83629 23.3821 9.68149 23.2645 9.56781L13.5875 0.173266C13.4112 0.00153287 13.1492 -0.0468427 12.9215 0.0474897C12.6938 0.141822 12.5469 0.361931 12.5469 0.606228L12.5616 6.48628C11.4744 6.54917 9.11634 6.8104 6.71668 7.89159C4.81165 8.75026 3.28125 9.94997 2.16712 11.4545C0.778747 13.3314 0.0490545 15.6801 8.18102e-05 18.4326C-0.00481546 18.7326 0.210664 18.9962 0.509398 19.0446C0.808131 19.093 1.09462 18.9236 1.19012 18.6406C1.19047 18.6398 1.19097 18.6385 1.19161 18.6369C1.20881 18.5936 1.33111 18.2858 1.63087 17.8521C1.92961 17.4192 2.4683 16.7685 3.34736 16.1179C4.34151 15.3826 5.56093 14.7996 6.96889 14.3836C8.59234 13.9023 10.4729 13.6483 12.564 13.6217L12.5493 19.3929C12.5493 19.6372 12.6962 19.8573 12.924 19.9516ZM13.1835 12.4123H12.9264C10.5366 12.4123 8.38665 12.6929 6.53793 13.2468C4.98305 13.716 3.63385 14.3763 2.52462 15.2157C2.10591 15.5325 1.7533 15.8518 1.45702 16.1566C1.74841 14.6448 2.31404 13.3145 3.14413 12.1849C4.12603 10.8498 5.48747 9.78066 7.19172 9.00665C10.1329 7.67182 13.1081 7.67623 13.1705 7.67632L13.1713 7.67632C13.3353 7.67874 13.4921 7.61585 13.6096 7.50217C13.7271 7.38848 13.7908 7.23368 13.7908 7.07162L13.7785 2.05266L21.9668 10.0008L13.7785 17.9489L13.7908 13.0194C13.7908 12.6856 13.5214 12.4147 13.1835 12.4123Z"
                                                    fill="#151E26"
                                                    fillOpacity="0.6"
                                                />
                                            </svg>
                                            <p className="font-roboto text-sm text-[#151E2699]">
                                                Share
                                            </p>
                                        </div>
                                        {/* <ButtonWithIconTwo
                                    customStyleMain={{ borderRadius: '0' }}
                                    startIcon={<ShareIcon />}
                                    text="Share"
                                    size="small"
                                    className="mr-2 bg-slate-200"
                                    onClick={() => {
                                        setIsOpen(true), setShow(!show);
                                    }}>
                                    <ShareIcon />
                                </ButtonWithIconTwo>
                                <div className={styles.iconTextW}></div> */}
                                    </div>
                                </footer>
                            </div>
                        </section>

                        {/* ad component */}

                        {/* <DfpCompnent /> */}
                        {/* {nameData?.religion !== 'Muslim' && (
                            <div className=" ">
                                <NativeAdComponentWrapper />
                            </div>
                        )} */}
                        <div className="mt-2 w-full">
                            <BabyNameBottom2 />
                        </div>
                        <Gutter styles={{ height: '20px' }} />
                        <div>
                            <TrendingGirlName country={country !== 'all' ? country : ''} />
                        </div>
                        <Gutter styles={{ height: '20px' }} />
                        <div>
                            <TrendingBoyName country={country !== 'all' ? country : ''} />
                        </div>
                        <Gutter styles={{ height: '20px' }} />
                    </div>
                </div>
                {/* added for seo*/}
                <div className="margin-section mx-10">
                    <BabyNameHindu
                        setreligion={religion}
                        setGender={gender}
                        forAfricanCountries={country && country !== 'all' ? true : false}
                        country={country}
                    />
                </div>
            </div>
            {showModal ? (
                <LoginPopup
                    showModal={showModal}
                    setShowModal={setShowModal}
                    questionContainerRef={questionContainerRef}
                    modalType={modalType}
                ></LoginPopup>
            ) : null}
        </>
    );
}

export default NameDetailAfrica;
