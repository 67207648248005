import bookmark from '../../assets/landing/one.svg';
import filled_bookmark from '../../assets/landing/filled_bookmark.svg';
import back from '../../assets/landing/two.svg';
import three from '../../assets/landing/three.svg';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../utils/hooks/useUserData';
import { DefaultUser, SendEvents } from '../../utils/utils';
import { useEffect, useRef, useState } from 'react';
import { Constants } from '../../components/Constants/Constants';
import bookmarkWorskshop from '../../api/workshop/bookmark';
import Overlay from '../../components/Overlay/Overlay';
import LoginPopup from '../../components/LoginPopup/LoginPopup';
import useIsGoogleBot from '../../utils/hooks/useIsGoogleBot';
import DialogModel from '../../components/DialogModel/DialogModel';
import { getEventStatus } from '../../utils/getEventStatus';
import LiveIcon from '../../components/Icons/LiveIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import CountUser from '../../api/workshop/CountUser';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { WorkShopLogCountHandler } from '../../components/WorkshopViewButton/utils';
import { addUserdata } from '../../store/slices/userDataSlice';
import { useDispatch } from 'react-redux';
import EventCountdown from '../../utils/GetTimeLeft';

const HeroHeader = (props: any) => {
    const { workshopdetails, handleVideo, videoOnOff } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userData: user_data, isLoggedIn } = useUserData();
    const isForNestleOffer = Number(user_data?.workshopCreditLeft) > 0;
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [isBookMarked, setisBookMarked] = useState<boolean>(workshopdetails.isBookmarked);
    const liveEventDetailCardRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const isGoogleBot = useIsGoogleBot();
    const [thumbnailloading, setThumnailLoading] = useState(true);
    const [bookmarkEnable, setBookmarkEnable] = useState(false);
    const [leaveEvent, setLeaveEvent] = useState(false);
    const [viewerCount, setViewerCount] = useState<number>(0);

    const [toast, setToast] = useState(false);
    const ToasterHandler = () => {
        setToast(true);
        setTimeout(() => {
            setToast(false);
        }, 2000);
    };

    useEffect(() => {
        setIsLoading(true);
    }, []);

    const startDate = new Date(workshopdetails.start_date).getTime();
    const endDate = new Date(workshopdetails.end_date).getTime();
    const [timeLeft, setTimeLeft] = useState(startDate - new Date().getTime());

    useEffect(() => {
        if (workshopdetails.status === 'live') {
            CountUser(workshopdetails.id, (res) => {
                setViewerCount(res.count);
            });
            const interval = setInterval(() => {
                //Call count api
                CountUser(workshopdetails.id, (res) => {
                    setViewerCount(res.count);
                });
            }, 10000);
            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime();
            const timeDifference = startDate - now;

            if (timeDifference <= 0) {
                clearInterval(intervalId);
                setTimeLeft(0);
            } else {
                setTimeLeft(timeDifference);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [startDate]);

    const handleBackButton = () => {
        if (videoOnOff === true && user_data) {
            setLeaveEvent(true);
        } else {
            navigate(-1);
        }
    };

    const handlePopState = () => {
        if (videoOnOff === true && user_data) {
            setLeaveEvent(true);
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [window.location.pathname, videoOnOff, leaveEvent]);

    const handleNestleFreeOrNot = () => {
        if (isForNestleOffer) return;
        handleVideo();
        window.scroll(0, 0);
        OneFreeLearnCreditHandler();
    };

    useEffect(() => {
        if (workshopdetails.status === 'live') {
            handleVideo();
        }
    }, []);

    const OneFreeLearnCreditHandler = async () => {
        const res = await WorkShopLogCountHandler({
            user_data,
            workshopId: workshopdetails.id
        });
        if (res?.statusCode != '200') return;
        GetUserProfile(
            async (data) => {
                dispatch(addUserdata({ ...data?.data }));
            },
            (err) => {
                console.error('Error: ', err);
            },
            user_data
        );

        const config = {
            value: workshopdetails?.id, // The data you want to store
            expiry: new Date().getTime() + 30 * 24 * 60 * 60 * 1000 // Expiration time: current time + 30 days
        };

        // Store the object as a JSON string in localStorage
        localStorage.setItem('_nestle_free_workshop', JSON.stringify(config));
    };

    const handleBookmark = () => {
        SendEvents(user_data?.id ?? DefaultUser, 'btn-click', `btn_bookmark`);

        if (!user_data) {
            setShowModal(true);
            setModalType(Constants.MODAL_TYPE.BOOKMARK);
        } else {
            setisBookMarked(!isBookMarked);
            ToasterHandler();
            bookmarkWorskshop(
                { user_data: user_data, id: workshopdetails.id },
                (data) => {
                    //     api.info({
                    //         message: `${
                    //             isBookMarked === true
                    //                 ? 'Event has been removed from your Bookmarks'
                    //                 : 'Event has been Saved to your Bookmarks'
                    //         }`,
                    //         duration: 2,
                    //         placement
                    //     });
                    //     console.log(data);
                    setBookmarkEnable(!bookmarkEnable);
                },
                (error: any) => {
                    console.log(error);
                }
            );
        }
    };

    const leaveEventDialog = (
        <DialogModel closeModel={() => setLeaveEvent(!leaveEvent)}>
            <div
                onClick={(e: any) => e.stopPropagation()}
                className="flex flex-col gap-[20px] justify-center items-center"
            >
                <h2 className="text-[#242F35] text-[16px] self-start">Leave this event?</h2>
                <div className="flex justify-end gap-[20px] w-full">
                    <button
                        className="bg-[#367E8A] text-[14px] font-medium  rounded-full px-[18px] py-[7px] text-white"
                        onClick={() =>
                            navigate('/congrutulation', {
                                state: { title: workshopdetails.title, itemid: workshopdetails.id }
                            })
                        }
                    >
                        LEAVE
                    </button>
                    <button
                        className="border border-[#151E261A] rounded-full text-[14px] font-medium px-[18px] py-[7px]"
                        onClick={() => {
                            setLeaveEvent(!leaveEvent);
                        }}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </DialogModel>
    );

    const thumbnailImage = () => {
        if (workshopdetails.status === 'live') return null;

        return (
            <>
                {thumbnailloading && !isGoogleBot && (
                    <div className="bg-[#ced2d9]  grid place-items-center  card-shine-effect h-[224px] md:h-[580px] w-full">
                        <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin-reverse  fill-gray-600 scale-[-1,-1]"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                )}

                <img
                    src={
                        window.innerWidth <= 600
                            ? workshopdetails.mimage
                            : workshopdetails.thumbimage
                    }
                    alt="thumnailImage"
                    className="w-full h-full"
                    style={
                        thumbnailloading && !isGoogleBot
                            ? { display: 'none' }
                            : { display: 'block' }
                    }
                    onLoad={() => setThumnailLoading(false)}
                />
            </>
        );
    };

    const showOrRemoveimg = () => {
        if (workshopdetails.status === 'upcoming' || workshopdetails.status === 'live') return null;

        return (
            <div
                className="play-button absolute top-1/2  -translate-y-1/2  rounded-full h-[67.36px] w-[66px] flex justify-center items-center"
                onClick={handleNestleFreeOrNot}
            >
                <img src={three} alt="play-icon" />
            </div>
        );
    };

    return (
        <>
            {leaveEvent && leaveEventDialog}
            <div className="w-full flex justify-center relative">
                <div className="max-w-4xl w-full h-[78px] img-gradient absolute top-0 left-0 right-0 "></div>
                <div
                    className="absolute left-[16px] top-[16px] right-[16px] flex justify-between"
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <div className=" z-50 flex items-center gap-[12px]">
                        <img src={back} alt="back-icon" onClick={handleBackButton} />
                        {workshopdetails.status === 'upcoming' && (
                            <span className="bg-[#D96572] p-2 text-white text-[14px] font-normal rounded-[8px] h-[32px] flex justify-center items-center">
                                <EventCountdown date={workshopdetails.start_date} />
                            </span>
                        )}
                    </div>
                    {workshopdetails.status !== 'live' && (
                        <img
                            className="z-50"
                            src={bookmarkEnable ? filled_bookmark : bookmark}
                            alt="saved-icon"
                            onClick={() => handleBookmark()}
                        />
                    )}

                    {workshopdetails.status === 'live' && (
                        <div className="flex">
                            <div className="mr-[12px] bg-[#D96572] rounded-[8px]">
                                <LiveIcon />
                            </div>
                            <div className="relative w-[60px] mr-[10px]  h-[35px]  rounded-[2px] overflow-hidden">
                                <div className="absolute top-0 right-0 w-full h-full backdrop-blur-[10px] bg-grey/30" />
                                <div className="w-full h-full flex items-center justify-evenly absolute">
                                    <EyeIcon />
                                    <p className="font-roboto text-[14px] text-white">
                                        {viewerCount}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!videoOnOff && (
                    <>
                        {showOrRemoveimg()}
                        {thumbnailImage()}
                    </>
                )}

                {videoOnOff && (
                    <>
                        {isLoading && !isGoogleBot && (
                            <div className="bg-[#ced2d9]  grid place-items-center  card-shine-effect h-[224px] md:h-[400px] w-full">
                                <svg
                                    aria-hidden="true"
                                    className="w-8 h-8 text-gray-200 animate-spin-reverse  fill-gray-600 scale-[-1,-1]"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                            </div>
                        )}
                        {/* {workshopdetails.amount !== null && (
                            <video
                                src={workshopdetails?.thumb_video}
                                className="aspect-video w-full h-full"
                                controls
                                autoPlay
                                muted
                                onLoadedData={(d) => {
                                    console.log('viemoe video loaded', d);
                                    setIsLoading(false);
                                }}
                                style={
                                    isLoading && !isGoogleBot
                                        ? { display: 'none' }
                                        : { display: 'block' }
                                }></video>
                        )} */}
                        {workshopdetails.embeddetails.iframe &&
                            workshopdetails.embeddetails.source === 'youtube' && (
                                <iframe
                                    src={`${workshopdetails.embeddetails.iframe}${
                                        workshopdetails.embeddetails.iframe.includes('?')
                                            ? '&autoplay=1&mute=1'
                                            : '?&autoplay=1&mute=1'
                                    }`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    height={400}
                                    className="aspect-video w-full"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                    onLoad={() => {
                                        console.log('yt video loaded');
                                        setIsLoading(false);
                                    }}
                                    style={
                                        isLoading && !isGoogleBot
                                            ? { display: 'none' }
                                            : { display: 'block' }
                                    }
                                ></iframe>
                            )}
                        {workshopdetails.embeddetails.iframe &&
                            workshopdetails.embeddetails.source === 'vimeo' && (
                                <video
                                    src={workshopdetails.embeddetails.iframe}
                                    height={400}
                                    className="aspect-video w-full"
                                    controls
                                    autoPlay
                                    muted
                                    onLoadedData={(d) => {
                                        console.log('viemoe video loaded', d);
                                        setIsLoading(false);
                                    }}
                                    style={
                                        isLoading && !isGoogleBot
                                            ? { display: 'none' }
                                            : { display: 'block' }
                                    }
                                ></video>
                            )}
                    </>
                )}
                <div>
                    {showModal ? (
                        <LoginPopup
                            showModal={showModal}
                            setShowModal={setShowModal}
                            questionContainerRef={liveEventDetailCardRef}
                            modalType={modalType}
                        ></LoginPopup>
                    ) : null}
                </div>
                {isLoggedIn && toast && (
                    <Overlay>
                        <div className="relative w-full h-screen">
                            <div className="absolute bottom-[-35vh] inset-0 flex items-center justify-center">
                                <div
                                    className="w-fit shadow-xl bg-[#f4d64a]  text-black p-2 px-4 rounded-md"
                                    style={{ textShadow: '0px 0px 10px 10px' }}
                                >
                                    <p>
                                        {isBookMarked
                                            ? 'Event has been Saved to your Bookmarks'
                                            : 'Event has been Removed from your Bookmarks'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Overlay>
                )}
            </div>
        </>
    );
};

export default HeroHeader;

const formatTime = (milliseconds: any) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
};
