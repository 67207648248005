import axios from 'axios';

export function RenewalPageApi(
    params: any,
    onSuccess: (response: any) => void,
    onFailure: (response: any) => void
) {
    const headerObj = {
        lang: params.language,
        userId: params?.userId?.toString() || '',
        instanceId: params?.instanceId?.toString() || '',
        authToken: params?.authToken || ''
    };
    axios({
        method: 'GET',
        url: process.env.REACT_APP_SUBSCRIPTION_SERVER + 'renew',
        headers: headerObj
    })
        .then((res) => {
            onSuccess(res.data);
        })
        .catch((err) => {
            onFailure(err);
        });
}
