import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '../../components/Icons/Card';
import ChoosePlanCard from '../../components/ChoosePlan/ChoosePlanCard';
import './ChoosePlan.css';
import ButtonPrimary from '../../components/V2Components/ButtonPrimary';
import axios from 'axios';
import { useUserData } from '../../utils/hooks/useUserData';
import { addUserdata } from '../../store/slices/userDataSlice';
import { useDispatch } from 'react-redux';
import useFetchUserLocation from '../../utils/hooks/useFetchUserLocation';
import ChoosePlanCard2 from '../../components/ChoosePlan/ChoosePlanCard2';
// @ts-ignore
import { staticChoosePlanData } from './utils';
import pushEventToGtm from '../../utils/pushEventToGtm';
import { useCookies } from 'react-cookie';

interface LocationState {
    from?: {
        pathname: string;
    };
}

const ChoosePlanPage = () => {
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [guestCookies] = useCookies();
    const newGuestUid = guestCookies['ptgc'];
    const [data, setData] = useState<any>(null);
    const [currentCardData, setcurrentCardData] = useState<any>(staticChoosePlanData);
    const [currentCardIndex, setcurrentCardIndex] = useState<any>(0);
    const { userData: user_data } = useUserData();
    const [currency_symbol, setCurrency_symbol] = useState('RS');
    const location = useLocation();
    const state = location.state as LocationState;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!user_data || ['plus', 'pro'].includes(user_data?.subscriptionInfo))
            navigate('/register', {
                replace: true
            });

        const fetchReq = async () => {
            const { country, city, currency_symbol } = await useFetchUserLocation();
            setCurrency_symbol(currency_symbol || 'RS');

            try {
                const res = await axios.get(
                    process.env.REACT_APP_SUBSCRIPTION_SERVER + 'v2/plans',
                    {
                        headers: {
                            country: country || 'IN'
                        }
                    }
                );
                if (res.data.statusCode !== 200) return;
                setData(res?.data?.data);
                setcurrentCardData(res?.data?.data[currentCardIndex]);
                setloading(false);
            } catch (error) {
                console.log('eeeeee', error);
                setloading(false);
            }
        };
        fetchReq();
    }, []);

    return (
        <main className="abc min-h-screen h-full w-full mx-auto max-w-4xl">
            <Header
                back
                onBack={() =>
                    navigate('/register', {
                        state: {
                            path: 'ProfileSummary'
                        }
                    })
                }
                headerText={'Choose your plan'}
            />
            {loading ? (
                <section>
                    <div className="h-[148px] w-full bg-gray-300 card-shine-effect px-12"></div>

                    <div className="flex items-center justify-between mt-4 space-x-5 ml-5">
                        <div className="h-[90px] w-[95%] bg-gray-300 card-shine-effect rounded-md"></div>
                        <div className="h-[90px] w-[5%] bg-gray-300 card-shine-effect  rounded-md"></div>
                        {/* <div className="h-[148px] w-full bg-gray-300 card-shine-effect mx-12"></div> */}
                    </div>
                    <div className="flex justify-center items-center mt-5 space-x-2 card-shine-effect">
                        <div className="h-3 w-3 rounded-full bg-gray-300 "></div>
                        <div className="h-3 w-3 rounded-full bg-gray-300 "></div>
                        <div className="h-3 w-3 rounded-full bg-gray-300 "> </div>
                    </div>

                    <div className="flex flex-col items-start space-y-2 mx-5 mt-10">
                        <p className="h-6 w-full bg-gray-300 card-shine-effect"></p>
                        <p className="h-6 w-full bg-gray-300 card-shine-effect"></p>
                        <p className="h-6 w-[20%] bg-gray-300 card-shine-effect"></p>
                    </div>

                    <div className="flex flex-col space-y-2 mx-10 mt-10">
                        {[1, 2, 3, 4, 5, 6].map((item) => (
                            <p
                                key={item}
                                className="flex items-center space-x-9 space-y-2 justify-center"
                            >
                                <p className="h-5 w-[5%] bg-gray-300 card-shine-effect"></p>
                                <p className="h-5 w-[95%] bg-gray-300 card-shine-effect"></p>
                            </p>
                        ))}
                    </div>
                </section>
            ) : (
                <section className="">
                    {/* <img
                        src={currentCardData?.banner}
                        alt=""
                        className="w-full mx-0 overflow-x-hidden  max-h-[148px] md:max-h-fit  object-contain"
                    /> */}

                    <div className="w-full h-auto flex flex-col justify-center items-center md:items-start  mt-3 ">
                        <ChoosePlanCard2
                            data={data}
                            setcurrentCardData={setcurrentCardData}
                            currency_symbol={currency_symbol}
                            setcurrentCardIndex={setcurrentCardIndex}
                        />

                        {/* {currentCardData?.claims && (
                            <h1 className="font-bold font-roboto text-xl mt-16 mx-6">
                                {currentCardData?.claims}
                            </h1>
                        )}

                        {currentCardData?.description && (
                            <ul className="space-y-2 font-roboto text-sm w-full text-left px-8  mt-4 mb-5">
                                {currentCardData?.description?.data_annual?.map(
                                    (item: any, index: number) => (
                                        <li
                                            key={index}
                                            className={`flex items-center justify-start w-full ${
                                                item.isLocked == 'True' ? 'space-x-10' : 'space-x-9'
                                            }  space-y-2`}>
                                            {item?.isLocked !== 'True' ? (
                                                <svg
                                                    width={16}
                                                    height={12}
                                                    viewBox="0 0 16 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M14.3333 1L5.16667 10.1667L1 6"
                                                        stroke="#464646"
                                                        strokeWidth={2}
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="11"
                                                    height="15"
                                                    viewBox="0 0 11 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.0833 5.625H9.16667V3.75C9.16667 1.67906 7.52492 0 5.5 0C3.47508 0 1.83333 1.67906 1.83333 3.75V5.625H0.916667C0.409979 5.625 0 6.0443 0 6.5625V14.0625C0 14.5807 0.409979 15 0.916667 15H10.0833C10.59 15 11 14.5807 11 14.0625V6.5625C11 6.0443 10.59 5.625 10.0833 5.625ZM5.5 11.25C4.99331 11.25 4.58333 10.8307 4.58333 10.3125C4.58333 9.7943 4.99331 9.375 5.5 9.375C6.00669 9.375 6.41667 9.7943 6.41667 10.3125C6.41667 10.8307 6.00669 11.25 5.5 11.25ZM7.33333 5.625H3.66667V3.75C3.66667 2.71453 4.48754 1.875 5.5 1.875C6.51246 1.875 7.33333 2.71453 7.33333 3.75V5.625Z"
                                                        fill="#464646"
                                                    />
                                                </svg>
                                            )}
                                            <p className={` text-left `}>{item?.text}</p>
                                        </li>
                                    )
                                )}
                            </ul>
                        )} */}
                    </div>
                </section>
            )}
            <div className=" sticky bottom-0 w-full h-auto z-50 ">
                <div className="p-5 bg-transparent">
                    <ButtonPrimary
                        size="large"
                        text={currentCardData?.cta ?? ''}
                        onClick={() => {
                            if (currentCardIndex == 2) {
                                const url = sessionStorage.getItem('urlFromOnboarding');

                                if (url) {
                                    pushEventToGtm('choose-plan-user-onboard', {
                                        'choose-plan': 'choose-plan-next',
                                        url: window.location.pathname,
                                        newGuestUid
                                    });
                                    navigate(JSON.parse(url), {
                                        replace: true
                                    });
                                    sessionStorage.removeItem('urlFromOnboarding');
                                    dispatch(addUserdata({ ...user_data, isNewUser: false }));

                                    return;
                                }
                                if (user_data?.isEligibleForDailyFocus) {
                                    navigate('/daily-focus', {
                                        replace: true
                                    });
                                    dispatch(addUserdata({ ...user_data, isNewUser: false }));
                                    return;
                                }
                                navigate(`${state?.from ?? '/parent-blog'}`, {
                                    replace: true
                                });
                                dispatch(addUserdata({ ...user_data, isNewUser: false }));
                                return;
                            }
                            navigate(`/choose-plan/${currentCardIndex}`, {
                                state: {
                                    planName: currentCardIndex == 0 ? 'PLUS' : 'PRO'
                                }
                            });
                        }}
                    />
                </div>
            </div>
        </main>
    );
};

export default ChoosePlanPage;
