import * as React from 'react';

const ParentuneLogo = (props: any) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width={44}
            height={44}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.5 0C27.164 0 35 7.836 35 17.5S27.164 35 17.5 35 0 27.164 0 17.5 7.836 0 17.5 0Z"
                fill="#F16A21"
            />
            <path
                d="M16.717 21.056c-.261-2.455-2.351-4.335-4.858-4.335-2.665 0-4.911 2.194-4.911 4.91a.747.747 0 0 1-.731.731.747.747 0 0 1-.732-.731c0-3.5 2.873-6.373 6.373-6.373 3.291 0 6.06 2.507 6.374 5.746-.157-.209-.418-.313-.732-.313-.313-.053-.574.104-.783.365ZM29.515 21.631c0 3.5-2.873 6.373-6.373 6.373-3.29 0-6.06-2.507-6.373-5.746.157.21.418.314.731.314.314 0 .575-.157.784-.418.261 2.455 2.35 4.335 4.858 4.335a4.905 4.905 0 0 0 4.91-4.91c0-.418.366-.731.732-.731.418 0 .731.313.731.783Z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.515 10.711a3.634 3.634 0 0 1-3.656 3.657 3.634 3.634 0 0 1-3.657-3.657 3.634 3.634 0 0 1 3.657-3.656 3.634 3.634 0 0 1 3.656 3.656ZM25.963 22.778a2.81 2.81 0 0 1-2.821 2.821 2.81 2.81 0 0 1-2.821-2.82 2.81 2.81 0 0 1 2.82-2.822c1.516 0 2.822 1.254 2.822 2.821Z"
                fill="#fff"
            />
        </svg>
    );
};
export default ParentuneLogo;
