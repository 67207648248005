import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { TopBabynames } from '../../api/baby-name';
import LoadingLayout from '../../components/V2Components/LoadingLayout';
import TrendingIcon from '../../components/Icons/TrendingIcon';
import BoyIcon from '../../components/Icons/BoyIcon';
import styles from './trendingBoyName.module.css';
// import i18n from '../../i18n/config';
import { getNameFromUrl } from '../../utils/utils';
import { TrendingBoyNameSkeletal } from '../../components/Loader/Others/Skeletal';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';

const TrendingBoyName = (props: any) => {
    const { data, isLoading } = TopBabynames('trendingBabyBoyNames', props?.country ?? '');
    const navigate = useNavigate();
    const SelectedLanguage = Geti18nLang();

    const handleNameClick = (e: any) => {
        const nameDetail = Object.assign({}, e.currentTarget.dataset);
        let name = getNameFromUrl(nameDetail?.name);
        const url =
            SelectedLanguage === 'en'
                ? `/baby-names/detail/${name}/${nameDetail.id}`
                : `/${SelectedLanguage}/baby-names/detail/${name}/${nameDetail.id}`;
        navigate({
            pathname: url
        });
    };

    const RedirectLink = () => {
        let url = '/baby-names/';

        // Check if country exists
        if (props.country) {
            url += `${props.country}/`;
        }

        // Check if SelectedLanguage exists
        if (SelectedLanguage !== 'en') {
            url += `${SelectedLanguage}/`;
        }

        // Add the gender
        url += 'boy';

        return url;
    };

    const child = (
        <div>
            <div className="mt-5 mx-9 grid grid-cols-2 gap-x-20 gap-y-6 sm:grid sm:grid-cols-4 mb-4 ">
                {data ? (
                    data.baby_names.map((c, index) => {
                        return (
                            <div
                                onClick={handleNameClick}
                                data-name={c.name}
                                data-desc={c.description}
                                data-gender={c.gender}
                                data-religion={c.origin}
                                data-id={c.id}
                                key={`trendingBoyName${c.id}`}
                                className="flex items-center cursor-pointer"
                            >
                                <div className="">
                                    <BoyIcon />
                                </div>

                                <p className="ml-3 para-1">{c.name}</p>
                            </div>
                        );
                    })
                ) : (
                    <div></div>
                )}
            </div>
            <div className={`mt-5 mb-8 px-10 md:px-[22rem]`}>
                <Link className="secondary-cta-lg" to={RedirectLink()}>
                    {t('View All')}
                </Link>
            </div>
        </div>
    );
    const skeletalLayout = <TrendingBoyNameSkeletal />;
    return (
        <>
            <div className="">
                <div className="tranding-section-ww">
                    <span className="tranding-section-w">
                        <TrendingIcon />
                    </span>
                    <h3 className="heading-1">{t('Trending baby boy names')}</h3>
                </div>
                <LoadingLayout skelton={skeletalLayout} child={child} isLoading={isLoading} />
            </div>
        </>
    );
};

export default TrendingBoyName;
