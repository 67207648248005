import React, { useEffect, useRef, useState } from 'react';
import AskanExpertYourNextQuestion from '../../assets/AskanExpertYourNextQuestion.svg';
import GetShortTermPlans from '../../api/askDoctor/GetShortTermPlans';
import { useUserData } from '../../utils/hooks/useUserData';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../components/V2Components/ButtonPrimary';

import { useTranslation } from 'react-i18next';
import { Geti18nLang } from '../../utils/i18n utils/Geti18nLang';
import askDocNestle from '../../api/askDoctor/AskDocXNestle';
import AskDocNestleSuccess from './AskDocNestleSuccess';
import { useCookies } from 'react-cookie';
import CanAskApi from '../../api/askDoctor/CanAsk';
import ShortTermPlans from '../../api/shortTermPlan';
import NestleWorkshop from '../../api/workshop/nestleWorkshop';
import { Buffer } from 'buffer';

import CanWatchWorkshop from '../../api/workshop/CanWatchWorkshop';
import { t } from 'i18next';
import SendOtp from '../../api/NonloginUser/SendOtp';
import MobileInputBox from '../../components/MobileInputBox/MobileInputBox';
import { useLocation } from '../../utils/hooks/useLocation';
import { SendEvents } from '../../utils/utils';
import Header from '../../components/Header/Header';
import { GetUserProfile } from '../../api/OnBoarding/GetUserProfile';
import { useDispatch } from 'react-redux';
import { addUserdata } from '../../store/slices/userDataSlice';
import ToastMessage from '../../components/Toast/ToastMessage';
const SelectedLanguage = Geti18nLang();
const ShortTermPlanWorkShop = () => {
    const [shortTermPlanData, setShortTermPlanData] = useState<any>([]);
    const { userData: user_data, isLoggedIn } = useUserData();
    const [selectedPriceCard, setselectedPriceCard] = useState(1);
    const [selectedCardPlanId, setselectedCardPlanId] = useState<any>(null);
    const { i18n } = useTranslation();
    const [orderSucces, setOrderSuccess] = useState(false);
    const [orderData, setOrderData] = useState<any>([]);
    const navigate = useNavigate();
    const [customID, setCustomID] = useState<any>(null);
    const [checked, setChecked] = React.useState(true);
    // todo make it false
    const [shouldShowMobileInput, setShouldShowMobileInput] = useState(false);
    const [mobileNumber, setMobileNumber] = useState<any>(null);
    const [disableContinueBtn, setDisableContinueBtn] = useState(false);
    const locationFromLocalStorage: any = localStorage.getItem('user_location');
    const [_1FreeWorkShopBtnLoading, set_1FreeWorkShopBtnLoading] = useState(false);
    const [showToaster, setShowToaster] = useState<boolean>(false);

    const _otpVerifiedShortTermWorkShop =
        localStorage.getItem('otpVerifiedShortTermWorkShop') ?? null;
    useLocation();
    const dispatch = useDispatch();

    // const buttonScrollRef = useRef<any>(null);
    useEffect(() => {
        setShowToaster(false);
        ShortTermPlans(onSuccess, onFailure, user_data);
        set_1FreeWorkShopBtnLoading(false);
    }, []);

    useEffect(() => {
        if (!user_data) {
            if (checked) setDisableContinueBtn(false);
            else setDisableContinueBtn(true);
            return;
        }

        if (_otpVerifiedShortTermWorkShop) {
            setMobileNumber(JSON.parse(_otpVerifiedShortTermWorkShop));
            setShouldShowMobileInput(false);
        }

        console.log('otpVerifiedShortTermWorkShop >>>>> ', _otpVerifiedShortTermWorkShop);

        // if (otpVerifiedShortTermWorkShop) {
        //     setShouldShowMobileInput(false);
        // }

        setDisableContinueBtn(true);
        console.log('btn disabled');
        if (!checked) return;
        if (customID !== selectedCardPlanId) return;
        if (mobileNumber || _otpVerifiedShortTermWorkShop) setDisableContinueBtn(false);
        if (user_data?.mobile !== '' && checked) setDisableContinueBtn(false);
    }, [window.location, checked, mobileNumber, user_data, _otpVerifiedShortTermWorkShop]);

    function onSuccess(data: any) {
        setShortTermPlanData(data);
        setselectedCardPlanId(data.workshopPlans[1].planId);
        console.log('data?.workshopPlans[1].amount', data?.workshopPlans[1].amount);
        if (data?.hasConsumedNestleOffer) {
            setCustomID(null);
        } else {
            setCustomID(12);
        }
        if (
            user_data &&
            // window.location.search.includes('?key=nestle') &&
            !data?.hasConsumedNestleOffer &&
            user_data?.mobile == ''
        ) {
            if (!_otpVerifiedShortTermWorkShop) setShouldShowMobileInput(true);
        }
    }

    function onFailure(error: any) {
        console.log(error);
    }
    const onSuccessData = (data: any) => {
        console.log(data);

        if (data.errorCode == 401) {
            setShowToaster(true);
            set_1FreeWorkShopBtnLoading(false);

            return;
        } else {
            setOrderSuccess(true);
            setOrderData(data);
            set_1FreeWorkShopBtnLoading(false);
        }
    };
    if (orderSucces && orderData) {
        if (customID === selectedCardPlanId) {
            GetUserProfile(
                async (data) => {
                    dispatch(addUserdata({ ...data?.data }));
                    localStorage.setItem('1FreeWorkshop', JSON.stringify(true));
                    navigate('/livechats#PlusListing');
                    window.location.reload();
                },
                (err) => {
                    console.error('Error: ', err);
                },
                user_data
            );
            return;
        }
        return (
            <AskDocNestleSuccess
                url={'/livechats#PlusListing'}
                success={orderSucces}
                id={orderData?.orderId}
            />
        );
    }

    function handleNestleOrder() {
        const user_location = JSON.parse(locationFromLocalStorage);
        const params = {
            user_data: user_data,
            productId: selectedCardPlanId,
            mobile: mobileNumber ?? user_data?.mobile,
            email: user_data?.email ?? '',
            latitude: user_location?.latitude ?? '',
            longitude: user_location?.longitude ?? ''
        };
        NestleWorkshop(params, onSuccessData, (error: any) => {
            console.log('error', error);
        });
        localStorage.removeItem('otpVerifiedShortTermWorkShop');
    }

    interface OfferCardProps {
        data: {
            index: number;
            planId: number;
            SaveValue: number;
            price: any;
            price_per_workshop: number;
            saving_percentage: number;
            no_of_workshops: number;
            recommendedText: string;
            recommendedPlan: boolean;
        };
    }

    const OfferCard: React.FC<OfferCardProps> = ({ data }) => {
        const {
            index,
            planId,
            SaveValue,
            price,
            no_of_workshops,
            price_per_workshop,
            recommendedPlan,
            recommendedText
        } = data;

        return (
            <>
                <button
                    className={`${
                        selectedPriceCard === index ? ` border-[2px] border-[#377E8B]` : ``
                    } h-auto w-auto bg-white rounded-xl  font-proxima cursor-pointer focus:border-[2px] border-[#377E8B] relative `}
                >
                    <section
                        className="pt-2 px-3  flex flex-col justify-center items-center pt-track-click min-w-[104px]"
                        data-ptname={`short_term_plan_card_${data.no_of_workshops}`}
                    >
                        <div
                            className={` text-center  ${selectedPriceCard === index ? `mt-3` : ``}`}
                        >
                            <p className="text-3xl leading-5">
                                {/* {index == 1 ? 20 : index == 2 ? 10 : 5} */}
                                {no_of_workshops}
                            </p>
                            <p className="text-sm leading-5 mt-2">
                                {`Parenting ${
                                    no_of_workshops.toString() === '1' ? ' Course' : ' Courses'
                                }`}
                            </p>
                        </div>

                        {/* For Nestle Etc */}

                        {planId === customID ? (
                            <div className="text-[#FB7882] font-bold">GIFT</div>
                        ) : (
                            <div className="text-[#8B8B8B] text-base leading-5 mt-2">
                                ₹ {price_per_workshop} /w
                            </div>
                        )}
                        {/* <div className="text-xs text-[#FE992F] leading-5 mt-2">SAVE </div> */}
                        <hr className="w-[80%] h-px md:h-[2px] my-2 bg-[#367E8A] px-4" />
                        {planId === customID ? (
                            <div className="flex justify-center items-center my-2 ">
                                <img
                                    src="https://img1.parentune.com/images/icons/Screenshot%202023-08-24%20at%2012.46.50%20PM.png"
                                    className="h-auto w-20"
                                />
                            </div>
                        ) : (
                            <p className="text-xl leading-5 mt-2 mb-2">₹ {price}</p>
                        )}
                    </section>
                    {/* {recommendedPlan ? (
                        <div className="bg-[#377E8B] text-white rounded-b-lg text-xs font-proxima font-normal mt-2 py-1">
                            {recommendedText}
                        </div>
                    ) : null} */}

                    {selectedPriceCard === index ? (
                        <div className="absolute top-0 font-proxima text-xs font-normal text-[#323232] bg-[#F2D85D] py-[2px] px-4 rounded left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                            Selected
                        </div>
                    ) : null}
                </button>
            </>
        );
    };

    const onOtpVerified = (mobile: any) => {
        if (!mobile) return;
        console.log('heyyy', mobile);
        setMobileNumber(mobile);
        SendEvents(user_data?.userId, 'btn-click', 'mobile_verfied_workshop_pii');
        localStorage.setItem('otpVerifiedShortTermWorkShop', JSON.stringify(mobile));
        localStorage.removeItem('otpVerifiedShortTerm1');
    };
    return (
        <main className="md:mx-48 max-w-[100vw] slideUpFast">
            {showToaster && (
                <div className="w-full h-auto">
                    <ToastMessage showToaster={showToaster} msg={'This Number is already Used'} />
                </div>
            )}{' '}
            <div className="w-full flex h-auto justify-between items-center mt-1 ">
                <Header
                    back
                    onBack={() =>
                        // navigate(-1)
                        navigate(
                            customID === selectedCardPlanId ? '/livechats#listing' : '/livechats'
                        )
                    }
                    headerText={'Back'}
                />
                <a
                    href="javascript:window.ZohoHCAsap.Actions.Open()"
                    className="text-[#367E8A] font-roboto text-[16px] mr-[30px] "
                >
                    {t('GET HELP')}
                </a>
            </div>
            {shortTermPlanData?.bannerImage ? (
                <section>
                    <div className="bg-[#fef0ca] flex justify-center items-center px-3 md:px-20 mx-5  rounded-xl mt-1">
                        <img
                            src={shortTermPlanData.bannerImage}
                            alt="Ask an expert your question next question"
                            className="w-auto md:w-[550px] "
                            loading="lazy"
                        />
                        {/* <div className="text-[#FE992F] font-roboto font-semibold text-lg md:text-4xl leading-[18px]">
                        Ask an expert your next question
                    </div> */}
                    </div>
                </section>
            ) : (
                <div className="h-[190px] w-[350px] bg-indigo-50  m-auto mt- animate-pulse rounded-xl">
                    {/* loader */}
                </div>
            )}
            <section className="mx-5 ">
                <div className="inline-flex items-center justify-center w-full mt-5 ">
                    <hr className="w-full h-px md:h-[2px] my-5 bg-[#FFF0E0]" />
                    <span className="absolute px-3 -translate-x-1/2  left-1/2 dark:text-white">
                        <div className="text-[#E87700] bg-[#FEEFDF] rounded-2xl px-3 py-1 oneTimeOffer">
                            One time offer
                        </div>
                    </span>
                </div>
            </section>
            <section className="mx-5 ">
                <div className="flex flex-col justify-end items-center leading-5  mt-5 mb-4">
                    <p className="text-base">Short term plans</p>
                    <p className="opacity-50 text-sm">Pay less, per workshop</p>
                </div>
            </section>
            {customID !== selectedCardPlanId && (
                <div
                    onClick={() => {
                        if (!user_data && selectedCardPlanId != customID) {
                            navigate('/register', {
                                replace: true,
                                state: { from: `/PlanDetailpage/${selectedCardPlanId}/workshop` }
                            });
                            return;
                        }

                        if (user_data) {
                            navigate(`/PlanDetailpage/${selectedCardPlanId}/workshop`);
                        }
                    }}
                    className="flex justify-center items-center space-x-3 py-2 px-4 rounded-full bg-[#377E8B] mt-8 mb-2 mx-3 sticky top-[90%] z-10"
                    style={{ marginTop: '1rem' }}
                >
                    <p className="text-sm font-roboto font-medium text-white">CONTINUE</p>
                </div>
            )}
            <div className="mt-2 ">
                {shortTermPlanData.workshopPlans ? (
                    <section
                        className="bg-[#F7F7F7] min-h-[220px] 
            "
                    >
                        <div className="flex justify-center items-start space-x-2 py-4">
                            {shortTermPlanData.workshopPlans.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        setselectedPriceCard(index);
                                        setselectedCardPlanId(item.planId);
                                        SendEvents(
                                            user_data?.userId,
                                            'btn-click',
                                            index == 0
                                                ? 'btn_10_workshop_plan'
                                                : index == 1
                                                ? 'btn_1_workshop_plan'
                                                : 'btn_3_workshop_plan'
                                        );
                                    }}
                                >
                                    <OfferCard
                                        data={{
                                            index: index,
                                            planId: item.planId,
                                            SaveValue: item.SaveValue,
                                            price: item.price,
                                            price_per_workshop: item.description.price_per_workshop,
                                            no_of_workshops: item.description.no_of_workshop,
                                            saving_percentage: item.description.saving_percentage,
                                            recommendedPlan: item.description.recommendedPlan,
                                            recommendedText: item.description.recommendedText
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </section>
                ) : (
                    <div className="flex justify-center items-start space-x-2 py-4 p-4 ">
                        <div className="h-52 w-40 bg-indigo-100 rounded-xl animate-pulse"></div>
                        <div className="h-52 w-40 bg-indigo-100 rounded-xl animate-pulse"></div>
                        <div className="h-52 w-40 bg-indigo-100 rounded-xl animate-pulse"></div>
                    </div>
                )}
            </div>
            {shouldShowMobileInput && customID === selectedCardPlanId && user_data && (
                <main className="mx-5">
                    <MobileInputBox onOtpVerified={onOtpVerified} />
                </main>
            )}
            <footer className="mb-5 mt-4 mx-5">
                {customID === selectedCardPlanId && (
                    <section className="">
                        <div className="flex items-start">
                            <input
                                defaultChecked={checked}
                                onChange={(e) => {
                                    setChecked(e.target.checked);
                                }}
                                id="checked-checkbox"
                                type="checkbox"
                                value=""
                                className="mt-1 w-4 h-4 accent-[#377e8b]  rounded focus:border-none "
                            />
                            <label
                                htmlFor="checked-checkbox"
                                className="ml-2 text-sm text-gray dark:text-gray font-roboto "
                            >
                                By way of consent, you agree that Nestle can reach out to you
                                through digital and physical{' '}
                                <a
                                    href="https://www.parentune.com/privacy-policy#pii"
                                    className="text-[#377E8B]"
                                >
                                    read more.
                                </a>
                            </label>
                        </div>

                        {/* channel including social messaging platforms. */}

                        <ButtonPrimary
                            size="large"
                            isLoading={_1FreeWorkShopBtnLoading}
                            text="CONTINUE"
                            className="mt-8 mb-2 "
                            onClick={() => {
                                set_1FreeWorkShopBtnLoading(true);
                                SendEvents(
                                    user_data?.userId,
                                    'btn-click',
                                    'btn_continue_free_1_workshop'
                                );
                                if (!user_data && selectedCardPlanId == customID) {
                                    localStorage.setItem('ComingFromFreeWorkshop', 'true');
                                    navigate('/register', {
                                        replace: true,
                                        state: { from: `/workshop/short-term-plan?key=nestle` }
                                    });
                                    set_1FreeWorkShopBtnLoading(false);
                                    return;
                                }
                                handleNestleOrder();
                            }}
                            disabled={disableContinueBtn}
                        />

                        {/* <div className="  flex justify-center items-center mt-8 mb-2 mx-3 ">
                            <button
                                onClick={() => {
                                    SendEvents(
                                        user_data?.userId,
                                        'btn-click',
                                        'btn_continue_free_1_workshop'
                                    );
                                    if (!user_data && selectedCardPlanId == customID) {
                                        navigate('/register', {
                                            replace: true,
                                            state: { from: `/workshop/short-term-plan?key=nestle` }
                                        });

                                        return;
                                    }
                                    handleNestleOrder();
                                }}
                                // @ts-ignore
                                disabled={disableContinueBtn}
                                className="disabled:bg-[#CED2D9] space-x-3 py-2 px-4 rounded-full bg-[#377E8B] w-full ">
                                <p className="text-sm font-roboto font-medium text-white ">
                                    CONTINUE
                                </p>
                            </button>
                        </div> */}
                        <p
                            className=" text-[#377E8B] text-center mt-2 mb-5"
                            onClick={() => navigate('/livechats')}
                        >
                            No Thanks
                        </p>
                    </section>
                )}

                {customID !== selectedCardPlanId && (
                    <section className="">
                        <Link to="/subscribe#chooseAplan">
                            <div className="flex justify-center items-center space-x-3 py-1 px-3 rounded-full border-[0.5px] border-black border-opacity-50 w-fit mx-auto">
                                <p className="text-black opacity-50 text-sm">Want to save more</p>
                                <svg
                                    width="6"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.694336 9.38672L4.875 5.20605L0.694336 1.02539"
                                        stroke="black"
                                    />
                                </svg>
                            </div>
                        </Link>

                        <p
                            className=" text-[#377E8B] text-center mt-2 mb-5"
                            onClick={() => navigate('/livechats')}
                        >
                            No Thanks
                        </p>
                    </section>
                )}
                <div className="invisible h-12  w-full"></div>
            </footer>
        </main>
    );
};

export default ShortTermPlanWorkShop;
