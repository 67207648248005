import { useState, useEffect } from 'react';

const isBotUserAgent = (agent: any, botUserAgentsArray: any) => {
    for (let i = 0; i < botUserAgentsArray.length; i++) {
        if (agent.toLowerCase().includes(botUserAgentsArray[i].toLowerCase())) {
            return true;
        }
    }
    return false;
};

const useIsGoogleBot = () => {
    const [isBot, setIsBot] = useState(false);

    useEffect(() => {
        const agent = window.navigator.userAgent;
        const botUserAgentsArray = [
            'googlebot',
            'bingbot',
            'linkedinbot',
            'mediapartners-google',
            'lighthouse',
            'insights'
        ];

        if (isBotUserAgent(agent, botUserAgentsArray)) {
            setIsBot(true);
        }
    }, []);

    return isBot;
};

export default useIsGoogleBot;
