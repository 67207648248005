import * as React from 'react';

function BookMarkIcon(props) {
    return (
        <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2225 18.7198C13.5007 18.9026 13.8187 18.994 14.1387 18.994C14.411 18.994 14.6852 18.9265 14.9317 18.7913C15.4743 18.4992 15.8101 17.9347 15.8101 17.3186V2.13849C15.8101 0.959937 14.8522 0 13.6716 0H2.14444C0.965888 0 0.00595093 0.95795 0.00595093 2.13849V17.3186C0.00595093 17.9347 0.343817 18.4992 0.884403 18.7913C1.42499 19.0835 2.08283 19.0576 2.59758 18.7198C2.60177 18.7184 2.60497 18.716 2.60857 18.7133C2.6101 18.7122 2.6117 18.711 2.61348 18.7098L7.18262 15.4623C7.62582 15.1762 8.19423 15.1762 8.63744 15.4623L13.2066 18.7098C13.2108 18.7112 13.214 18.7136 13.2176 18.7163C13.2191 18.7174 13.2207 18.7186 13.2225 18.7198ZM1.00166 2.13849C1.00166 1.50847 1.51442 0.993724 2.14643 0.993724H13.6756C14.3056 0.993724 14.8204 1.50649 14.8204 2.13849V17.3186C14.8204 17.573 14.6852 17.7956 14.4626 17.9168C14.242 18.0381 13.9837 18.0281 13.773 17.893L9.20783 14.6455C9.20365 14.6441 9.20045 14.6417 9.19685 14.6391C9.19532 14.6379 9.19371 14.6368 9.19193 14.6356C8.41484 14.1268 7.41118 14.1268 6.63409 14.6356C6.62991 14.637 6.6267 14.6393 6.6231 14.642C6.62157 14.6431 6.61997 14.6443 6.61819 14.6455L2.04905 17.893C1.83838 18.0261 1.58001 18.0361 1.3594 17.9168C1.13482 17.7976 1.00166 17.573 1.00166 17.3186V2.13849Z"
                fill="#151E26"
                fillOpacity="0.6"
            />
        </svg>
    );
}

export default BookMarkIcon;
