import * as React from 'react';
import { classNames } from '../../../utils/utils';

type buttonliveChats = {
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    dataPtName?: string;
    className?: string;
    Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
    customStyle?: React.CSSProperties;
};

const ButtonLiveChat = (props: buttonliveChats) => {
    const { text, onClick, disabled = false, className, dataPtName, Icon, customStyle } = props;

    return (
        <button
            data-ptname={dataPtName}
            disabled={disabled}
            onClick={onClick}
            className={classNames(
                'flex bg-[#367E8A] text-[white] text-[14px] pt-[11px] pr-[20px] pb-[10px] pl-[28px] font-[500] rounded-[50px]',
                className
            )}
            style={customStyle}
        >
            {text.toUpperCase()}
            {Icon && <Icon className="h-6 w-6" />}
        </button>
    );
};

export default ButtonLiveChat;
