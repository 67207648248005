import { useEffect } from 'react';

const ZohoDeskScript = ({ user_data }) => {
    const scriptSrc =
        'https://desk.zoho.in/portal/api/web/inapp/124514000000223016?orgId=60023887481';
    const nonceValue = '{place_your_nonce_value_here}';

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.nonce = nonceValue;
        script.src = scriptSrc;
        script.defer = true;
        // script.id = 'xxxyz';

        document.body.appendChild(script);

        // additional scripts
        window.ZohoHCAsapSettings = {
            hideLauncherIcon: true,
            ticketsSettings: {
                preFillFields: {
                    email: {
                        defaultValue: user_data?.email ?? null
                    },
                    // departmentId: {
                    //     defaultValue: '124514000000223016'
                    // },
                    contactId: {
                        defaultValue: (user_data?.name || user_data?.name) ?? null
                    }
                }
            }

            // userInfo: {
            //     token: 'WECYKhrkZLTq11DqYxA/C/Poz07N/C8bS1sKZZY6WkReuPDTxoFRNFf674bp+mG4UiBg91ATeYPwdWrfJby7gQ=='
            // }
        };

        return () => {
            // Cleanup: Remove the script element when the component unmounts
            document.body.removeChild(script);
        };
    }, [nonceValue]);

    // useEffect(() => {
    //     var userInformation = {
    //         token: 'WECYKhrkZLTq11DqYxA/C/Poz07N/C8bS1sKZZY6WkReuPDTxoFRNFf674bp+mG4UiBg91ATeYPwdWrfJby7gQ=='
    //     };

    //     console.log('window.ZohoHCAsapReadyStatus', window.ZohoHCAsapReadyStatus);
    //     if (window.ZohoHCAsapReadyStatus) {
    //         window.ZohoHCAsap.Login(userInformation);
    //     }
    // }, [window.ZohoHCAsapReadyStatus]);

    return null;
};

export default ZohoDeskScript;
