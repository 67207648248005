import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { showPurposeCardsInTheseRoutes } from './utils.js';
const PurposeCard = () => {
    const purposeCard1 = 'http://img1.parentune.com/images/purpose_card_1.gif';
    const purposecard2 = 'http://img1.parentune.com/images/purpose_card_2.gif';

    // const purposeCard1 = 'https://img1.parentune.com/web/images/PurposeCard/Valentines%20day.gif';
    // const purposecard2 = 'https://img1.parentune.com/web/images/PurposeCard/Valentines%20day.gif';
    const path = window.location.pathname;
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '0px 0px -50% 0px' // Adjust as needed
    });

    useEffect(() => {
        console.log('window?.location?.pathname: ', path);
        if (!inView) return;

        // setTimeout(() => {

        //     if (!showPurposeCardsInTheseRoutes.some((path_) => path.startsWith(path_))) return;
        //     const PurposeCardsExistsInLocalStorage = sessionStorage.getItem('PurposeCardsData');
        //     if (!PurposeCardsExistsInLocalStorage) return;
        //     const data = JSON.parse(PurposeCardsExistsInLocalStorage);
        //     const ky = window.location.pathname.substring(1);
        //     if (data[ky] == 1) data[ky] = 0;
        //     sessionStorage.setItem('PurposeCardsData', JSON.stringify(data));
        // }, 1500);

        // setTimeout(() => {
        //     if (!showPurposeCardsInTheseRoutes.some((path_) => path.startsWith(path_))) return;
        //     const PurposeCardsExistsInLocalStorage = localStorage.getItem('PurposeCardsData');
        //     if (!PurposeCardsExistsInLocalStorage) return;
        //     const data = JSON.parse(PurposeCardsExistsInLocalStorage);
        //     const ky = window.location.pathname.substring(1);
        //     if (data[ky] == 1) data[ky] = 0;
        //     localStorage.setItem('PurposeCardsData', JSON.stringify(data));
        // }, 1500);
    }, [inView]);

    return (
        <main
            ref={ref}
            className={`flex justify-center items-center ${
                window.location.pathname == '/expert-corner' && 'mb-5'
            } mx-4 `}
        >
            <section className="shadow-lg  w-[350px] h-auto rounded-lg bg-transparent  ">
                <img
                    className="rounded-lg shadow-lg"
                    alt="purpose card"
                    src={
                        window.location.pathname === '/expert-croner' ||
                        window.location.pathname === '/expert-corner/detail'
                            ? purposeCard1
                            : purposecard2
                    }
                ></img>
            </section>
        </main>
    );
};

export default PurposeCard;
