import * as React from 'react';

function ArrowLeft(props) {
    return (
        <svg
            width={10}
            height={17}
            viewBox="0 0 10 17"
            fill={props.color}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.351 9.593a1.199 1.199 0 010-1.695l6.851-6.852a1.199 1.199 0 011.696 1.696L2.894 8.745l6.004 6.004a1.199 1.199 0 11-1.696 1.695l-6.85-6.85z"
                fill={props.color}
            />
        </svg>
    );
}

export default ArrowLeft;
